import React from 'react';
import { select } from 'd3-selection';
import { scaleLinear, scaleBand } from 'd3-scale';
import { axisBottom } from 'd3-axis';
import tip from 'd3-tip';
import Panel from 'components/Panel';
import Locale from 'locale/LocaleFactory';

import { mainLabel, comparedLabel } from 'services/ReportingLabelService';

import ReportingFiltersStore from 'stores/ReportingFiltersStore';
import ReportingStore from 'stores/OutingReportingStore';
import SidebarCollapseStore from 'stores/SidebarCollapseStore';

import OutingsFrequencyLegend from './OutingsFrequencyLegend';

const margin = {
    top: 0,
    right: 30,
    bottom: 22,
    left: 20,
};

function getMaxValue(data) {
    let max = 0;
    data.main.forEach((value) => {
        if (max < value.value) {
            max = value.value;
        }
        return value;
    });
    data.compared.forEach((value) => {
        if (max < value.value) {
            max = value.value;
        }
        return value;
    });
    return max;
}

export default class OutingsFrequency extends React.Component {
    componentDidMount() {
        this._draw();
        ReportingFiltersStore.addChangeListener(this.changeFilters);
        ReportingStore.addChangeListener(this.receiveData.bind(this));
        SidebarCollapseStore.addChangeListener(() => {
            setTimeout(this.redraw, 250);
        });
        this.filters = ReportingFiltersStore.get();
        this.tooltip = null;
    }

    _draw = () => {
        this.width = this.wrapper.clientWidth;
        this.height = this.wrapper.clientHeight;

        this.chart = select(this.node)
            .attr('height', this.height)
            .attr('width', this.width)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);
    }

    _clear = () => {
        this.chart.remove();
    }

    redraw = () => {
        this._clear();
        this._draw();
        this.receiveData();
    };

    changeFilters() {
        this.filters = ReportingFiltersStore.get();
    }

    receiveData() {
        this.createChart();
    }

    createChart() {
        const data = this.getData();

        // set the dimensions and margins of the graph
        const width = this.width - margin.left - margin.right;
        const height = this.height - margin.top - margin.bottom;

        // We merge the two domain in order to have a scale that is usable in both case.
        const domain = new Set(data.main.map(d => d.hour));
        data.compared.map(d => domain.add(d.hour));
        // set the ranges
        const x = scaleBand()
            .range([0, width])
            .padding(0.1)
            .domain(Array.from(domain.values()));
        const y = scaleLinear()
            .range([height, 0])
            .domain([0, getMaxValue(data)]);

        this.createTooltip(data);

        this.createBarsSet('main', data.main, height, x, y, d => x(d.hour));
        this.createBarsSet(
            'compared',
            data.compared,
            height,
            x,
            y,
            d => x(d.hour) + (x.bandwidth() / 2),
        );

        this.chart.call(this.tooltip);
        this.createAxis(height, x);
    }

    createAxis(height, x) {
        this.chart.select('.xAxis').remove();
        this.chart.append('g')
            .attr('class', 'xAxis')
            .attr('transform', `translate(0,${height})`)
            .call(axisBottom(x));
    }

    createBarsSet(className, data, height, x, y, positionX) {
        const bar = this.chart
            .selectAll(`g.${className}`)
            .data(data);
        const enterRects = bar
            .enter()
            .append('g')
            .attr('class', className);

        enterRects
            .append('rect')
            .attr('y', height)
            .attr('height', 0)
            .attr('width', x.bandwidth() / 2)
            .attr('x', positionX);
        bar
            .merge(enterRects)
            .select('rect')
            .on('mouseover', this.tooltip.show)
            .on('mouseout', this.tooltip.hide)
            .transition()
            .duration(300)
            .attr('height', d => height - y(d.value))
            .attr('y', d => y(d.value))
            .attr('width', x.bandwidth() / 2)
            .attr('x', positionX);
        bar
            .exit()
            .transition()
            .duration(300)
            .attr('y', height)
            .attr('height', 0)
            .remove();
    }

    getData() {
        const outings = ReportingStore.getOutings();
        return {
            main: outings.main.repartition,
            compared: outings.compared.repartition,
        };
    }

    createTooltip(data) {
        this.tooltip = tip()
            .attr('class', 'd3-tip')
            .offset([-10, 0])
            .html((d) => {
                const main = data.main.find(e => e.hour === d.hour);
                const compared = data.compared.find(e => e.hour === d.hour);
                return `<span class="reporting-main-text">${mainLabel(this.filters)}: <strong>${main ? main.value : '0'}</strong></span><br />`
                    + `<span class="reporting-compared-text">${comparedLabel(this.filters)}: <strong>${compared ? compared.value : '0'}</strong></span>`;
            });
    }

    render() {
        return (
            <Panel
                title={Locale.trans('reporting.repartition')}
                className="reporting"
            >
                <div
                    className="chart bar-chart outing-repartition"
                    ref={(wrapper) => {
                        this.wrapper = wrapper;
                    }}
                >
                    <svg ref={(node) => {
                        this.node = node;
                    }}
                    />
                </div>
                <OutingsFrequencyLegend />
            </Panel>
        );
    }
}

import React, { useEffect, useState, useRef } from 'react';
import SvgPath from './SvgPath';
import useZonesOfHarbour from 'hooks/useZonesOfHarbour';
import useIncidentsOfHarbour from 'hooks/useIncidentsOfHarbour';
import HarbourStore from 'stores/HarbourStore';
import MathService from 'services/utils/MathService';
import IncidentConstants from 'constants/IncidentConstants';

function getPolygonFromZone(zone, viewMode) {
    const coordinates = MathService.convertStringToPoints(zone.svgPoints);
    const longest = MathService.highestEdge(coordinates);

    let manualRotation = null;
    let manualPositionX = null;
    let manualPositionY = null;
    switch (viewMode) {
        case 'web':
            manualRotation = zone.webLabelRotation;
            manualPositionX = zone.webLabelPositionX;
            manualPositionY = zone.webLabelPositionY;
            break;
        case 'mobile':
            manualRotation = zone.mobileLabelRotation;
            manualPositionX = zone.mobileLabelPositionX;
            manualPositionY = zone.mobileLabelPositionY;
            break;
        default:
        // Nothing
    }

    let angle;
    if (manualRotation !== null) {
        angle = manualRotation;
    } else {
        angle = MathService.getAngleOfLine(longest.p1, longest.p2);
        if (angle < -90) {
            angle += 180;
        } else if (angle > 90) {
            angle -= 180;
        }
    }

    let textPosition;
    if (manualPositionX !== null && manualPositionY !== null) {
        textPosition = {
            x: manualPositionX,
            y: manualPositionY,
        };
    } else {
        textPosition = {
            x: (longest.p1.x + longest.p2.x) / 2,
            y: (longest.p1.y + longest.p2.y) / 2 + 8,
        };
    }

    return {
        id: zone.id,
        text: zone.name,
        textPosition,
        angle,
        points: zone.svgPoints,
        zone,
    };
}

function zoneHasIncident(zone, incidents) {
    return !!incidents.find(
        (i) =>
            i.zone &&
            i.zone.id === zone.id &&
            i.status === IncidentConstants.PENDING,
    );
}

function ZoneText({
    viewMode,
    zoom,
    color,
    text,
    position,
    rotation,
    active,
    onRotationChange,
    onPositionChange,
}) {
    const textRef = useRef(null);
    const [isMoving, setIsMoving] = useState(false);
    const [moveStartPosition, setMoveStartPosition] = useState(null);
    const [isRotate, setIsRotate] = useState(false);

    useEffect(() => {
        function onMouseMove(e) {
            if (!active) {
                return;
            }
            if (isRotate && textRef.current) {
                e.stopPropagation();

                const {
                    x,
                    y,
                    width,
                    height,
                } = textRef.current.getClientRects()[0];
                const textCenter = {
                    x: x + width / 2,
                    y: y + height / 2,
                };
                const mousePosition = {
                    x: e.clientX,
                    y: e.clientY,
                };

                let newRotation = Math.round(
                    (Math.atan(
                        (textCenter.y - mousePosition.y) /
                            (textCenter.x - mousePosition.x),
                    ) *
                        180) /
                        Math.PI,
                );
                if (mousePosition.x < textCenter.x) {
                    newRotation += 180;
                }

                onRotationChange(newRotation);
            }
            if (isMoving) {
                e.stopPropagation();
                const currentPosition = {
                    x:
                        typeof e.clientX === 'undefined'
                            ? e.changedTouches[0].clientX
                            : e.clientX,
                    y:
                        typeof e.clientY === 'undefined'
                            ? e.changedTouches[0].clientY
                            : e.clientY,
                };

                // Take the delta where we are minus where we came from.
                const dx = (currentPosition.x - moveStartPosition.x) / zoom;
                const dy = (currentPosition.y - moveStartPosition.y) / zoom;

                setMoveStartPosition(currentPosition);
                onPositionChange({
                    x: Math.round(position.x + dx),
                    y: Math.round(position.y + dy),
                });
            }
        }
        function onMouseUp() {
            setIsRotate(false);
            setIsMoving(false);
        }

        if (isRotate || isMoving) {
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        }
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, [
        isRotate,
        isMoving,
        active,
        moveStartPosition,
        position,
        zoom,
        onPositionChange,
        onRotationChange,
    ]);

    useEffect(() => {
        if (!active) {
            setIsRotate(false);
        }
    }, [active]);

    const charSize = viewMode === 'mobile' ? 20 : 16;
    const offsetY = viewMode === 'mobile' ? 4 : 2;

    const rectWidth = text.length * (charSize / 2);
    const rectHeight = charSize;
    const rectPosition = {
        x: position.x - rectWidth / 2,
        y: position.y - rectHeight / 2 - offsetY,
    };

    const circlePosition = {
        x: position.x + rectWidth / 2,
        y: position.y - offsetY,
    };

    return (
        <g transform={`rotate(${rotation} ${position.x},${position.y})`}>
            <text
                ref={textRef}
                x={position.x}
                y={position.y}
                dy="1"
                textAnchor="middle"
                className="zone-text"
                fill={color || '#787878'}
            >
                {text}
            </text>
            {active && (
                <React.Fragment>
                    <rect
                        x={rectPosition.x}
                        y={rectPosition.y}
                        width={rectWidth}
                        height={rectHeight}
                        stroke="red"
                        fill="none"
                        className="zone-rect"
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            setIsRotate(false);
                            setIsMoving(true);
                            setMoveStartPosition({
                                x:
                                    typeof e.clientX === 'undefined'
                                        ? e.changedTouches[0].clientX
                                        : e.clientX,
                                y:
                                    typeof e.clientY === 'undefined'
                                        ? e.changedTouches[0].clientY
                                        : e.clientY,
                            });
                            return false;
                        }}
                    />
                    <circle
                        cx={circlePosition.x}
                        cy={circlePosition.y}
                        r="6"
                        className="zone-rotation-handle"
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            setIsRotate(true);
                            setIsMoving(false);
                            return false;
                        }}
                    />
                </React.Fragment>
            )}
        </g>
    );
}

function ZoneSvg({
    viewMode,
    zoom,
    color,
    points,
    label,
    labelPosition,
    labelRotation,
    className,
    onClick,
    active,

    onLabelRotationChange,
    onLabelPositionChange,
    onPathChange,
}) {
    return (
        <g
            className={`zone ${className || ''}`}
            onClick={onClick}
        >
            <SvgPath
                d={points}
                stroke={color || null}
                fill={color ? color + '55' : null}
                editable={active}
                zoom={zoom}
                onChange={onPathChange}
            />
            <ZoneText
                viewMode={viewMode}
                zoom={zoom}
                color={color}
                text={label}
                position={labelPosition}
                rotation={labelRotation}
                active={active}
                onRotationChange={onLabelRotationChange}
                onPositionChange={onLabelPositionChange}
            />
        </g>
    );
}

export default function HarbourMapZoneCalque({
    viewMode,
    zoom,
    onZoneClick,
    showIncident,
    selectedZone,
    onSelectedZoneChange,
}) {
    const currentHarbour = HarbourStore.getCurrentHarbour();
    let zones = [...useZonesOfHarbour(currentHarbour)];
    if (selectedZone) {
        const iZone = zones.findIndex((z) => z.id === selectedZone.id);
        if (iZone !== -1) {
            zones[iZone] = selectedZone;
        }
    }

    const incidents = useIncidentsOfHarbour(currentHarbour);
    const polygons = zones
        .map((z) => getPolygonFromZone(z, viewMode))
        .filter((p) => !!p);

    function onLabelRotationChange(zone, newRotation) {
        switch (viewMode) {
            case 'web':
                onSelectedZoneChange({ webLabelRotation: newRotation });
                break;
            case 'mobile':
                onSelectedZoneChange({ mobileLabelRotation: newRotation });
                break;
            default:
            // Nothing
        }
    }
    function onLabelPositionChange(zone, position) {
        switch (viewMode) {
            case 'web':
                onSelectedZoneChange({
                    webLabelPositionX: position.x,
                    webLabelPositionY: position.y,
                });
                break;
            case 'mobile':
                onSelectedZoneChange({
                    mobileLabelPositionX: position.x,
                    mobileLabelPositionY: position.y,
                });
                break;
            default:
            // Nothing
        }
    }

    function onPathChange(zone, points) {
        onSelectedZoneChange({
            svgPoints: points,
        });
    }

    return (
        <g>
            {polygons
                .filter((p) => !selectedZone || p.zone.id !== selectedZone.id)
                .map((p) => {
                    return (
                        <ZoneSvg
                            key={p.id}
                            zoom={zoom}
                            viewMode={viewMode}
                            color={viewMode === 'mobile' ? p.zone.color : null}
                            className={
                                showIncident &&
                                zoneHasIncident(p.zone, incidents)
                                    ? 'incident'
                                    : ''
                            }
                            onClick={() => onZoneClick(p.zone)}
                            points={p.points}
                            label={p.text}
                            labelPosition={p.textPosition}
                            labelRotation={p.angle}
                        />
                    );
                })}
            {/* We render the selected zone at the end to make them on top to capture the mouse events */}
            {polygons
                .filter((p) => selectedZone && p.zone.id === selectedZone.id)
                .map((p) => {
                    return (
                        <ZoneSvg
                            key={p.id}
                            viewMode={viewMode}
                            zoom={zoom}
                            color={viewMode === 'mobile' ? p.zone.color : null}
                            className={
                                showIncident &&
                                zoneHasIncident(p.zone, incidents)
                                    ? 'incident'
                                    : ''
                            }
                            onClick={() => onZoneClick(p.zone)}
                            points={p.points}
                            label={p.text}
                            labelPosition={p.textPosition}
                            labelRotation={p.angle}
                            active
                            onLabelRotationChange={(newRotation) =>
                                onLabelRotationChange(p.zone, newRotation)
                            }
                            onLabelPositionChange={(newPosition) =>
                                onLabelPositionChange(p.zone, newPosition)
                            }
                            onPathChange={(newPath) =>
                                onPathChange(p.zone, newPath)
                            }
                        />
                    );
                })}
        </g>
    );
}

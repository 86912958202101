import React from 'react';

import Panel from 'components/Panel';
import Locale from 'locale/LocaleFactory';
import ReportingFiltersStore from 'stores/ReportingFiltersStore';
import ReportingStore from 'stores/OutingReportingStore';
import ReportingConstants from 'constants/ReportingConstants';

import LineChart from 'components/charts/LineChart';
import { comparedLabel } from 'services/ReportingLabelService';
import SidebarCollapseStore from 'stores/SidebarCollapseStore';
import LineChartLegend from './LineChartLegend';

const margin = {
    top: 15,
    right: 20,
    bottom: 53,
    left: 30,
};


function getMaxValue(data) {
    if (data.main.length === 0 && data.compared.length === 0) {
        return 0;
    }
    const getMaximum = (maximum, value) => {
        if (maximum.value < value.value) {
            return value;
        }
        return maximum;
    };

    if (data.main.length === 0) {
        return data.compared.reduce(getMaximum).value;
    }
    if (data.compared.length === 0) {
        return data.main.reduce(getMaximum).value;
    }
    const max1 = data.main.reduce(getMaximum);
    const max2 = data.compared.reduce(getMaximum);
    return Math.max(max1.value, max2.value);
}

export default class OutingsHistory extends LineChart {
    // TODO allow change
    // TODO allow resize
    currentDisplay = ReportingConstants.DISPLAYS.DAYS;

    componentDidMount() {
        super.componentDidMount();

        ReportingFiltersStore.addChangeListener(this.changeFilters);
        ReportingStore.addChangeListener(this.receiveData);
        SidebarCollapseStore.addChangeListener(() => {
            setTimeout(this.redraw, 250);
        });
        this.filters = ReportingFiltersStore.get();
    }
    getMargin() {
        return margin;
    }
    getXValue(d) {
        return new Date(d.date);
    }

    getYDomain(data) {
        return [0, getMaxValue(data)];
    }

    getXMainDomain(data) {
        return [
            new Date(data.main[0].date),
            new Date(data.main[data.main.length - 1].date)
        ];
    }

    getXComparedDomain(data) {
        return [
            new Date(data.compared[0].date),
            new Date(data.compared[data.compared.length - 1].date)
        ];
    }

    changeFilters() {
        this.filters = ReportingFiltersStore.get();
    }

    getTooltipTitle(point) {
        const date = this.getXValue(point);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('fr-FR', options);
    }

    getTooltipContent(point, pointCompared) {
        const displayCompared = () => {
            if (isNaN(this.getYValueCompared(pointCompared))) {
                return '';
            }
            return `
                <span>
                    <br />
                    <span class="reporting-compared-text">${comparedLabel(this.filters)}: </span>
                    ${this.formatTooltipText(this.getYValueCompared(pointCompared))}
                </span>
            `;
        };
        return `
            <p>
                <span class="reporting-main-text">${Locale.trans('Période')}: </span>
                ${this.formatTooltipText(this.getYValueMain(point))}
                ${displayCompared()}
            </p>`;
    }

    createLines(data, xMain, xCompared, y) {
        this.createLine('main', data.main, d => xMain(this.getXValue(d)), d => y(d.value));
        const mainMean = data.main.map((val) => ({ date: val.date, value: data.mainMean}));
        this.createLine('main dotted', mainMean, d => xMain(this.getXValue(d)), d => y(d.value));
        this.createArea('main', data.main, d => xMain(this.getXValue(d)), d => y(d.value));
        this.createLine('compared', data.compared, d => xCompared(this.getXValue(d)), d => y(d.value));
        const comparedMean = data.compared.map((val) => ({ date: val.date, value: data.comparedMean}));
        this.createLine('compared dotted', comparedMean, d => xCompared(this.getXValue(d)), d => y(d.value));
        this.createArea('compared', data.compared, d => xCompared(this.getXValue(d)), d => y(d.value));
    }

    getData() {
        const outings = ReportingStore.getOutings();
        const reducer = (total, value) => total + value.value;
        const dataRaw = {
            main: outings.main.history,
            compared: outings.compared.history,
            mainMean: outings.main.history.reduce(reducer, 0) / outings.main.history.length,
            comparedMean: outings.compared.history.reduce(reducer, 0) / outings.compared.history.length,
        };

        let data = {};
        if (this.currentDisplay === ReportingConstants.DISPLAYS.DAYS) {
            data = dataRaw;
        } else if (this.currentDisplay === ReportingConstants.DISPLAYS.WEEK) {
            // TODO
        } else if (this.currentDisplay === ReportingConstants.DISPLAYS.MONTH) {
            // TODO
        }
        return data;
    }

    formatTooltipText(d) {
        return d;
    }

    render() {
        return (
            <Panel
                title={Locale.trans('reporting.outing_history')}
                className="reporting"
            >
                {super.render()}
                <LineChartLegend />
            </Panel>
        );
    }
}

import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import PlaceConstants from 'constants/PlaceConstants';
import PlaceService from 'services/PlaceService';

import CacheManager from 'services/CacheManager';
import HarbourStore from 'stores/HarbourStore';

const CACHE_DURATION = 15 * 1000;

const RELOAD_INTERVAL = 20 * 1000;
const intervalIds = {} as { [harbourId: number]: any };
class PlaceActions extends ActionsBase {
    create = (harbour: IHarbour, place: Object) => {
        const $this = this;
        return new Promise(
            (
                resolve: (place: IPlace) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newPlace: IPlace): void {
                    dispatcher.dispatch({
                        type: PlaceConstants.RECEIVE_PLACE_OF_HARBOUR,
                        payload: {
                            harbour,
                            place: newPlace,
                        },
                    });
                    resolve(newPlace);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                PlaceService.post(harbour.id, place)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    edit = (harbour: Entity, placeId: number, place: Object) => {
        const $this = this;
        return new Promise(
            (
                resolve: (place: IPlace) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newPlace: IPlace): void {
                    dispatcher.dispatch({
                        type: PlaceConstants.RECEIVE_PLACE_OF_HARBOUR,
                        payload: {
                            harbour,
                            place: newPlace,
                        },
                    });
                    resolve(newPlace);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                PlaceService.patch(harbour.id, placeId, place)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    delete = (harbour: Entity, id: number) => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                function handleSuccess(): void {
                    dispatcher.dispatch({
                        type: PlaceConstants.DELETE_PLACE_OF_HARBOUR,
                        payload: {
                            harbour,
                            id,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                PlaceService.remove(harbour.id, id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    reload = (force?: boolean) =>
        this.reloadByHarbour(HarbourStore.getCurrentHarbour(), force);

    reloadByHarbour = (harbour: Entity | null | undefined, force?: boolean) => {
        if (harbour && !intervalIds[harbour.id]) {
            intervalIds[harbour.id] = setInterval(() => {
                this.reloadByHarbour(harbour).catch(function() {
                    clearInterval(intervalIds[harbour.id]);
                    intervalIds[harbour.id] = null;
                });
            }, RELOAD_INTERVAL);
        }

        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                if (
                    !harbour ||
                    (!force &&
                        CacheManager.isCached(
                            'Place:reloadByHarbour',
                            harbour.id.toString(),
                            CACHE_DURATION,
                        ))
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(places: Array<IPlace>): void {
                    dispatcher.dispatch({
                        type: PlaceConstants.RECEIVE_PLACES_OF_HARBOUR,
                        payload: {
                            harbour,
                            places,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                PlaceService.getByHarbour(harbour.id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };
}

export default new PlaceActions();

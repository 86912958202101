import EntityStoreBase from 'stores/EntityStoreBase';
import UserConstants from 'constants/UserConstants';
import HarbourStore from 'stores/HarbourStore';

class UserStore extends EntityStoreBase<User> {
    _harbourUsers: { [harbour_id: number]: User[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._harbourUsers = {};
    }

    _registerToActions = (action: { type: string; payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
            case UserConstants.RECEIVE_USERS_OF_HARBOUR:
                this._receiveByHarbour(payload.harbour, payload.users);
                break;
            case UserConstants.RECEIVE_USER_OF_HARBOUR:
                this._receiveOneByHarbour(payload.harbour, payload.user);
                break;
            case UserConstants.DELETE_USER_OF_HARBOUR:
                this._deleteOneByHarbour(payload.harbour, payload.id);
                break;
            case UserConstants.DELETE_USER:
                this._deleteEntity(payload.id);
                break;

            default:
                // Do Nothing
                break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, users: User[]) => {
        this._harbourUsers[harbour.id] = users;
        this._receiveSomeEntities(users);
    };

    _receiveOneByHarbour = (harbour: IHarbour, user: User) => {
        const users = this._harbourUsers[harbour.id];
        if (!users) {
            this._harbourUsers[harbour.id] = [user];
        } else {
            const i = users.findIndex(c => c.id === user.id);
            if (i > -1) {
                users[i] = user;
            } else {
                users.push(user);
            }
        }
        this._receiveEntity(user);
    };

    _deleteOneByHarbour = (harbour: IHarbour, userId: number) => {
        const users = this._harbourUsers[harbour.id];
        if (!users) {
            return;
        }

        const i = users.findIndex(c => c.id === userId);
        if (i > -1) {
            users.splice(i, 1);
            this.emitChange();
        }
    };

    // /// Public Methods  /////

    /**
     * Get all the users of a harbour.
     */
    getByHarbour = (harbour: IHarbour | null | undefined): User[] =>
        (harbour && this._harbourUsers[harbour.id]) || [];

    getForCurrentHarbour = (): User[] =>
        this.getByHarbour(HarbourStore.getCurrentHarbour());

    /**
     * Get a user by ID.
     * @param {number} id The identifier of the user.
     * @return {?User}    The user, or NULL if no entity is found.
     */
    getById = (id: number): User | undefined =>
        this._entities.find(e => e.id.toString() === id.toString());
}

export default new UserStore();

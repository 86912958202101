import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import ZoneConstants from 'constants/ZoneConstants';
import ZoneService from 'services/ZoneService';

import CacheManager from 'services/CacheManager';
import HarbourStore from 'stores/HarbourStore';

const CACHE_DURATION = 30 * 1000;

class ZoneActions extends ActionsBase {
    create = (harbour: IHarbour, zone: Object) => {
        const $this = this;
        return new Promise(
            (
                resolve: (zone: IZone) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newZone: IZone): void {
                    dispatcher.dispatch({
                        type: ZoneConstants.RECEIVE_ZONE_OF_HARBOUR,
                        payload: {
                            harbour,
                            zone: newZone,
                        },
                    });
                    resolve(newZone);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                ZoneService.post(harbour.id, zone)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    edit = (harbour: Entity, zoneId: number, zone: Object) => {
        const $this = this;
        return new Promise(
            (
                resolve: (zone: IZone) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newZone: IZone): void {
                    dispatcher.dispatch({
                        type: ZoneConstants.RECEIVE_ZONE_OF_HARBOUR,
                        payload: {
                            harbour,
                            zone: newZone,
                        },
                    });
                    resolve(newZone);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                ZoneService.patch(harbour.id, zoneId, zone)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    delete = (harbour: Entity, id: number) => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                function handleSuccess(): void {
                    dispatcher.dispatch({
                        type: ZoneConstants.DELETE_ZONE_OF_HARBOUR,
                        payload: {
                            harbour,
                            id,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                ZoneService.remove(harbour.id, id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    reload = (force?: boolean) =>
        this.reloadByHarbour(HarbourStore.getCurrentHarbour(), force);

    reloadByHarbour = (harbour: Entity | null | undefined, force?: boolean) => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                if (
                    !harbour ||
                    (!force &&
                        CacheManager.isCached(
                            'Zone:reloadByHarbour',
                            harbour.id.toString(),
                            CACHE_DURATION,
                        ))
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(zones: Array<IZone>): void {
                    dispatcher.dispatch({
                        type: ZoneConstants.RECEIVE_ZONES_OF_HARBOUR,
                        payload: {
                            harbour,
                            zones,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                ZoneService.getByHarbour(harbour.id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };
}

export default new ZoneActions();

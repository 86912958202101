import HarbourStore from 'stores/HarbourStore';
import BaseStore from 'stores/BaseStore';

import ReportingConstants from 'constants/ReportingConstants';
import moment from 'moment';

class OccupancyRateReportingStore extends BaseStore {
    _entities: { [harbour_id: number]: { main?: IOptimisationList, compared?: IOptimisationList } };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case ReportingConstants.OPTIMISATION_RECEIVED:
            this._receiveByHarbour(payload.harbour, payload.optimisation);
            break;
        default:
            // Do Nothing
            break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, optimisation: any): void => {
        if (!this._entities[harbour.id]) {
            this._entities[harbour.id] = {};
        }
        const optimisationFormatted = optimisation;
        optimisationFormatted.main.nbAbsences = Object.values(optimisationFormatted.main.nbAbsences);
        optimisationFormatted.main.period.start = moment(optimisationFormatted.main.period.start);
        optimisationFormatted.main.period.end = moment(optimisationFormatted.main.period.end);
        optimisationFormatted.compared.nbAbsences = Object.values(optimisationFormatted.compared.nbAbsences);
        optimisationFormatted.compared.period.start = moment(optimisationFormatted.compared.period.start);
        optimisationFormatted.compared.period.end = moment(optimisationFormatted.compared.period.end);
        this._entities[harbour.id] = optimisationFormatted;
        this.emitChange();
    };

    getHistory = (): { main: IOptimisationList, compared: IOptimisationList} => {
        const harbour = HarbourStore.getCurrentHarbour();
        const entities = harbour && this._entities[harbour.id];
        if (entities === undefined || entities.main === undefined) {
            return {
                main: {
                    nbAbsences: [],
                    period: {
                        start: moment(),
                        end: moment(),
                    },
                },
                compared: {
                    nbAbsences: [],
                    period: {
                        start: moment(),
                        end: moment(),
                    },
                },
            };
        }
        const historyMain = entities.main;
        const historyCompared = entities.compared;

        return {
            main: {
                nbAbsences: [...historyMain.nbAbsences],
                period: {
                    start: historyMain.period.start.clone(),
                    end: historyMain.period.end.clone(),
                },
            },
            compared: {
                nbAbsences: historyCompared ? [...historyCompared.nbAbsences] : null,
                period: {
                    start: historyCompared ? historyCompared.period.start.clone() : null,
                    end: historyCompared ? historyCompared.period.end.clone() : null,
                },
            },
        };
    };
}

export default new OccupancyRateReportingStore();

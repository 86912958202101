import React from 'react';

import { Table, Button } from 'antd/lib/index';
import TableColumnFilter from 'components/table/TableColumnFilter.jsx';
import FilterIcon from 'components/table/FilterIcon.jsx';
import CreatePlaceModal from 'pages/Configuration/CreatePlaceModal.jsx';
import EditPlaceModal from 'pages/Configuration/EditPlaceModal.jsx';
import LoadingIcon from 'components/LoadingIcon.jsx';

import Locale from 'locale/LocaleFactory';
import ArrayService from 'services/utils/ArrayService';
import StringService from 'services/utils/StringService';
import FilterService from 'services/utils/FilterService';

import PlaceActions from 'actions/PlaceActions';
import PlaceStore from 'stores/PlaceStore';
import HarbourStore from 'stores/HarbourStore';

// Sorting Methods
function sortCodeColumn(p1, p2) {
    return StringService.compareCaseInsensitive(p1.code, p2.code);
}
function sortLengthColumn(p1, p2) {
    return StringService.compareCaseInsensitive(p1.length, p2.length);
}
function sortWidthColumn(p1, p2) {
    return StringService.compareCaseInsensitive(p1.width, p2.width);
}
function sortBoatTypesColumn(b1, b2) {
    return StringService.compareCaseInsensitive(
        b1.boatTypes && b1.boatTypes.length && b1.boatTypes[0],
        b2.boatTypes && b2.boatTypes.length && b2.boatTypes[0],
    );
}
function sortPontoonColumn(p1, p2) {
    return StringService.compareCaseInsensitive(
        p1.pontoon && p1.pontoon.name,
        p2.pontoon && p2.pontoon.name,
    );
}

/**
 * The list of the places of a harbour
 */
export default class PlaceList extends React.Component {
    constructor() {
        super();

        const places = PlaceStore.getByHarbour(
            HarbourStore.getCurrentHarbour(),
        );

        this.state = {
            loading: !places.length,
            places,

            filteredPlaces: [],

            filterGlobal: '',
            filters: {
                code: [],
                boatTypes: [],
                placeLength: [],
                width: [],
                draught: [],
                pontoon: [],
            },

            createPlaceVisible: false,
            editPlaceVisible: false,
            placeToEdit: null,
        };
    }

    componentDidMount() {
        HarbourStore.addCurrentHarbourChangeListener(
            this.onChangeCurrentHarbour,
        );
        PlaceStore.addChangeListener(this.receivePlaces);

        this.reloadData();

        this.updateFilters();
        // Here we set the default sorted column
        // Temporary solution waiting for AntD to propose a native way to do it.
        const column = this.table.findColumn('reference');
        this.table.toggleSortOrder('descend', column);
    }
    componentWillUnmount() {
        HarbourStore.removeCurrentHarbourChangeListener(
            this.onChangeCurrentHarbour,
        );
        PlaceStore.removeChangeListener(this.receivePlaces);
    }

    reloadData = () => {
        this.loadPlaces();
    };

    onChangeCurrentHarbour = () => {
        this.reloadData();
        this.receivePlaces();
    };

    loadPlaces = () => {
        this.setState({
            loading: true,
        });
        PlaceActions.reloadByHarbour(HarbourStore.getCurrentHarbour()).then(
            () => {
                this.setState({
                    loading: false,
                });
            },
        );
    };

    receivePlaces = () => {
        const places = PlaceStore.getByHarbour(
            HarbourStore.getCurrentHarbour(),
        );
        this.setState({
            places,
        });
        this.updateFilters();
    };

    // Filters
    updateFilters = () => {
        const { places } = this.state;
        const filteredPlaces = places.filter(this.placeMatchFilters);
        this.setState({ filteredPlaces });
    };

    placeMatchFilters = (p) => {
        const { filters } = this.state;
        return (
            FilterService.matchFilter(filters.code, p.code) &&
            FilterService.matchFilter(filters.boatTypes, p.boatTypes) &&
            FilterService.matchFilter(filters.placeLength, p.length) &&
            FilterService.matchFilter(filters.width, p.width) &&
            FilterService.matchFilter(
                filters.pontoon,
                p.pontoon && p.pontoon.id,
            )
        );
    };

    handleFilterChange = (name, values) => {
        const newFilters = { ...this.state.filters };
        newFilters[name] = values;
        this.setState(
            {
                filters: newFilters,
            },
            this.updateFilters,
        );
    };

    getPlaceCodes = () =>
        ArrayService.unique(this.state.places.map((p) => p.code));
    getPlaceLengths = () =>
        ArrayService.unique(this.state.places.map((p) => p.length));
    getPlaceWidths = () =>
        ArrayService.unique(this.state.places.map((p) => p.width));
    getPlaceDraughts = () =>
        ArrayService.unique(this.state.places.map((p) => p.draught));
    getPlaceTypes = () =>
        ArrayService.unique(
            [].concat(...this.state.places.map((b) => b.boatTypes)),
        );

    getPlacePontoons = () =>
        ArrayService.uniqueEntity(
            [].concat(...this.state.places.map((b) => b.pontoon)),
        ).sort(sortPontoonColumn);

    showCreatePlaceModal = () => {
        this.setState({
            createPlaceVisible: true,
        });
    };
    hideCreatePlaceModal = () => {
        this.setState({
            createPlaceVisible: false,
        });
    };

    editPlace = (place) => {
        this.setState({
            editPlaceVisible: true,
            placeToEdit: place,
        });
    };
    hideEditPlaceModal = () => {
        this.setState({
            editPlaceVisible: false,
            placeToEdit: null,
        });
    };

    render() {
        const { loading, filteredPlaces } = this.state;
        return (
            <div className="places-list">
                {this.renderPlaceTable()}
                {!loading && (
                    <div
                        className="actions-row"
                        style={{
                            marginTop: filteredPlaces.length > 0 ? '-50px' : 0,
                        }}
                    >
                        <Button
                            type="primary"
                            icon="plus"
                            onClick={this.showCreatePlaceModal}
                        >
                            {Locale.trans('place.creation.title')}
                        </Button>
                    </div>
                )}

                <CreatePlaceModal
                    onCancel={this.hideCreatePlaceModal}
                    visible={this.state.createPlaceVisible}
                />
                <EditPlaceModal
                    place={this.state.placeToEdit}
                    onCancel={this.hideEditPlaceModal}
                    visible={this.state.editPlaceVisible}
                />
            </div>
        );
    }

    renderPlaceTable() {
        const { filters, filteredPlaces } = this.state;

        const columns = [
            {
                title: Locale.trans('place.code'),
                dataIndex: 'code',
                key: 'code',
                sorter: sortCodeColumn,
                filterIcon: <FilterIcon active={filters.code.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="code"
                        selectedValues={filters.code}
                        values={this.getPlaceCodes().map((r) => ({
                            text: r,
                            value: r,
                        }))}
                        onChange={this.handleFilterChange}
                    />
                ),
                width: '100px',
            },
            {
                title: Locale.trans('place.boatTypes'),
                key: 'boatTypes',
                render: this.renderBoatTypesCell,
                sorter: sortBoatTypesColumn,
                filterIcon: (
                    <FilterIcon active={filters.boatTypes.length > 0} />
                ),
                filterDropdown: (
                    <TableColumnFilter
                        name="boatTypes"
                        selectedValues={filters.boatTypes}
                        values={this.getPlaceTypes().map((r) => ({
                            text: Locale.trans(r),
                            value: r,
                        }))}
                        onChange={this.handleFilterChange}
                    />
                ),
                width: '250px',
            },
            {
                title: Locale.trans('place.length'),
                dataIndex: 'length',
                key: 'length',
                sorter: sortLengthColumn,
                filterIcon: (
                    <FilterIcon active={filters.placeLength.length > 0} />
                ),
                filterDropdown: (
                    <TableColumnFilter
                        name="placeLength"
                        selectedValues={filters.placeLength}
                        values={this.getPlaceLengths().map((r) => ({
                            text: r,
                            value: r,
                        }))}
                        onChange={this.handleFilterChange}
                    />
                ),
                width: '125px',
            },
            {
                title: Locale.trans('place.width'),
                dataIndex: 'width',
                key: 'width',
                sorter: sortWidthColumn,
                filterIcon: <FilterIcon active={filters.width.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="width"
                        selectedValues={filters.width}
                        values={this.getPlaceWidths().map((r) => ({
                            text: r,
                            value: r,
                        }))}
                        onChange={this.handleFilterChange}
                    />
                ),
                width: '125px',
            },
            {
                title: Locale.trans('place.pontoon'),
                key: 'pontoon',
                render: ({ pontoon }) => pontoon && pontoon.name,
                sorter: sortPontoonColumn,
                filterIcon: <FilterIcon active={filters.pontoon.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="pontoon"
                        selectedValues={filters.pontoon}
                        values={this.getPlacePontoons().map((p) => ({
                            text: p.name,
                            value: p.id,
                        }))}
                        onChange={this.handleFilterChange}
                    />
                ),
            },
        ];
        return (
            <Table
                dataSource={filteredPlaces}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
                ref={(r) => {
                    this.table = r;
                }}
                loading={this.state.loading && { indicator: <LoadingIcon /> }}
                onRow={(a) => ({
                    className: 'clickable',
                    onClick: () => this.editPlace(a),
                })}
            />
        );
    }

    renderBoatTypesCell = (place) =>
        place.boatTypes &&
        place.boatTypes
            .sort(StringService.compareCaseInsensitive)
            .map((type) => (
                <img
                    key={type}
                    className="boat-type-icon"
                    alt={Locale.trans(type)}
                    title={Locale.trans(type)}
                    src={`images/${type}.png`}
                    style={{ marginRight: '8px' }}
                />
            ));
}

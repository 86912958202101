import React, { useState, useEffect } from 'react';

import useHarbourModules, { ADVANCED } from 'hooks/useHarbourModules';

import { Table, Button } from 'antd/lib/index';
import CreateCameraModal from 'pages/Configuration/CreateCameraModal.jsx';
import EditCameraModal from 'pages/Configuration/EditCameraModal.jsx';
import LoadingIcon from 'components/LoadingIcon.jsx';

import Locale from 'locale/LocaleFactory';

import CameraActions from 'actions/CameraActions';
import CameraStore from 'stores/CameraStore';
import useCurrentHarbour from 'hooks/useCurrentHarbour';

const columns = [
    {
        title: Locale.trans('camera.name'),
        dataIndex: 'name',
        key: 'name',
        width: '100px',
    },
    {
        title: Locale.trans('camera.url'),
        dataIndex: 'url',
        key: 'url',
    },
    {
        title: Locale.trans('camera.type'),
        key: 'type',
        render: (camera) => Locale.trans(`camera.types.${camera.type}`),
    },
];

export default function CamerasConfiguration() {
    const modules = useHarbourModules();
    const harbour = useCurrentHarbour();
    const [cameras, setCameras] = useState(CameraStore.getByHarbour(harbour));
    const [loading, setLoading] = useState(false);
    const [createCameraVisible, setCreateCameraVisible] = useState(false);
    const [cameraToEdit, setCameraToEdit] = useState(null);

    useEffect(() => {
        function receiveCameras() {
            setCameras(CameraStore.getByHarbour(harbour));
        }
        CameraStore.addChangeListener(receiveCameras);

        setLoading(true);
        CameraActions.reloadByHarbour(harbour).finally(() => {
            setLoading(false);
        });

        return () => {
            CameraStore.removeChangeListener(receiveCameras);
        };
    }, [harbour]);

    function showCreateCameraModal() {
        setCreateCameraVisible(true);
    }
    function hideCreateCameraModal() {
        setCreateCameraVisible(false);
    }

    function hideEditCameraModal() {
        setCameraToEdit(null);
    }

    return (
        <div className="cameras-list">
            <Table
                dataSource={cameras}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
                loading={loading && { indicator: <LoadingIcon /> }}
                onRow={(a) => ({
                    className: 'clickable',
                    onClick: () => setCameraToEdit(a),
                })}
            />
            {!loading && (modules.webcam >= ADVANCED || cameras.length === 0) && (
                <div
                    className="actions-row"
                    style={{ marginTop: cameras.length > 0 ? '-50px' : 0 }}
                >
                    <Button
                        type="primary"
                        icon="plus"
                        onClick={showCreateCameraModal}
                    >
                        {Locale.trans('camera.creation.title')}
                    </Button>
                </div>
            )}

            <CreateCameraModal
                onCancel={hideCreateCameraModal}
                visible={createCameraVisible}
            />
            <EditCameraModal
                camera={cameraToEdit}
                onCancel={hideEditCameraModal}
                visible={!!cameraToEdit}
            />
        </div>
    );
}



import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import UrgencePhoneNumberService from 'services/UrgencePhoneNumberService';

import CacheManager from 'services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class UrgencePhoneNumberActions extends ActionsBase {
    create = (harbour: IHarbour, phoneNumber: Object) => {
        const $this = this;
        return new Promise((resolve: (phoneNumber: any) => void, reject: (response: Object) => void) => {
            function handleSuccess(newPhoneNumber: any) {
                dispatcher.dispatch({
                    type: 'UrgencePhoneNumber:receiveOneByHarbour',
                    payload: {
                        harbour,
                        phoneNumber: newPhoneNumber,
                    },
                });
                resolve(newPhoneNumber);
            }

            function handleError(err: Object) {
                $this.handleError(err, reject);
            }

            UrgencePhoneNumberService.post(harbour.id, phoneNumber)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (harbour: Entity, phoneNumberId: number, phoneNumber: Object) => {
        const $this = this;
        return new Promise((resolve: (phoneNumber: any) => void, reject: (response: Object) => void) => {
            function handleSuccess(newPhoneNumber: any) {
                dispatcher.dispatch({
                    type: 'UrgencePhoneNumber:receiveOneByHarbour',
                    payload: {
                        harbour,
                        phoneNumber: newPhoneNumber,
                    },
                });
                resolve(newPhoneNumber);
            }

            function handleError(err: Object) {
                $this.handleError(err, reject);
            }

            UrgencePhoneNumberService.patch(harbour.id, phoneNumberId, phoneNumber)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    delete = (harbour: Entity, id: number) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void) => {
            function handleSuccess() {
                dispatcher.dispatch({
                    type: 'UrgencePhoneNumber:deleteOneByHarbour',
                    payload: {
                        harbour,
                        id,
                    },
                });
                resolve();
            }

            function handleError(err: Object) {
                $this.handleError(err, reject);
            }

            UrgencePhoneNumberService.remove(harbour.id, id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByHarbour = (harbour: Entity) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void) => {
            if (
                CacheManager.isCached(
                    'UrgencePhoneNumber:reloadByHarbour',
                    harbour.id.toString(),
                    CACHE_DURATION,
                )
            ) {
                resolve();
                return;
            }

            function handleSuccess(phoneNumbers: Array<any>) {
                dispatcher.dispatch({
                    type: 'UrgencePhoneNumber:receiveByHarbour',
                    payload: {
                        harbour,
                        phoneNumbers,
                    },
                });
                resolve();
            }

            function handleError(err: Object) {
                $this.handleError(err, reject);
            }

            UrgencePhoneNumberService.getByHarbour(harbour.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

export default new UrgencePhoneNumberActions();

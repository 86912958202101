import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import BoatCategoryService from 'services/BoatCategoryService';

import CacheManager from 'services/CacheManager';
import HarbourStore from 'stores/HarbourStore';
import BoatCategoryConstants from 'constants/BoatCategoryConstants';

const CACHE_DURATION = 30 * 1000;

class BoatCategoryActions extends ActionsBase {
    reloadByHarbour = (harbour: Entity | null | undefined) => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                if (
                    !harbour ||
                    CacheManager.isCached(
                        'BoatCategory:reloadByHarbour',
                        harbour.id.toString(),
                        CACHE_DURATION,
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(categories: Array<IBoatCategory>): void {
                    dispatcher.dispatch({
                        type: BoatCategoryConstants.RECEIVE_BOAT_CATEGORY,
                        payload: {
                            harbour,
                            categories,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                BoatCategoryService.getByHarbour(harbour.id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    reloadForCurrentHarbour = () =>
        this.reloadByHarbour(HarbourStore.getCurrentHarbour());

    create = (category: IBoatCategory) => {
        const harbour = HarbourStore.getCurrentHarbour();
        const $this = this;
        return new Promise(
            (
                resolve: (category: IBoatCategory) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newCategory: IBoatCategory): void {
                    dispatcher.dispatch({
                        type: BoatCategoryConstants.RECEIVE_ONE_BOAT_CATEGORY,
                        payload: {
                            harbour,
                            category: newCategory,
                        },
                    });

                    resolve(newCategory);
                }
                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }
                if (harbour) {
                    BoatCategoryService.post(harbour.id, category)
                        .then(handleSuccess)
                        .catch(handleError);
                }
            },
        );
    };

    edit = (categoryId: number, category: IBoatCategory) => {
        const harbour = HarbourStore.getCurrentHarbour();
        const $this = this;
        return new Promise(
            (
                resolve: (category: User) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newCategory: User): void {
                    dispatcher.dispatch({
                        type: BoatCategoryConstants.RECEIVE_ONE_BOAT_CATEGORY,
                        payload: {
                            harbour,
                            category: newCategory,
                        },
                    });
                    resolve(newCategory);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                if (harbour) {
                    BoatCategoryService.patch(harbour.id, categoryId, category)
                        .then(handleSuccess)
                        .catch(handleError);
                }
            },
        );
    };
}

export default new BoatCategoryActions();

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL = `${BaseUrlConstants.BASE_URL}`;

class HarbourMapPositionService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get the map of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/map/positions?platform=web`,
            method: 'GET',
        });
    }

    /**
     * Post a new position to a harbour map.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The position to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/map/positions`,
            method: 'POST',
            data: {
                ...resource,
                platform: 'web'
            },
        });
    }

    /**
     * Delete an existing map position.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} id The identifier of the position.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, id: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/map/positions/${id}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} id The identifier of the position.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, id: number, patch: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/map/positions/${id}`,
            method: 'PATCH',
            data: patch,
        });
    }
}

export default new HarbourMapPositionService();

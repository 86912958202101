import { useEffect, useState } from 'react';
import Logger from 'services/Logger';

const useStore = (actions) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const {get, load, register} = actions;
    useEffect(() => {
        function refresh() {
            Logger.debug('[useStore], refreshed');
            setLoading(false);
            get && setItems(get());
        }
        setLoading(true);
        refresh();
        load && load();
        if (register) {
            const temp = register(refresh);
            return () => temp.remove();
        }
        return null;
    }, [get, load, register]);

    // the "..." allows to use the "add" function the exact same way as the store wants it.
    // ie: add(harbour, notification, user)
    const add = (...items) => {
        setLoading(true);
        return actions.add && actions.add(...items)
            .then(() => {
                setLoading(false);
                actions.onSuccess && actions.onSuccess();
            })
            .catch(() => {
                setLoading(false);
                actions.onError && actions.onError();
            });
    };
    return [[...items], add, loading];
};
export default useStore

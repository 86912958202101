import React from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';

import IncidentConstants from 'constants/IncidentConstants';

class IncidentForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const incident = this.getEntityFromValues(values);

                if (incident.zone.startsWith('zone')) {
                    const split = incident.zone.split('-');
                    incident.zone = split[1];
                } else if (incident.zone.startsWith('place')) {
                    const split = incident.zone.split('-');
                    incident.place = split[1];
                    delete incident.zone;
                } else {
                    delete incident.zone;
                }

                this.props.onSubmit(incident);
            }
        });
    };

    getEntityFromValues = (values) => {
        const boat = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.map((k) => {
            if (k.endsWith('_ids')) {
                return null;
            }
            if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                boat[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                boat[k] = values[k];
            }
            return null;
        });
        return boat;
    };

    render() {
        const { loading, form } = this.props;
        const { getFieldValue } = form;
        return (
            <Form onSubmit={this.handleSubmit}>
                {this.renderIncidentTypeField()}
                {this.renderZoneField()}
                <FormItem.TextArea
                    id="comments"
                    label={Locale.trans('incident.comments')}
                    initialValue={getFieldValue('comments')}
                    form={form}
                    validateStatus={this.getValidateStatus('comments')}
                    help={this.getHelp('comments')}
                    hasFeedback
                />
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }
    renderIncidentTypeField() {
        const { form } = this.props;
        const { getFieldValue } = form;

        const incidentTypes = [
            IncidentConstants.FIRE,
            IncidentConstants.SINK,
            IncidentConstants.THEFT,
            IncidentConstants.ELECTRIC,
            IncidentConstants.WATER,
            IncidentConstants.POLUTION,
            IncidentConstants.LIGHTING,
            IncidentConstants.SANITARY,
            IncidentConstants.SOUND,
            IncidentConstants.BROKEN_BOARD,
            IncidentConstants.COLLISION,
            IncidentConstants.HARBOUR_MAP,
            IncidentConstants.EXTINGUISHER,
            IncidentConstants.CHAIN,
            IncidentConstants.LADDER,
            IncidentConstants.TOWING,
            IncidentConstants.MOORING,
            IncidentConstants.OTHER,
        ];

        const options = incidentTypes.map(t => ({
            value: t,
            label: Locale.trans(`incident.types.${t}`),
        }));

        return (
            <React.Fragment>
                <FormItem.Select
                    id="incidentType"
                    required
                    showSearch
                    label={Locale.trans('incident.type')}
                    initialValue={getFieldValue('incidentType')}
                    options={options}
                    form={form}
                    validateStatus={this.getValidateStatus('incidentType')}
                    help={this.getHelp('incidentType')}
                    hasFeedback
                />
                {getFieldValue('incidentType') === IncidentConstants.OTHER && (
                    <FormItem.Input
                        id="comment"
                        label={Locale.trans('incident.comment')}
                        initialValue={getFieldValue('comment')}
                        form={form}
                        validateStatus={this.getValidateStatus('comment')}
                        help={this.getHelp('comment')}
                        hasFeedback
                    />
                )}
            </React.Fragment>
        );
    }
    renderZoneField() {
        const { zones, places, form } = this.props;
        const { getFieldValue } = form;

        let initialValue = null;
        if (getFieldValue('zone')) {
            initialValue = `zone-${getFieldValue('zone').id.toString()}`;
        } else if (getFieldValue('place')) {
            initialValue = `place-${getFieldValue('place').id.toString()}`;
        }

        const zoneOptions = zones.map(z => ({
            value: `zone-${z.id}`,
            label: z.name,
        }));

        const placeOptions = places.map(p => ({
            value: `place-${p.id}`,
            label: p.code,
        }));

        const options = zoneOptions.concat(placeOptions);

        return (
            <FormItem.Select
                id="zone_id"
                required
                showSearch
                label={Locale.trans('incident.zone')}
                initialValue={initialValue}
                options={options}
                form={form}
                validateStatus={this.getValidateStatus('zone_id')}
                help={this.getHelp('zone_id')}
                hasFeedback
            />
        );
    }
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(IncidentForm);

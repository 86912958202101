import React from 'react';
import FormItemBase from 'components/form-items/FormItemBase.jsx';
import { Form, Input } from 'antd';

export default class TextAreaFormItem extends FormItemBase {
    render() {
        const { id, initialValue, readOnly } = this.props;
        // Form.Item extra props
        const {
            label,
            labelCol,
            wrapperCol,
            extra,
            validateStatus,
            help,
            rows,
        } = this.props;
        // Input props
        const {
            placeholder,
            autosize,
            disabled,
            maxLength,
            onChange,
            form,
            className,
            value,
        } = this.props;
        if (!form) {
            return (
                <Form.Item
                    validateStatus={validateStatus}
                    help={help}
                    hasFeedback
                    label={label}
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                    extra={extra}
                >
                    <Input.TextArea
                        placeholder={placeholder}
                        autosize={autosize}
                        disabled={disabled}
                        readOnly={readOnly}
                        maxLength={maxLength}
                        onChange={onChange ? e => onChange(e.target.value) : null}
                        ref={(n) => { this.input = n; }}
                        className={className}
                        value={!form ? value || undefined : undefined}
                        rows={rows}
                    />
                </Form.Item>
            );
        }

        return (
            <Form.Item
                validateStatus={validateStatus || this.getValidateStatus(id)}
                help={help || this.getHelp(id)}
                hasFeedback
                label={label}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                extra={extra}
            >
                {this.getFieldDecorator(id, {
                    initialValue,
                    rules: this.getRules(),
                })(
                    <Input.TextArea
                        placeholder={placeholder}
                        autosize={autosize}
                        disabled={disabled}
                        readOnly={readOnly}
                        maxLength={maxLength}
                        onChange={onChange ? e => onChange(e.target.value) : null}
                        ref={(n) => { this.input = n; }}
                        className={className}
                        rows={rows}
                    />
                )}
            </Form.Item>
        );
    }
}

import React from 'react';
import { Modal } from 'antd';

import AnticipatedReturnForm from 'components/forms/AnticipatedReturnForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import AbsenceActions from 'actions/AbsenceActions';
import AbsenceStore from 'stores/AbsenceStore';

import BoatActions from 'actions/BoatActions';
import BoatStore from 'stores/BoatStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to create a new anticipated return.
 */
export default class CreateAnticipatedReturnModal extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {},
            boats: BoatStore.getByHarbour(HarbourStore.getCurrentHarbour()),
            absences: AbsenceStore.getByHarbour(
                HarbourStore.getCurrentHarbour(),
            ),
        };
    }

    componentDidMount() {
        BoatStore.addChangeListener(this.receiveBoats);
        BoatActions.reloadByHarbour(HarbourStore.getCurrentHarbour());

        AbsenceStore.addChangeListener(this.receiveAbsences);
        AbsenceActions.reloadByHarbour(HarbourStore.getCurrentHarbour(), true);
    }
    componentWillUnmount() {
        BoatStore.removeChangeListener(this.receiveBoats);
        AbsenceStore.removeChangeListener(this.receiveAbsences);
    }

    receiveBoats = () => {
        const boats = BoatStore.getByHarbour(HarbourStore.getCurrentHarbour());
        this.setState({
            boats,
        });
    };

    receiveAbsences = () => {
        const absences = AbsenceStore.getByHarbour(
            HarbourStore.getCurrentHarbour(),
        );
        this.setState({
            absences,
        });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (a) => {
        this.setState({
            loading: true,
        });
        const absence = {
            boat: a.boat,
            anticipatedReturnDate: {
                year: a.anticipatedReturnDate.year(),
                month: a.anticipatedReturnDate.month() + 1,
                day: a.anticipatedReturnDate.date(),
            },
        };
        AbsenceActions.edit(
            HarbourStore.getCurrentHarbour(),
            a.absence,
            absence,
        )
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('absence.anticipatedReturn.creation.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        return (
            <Modal
                title={Locale.trans('absence.anticipatedReturn.creation.title')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                footer={null}
            >
                <AnticipatedReturnForm
                    absences={this.state.absences}
                    boats={this.state.boats}
                    onChange={this.handleFormChange}
                    fields={this.state.fields}
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                />
            </Modal>
        );
    }
}

import React from 'react';
import { Row, Col } from 'antd';

import LoginForm from 'components/forms/LoginForm.jsx';
import AuthService from 'services/AuthService';
import FormService from 'services/utils/FormService';

export default class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {},
        };
    }

    login = (username, password) => {
        this.setState({
            loading: true,
        });
        AuthService.login(username, password)
            .then(() => {
                this.props.history.push('/');
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    render() {
        return (
            <Row
                type="flex"
                align="middle"
                justify="center"
                className="login-page"
                style={{ backgroundImage: 'url("images/login-background.jpg")' }}
            >
                <Col style={{minWidth: 280}} xs={14} sm={8} md={6} lg={4} xl={4}>
                    <img alt="Logo" src="images/logo.png" style={{ width: '100%', paddingBottom: '1rem' }} />
                    <LoginForm
                        onChange={this.handleFormChange}
                        fields={this.state.fields}
                        onLogin={this.login}
                        loading={this.state.loading}
                    />
                </Col>
            </Row>
        );
    }
}

import React from 'react';
import moment from 'moment';
import { Form, DatePicker, Select } from 'antd';

import Locale from 'locale/LocaleFactory';

import FormBase from 'components/forms/FormBase';
import BoatStore from 'stores/BoatStore';
import BoatActions from 'actions/BoatActions';
import BoatCategoryStore from 'stores/BoatCategoryStore';
import UserStore from 'stores/UserStore';
import FormItem from 'components/forms/FormItems';

import UserActions from 'actions/UserActions';
import ZoneStore from 'stores/ZoneStore';
import PlaceActions from 'actions/PlaceActions';
import PlaceStore from 'stores/PlaceStore';
import ZoneActions from 'actions/ZoneActions';
import SelectFormItem from '../form-items/SelectFormItem';

class ReportingFiltersForm extends FormBase {
    constructor() {
        super();
        this.state = {
            boatTypes: [],
            categories: [],
            users: [],
            places: [],
            zones: [],
        };
    }

    componentDidMount() {
        this.props.form.validateFields();
        BoatStore.addChangeListener(this.reloadBoats);
        BoatActions.reloadForCurrentHarbour();
        
        BoatCategoryStore.addChangeListener(this.changeCategories);
        
        UserStore.addChangeListener(this.changeUsers);
        UserActions.reload();
        
        PlaceStore.addChangeListener(this.changePlaces);
        PlaceActions.reload(false);
        
        ZoneStore.addChangeListener(this.changeZones);
        ZoneActions.reload(false);
        this.changeCategories();
        this.reloadBoats();
    }

    componentWillUnmount() {
        BoatStore.removeChangeListener(this.receiveBoats);
        BoatCategoryStore.removeChangeListener(this.receiveBoats);
        UserStore.removeChangeListener(this.changeUsers);
        PlaceStore.removeChangeListener(this.receivePlaces);
        ZoneStore.removeChangeListener(this.changeZone);
    }

    changeCategories = () => {
        this.setState({
            categories: BoatCategoryStore.getForCurrentHarbour(),
        });
    };

    changeUsers = () => {
        this.setState({
            users: UserStore.getForCurrentHarbour(),
        });
    }
    
    changePlaces = () => {
        this.setState({
            places: PlaceStore.getForCurrentHarbour(),
        });
    }

    changeZones = () => {
        this.setState({
            zones: ZoneStore.getForCurrentHarbour(),
        });
    }

    reloadBoats = () => {
        this.setState({
            boatTypes: BoatStore.getAllTypes(),
        });
    };

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const {
            boatTypes,
            categories,
            users,
            places,
            zones,
        } = this.state;
        const years = [];
        const now = moment();
        const start = moment().subtract(5, 'year');
        while (start < now) {
            years.push(start.year());
            start.add(1, 'year');
        }
        return (
            <Form>
                <h3>{Locale.trans('reporting.filters.labels.period_title')}</h3>
                <Form.Item
                    label={Locale.trans('reporting.filters.labels.period')}
                >
                    {getFieldDecorator('period', {
                        rules: [{
                            type: 'array',
                            required: true,
                            message: Locale.trans('reporting.filters.date'),
                        }],
                    })(
                        <DatePicker.RangePicker
                            format="DD/MM/YYYY"
                        />
                    )}
                </Form.Item>
                <SelectFormItem
                    id="yearCompared"
                    label={Locale.trans('reporting.filters.labels.compare')}
                    rules={{
                        rules: [{
                            type: 'number',
                            required: false,
                            message: Locale.trans('reporting.filters.year'),
                        }],
                    }}
                    form={form}
                    options={years.map(year => ({ value: year, label: year }))}
                />
                <h3>{Locale.trans('reporting.filters.title')}</h3>
                <SelectFormItem
                    id="user"
                    allowClear
                    label={Locale.trans('reporting.filters.labels.users')}
                    options={users.map(user => ({
                        value: user.id,
                        label: `${user.firstName} ${user.lastName}`,
                    }))}
                    form={this.props.form}
                    mode="tags"
                />
                <Form.Item
                    label={Locale.trans('reporting.filters.labels.places_zones')}
                >
                    {getFieldDecorator('place', {
                        rules: [{
                            required: false,
                        }],
                    })(<Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Select.OptGroup label={Locale.trans('reporting.filters.labels.zones')}>
                            {zones.map(zone => (
                                <Select.Option value={`Z${zone.id}`} key={`z${zone.id}`}>
                                    {zone.name}
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                        <Select.OptGroup label={Locale.trans('reporting.filters.labels.places')}>
                            {places.map(place => (
                                <Select.Option value={`${place.id}`} key={place.id}>
                                    {place.code}
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                    </Select>)}
                </Form.Item>
                <FormItem.Select
                    id="boatType"
                    allowClear
                    label={Locale.trans('reporting.filters.labels.types')}
                    options={boatTypes.map(type => ({
                        value: type,
                        label: type,
                    }))}
                    form={this.props.form}
                    mode="tags"
                />
                <FormItem.Select
                    id="categorie"
                    allowClear
                    label={Locale.trans('reporting.filters.labels.category')}
                    options={categories.map(type => ({
                        value: type.id,
                        label: type.name,
                    }))}
                    form={this.props.form}
                />
            </Form>
        );
    }
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);

        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(ReportingFiltersForm);



import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import CameraService from 'services/CameraService';

import CacheManager from 'services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class CameraActions extends ActionsBase {
    create = (harbour: IHarbour, camera: Object) => {
        const $this = this;
        return new Promise((resolve: (camera: ICamera) => void, reject: (response: Object) => void): void => {
            function handleSuccess(newCamera: ICamera): void {
                dispatcher.dispatch({
                    type: 'Camera:receiveOneByHarbour',
                    payload: {
                        harbour,
                        camera: newCamera,
                    },
                });
                resolve(newCamera);
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            CameraService.post(harbour.id, camera)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (harbour: Entity, cameraId: number, camera: Object) => {
        const $this = this;
        return new Promise((resolve: (camera: ICamera) => void, reject: (response: Object) => void): void => {
            function handleSuccess(newCamera: ICamera): void {
                dispatcher.dispatch({
                    type: 'Camera:receiveOneByHarbour',
                    payload: {
                        harbour,
                        camera: newCamera,
                    },
                });
                resolve(newCamera);
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            CameraService.patch(harbour.id, cameraId, camera)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    delete = (harbour: Entity, id: number) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            function handleSuccess(): void {
                dispatcher.dispatch({
                    type: 'Camera:deleteOneByHarbour',
                    payload: {
                        harbour,
                        id,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            CameraService.remove(harbour.id, id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByHarbour = (harbour: Entity) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            if (
                CacheManager.isCached(
                    'Camera:reloadByHarbour',
                    harbour.id.toString(),
                    CACHE_DURATION,
                )
            ) {
                resolve();
                return;
            }

            function handleSuccess(cameras: Array<ICamera>): void {
                dispatcher.dispatch({
                    type: 'Camera:receiveByHarbour',
                    payload: {
                        harbour,
                        cameras,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            CameraService.getByHarbour(harbour.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

export default new CameraActions();

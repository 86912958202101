import React from 'react';
import { Button, Col, Form, Row, Table } from 'antd';
import FormBase from 'components/forms/FormBase';
import FormItem from 'components/forms/FormItems';
import Locale from 'locale/LocaleFactory';
import CheckboxFormItem from 'components/form-items/CheckboxFormItem';
import FormService from 'services/utils/FormService';
import SelectFormItem from 'components/form-items/SelectFormItem';

class IncidentConfigurationForm extends FormBase {
    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const entity = this.getEntityFromValues(values);
                this.props.onSubmit(entity);
            }
        });
    };

    getEntityFromValues = (values) => {
        const configurations = [];

        const keys = Object.keys(values);
        keys.forEach((key) => {
            const value = values[key];
            if (
                !key.startsWith('visibility') &&
                !key.startsWith('email') &&
                !key.startsWith('isCritical') &&
                !key.startsWith('criticalPhoneNumber')
            ) {
                return;
            }
            const id = key.match(/[1-90]*$/)[0];
            configurations[id] = {
                id,
                ...configurations[id],
            };
            configurations[id][key.replace(id, '')] = value;
        });
        return {
            securityEmail: values.securityEmail,
            hourBeginDay:
                values.hourBeginDay && values.hourBeginDay.format('HH:mm'),
            hourEndDay: values.hourEndDay && values.hourEndDay.format('HH:mm'),
            configurations: configurations.filter((conf) => !!conf),
        };
    };

    render() {
        const { incidents, loading, form } = this.props;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Row gutter={12}>
                    <Col span={8}>
                        <FormItem.Input
                            id="securityEmail"
                            required
                            label={Locale.trans('incident.notificationEmail')}
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'securityEmail',
                            )}
                            help={this.getHelp('securityEmail')}
                            hasFeedback
                        />
                    </Col>
                    <Col span={6} />
                    <Col span={4}>
                        <Row gutter={12}>
                            <Col span={12}>
                                <FormItem.TimePicker
                                    id="hourBeginDay"
                                    label={Locale.trans('harbour.hourBeginDay')}
                                    form={form}
                                    format="HH:mm"
                                    placeholder={null}
                                />
                            </Col>
                            <Col span={12}>
                                <FormItem.TimePicker
                                    id="hourEndDay"
                                    label={Locale.trans('harbour.hourEndDay')}
                                    form={form}
                                    format="HH:mm"
                                    placeholder={null}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.renderSafety(incidents)}
                {this.renderSecurity(incidents)}
                {this.renderMaintenance(incidents)}
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }

    renderTable(data) {
        return (
            <Table
                pagination={false}
                dataSource={data}
                columns={[
                    {
                        key: 'actions',
                        title: Locale.trans('incident.visibility'),
                        width: '30px',
                        render: (incident) => (
                            <SelectFormItem
                                id={`visibility${incident.id}`}
                                form={this.props.form}
                                validateStatus={this.getValidateStatus(
                                    `visibility${incident.id}`,
                                )}
                                help={this.getHelp(`visibility${incident.id}`)}
                                hasFeedback
                                options={['none', 'captain', 'all'].map(
                                    (v) => ({
                                        value: v,
                                        label: Locale.trans(
                                            `incident.visibility.${v}`,
                                        ),
                                    }),
                                )}
                            />
                        ),
                    },
                    {
                        key: 'incident',
                        width: '200px',
                        title: Locale.trans('incident.name'),
                        render: (incident) => (
                            <React.Fragment>
                                <div className="incident-icon">
                                    <img
                                        src={incident.category.image}
                                        alt={incident.category.name}
                                    />
                                </div>
                                {Locale.trans(
                                    `incident.types.${incident.category.name}`,
                                )}
                            </React.Fragment>
                        ),
                    },
                    {
                        key: 'emails',
                        title: Locale.trans('incident.email'),
                        width: '400px',
                        render: (incident) => (
                            <FormItem.Input
                                id={`email${incident.id}`}
                                form={this.props.form}
                                validateStatus={this.getValidateStatus(
                                    `email${incident.id}`,
                                )}
                                help={this.getHelp(`email${incident.id}`)}
                                hasFeedback
                            />
                        ),
                    },
                    {
                        key: 'isCritical',
                        title: Locale.trans('incident.isCritical'),
                        width: '70px',
                        render: (incident) => (
                            <CheckboxFormItem
                                id={`isCritical${incident.id}`}
                                form={this.props.form}
                                validateStatus={this.getValidateStatus(
                                    `isCritical${incident.id}`,
                                )}
                                help={this.getHelp(`isCritical${incident.id}`)}
                                hasFeedback
                                style={{ textAlign: 'center' }}
                            />
                        ),
                    },
                    {
                        key: 'criticalPhoneNumberDay',
                        width: '250px',
                        title: Locale.trans('incident.criticalPhoneNumber.day'),
                        render: (incident) =>
                            this.props.form.getFieldValue(
                                `isCritical${incident.id}`,
                            ) && (
                                <FormItem.Input
                                    id={`criticalPhoneNumberDay${incident.id}`}
                                    form={this.props.form}
                                    validateStatus={this.getValidateStatus(
                                        `criticalPhoneNumberDay${incident.id}`,
                                    )}
                                    help={this.getHelp(
                                        `criticalPhoneNumberDay${incident.id}`,
                                    )}
                                    hasFeedback
                                />
                            ),
                    },
                    {
                        key: 'criticalPhoneNumberNight',
                        width: '250px',
                        title: Locale.trans(
                            'incident.criticalPhoneNumber.night',
                        ),
                        render: (incident) =>
                            this.props.form.getFieldValue(
                                `isCritical${incident.id}`,
                            ) && (
                                <FormItem.Input
                                    id={`criticalPhoneNumberNight${incident.id}`}
                                    form={this.props.form}
                                    validateStatus={this.getValidateStatus(
                                        `criticalPhoneNumberNight${incident.id}`,
                                    )}
                                    help={this.getHelp(
                                        `criticalPhoneNumberNight${incident.id}`,
                                    )}
                                    hasFeedback
                                />
                            ),
                    },
                    {
                        key: 'none',
                        render: () => null,
                    },
                ]}
            />
        );
    }

    renderSecurity(incidents) {
        return (
            <div className="incident-category">
                <h3>{Locale.trans('incident.safety')}</h3>
                {this.renderTable(
                    incidents.filter(
                        (incident) => incident.category.category === 'safety',
                    ),
                )}
            </div>
        );
    }

    renderSafety(incidents) {
        return (
            <div className="incident-category">
                <h3>{Locale.trans('incident.security')}</h3>
                {this.renderTable(
                    incidents.filter(
                        (incident) => incident.category.category === 'security',
                    ),
                )}
            </div>
        );
    }

    renderMaintenance(incidents) {
        return (
            <div className="incident-category">
                <h3>{Locale.trans('incident.maintenance')}</h3>
                {this.renderTable(
                    incidents.filter(
                        (incident) => incident.category.category === 'maintenance',
                    ),
                )}
            </div>
        );
    }

}

export default FormService.create(IncidentConfigurationForm);

import React from 'react';
import { Col, Row } from 'antd';
import WeatherForecast from './Communication/WeatherForecast';
import Notifications from './Communication/Notifications';
import CreateCommunication from './Communication/CreateCommunication';

import CommunicationContext from './Communication/CommunicationContext';
import useHarbourModules, { ADVANCED } from 'hooks/useHarbourModules';

const gutter = 12;

export default function Communication() {
    const modules = useHarbourModules();

    return (
        <CommunicationContext.Provider>
            <div id="communication-page">
                <Row gutter={gutter}>
                    <Col
                        span={12}
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                        }}
                    >
                        {modules.weather >= ADVANCED && <WeatherForecast />}
                        <Notifications />
                    </Col>
                    <Col span={12}>
                        <CreateCommunication />
                    </Col>
                </Row>
            </div>
        </CommunicationContext.Provider>
    );
}

import BaseStore from 'stores/BaseStore';
import ReportingConstants from 'constants/ReportingConstants';
import HarbourStore from 'stores/HarbourStore';

class OutingByBoatReportingStore extends BaseStore {
    _entities: { [harbour_id: number]: Array<any> };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string; payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
            case ReportingConstants.OUTINGS_BY_BOAT_RECEIVED:
                this._receiveOutings(payload.harbour, payload.outings);
                break;
            default:
                // Do Nothing
                break;
        }
    };
    _receiveOutings = (harbour: IHarbour, outings: any) => {
        if (typeof this._entities[harbour.id] === 'undefined') {
            this._entities[harbour.id] = [];
        }
        this._entities[harbour.id] = this._formatOutings(outings);
        this.emitChange();
    };

    _formatOutings(outings: any) {
        return Object.values(outings.boats);
    }

    getOutings(harbour?: IHarbour) {
        const id = harbour ? harbour.id : HarbourStore.getCurrentHarbourId();
        return (id && this._entities[id]) || [];
    }
}

export default new OutingByBoatReportingStore();

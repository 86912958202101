import React from "react";
import OccupancyRateReportingStore from 'stores/OccupancyRateReportingStore';
import { Icon, Tooltip } from 'antd';
import Locale from 'locale/LocaleFactory';
import LoadingChart from 'components/charts/LoadingChart';
import MathService from "services/utils/MathService";

export default class OccupancyRate extends React.Component {
    constructor() {
        super();
        this.state = {
            mean: 0,
            meanCompared: 0,
            nbPlaces: 1,
            nbPlacesCompared: 0,
        };
    }

    componentDidMount() {
        OccupancyRateReportingStore.addChangeListener(this.receiveData);
    }

    receiveData = () => {
        const data = OccupancyRateReportingStore.getMean();
        this.setState({
            mean: data.meanEmpty,
            meanCompared: data.meanEmptyCompared,
            nbPlaces: data.nbPlaces,
            nbPlacesCompared: data.nbPlacesCompared,
        });
    };

    render() {
        const { mean, nbPlaces } = this.state;
        const percent = ((nbPlaces - mean) / nbPlaces) * 100;
        return (
            <div className="chart reporting occupancy">
                <LoadingChart percent={percent} precision={1} />
                <div className="loading-chart_numbers">
                    <div>
                        {nbPlaces} places
                        <br />
                        <Tooltip title={Locale.trans('reporting.occupancy.placesUsed')}>
                            <strong className="main">
                                {MathService.round(nbPlaces - mean, 0)}
                            </strong>
                        </Tooltip> / <Tooltip title={Locale.trans('reporting.occupancy.placesEmpty')}>
                            <strong className="empty">
                                {MathService.round(mean, 0)}
                            </strong>
                        </Tooltip>
                    </div>
                    {this.renderEvolution(percent)}
                </div>
            </div>
        );
    }

    renderEvolution(percent) {
        const { meanCompared, nbPlacesCompared } = this.state;
        const percentCompared = ((nbPlacesCompared - meanCompared) / nbPlacesCompared) * 100;
        if (isNaN(percentCompared)) {
            return (
                <div>
                    {Locale.trans('comparaison impossible')}
                </div>
            );
        }
        const delta = percent - percentCompared;
        return (
            <div>
                {delta > 0 ? <Icon type="caret-up" /> : <Icon type="caret-down" />}{' '}
                {MathService.format(delta, 1)}% ce mois-ci
            </div>
        );
    }
};

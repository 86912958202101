import React from 'react';
import { Modal } from 'antd';

import YachtsmanUserForm from 'components/forms/YachtsmanUserForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import UserActions from 'actions/UserActions';
import HarbourStore from 'stores/HarbourStore';

import BoatActions from 'actions/BoatActions';
import BoatStore from 'stores/BoatStore';
import DeleteButton from 'components/forms/DeleteButton';
import LoginStore from 'stores/LoginStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to edit a yachtsman.
 */
export default class EditYachtsmanModal extends React.Component {
    newUser = null;
    totalActions = 0;
    completedActions = 0;

    constructor(props) {
        super();
        this.state = {
            loading: false,
            fields: this.getFieldsFromEntity(props.boat),
            boats: BoatStore.getByHarbour(HarbourStore.getCurrentHarbour()),
            loadingDelete: false,
        };
    }

    componentDidMount() {
        BoatStore.addChangeListener(this.receiveBoats);
        BoatActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    }
    componentWillUnmount() {
        BoatStore.removeChangeListener(this.receiveBoats);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            this.setState({
                fields: this.getFieldsFromEntity(nextProps.user),
            });
        }
    }

    getFieldsFromEntity = (entity) => {
        if (!entity) {
            return {};
        }
        const fields = {};
        const keys = Object.keys(entity);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            fields[k] = { value: entity[k] };
        }
        return fields;
    };

    receiveBoats = () => {
        this.setState({
            boats: BoatStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (user, boats) => {
        this.newUser = null;
        this.totalActions = 0;
        this.completedActions = 0;

        if (!this.props.user) {
            return null;
        }
        const userId = this.props.user.id;

        this.setState({
            loading: true,
        });
        UserActions.edit(HarbourStore.getCurrentHarbour(), userId, user)
            .then((newUser) => {
                this.newUser = newUser;

                boats.created.map((b) => {
                    return this._addBoat(userId, b);
                });
                boats.deleted.map((b) => {
                    return this._deleteBoat(userId, b);
                });

                this.tryToComplete();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                    loadingDelete: false,
                });
            });
    };

    // Boat
    _addBoat = (userId, boat) => {
        this.totalActions++;
        BoatActions.addOwner(HarbourStore.getCurrentHarbour(), boat.id, userId)
            .then(() => {
                this.completedActions++;
                this.tryToComplete();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                    loadingDelete: false,
                });
            });
    };

    _deleteBoat = (userId, boat) => {
        this.totalActions++;
        BoatActions.removeOwner(
            HarbourStore.getCurrentHarbour(),
            boat.id,
            userId,
        )
            .then(() => {
                this.completedActions++;
                this.tryToComplete();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                    loadingDelete: false,
                });
            });
    };

    /**
     * Check if all the things that have to be created are done. (ex: Phones, Mail, etc.)
     * If yes, we complete the process with toast to the user and redirection to Edit page.
     */
    tryToComplete = () => {
        if (!this.newUser || this.completedActions < this.totalActions) {
            return;
        }

        this.setState({
            fields: {},
            loading: false,
        });
        ToastActions.createToastSuccess(
            Locale.trans('yachtsman.update.success', {
                name: this.newUser.firstName,
            }),
        );
        this.props.onCancel();
    };

    deleteUser = () => {
        const { user, onCancel } = this.props;

        const userId = user.id;

        this.setState({
            loadingDelete: true,
        });
        UserActions.delete(HarbourStore.getCurrentHarbour(), userId)
            .then(() => {
                ToastActions.createToastSuccess(
                    Locale.trans('user.delete.success'),
                );
                onCancel();
            })
            .catch(() => {
                ToastActions.createToastError(
                    Locale.trans('user.delete.error'),
                );
            })
            .finally(() => {
                this.setState({
                    loading: false,
                    loadingDelete: false,
                });
            });
    };

    render() {
        const { user, visible, onCancel } = this.props;
        const { fields, boats, loading, loadingDelete } = this.state;
        if (!user) {
            return null;
        }
        const loggedUser = LoginStore.getUser();
        return (
            <Modal
                title={Locale.trans('yachtsman.update.title')}
                visible={user && visible}
                onCancel={onCancel}
                footer={null}
            >
                <YachtsmanUserForm
                    user={user}
                    boats={boats}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                />
                {loggedUser.isSuperAdmin && (
                    <DeleteButton
                        onDelete={this.deleteUser}
                        loading={loadingDelete}
                        style={{ width: '100%' }}
                    />
                )}
            </Modal>
        );
    }
}

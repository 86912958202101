import { useCallback, useEffect, useState } from 'react';
import ServiceBase from 'services/ServiceBase';

interface Props {
    url: string;
    method: string | undefined;
    // Polling itnerval in seconds
    polling: number | undefined;
}

export default function useQuery({ url, method, polling }: Props) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const refetch = useCallback(() => {
        setLoading(true);
        ServiceBase.execute({
            url,
            method,
        })
            .then((res: any) => {
                setData(res);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [url, method]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (polling) {
            const intervalId = setInterval(refetch, polling * 1000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [polling, refetch]);

    return { data, loading, refetch };
}

import React from 'react';
import FormItemBase from 'components/form-items/FormItemBase.jsx';
import { Form } from 'antd';
import CountrySelect from 'components/forms/PhoneInput/CountrySelect';

export default class CountrySelectFormItem extends FormItemBase {
    render() {
        const { id, initialValue } = this.props;
        // Form.Item extra props
        const {
            label,
            labelCol,
            wrapperCol,
            extra,
            validateStatus,
            help,
        } = this.props;

        return (
            <Form.Item
                validateStatus={validateStatus || this.getValidateStatus(id)}
                help={help || this.getHelp(id)}
                hasFeedback
                label={label}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                extra={extra}
            >
                {this.getFieldDecorator(id, {
                    initialValue,
                    rules: this.getRules(),
                })(<CountrySelect />)}
            </Form.Item>
        );
    }
}

import BaseStore from 'stores/BaseStore';
import HarbourStore from 'stores/HarbourStore';

class PontoonStore extends BaseStore {
    _entities: { [harbour_id: number]: IPontoon[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case "Pontoon:receivePontoonsOfHarbour":
            this._receiveByHarbour(payload.harbour, payload.pontoons);
            break;
        case "Pontoon:receivePontoon":
            this._receiveOneByHarbour(payload.harbour, payload.pontoon);
            break;
        case "Pontoon:deletePontoon":
            this._deleteOneByHarbour(payload.harbour, payload.id);
            break;

        default:
            // Do Nothing
            break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, pontoons: IPontoon[]) => {
        this._entities[harbour.id] = pontoons;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, pontoon: IPontoon) => {
        this._entities[harbour.id] = [...this._entities[harbour.id]];
        const pontoons = this._entities[harbour.id];
        if (!pontoons) {
            this._entities[harbour.id] = [pontoon];
        } else {
            const i = pontoons.findIndex(c => c.id === pontoon.id);
            if (i > -1) {
                pontoons[i] = pontoon;
            } else {
                pontoons.push(pontoon);
            }
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, pontoonId: number) => {
        this._entities[harbour.id] = [...this._entities[harbour.id]];
        const pontoons = this._entities[harbour.id];
        if (!pontoons) {
            return;
        }

        const i = pontoons.findIndex(c => c.id === pontoonId);
        if (i > -1) {
            pontoons.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    getForCurrentHarbour = () => this.getByHarbour(HarbourStore.getCurrentHarbour());
    /**
     * Get all the pontoons of a harbour.
     */
    getByHarbour = (harbour: IHarbour | null | undefined): IPontoon[] => (harbour && this._entities[harbour.id]) || [];

    getById = (id: number): IPontoon | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const pontoons = this._entities[harbourId] || [];
            const pontoon = pontoons.find(c => c.id.toString() === id.toString());
            if (pontoon) {
                return pontoon;
            }
        }
        return null;
    };
}

export default new PontoonStore();

export default {
    RECEIVE_BOATS_OF_HARBOUR: 'RECEIVE_BOATS_OF_HARBOUR',
    RECEIVE_BOAT_OF_HARBOUR: 'RECEIVE_BOAT_OF_HARBOUR',
    DELETE_BOAT_OF_HARBOUR: 'DELETE_BOAT_OF_HARBOUR',
    ADD_BOAT_OWNER: 'ADD_BOAT_OWNER',
    REMOVE_BOAT_OWNER: 'REMOVE_BOAT_OWNER',

    // Types
    MULTIHULL: 'multihull',
    SAILBOAT: 'sailboat',
    MOTORBOAT: 'motorboat',
};

import { useRef, useEffect } from "react";

function useOutClickEvent(onClickOut) {
  const containerRef = useRef(null);

  
  useEffect(() => {
    function handleClickOutside(e) {
      if (containerRef && !containerRef.current.contains(e.target)) {
        onClickOut();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOut]);

  return containerRef;
}

export default useOutClickEvent;
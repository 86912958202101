import React from 'react';
import FormItemBase from 'components/form-items/FormItemBase.jsx';
import { Form } from 'antd';
import SelectMultipleField from 'components/form-fields/SelectMultipleField';

export default class SelectMultipleFormItem extends FormItemBase {

    render(){
        const {id, initialValue, options} = this.props;
        // Form.Item extra props
        const {label, labelCol, wrapperCol, extra} = this.props;
        // Select props
        const {placeholder} = this.props;

        return (
                <Form.Item
                    validateStatus={this.getValidateStatus(id)}
                    help={this.getHelp(id)}
                    hasFeedback
                    label={label} labelCol={labelCol} wrapperCol={wrapperCol} extra={extra}
                >
                    {this.getFieldDecorator(id, {
                        initialValue: initialValue,
                        rules: this.getRules(),
                    })(
                        <SelectMultipleField
                            placeholder={placeholder}
                            options={options} 
                        />
                    )}
                </Form.Item>
            );
    }

}

import { useEffect, useState } from 'react';
import BoatStore from '../stores/BoatStore';
import BoatActions from '../actions/BoatActions';
import HarbourStore from 'stores/HarbourStore';

export default function useBoatsOfHarbour(harbour) {
    const [entities, setEntities] = useState([]);

    useEffect(() => {
        const h = HarbourStore.getById(harbour.id);
        function refresh() {
            setEntities(BoatStore.getByHarbour(h));
        }

        refresh();
        BoatActions.reloadByHarbour(h);

        BoatStore.addChangeListener(refresh);
        return () => {
            BoatStore.removeChangeListener(refresh);
        };
    }, [harbour.id]);

    return entities;
}

import React from "react";

import { Icon } from "antd";

function FilterIcon({ active, onClick }) {
  return (
    <Icon
      type="filter"
      style={{
        color: active ? "#108ee9" : "#aaa"
      }}
      onClick={onClick}
    />
  );
}

export default FilterIcon;

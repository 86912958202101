

import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import RateSeasonConfigurationConstants from 'constants/RateSeasonConfigurationConstants';
import RateSeasonConfigurationService from 'services/RateSeasonConfigurationService';

import CacheManager from 'services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class RateSeasonConfigurationActions extends ActionsBase {
    edit = (harbour: IHarbour, configuration: Object) => {
        const $this = this;
        return new Promise((
            resolve: (configuration: IRateSeasonConfiguration) => void,
            reject: (response: Object) => void,
        ): void => {
            function handleSuccess(newConfiguration: IRateSeasonConfiguration): void {
                dispatcher.dispatch({
                    type:
                            RateSeasonConfigurationConstants.RECEIVE_RATE_SEASON_CONFIGURATION_OF_HARBOUR,
                    payload: {
                        harbour,
                        configuration: newConfiguration,
                    },
                });
                resolve(newConfiguration);
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            RateSeasonConfigurationService.patch(harbour.id, configuration)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByHarbour = (harbour: Entity) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            if (
                CacheManager.isCached(
                    'RateSeasonConfiguration:reloadByHarbour',
                    harbour.id.toString(),
                    CACHE_DURATION,
                )
            ) {
                resolve();
                return;
            }

            function handleSuccess(configuration: IRateSeasonConfiguration): void {
                dispatcher.dispatch({
                    type:
                        RateSeasonConfigurationConstants.RECEIVE_RATE_SEASON_CONFIGURATION_OF_HARBOUR,
                    payload: {
                        harbour,
                        configuration,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            RateSeasonConfigurationService.getByHarbour(harbour.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

export default new RateSeasonConfigurationActions();

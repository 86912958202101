import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Upload, Icon } from 'antd';
import ReactCrop from 'react-image-crop';
import Locale from 'locale/LocaleFactory';
import 'react-image-crop/dist/ReactCrop.css';

import './NotificationThumbnailUpload.scss';

export default function NotificationThumbnailUpload({
    file,
    onChange,
    placeholder,
}) {
    const [visible, setVisible] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    return (
        <div className="notification-thumbnail-upload">
            <Button className="__trigger" onClick={() => setVisible(true)}>
                {previewUrl ? (
                    <img
                        alt={Locale.trans(
                            'communication.notification.thumbnail',
                        )}
                        src={previewUrl}
                    />
                ) : (
                    placeholder ||
                    Locale.trans('communication.notification.thumbnail')
                )}
            </Button>
            <NotificationThumbnailUploadModal
                visible={visible}
                onClose={() => setVisible(false)}
                onFileChange={onChange}
                onPreviewUrlChange={setPreviewUrl}
            />
        </div>
    );
}
function NotificationThumbnailUploadModal({
    onFileChange,
    onPreviewUrlChange,
    visible,
    onClose,
}) {
    const [upImg, setUpImg] = useState();
    const [imgRef, setImgRef] = useState(null);
    const [crop, setCrop] = useState({
        unit: '%',
        width: 50,
        aspect: 1,
    });
    const [, setPreviewUrl] = useState();

    const makeClientCrop = async (crop) => {
        if (imgRef && crop.width && crop.height) {
            createCropPreview(imgRef, crop, 'newFile.jpeg');
        }
    };

    const createCropPreview = useCallback(
        async (image, crop, fileName) => {
            const canvas = document.createElement('canvas');
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext('2d');
            // fill the entire canvas with white before drawing the circles
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height,
            );

            return new Promise((resolve, reject) => {
                canvas.toBlob((blob) => {
                    if (!blob) {
                        reject(new Error('Canvas is empty'));
                        return;
                    }
                    blob.name = fileName;
                    const newPreviewUrl = window.URL.createObjectURL(blob);
                    setPreviewUrl((prevPreviewUrl) => {
                        window.URL.revokeObjectURL(prevPreviewUrl);
                        return newPreviewUrl;
                    });
                    onFileChange(blob);
                    onPreviewUrlChange(newPreviewUrl);
                }, 'image/jpeg');
            });
        },
        [onFileChange, onPreviewUrlChange],
    );

    const onImageLoaded = (image) => {
        setImgRef(image);

        // Center a square percent crop.
        const widthPercent =
            image.width > image.height ? image.height / image.width : 1;

        const heightPercent =
            image.height > image.width ? image.width / image.height : 1;
        const xPercent = widthPercent === 1 ? 0 : (1 - widthPercent) / 2;
        const yPercent = heightPercent === 1 ? 0 : (1 - heightPercent) / 2;

        const width = widthPercent * image.width;
        const height = heightPercent * image.height;
        const x = xPercent * image.width;
        const y = yPercent * image.height;

        const newCrop = {
            unit: 'px',
            aspect: 1,
            width,
            height,
            x,
            y,
        };
        setCrop(newCrop);

        return false; // Return false if you set crop state in here.
    };

    useEffect(() => {
        if (imgRef && crop.width && crop.height) {
            const tid = setTimeout(() => {
                createCropPreview(imgRef, crop, 'newFile.jpeg');
            }, 200);
            return () => {
                clearTimeout(tid);
            };
        }
    }, [imgRef, crop, createCropPreview]);

    const props = {
        accept: 'image/png,image/jpeg,image/svg+xml',
        beforeUpload: (file) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setUpImg(reader.result);
            });
            reader.readAsDataURL(file);

            return false;
        },
        showUploadList: false,
    };

    return (
        <Modal
            title={Locale.trans('communication.notification.selectThumbnail')}
            visible={visible}
            onCancel={onClose}
            footer={null}
            className="notification-thumbnail-upload-modal"
        >
            <Upload {...props}>
                <Button className="__trigger">
                    <Icon type="plus" />
                    {Locale.trans('communication.notification.selectThumbnail')}
                </Button>
            </Upload>
            <ReactCrop
                src={upImg}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={makeClientCrop}
                onImageLoaded={onImageLoaded}
            />
        </Modal>
    );
}

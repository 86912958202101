import React from 'react';
import HarbourMapIncident from './HarbourMapIncident';
import IncidentConstants from '../../constants/IncidentConstants';
import HarbourStore from '../../stores/HarbourStore';
import useIncidentsOfHarbour from '../../hooks/useIncidentsOfHarbour';
import useZonesOfHarbour from '../../hooks/useZonesOfHarbour';
import MathService from '../../services/utils/MathService';
import usePlacesOfHarbour from '../../hooks/usePlacesOfHarbour';

const INCIDENT_TYPES = [
    IncidentConstants.FIRE,
    IncidentConstants.SINK,
    IncidentConstants.THEFT,
    IncidentConstants.ELECTRIC,
    IncidentConstants.WATER,
    IncidentConstants.POLUTION,
    IncidentConstants.LIGHTING,
    IncidentConstants.SANITARY,
    IncidentConstants.SOUND,
    IncidentConstants.BROKEN_BOARD,
    IncidentConstants.COLLISION,
    IncidentConstants.HARBOUR_MAP,
    IncidentConstants.EXTINGUISHER,
    IncidentConstants.CHAIN,
    IncidentConstants.LADDER,
    IncidentConstants.TOWING,
    IncidentConstants.MOORING,
    IncidentConstants.OTHER,
];

/**
 * The calque containing all the incidents.
 */
function HarbourMapIncidentCalque({
    onClick, onShowIncidentClick,
}) {
    const currentHarbour = HarbourStore.getCurrentHarbour();
    const zones = useZonesOfHarbour(currentHarbour);
    const places = usePlacesOfHarbour(currentHarbour);
    const incidents = useIncidentsOfHarbour(currentHarbour);

    const incidentsByPolygons = zones
        .map(zone => ({
            path: zone.svgPoints,
            incidents: incidents.filter(i => i.status !== IncidentConstants.CLOSED && i.zone && i.zone.id === zone.id),
        }))
        .concat(places.map(place => ({
            path: place.placeZone && place.placeZone.svgPoints,
            incidents: incidents.filter(i => i.status !== IncidentConstants.CLOSED && i.place && i.place.id === place.id),
        })));

    return (
        <React.Fragment>
            {/* Patterns are used to reuse it in incidents */}
            <defs>
                {INCIDENT_TYPES.map(incidentType => (
                    <pattern
                        key={incidentType}
                        id={`icon-${incidentType}`}
                        x="0%"
                        y="0%"
                        height="100%"
                        width="100%"
                        viewBox="0 0 512 512"
                    >
                        <image
                            x="80"
                            y="80"
                            width="340"
                            height="340"
                            xlinkHref={`images/icon_${incidentType}.svg`}
                        />
                    </pattern>
                ))}
                <pattern
                    id="icon-attachment"
                    x="0%"
                    y="0%"
                    height="100%"
                    width="100%"
                    viewBox="0 0 512 512"
                >
                    <image
                        x="0%"
                        y="0%"
                        width="512"
                        height="512"
                        xlinkHref="images/icon_attachment.svg"
                    />
                </pattern>
            </defs>
            <g>
                {incidentsByPolygons.map((inc) => {
                    const { path } = inc;
                    const currentIncidents = inc.incidents;

                    if (!path || currentIncidents.length === 0) {
                        return null;
                    }
                    const coordinates = MathService.convertStringToPoints(path);
                    const center = MathService.getAreaCenter(coordinates);
                    const basePoint = {
                        x: center.x + 15,
                        y: center.y - 30,
                    };

                    const incidentNodes = [];
                    for (let i = currentIncidents.length - 1; i >= 0; i--) {
                        const incident = currentIncidents[i];

                        let offsetX = 0;
                        if (i === 0) {
                            offsetX = 0;
                        } else if (i % 2 === 0) {
                            offsetX = -20 * (i / 2);
                        } else {
                            offsetX = 20 * ((i + 1) / 2);
                        }

                        // Get the position of the incident
                        const position = {
                            x: basePoint.x + offsetX,
                            y: basePoint.y,
                        };
                        incidentNodes.push(<HarbourMapIncident
                            key={incident.id}
                            position={position}
                            incident={incident}
                            onClick={() => onClick(incident)}
                            onShowIncidentClick={() => onShowIncidentClick(incident)}
                        />);
                    }

                    return incidentNodes;
                })}
            </g>
        </React.Fragment>
    );
}

export default HarbourMapIncidentCalque;

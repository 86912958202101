import SvgPath from '../js-svg-path';

type Point = { x: number; y: number };

export default class MathService {
    /**
     * Round a number with a certain precision.
     * @param {number} num The number to round.
     * @param {number} precision The number of number after to the coma. (Default: 0)
     * @returns {number} The rounded number
     */
    static round(num: number, precision?: number) {
        const multiplicator = Math.pow(10, precision || 0);
        return Math.round(num * multiplicator) / multiplicator;
    }

    /**
     * Format a number to display.
     * @param {number} num The number to round.
     * @param {number} precision The number of number after to the coma. (Default: 0)
     * @returns {number} The formatted number
     */
    static format(num: number, precision?: number) {
        const roundedNum = MathService.round(num, precision).toFixed(
            precision || 0,
        );
        return MathService.addThousandSeparator(roundedNum, ' ', '.');
    }

    static addThousandSeparator(
        num: number | string,
        thousandSeparator: string,
        decimalSeparator: string,
    ) {
        const nStr = num.toString();
        const x = nStr.split('.');
        let x1 = x[0];
        const x2 = x.length > 1 ? `${decimalSeparator}${x[1]}` : '';
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, `$1${thousandSeparator}$2`);
        }
        return x1 + x2;
    }

    /**
     * Get the center point of a polygon.
     * @param {*} points The array of points of the polygon.
     */
    static getAreaCenter(points: Point[]) {
        let maxX = null;
        let maxY = null;
        let minX = null;
        let minY = null;
        for (let i = 0; i < points.length; i++) {
            const { x, y } = points[i];

            if (minX === null || x < minX) {
                minX = x;
            }
            if (maxX === null || x > maxX) {
                maxX = x;
            }

            if (minY === null || y < minY) {
                minY = y;
            }
            if (maxY === null || y > maxY) {
                maxY = y;
            }
        }
        minX = minX || 0;
        maxX = maxX || 0;
        minY = minY || 0;
        maxY = maxY || 0;
        return {
            x: (minX + maxX) / 2,
            y: (minY + maxY) / 2,
        };
    }

    static highestEdge(points: Point[]) {
        let highestEdge = {
            p1: { x: 0, y: 0 },
            p2: { x: 0, y: 0 },
        };

        for (let i = 0; i < points.length; i++) {
            const y1 = points[i].y;

            let i2 = i + 1;
            if (i2 >= points.length) {
                i2 = 0;
            }

            const y2 = points[i2].y;

            if (
                (y1 >= highestEdge.p1.y && y2 >= highestEdge.p2.y) ||
                (y2 >= highestEdge.p1.y && y1 >= highestEdge.p2.y)
            ) {
                highestEdge = {
                    p1: points[i],
                    p2: points[i2],
                };
            }
        }
        return highestEdge;
    }

    /**
     * Get the longuest edge of a polygon.
     * @param {*} points The array of points of the polygon.
     * @return           The two points of the longuest edge.
     */
    static longuestEdge(points: Point[]) {
        let maxDistance = null;
        let maxStroke = null;

        for (let i = 0; i < points.length; i++) {
            const x1 = points[i].x;
            const y1 = points[i].y;

            let i2 = i + 1;
            if (i2 >= points.length) {
                i2 = 0;
            }

            const x2 = points[i2].x;
            const y2 = points[i2].y;

            const a = x1 - x2;
            const b = y1 - y2;
            const d = Math.sqrt(a * a + b * b);
            if (maxDistance === null || d > maxDistance) {
                maxDistance = d;
                maxStroke = {
                    p1: { x: x1, y: y1 },
                    p2: { x: x2, y: y2 },
                };
            }
        }
        return maxStroke;
    }

    /**
     * Get the angle of a line.
     * @param {*} p1 The first point of the line.
     * @param {*} p2 The second point of the line.
     * @returns      The angle in Degree in a range [-180, 180]
     */
    static getAngleOfLine(p1: Point, p2: Point) {
        const dy = p1.y - p2.y;
        const dx = p1.x - p2.x;
        let theta = Math.atan2(dy, dx); // range (-PI, PI]
        theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
        // if (theta < 0) theta = 360 + theta; // range [0, 360)
        return theta;
    }

    static getPointFromAngleAndDistance(
        point: Point,
        angle: number,
        distance: number,
    ): Point {
        return {
            x: Math.round(
                Math.cos((angle * Math.PI) / 180) * distance + point.x,
            ),
            y: Math.round(
                Math.sin((angle * Math.PI) / 180) * distance + point.y,
            ),
        };
    }

    static getCurveBounds(p0: Point, p0hr: Point, p1: Point, p1hl: Point) {
        const ax = p0.x;
        const ay = p0.y;
        const bx = p0hr.x;
        const by = p0hr.y;
        const cx = p1hl.x;
        const cy = p1hl.y;
        const dx = p1.x;
        const dy = p1.y;

        let minX = Number.POSITIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;

        const tobx = bx - ax;
        const toby = by - ay; // directions
        const tocx = cx - bx;
        const tocy = cy - by;
        const todx = dx - cx;
        const tody = dy - cy;
        const step = 1 / 40; // precission
        for (let d = 0; d < 1.001; d += step) {
            const px = ax + d * tobx;
            const py = ay + d * toby;
            const qx = bx + d * tocx;
            const qy = by + d * tocy;
            const rx = cx + d * todx;
            const ry = cy + d * tody;
            const toqx = qx - px;
            const toqy = qy - py;
            const torx = rx - qx;
            const tory = ry - qy;

            const sx = px + d * toqx;
            const sy = py + d * toqy;
            const tx = qx + d * torx;
            const ty = qy + d * tory;
            const totx = tx - sx;
            const toty = ty - sy;

            const x = sx + d * totx;
            const y = sy + d * toty;

            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
        }
        return { minX, maxY, maxX, minY };
    }

    static rotatePoint(center: Point, point: Point, angle: number): Point {
        const radians = (Math.PI / 180) * angle;
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);
        return {
            x:
                cos * (point.x - center.x) +
                sin * (point.y - center.y) +
                center.x,
            y:
                cos * (point.y - center.y) -
                sin * (point.x - center.x) +
                center.y,
        };
    }

    static getPointsDistance(p0: Point, p1: Point) {
        const l0 = p1.x - p0.x;
        const l1 = p1.y - p0.y;
        return Math.sqrt(Math.pow(l0, 2) + Math.pow(l1, 2));
    }

    /**
     * Get the angle of a polygon based on its longuest edge.
     * @param {*} points The array of points of the polygon.
     * @return           The angle in Degree in a range [-180, 180]
     */
    static getAngleOfPolygon(points: Point[]) {
        const line = MathService.longuestEdge(points);
        return line ? MathService.getAngleOfLine(line.p1, line.p2) : 0;
    }

    /**
     * Get the list of points in a string.
     * The format is : "x1,y1 x2,y2 x3,y3" or "Mx1 y1 Lx2 y2 Lx3y3"
     * @param {string} str The string to convert.
     */
    static convertStringToPoints(str: string) {
        let path = str;
        if (!path.startsWith('M')) {
            path = `M${path.replace(/\s/g, ' L').replace(/,/g, ' ')} z`;
        }
        const outline = new SvgPath.Outline();
        const parser = new SvgPath.SVGParser(outline);
        parser.parse(path);

        const shapes = outline.getShapes();
        if (shapes.length > 0) {
            const shape = shapes[0];
            return shape.points.map((p: any) => p.main);
        }
        return [];
    }
}

import ZoneConstants from 'constants/ZoneConstants';
import BaseStore from 'stores/BaseStore';
import HarbourStore from 'stores/HarbourStore';

class ZoneStore extends BaseStore {
    _entities: { [harbour_id: number]: IZone[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case ZoneConstants.RECEIVE_ZONES_OF_HARBOUR:
            this._receiveByHarbour(payload.harbour, payload.zones);
            break;
        case ZoneConstants.RECEIVE_ZONE_OF_HARBOUR:
            this._receiveOneByHarbour(payload.harbour, payload.zone);
            break;
        case ZoneConstants.DELETE_ZONE_OF_HARBOUR:
            this._deleteOneByHarbour(payload.harbour, payload.id);
            break;
        default:
            // Do Nothing
            break;
        }
    };
    _receiveByHarbour = (harbour: IHarbour, zones: IZone[]) => {
        this._entities = {...this._entities};
        this._entities[harbour.id] = zones;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, zone: IZone) => {
        this._entities[harbour.id] = [...this._entities[harbour.id]];
        const zones = this._entities[harbour.id];
        if (!zones) {
            this._entities[harbour.id] = [zone];
        } else {
            const i = zones.findIndex(c => c.id === zone.id);
            if (i > -1) {
                zones[i] = zone;
            } else {
                zones.push(zone);
            }
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, zoneId: number) => {
        this._entities[harbour.id] = [...this._entities[harbour.id]];
        const zones = this._entities[harbour.id];
        if (!zones) {
            return;
        }

        const i = zones.findIndex(c => c.id === zoneId);
        if (i > -1) {
            zones.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    getForCurrentHarbour = () => this.getByHarbour(HarbourStore.getCurrentHarbour());
    /**
     * Get all the zones of a harbour.
     */
    getByHarbour = (harbour: IHarbour | null | undefined): IZone[] => (harbour && this._entities[harbour.id]) || [];

    getById = (id: number): IZone | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const zones = this._entities[harbourId] || [];
            const zone = zones.find(c => c.id.toString() === id.toString());
            if (zone) {
                return zone;
            }
        }
        return null;
    };
}

export default new ZoneStore();

import React from 'react';
import { Row, Col, Button } from 'antd';
import { rgb, scaleLinear } from 'd3';

import Panel from 'components/Panel';
import Locale from 'locale/LocaleFactory';
import moment from 'moment';
import { Select } from 'components/FormFields';
import StringService from 'services/utils/StringService';
import OccupancyRateReportingStore from 'stores/OccupancyRateReportingStore';
import ReportingFiltersStore from 'stores/ReportingFiltersStore';
import OutingByBoatReportingStore from 'stores/OutingByBoatReportingStore';
import OutingReportingStore from 'stores/OutingReportingStore';
import CalendarCell from 'components/charts/CalendarCell';
import ViewOutingsModal from 'pages/ViewOutingsModal';
import ReportingActions from 'actions/ReportingActions';
import OutingByDayReportingStore from 'stores/OutingByDayReportingStore';

/*
type Props = {};
type State = {
    occupancy: {
        mean: number;
        min: number;
        max: number;
        maxBoatValue: number;
    };
    outings: {
        mean: number;
        min: number;
        max: number;
        maxBoatValue: number;
    };
    modal: {
        visible: boolean,
        outings: array,
        loading: boolean,
        day: moment,
    },
    currentMonth: moment.Moment;
    days: Array<DayCell>;
    visualize: 'occupancy'|'outings'|'all'|'none';
};

type DayCell = {
    day: moment.Moment,
    value: number;
    occupancy: number;
    placesEmpty: number;
    byTypes: Array<BoatTypeDayCell>;
};

type BoatTypeDayCell = {
    type;
    value: number;
    occupancy: number;
    placesEmpty: number;
};
*/

export default class OutingCalendar extends React.Component {
    constructor() {
        super();
        this.state = {
            occupancy: {
                mean: 0,
                min: 0,
                max: 0,
                maxBoatValue: 0,
            },
            outings: {
                mean: 0,
                min: 0,
                max: 0,
                maxBoatValue: 0,
            },
            modal: {
                visible: false,
                outings: [],
                loading: true,
                day: moment(),
            },
            currentMonth: moment(),
            days: [],
            visualize: 'outing',
        };
        this.filters = ReportingFiltersStore.get();
    }

    componentDidMount() {
        try {
            OccupancyRateReportingStore.addChangeListener(this.occupancyReloaded);
            OutingByBoatReportingStore.addChangeListener(this.outingReloaded);
            ReportingFiltersStore.addChangeListener(this.changeFilters);
            OutingByDayReportingStore.addChangeListener(this.receiveDataForModal);
            this.changeFilters();
            this.changeData();
        } catch (err) {
            OccupancyRateReportingStore.removeChangeListener(this.occupancyReloaded);
            ReportingFiltersStore.removeChangeListener(this.changeFilters);
            throw err;
        }
    }

    componentWillUnmount() {
        OccupancyRateReportingStore.removeChangeListener(this.occupancyReloaded);
        ReportingFiltersStore.removeChangeListener(this.changeFilters);
        OutingByBoatReportingStore.removeChangeListener(this.outingReloaded);
        OutingByDayReportingStore.removeChangeListener(this.receiveDataForModal);
    }

    changeFilters = () => {
        const currentMonth = this.state.currentMonth.clone();
        this.filters = ReportingFiltersStore.get();
        if (
            currentMonth.isBetween(this.filters.period.start, this.filters.period.end)
            || this.filters.period.start.isBetween(currentMonth.startOf('month'), currentMonth.endOf('month'))
            || this.filters.period.end.isBetween(currentMonth.startOf('month'), currentMonth.endOf('month'))
        ) {
            return;
        }
        this.setState({
            currentMonth: this.filters.period.start,
        });
    };
    changeData = () => {
        if (this.filters === undefined) {
            return;
        }

        const history = OccupancyRateReportingStore.getHistory();
        const outingsValues = OutingReportingStore.getOutings().main;
        if (history.main === undefined && outingsValues.history.length === 0) {
            return;
        }

        const currentDay = this.filters.period.start.clone().startOf('day');

        const days = [];
        while (currentDay.isSameOrBefore(this.filters.period.end.clone().endOf('day'))) {
            const getValues = (data, nbPlaces, outings) => {
                const day = data.shift();
                const outing = outings.shift();
                let occupancy = 100;
                let emptyPlaces = 0;
                if (day !== undefined) {
                    if (currentDay.isSame(day.day)) {
                        occupancy = Math.round((1 - (day.placesEmpty / nbPlaces)) * 1000) / 10;
                        emptyPlaces = day.placesEmpty;
                    } else if (currentDay.isBefore(day.day)) {
                        data.unshift(day);
                    }
                }
                let nbOutings = 0;
                if (outing !== undefined) {
                    if (currentDay.isSame(outing.date)) {
                        nbOutings = outing.value;
                    } else if (currentDay.isBefore(outing.date)) {
                        data.unshift(outing);
                    }
                }
                return {
                    occupancy,
                    emptyPlaces,
                    nbOutings,
                };
            };
            const rates = history.main.boatTypesOccupancyRates;
            const nbPlaces = history.main.nbPlacesByType;
            
            const sailboat = getValues(rates.sailboat, nbPlaces.sailboat, outingsValues.historyByType.sailboat);
            const motorboat = getValues(rates.motorboat, nbPlaces.motorboat, outingsValues.historyByType.motorboat);
            const multihull = getValues(rates.multihull, nbPlaces.multihull, outingsValues.historyByType.multihull);
            const main = getValues(
                history.main.mainOccupancyRates,
                history.main.nbPlaces,
                outingsValues.history,
            );
            days.push({
                day: currentDay.clone(),
                value: Math.round(main.nbOutings),
                occupancy: Math.round(main.occupancy),
                placesEmpty: main.emptyPlaces,
                byTypes: [
                    {
                        type: 'motorboat',
                        value: motorboat.nbOutings,
                        occupancy: motorboat.occupancy,
                        placesEmpty: motorboat.emptyPlaces,
                    },
                    {
                        type: 'sailboat',
                        value: sailboat.nbOutings,
                        occupancy: sailboat.occupancy,
                        placesEmpty: sailboat.emptyPlaces,
                    },
                    {
                        type: 'multihull',
                        value: multihull.nbOutings,
                        occupancy: multihull.occupancy,
                        placesEmpty: multihull.emptyPlaces,
                    },
                ],
            });
            currentDay.add(1, 'day');
        }
        const { occupancy, outings } = this.getImportantValues(days);

        this.setState({
            days,
            occupancy,
            outings,
        });
    };
    receiveDataForModal = () => {
        const { modal } = this.state;
        this.setState({
            modal : {
                day: modal.day,
                outings: OutingByDayReportingStore.getOutings(),
                loading: false,
                visible: modal.visible,
            },
        });
    }

    getImportantValues = (days) => {
        let min = Infinity;
        let max = 0;
        let total = 0;
        let minOccupancy = Infinity;
        let maxOccupancy = 0;
        let totalOccupancy = 0;
        let maxBoatValue = 0;
        let maxBoatOccupancy = 0;
        const numberDays = days.length;
        days.forEach((day) => {
            if (day.value < min) {
                min = day.value;
            }
            if (day.value > max) {
                max = day.value;
            }
            total += day.value;
            if (day.occupancy < minOccupancy) {
                minOccupancy = day.occupancy;
            }
            if (day.occupancy > maxOccupancy) {
                maxOccupancy = day.occupancy;
            }
            totalOccupancy += day.occupancy;
            day.byTypes.forEach((type) => {
                if (type.occupancy > maxBoatOccupancy) {
                    maxBoatOccupancy = type.occupancy;
                }
                if (type.value > maxBoatValue) {
                    maxBoatValue = type.value;
                }
            });
        });
        return {
            occupancy: {
                mean: Math.round(totalOccupancy / numberDays),
                min: minOccupancy,
                max: maxOccupancy,
                maxBoatValue: maxBoatOccupancy,
            },
            outings: {
                mean: Math.round(total / numberDays),
                min,
                max,
                maxBoatValue,
            },
        };
    }

    occupancyReloaded= () => {
        this.changeData();
    };
    outingReloaded= () => {
        this.changeData();
    };

    render() {
        const { visualize, occupancy, outings } = this.state;
        const title = this.renderSelectMonth();
        const colorOccupancy = this.getColorScale(occupancy);
        const colorOutings = this.getColorScale(outings);
        return (
            <Panel
                title={title}
                className="reporting calendar"
                style={{ marginBottom: '10px' }}
            >
                <Row className="row">
                    <Col span={20}>
                        {this.renderCalendar()}
                    </Col>
                    <Col span={4} className="calendar-legend">
                        <div>
                            <h4
                                onClick={() => this.changeVisualisation('occupancy')}
                                className={ `${visualize === 'occupancy' ? 'selected' : ''} clickable`}
                            >
                                {Locale.trans('reporting.occupancy.title')}
                            </h4>
                            <ul>
                                <li style={{ color: colorOccupancy(occupancy.max) }}>
                                    {Locale.trans('calendar.max')}
                                    <span className="value">{occupancy.max}%</span>
                                </li>
                                <li style={{ color: colorOccupancy(occupancy.mean) }}>
                                    {Locale.trans('calendar.mean')}
                                    <span className="value">{occupancy.mean}%</span>
                                </li>
                                <li style={{ color: colorOccupancy(occupancy.min) }}>
                                    {Locale.trans('calendar.min')}
                                    <span className="value">{occupancy.min}%</span>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4
                                onClick={() => this.changeVisualisation('outing')}
                                className={ `${visualize === 'outing' ? 'selected' : ''} clickable`}
                            >
                                {Locale.trans('reporting.outing')}
                            </h4>
                            <ul>
                                <li style={{ color: colorOutings(outings.max) }}>
                                    {Locale.trans('calendar.max')}
                                    <span className="value">{outings.max}</span>
                                    
                                </li>
                                <li style={{ color: colorOutings(outings.mean) }}>
                                    {Locale.trans('calendar.mean')}
                                    <span className="value">{outings.mean}</span>
                                </li>
                                <li style={{ color: colorOutings(outings.min) }}>
                                    {Locale.trans('calendar.min')}
                                    <span className="value">{outings.min}</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                {this.renderModal()}
            </Panel>
        );
    }

    changeMonth = (newYear, newMonth) => {
        const newDate = moment()
            .year(newYear)
            .day(1)
            .month(newMonth);
        this.setState({
            currentMonth: newDate,
        });
    };

    renderModal() {
        const {
            visible,
            outings,
            loading,
            day,
        } = this.state.modal;
        return (
            <ViewOutingsModal
                visible={visible}
                outings={outings}
                loading={loading}
                day={day}
                onCancel={this.hideModal}
            />
        );
    }
    hideModal = () => {
        const { modal } = this.state;
        this.setState({
            modal: {
                day: modal.day,
                outings: [],
                visible: false,
                loading: false,
            },
        });
    }
    openModal = (day) => {
        this.setState({
            modal: {
                visible: true,
                outings: [],
                loading: true,
                day,
            },
        });
        ReportingActions.reloadOutingsByDayForCurrentHarbour(day);
    }

    getColorScale = (values) => {
        const { mean, max, min } = values;
        return scaleLinear().domain([min, mean, max])
            .range([rgb('#A90100'), rgb('#F59300'), rgb('#00A931')]);
    }

    renderCalendar = () => {
        const { currentMonth } = this.state;
        const start = currentMonth.clone().startOf('month').startOf('week');
        return (
            <table id="calendar">
                <thead>
                    <tr>
                        <th>{Locale.trans('days.short.monday')}</th>
                        <th>{Locale.trans('days.short.tuesday')}</th>
                        <th>{Locale.trans('days.short.wednesday')}</th>
                        <th>{Locale.trans('days.short.thursday')}</th>
                        <th>{Locale.trans('days.short.friday')}</th>
                        <th>{Locale.trans('days.short.saturday')}</th>
                        <th>{Locale.trans('days.short.sunday')}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderWeek(start)}
                    {this.renderWeek(start.add(1, 'week'))}
                    {this.renderWeek(start.add(1, 'week'))}
                    {this.renderWeek(start.add(1, 'week'))}
                    {this.renderWeek(start.add(1, 'week'))}
                </tbody>
            </table>
        );
    };

    renderWeek = (startOfWeek) => {
        const {
            currentMonth,
            visualize,
            occupancy,
            outings,
        } = this.state;
        const days = [];
        const day = startOfWeek.clone();
        while (day.isBefore(startOfWeek.clone().endOf('week'))) {
            days.push({
                day: day.clone(),
            });
            day.add(1, 'day');
        }

        return (
            <tr className="calendar-row">
                {days.map((e) => {
                    const data = this.getDataForDay(e.day);
                    return (
                        <CalendarCell
                            key={e.day.format('YYYY-MM-DD')}
                            day={e.day}
                            data={data}
                            currentMonth={currentMonth}
                            visualize={visualize}
                            occupancy={occupancy}
                            outings={outings}
                            getColorScale={this.getColorScale}
                            openModal={this.openModal}
                        />
                    );
                })}
            </tr>
        );
    };

    getDataForDay = (day) => {
        const { days } = this.state;
        return days.find(e => e.day.isSame(day));
    }

    changeYear = (year, month) => {
        const newDate = moment()
            .year(year)
            .day(1)
            .month(month);
        this.setState({
            currentMonth: newDate,
        });
    }

    renderSelectMonth = () => {
        const { currentMonth } = this.state;
        const { filters } = this;
        const year = currentMonth.year();
        const month = currentMonth.month();
        const months = moment.months();
        const canGoBack = filters.period.start.clone().startOf('month') < currentMonth.clone().startOf('month');
        const canGoForth = filters.period.end.clone().startOf('month') > currentMonth.clone().startOf('month');
        const years = [currentMonth.year() - 1, currentMonth.year()];
        return (
            <Row gutter={8}>
                <Col span={3} />
                <Col span={3}>
                    <Button
                        shape="circle"
                        className="pull-right"
                        icon="left"
                        onClick={() => this.changeMonth(year, month - 1)}
                        disabled={!canGoBack}
                    />
                </Col>
                <Col span={7}>
                    <Select
                        value={month.toString()}
                        onChange={(newMonth) => {
                            this.changeMonth(year, newMonth);
                        }}
                        options={months.map((m, i) => ({
                            value: i.toString(),
                            label: `${StringService.capitalize(m)}`,
                        }))}
                    />
                </Col>
                <Col span={5}>
                    <Select
                        value={year}
                        onChange={(newYear) => {
                            this.changeYear(newYear, month);
                        }}
                        options={years.map(yearValue => ({
                            value: yearValue,
                            label: `${yearValue}`,
                        }))}
                    />
                </Col>
                <Col span={3}>
                    <Button
                        shape="circle"
                        icon="right"
                        onClick={() => this.changeMonth(year, month + 1)}
                        disabled={!canGoForth}
                    />
                </Col>
            </Row>
        );
    };

    changeVisualisation = (current) => {
        this.setState({
            visualize: current,
        });
    };
}


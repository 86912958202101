/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import Locale from 'locale/LocaleFactory';
import { Form, Icon, Row, Col, Input } from 'antd';
import { Select } from 'components/FormFields';

function generateSelectField(id, label, options, multiple, form) {
    const { getFieldDecorator } = form;
    return getFieldDecorator(id)(<Select allowClear mode={multiple ? 'multiple' : 'default'} options={options} />);
}
function generateTextField(id, label, form) {
    const { getFieldDecorator } = form;
    return getFieldDecorator(id)(<Input />);
}
function generateFieldByFilterDef(filterDef, form) {
    let field = null;
    switch (filterDef.type) {
    case 'choices':
        field = generateSelectField(
            filterDef.id,
            filterDef.label,
            filterDef.options,
            true,
            form,
        );
        break;
    case 'choice':
        field = generateSelectField(
            filterDef.id,
            filterDef.label,
            filterDef.options,
            false,
            form,
        );
        break;
    case 'text':
        field = generateTextField(filterDef.id, filterDef.label, form);
        break;
    default:
        // Do Nothing
    }

    return (
        <Col span={6} key={filterDef.id}>
            <Form.Item label={filterDef.label}>{field}</Form.Item>
        </Col>
    );
}

function AdvancedSearchForm(props) {
    const { filterDefs, form } = props;
    return (
        <Form className="ant-advanced-search-form">
            <Row gutter={8}>{filterDefs.map(def => generateFieldByFilterDef(def, form))}</Row>
        </Form>
    );
}

export default class AdvancedSearch extends React.Component {
    constructor() {
        super();
        this.state = {
            isExpanded: false,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.isExpanded !== this.state.isExpanded;
    }

    handleAdvancedSearchChange = (props, values) => {
        this.props.onFiltersChange(values);
    };

    filterDefsToFormFields = (filterDefs) => {
        const fields = {};
        for (let i = 0; i < filterDefs.length; i++) {
            const def = filterDefs[i];
            if (def.type === 'choices' && !def.value) {
                def.value = [];
            }
            fields[def.id] = Form.createFormField({ value: def.value });
        }
        return fields;
    };

    toggleAdvancedSearch = () => {
        const { onExpandeChange } = this.props;
        const isExpanded = !this.state.isExpanded;
        this.setState({ isExpanded });

        if (onExpandeChange) {
            onExpandeChange(isExpanded);
        }
    };

    render() {
        const { filterDefs } = this.props;

        if (this.state.isExpanded) {
            const advancedSearchFields = this.filterDefsToFormFields(filterDefs);
            const WrappedAdvancedSearchForm = Form.create({
                onValuesChange: this.handleAdvancedSearchChange,
                mapPropsToFields: () => advancedSearchFields,
            })(AdvancedSearchForm);

            return (
                <div>
                    <Row>
                        <Col span={24}>
                            <a style={{ fontSize: 12 }} onClick={this.toggleAdvancedSearch}>
                                <span>
                                    {Locale.trans('advancedSearch.hide')} <Icon type="up" />
                                </span>
                            </a>
                        </Col>
                    </Row>
                    <WrappedAdvancedSearchForm filterDefs={filterDefs} />
                </div>
            );
        }
        return (
            <div>
                <Row>
                    <Col span={24}>
                        <a style={{ fontSize: 12 }} onClick={this.toggleAdvancedSearch}>
                            <span>
                                {Locale.trans('advancedSearch.show')} <Icon type="down" />
                            </span>
                        </a>
                    </Col>
                </Row>
            </div>
        );
    }
}

import React, { useState, useEffect } from 'react';

import BaseUrlConstants from 'constants/BaseUrlConstants';
import useCurrentHarbour from 'hooks/useCurrentHarbour';
import { Select, Input, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import ToastActions from 'actions/ToastActions';
import ServiceBase from 'services/ServiceBase';

export default function WeatherApiConfiguration() {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [apis, setApis] = useState([]);
    const [selectedApi, setSelectedApi] = useState(null);
    const [attributes, setAttributes] = useState(null);

    const harbour = useCurrentHarbour();

    useEffect(() => {
        ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}weather/api/definitions`,
        }).then(setApis);
    }, []);

    useEffect(() => {
        ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/weather/api`,
        }).then((res) => {
            setSelectedApi(res.weatherApi);
            setAttributes(res.weatherApiAttributes);
        });
    }, [harbour]);

    function submit() {
        setSubmitLoading(true);
        ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/weather/api`,
            method: 'PATCH',
            data: {
                weatherApi: selectedApi,
                weatherApiAttributes: attributes,
            },
        })
            .then(() => {
                ToastActions.createToastSuccess(
                    Locale.trans('weather-api.success'),
                );
            })
            .catch(() => {
                ToastActions.createToastError(Locale.trans('error_message'));
            })
            .finally(() => {
                setSubmitLoading(false);
            });
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div style={{ width: 300 }}>
                <Select
                    value={selectedApi}
                    onChange={setSelectedApi}
                    allowClear
                    style={{ width: '100%', marginBottom: 8 }}
                >
                    {apis.map((a) => (
                        <Select.Option key={a.code} value={a.code}>
                            {a.code}
                        </Select.Option>
                    ))}
                </Select>
                {apis.find((a) => a.code === selectedApi) && (
                    <Attributes
                        definitions={
                            apis.find((a) => a.code === selectedApi)
                                .attributeDefinitions
                        }
                        values={attributes || {}}
                        onChange={setAttributes}
                    />
                )}
                <Button
                    loading={submitLoading}
                    type="primary"
                    onClick={submit}
                    style={{ width: '100%' }}
                >
                    {Locale.trans('save')}
                </Button>
            </div>
        </div>
    );
}

function Attributes({ definitions, values, onChange }) {
    if (!definitions || definitions.length === 0) {
        return null;
    }
    return (
        <div>
            {definitions.map((d) => (
                <div key={d.code} style={{ marginBottom: 8 }}>
                    <div>{d.name}</div>
                    <AttributesField
                        definition={d}
                        value={values[d.code] || null}
                        onChange={(v) => onChange({ ...values, [d.code]: v })}
                    />
                </div>
            ))}
        </div>
    );
}

function AttributesField({ definition, value, onChange }) {
    switch (definition.type) {
        case 'text':
            return (
                <Input
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        case 'select':
            return (
                <Select value={value} onChange={onChange}>
                    {definition.selectOptions.map((o) => (
                        <Select.Option key={o.value} value={o.value}>
                            {o.text}
                        </Select.Option>
                    ))}
                </Select>
            );
        default:
            return null;
    }
}

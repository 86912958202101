import { useEffect, useState } from 'react';
import HarbourStore from '../stores/HarbourStore';

function useCurrentHarbour() {
    const [harbour, setHarbour] = useState(HarbourStore.getCurrentHarbour());
    useEffect(() => {
        function update() {
            setHarbour(HarbourStore.getCurrentHarbour());
        }
        update();
        HarbourStore.addChangeListener(update);
        return () => {
            HarbourStore.removeChangeListener(update);
        };
    }, []);
    return harbour;
}

export default useCurrentHarbour;

import ActionsBase from 'actions/ActionsBase';
import dispatcher from 'dispatchers/AppDispatcher';
import ReportingConstants from 'constants/ReportingConstants';

class ReportingFilterActions extends ActionsBase {
    change = (filters: any) => {
        dispatcher.dispatch({
            type: ReportingConstants.FILTERS_UPDATED,
            payload: {
                filters,
            },
        });
    };
    changeType = (boatType: string) => {
        dispatcher.dispatch({
            type: ReportingConstants.FILTERS_BOAT_TYPES_UPDATED,
            payload: {
                boatType,
            },
        });
    }
    changeCategory = (boatCategory: string) => {
        dispatcher.dispatch({
            type: ReportingConstants.FILTERS_BOAT_CATEGORIES_UPDATED,
            payload: {
                boatCategory,
            },
        });
    }
}

export default new ReportingFilterActions();

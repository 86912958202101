import React from 'react';
import { Table, Button } from 'antd';

import CreateRateModal from 'pages/CreateRateModal.jsx';
import EditRateModal from 'pages/EditRateModal.jsx';

import Locale from 'locale/LocaleFactory';
import SecurityService from 'services/SecurityService';
import StringService from 'services/utils/StringService';
import MathService from 'services/utils/MathService';

import RateActions from 'actions/RateActions';
import RateStore from 'stores/RateStore';

import RateSeasonConfigurationActions from 'actions/RateSeasonConfigurationActions';
import RateSeasonConfigurationStore from 'stores/RateSeasonConfigurationStore';

import HarbourStore from 'stores/HarbourStore';

/**
 * A list of rates pricing of the harbour by season.
 */
export default class RateList extends React.Component {
    constructor() {
        super();
        this.state = {
            rates: RateStore.getByHarbour(HarbourStore.getCurrentHarbour()),
            rateSeasonConfiguration: RateSeasonConfigurationStore.getByHarbour(HarbourStore.getCurrentHarbour()),

            createRateVisible: false,
            editRateVisible: false,
            rateToEdit: null,
        };
    }

    componentDidMount() {
        HarbourStore.addCurrentHarbourChangeListener(this.onChangeCurrentHarbour);
        RateStore.addChangeListener(this.receiveRates);
        RateSeasonConfigurationStore.addChangeListener(this.receiveRateSeasonConfiguration);

        this.reloadData();
    }
    componentWillUnmount() {
        HarbourStore.removeCurrentHarbourChangeListener(this.onChangeCurrentHarbour);
        RateStore.removeChangeListener(this.receiveRates);
        RateSeasonConfigurationStore.removeChangeListener(this.receiveRateSeasonConfiguration);
    }

    reloadData = () => {
        RateActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
        RateSeasonConfigurationActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    };

    onChangeCurrentHarbour = () => {
        this.reloadData();
        this.receiveRates();
        this.receiveRateSeasonConfiguration();
    };

    receiveRates = () => {
        this.setState({
            rates: RateStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        });
    };

    receiveRateSeasonConfiguration = () => {
        this.setState({
            rateSeasonConfiguration: RateSeasonConfigurationStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        });
    };

    getGroupedRates = () => {
        const { rates } = this.state;

        const groupedRates = {};

        for (let iC = 0; iC < rates.length; iC++) {
            const rate = rates[iC];
            const boatTypes =
                rate.boatTypes && rate.boatTypes.sort((t1, t2) => (t1 < t2 ? -1 : 1)).join('-');
            if (!groupedRates[boatTypes]) {
                groupedRates[boatTypes] = [];
            }
            groupedRates[boatTypes].push(rate);
        }
        return groupedRates;
    };

    showCreateRateModal = () => {
        this.setState({
            createRateVisible: true,
        });
    };
    hideCreateRateModal = () => {
        this.setState({
            createRateVisible: false,
        });
    };

    editRate = (rate) => {
        this.setState({
            editRateVisible: true,
            rateToEdit: rate,
        });
    };
    hideEditRateModal = () => {
        this.setState({
            editRateVisible: false,
            rateToEdit: null,
        });
    };

    render() {
        const showActions = SecurityService.isGranted(['administrator']);
        return (
            <div>
                {showActions && (
                    <div className="actions-row">
                        <Button type="primary" icon="plus" onClick={this.showCreateRateModal}>
                            {Locale.trans('rate.creation.title')}
                        </Button>
                    </div>
                )}
                {this.renderRateTables(showActions)}
                <CreateRateModal
                    onCancel={this.hideCreateRateModal}
                    visible={this.state.createRateVisible}
                />
                <EditRateModal
                    rate={this.state.rateToEdit}
                    onCancel={this.hideEditRateModal}
                    visible={this.state.editRateVisible}
                />
            </div>
        );
    }

    renderRateTables(showActions) {
        const groupedRates = this.getGroupedRates();
        const kCategories = Object.keys(groupedRates);
        return kCategories.map((kC) => {
            const boatTypes = kC.split('-');
            return (
                <div key={kC} style={{ marginBottom: '16px' }}>
                    <h2>{boatTypes.map(t => Locale.trans(t)).join(' / ')}</h2>
                    {this.renderRateTable(groupedRates[kC], showActions)}
                </div>
            );
        });
    }
    renderRateTable(rates, showActions) {
        const { rateSeasonConfiguration } = this.state;
        const months = [
            Locale.trans('months.jan'),
            Locale.trans('months.feb'),
            Locale.trans('months.mar'),
            Locale.trans('months.apr'),
            Locale.trans('months.may'),
            Locale.trans('months.jun'),
            Locale.trans('months.jul'),
            Locale.trans('months.aug'),
            Locale.trans('months.sep'),
            Locale.trans('months.oct'),
            Locale.trans('months.nov'),
            Locale.trans('months.dec'),
        ];

        const columns = [
            {
                title: Locale.trans('rate.category'),
                key: 'category',
                render: (rate) => {
                    let descriptionLength = '';
                    let descriptionWidth = '';
                    if (rate.category !== undefined) {
                        if (rate.category.lengthMin !== null && rate.category.lengthMax !== null) {
                            descriptionLength = Locale.trans('boat.category.definition', { 
                                min: rate.category.lengthMin, 
                                max: rate.category.lengthMax,
                            });
                        } else if (rate.category.lengthMin !== null) {
                            descriptionLength = Locale.trans('boat.category.definition_min', { min: rate.category.lengthMin });
                        } else if (rate.category.lengthMax !== null) {
                            descriptionLength = Locale.trans('boat.category.definition_max', { max: rate.category.lengthMax });
                        } else {
                            descriptionLength = Locale.trans('boat.category.definition_undefined');
                        }
                    
                        if (rate.category.widthMin !== null && rate.category.widthMax !== null) {
                            descriptionWidth = Locale.trans('boat.category.definition', { 
                                min: rate.category.widthMin, 
                                max: rate.category.widthMax,
                            });
                        } else if (rate.category.widthMin !== null) {
                            descriptionWidth = Locale.trans('boat.category.definition_min', { min: rate.category.widthMin });
                        } else if (rate.category.widthMax !== null) {
                            descriptionWidth = Locale.trans('boat.category.definition_max', { max: rate.category.widthMax });
                        } else {
                            descriptionWidth = Locale.trans('boat.category.definition_undefined');
                        }
                    } else {
                        descriptionLength = Locale.trans('boat.category.definition_undefined');
                        descriptionWidth = Locale.trans('boat.category.definition_undefined');
                    }
                    return (
                        <React.Fragment>
                            <div>{rate.category.name}</div>
                            <div>
                                {Locale.trans('boat.category.length')}: {descriptionLength}
                            </div>
                            <div>
                                {Locale.trans('boat.category.width')}: {descriptionWidth}
                            </div>
                        </React.Fragment>
                    );
                },
            },
        ];

        if (rateSeasonConfiguration) {
            if (rateSeasonConfiguration.hightSeasonStartDay) {
                const hightSeasonColumns = {
                    title: (
                        <React.Fragment>
                            <div>{Locale.trans('rate.season.high')}</div>
                            <div>
                            ( {Locale.trans('rate.season.definition', {
                                start: `${rateSeasonConfiguration.hightSeasonStartDay} ${months[rateSeasonConfiguration.hightSeasonStartMonth - 1]}`,
                                end: `${rateSeasonConfiguration.hightSeasonEndDay} ${months[rateSeasonConfiguration.hightSeasonEndMonth - 1]}`,
                            })} )
                            </div>
                        </React.Fragment>
                    ),
                    children: [
                        {
                            title: StringService.capitalize(Locale.trans('day')),
                            key: 'hight.day',
                            render: (rate) =>
                                rate.hightSeasonDay && `${MathService.format(rate.hightSeasonDay, 2)}€`,
                            width: '8%',
                        },
                        {
                            title: StringService.capitalize(Locale.trans('week')),
                            key: 'hight.week',
                            render: (rate) =>
                                rate.hightSeasonWeek &&
                                `${MathService.format(rate.hightSeasonWeek, 2)}€`,
                            width: '8%',
                        },
                        {
                            title: StringService.capitalize(Locale.trans('month')),
                            key: 'hight.month',
                            render: (rate) =>
                                rate.hightSeasonMonth &&
                                `${MathService.format(rate.hightSeasonMonth, 2)}€`,
                            width: '8%',
                        },
                    ],
                };
                columns.push(hightSeasonColumns);
            }
            if(rateSeasonConfiguration.lowSeasonStartDay) {
                const lowSeasonColumns = {
                    title: (
                        <React.Fragment>
                            <div>{Locale.trans('rate.season.low')}</div>
                            <div>
                            ( {Locale.trans('rate.season.definition', {
                                start: `${rateSeasonConfiguration.lowSeasonStartDay} ${months[rateSeasonConfiguration.lowSeasonStartMonth - 1]}`,
                                end: `${rateSeasonConfiguration.lowSeasonEndDay} ${months[rateSeasonConfiguration.lowSeasonEndMonth - 1]}`,
                            })} )
                            </div>
                        </React.Fragment>
                    ),
                    children: [
                        {
                            title: StringService.capitalize(Locale.trans('day')),
                            key: 'low.day',
                            render: (rate) =>
                                rate.lowSeasonDay && `${MathService.format(rate.lowSeasonDay, 2)}€`,
                            width: '8%',
                        },
                        {
                            title: StringService.capitalize(Locale.trans('week')),
                            key: 'low.week',
                            render: (rate) =>
                                rate.lowSeasonWeek && `${MathService.format(rate.lowSeasonWeek, 2)}€`,
                            width: '8%',
                        },
                        {
                            title: StringService.capitalize(Locale.trans('month')),
                            key: 'low.month',
                            render: (rate) =>
                                rate.lowSeasonMonth && `${MathService.format(rate.lowSeasonMonth, 2)}€`,
                            width: '8%',
                        },
                    ],
                };
                columns.push(lowSeasonColumns);
            }
            if(rateSeasonConfiguration.offSeasonStartDay) {
                const offSeasonColumns = {
                    title: (
                        <React.Fragment>
                            <div>{Locale.trans('rate.season.off')}</div>
                            <div>
                            ( {Locale.trans('rate.season.definition', {
                                start: `${rateSeasonConfiguration.offSeasonStartDay} ${months[rateSeasonConfiguration.offSeasonStartMonth - 1]}`,
                                end: `${rateSeasonConfiguration.offSeasonEndDay} ${months[rateSeasonConfiguration.offSeasonEndMonth - 1]}`,
                            })} )
                            </div>
                        </React.Fragment>
                    ),
                    children: [
                        {
                            title: StringService.capitalize(Locale.trans('day')),
                            key: 'off.day',
                            render: (rate) =>
                                rate.offSeasonDay && `${MathService.format(rate.offSeasonDay, 2)}€`,
                            width: '8%',
                        },
                        {
                            title: StringService.capitalize(Locale.trans('week')),
                            key: 'off.week',
                            render: (rate) =>
                                rate.offSeasonWeek && `${MathService.format(rate.offSeasonWeek, 2)}€`,
                            width: '8%',
                        },
                        {
                            title: StringService.capitalize(Locale.trans('month')),
                            key: 'off.month',
                            render: (rate) =>
                                rate.offSeasonMonth && `${MathService.format(rate.offSeasonMonth, 2)}€`,
                            width: '8%',
                        },
                    ],
                };
                columns.push(offSeasonColumns);
            }
        }

        if (showActions) {
            columns.push({
                title: null,
                key: 'actions',
                width: '50px',
                render: this.rendActionsCell,
            });
        }
        return (
            <Table
                bordered
                pagination={false}
                dataSource={rates.sort((r1, r2) => StringService.compareCaseInsensitive(r1.category.name, r2.category.name))}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
            />
        );
    }

    rendActionsCell = (rate) => (
        <React.Fragment>
            <Button
                type="primary"
                shape="circle"
                icon="edit"
                onClick={(e) => {
                    this.editRate(rate);
                    e.stopPropagation();
                    e.preventDefault();
                    return false;
                }}
            />
        </React.Fragment>
    );
}

import React from 'react';

import { Button, Row, Col, Tooltip } from 'antd';
import moment from 'moment';
import { Select } from 'components/FormFields';
import RoolingWeeksTable from 'components/RollingWeeksTable.jsx';
import ShowIncidentModal from 'pages/ShowIncidentModal.jsx';
import EditIncidentZoneModal from 'pages/EditIncidentZoneModal.jsx';
import CreateIncidentModal from 'pages/CreateIncidentModal.jsx';
import ExportIncidentModal from 'pages/ExportIncidentModal';

import IncidentCard from 'components/IncidentCard';

import StringService from 'services/utils/StringService';
import Locale from 'locale/LocaleFactory';

import IncidentConstants from 'constants/IncidentConstants';
import IncidentActions from 'actions/IncidentActions';
import IncidentStore from 'stores/IncidentStore';
import IncidentConfigurationStore from 'stores/IncidentConfigurationStore';

import PlaceActions from 'actions/PlaceActions';
import PlaceStore from 'stores/PlaceStore';

import ZoneActions from 'actions/ZoneActions';
import ZoneStore from 'stores/ZoneStore';

import BoatActions from 'actions/BoatActions';
import BoatStore from 'stores/BoatStore';

import UserActions from 'actions/UserActions';
import UserStore from 'stores/UserStore';

import HarbourStore from 'stores/HarbourStore';

const incidentTypes = [
    IncidentConstants.FIRE,
    IncidentConstants.SINK,
    IncidentConstants.THEFT,
    IncidentConstants.ELECTRIC,
    IncidentConstants.WATER,
    IncidentConstants.POLUTION,
    IncidentConstants.LIGHTING,
    IncidentConstants.SANITARY,
    IncidentConstants.SOUND,
    IncidentConstants.BROKEN_BOARD,
    IncidentConstants.COLLISION,
    IncidentConstants.HARBOUR_MAP,
    IncidentConstants.EXTINGUISHER,
    IncidentConstants.CHAIN,
    IncidentConstants.LADDER,
    IncidentConstants.TOWING,
    IncidentConstants.MOORING,
    IncidentConstants.OTHER,
];

function sortIncidentByDateDesc(i1, i2) {
    return i1.createdAt < i2.createdAt ? 1 : -1;
}

/**
 * The security module.
 */
export default class Security extends React.Component {
    constructor() {
        super();
        this.state = {
            year: moment().year(),
            week: moment().week(),

            createIncidentVisible: false,

            incidentToShow: null,
            incidentToEditZone: null,
        };
    }

    componentDidMount() {
        HarbourStore.addCurrentHarbourChangeListener(
            this.onChangeCurrentHarbour,
        );

        IncidentStore.addChangeListener(this.receiveIncidents);
        IncidentConfigurationStore.addChangeListener(
            this.receiveIncidentConfigurations,
        );
        PlaceStore.addChangeListener(this.receivePlaces);
        ZoneStore.addChangeListener(this.receiveZones);
        BoatStore.addChangeListener(this.receiveBoats);
        UserStore.addChangeListener(this.receiveUsers);
        this.reloadData();
    }

    componentWillUnmount() {
        HarbourStore.removeCurrentHarbourChangeListener(
            this.onChangeCurrentHarbour,
        );

        IncidentStore.removeChangeListener(this.receiveIncidents);
        IncidentConfigurationStore.addChangeListener(
            this.receiveIncidentConfigurations,
        );
        PlaceStore.removeChangeListener(this.receivePlaces);
        ZoneStore.removeChangeListener(this.receiveZones);
        BoatStore.removeChangeListener(this.receiveBoats);
    }

    reloadData = () => {
        const harbour = HarbourStore.getCurrentHarbour();
        IncidentActions.reloadByHarbour(harbour);
        IncidentActions.reloadConfigurationByHarbour(harbour);
        PlaceActions.reloadByHarbour(harbour);
        ZoneActions.reloadByHarbour(harbour);
        BoatActions.reloadByHarbour(harbour);
        UserActions.reloadByHarbour(harbour);
    };

    onChangeCurrentHarbour = () => {
        this.reloadData();
        this.receiveIncidents();
        this.receivePlaces();
        this.receiveZones();
        this.receiveBoats();
        this.receiveUsers();
    };

    showCreateIncidentModal = () => {
        this.setState({
            createIncidentVisible: true,
        });
    };
    hideCreateIncidentModal = () => {
        this.setState({
            createIncidentVisible: false,
        });
    };

    showExportModal = () => {
        this.setState({
            exportVisible: true,
        });
    };
    hideExportModal = () => {
        this.setState({
            exportVisible: false,
        });
    };

    receiveBoats = () => {
        this.forceUpdate();
    };

    receiveIncidents = () => {
        this.forceUpdate();
    };

    receiveIncidentConfigurations = () => {
        this.forceUpdate();
    };

    receiveUsers = () => {
        this.forceUpdate();
    };

    receivePlaces = () => {
        this.forceUpdate();
    };

    receiveZones = () => {
        this.forceUpdate();
    };

    changeWeek = (newYear, newWeek) => {
        let year;
        let week;

        const totalWeeks = moment().year(newYear).weeksInYear();

        if (newWeek === 0) {
            year = newYear - 1;
            week = moment().year(year).weeksInYear();
        } else if (newWeek >= totalWeeks) {
            week = 1;
            year = newYear + 1;
        } else {
            week = newWeek;
            year = newYear;
        }
        this.setState({
            year,
            week,
        });
    };

    changeMonth = (newYear, newMonth) => {
        const newDate = moment()
            .year(newYear)
            .day(1)
            .month(newMonth - 1);
        this.setState({
            year: newYear,
            week: newDate.week(),
        });
    };

    goToIncident = (incident) => {
        const date = moment(incident.createdAt);
        this.changeWeek(date.year(), date.isoWeek());
    };

    showIncidentModal = (incident) => {
        this.setState({
            incidentToShow: incident,
        });
    };

    hideIncidentModal = () => {
        this.setState({
            incidentToShow: null,
        });
    };

    showEditIncidentZoneModal = (incident) => {
        this.setState({
            incidentToEditZone: incident,
        });
    };

    hideEditIncidentZoneModal = () => {
        this.setState({
            incidentToEditZone: null,
        });
    };

    render() {
        const {
            createIncidentVisible,
            exportVisible,
            incidentToShow,
            incidentToEditZone,
        } = this.state;
        return (
            <div>
                <div
                    className="security-actions actions-row"
                    style={{ display: 'block' }}
                >
                    <Button
                        type="primary"
                        icon="plus"
                        onClick={this.showCreateIncidentModal}
                        style={{ float: 'right', zIndex: 3 }}
                    >
                        Déclarer un incident
                    </Button>
                    <Button
                        icon="file"
                        onClick={this.showExportModal}
                        style={{ float: 'right', zIndex: 3 }}
                    >
                        Exporter
                    </Button>
                </div>
                {this.renderCalendar()}

                {this.renderPendingIncidents()}

                <ExportIncidentModal
                    onCancel={this.hideExportModal}
                    visible={exportVisible}
                />

                <CreateIncidentModal
                    onCancel={this.hideCreateIncidentModal}
                    visible={createIncidentVisible}
                />

                {incidentToShow && (
                    <ShowIncidentModal
                        visible
                        incidentId={incidentToShow.id}
                        onCancel={this.hideIncidentModal}
                    />
                )}

                {incidentToEditZone && (
                    <EditIncidentZoneModal
                        visible
                        incident={incidentToEditZone}
                        onCancel={this.hideEditIncidentZoneModal}
                    />
                )}
            </div>
        );
    }

    renderPendingIncidents() {
        const harbour = HarbourStore.getCurrentHarbour();
        const incidents = IncidentStore.getByHarbour(harbour).sort(
            sortIncidentByDateDesc,
        );
        const pendingIncidents = incidents.filter(
            (i) => i.status !== IncidentConstants.CLOSED,
        );

        if (pendingIncidents.length === 0) {
            return null;
        }

        return (
            <div
                className="pending-incident-table"
                style={{ marginTop: '16px' }}
            >
                <h2>{Locale.trans('incident.pendingIncidents')}</h2>
                <div className="incident-cards">
                    {pendingIncidents
                        .filter((i) => i.isCritical)
                        .map((i) => {
                            const author = i.user
                                ? UserStore.getById(i.user.id)
                                : null;
                            const zone = i.zone
                                ? ZoneStore.getById(i.zone.id)
                                : null;
                            return (
                                <IncidentCard
                                    key={i.id}
                                    incident={i}
                                    zone={zone}
                                    author={author}
                                    onClick={() => this.showIncidentModal(i)}
                                    onChangeZone={() =>
                                        this.showEditIncidentZoneModal(i)
                                    }
                                />
                            );
                        })}
                    {pendingIncidents
                        .filter((i) => !i.isCritical)
                        .map((i) => {
                            const author = i.user
                                ? UserStore.getById(i.user.id)
                                : null;
                            const zone = i.zone
                                ? ZoneStore.getById(i.zone.id)
                                : null;
                            return (
                                <IncidentCard
                                    key={i.id}
                                    incident={i}
                                    zone={zone}
                                    author={author}
                                    onClick={() => this.showIncidentModal(i)}
                                />
                            );
                        })}
                </div>
            </div>
        );
    }

    renderIncidentAttachments = (incident) => {
        if (incident.attachments.length === 0) {
            return null;
        }
        return (
            <Tooltip title={`${incident.attachments.length} pièces jointes`}>
                <Button
                    type="info"
                    shape="circle"
                    icon="paper-clip"
                    ghost
                    onClick={(e) => {
                        this.showIncidentModal(incident);
                        e.stopPropagation();
                    }}
                />
            </Tooltip>
        );
    };

    renderCalendar() {
        const { year, week } = this.state;

        const previousColumns = [
            {
                title: '',
                key: 'title',
                render: () => Locale.trans('incident.type'),
            },
        ];

        return (
            <div className="security-calendar">
                <Row gutter={8}>
                    <Col span={8} />
                    <Col span={8}>{this.renderMonthSelector()}</Col>
                    <Col span={8} />
                </Row>

                <br />

                <RoolingWeeksTable
                    dataSource={[{ key: 'types' }]}
                    year={year}
                    week={week}
                    countDisplayedWeeks={4}
                    renderCell={this.renderCell}
                    previousColumns={previousColumns}
                />
                <div className="legends">
                    {incidentTypes.map((incidentType) => (
                        <div key={incidentType}>
                            <div className="incident-icon">
                                <img
                                    src={`images/icon_${incidentType}.svg`}
                                    alt={incidentType}
                                />
                            </div>
                            {Locale.trans(`incident.types.${incidentType}`)}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    renderCell = (o, date) => (
        <div className="security-day">
            {IncidentStore.getByDate(
                HarbourStore.getCurrentHarbour(),
                date,
            ).map((incident) => (
                <div
                    key={incident.id}
                    className="incident-icon"
                    onClick={(e) => {
                        this.showIncidentModal(incident);
                        e.stopPropagation();
                    }}
                    onKeyPress={(e) => {
                        this.showIncidentModal(incident);
                        e.stopPropagation();
                    }}
                    role="button"
                    tabIndex={0}
                >
                    <img
                        src={`images/icon_${incident.incidentType}.svg`}
                        alt={incident.incidentType}
                    />
                </div>
            ))}
        </div>
    );

    renderMonthSelector() {
        const { year, week } = this.state;

        return (
            <Row gutter={8}>
                <Col span={3}>
                    <Button
                        shape="circle"
                        className="pull-right"
                        icon="left"
                        onClick={() => this.changeWeek(year, week - 1)}
                    />
                </Col>
                <Col span={9}>{this.renderSelectMonth()}</Col>
                <Col span={9}>{this.renderSelectYear()}</Col>
                <Col span={3}>
                    <Button
                        shape="circle"
                        icon="right"
                        onClick={() => this.changeWeek(year, week + 1)}
                    />
                </Col>
            </Row>
        );
    }

    renderSelectMonth = () => {
        const { year, week } = this.state;

        const date = moment().year(year).day(1).week(week);

        const y = date.year();
        const month = date.month();
        const months = moment.months();

        return (
            <Select
                value={month + 1}
                onChange={(newMonth) => {
                    this.changeMonth(y, newMonth);
                }}
                options={months.map((m, i) => ({
                    value: i + 1,
                    label: StringService.capitalize(m),
                }))}
            />
        );
    };

    renderSelectYear = () => {
        const { year, week } = this.state;

        const date = moment().year(year).day(1).week(week);

        const y = date.year();
        const month = date.month();

        const options = [];
        for (let currentYear = y - 5; currentYear <= y + 5; currentYear++) {
            options.push({ value: currentYear, label: currentYear });
        }

        return (
            <Select
                value={y}
                onChange={(newYear) => {
                    this.changeMonth(newYear, month);
                }}
                options={options}
            />
        );
    };
}

import dispatcher from '../dispatchers/AppDispatcher';
import ActionsBase from '../actions/ActionsBase';

import HarbourMapShapeConstants from '../constants/HarbourMapShapeConstants';
import HarbourMapShapeService from '../services/HarbourMapShapeService';

import CacheManager from '../services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class HarbourMapShapeActions extends ActionsBase {
    reloadByHarbour = (harbour: IHarbour) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            if (
                CacheManager.isCached(
                    'HarbourMapShape:reloadByHarbour',
                    harbour.id.toString(),
                    CACHE_DURATION,
                )
            ) {
                resolve();
                return;
            }

            function handleSuccess(shapes: IHarbourMapShape[]) {
                dispatcher.dispatch({
                    type: HarbourMapShapeConstants.RECEIVE_MAP_SHAPES,
                    payload: {
                        shapes,
                    },
                });
                resolve();
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }

            HarbourMapShapeService.getByHarbour(harbour.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    create = (harbour: IHarbour, shape: any) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newShape: IHarbourMapShape) {
                dispatcher.dispatch({
                    type: HarbourMapShapeConstants.RECEIVE_MAP_SHAPE,
                    payload: {
                        shape: newShape,
                    },
                });
                resolve(newShape);
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }

            HarbourMapShapeService.post(harbour.id, shape)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (harbour: IHarbour, shapeId: number, shape: any) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newShape: IHarbourMapShape) {
                dispatcher.dispatch({
                    type: HarbourMapShapeConstants.RECEIVE_MAP_SHAPE,
                    payload: {
                        harbour,
                        shape: newShape,
                    },
                });
                resolve(newShape);
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }

            HarbourMapShapeService.patch(harbour.id, shapeId, shape)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    delete = (harbour: IHarbour, id: number) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess() {
                dispatcher.dispatch({
                    type: HarbourMapShapeConstants.DELETE_MAP_SHAPE,
                    payload: {
                        id,
                    },
                });
                resolve();
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }

            HarbourMapShapeService.remove(harbour.id, id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

export default new HarbourMapShapeActions();

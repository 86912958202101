import BoatCategoryConstants from 'constants/BoatCategoryConstants';

import BaseStore from './BaseStore';
import HarbourStore from './HarbourStore';

class BoatCategoryStore extends BaseStore {
    _entities: { [harbour_id: number]: IBoatCategory[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case BoatCategoryConstants.RECEIVE_BOAT_CATEGORY:
            this._receiveByHarbour(payload.harbour, payload.categories);
            break;
        case BoatCategoryConstants.RECEIVE_ONE_BOAT_CATEGORY:
            this._receiveOneByHarbour(payload.harbour, payload.category);
            break;
        default:
            // Do Nothing
            break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, categories: IBoatCategory[]) => {
        this._entities[harbour.id] = categories;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, category: IBoatCategory) => {
        const categories = this._entities[harbour.id];
        if (!categories) {
            this._entities[harbour.id] = [category];
        } else {
            const i = categories.findIndex(c => c.id === category.id);
            if (i > -1) {
                categories[i] = category;
            } else {
                categories.push(category);
            }
        }
        this.emitChange();
    };

    getByHarbour = (harbour: IHarbour | null | undefined): IBoatCategory[] =>
        (harbour ? this._entities[harbour.id] || [] : []);

    getForCurrentHarbour = (): IBoatCategory[] =>
        this.getByHarbour(HarbourStore.getCurrentHarbour());;
}

export default new BoatCategoryStore();

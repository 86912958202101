export default {
    RECEIVE_INCIDENTS_OF_HARBOUR: 'RECEIVE_INCIDENTS_OF_HARBOUR',
    RECEIVE_INCIDENT_OF_HARBOUR: 'RECEIVE_INCIDENT_OF_HARBOUR',
    DELETE_INCIDENT_OF_HARBOUR: 'DELETE_INCIDENT_OF_HARBOUR',
    RECEIVE_CONFIGURATION_OF_HARBOUR: 'RECEIVE_CONFIGURATION_OF_HARBOUR',

    // Status
    PENDING: 'pending',
    ACKNOWLEDGED: 'acknowledged',
    CLOSED: 'closed',

    // Type
    SOUND: 'sound',
    FIRE: 'fire',
    ELECTRIC: 'electric',
    WATER: 'water',
    THEFT: 'theft',
    SINK: 'sink',
    POLUTION: 'polution',
    LIGHTING: 'lighting',
    SANITARY: 'sanitary',
    BROKEN_BOARD: 'broken_board',
    COLLISION: 'collision',
    HARBOUR_MAP: 'harbour_map',
    OTHER: 'other',
    EXTINGUISHER: 'extinguisher',
    CHAIN: 'chain',
    LADDER: 'ladder',
    TOWING: 'towing',
    MOORING: 'mooring',
};

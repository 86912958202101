
/* global localStorage */

import AppDispatcher from 'dispatchers/AppDispatcher.js';
import Login from 'constants/LoginConstants.js';

import ToastActions from 'actions/ToastActions';
import TokenContainer from 'services/TokenContainer';
import UserActions from "actions/UserActions";

class LoginActions {
    loginUser = (jwt: string, user: User): void => {
        const savedJwt = localStorage.getItem('jwt');

        TokenContainer.set(jwt);

        AppDispatcher.dispatch({
            type: Login.LOGIN_USER,
            payload: {
                jwt,
                user,
            },
        });

        UserActions.reloadCurrentUser();

        if (savedJwt !== jwt) {
            localStorage.setItem('jwt', jwt);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('created-at', Date.now().toString());
        }
    };

    loginUserIfRemembered = (): void => {
        const jwt = localStorage.getItem('jwt');
        const user = localStorage.getItem('user');
        if (jwt && user) {
            this.loginUser(jwt, JSON.parse(user));
        } else {

            AppDispatcher.dispatch({
                type: Login.INIT,
            });
        }
    };

    logoutUser = (): void => {
        localStorage.clear();
        // localStorage.removeItem('jwt');
        // localStorage.removeItem('user');
        // localStorage.removeItem('created-at');
        // localStorage.removeItem('currentHarbourId')
        AppDispatcher.dispatch({
            type: Login.LOGOUT_USER,
            payload: {},
        });
    };

    logoutIfUnauthorized = (err: any): void => {
        // If the error is Unauthorized, it means that the token isn't valid.
        // So we logout the user to let him reconnect.
        if (err && err.status === 401) {
            if (localStorage.getItem('user') != null) {
                ToastActions.createToastError('You have to login to access the application.', '');
                this.logoutUser();
            }
        }
    };
}

export default new LoginActions();

import React from 'react';
import MaterialIcon from '@mdi/react';
import {
    mdiWeatherPartlyCloudy,
    mdiCalendar,
    mdiBellOutline,
    mdiSecurity,
    mdiAlertOutline,
    mdiMail,
    mdiLabel,
} from '@mdi/js';

const notificationTypeIcons = {
    weather: mdiWeatherPartlyCloudy,
    security: mdiSecurity,
    event: mdiCalendar,
    work: mdiAlertOutline,
    'concierge-package': mdiLabel,
    'concierge-mail': mdiMail,
};

export default function NotificationIcon({ notification, size }) {
    return (
        <MaterialIcon
            className="notification-icon"
            path={notificationTypeIcons[notification.type] || mdiBellOutline}
            size={size}
            color="black"
        />
    );
}

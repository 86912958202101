import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import BoatConstants from 'constants/BoatConstants';
import BoatService from 'services/BoatService';

import CacheManager from 'services/CacheManager';
import HarbourStore from 'stores/HarbourStore';

const CACHE_DURATION = 30 * 1000;

class BoatActions extends ActionsBase {
    create = (harbour: IHarbour, boat: Object) => {
        const $this = this;
        return new Promise(
            (
                resolve: (boat: IBoat) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newBoat: IBoat): void {
                    dispatcher.dispatch({
                        type: BoatConstants.RECEIVE_BOAT_OF_HARBOUR,
                        payload: {
                            harbour,
                            boat: newBoat,
                        },
                    });
                    resolve(newBoat);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                BoatService.post(harbour.id, boat)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    edit = (harbour: Entity, boatId: number, boat: Object) => {
        const $this = this;
        return new Promise(
            (
                resolve: (boat: IBoat) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newBoat: IBoat): void {
                    dispatcher.dispatch({
                        type: BoatConstants.RECEIVE_BOAT_OF_HARBOUR,
                        payload: {
                            harbour,
                            boat: newBoat,
                        },
                    });
                    resolve(newBoat);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                BoatService.patch(harbour.id, boatId, boat)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    delete = (harbour: Entity, id: number) => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                function handleSuccess(): void {
                    dispatcher.dispatch({
                        type: BoatConstants.DELETE_BOAT_OF_HARBOUR,
                        payload: {
                            harbour,
                            id,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                BoatService.remove(harbour.id, id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    // Owners

    /**
     * Add a owner to a boat.
     * @param {[type]} harbour
     * @param {[type]} boatId
     * @param {[type]} ownerId
     */
    addOwner = (harbour: Entity, boatId: number, ownerId: number) => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                function handleSuccess(newOwner: User): void {
                    dispatcher.dispatch({
                        type: BoatConstants.ADD_BOAT_OWNER,
                        payload: {
                            boatId,
                            owner: newOwner,
                        },
                    });

                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                BoatService.addOwner(harbour.id, boatId, ownerId)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    /**
     * Remove a owner from a boat.
     * @param  {[type]} harbour
     * @param  {[type]} boatId
     * @param  {[type]} ownerId
     */
    removeOwner = (harbour: Entity, boatId: number, ownerId: number) => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                function handleSuccess(): void {
                    dispatcher.dispatch({
                        type: BoatConstants.REMOVE_BOAT_OWNER,
                        payload: {
                            boatId,
                            ownerId,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                BoatService.removeOwner(harbour.id, boatId, ownerId)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    reloadByHarbour = (harbour: Entity | null | undefined) => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                if (
                    !harbour ||
                    CacheManager.isCached(
                        'Boat:reloadByHarbour',
                        harbour.id.toString(),
                        CACHE_DURATION,
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(boats: Array<IBoat>): void {
                    dispatcher.dispatch({
                        type: BoatConstants.RECEIVE_BOATS_OF_HARBOUR,
                        payload: {
                            harbour,
                            boats,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                BoatService.getByHarbour(harbour.id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    reloadForCurrentHarbour = () =>
        this.reloadByHarbour(HarbourStore.getCurrentHarbour());
}

export default new BoatActions();

import StringService from './StringService';

export default class ArrayService {
    static unique(array: Array<any>): Array<any> {
        const n = {} as any;
        const r = [];
        for (let i = 0; i < array.length; i++) {
            if (!n[array[i]]) {
                n[array[i]] = true;
                r.push(array[i]);
            }
        }
        return r;
    }

    static uniqueEntity(array: Array<Entity>): Array<Entity> {
        const n = {} as any;
        const r = [];
        for (let i = 0; i < array.length; i++) {
            if (array[i]) {
                const { id } = array[i];
                if (!n[id]) {
                    n[id] = true;
                    r.push(array[i]);
                }
            }
        }
        return r;
    }

    static uniqueObject(
        array: Array<any>,
        getId: (object: any) => string | number,
    ) {
        array = array.reverse();
        const n = {} as any;
        const r = [];
        for (let i = 0; i < array.length; i++) {
            if (array[i]) {
                const id = getId(array[i]);
                if (!n[id]) {
                    n[id] = true;
                    r.push(array[i]);
                }
            }
        }
        return r.reverse();
    }

    static flatten(arrays: any[][]) {
        return ([] as any[]).concat.apply([], arrays);
    }

    static sortStringColumn<T extends { [key: string]: any }>(
        field: string | ((p: T) => string),
    ) {
        function getValue(p: T) {
            if (typeof field === 'string') {
                return p[field];
            }
            return field(p);
        }
        return (p1: T, p2: T) =>
            StringService.compareCaseInsensitive(getValue(p1), getValue(p2));
    }

    static sortNumberColumn<T extends { [key: string]: any }>(
        field: string | ((p: T) => string),
    ) {
        function getValue(p: T) {
            if (typeof field === 'string') {
                return p[field];
            }
            return field(p);
        }
        return (p1: T, p2: T) => (getValue(p1) > getValue(p2) ? 1 : -1);
    }

    static getValuesOfField<T extends { [key: string]: any }>(
        array: T[],
        field: string | ((p: T) => string),
    ) {
        function getValue(p: T) {
            if (typeof field === 'string') {
                return p[field];
            }
            return field(p);
        }
        return array.map((r) => getValue(r));
    }
}

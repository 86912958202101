import React from 'react';

import { Table, Button } from 'antd/lib/index';
import CreateUserModal from 'pages/Configuration/CreateUserModal.jsx';
import EditUserModal from 'pages/Configuration/EditUserModal.jsx';
import LoadingIcon from 'components/LoadingIcon.jsx';

import Locale from 'locale/LocaleFactory';

import UserActions from 'actions/UserActions';
import UserStore from 'stores/UserStore';
import HarbourStore from 'stores/HarbourStore';

import TableColumnFilter from 'components/table/TableColumnFilter.jsx';
import FilterIcon from 'components/table/FilterIcon.jsx';
import FilterService from 'services/utils/FilterService';
import ArrayService from 'services/utils/ArrayService';

import RoleConstants from '../../constants/RoleConstants';

/**
 * The list of the users of a harbour that are not yatchmen. (e.g. Captain, Security officer, etc.)
 */
export default class UsersConfiguration extends React.Component {
    constructor() {
        super();

        const users = UserStore.getByHarbour(HarbourStore.getCurrentHarbour());

        this.state = {
            loading: !users.length,
            users,
            filters: {
                role: Object.values(RoleConstants)
                    .filter(r => r !== RoleConstants.YACHTSMAN),
                firstName: [],
                lastName: [],
                email: [],
            },

            createUserVisible: false,
            editUserVisible: false,
            userToEdit: null,
        };
    }

    componentDidMount() {
        HarbourStore.addCurrentHarbourChangeListener(this.onChangeCurrentHarbour);
        UserStore.addChangeListener(this.receiveUsers);

        this.loadUsers();
    }

    componentWillUnmount() {
        HarbourStore.removeCurrentHarbourChangeListener(this.onChangeCurrentHarbour);
        UserStore.removeChangeListener(this.receiveUsers);
    }

    onChangeCurrentHarbour = () => {
        this.loadUsers();
        this.receiveUsers();
    };

    loadUsers = () => {
        this.setState({
            loading: true,
        });
        UserActions.reloadByHarbour(HarbourStore.getCurrentHarbour())
            .then(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    receiveUsers = () => {
        this.setState({
            users: UserStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        });
    };

    showCreateUserModal = () => {
        this.setState({
            createUserVisible: true,
        });
    };
    hideCreateUserModal = () => {
        this.setState({
            createUserVisible: false,
        });
    };

    editUser = (user) => {
        this.setState({
            editUserVisible: true,
            userToEdit: user,
        });
    };
    hideEditUserModal = () => {
        this.setState({
            editUserVisible: false,
            userToEdit: null,
        });
    };

    render() {
        const { loading, users } = this.state;

        // We want to place the button a little bit over the table
        // if there is users (because of pagination)
        const marginTop = users.filter(this.userMatchFilters).length > 0 ? -50 : 15;

        return (
            <div className="users-list">
                {this.renderUserTable()}
                {!loading && (
                    <div
                        className="actions-row"
                        style={{ marginTop }}
                    >
                        <Button
                            type="primary"
                            icon="plus"
                            onClick={this.showCreateUserModal}
                        >
                            {Locale.trans('user.creation.title')}
                        </Button>
                    </div>
                )}

                <CreateUserModal
                    onCancel={this.hideCreateUserModal}
                    visible={this.state.createUserVisible}
                />
                <EditUserModal
                    user={this.state.userToEdit}
                    onCancel={this.hideEditUserModal}
                    visible={this.state.editUserVisible}
                />
            </div>
        );
    }

    handleFilterChange = (name, values) => {
        this.setState(({ filters }) => {
            const newFilters = { ...filters };
            newFilters[name] = values;
            return {
                filters: newFilters,
            };
        });
    };

    userMatchFilters = (u) => {
        const { filters } = this.state;

        const harbour = HarbourStore.getCurrentHarbour();

        const matchRole = !!u.rolesInHarbours.find(r =>
            r.harbourId === harbour.id && filters.role.indexOf(r.role) !== -1);

        return (
            matchRole &&
            FilterService.matchFilter(filters.lastName, u.lastName) &&
            FilterService.matchFilter(filters.firstName, u.firstName) &&
            FilterService.matchFilter(filters.email, u.email)
        );
    };

    renderUserTable() {
        const { users, filters, loading } = this.state;

        const harbour = HarbourStore.getCurrentHarbour();

        const columns = [
            {
                title: Locale.trans('user.firstName'),
                dataIndex: 'firstName',
                key: 'firstName',
                width: '150px',

                filterIcon: <FilterIcon active={filters.firstName.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="firstName"
                        selectedValues={filters.firstName}
                        values={ArrayService.uniqueObject(
                            users.map(u => ({
                                text: u.firstName,
                                value: u.firstName,
                            })),
                            o => o.value,
                        )}
                        onChange={this.handleFilterChange}
                    />
                ),
            },
            {
                title: Locale.trans('user.lastName'),
                dataIndex: 'lastName',
                key: 'lastName',
                width: '150px',
                filterIcon: <FilterIcon active={filters.lastName.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="lastName"
                        selectedValues={filters.lastName}
                        values={ArrayService.uniqueObject(
                            users.map(u => ({
                                text: u.lastName,
                                value: u.lastName,
                            })),
                            o => o.value,
                        )}
                        onChange={this.handleFilterChange}
                    />
                ),
            },
            {
                title: Locale.trans('user.email'),
                dataIndex: 'email',
                key: 'email',
                width: '230px',
                filterIcon: <FilterIcon active={filters.email.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="email"
                        selectedValues={filters.email}
                        values={ArrayService.uniqueObject(
                            users.map(u => ({
                                text: u.email,
                                value: u.email,
                            })),
                            o => o.value,
                        )}
                        onChange={this.handleFilterChange}
                    />
                ),
            },
            {
                title: Locale.trans('user.roles'),
                key: 'role',
                render: (u) => {
                    let isCaptain = false;
                    if (u.rolesInHarbours.find(r =>
                        r.harbourId === harbour.id && r.role === RoleConstants.CAPTAIN)) {
                        isCaptain = true;
                    }
                    return u.rolesInHarbours
                        .filter(r =>
                            r.harbourId === harbour.id
                            && (
                                !isCaptain
                                || (isCaptain && r.role !== RoleConstants.SECURITY_OFFICER)
                            ))
                        .map(r => Locale.trans(`role.${r.role}`))
                        .join(', ');
                },
                filterIcon: <FilterIcon active={filters.role.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="role"
                        selectedValues={filters.role}
                        values={Object.values(RoleConstants)
                            .filter(r => r !== 'any.role.nauticspot')
                            .map(r => ({
                                text: Locale.trans(`role.${r}`),
                                value: r,
                            }))}
                        onChange={this.handleFilterChange}
                    />
                ),
            },
        ];
        return (
            <Table
                dataSource={users.filter(this.userMatchFilters)}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
                loading={loading && { indicator: <LoadingIcon /> }}
                onRow={a => ({
                    className: 'clickable',
                    onClick: () => this.editUser(a),
                })}
            />
        );
    }
}

import BaseStore from 'stores/BaseStore';
import HarbourStore from 'stores/HarbourStore';

class PartnerStore extends BaseStore {
    _entities: { [harbour_id: number]: IPartner[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case "Partner:receivePartnersOfHarbour":
            this._receiveByHarbour(payload.harbour, payload.partners);
            break;
        case "Partner:receivePartner":
            this._receiveOneByHarbour(payload.harbour, payload.partner);
            break;
        case "Partner:deletePartner":
            this._deleteOneByHarbour(payload.harbour, payload.id);
            break;

        default:
            // Do Nothing
            break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, partners: IPartner[]) => {
        this._entities[harbour.id] = partners;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, partner: IPartner) => {
        this._entities[harbour.id] = [...this._entities[harbour.id]];
        const partners = this._entities[harbour.id];
        if (!partners) {
            this._entities[harbour.id] = [partner];
        } else {
            const i = partners.findIndex(c => c.id === partner.id);
            if (i > -1) {
                partners[i] = partner;
            } else {
                partners.push(partner);
            }
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, partnerId: number) => {
        this._entities[harbour.id] = [...this._entities[harbour.id]];
        const partners = this._entities[harbour.id];
        if (!partners) {
            return;
        }

        const i = partners.findIndex(c => c.id === partnerId);
        if (i > -1) {
            partners.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    getForCurrentHarbour = () => this.getByHarbour(HarbourStore.getCurrentHarbour());
    /**
     * Get all the partners of a harbour.
     */
    getByHarbour = (harbour: IHarbour | null | undefined): IPartner[] => (harbour && this._entities[harbour.id]) || [];

    getById = (id: number): IPartner | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const partners = this._entities[harbourId] || [];
            const partner = partners.find(c => c.id.toString() === id.toString());
            if (partner) {
                return partner;
            }
        }
        return null;
    };
}

export default new PartnerStore();

import React, {useState} from 'react';
import Locale from "../../locale/LocaleFactory";
import {Button, Col, Row} from "antd";
import moment from "moment";
import Panel from "../../components/Panel";
import Loading from "../../components/Loading";
import useLocalWeatherForecast from "../../hooks/useLocalWeatherForecast";
import UploadButton from "../../components/UploadButton";
import ToastActions from "../../actions/ToastActions";
import HarbourStore from "../../stores/HarbourStore";
import * as WeatherStore from "../../stores/WeatherStore";


const gutter = 12;

const WeatherForecast = () => {
    const [currentImageVisible, setCurrentImageVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const localWeather = useLocalWeatherForecast();
    let Image = <Loading message={Locale.trans('loading')} />;
    if (localWeather.length !== 0) {
        Image = <div className={"image-container"}>
            {localWeather.length !== 1 && <React.Fragment>
                <Button icon="left" className="left-button-image" onClick={() => {
                    if (currentImage === 0) {
                        setCurrentImage(localWeather.length - 1);
                    }else {
                        setCurrentImage(currentImage - 1);
                    }
                }}/>
                <Button icon="right" className="right-button-image" onClick={() => {
                    if (currentImage === localWeather.length - 1) {
                        setCurrentImage(0);
                    }else {
                        setCurrentImage(currentImage + 1);
                    }
                }}/>
            </React.Fragment>}
            <img
                style={{
                }}
                src={localWeather[currentImage].data}
                alt="Météo"
            />

        </div>
    }
    const harbour = HarbourStore.getCurrentHarbour();
    return (
        <Panel title={Locale.trans('weather.forecast')} className={"forecast"}>
            <Row gutter={gutter}>
                <Col span={12}>
                    <UploadButton
                        type="primary"
                        size="large"
                        single
                        url={`harbours/${harbour.id}/weather-forecast/local`}
                        onError={() => ToastActions.createToastError(Locale.trans('communication.weather_upload.failed'))}
                        onSuccess={() => {
                            ToastActions.createToastSuccess(Locale.trans('communication.weather_upload.success'));
                            WeatherStore.reload();
                        }}
                    >
                        {Locale.trans('weather.forecast.upload')}
                    </UploadButton>
                </Col>
                <Col span={12}>
                    <Button
                        htmlType={"button"}
                        icon={currentImageVisible ? "close" : "download"}
                        onClick={() => setCurrentImageVisible(!currentImageVisible)}
                        size="large"
                        disabled={localWeather.length === 0}
                    >
                        {currentImageVisible ? Locale.trans('weather.forecast.close_preview') : Locale.trans('weather.forecast.download')}
                    </Button>
                    {localWeather.length !== 0 && (<span className="weather-forecast-updated-date">
                            {Locale.trans('weather.forecast.updated', {date: moment(localWeather[0].created).calendar()})}
                    </span>)}
                </Col>
            </Row>
            {currentImageVisible && Image}
        </Panel>
    );
};
export default WeatherForecast;

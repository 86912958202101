// @flow

import React from 'react';
import { Popover, Table } from 'antd';
import Locale from 'locale/LocaleFactory';

export default class CalendarCell extends React.Component {
    render() {
        const { day, data, currentMonth, visualize, openModal } = this.props;
        const startMonth = currentMonth.clone().startOf('month');
        const isCurrentMonth =
            day.isSame(startMonth) ||
            day.isBetween(startMonth, currentMonth.clone().endOf('month'));
        let content = null;
        const displayData =
            data !== undefined && isCurrentMonth && visualize !== 'none';
        if (displayData) {
            content = (
                <div>
                    {this.renderBarsForTypesInCell(data)}
                    <Popover
                        title={data.day.format('Do MMMM YYYY')}
                        content={this.renderPopOverCell(data)}
                    >
                        {this.renderValueInCell(data)}
                    </Popover>
                </div>
            );
        }
        return (
            <td
                className={`${displayData ? 'clickable' : null} calendar-cell`}
                onClick={() => {
                    displayData && openModal(day);
                }}
            >
                {content}
                <div className="calendar-date">{day.date()}</div>
            </td>
        );
    }

    renderPopOverCell = cell => {
        const columns = [
            {
                title: null,
                dataIndex: 'type',
                key: 'type',
                render: text => ({
                    props: {
                        className: `${text} type`,
                    },
                    children: Locale.trans(text),
                }),
            },
            {
                title: Locale.trans('reporting.outing'),
                dataIndex: 'outing',
                key: 'outing',
            },
            {
                title: Locale.trans('reporting.occupancy.title'),
                dataIndex: 'occupancy',
                key: 'occupancy',
                render: occupancy => `${occupancy}%`,
            },
        ];
        const multihull = cell.byTypes.filter(
            value => value.type === 'multihull',
        )[0];
        const motorboat = cell.byTypes.filter(
            value => value.type === 'motorboat',
        )[0];
        const sailboat = cell.byTypes.filter(
            value => value.type === 'sailboat',
        )[0];
        const data = [
            {
                type: 'reporting.main',
                outing: cell.value,
                occupancy: cell.occupancy,
            },
            {
                type: 'multihull',
                outing: multihull.value,
                occupancy: multihull.occupancy,
            },
            {
                type: 'motorboat',
                outing: motorboat.value,
                occupancy: motorboat.occupancy,
            },
            {
                type: 'sailboat',
                outing: sailboat.value,
                occupancy: sailboat.occupancy,
            },
        ];
        return (
            <Table
                className="calendar-cell-popover-table"
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        );
    };

    renderValueInCell = cell => {
        const { visualize, occupancy, outings, getColorScale } = this.props;
        if (visualize === 'none') {
            return;
        }
        let color = null;

        if (visualize === 'all') {
            const color = getColorScale(occupancy);
            const color2 = getColorScale(outings);
            return (
                <p className={'value compound'}>
                    <span style={{ color: color2(cell.value) }}>
                        {cell.value}
                    </span>
                    <span style={{ color: color(cell.occupancy) }}>
                        {cell.occupancy}%
                    </span>
                </p>
            );
        }
        if (visualize === 'occupancy') {
            color = getColorScale(occupancy);
            return (
                <p className={'value'}>
                    <span style={{ color: color(cell.occupancy) }}>
                        {cell.occupancy}%
                    </span>
                </p>
            );
        }
        if (visualize === 'outing') {
            const color = getColorScale(outings);
            return (
                <p className={'value'}>
                    <span style={{ color: color(cell.value) }}>
                        {cell.value}
                    </span>
                </p>
            );
        }
    };

    renderBarsForTypesInCell = cell => {
        const { occupancy, outings, visualize } = this.props;
        let value = () => 0;
        let height = () => 0;
        if (visualize === 'all' || visualize === 'none') {
            return;
        }
        if (visualize === 'occupancy') {
            height = e => (e.occupancy / occupancy.maxBoatValue) * 100;
            value = e => e.occupancy;
        }
        if (visualize === 'outing') {
            height = e => (e.value / outings.maxBoatValue) * 100;
            value = e => e.value;
        }
        return (
            <div className="byType">
                {cell.byTypes.map(e => (
                    <div
                        className={'type ' + e.type}
                        key={e.type}
                        style={{ height: height(e) + '%' }}
                        title={value(e)}
                    />
                ))}
            </div>
        );
    };
}

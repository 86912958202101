import React from 'react';

import { Table, Icon, Button, Row, Col, Popover } from 'antd';
import { Select } from 'components/FormFields';
import RoolingWeeksTable from 'components/RollingWeeksTable.jsx';
import AdvancedSearch from 'components/AdvancedSearch.jsx';
import CreateAbsenceModal from 'pages/CreateAbsenceModal.jsx';
import CreateAnticipatedReturnModal from 'pages/CreateAnticipatedReturnModal.jsx';

import SecurityService from 'services/SecurityService';
import DateService from 'services/utils/DateService';
import StringService from 'services/utils/StringService';
import FilterService from 'services/utils/FilterService';
import moment from 'moment';
import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';

import AbsenceConstants from 'constants/AbsenceConstants';
import AbsenceActions from 'actions/AbsenceActions';
import AbsenceStore from 'stores/AbsenceStore';

import PlaceActions from 'actions/PlaceActions';
import PlaceStore from 'stores/PlaceStore';

import BoatActions from 'actions/BoatActions';
import BoatStore from 'stores/BoatStore';

import HarbourStore from 'stores/HarbourStore';

function getDisplayedDates(year, week, countDisplayedWeeks) {
    const countDisplayedDays = countDisplayedWeeks * 7;

    const dates = [];
    let currentDate = moment()
        .year(year)
        .week(week)
        .day(1);
    for (let i = 0; i < countDisplayedDays; i++) {
        dates.push(currentDate);
        currentDate = moment(currentDate).add(1, 'days');
    }

    return dates;
}

function cmpPlaceByCode(p1, p2) {
    return StringService.compareCaseInsensitive(p1.code, p2.code);
}

/**
 * The calendar of the places availability.
 */
export default class Calendar extends React.Component {
    constructor() {
        super();
        this.state = {
            startDate: moment()
                .day('Monday')
                .add(7, 'days'),
            places: PlaceStore.getByHarbour(
                HarbourStore.getCurrentHarbour(),
            ).sort(cmpPlaceByCode),
            filteredPlaces: [],
            filters: {
                status: [
                    'pending-absence',
                    'approved-absence',
                    'pending-anticipated-return',
                ],
                date: [],
            },

            createAbsenceVisible: false,
            createAnticipatedReturnVisible: false,
        };
    }

    componentDidMount() {
        HarbourStore.addCurrentHarbourChangeListener(
            this.onChangeCurrentHarbour,
        );

        AbsenceStore.addChangeListener(this.receiveAbsences);
        PlaceStore.addChangeListener(this.receivePlaces);
        BoatStore.addChangeListener(this.receiveBoats);
        this.reloadData();
        this.updateFilters();
    }

    componentWillUnmount() {
        HarbourStore.removeCurrentHarbourChangeListener(
            this.onChangeCurrentHarbour,
        );

        AbsenceStore.removeChangeListener(this.receiveAbsences);
        PlaceStore.removeChangeListener(this.receivePlaces);
        BoatStore.removeChangeListener(this.receiveBoats);
    }

    reloadData = () => {
        AbsenceActions.reloadByHarbour(HarbourStore.getCurrentHarbour(), true);
        PlaceActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
        BoatActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    };

    onChangeCurrentHarbour = () => {
        this.reloadData();
        this.receiveAbsences();
        this.receivePlaces();
        this.receiveBoats();
    };

    showCreateAbsenceModal = () => {
        this.setState({
            createAbsenceVisible: true,
        });
    };
    hideCreateAbsenceModal = () => {
        this.setState({
            createAbsenceVisible: false,
        });
    };

    showCreateAnticipatedReturnModal = () => {
        this.setState({
            createAnticipatedReturnVisible: true,
        });
    };
    hideCreateAnticipatedReturnModal = () => {
        this.setState({
            createAnticipatedReturnVisible: false,
        });
    };

    // Filters
    updateFilters = () => {
        const { places } = this.state;
        const filteredPlaces = places
            .filter(this.placeMatchFilters)
            .map(p => ({ ...p, boat: BoatStore.getOneByPlace(p) }));
        this.setState({ filteredPlaces });
    };

    placeMatchFilters = p => {
        const { filters } = this.state;
        return (
            FilterService.matchFilter(filters.length, p.length) &&
            FilterService.matchFilter(filters.width, p.width) &&
            FilterService.matchFilter(filters.boatType, p.boatTypes) &&
            this.matchDateFilter(p) &&
            this.matchStatusFilter(p)
        );
    };

    matchDateFilter = place => {
        const { filters } = this.state;
        const filterDate = filters.date;
        const filterStatus = filters.status;

        if (
            !filterDate ||
            filterDate.length === 0 ||
            !filterStatus ||
            filterStatus.length === 0
        ) {
            return true;
        }

        const absences = [];
        const dates = filterDate.map(f => DateService.parseApi(f));

        dates.map(date => {
            const absence = AbsenceStore.getOneByPlaceAndDate(place, date);
            if (absence) {
                absences.push(absence);
            }
            return null;
        });

        if (filterStatus.indexOf('pending-absence') !== -1) {
            if (absences.find(a => a.status === AbsenceConstants.PENDING)) {
                return true;
            }
        }
        if (filterStatus.indexOf('approved-absence') !== -1) {
            if (absences.find(a => a.status === AbsenceConstants.APPROVED)) {
                return true;
            }
        }
        if (filterStatus.indexOf('pending-anticipated-return') !== -1) {
            if (
                absences.find(
                    a => a.anticipatedReturnStatus === AbsenceConstants.PENDING,
                )
            ) {
                return true;
            }
        }
        if (filterStatus.indexOf('present') !== -1) {
            if (absences.length === 0) {
                return true;
            }
        }
        return false;
    };

    matchStatusFilter = place => {
        const { filters, startDate } = this.state;
        const filter = filters.status;

        if (!filter || filter.length === 0) {
            return true;
        }

        const endDate = moment(startDate).add(4, 'weeks');

        const absences = AbsenceStore.getByPlaceBetweenDates(
            place,
            startDate,
            endDate,
        ).filter(a => a.departureDate !== a.returnDate);

        if (filter.indexOf('pending-absence') !== -1) {
            if (
                absences.findIndex(
                    absence => absence.status === AbsenceConstants.PENDING,
                ) > -1
            ) {
                return true;
            }
        }
        if (filter.indexOf('approved-absence') !== -1) {
            if (
                absences.findIndex(
                    absence => absence.status === AbsenceConstants.APPROVED,
                ) > -1
            ) {
                return true;
            }
        }
        if (filter.indexOf('pending-anticipated-return') !== -1) {
            if (
                absences.findIndex(
                    absence =>
                        absence.anticipatedReturnStatus ===
                        AbsenceConstants.PENDING,
                ) > -1
            ) {
                return true;
            }
        }
        if (filter.indexOf('present') !== -1) {
            const year = startDate.year();
            const week = startDate.week();

            const dates = getDisplayedDates(year, week, 4);
            if (
                dates.findIndex(
                    date => !AbsenceStore.getOneByPlaceAndDate(place, date),
                ) > -1
            ) {
                return true;
            }
        }

        return false;
    };

    toggleStatusFilter = value => {
        const { filters } = this.state;
        const filter = filters.status;

        const i = filter.indexOf(value);
        if (i !== -1) {
            filter.splice(i, 1);
        } else {
            filter.push(value);
        }
        this.handleFilterChange('status', filter);
    };

    getFilterDefinitions = () => {
        const { filters } = this.state;
        const boatTypeFilter = {
            id: 'boatType',
            label: Locale.trans('boat.boatType'),
            value: filters.boatType,
            type: 'choices',
            options: [
                { value: 'multihull', label: Locale.trans('multihull') },
                { value: 'sailboat', label: Locale.trans('sailboat') },
                { value: 'motorboat', label: Locale.trans('motorboat') },
            ],
        };
        const lengthFilter = {
            id: 'length',
            label: Locale.trans('boat.length'),
            value: filters.length,
            type: 'text',
        };
        const widthFilter = {
            id: 'width',
            label: Locale.trans('boat.width'),
            value: filters.width,
            type: 'text',
        };
        const draughtFilter = {
            id: 'draught',
            label: Locale.trans('boat.draught'),
            value: filters.draught,
            type: 'text',
        };

        return [
            boatTypeFilter,
            lengthFilter,
            widthFilter,
            draughtFilter,
        ].filter(f => f !== null);
    };

    handleAdvancedSearchChange = values => {
        const newFilters = {...this.state.filters};
        const kValues = Object.keys(values);
        for (let iK = 0; iK < kValues.length; iK++) {
            const k = kValues[iK];
            newFilters[k] = values[k];
        }
        this.setState({
            filters: newFilters
        }, this.updateFilters);
    };

    handleFilterChange = (name, values) => {
        const newFilters = { ...this.state.filters };
        newFilters[name] = values;
        this.setState({ 
            filters: newFilters
        }, this.updateFilters);
    };

    receiveBoats = () => {
        this.forceUpdate();
    };

    receiveAbsences = () => {
        this.updateFilters();
    };

    receivePlaces = () => {
        this.setState(
            {
                places: PlaceStore.getByHarbour(
                    HarbourStore.getCurrentHarbour(),
                ).sort(cmpPlaceByCode),
            },
            this.updateFilters,
        );
    };

    gotoPrevWeek = () => {
        this.setState(({ startDate }) => {
            const newDate = startDate.clone();
            newDate.subtract(1, 'week');
            return {
                startDate: newDate,
            };
        });
    };

    gotoNextWeek = () => {
        this.setState(({ startDate }) => {
            const newDate = startDate.clone();
            newDate.add(1, 'week');
            return {
                startDate: newDate,
            };
        });
    };

    changeWeek = (newYear, newWeek) => {
        let year;
        let week;
        if (newWeek === 0) {
            year = newYear - 1;
            week = moment()
                .year(year)
                .weeksInYear();
        } else if (newWeek === this.state.startDate.weeksInYear()) {
            week = 1;
            year = newYear + 1;
        } else {
            week = newWeek;
            year = newYear;
        }
        const newDate = moment()
            .year(year)
            .day(1)
            .week(week);

        this.setState({
            startDate: newDate,
            week,
            year
        }, this.updateFilters);
    };

    changeMonth = (newYear, newMonth) => {
        const newDate = moment()
            .year(newYear)
            .day(1)
            .month(newMonth - 1);
        this.setState({
            startDate: newDate
        }, this.updateFilters);
    };

    goToAbsence = absence => {
        this.changeWeek(
            moment(absence.departureDate).year(),
            moment(absence.departureDate).isoWeek(),
        );
    };

    acceptAbsence = absence => {
        AbsenceActions.edit(HarbourStore.getCurrentHarbour(), absence.id, {
            status: AbsenceConstants.APPROVED,
        }).then(() => {
            ToastActions.createToastSuccess('Absence validée');
        });
    };

    acceptAnticipatedReturn = absence => {
        AbsenceActions.edit(HarbourStore.getCurrentHarbour(), absence.id, {
            anticipatedReturnStatus: AbsenceConstants.APPROVED,
        }).then(() => {
            ToastActions.createToastSuccess('Retour anticipé validé');
        });
    };

    toggleDateFilter = date => {
        const dateFilter = this.state.filters.date;
        const dateStr = DateService.formatApi(date);
        const iDate = dateFilter.indexOf(dateStr);
        if (iDate !== -1) {
            dateFilter.splice(iDate, 1);
        } else {
            dateFilter.push(dateStr);
        }
        this.updateFilters();
    };

    render() {
        return (
            <div>
                <div className="actions-row" style={{ display: 'block' }}>
                    <Button
                        type="primary"
                        icon="plus"
                        onClick={this.showCreateAnticipatedReturnModal}
                        style={{ float: 'right', zIndex: 3 }}
                    >
                        Ajouter un retour anticipé
                    </Button>
                    <Button
                        type="primary"
                        icon="plus"
                        onClick={this.showCreateAbsenceModal}
                        style={{ float: 'right', zIndex: 3 }}
                    >
                        Ajouter une absence
                    </Button>
                </div>
                {this.renderCalendar()}

                {SecurityService.isGranted(['captain']) && (
                    <React.Fragment>
                        {this.renderPendingAbsences()}
                        {this.renderPendingAnticipatedReturns()}
                    </React.Fragment>
                )}

                <CreateAbsenceModal
                    onCancel={this.hideCreateAbsenceModal}
                    visible={this.state.createAbsenceVisible}
                />
                <CreateAnticipatedReturnModal
                    onCancel={this.hideCreateAnticipatedReturnModal}
                    visible={this.state.createAnticipatedReturnVisible}
                />
            </div>
        );
    }

    renderPendingAbsences() {
        const absences = AbsenceStore.getByHarbour(
            HarbourStore.getCurrentHarbour(),
        );
        const pendingAbsences = absences.filter(
            a => a.status === AbsenceConstants.PENDING,
        );

        if (pendingAbsences.length === 0) {
            return null;
        }

        const columns = [
            {
                title: Locale.trans('absence.place'),
                key: 'place',
                width: '8%',
                render: absence => {
                    const place =
                        absence.place && PlaceStore.getById(absence.place.id);
                    return place ? place.code : '';
                },
            },
            {
                title: Locale.trans('absence.owners'),
                key: 'owners',
                width: '200px',
                render: absence => {
                    const boat = absence.boat
                        ? BoatStore.getById(absence.boat.id)
                        : null;
                    return boat
                        ? boat.owners.map(o => (
                              <div key={o.id}>
                                  {o.firstName} {o.lastName}
                              </div>
                          ))
                        : '';
                },
            },
            {
                title: Locale.trans('absence.boat'),
                key: 'boat',
                width: '200px',
                render: absence => {
                    const boat = absence.boat
                        ? BoatStore.getById(absence.boat.id)
                        : null;
                    return boat ? boat.name : '';
                },
            },
            {
                title: Locale.trans('boat.size'),
                key: 'size',
                width: '110px',
                render: absence => {
                    const boat = absence.boat
                        ? BoatStore.getById(absence.boat.id)
                        : null;
                    return boat
                        ? `${boat.length || 0}m - ${boat.width || 0}m`
                        : '';
                },
            },
            {
                title: Locale.trans('absence.departureDate'),
                key: 'departureDate',
                width: '110px',
                render: absence =>
                    DateService.formatApiToDisplay(absence.departureDate),
            },
            {
                title: Locale.trans('absence.returnDate'),
                key: 'returnDate',
                width: '310px',
                render: absence =>
                    DateService.formatApiToDisplay(absence.returnDate),
            },
            {
                key: 'action',
                width: '8%',
                render: this.renderPendingAbsenceActions,
            },
        ];

        return (
            <div style={{ marginTop: '16px' }}>
                <h2>Demandes d&apos;absences en attente de prise en compte</h2>
                <Table
                    dataSource={pendingAbsences}
                    rowKey="id"
                    columns={columns}
                    pagination={false}
                    className="row-clickable"
                    onRow={a => ({
                        onClick: () => {
                            this.goToAbsence(a);
                        },
                    })}
                    locale={Locale.Table}
                />
            </div>
        );
    }

    renderPendingAbsenceActions = absence => (
        <div className="pull-right">
            <Button
                type="success"
                shape="circle"
                icon="check"
                onClick={e => {
                    this.acceptAbsence(absence);
                    e.stopPropagation();
                }}
            />
        </div>
    );

    renderPendingAnticipatedReturns() {
        const absences = AbsenceStore.getByHarbour(
            HarbourStore.getCurrentHarbour(),
        );
        const pendingReturns = absences.filter(
            a => a.anticipatedReturnStatus === AbsenceConstants.PENDING,
        );

        if (pendingReturns.length === 0) {
            return null;
        }

        const columns = [
            {
                title: Locale.trans('absence.place'),
                key: 'place',
                width: '8%',
                render: absence => {
                    const place =
                        absence.place && PlaceStore.getById(absence.place.id);
                    return place ? place.code : '';
                },
            },
            {
                title: Locale.trans('absence.owners'),
                key: 'owners',
                width: '200px',
                render: absence => {
                    const boat = absence.boat
                        ? BoatStore.getById(absence.boat.id)
                        : null;
                    return boat
                        ? boat.owners.map(o => (
                              <div key={o.id}>
                                  {o.firstName} {o.lastName}
                              </div>
                          ))
                        : '';
                },
            },
            {
                title: Locale.trans('absence.boat'),
                key: 'boat',
                width: '200px',
                render: absence => {
                    const boat = absence.boat
                        ? BoatStore.getById(absence.boat.id)
                        : null;
                    return boat ? boat.name : '';
                },
            },
            {
                title: Locale.trans('boat.size'),
                key: 'size',
                width: '110px',
                render: absence => {
                    const boat = absence.boat
                        ? BoatStore.getById(absence.boat.id)
                        : null;
                    return boat
                        ? `${boat.length || 0}m - ${boat.width || 0}m`
                        : '';
                },
            },
            {
                title: Locale.trans('absence.departureDate'),
                key: 'departureDate',
                width: '110px',
                render: absence =>
                    DateService.formatApiToDisplay(absence.departureDate),
            },
            {
                title: Locale.trans('absence.returnDate'),
                key: 'returnDate',
                width: '110px',
                render: absence =>
                    DateService.formatApiToDisplay(absence.returnDate),
            },
            {
                title: Locale.trans('absence.anticipatedReturnDate'),
                key: 'anticipatedReturnDate',
                width: '200px',
                render: absence =>
                    DateService.formatApiToDisplay(
                        absence.anticipatedReturnDate,
                    ),
            },
            {
                key: 'action',
                width: '8%',
                render: this.renderAnticipatedReturnActions,
            },
        ];

        return (
            <div style={{ marginTop: '16px' }}>
                <h2>Retours anticipés en attente de prise en compte</h2>
                <Table
                    dataSource={pendingReturns}
                    rowKey="id"
                    columns={columns}
                    pagination={false}
                    className="row-clickable"
                    onRow={a => ({
                        onClick: () => {
                            this.goToAbsence(a);
                        },
                    })}
                    locale={Locale.Table}
                />
            </div>
        );
    }

    renderAnticipatedReturnActions = absence => (
        <div className="pull-right">
            <Button
                type="success"
                shape="circle"
                icon="check"
                onClick={e => {
                    this.acceptAnticipatedReturn(absence);
                    e.stopPropagation();
                }}
            />
        </div>
    );

    renderCalendar() {
        const { filteredPlaces, startDate } = this.state;

        const previousColumns = [
            {
                title: Locale.trans('absence.place'),
                key: 'place',
                render: (_, record) => {
                    const { boat } = record;
                    if (!boat) {
                        return record.code;
                    }
                    return (
                        <Popover
                            title={record.code}
                            content={
                                <div>
                                    <p>
                                        Dimension : {record.length || 0}m x{' '}
                                        {record.width || 0}m x{' '}
                                        {record.draught || 0}m
                                    </p>
                                    <p>
                                        Plaisancier :{' '}
                                        {boat.owners
                                            .map(
                                                o =>
                                                    `${o.firstName} ${o.lastName}`,
                                            )
                                            .join(', ')}
                                    </p>
                                    <p>
                                        Bateau : {boat.name} ({boat.length || 0}
                                        m x {boat.width || 0}m x{' '}
                                        {boat.draught || 0}m)
                                    </p>
                                </div>
                            }
                        >
                            {record.code}
                        </Popover>
                    );
                },
            },
        ];

        const year = startDate.year();
        const week = startDate.week();

        return (
            <div className="absence-calendar">
                <Row gutter={8}>
                    <Col span={8} />
                    <Col span={8}>{this.renderMonthSelector()}</Col>
                    <Col span={8} />
                </Row>

                <br />

                <RoolingWeeksTable
                    dataSource={filteredPlaces}
                    year={year}
                    week={week}
                    countDisplayedWeeks={4}
                    previousColumns={previousColumns}
                    renderCell={this.renderCell}
                    selectedDates={this.state.filters.date}
                    onDateClick={this.toggleDateFilter}
                />
                <div className="legends">
                    <div
                        role="button"
                        tabIndex={0}
                        className={
                            this.state.filters.status.indexOf(
                                'approved-absence',
                            ) !== -1
                                ? 'selected'
                                : ''
                        }
                        onClick={() => {
                            this.toggleStatusFilter('approved-absence');
                        }}
                        onKeyPress={() => {
                            this.toggleStatusFilter('approved-absence');
                        }}
                    >
                        <div className="legend-thumbnail approved" />
                        {Locale.trans('absence.legend.approved')}
                    </div>
                    <div
                        role="button"
                        tabIndex={0}
                        className={
                            this.state.filters.status.indexOf(
                                'pending-absence',
                            ) !== -1
                                ? 'selected'
                                : ''
                        }
                        onClick={() => {
                            this.toggleStatusFilter('pending-absence');
                        }}
                        onKeyPress={() => {
                            this.toggleStatusFilter('pending-absence');
                        }}
                    >
                        <div className="legend-thumbnail pending" />
                        {Locale.trans('absence.legend.pending')}
                    </div>
                    <div
                        role="button"
                        tabIndex={0}
                        className={
                            this.state.filters.status.indexOf('present') !== -1
                                ? 'selected'
                                : ''
                        }
                        onClick={() => {
                            this.toggleStatusFilter('present');
                        }}
                        onKeyPress={() => {
                            this.toggleStatusFilter('present');
                        }}
                    >
                        <div className="legend-thumbnail present" />
                        {Locale.trans('absence.legend.present')}
                    </div>
                    <div
                        role="button"
                        tabIndex={0}
                        className={
                            this.state.filters.status.indexOf(
                                'pending-anticipated-return',
                            ) !== -1
                                ? 'selected'
                                : ''
                        }
                        onClick={() => {
                            this.toggleStatusFilter(
                                'pending-anticipated-return',
                            );
                        }}
                        onKeyPress={() => {
                            this.toggleStatusFilter(
                                'pending-anticipated-return',
                            );
                        }}
                    >
                        <div className="legend-thumbnail anticipated-return">
                            <Icon type="exclamation-circle-o" />
                        </div>
                        {Locale.trans('absence.legend.anticipated-return')}
                    </div>
                </div>
                <AdvancedSearch
                    filterDefs={this.getFilterDefinitions()}
                    onFiltersChange={this.handleAdvancedSearchChange}
                />
            </div>
        );
    }

    renderCell = (place, date) => {
        const absences = AbsenceStore.getByPlaceAndDate(place, date);
        const absence = absences.reduce(
            (a, current) =>
                a === null || current.status === AbsenceConstants.APPROVED
                    ? current
                    : a,
            null,
        );

        // We don't take in count the last day of the absence to be shown in the calendar.
        if (
            !absence ||
            absence.status === AbsenceConstants.REFUSED ||
            absence.returnDate === DateService.formatApi(date)
        ) {
            return null;
        }

        const stateClass = absence.status;

        const anticipatedReturn = AbsenceStore.getOnePendingAnticipatedReturnByPlaceAndDate(
            place,
            date,
        );
        const anticipatedReturnClass = anticipatedReturn
            ? 'anticipated-return'
            : '';

        return (
            <div
                className={`absence-planning-day ${stateClass} ${anticipatedReturnClass}`}
            >
                {anticipatedReturn && <Icon type="exclamation-circle-o" />}
            </div>
        );
    };

    renderMonthSelector() {
        return (
            <Row gutter={8}>
                <Col span={3}>
                    <Button
                        shape="circle"
                        className="pull-right"
                        icon="left"
                        onClick={this.gotoPrevWeek}
                    />
                </Col>
                <Col span={9}>{this.renderSelectMonth()}</Col>
                <Col span={9}>{this.renderSelectYear()}</Col>
                <Col span={3}>
                    <Button
                        shape="circle"
                        icon="right"
                        onClick={this.gotoNextWeek}
                    />
                </Col>
            </Row>
        );
    }

    renderSelectMonth = () => {
        const { startDate } = this.state;

        const year = startDate.year();
        const month = startDate.month();
        const months = moment.months();

        return (
            <Select
                value={month + 1}
                onChange={newMonth => {
                    this.changeMonth(year, newMonth);
                }}
                options={months.map((m, i) => ({
                    value: i + 1,
                    label: StringService.capitalize(m),
                }))}
            />
        );
    };

    renderSelectYear = () => {
        const { startDate } = this.state;

        const year = startDate.year();
        const month = startDate.month();

        const options = [];
        for (
            let currentYear = year - 5;
            currentYear <= year + 5;
            currentYear++
        ) {
            options.push({ value: currentYear, label: currentYear });
        }

        return (
            <Select
                value={year}
                onChange={newYear => {
                    this.changeMonth(newYear, month);
                }}
                options={options}
            />
        );
    };
}

import React, { useState, useEffect, useMemo } from 'react';

import { Table, Button } from 'antd/lib/index';
import TableColumnFilter from 'components/table/TableColumnFilter.jsx';
import FilterIcon from 'components/table/FilterIcon.jsx';
import CreatePartnerModal from './CreatePartnerModal.jsx';
import EditPartnerModal from './EditPartnerModal.jsx';
import LoadingIcon from 'components/LoadingIcon.jsx';

import Locale from 'locale/LocaleFactory';
import ArrayService from 'services/utils/ArrayService';
import FilterService from 'services/utils/FilterService';

import PartnerActions from 'actions/PartnerActions';
import PartnerStore from 'stores/PartnerStore';
import HarbourStore from 'stores/HarbourStore';
import PartnerTypeConstants from 'constants/PartnerTypeConstants.js';

import useHarbourModules, { ADVANCED } from 'hooks/useHarbourModules';

/**
 * The list of the partners of a harbour
 */
export default function PartnerConfiguration() {
    const modules = useHarbourModules();

    const [loading, setLoading] = useState(false);
    const [partners, setPartners] = useState(
        PartnerStore.getByHarbour(HarbourStore.getCurrentHarbour()),
    );
    const [filters, setFilters] = useState({});
    const [createPartnerVisible, setCreatePartnerVisible] = useState(false);
    const [partnerToEdit, setPartnerToEdit] = useState(null);

    useEffect(() => {
        function loadPartners() {
            setLoading(true);
            PartnerActions.reloadByHarbour(
                HarbourStore.getCurrentHarbour(),
            ).finally(() => {
                setLoading(false);
            });
        }

        function reloadData() {
            loadPartners();
        }

        function onChangeCurrentHarbour() {
            reloadData();
            receivePartners();
        }

        function receivePartners() {
            setPartners(
                PartnerStore.getByHarbour(HarbourStore.getCurrentHarbour()),
            );
        }

        HarbourStore.addCurrentHarbourChangeListener(onChangeCurrentHarbour);
        PartnerStore.addChangeListener(receivePartners);

        reloadData();

        return () => {
            HarbourStore.removeCurrentHarbourChangeListener(
                onChangeCurrentHarbour,
            );
            PartnerStore.removeChangeListener(receivePartners);
        };
    }, []);

    const filteredPartners = useMemo(() => {
        function partnerMatchFilters(p) {
            return (
                FilterService.matchFilter(filters.name, p.name) &&
                FilterService.matchFilter(filters.type, p.type) &&
                FilterService.matchFilter(filters.phone, p.phone)
            );
        }
        return partners.filter(partnerMatchFilters);
    }, [partners, filters]);

    function handleFilterChange(name, values) {
        setFilters((prevFilters) => ({ ...prevFilters, [name]: values }));
    }

    function showCreatePartnerModal() {
        setCreatePartnerVisible(true);
    }
    function hideCreatePartnerModal() {
        setCreatePartnerVisible(false);
    }

    function editPartner(partner) {
        setPartnerToEdit(partner);
    }
    function hideEditPartner() {
        setPartnerToEdit(null);
    }

    const columns = [
        {
            title: Locale.trans('partner.name'),
            dataIndex: 'name',
            key: 'name',
            sorter: ArrayService.sortStringColumn('name'),
            filterIcon: (
                <FilterIcon active={filters.name && filters.name.length > 0} />
            ),
            filterDropdown: (
                <TableColumnFilter
                    name="name"
                    selectedValues={filters.name || []}
                    values={ArrayService.getValuesOfField(partners, 'name').map(
                        (r) => ({
                            text: r,
                            value: r,
                        }),
                    )}
                    onChange={handleFilterChange}
                />
            ),
        },
        {
            title: Locale.trans('partner.type'),
            key: 'type',
            render: (partner) => Locale.trans(`partner.types.${partner.type}`),
            sorter: ArrayService.sortStringColumn((p) =>
                Locale.trans(`partner.types.${p.type}`),
            ),
            filterIcon: (
                <FilterIcon active={filters.type && filters.type.length > 0} />
            ),
            filterDropdown: (
                <TableColumnFilter
                    name="type"
                    selectedValues={filters.type || []}
                    values={Object.values(PartnerTypeConstants).map((r) => ({
                        text: Locale.trans(`partner.types.${r}`),
                        value: r,
                    }))}
                    onChange={handleFilterChange}
                />
            ),
        },
        {
            title: Locale.trans('partner.phone'),
            key: 'phone',
            dataIndex: 'phone',
            sorter: ArrayService.sortStringColumn('phone'),
            filterIcon: (
                <FilterIcon
                    active={filters.phone && filters.phone.length > 0}
                />
            ),
            filterDropdown: (
                <TableColumnFilter
                    name="phone"
                    selectedValues={filters.phone || []}
                    values={ArrayService.getValuesOfField(
                        partners,
                        'phone',
                    ).map((r) => ({
                        text: r,
                        value: r,
                    }))}
                    onChange={handleFilterChange}
                />
            ),
        },
        {
            title: Locale.trans('partner.websites'),
            key: 'websites',
            render: (p) =>
                p.websites &&
                p.websites.map((w) => (
                    <div>
                        <a href={w.url}>{w.label}</a>
                    </div>
                )),
        },
        {
            title: Locale.trans('address.title'),
            key: 'address',
            render: (p) =>
                p.address &&
                p.address.street &&
                p.address.city &&
                `${p.address.street} - ${p.address.city}`,
        },
    ];

    return (
        <div className="partner-list">
            <Table
                dataSource={filteredPartners}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
                loading={loading && { indicator: <LoadingIcon /> }}
                onRow={(a) => ({
                    className: 'clickable',
                    onClick: () => editPartner(a),
                })}
            />
            {!loading && modules.info >= ADVANCED && (
                <div
                    className="actions-row"
                    style={{
                        marginTop: filteredPartners.length > 0 ? '-50px' : 0,
                    }}
                >
                    <Button
                        type="primary"
                        icon="plus"
                        onClick={showCreatePartnerModal}
                    >
                        {Locale.trans('partner.creation.title')}
                    </Button>
                </div>
            )}

            <CreatePartnerModal
                onCancel={hideCreatePartnerModal}
                visible={createPartnerVisible}
                onCreate={(newPartner) => {
                    hideCreatePartnerModal();
                    editPartner(newPartner);
                }}
            />
            <EditPartnerModal
                partner={partnerToEdit}
                onCancel={hideEditPartner}
                visible={!!partnerToEdit}
            />
        </div>
    );
}

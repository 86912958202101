import React from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';

import LoginStore from 'stores/LoginStore';

import RoleConstants from 'constants/RoleConstants';
import SecuritySercive from 'services/SecurityService';

function cmpBoatByName(u1, u2) {
    const uN1 = u1.name;
    const uN2 = u2.name;
    return uN1 > uN2 ? 1 : -1;
}

class YachtsmanUserForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const user = this.getUserFromValues(values);

                // Boats
                const allBoats = this.props.boats;
                const initialBoatsId =
                    this.props.user && this.props.user.boats
                        ? this.props.user.boats.map(o => o.id.toString())
                        : [];
                const boatsIds = values.boats_ids || [];

                const boats = {
                    created: boatsIds
                        .filter(id => initialBoatsId.indexOf(id) < 0)
                        .map(id => (allBoats || []).find(c => c.id.toString() === id.toString())),
                    deleted: initialBoatsId
                        .filter(id => boatsIds.indexOf(id) < 0)
                        .map(id => (allBoats || []).find(c => c.id.toString() === id.toString())),
                };

                this.props.onSubmit(user, boats);
            }
        });
    };

    getUserFromValues = (values) => {
        const boat = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.map((k) => {
            if (k.endsWith('_ids') || k === 'passwordConfirm' || k === 'boats') {
                return null;
            }
            if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                boat[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                boat[k] = values[k];
            }
            return null;
        });
        return boat;
    };

    checkPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('plainPassword')) {
            callback('Les mots de passe ne sont pas identiques!');
        } else {
            callback();
        }
    };

    render() {
        const { form, user, loading } = this.props;
        const isEditing = !!user;

        const loggedUser = LoginStore.getUser();
        const canEditPassword = user && (loggedUser.id === user.id || SecuritySercive.isGranted([RoleConstants.ADMINISTRATOR]));

        return (
            <Form onSubmit={this.handleSubmit}>
                <FormItem.Input
                    id="firstName"
                    required
                    label={Locale.trans('user.firstName')}
                    form={form}
                    validateStatus={this.getValidateStatus('firstName')}
                    help={this.getHelp('firstName')}
                    hasFeedback
                />
                <FormItem.Input
                    id="lastName"
                    required
                    label={Locale.trans('user.lastName')}
                    form={form}
                    validateStatus={this.getValidateStatus('lastName')}
                    help={this.getHelp('lastName')}
                    hasFeedback
                />
                <FormItem.Input
                    id="email"
                    required
                    label={Locale.trans('user.email')}
                    form={form}
                    validateStatus={this.getValidateStatus('email')}
                    help={this.getHelp('email')}
                    hasFeedback
                />
                <FormItem.PhoneInput
                    id="phoneNumber"
                    label={Locale.trans('user.phoneNumber')}
                    form={form}
                    validateStatus={this.getValidateStatus('phoneNumber')}
                    help={this.getHelp('phoneNumber')}
                    hasFeedback
                />
                <FormItem.Input
                    id="nationality"
                    label={Locale.trans('user.nationality')}
                    form={form}
                    validateStatus={this.getValidateStatus('nationality')}
                    help={this.getHelp('nationality')}
                    hasFeedback
                />
                <FormItem.Select
                    id="locale"
                    required
                    label={Locale.trans('user.locale')}
                    form={form}
                    options={Locale.getAvailableLocales().map((l) => ({
                        value: l,
                        label: Locale.trans(`locale.${l}`),
                    }))}
                />

                {this.renderBoatsField()}

                <FormItem.Input
                    id="username"
                    required
                    label={Locale.trans('user.username')}
                    form={form}
                    validateStatus={this.getValidateStatus('username')}
                    help={this.getHelp('username')}
                    hasFeedback
                />

                {loggedUser.isSuperAdmin && (
                    <React.Fragment>
                        <FormItem.Checkbox
                            id="showSecurityModule"
                            label="Module sécurité"
                            form={form}
                            validateStatus={this.getValidateStatus('showSecurityModule')}
                            help={this.getHelp('showSecurityModule')}
                            hasFeedback
                        />
                        <FormItem.Checkbox
                            id="showReportingModule"
                            label="Module reporting"
                            form={form}
                            validateStatus={this.getValidateStatus('showReportingModule')}
                            help={this.getHelp('showReportingModule')}
                            hasFeedback
                        />
                    </React.Fragment>
                )}

                {!isEditing && this.renderPasswordFields(true)}
                {isEditing && canEditPassword && (
                    <React.Fragment>
                        <h2>Modifier le mot de passe</h2>
                        {this.renderPasswordFields(false)}
                    </React.Fragment>
                )}

                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }

    renderPasswordFields(isRequired) {
        const { form } = this.props;
        return (
            <React.Fragment>
                <FormItem.Input
                    id="plainPassword"
                    type="password"
                    required={isRequired}
                    label={Locale.trans('user.password')}
                    form={form}
                    validateStatus={this.getValidateStatus('plainPassword')}
                    help={this.getHelp('plainPassword')}
                    hasFeedback
                />
                <FormItem.Input
                    id="passwordConfirm"
                    type="password"
                    required={isRequired}
                    label={Locale.trans('user.passwordConfirm')}
                    rules={[
                        {
                            validator: this.checkPassword,
                        },
                    ]}
                    form={form}
                    validateStatus={this.getValidateStatus('passwordConfirm')}
                    help={this.getHelp('passwordConfirm')}
                    hasFeedback
                />
            </React.Fragment>
        );
    }

    renderBoatsField() {
        const { boats } = this.props;

        if (!boats || boats.length === 0) {
            return null;
        }

        const { getFieldValue } = this.props.form;
        return (
            <FormItem.Select
                id="boats_ids"
                multiple
                label={Locale.trans('user.boats')}
                initialValue={(getFieldValue('boats') || []).map(c => c.id.toString())}
                options={boats.sort(cmpBoatByName).map(c => ({
                    value: c.id,
                    label: `${c.name} (${c.immatriculation})`,
                }))}
                form={this.props.form}
            />
        );
    }
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(YachtsmanUserForm);

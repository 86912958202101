import React from 'react';
import { Row, Col, Button, Icon, Modal } from 'antd';
import ReportingFiltersForm from 'components/forms/ReportingFiltersForm';

import OutingsFrequency from 'components/charts/OutingsFrequency';
import OutingsHistory from 'components/charts/OutingsHistory';

import ReportingActions from 'actions/ReportingActions';
import ReportingFiltersStore from 'stores/ReportingFiltersStore';
import OutingCalendar from 'components/charts/OutingCalendar';
import Optimisation from 'components/charts/Optimisation';
import OutingByType from 'components/charts/OutingByType';
import OccupancyRateHistory from 'components/charts/OccupancyRateHistory';
import Top10 from 'components/charts/Top10';
import OccupancyRate from 'components/charts/OccupancyRate';
import ReportingFiltersActions from 'actions/ReportingFiltersActions';

import Locale from 'locale/LocaleFactory';

export default class Reporting extends React.Component {
    constructor() {
        super();
        this.state = {
            fields: this._createFieldsFromFilters(ReportingFiltersStore.get()),
            modalVisible: false,
        };
    }

    _createFieldsFromFilters(filters) {
        const {
            yearCompared,
            period,
            user,
            place,
            boatType,
            categorie,
        } = filters;
        return {
            yearCompared: { value: yearCompared },
            period: { value: [period.start, period.end] },
            user: { value: user },
            place: { value: place },
            boatType: { value: boatType },
            categorie: { value: categorie },
        };
    }

    componentDidMount() {
        ReportingFiltersStore.addChangeListener(this.changeFilters);
        const filters = ReportingFiltersStore.get();
        ReportingActions.reloadOutingsForCurrentHarbour(filters);
        ReportingActions.reloadOccupancyRates(filters);
        ReportingActions.reloadOptimisationForCurrentHarbour(filters);
    }

    changeFilters = () => {
        const filters = ReportingFiltersStore.get();
        this.setState(({ fields }) => ({
            fields: { ...fields, ...this._createFieldsFromFilters(filters) },
        }));
        ReportingActions.reloadOutingsForCurrentHarbour(filters);
        ReportingActions.reloadOccupancyRates(filters);
        ReportingActions.reloadOptimisationForCurrentHarbour(filters);
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = () => {
        const { fields } = this.state;
        let place = null;
        let zone = null;
        if (fields.place.value === null || fields.place.value.indexOf('Z') === -1) {
            place = fields.place.value;
        } else {
            zone = fields.place.value.slice(1);
        }
        const filters = {
            yearCompared: fields.yearCompared.value,
            period: {
                start: fields.period.value[0],
                end: fields.period.value[1],
            },
            user: fields.user.value,
            place: place,
            zone: zone,
            boatType: fields.boatType.value,
            categorie: fields.categorie.value,
        };
        ReportingFiltersActions.change(filters);
        this.setState({
            modalVisible: false,
        });
    };

    showModal = () => {
        this.setState({
            modalVisible: true,
        });
    };

    handleCancel = () => {
        this.setState({
            modalVisible: false,
        });
    };

    render() {
        const { modalVisible, fields } = this.state;
        const gutter = {
            xs: 8,
            sm: 16,
            md: 16,
            lg: 16,
        };

        return (
            <div id="reporting-page">
                <Row className="filtersPart">
                    <div>
                        <Button onClick={() => this.showModal()}>
                            <Icon type="filter" />
                        </Button>
                        <Modal
                            title={Locale.trans('reporting.filters.title')}
                            visible={modalVisible}
                            onCancel={this.handleCancel}
                            onOk={this.handleSubmit}
                        >
                            <ReportingFiltersForm
                                fields={fields}
                                onChange={this.handleFormChange}
                            />
                        </Modal>
                    </div>
                </Row>
                <Row gutter={gutter}>
                    <Col className="gutter-row" span={4}>
                        <div>
                            <Row className="fifth">
                                <OccupancyRate />
                            </Row>
                            <Row className="four-fifth">
                                <Top10 />
                            </Row>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={20}>
                        <Row className="mainPart" gutter={gutter}>
                            <Col className="gutter-row mainPart" span={14}>
                                <Optimisation />
                            </Col>
                            <Col className="gutter-row mainPart" span={10}>
                                <OutingCalendar />
                            </Col>
                        </Row>
                        <Row className="mainPart" gutter={gutter}>
                            <Col className="gutter-row" span={14}>
                                <Row className="half">
                                    <OccupancyRateHistory />
                                </Row>
                                <Row className="half">
                                    <OutingsHistory />
                                </Row>
                            </Col>
                            <Col className="gutter-row" span={10}>
                                <Row className="three-quarter">
                                    <OutingByType />
                                </Row>
                                <Row className="quarter">
                                    <OutingsFrequency />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

// @flow

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}harbours`;

class HarbourService extends ServiceBase {
    constructor() {
        super(URL);
    }
}

export default new HarbourService();

import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'antd';

import TableColumnFilter from 'components/table/TableColumnFilter.jsx';
import FilterIcon from 'components/table/FilterIcon.jsx';
import FilterService from 'services/utils/FilterService';

import HarbourStore from 'stores/HarbourStore';
import CommitmentStore from 'stores/CommitmentStore';
import UserStore from 'stores/UserStore';
import CommitmentActions from 'actions/CommitmentActions';
import UserActions from 'actions/UserActions';
import Locale from 'locale/LocaleFactory';
import DateService from 'services/utils/DateService';
import moment from "moment";

function cmpCommitmentsByStartDate(c1, c2) {
    const d1 = moment(c1.startDate);
    const d2 = moment(c2.startDate);
    return d1 > d2 ? 1 : -1;
}

function CommitmentHistoryModal({ visible, onCancel }) {
    const harbour = HarbourStore.getCurrentHarbour();

    const [commitments, setCommitments] = useState([]);
    const [users, setUsers] = useState([]);

    const currentHarbourId = harbour.id;
    useEffect(() => {
        const currentHarbour = HarbourStore.getById(currentHarbourId);
        function refreshCommitments() {
            setCommitments(
                CommitmentStore.getByHarbour(currentHarbour).sort(cmpCommitmentsByStartDate).reverse()
            );
        }

        refreshCommitments();
        CommitmentActions.reloadByHarbour(currentHarbour);

        CommitmentStore.addChangeListener(refreshCommitments);
        return () => {
            CommitmentStore.removeChangeListener(refreshCommitments);
        };
    }, [currentHarbourId]);

    useEffect(() => {
        const currentHarbour = HarbourStore.getById(currentHarbourId);
        function refreshUsers() {
            setUsers(UserStore.getByHarbour(currentHarbour));
        }

        refreshUsers();
        UserActions.reloadByHarbour(currentHarbour);

        UserStore.addChangeListener(refreshUsers);
        return () => {
            UserStore.removeChangeListener(refreshUsers);
        };
    }, [currentHarbourId]);

    const userTypes = ['agent', 'supervisor'];

    return (
        <Modal
            title={Locale.trans('commitment.historyModal.title')}
            visible={visible}
            onCancel={onCancel}
            footer={null}
            className="commitment-history"
        >
            {userTypes.map(userType => {
                return (
                    <UserTypeTable
                        key={userType}
                        userType={userType}
                        commitments={commitments}
                        users={users}
                    />
                );
            })}
        </Modal>
    );
}

function UserTypeTable({ userType, commitments, users }) {
    const [filters, setFilters] = useState({
        user: [],
    });

    function commitmentMatchFilter(commitment) {
        return FilterService.matchFilter(filters.user, commitment.user.id);
    }

    function handleFilterChange(name, values) {
        const newFilters = { ...filters };
        newFilters[name] = values;
        setFilters(newFilters);
    }

    const columns = [
        {
            title: Locale.trans('commitment.user'),
            key: 'user',
            render: commitment => {
                const user = users.find(u => u.id === commitment.user.id);
                return user
                    ? `${user.firstName} ${user.lastName}`
                    : Locale.trans('commitment.unknown');
            },
            filterIcon: <FilterIcon active={filters.user.length > 0} />,
            filterDropdown: (
                <TableColumnFilter
                    name="user"
                    selectedValues={filters.user}
                    values={users.map(u => ({
                        text: `${u.firstName} ${u.lastName}`,
                        value: u.id,
                    }))}
                    onChange={handleFilterChange}
                />
            ),
        },
        {
            title: Locale.trans('commitment.startDate'),
            key: 'startDate',
            render: commitment => {
                return DateService.formatApiToDisplay(
                    commitment.startDate,
                    true
                );
            },

            //sorter: cmpCommitmentsByStartDate,
            //defaultSortOrder: 'descend',
        },
        {
            title: Locale.trans('commitment.endDate'),
            key: 'endDate',
            render: commitment => {
                return commitment.endDate
                    ? DateService.formatApiToDisplay(commitment.endDate, true)
                    : Locale.trans('commitment.inProgress');
            },
        },
    ];
    return (
        <section className="commitment-history__section">
            <h2 className="commitment-history__section__title">
                {Locale.trans(`commitment.userTypes.${userType}`)}
            </h2>
            <Table
                className="commitment-history__section__table"
                dataSource={commitments
                    .filter(c => c.userType === userType)
                    .filter(commitmentMatchFilter)}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
            />
        </section>
    );
}

export default CommitmentHistoryModal;

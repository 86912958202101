export default {
    FILTERS_UPDATED: 'REPORTING_FILTERS_UPDATED',
    FILTERS_BOAT_TYPES_UPDATED: 'FILTERS_BOAT_TYPES_UPDATED',
    FILTERS_BOAT_CATEGORIES_UPDATED: 'FILTERS_BOAT_CATEGORIES_UPDATED',
    OUTINGS_RECEIVED: 'REPORTING_OUTINGS_RECEIVED',
    OUTINGS_BY_DAY_RECEIVED: 'REPORTING_OUTINGS_BY_DAY_RECEIVED',
    OUTINGS_BY_BOAT_TYPE_RECEIVED: 'REPORTING_OUTINGS_BY_BOAT_TYPE_RECEIVED',
    OCCUPANCY_RATES_RECEIVED: 'OCCUPANCY_RATES_RECEIVED',
    CURRENT_OCCUPANCY_RATE_RECEIVED: 'CURRENT_OCCUPANCY_RATE_RECEIVED',
    OUTINGS_BY_BOAT_RECEIVED: 'REPORTING_OUTINGS_BY_BOAT_RECEIVED',
    OPTIMISATION_RECEIVED: 'REPORTING_OPTIMISATION_RECEIVED',

    DISPLAYS: {
        DAYS: 'reporting_display_days',
        WEEK: 'reporting_display_week',
        MONTH: 'reporting_display_month',
    },

    TOP_10_FILTER: {
        NIGHT: 'reporting_top10_night',
        DAY: 'reporting_top10_day',
        ALL: 'reporting_top10_all',
    }
};

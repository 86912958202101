export default {
    RECEIVE_ABSENCES_OF_HARBOUR: 'RECEIVE_ABSENCES_OF_HARBOUR',
    RECEIVE_ABSENCE_OF_HARBOUR: 'RECEIVE_ABSENCE_OF_HARBOUR',
    DELETE_ABSENCE_OF_HARBOUR: 'DELETE_ABSENCE_OF_HARBOUR',

    // Status
    PENDING: 'pending',
    APPROVED: 'approved',
    REFUSED: 'refused',
};

import React, { useEffect, useState } from 'react';
import BaseInput from '@opuscapita/react-markdown';
import Locale from 'locale/LocaleFactory';
import MDReactComponent from 'markdown-react-js';


const MarkdownInput = ({ key, initialValue, onChange, label }) => {
    const [initialValueStored, setInitialValue] = useState('');
    const [value, setValue] = useState('');
    useEffect(() => {
        setInitialValue(initialValue);
        onChange(initialValue);
        setValue(initialValue);
    }, [key, initialValue, onChange]);
    return (
        <div>
            {label && <h3>{label}</h3>}
            <BaseInput
                value={initialValueStored}
                onChange={(val) => {
                    onChange(val);
                    setValue(val);
                }}
                showFullScreenButton
                hideToolbar
                autoFocus={false}
                readOnly={false}
            />
            <h3>{Locale.trans('markdown.help')}</h3>
            <ul>
                <li>{Locale.trans('markdown.link')}</li>
                <li>{Locale.trans('markdown.h1')}</li>
                <li>{Locale.trans('markdown.italic')}</li>
            </ul>
            <h3>{Locale.trans('markdown.preview')}</h3>
            <div style={{ border: '1px solid #d9d9d9' }}>
                <MDReactComponent

                    text={value}
                    onIterate={(Tag, props, children, level) => {
                        if (!children) {
                            return <Tag {...props} />;
                        }
                        let newChildren = [...children];
                        const newProps = {...props};
                        if (Tag === 'p') {
                            newChildren = newChildren.map((item) => {
                                if (item === '\n') {
                                    return <br />;
                                }
                                return item;
                            });
                        }
                        if (!newProps.style) {
                            newProps.style = {};
                        }
                        if (Tag === 'h1') {
                            newProps.style.color = '#f59e00';
                        }
                        newProps.style.textAlign = 'center';
                        return <Tag {...newProps}>{newChildren}</Tag>;
                    }}
                />
            </div>
        </div>
    );
};
export default MarkdownInput;

import React from 'react';

import PlaceList from './PlaceList';
import PontoonList from './PontoonList';
import LocaleFactory from 'locale/LocaleFactory';

export default function PlacesConfiguration() {
    return (
        <div className="places-configuration">
            <fieldset className="bold">
                <legend>{LocaleFactory.trans('places')}</legend>
                <PlaceList />
            </fieldset>
            <fieldset className="bold">
                <legend>{LocaleFactory.trans('pontoons')}</legend>
                <PontoonList />
            </fieldset>
        </div>
    );
}

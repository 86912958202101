import ActionsBase from 'actions/ActionsBase';
import dispatcher from 'dispatchers/AppDispatcher';
import ReportingConstants from 'constants/ReportingConstants';

import HarbourStore from 'stores/HarbourStore';

import ReportingService from 'services/ReportingService';

import moment, { Moment } from 'moment';
import ReportingFiltersStore from 'stores/ReportingFiltersStore';

class ReportingActions extends ActionsBase {
    reloadOutingsForCurrentHarbour = (filters: any) =>
        new Promise(
            (resolve: () => void, reject: (response: Object) => void) => {
                const harbour = HarbourStore.getCurrentHarbour();
                if(!harbour) {
                    resolve();
                    return;
                }

                ReportingService.getOutingsByHarbour(harbour.id, filters)
                    .then((outings: any) =>
                        this.handleSuccess(
                            ReportingConstants.OUTINGS_RECEIVED,
                            {
                                harbour,
                                outings,
                            },
                            resolve,
                        ),
                    )
                    .catch((err: any) => this.handleError(err, reject));
                ReportingService.getOutingsByBoatTypes(harbour.id, filters)
                    .then((outings: any) =>
                        this.handleSuccess(
                            ReportingConstants.OUTINGS_BY_BOAT_TYPE_RECEIVED,
                            {
                                harbour,
                                outings,
                            },
                            resolve,
                        ),
                    )
                    .catch((err: any) => this.handleError(err, reject));
                ReportingService.getOutingsByBoats(harbour.id, filters)
                    .then((outings: any) =>
                        this.handleSuccess(
                            ReportingConstants.OUTINGS_BY_BOAT_RECEIVED,
                            {
                                harbour,
                                outings,
                            },
                            resolve,
                        ),
                    )
                    .catch((err: any) => this.handleError(err, reject));
            },
        );
    reloadOccupancyRates = (filters: any) =>
        new Promise(
            (resolve: () => void, reject: (response: Object) => void) => {
                const harbour = HarbourStore.getCurrentHarbour();

                if(!harbour) {
                    resolve();
                    return;
                }

                ReportingService.getOccupancyRatesByHarbour(harbour.id, filters)
                    .then((rates: any) =>
                        this.handleSuccess(
                            ReportingConstants.OCCUPANCY_RATES_RECEIVED,
                            {
                                harbour,
                                rates,
                            },
                            resolve,
                        ),
                    )
                    .catch((err: any) => this.handleError(err, reject));
            },
        );
    handleSuccess = (event: any, payload: any, resolve: () => void) => {
        dispatcher.dispatch({
            type: event,
            payload,
        });
        resolve();
    };
    reloadPreviousMonthOccupancyRate = () =>
        new Promise(
            (resolve: () => void, reject: (response: Object) => void) => {
                const harbour = HarbourStore.getCurrentHarbour();

                if(!harbour) {
                    resolve();
                    return;
                }

                const filters = {
                    period: {
                        start: moment()
                            .subtract(1, 'month')
                            .startOf('month'),
                        end: moment()
                            .subtract(1, 'month')
                            .endOf('month'),
                    },
                    yearCompared: null,
                    user: null,
                    zone: null,
                    place: null,
                    boatType: null,
                    categorie: null,
                };

                ReportingService.getOccupancyRatesByHarbour(harbour.id, filters)
                    .then((rates: any) =>
                        this.handleSuccess(
                            ReportingConstants.OCCUPANCY_RATES_RECEIVED,
                            {
                                harbour,
                                rates,
                            },
                            resolve,
                        ),
                    )
                    .catch((err: any) => this.handleError(err, reject));
            },
        );
    reloadOptimisationForCurrentHarbour = (filters: any) =>
        new Promise(
            (resolve: () => void, reject: (response: Object) => void) => {
                const harbour = HarbourStore.getCurrentHarbour();
                if(!harbour) {
                    resolve();
                    return;
                }

                ReportingService.getOptimisationByHarbour(harbour.id, filters)
                    .then((optimisation: any) =>
                        this.handleSuccess(
                            ReportingConstants.OPTIMISATION_RECEIVED,
                            {
                                harbour,
                                optimisation,
                            },
                            resolve,
                        ),
                    )
                    .catch((err: any) => this.handleError(err, reject));
            },
        );
    reloadOutingsByDayForCurrentHarbour = (day: Moment) =>
        new Promise(
            (resolve: () => void, reject: (response: Object) => void) => {
                const harbour = HarbourStore.getCurrentHarbour();
                if(!harbour) {
                    resolve();
                    return;
                }
                const filters = ReportingFiltersStore.get();

                ReportingService.getOutingsByDayByHarbour(
                    harbour.id,
                    filters,
                    day,
                )
                    .then((outings: any) =>
                        this.handleSuccess(
                            ReportingConstants.OUTINGS_BY_DAY_RECEIVED,
                            {
                                harbour,
                                outings,
                            },
                            resolve,
                        ),
                    )
                    .catch((err: any) => this.handleError(err, reject));
            },
        );
}
export default new ReportingActions();

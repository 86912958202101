import React from 'react';


import Locale from 'locale/LocaleFactory';

import Panel from 'components/Panel';
import ReportingConstants from 'constants/ReportingConstants';
import OccupancyRateReportingStore from 'stores/OccupancyRateReportingStore';
import LineChart from 'components/charts/LineChart';
import SidebarCollapseStore from 'stores/SidebarCollapseStore';
import { comparedLabel } from 'services/ReportingLabelService';
import LineChartLegend from './LineChartLegend';
import ReportingFiltersStore from '../../stores/ReportingFiltersStore';

const margin = {
    top: 10,
    right: 20,
    bottom: 52,
    left: 30,
};

const getPercent = (nbPlacesEmpty, nbPlacesTotal) => {
    return (1 - (nbPlacesEmpty / nbPlacesTotal)) * 100;
};

const getMaximum = (maximum, value, nbPlaces) => {
    if (getPercent(maximum.placesEmpty, nbPlaces) > getPercent(value.placesEmpty, nbPlaces)) {
        return value;
    }
    return maximum;
};

const getMinimum = (minimum, value, nbPlaces) => {
    if (getPercent(minimum.placesEmpty, nbPlaces) < getPercent(value.placesEmpty, nbPlaces)) {
        return value;
    }
    return minimum;
};


const getMaxPercent = (array, nbPlacesTotal) =>
    getPercent(array.reduce((m, v) => getMinimum(m, v, nbPlacesTotal)).placesEmpty, nbPlacesTotal);
const getMinPercent = (array, nbPlacesTotal) =>
    getPercent(array.reduce((m, v) => getMaximum(m, v, nbPlacesTotal)).placesEmpty, nbPlacesTotal);

function getMaxValue(data, nbPlaces) {
    if (data.main.length === 0 && data.compared.length === 0) {
        return 0;
    }

    if (data.main.length === 0) {
        return getMaxPercent(data.compared, nbPlaces.compared);
    }
    if (data.compared.length === 0) {
        return getMaxPercent(data.main, nbPlaces.main);
    }
    const max1 = getMaxPercent(data.main, nbPlaces.main);
    const max2 = getMaxPercent(data.compared, nbPlaces.compared);
    return Math.max(max1, max2);
}
function getMinValue(data, nbPlaces) {
    if (data.main.length === 0 && data.compared.length === 0) {
        return 0;
    }
    if (data.main.length === 0) {
        return getMinPercent(data.compared, nbPlaces.compared);
    }
    if (data.compared.length === 0) {
        return getMinPercent(data.main, nbPlaces.main);
    }
    const max1 = getMinPercent(data.main, nbPlaces.main);
    const max2 = getMinPercent(data.compared, nbPlaces.compared);
    return Math.min(max1, max2);
}

export default class OccupancyRateHistory extends LineChart {
    // TODO allow change
    // TODO allow resize
    currentDisplay = ReportingConstants.DISPLAYS.DAYS;
    constructor() {
        super();
        this.filters = ReportingFiltersStore.get();
        this.nbPlaces = {
            main: 0,
            compared: 0,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        OccupancyRateReportingStore.addChangeListener(this.receiveData);
        ReportingFiltersStore.addChangeListener(this.changeFilters);
        SidebarCollapseStore.addChangeListener(() => {
            setTimeout(this.redraw, 250);
        });
    }

    changeFilters = () => {
        this.filters = ReportingFiltersStore.get();
    }

    getMargin() {
        return margin;
    }
    getXValue(d) {
        return new Date(d.day);
    }
    getYValueMain = (d) => {
        return getPercent(d.placesEmpty, this.nbPlaces.main);
    };
    getYValueCompared = (d) => {
        return getPercent(d.placesEmpty, this.nbPlaces.compared);
    };
    getYDomain(data) {
        if (this.nbPlaces.compared === 0) {
            return [
                Math.floor(getMinPercent(data.main, this.nbPlaces.main) / 20) * 20,
                Math.ceil(getMaxPercent(data.main, this.nbPlaces.main) / 20) * 20,
            ];
        }
        return [
            Math.floor(getMinValue(data, this.nbPlaces) / 20) * 20,
            Math.ceil(getMaxValue(data, this.nbPlaces) / 20) * 20,
        ];
    }

    getXMainDomain(data) {
        return [
            new Date(data.main[0].day),
            new Date(data.main[data.main.length - 1].day),
        ];
    }

    getXComparedDomain(data) {
        return [
            new Date(data.compared[0].day),
            new Date(data.compared[data.compared.length - 1].day),
        ]
    }

    createLines(data, xMain, xCompared, y) {
        if (this.nbPlaces.compared !== 0) {
            const meanCompared = data.compared.map((val) => ({placesEmpty: data.comparedMean, day: val.day }))
            this.createLine('compared', data.compared, d => xCompared(this.getXValue(d)), d => y(this.getYValueCompared(d)));
            this.createLine('compared dotted', meanCompared, d => xCompared(this.getXValue(d)), d => y(this.getYValueCompared(d)));
            this.createArea('compared', data.compared, d => xCompared(this.getXValue(d)), d => y(this.getYValueCompared(d)));
        }
        const mean = data.main.map((val) => ({placesEmpty: data.mainMean, day: val.day }))
        this.createLine('main', data.main, d => xMain(this.getXValue(d)), d => y(this.getYValueMain(d)));
        this.createLine('main dotted', mean, d => xMain(this.getXValue(d)), d => y(this.getYValueMain(d)));
        this.createArea('main', data.main, d => xMain(this.getXValue(d)), d => y(this.getYValueMain(d)));
    }

    getData() {
        const data = OccupancyRateReportingStore.getHistory();
        this.nbPlaces.main = data.main.nbPlaces;
        this.nbPlaces.compared = data.compared.nbPlaces;
        const reducer = (total, current) => total + current.placesEmpty;
        
        return {
            main: data.main.mainOccupancyRates,
            compared: data.compared.mainOccupancyRates,
            mainMean: data.main.mainOccupancyRates.reduce(reducer, 0) / data.main.mainOccupancyRates.length,
            comparedMean: data.compared.mainOccupancyRates.reduce(reducer, 0) / data.compared.mainOccupancyRates.length,
        };
    }

    formatTooltipText(d) {
        return Math.round(d * 100) / 100;
    }

    getTooltipTitle(point) {
        const date = this.getXValue(point);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('fr-FR', options);
    }

    getTooltipContent(point, pointCompared) {
        const displayCompared = () => {
            if (isNaN(this.getYValueCompared(pointCompared))) {
                return '';
            }
            return `
                <span>
                    <br />
                    <span class="reporting-compared-text">${comparedLabel(this.filters)}: </span>
                    ${this.formatTooltipText(this.getYValueCompared(pointCompared))}
                </span>
            `;
        };
        return `
            <p>
                <span class="reporting-main-text">${Locale.trans('Période')}: </span>
                ${this.formatTooltipText(this.getYValueMain(point))}
                ${displayCompared()}
            </p>`;
    }

    render() {
        return (
            <Panel
                title={Locale.trans('reporting.fill_history')}
                className="reporting"
            >
                {super.render()}
                <LineChartLegend />
            </Panel>
        );
    }
}

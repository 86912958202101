/**
 * A basic logger to encapsulate the console.log.
 * It log stuff only on DEBUG mode.
 */

 // @ts-ignore
const isLoggerEnabled: boolean = process.env.NODE_ENV !== 'production';
class Logger {
    log(...messages: Array<any>){
        if(isLoggerEnabled){
            console.log(...messages);
        }
    }

    error(...messages: Array<any>){
        if(isLoggerEnabled){
            console.error('[ERROR]', ...messages);
        }
    }
    warning(...messages: Array<any>) {
        if(isLoggerEnabled) {
            //console.warn('[WARN]', ...messages);
        }
    }
    debug(...messages: Array<any>) {
        if(isLoggerEnabled) {
            console.debug('[DEBUG]', ...messages);
        }
    }
}

export default new Logger();

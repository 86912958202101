import React from 'react';
import { Modal } from 'antd';

import BoatForm from 'components/forms/BoatForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import BoatActions from 'actions/BoatActions';
import BoatStore from 'stores/BoatStore';

import PlaceActions from 'actions/PlaceActions';
import PlaceStore from 'stores/PlaceStore';

import UserActions from 'actions/UserActions';
import UserStore from 'stores/UserStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to create a new boat.
 */
export default class CreateBoatModal extends React.Component {
    newBoat = null;
    totalActions = 0;
    completedActions = 0;

    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {},
            places: PlaceStore.getAvailableByHarbour(HarbourStore.getCurrentHarbour()),
            users: UserStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        };
    }

    componentDidMount() {
        BoatStore.addChangeListener(this.receivePlaces);

        PlaceStore.addChangeListener(this.receivePlaces);
        PlaceActions.reloadByHarbour(HarbourStore.getCurrentHarbour());

        UserStore.addChangeListener(this.receiveUsers);
        UserActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    }
    componentWillUnmount() {
        BoatStore.removeChangeListener(this.receivePlaces);
        PlaceStore.removeChangeListener(this.receivePlaces);
        UserStore.removeChangeListener(this.receiveUsers);
    }

    receivePlaces = () => {
        this.setState({
            places: PlaceStore.getAvailableByHarbour(HarbourStore.getCurrentHarbour()),
        });
    };

    receiveUsers = () => {
        const users = UserStore.getByHarbour(HarbourStore.getCurrentHarbour());
        this.setState({
            users,
        });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (boat, owners) => {
        this.newBoat = null;
        this.totalActions = 0;
        this.completedActions = 0;

        this.setState({
            loading: true,
        });
        BoatActions.create(HarbourStore.getCurrentHarbour(), boat)
            .then((newBoat) => {
                this.newBoat = newBoat;

                owners.created.map((o) => 
                    this._addOwner(this.newBoat.id, o)
                );

                this.tryToComplete();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    // Owner
    _addOwner = (boatId, owner) => {
        this.totalActions++;
        BoatActions.addOwner(HarbourStore.getCurrentHarbour(), boatId, owner.id)
            .then(() => {
                this.completedActions++;
                this.tryToComplete();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    /**
     * Check if all the things that have to be created are done. (ex: Phones, Mail, etc.)
     * If yes, we complete the process with toast to the user and redirection to Edit page.
     */
    tryToComplete = () => {
        if (!this.newBoat || this.completedActions < this.totalActions) {
            return;
        }

        this.setState({
            fields: {},
            loading: false,
        });
        ToastActions
            .createToastSuccess(Locale.trans('boat.creation.success', { name: this.newBoat.name }));
        this.props.onCancel();
    };

    render() {
        return (
            <Modal
                title={Locale.trans('boat.creation.title')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                footer={null}
            >
                <BoatForm
                    places={this.state.places}
                    users={this.state.users}
                    onChange={this.handleFormChange}
                    fields={this.state.fields}
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                />
            </Modal>
        );
    }
}



import { notification } from 'antd';
import StringService from 'services/utils/StringService';

class ToastActions {
    createToast(message: string, description: string | null | undefined, toastType: ToastType): void {
        notification[toastType]({
            message: StringService.nl2br(message),
            description,
        });
    }

    createToastError(message: string, description?: string): void {
        this.createToast(message, description, 'error');
    }
    createToastSuccess(message: string, description?: string): void {
        this.createToast(message, description, 'success');
    }
}

export default new ToastActions();

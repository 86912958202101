import BaseStore from 'stores/BaseStore';
import BoatConstants from 'constants/BoatConstants';
import HarbourStore from './HarbourStore';


class BoatStore extends BaseStore {
    _entities: { [harbour_id: number]: IBoat[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case BoatConstants.RECEIVE_BOATS_OF_HARBOUR:
            this._receiveByHarbour(payload.harbour, payload.boats);
            break;
        case BoatConstants.RECEIVE_BOAT_OF_HARBOUR:
            this._receiveOneByHarbour(payload.harbour, payload.boat);
            break;
        case BoatConstants.DELETE_BOAT_OF_HARBOUR:
            this._deleteOneByHarbour(payload.harbour, payload.id);
            break;

            // Owners
        case BoatConstants.ADD_BOAT_OWNER:
            this._addOwner(payload.boatId, payload.owner);
            break;
        case BoatConstants.REMOVE_BOAT_OWNER:
            this._removeOwner(payload.boatId, payload.ownerId);
            break;

        default:
            // Do Nothing
            break;
        }
    };

    // Owners
    _addOwner(boatId: number, owner: User): void {
        const boat = this.getById(boatId);
        if (!boat) {
            return;
        }
        if (!boat.owners) {
            boat.owners = [];
        }

        const i = boat.owners.findIndex(g => g.id === owner.id);
        if (i < 0) {
            boat.owners.push({
                id: owner.id,
                firstName: owner.firstName,
                lastName: owner.lastName,
            });
        }

        this.emitChange();
    }

    _removeOwner(boatId: number, ownerId: number): void {
        const boat = this.getById(boatId);
        if (!boat || !boat.owners) {
            return;
        }

        const i = boat.owners.findIndex(g => g.id === ownerId);
        if (i > -1) {
            boat.owners.splice(i, 1);
            this.emitChange();
        }
    }

    _receiveByHarbour = (harbour: IHarbour, boats: IBoat[]) => {
        this._entities[harbour.id] = boats;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, boat: IBoat) => {
        const boats = this._entities[harbour.id];
        if (!boats) {
            this._entities[harbour.id] = [boat];
        } else {
            const i = boats.findIndex(c => c.id === boat.id);
            if (i > -1) {
                boats[i] = boat;
            } else {
                boats.push(boat);
            }
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, boatId: number) => {
        const boats = this._entities[harbour.id];
        if (!boats) {
            return;
        }

        const i = boats.findIndex(c => c.id === boatId);
        if (i > -1) {
            boats.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    /**
     * Get all the places of a harbour.
     */
    getByHarbour = (harbour: IHarbour | null | undefined): IBoat[] =>
        (harbour ? this._entities[harbour.id] || [] : []);

    getOneByPlace = (place: IPlace): IBoat | null | undefined =>
        (place ? (this._entities[place.harbour.id] || []).find(e => e.place && e.place.id === place.id) : null);

    getById = (id: number): IBoat | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const boats = this._entities[harbourId] || [];
            const boat = boats.find(c => c.id.toString() === id.toString());
            if (boat) {
                return boat;
            }
        }
        return null;
    };

    getAllTypes = () => {
        const entities = this.getByHarbour(HarbourStore.getCurrentHarbour());
        const types: string[] = [];
        entities.map((entity) => {
            if (!types.includes(entity.boatType)) {
                types.push(entity.boatType);
            }
            return null;
        });
        return types.filter(f => !!f);
    }
}

export default new BoatStore();

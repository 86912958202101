import React, { useState, useEffect } from 'react';
import IncidentConfigurationForm from 'components/forms/IncidentConfigurationForm';
import IncidentConfigurationStore from 'stores/IncidentConfigurationStore';
import IncidentActions from 'actions/IncidentActions';
import HarbourStore from 'stores/HarbourStore';
import HarbourActions from 'actions/HarbourActions';
import ToastActions from 'actions/ToastActions';
import Locale from 'locale/LocaleFactory';
import moment from 'moment';

const IncidentConfiguration = React.memo(() => {
    const [fields, setFields] = useState({});

    const receive = () => {
        const harbour = HarbourStore.getCurrentHarbour();
        const configurations = IncidentConfigurationStore.getByHarbour(harbour);
        let beginDay = null;
        if (harbour.hourBeginDay) {
            beginDay = moment(harbour.hourBeginDay, 'HH:mm');
        } else {
            beginDay = moment('00:00', 'HH:mm');
        }
        let hourEndDay = null;
        if (harbour.hourEndDay) {
            hourEndDay = moment(harbour.hourEndDay, 'HH:mm');
        } else {
            hourEndDay = moment('23:59', 'HH:mm');
        }
        const fieldsTemp = {
            securityEmail: {
                value: harbour.securityEmail,
            },
            hourBeginDay: {
                value: beginDay,
            },
            hourEndDay: {
                value: hourEndDay,
            },
        };
        configurations.forEach((configuration) => {
            fieldsTemp[`visibility${configuration.id}`] = {
                value: configuration.visibility,
            };
            fieldsTemp[`email${configuration.id}`] = {
                value: configuration.email,
            };
            fieldsTemp[`isCritical${configuration.id}`] = {
                value: configuration.isCritical,
            };
            fieldsTemp[`criticalPhoneNumberDay${configuration.id}`] = {
                value: configuration.criticalPhoneNumberDay,
            };
            fieldsTemp[`criticalPhoneNumberNight${configuration.id}`] = {
                value: configuration.criticalPhoneNumberNight,
            };
        });
        setFields(fieldsTemp);
    };

    useEffect(() => {
        IncidentConfigurationStore.addChangeListener(receive);
        HarbourStore.addChangeListener(receive);
        IncidentActions.reloadConfigurationByHarbour(HarbourStore.getCurrentHarbour());
        receive();
    }, []);

    const incidents = IncidentConfigurationStore.getCurrent();
    return (
        <div className="incident-list">
            <IncidentConfigurationForm
                fields={fields}
                incidents={incidents}
                onSubmit={(values) => {
                    IncidentActions.editConfigurations(values.configurations)
                    .then(() => {
                        ToastActions.createToastSuccess(Locale.trans('saved'));
                    });
                    HarbourActions.edit(HarbourStore.getCurrentHarbourId(), {
                        ...values,
                        configurations: undefined
                    });
                }}
            />
        </div>
    );
});

export default IncidentConfiguration;

import HarbourStore from 'stores/HarbourStore';
import BaseStore from 'stores/BaseStore';

import ReportingConstants from 'constants/ReportingConstants';
import moment from 'moment';

class OccupancyRateReportingStore extends BaseStore {
    _entities: {
        [harbour_id: number]: { history?: IOccupancyRates; current?: number };
    };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string; payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
            case ReportingConstants.OCCUPANCY_RATES_RECEIVED:
                this._receiveByHarbour(payload.harbour, payload.rates);
                break;
            case ReportingConstants.CURRENT_OCCUPANCY_RATE_RECEIVED:
                this._receiveCurrentByHarbour(payload.harbour, payload.rate);
                break;
            default:
                // Do Nothing
                break;
        }
    };

    _receiveByHarbour = (
        harbour: IHarbour,
        filledRates: IOccupancyRates,
    ): void => {
        if (!this._entities[harbour.id]) {
            this._entities[harbour.id] = { };
        }
        const occupancyRatesFormatted = filledRates;
        occupancyRatesFormatted.main.mainOccupancyRates = Object.values(
            occupancyRatesFormatted.main.mainOccupancyRates,
        );
        occupancyRatesFormatted.main.boatTypesOccupancyRates.motorboat = Object.values(
            occupancyRatesFormatted.main.boatTypesOccupancyRates.motorboat ||
                {},
        );
        occupancyRatesFormatted.main.boatTypesOccupancyRates.sailboat = Object.values(
            occupancyRatesFormatted.main.boatTypesOccupancyRates.sailboat || {},
        );
        occupancyRatesFormatted.main.boatTypesOccupancyRates.multihull = Object.values(
            occupancyRatesFormatted.main.boatTypesOccupancyRates.multihull ||
                {},
        );
        if (
            !(
                Array.isArray(occupancyRatesFormatted.compared) &&
                occupancyRatesFormatted.compared.length === 0
            )
        ) {
            occupancyRatesFormatted.compared.mainOccupancyRates = Object.values(
                occupancyRatesFormatted.compared.mainOccupancyRates,
            );
        }
        this._entities[harbour.id].history = occupancyRatesFormatted;
        this.emitChange();
    };

    _receiveCurrentByHarbour = (
        harbour: IHarbour,
        filledRate: number,
    ): void => {
        this._entities[harbour.id].current = filledRate;
        this.emitChange();
    };

    getHistory = (): IOccupancyRates | null => {
        const harbour = HarbourStore.getCurrentHarbour();
        if(!harbour) {
            return null;
        }
        const entities = this._entities[harbour.id] || [];
        let main = {
            mainOccupancyRates: [],
            boatTypesOccupancyRates: {
                sailboat: [],
                motorboat: [],
                multihull: [],
            },
            nbPlacesByType: {
                sailboat: 0,
                motorboat: 0,
                multihull: 0,
            },
            nbPlaces: 0,
        };
        let compared = {
            mainOccupancyRates: [],
            boatTypesOccupancyRates: {
                sailboat: [] as any[],
                motorboat: [] as any[],
                multihull: [] as any[],
            },
            nbPlacesByType: {
                sailboat: 0,
                motorboat: 0,
                multihull: 0,
            },
            nbPlaces: 0,
        };
        if (
            entities === undefined ||
            entities.history === undefined ||
            entities.history.main === undefined
        ) {
            return {
                main,
                compared,
            };
        }
        const historyMain = entities.history.main;
        if (!(Array.isArray(historyMain) && historyMain.length === 0)) {
            main = {
                // @ts-ignore
                mainOccupancyRates: [...historyMain.mainOccupancyRates],
                boatTypesOccupancyRates: {
                    // @ts-ignore
                    sailboat: historyMain.boatTypesOccupancyRates.sailboat ? [...historyMain.boatTypesOccupancyRates.sailboat] : [],
                    // @ts-ignore
                    motorboat: historyMain.boatTypesOccupancyRates.motorboat ? [
                        ...historyMain.boatTypesOccupancyRates.motorboat,
                    ] : [],
                    // @ts-ignore
                    multihull: historyMain.boatTypesOccupancyRates.multihull ? [
                        ...historyMain.boatTypesOccupancyRates.multihull,
                    ] : [],
                },
                nbPlacesByType: historyMain.nbPlacesByType,
                nbPlaces: historyMain.nbPlaces,
            };
        }
        const historyCompared = entities.history.compared;
        if (!(Array.isArray(historyCompared) && historyCompared.length === 0)) {

            compared = {
                // @ts-ignore
                mainOccupancyRates: [...historyCompared.mainOccupancyRates],
                boatTypesOccupancyRates: {
                    sailboat: historyCompared.boatTypesOccupancyRates.sailboat
                    ? Object.values(historyCompared.boatTypesOccupancyRates.sailboat)
                    : [],
                    motorboat: historyCompared.boatTypesOccupancyRates.motorboat
                    ? Object.values(historyCompared.boatTypesOccupancyRates.motorboat)
                    : [],
                    multihull: historyCompared.boatTypesOccupancyRates.multihull
                    ? Object.values(historyCompared.boatTypesOccupancyRates.multihull)
                    : [],
                },
                nbPlacesByType: historyCompared.nbPlacesByType,
                nbPlaces: historyCompared.nbPlaces,
            };
        }
        return {
            main,
            compared,
        };
    };

    getMean = () => {
        const harbour = HarbourStore.getCurrentHarbour();
        if(!harbour) {
            return null;
        }
        const data = {
            nbPlaces: 0,
            nbPlacesCompared: 0,
            meanEmpty: 0,
            meanEmptyCompared: 0,
        };
        const entities = this._entities[harbour.id] || [];
        if (
            entities === undefined ||
            entities.history === undefined ||
            entities.history.main === undefined
        ) {
            return data;
        }
        const historyMain = entities.history.main;
        if (!(Array.isArray(historyMain) && historyMain.length === 0)) {
            let totalEmptyPlaces = 0;
            historyMain.mainOccupancyRates.forEach(
                (e: IOccupancyRate) => (totalEmptyPlaces += e.placesEmpty),
            );
            data.meanEmpty =
                totalEmptyPlaces / historyMain.mainOccupancyRates.length;
            data.nbPlaces = historyMain.nbPlaces;
        }
        const historyCompared = entities.history.compared;
        if (!(Array.isArray(historyCompared) && historyCompared.length === 0)) {
            let totalEmptyPlaces = 0;
            historyCompared.mainOccupancyRates.forEach(
                (e: IOccupancyRate) => (totalEmptyPlaces += e.placesEmpty),
            );
            data.meanEmptyCompared =
                totalEmptyPlaces / historyCompared.mainOccupancyRates.length;
            data.nbPlacesCompared = historyCompared.nbPlaces;
        }
        return data;
    };

    getHistoryForMonth(currentMonth: moment.Moment) {
        const harbour = HarbourStore.getCurrentHarbour();
        if (
            !harbour ||
            this._entities[harbour.id] === undefined ||
            this._entities[harbour.id].history === undefined
        ) {
            return this._getDefault();
        }
        const history = this._entities[harbour.id].history;
        if(!history) {
            return this._getDefault();
        }
        const formatBoatType = (data: any) => {
            if (data === undefined) {
                return [];
            }
            return Object.values(data)
                .filter((type: any) => {
                    const day = moment(type.day);
                    const start = currentMonth
                        .clone()
                        .startOf('month')
                        .startOf('day');
                    const end = currentMonth
                        .clone()
                        .endOf('month')
                        .endOf('day');
                    return (
                        day.isSame(start) ||
                        day.isSame(end) ||
                        day.isBetween(start, end)
                    );
                })
                .map((type: any) => ({
                    ...type,
                    day: moment(type.day),
                }));
        };
        return {
            main: {
                mainOccupancyRates: history.main.mainOccupancyRates.map(d => d),
                boatTypesOccupancyRates: {
                    sailboat: formatBoatType(
                        history.main.boatTypesOccupancyRates.sailboat,
                    ),
                    motorboat: formatBoatType(
                        history.main.boatTypesOccupancyRates.motorboat,
                    ),
                    multihull: formatBoatType(
                        history.main.boatTypesOccupancyRates.multihull,
                    ),
                },
                nbPlacesByType: history.main.nbPlacesByType,
                nbPlaces: history.main.nbPlaces,
            },
        };
    }

    _getDefault = () => {
        return {
            main: {
                mainOccupancyRates: [],
                boatTypesOccupancyRates: {
                    sailboat: [],
                    motorboat: [],
                    multihull: [],
                },
                nbPlacesByType: {
                    sailboat: 0,
                    motorboat: 0,
                    multihull: 0,
                },
                nbPlaces: 0,
            },
        };
    };
}

export default new OccupancyRateReportingStore();

export default {
    // NauticSpot
    Trans: {
        ok: 'OK',
        beginning: 'Comienza',
        cost: 'Costo',
        date: 'Fecha',
        day: 'día',
        week: 'semana',
        month: 'mes',
        days: 'días',
        description: 'Descripción',
        delete: 'Eliminar',
        'delete.confirm': 'Está usted seguro de borrar?',
        download: 'Descargar',
        end: 'Fin',
        edit: 'Edita',
        save: 'Registrarse',
        saved: 'Salvado',
        send: 'Enviar a',
        cancel: 'Cancelar',
        total: 'Total',
        notFound: 'Ninguno',
        emptyText: 'Sin datos',
        back: 'Regroso',
        close: 'Cerrar',
        map: 'Mapa',
        'map.stroke': 'Mapa de contorno',
        search: 'Buscar',
        at: 'a',
        loading: 'Cargando',

        mister: 'Señor',
        miss: 'Señora',

        facebook: 'Facebook',
        linkedin: 'Linkedin',
        twitter: 'Twitter',

        matin: 'mañana',
        apresmidi: 'tarde',
        refused: 'Denegado',
        approved: 'Aceptado',
        requested: 'Pendiente',
        accepted: 'Aceptado',

        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo',

        welcome: 'Hola __USER__',

        locale: {
            fr: 'Français',
            en: 'English',
            es: 'Español',
            it: 'Italiano',
        },

        stats: 'Estadísticas',
        drag_drop_invitation:
            'Haga clic o suelte un archivo aquí para cargarlo.',

        pages: {
            home: 'Acogida',
            users: 'Navegantes',
            calendar: 'Calendario',
            map: 'Papa del puerto',
            rates: 'Precios',
            reporting: 'Reporting',
            profile: 'Mi perfil',
            configuration: 'Configuracion',
            security: 'Seguridad',
            communication: 'Comunicación',
        },

        communication: {
            weather_upload: {
                success: 'El boletín ha sido actualizado',
                failed:
                    'Se produjo un error, el boletín no se pudo actualizar.',
            },
            notification: {
                _: 'Notificaciones - Histórico',
                duplicate: 'Duplicar',
                edit: {
                    title: 'Edit',
                    success: 'Notification updated'
                },
                notify: 'Notificar a los navegantes',
                title: 'Ingrese el título del mensaje ...',
                content: 'Detalla el mensaje ...',
                url: 'Rellene un enlace (opcional)',
                link: 'Enlace',
                selectCategory: 'Seleccione una categoria',
                selectHarbour: 'Seleccione un puerto',
                sentAt: 'Enviado el',
                showDetails: 'Ver detalles',
                details: 'Detalles',
                attachments: 'Documento(s) adjunto(s)',
                thumbnail: 'Imagen de vista',
                selectThumbnail: 'Selecciona una imagen',
                recipients: {
                    _: 'Destinatarios',
                    all: 'Todos',
                    'user-lists': 'Lista de difusión',
                    pontoon: 'Pontones',
                    users: 'Navegantes',
                },
                type: {
                    weather: 'Clima',
                    security: 'Seguridad',
                    event: 'Evento',
                    work: 'Obras / Mantenimiento',
                    'concierge-package': 'Conserjería - Paquetes',
                    'concierge-mail': 'Conserjería - Correo',
                    news: 'Actualidaded',
                    other: 'Otros',
                },
                success: 'La notificación ha sido enviada',
                failure:
                    'No se puede enviar la notificación, se produjo un error',
                delete: {
                    success: 'Notification deleted',
                },
                deleteAttachment: {
                    success: 'Attachment deleted'
                }
            },
            communication: 'Comunicación',
        },

        months: {
            jan: 'Enero',
            feb: 'Febrero',
            mar: 'Marte',
            apr: 'Abril',
            may: 'Mayo',
            jun: 'Junio',
            jul: 'Julio',
            aug: 'Agosto',
            sep: 'Septiembre',
            oct: 'Octubre',
            nov: 'Noviembre',
            dec: 'Diciembre',
        },
        'days.short': {
            monday: 'Lun',
            tuesday: 'Mar',
            wednesday: 'Mié',
            thursday: 'Jue',
            friday: 'Vie',
            saturday: 'Sáb',
            sunday: 'Dom',
        },
        calendar: {
            max: 'Max : ',
            mean: 'Moy : ',
            min: 'Min : ',
            days: {
                long: {
                    monday: 'Lunes',
                    tuesday: 'Martes',
                    wednesday: 'Miércoles',
                    thursday: 'Jueves',
                    friday: 'Viernes',
                    saturday: 'Sábado',
                    sunday: 'Domingo',
                },
                short: {
                    monday: 'Lun',
                    tuesday: 'Mar',
                    wednesday: 'Mié',
                    thursday: 'Jue',
                    friday: 'Vie',
                    saturday: 'Sáb',
                    sunday: 'Dom',
                },
            },
            months: {
                long: {
                    jan: 'Enero',
                    feb: 'Febrero',
                    mar: 'Marte',
                    apr: 'Abril',
                    may: 'Mayo',
                    jun: 'Junio',
                    jul: 'Julio',
                    aug: 'Agosto',
                    sep: 'Septiembre',
                    oct: 'Octubre',
                    nov: 'Noviembre',
                    dec: 'Diciembre',
                },
                short: {
                    jan: 'Ene',
                    feb: 'Feb',
                    mar: 'Mar',
                    apr: 'Abr',
                    may: 'May',
                    jun: 'Jun',
                    jul: 'Jul',
                    aug: 'Ago',
                    sep: 'Sep',
                    oct: 'Oct',
                    nov: 'Nov',
                    dec: 'Dic',
                },
            },
        },

        license:
            'Nauticspot todos los derechos reservados © 2018 Creado por IAS Ingénierie',

        address: {
            title: 'Dirección',
            street: 'Calle',
            country: 'País',
            city: 'Ciudad',
            postalCode: 'Código postal',
        },
        error_message: 'Se ha producido un error',
        error: {
            required: 'Por favor, rellene este campo',
            'email.invalid': 'Email inválido',
            'phone.invalid': 'Número de teléfono inválido',
        },

        advancedSearch: {
            show: 'Filtros avanzados',
            hide: 'Filtros ocultos',
        },
        'auth.error.invalidCredentials': 'Nombre o contraseña incorrectos',
        login: {
            username: 'Nombre del usuario',
            password: 'Contraseña',
            rememberMe: 'Recuérdame',

            'logIn.button': 'Conexión',
            'logOut.button': 'Desconectar',
            'username.error.required':
                'Por favor, introduzca su nombre de usuario',
            'password.error.required': 'Por favor, introduzca su contraseña',
            'password.forgotten': 'Olvidé mi contraseña',
        },
        password_forgotten: {
            title: 'Contraseña olvidada',
            resetPassword: 'Cambiar mi contraseña',
            confirm:
                'Se le ha enviado un correo electrónico para cambiar su contraseña.',
        },
        accessRequest: {
            title: 'Solicitud de conexión pendiente',
            user: 'Usuario',
            categorie: 'Categoría',
            place: 'Espacio',
            boat: 'Barco',
            refusalComment: 'Motivo de Rechazo',
            refusal: {
                title: 'Rechazar la solicitud',
                success: 'Solicitud rechazada',
            },
            approval: {
                title: 'Acepta la solicitud',
                existingBoat: 'Barco existente',
                newBoat: 'Nuevo Barco',
                success: 'Solicitud aceptada',
            },
            categories: {
                yachtsman: 'Navegante',
                professional: 'Profesional',
                visitor: 'Visitante',
            },
        },
        user: {
            firstName: 'Nombre',
            lastName: 'Apellido',
            name: 'Apellido',
            role: 'Papel',
            roles: 'Papeles',
            username: 'nombre de usuario',
            nationality: 'Nacionalidad',
            locale: 'Lengua',
            email: 'eMail',
            boats: 'Barcos',
            phoneNumber: 'N° de teléfono',
            'password.edit.title': 'Editar contraseña',
            password: 'Contraseña',
            passwordConfirm: 'Nueva contraseña',
            'password.error.required': 'Por favor, introduzca su contraseña',
            error: {
                emailAlreadyUsed: 'Este correo electrónico ya está en uso',
                usernameAlreadyUsed: 'Este identificador ya está en uso',
            },

            creation: {
                title: 'Agregar usuario',
                success: 'Usuario "__name__" creado',
            },
            update: {
                title: 'Editar usuario',
                success: 'Usuario "__name__" editado',
            },
            delete: {
                success: 'Usuario borrado',
                error: 'Usuario borrado error',
            },
        },
        role: {
            administrator: 'Administrador',
            yachtsman: 'Navegante',
            security_officer: 'Agente de seguridad',
            captain: 'Capitanía',
            professional: 'Profesional',
            surveyor: 'Supervisor',
            visitor: 'Cisitante',
            super_admin: 'Super Admin',
            'any.role.nauticspot': 'Otro',
        },
        yachtsman: {
            creation: {
                title: 'Agregar un navegante',
                success: 'Navegante "__name__" creado',
            },
            update: {
                title: 'Editar un navegante',
                success: 'Navegante "__name__" editado',
            },
        },

        weather: {
            forecast: {
                _: 'Boletín meteorológico',
                upload: 'Actualizar el boletín',
                download: 'Ver el boletín en línea',
                updated: 'Última actualización: __date__',
                close_preview: 'Cerrar',
            },
        },
        absence: {
            absence: 'Ausencia',
            absences: 'Ausencias',
            departureDate: 'Fecha de salida',
            returnDate: 'Fecha de regreso',
            anticipatedReturnDate: 'Fecha de regreso anticipado',
            place: 'Amarre',
            boat: 'Barco',
            owners: 'Navegante',
            legend: {
                pending: 'Esperando',
                approved: 'Disponible',
                present: 'Occupado',
                'anticipated-return': 'Regreso Anticipado',
            },
            creation: {
                title: 'Nueva ausencia',
                success: 'Ausencia declarada',
            },
            anticipatedReturn: {
                creation: {
                    title: 'Agregar un Regreso Anticipado',
                    success: 'Regreso Anticipado creado',
                },
            },
        },
        multihull: 'Multi Casco',
        sailboat: 'Velero',
        motorboat: 'Motor',
        resident: 'Residente',
        stopover: 'Parada',
        commitment: {
            user: 'Nombre',
            startDate: 'Inicio',
            endDate: 'Fin',
            unknown: 'Desconocido',
            inProgress: 'En curso',

            disengage: 'Retirar',
            engage: 'Contratame',
            showHistory: 'Ver historial',
            userTypes: {
                agent: 'Agente',
                supervisor: 'Supervisor',
            },
            confirmModal: {
                title: '¿De verdad quieres involucrarte?',
                content:
                    'Si te comprometes, no puedes volver atrás.\nSolo otro usuario tendrá que comprometerse a su vez para liberarlo.',
            },
            disconfirmModal: {
                title: '¿Estás seguro de que deseas optar por no participar?',
                content: ' ',
            },
            historyModal: {
                title: 'Historia de compromisos',
            },
        },
        boat: {
            _: 'Bateau',
            name: 'Nombre',
            boatType: 'Tipo',
            place: 'Lugar',
            draught: 'Calado',
            length: 'Longitud',
            width: 'Ancho',
            size: 'Altura',
            immatriculation: 'Registro',
            hasTank: 'Tanque de aguas negras',
            owners: 'Propietarios',
            position: 'Posición',
            countAbsences: 'Número de ausencias',
            countOutings: 'Número de salidas',
            synoxDeviceId: 'N° Box Synox',
            creation: {
                title: 'Agregar un Barco',
                success: 'Barco "__name__" creado',
            },
            update: {
                title: 'Editar un Barco',
                success: 'Barco "__name__" editado',
            },
            delete: {
                success: 'Barco borrado',
                error: 'Barco borrado error',
            },
            categories: 'Categorías de barcos',
            category: {
                name: 'Nombre',
                length: 'Largo',
                width: 'Ancho',
                minLength: 'Largo Min',
                maxLength: 'Largo Max',
                minWidth: 'Ancho Min',
                maxWidth: 'Ancho Max',
                definition: 'De __min__m hasta __max__m',
                definition_min: 'Más de __min__m',
                definition_max: 'Hasta __max__m',
                definition_undefined: 'No-definido',
                other: {
                    description: 'Fuera de categoría',
                },
                creation: {
                    title: 'Agregar una categoría.',
                    success: 'Categoría agregada.',
                },
                update: {
                    title: 'Editar una categoría.',
                    success: 'Categoría editada.',
                },
            },
        },
        configuration: {
            informations: 'Informaciones',
            photo_updated: 'Imagen actualizada',
            upload_failed: 'Error al cargar el archivo.',
            photo_update: 'Editar logo',
            map_updated: 'Mapa actualizado',
            map_update: 'Editar el mapa',
            import_users: 'Importar usuarios',
            import_users_success: 'Importación terminada',
            security: 'Módulo de seguridad',
            absence: 'Módulo de ausencia',
            port: 'Puerto',
            miscellanous: 'Informaciónes prácticas',
        },
        partners: 'Servicios',
        'external-links': 'Enlaces externos',
        externalLink: {
            name: 'Fraseología',
            color: 'Color',
            noBackground: 'Contornos',
            url: 'URL',
            updateIcon: 'Cambiar el ícono',
            create: {
                title: 'Agregar un enlace',
                success: 'Enlaces actualizados',
                error: "No se puede guardar",
            }
        },
        partner: {
            name: 'Nom',
            type: 'Catégorie',
            description: 'Description',
            email: 'Email',
            phone: 'Téléphone',
            websites: 'Sites internet',
            website: {
                label: 'Libellé',
                add: 'Ajouter un site internet',
            },
            types: {
                'harbour-office': 'Capitanía',
                maintenance: 'Mantenimiento',
                restaurant: 'Restaurante',
                'nautic-activity': 'Actividad náutica',
                association: 'Asociación',
                health: 'Salud',

                'activity-culture': 'Cultivo',
                'activity-entertainment': 'Entretenimiento',
                'activity-nature': 'Naturaleza',
                'activity-other': 'Otro',

                'shop-food': 'Alimentación',
                'shop-tools': 'Bricolaje - Herramientas',
                'shop-nautic-tools': 'Herramientas náuticas',
            },
            hours: 'Horaires',
            miscellanous: 'Informaciónes prácticas',
            thumbnail: 'Logo',
            pictures: 'Photos',
            picture: {
                add: {
                    title: 'Ajouter une photo',
                    error: 'Impossible de télécharger la photo',
                },
            },
            update: {
                title: 'Modifier un partenaire',
                success: 'Partenaire modifié',
            },
            creation: {
                title: 'Ajouter un partenaire',
                success: 'Partenaire créé',
            },
            delete: {
                error: 'Erreur lors de la suppression',
                success: 'Partenaire supprimé',
            },
        },
        markdown: {
            link: 'Para crear un enlace: [nombre del enlace] (dirección URL)',
            h1: 'Para crear un titulo: # Título del título',
            italic: 'Para poner en cursiva: _texto_',
            preview: 'Vista previa',
            help: 'Ayuda',
        },
        places: 'Amarres',
        place: {
            _: 'Place',
            code: 'Codice',
            boatTypes: 'Navi autorizzate',
            placeType: 'Sala da pranzo',
            boat: 'Barca',
            length: 'Lunghezza',
            width: 'Larghezza',
            draught: 'Profondità',
            pontoon: 'Pontón',

            deviceId: 'N° Sensor Pontón',
            ultrasonicMinThreshold: 'Umbral min',
            ultrasonicMaxThreshold: 'Umbral max',
            minInFrameThreshold: 'Nb trames retour',
            minOutFrameThreshold: 'Nb trames sortie',

            deviceType: 'Type',
            deviceTypes: {
                buoy: 'Buoy',
                pontoon: 'Pontoon'
            },

            creation: {
                title: 'Agregar un amarre',
                success: 'Amarre creado',
                draw: 'Dibuja el área para definir',
            },
            update: {
                title: 'Modificar un amarre',
                success: 'Amarre modificado',
            },
            deletion: {
                title:
                    '¿Estás seguro de que deseas eliminar el amarre "__name__"?',
                content:
                    'Esta acción solo borrará el dibujo del área en el mapa y no el amarre vinculado a él.',
                success: 'Amarre borrado',
                select: 'Seleccione el área para borrar',
            },
            state: {
                occupied: 'Amarre ocupado',
                in: 'Amarre ocupado',
                out: 'Amarre vacio',
                absence: 'Amarre disponible',
                empty: 'Amarre vacio',
                available: 'Amarre disponible',
                stopover: 'Escala',
                error: 'Error sensor',
            },
            error: {
                notFound: 'El amarre solicitado no existe.',
            },
        },
        pontoons: 'Pontones',
        pontoon: {
            name: 'Codigo',
            places: 'Amarres',
            creation: {
                title: 'Agregar un pontón',
                success: 'Pontón creado',
            },
            update: {
                title: 'Modificar un pontón',
                success: 'Pontón modificado',
            },
        },
        'notification-user-lists': 'Listas de correo',
        'notification-user-list': {
            name: 'Nombre',
            users: 'Usarios',
            creation: {
                title: 'Agregar una lista de correo',
                success: 'Lista creada',
            },
            update: {
                title: 'Modificar una lista de correo',
                success: 'Lista modificada',
            },
        },
        'weather-api': 'Estación meteorológica',
        mapShape: {
            update: {
                success: 'Map shape updated',
                error: 'Map shape update error',
            },
            deletion: {
                title: '¿Estás seguro de que deseas borrar la forma?',
                content:
                    'Esta acción borrará solo la forma seleccionada y no el resto del mapa.',
                success: 'Forma borrada',
                select: 'Seleccione la forma para borrar',
            },
        },
        mapPosition: {
            name: 'Nombre',
            isDefault: 'Posición por defecto',
            isPublic: 'Cuenca visible para todos los usuarios',
            save: {
                success: 'Posición registrada',
            },
            delete: {
                success: 'Posición borrada',
            },

            label: 'Cuencas',
            saveBtn: {
                tooltip: 'Establecer como posición / zoom de la cuenca',
            },
            defaultBtn: {
                tooltip: 'Establecer como cuenca predeterminada del puerto',
            },

            createModal: {
                title: 'Agregar una cuenca',
                success: 'Cuenca creada',
                error: 'No se puede crear la cuenca',
            },
            createBtn: {
                title: 'Agregar una cuenca',
            },
        },
        editPanel: {
            zone: 'Zona',
            place: 'Amarre',
            shape: 'Forma',

            calques: 'Capas',
            actions: 'Acciones',
        },
        zone: {
            zone: 'Zona',
            name: 'Nombre',
            creation: {
                title: 'Agregar una Zona',
                success: 'Zona creada',
                choice: '¿Quieres crear una zona o un amarre?',
            },
            update: {
                title: 'Modificar una zona',
                success: 'Zona modificada.',
                message: 'Seleccionar la zona para editar',
                bad_zone: 'La zona que seleccionaste es un amarre.',
                bad_zone_description:
                    ' Un amarre no se puede cambiar de esta manera en este momento. Vaya a "configuración" para modificar sus amarres.',
            },
            deletion: {
                title: '¿Estás seguro de que deseas borrar la zona "__name__"?',
            },
            error: {
                notFound: 'La zona solicitada no existe',
            },
        },
        position: {
            in: 'Al Puerto',
            out: 'En Mar',
        },
        rate: {
            category: 'Categoría',
            name: 'Código',
            boatTypes: 'Tipos de barcos',
            season: {
                high: 'Temporada alta',
                low: 'Temporada baja',
                off: 'Fuera de temporada',
                definition: 'De __start__ hasta __end__',
                from: 'De',
                to: 'hasta',
                update: {
                    success: 'Estación modificada',
                },
            },
            creation: {
                title: 'Crear una tarifa',
                success: 'Tarifa creada',
            },
            update: {
                title: 'Modificar una tarifa',
                success: 'Tarifa modificada',
            },
        },
        harbour: {
            name: 'Nombre',
            latitude: 'Latitud',
            longitude: 'Longitud',
            address: 'Dirección',
            phone: 'Téléfono',
            fax: 'Fax',
            email: 'Email',
            defaultLocale: 'Lengua',
            country: 'País',
            absenceEmail:
                'Correo electrónico utilizado para notificar una solicitud de ausencia',
            securityEmail:
                'Correo electrónico utilizado para informar un nuevo incidente',
            commitmentEmail:
                'Correo electrónico utilizado para notificar un cambio de compromiso',
            minutesBeforeEmail: 'Minutos antes notificación',
            intervalMinutesBetweenMail: 'Minutos entre notificación',
            select: 'Seleccione un puerto',
            change: 'Cambiar puerto',
            update: {
                success: 'Puerto "__name__" modificado',
            },
            hourBeginDay: 'Hora de inicio del día',
            hourEndDay: 'Hora del fin del día',
        },
        cameras: 'Cámaras',
        information: 'Informaciones',
        season: 'Estación ',
        camera: {
            name: 'Nombre',
            url: 'URL',
            type: 'Tipo',
            creation: {
                title: 'Agregar una camara',
                success: 'Camara creada',
            },
            update: {
                title: 'Modificar una camara',
                success: 'Camara modificada',
            },
            delete: {
                success: 'Camara borrada',
            },
            types: {
                playlist: 'Fichier vidéo',
                iframe: 'Lecteur Web',
            },
        },
        reporting: {
            main: 'General',
            repartition: 'Salidas por hora',
            outing: 'Salidas',
            outing_history: 'Salidas',
            calendar: 'Calendario',
            outing_by_type: 'Salidas para tipo de barco',
            fill_history: 'Historia de llenado',
            top_10: 'Top navegantes',
            mean: 'Medio',
            potential: 'Potencial',
            filters: {
                title: 'Filtres',
                labels: {
                    period_title: 'Periodo',
                    period: 'Período durante el cual ver informes',
                    compare: 'Comparar con :',
                    users: 'Navegantes',
                    places_zones: 'Amarre / Zona',
                    places: 'Amarres',
                    zones: 'Zonas',
                    types: 'Tipo de barcos :',
                    category: 'Categoría de barco',
                },
                date: 'Por favor seleccione un dato valido',
                year: 'Por favor seleccione un año',
            },
            top10: {
                number_outing: 'Número de salidas sobre el periodo',
                by_day: 'Salidas Día',
                by_night: 'Salidas Cruceras',
                relative_night: '% de salidas de ncoche',
                title_less: 'Usarios les menos activos',
                title_most: 'Usarios les más activos',
            },
            occupancy: {
                title: 'Llenado',
                evolution: '__EVOLUTION__% comparé à __DATE__',
                no_evolution: 'sin evolución en comparación con __DATE__',
                places: '__NB__ amarres',
                placesUsed: 'Amarres ocupados',
                placesEmpty: 'Amarres vacios',
            },
            data: {
                waiting: 'Esperando datos.',
                no_data: 'Sin datos recibidos.',
                waiting_explanation:
                    'No se han recibido datos hasta el momento.',
                no_data_explanation:
                    'Parece que no hay datos disponibles en este período para este gráfico.',
            },
            optimisation: {
                title: 'Optimización',
                tableTitle: {
                    period: 'Periodos',
                    nights: 'Salidas',
                    profit: 'Ganancias',
                    absences: 'Ausencias',
                    losts: 'Pérdidas',
                },
                period: 'Desde __start__ hasta __end__',
                profit: {
                    potential: 'Potencial',
                    realised: 'Realisado',
                    lost: 'Pérdidas',
                },
                outings: 'Salidas en mar',
                optimized: 'Optimizadas en escala',
                declared: 'Declaradas',
                outside: 'Excluyendo puerto de origen',
            },
        },
        outing: {
            timeIn: 'Hora de llegada',
            timeOut: 'Hora de salida',
            listByDay: 'Lista de salidas el __date__',
        },
        urgencePhoneNumbers: 'Números de emergencia',
        urgencePhoneNumber: {
            name: 'Nombre',
            phoneNumber: 'Tel.',
            vhf: 'VHF',
            creation: {
                title: 'Agregar un número de emergencia',
                success: 'Número creado',
                btn: 'Agregar un núméro de emergencia',
            },
            update: {
                title: 'Modificar un núméro de emergencia',
                success: 'Número modificado',
            },
            delete: {
                success: 'Número borrado',
            },
        },
        users: 'Usarios',
        categories: 'Categorias',

        incidents: 'Incidentes',
        incident: {
            name: 'Incidente',
            user: 'Navegante',
            types: {
                fire: 'Fuego',
                sink: 'Daños',
                theft: 'Allanamiento de morada',
                electric: 'Problema eléctrico',
                water: 'Fuga de agua',
                sound: 'Contaminación acústica',
                pollution: 'Polución',
                lighting: 'Iluminación',
                sanitary: 'Sanitario',
                broken_board: 'Tabla quabrada',
                collision: 'Choque',
                harbour_map: 'Mapa del poerto',
                other: 'Otro',
                extinguisher: 'Extintor',
                chain: 'Cadena',
                ladder: 'Escalera',
                towing: 'Remolque',
                mooring: 'Amarre',                
            },
            visibility: {
                _: 'Visibilidad',
                none: 'Nadie',
                captain: 'Capitanía',
                all: 'Todo'
            },
            type: {
                _: "Type d'incident",
                fire: 'Fuego',
                sink: 'Daños',
                theft: 'Allanamiento de morada',
                electric: 'Problema eléctrico',
                water: 'Fuga de agua',
                sound: 'Contaminación acústica',
                pollution: 'Polución',
                lighting: 'Iluminación',
                sanitary: 'Sanitario',
                broken_board: 'Tabla quabrada',
                collision: 'Choque',
                harbour_map: 'Mapa del poerto',
                other: 'Otro',
                extinguisher: 'Extintor',
                chain: 'Cadena',
                ladder: 'Escalera',
                towing: 'Remolque',
                mooring: 'Amarre',                
            },
            comment: 'Patrón',
            comments: 'Comentario',
            harbourComment: 'Comentario del puerto',
            saveHarbourComment: 'Registrar el comentario',
            status: 'Estado',
            zone: 'Zona',
            createdAt: 'Fecha',
            confirmedByOne: 'Confirmado por un navegante',
            confirmedBy: 'Confirmado por __count__ los navegantes',
            acknowlegedAt: 'Teniendo en cuenta el __DATE__',
            acknowlegedAt_by: 'Teniendo en cuenta el __DATE__ por __USER__',
            closedAt: 'Cerrado el __DATE__',
            closedAt_by: 'Cerrado el __DATE__ por __USER__',
            attachments: 'Anexos',
            no_attachment: 'Sin archivos adjuntos',
            createdBy: 'Creado por',
            security: 'Seguridad',
            safety: 'Seguridad',
            maintenance: 'Mantenimiento',
            email: 'Emails de notificación',
            notificationEmail:
                'Email utilizado para notificar un nuevo incidente',
            isCritical: 'Critico',
            criticalPhoneNumber: {
                day: 'Telefono de emergencia (día)',
                night: 'Telefono de emergencia (noche)',
            },
            editZone: 'Definir la zona',
            statuses: {
                pending: 'Pendiente',
                acknowledged: 'Tomado en cuenta',
                closed: 'Cerrado',
            },
            action: {
                acknowledge: 'Tener en cuenta el incidente.',
                close: 'Cerrar el incident',
            },

            pendingIncidents: 'Incidente notificado',

            creation: {
                title: 'Reportar un incidente',
                success: 'Incidente notificado',
            },
            update: {
                success: 'Incidente modificado',
            },
        },
    },

    // Ant Design
    Table: {
        filterTitle: 'Buscar...',
        filterConfirm: 'OK',
        filterReset: 'Reiniciar',
        emptyText: 'No hay datos',
    },
    Modal: {
        okText: 'OK',
        cancelText: 'Cancelar',
        justOkText: 'OK',
    },
    Popconfirm: {
        okText: 'OK',
        cancelText: 'Cancelar',
    },
    Transfer: {
        notFoundContent: 'No hay resultados',
        searchPlaceholder: 'Investigación',
        itemUnit: 'elemento',
        itemsUnit: 'elementos',
    },
    Select: {
        notFoundContent: 'No hay resultados',
    },
    Pagination: {
        // Options.jsx
        items_per_page: '/ página',
        jump_to: 'Ir a',
        page: '',

        // Pagination.jsx
        prev_page: 'Página Anterior',
        next_page: 'Página Siguiente',
        prev_5: '5 Páginas Anteriores',
        next_5: '5 Páginas Siguientes',
        prev_3: '3 Páginas Anteriores',
        next_3: '3 Páginas Siguientes',
    },
    TimePicker: {
        placeholder: '',
    },
    DatePicker: {
        lang: {
            today: 'Hoy',
            now: 'Ahora',
            backToToday: 'Volver al día de hoy',
            ok: 'Ok',
            clear: 'Vacío',
            month: 'Meses',
            year: 'Año',
            timeSelect: 'Seleccione el tiempo',
            dateSelect: 'Seleccione una fecha',
            monthSelect: 'Seleccione un mes',
            yearSelect: 'Seleccione un año',
            decadeSelect: 'Seleccione una década',
            yearFormat: 'YYYY',
            dateFormat: 'M/D/YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'M/D/YYYY HH:mm:ss',
            monthFormat: 'MMMM',
            monthBeforeYear: true,
            previousMonth: 'Mes anterior (PageUp)',
            nextMonth: 'El próximo mes (PageDown)',
            previousYear: 'Año anterior (Control + left)',
            nextYear: 'El próximo año (Control + right)',
            previousDecade: 'La próxima década',
            nextDecade: 'Décennie suivante',
            previousCentury: 'Siglo anterior',
            nextCentury: 'El próximo siglo',

            placeholder: 'Seleccione una fecha',
            rangePlaceholder: ['Fecha de inicio', 'Fecha de finalización'],
        },
    },
};

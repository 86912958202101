import { useEffect, useState } from 'react';
import ZoneStore from 'stores/ZoneStore';
import ZoneActions from 'actions/ZoneActions';
import HarbourStore from 'stores/HarbourStore';

export default function useZonesOfHarbour(harbour) {
    const [zones, setZones] = useState([]);

    useEffect(() => {
        const currentHarbour = HarbourStore.getById(harbour.id);
        function refreshZonesOfCurrentHarbour() {
            setZones(ZoneStore.getByHarbour(currentHarbour));
        }

        refreshZonesOfCurrentHarbour();
        ZoneActions.reloadByHarbour(currentHarbour);

        ZoneStore.addChangeListener(refreshZonesOfCurrentHarbour);
        return () => {
            ZoneStore.removeChangeListener(refreshZonesOfCurrentHarbour);
        };
    }, [harbour.id]);

    return zones;
}

import React from 'react';
import { Form, Button, Row, Col, Icon } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';
import FormService from 'services/utils/FormService';
import moment from 'moment';
import HarbourStore from 'stores/HarbourStore';
import UploadButton from 'components/UploadButton';
import ToastActions from 'actions/ToastActions';
import SecurityService from 'services/SecurityService';

const gutter = 12;

function renderUploadPictureButton() {
    const harbour = HarbourStore.getCurrentHarbour();
    return (
        <UploadButton
            single
            reloadAfterSuccess
            url={`harbours/${harbour.id}/picture`}
            onError={() => ToastActions.createToastError(Locale.trans('configuration.upload_failed'))}
            onSuccess={() => ToastActions.createToastSuccess(Locale.trans('configuration.photo_updated'))}
        >
            {Locale.trans('configuration.photo_update')}
        </UploadButton>
    );
}

function renderUploadPlanButton() {
    if (!SecurityService.isGranted(['super-admin'])) {
        return null;
    }
    const harbour = HarbourStore.getCurrentHarbour();
    return (
        <UploadButton
            single
            reloadAfterSuccess
            url={`harbours/${harbour.id}/plan`}
            onError={() => ToastActions.createToastError(Locale.trans('configuration.upload_failed'))}
            onSuccess={() => ToastActions.createToastSuccess(Locale.trans('configuration.map_updated'))}
        >
            {Locale.trans('configuration.map_update')}
        </UploadButton>
    );
}

function renderUploadUserCsvButton() {
    if (!SecurityService.isGranted(['super-admin'])) {
        return null;
    }
    const harbour = HarbourStore.getCurrentHarbour();
    return (
        <UploadButton
            single
            reloadAfterSuccess
            url={`harbours/${harbour.id}/users.csv`}
            onError={() => ToastActions.createToastError(Locale.trans('configuration.upload_failed'))}
            onSuccess={() => ToastActions.createToastSuccess(Locale.trans('configuration.import_users_success'))}
        >
            {Locale.trans('configuration.import_users')}
        </UploadButton>
    );
}

class HarbourForm extends FormBase {

    constructor(props) {
        super(props);

        this.state = {
            valueMiscellanous: '',
        };
    }

    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        const { form, onSubmit } = this.props;
        const { valueMiscellanous } = this.state;
        e.preventDefault();
        this.isFormSubmited(true);
        form.validateFieldsAndScroll((err, values) => {
            if (!err && onSubmit) {
                const newValue = { ...values };
                const harbour = this.getHarbourFromValues(newValue);
                harbour.miscellanous = valueMiscellanous;
                onSubmit(harbour);
            }
        });
    };

    getHarbourFromValues = (values) => {
        const harbour = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.forEach((k) => {
            if (k.endsWith('_ids')) {
                return;
            }
            if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                harbour[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                harbour[k] = values[k];
            }
        });
        return harbour;
    };

    updateMiscellanous = (value) => {
        this.setState({
            valueMiscellanous: value,
        });
    };

    render() {
        const { form, loading, harbour } = this.props;

        return (
            <Form onSubmit={this.handleSubmit}>
                <Row gutter={gutter}>
                    <Col span={8}>
                        <fieldset className="bold">
                            <legend>{Locale.trans('configuration.port')}</legend>
                            <FormItem.Input
                                id="name"
                                required
                                label={Locale.trans('harbour.name')}
                                form={form}
                                validateStatus={this.getValidateStatus('name')}
                                help={this.getHelp('name')}
                                hasFeedback
                            />
                            <FormItem.Select
                                id="defaultLocale"
                                required
                                label={Locale.trans('harbour.defaultLocale')}
                                form={form}
                                options={Locale.getAvailableLocales().map((l) => ({
                                    value: l,
                                    label: Locale.trans(`locale.${l}`),
                                }))}
                            />
                            <FormItem.CountrySelect
                                id="country"
                                required
                                label={Locale.trans('harbour.country')}
                                form={form}
                            />
                            <Row gutter={gutter}>
                                <Col span={12}>
                                    <FormItem.Input
                                        id="latitude"
                                        required
                                        label={Locale.trans('harbour.latitude')}
                                        form={form}
                                        validateStatus={this.getValidateStatus('latitude')}
                                        help={this.getHelp('latitude')}
                                        hasFeedback
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormItem.Input
                                        id="longitude"
                                        required
                                        label={Locale.trans('harbour.longitude')}
                                        form={form}
                                        validateStatus={this.getValidateStatus('longitude')}
                                        help={this.getHelp('longitude')}
                                        hasFeedback
                                    />
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                    <Col span={8}>
                        <fieldset className="bold">
                            <legend>{Locale.trans('configuration.absence')}</legend>
                            <FormItem.Input
                                id="absenceEmail"
                                label={Locale.trans('harbour.absenceEmail')}
                                form={form}
                                prefix="@"
                            />
                        </fieldset>
                        <fieldset className="bold">
                            <legend>{Locale.trans('configuration.security')}</legend>
                            <FormItem.Input
                                id="commitmentEmail"
                                label={Locale.trans('harbour.commitmentEmail')}
                                form={form}
                                prefix="@"
                            />
                            <Row gutter={12}>
                                <Col span={12}>
                                    <FormItem.Input
                                        id="minutesBeforeEmail"
                                        label={Locale.trans('harbour.minutesBeforeEmail')}
                                        form={form}
                                        prefix={<Icon type="clock-circle" />}
                                        suffix="min"
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormItem.Input
                                        id="intervalMinutesBetweenMail"
                                        label={Locale.trans('harbour.intervalMinutesBetweenMail')}
                                        form={form}
                                        prefix={<Icon type="clock-circle" />}
                                        suffix="min"
                                    />
                                </Col>
                            </Row>
                        </fieldset>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 16,
                            justifyContent: 'center',
                        }}>
                            {renderUploadPictureButton()}
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 16,
                            justifyContent: 'center',
                        }}>
                            {renderUploadPlanButton()}
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 16,
                            justifyContent: 'center',
                        }}>
                            {renderUploadUserCsvButton()}
                        </div>
                    </Col>
                    <Col span={8}>
                        <fieldset className="bold">
                            <legend>{Locale.trans('configuration.miscellanous')}</legend>
                            <FormItem.MarkdownInput
                                initialValue={harbour.miscellanous}
                                key={harbour.id}
                                form={form}
                                rows={20}
                                onChange={this.updateMiscellanous}
                            />
                        </fieldset>
                    </Col>
                </Row>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                    size={'large'}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }
}

export default FormService.create(HarbourForm, (key, value) => {
    if ((key === 'hourBeginDay' || key === 'hourEndDay') && typeof value === 'string') {
        return moment(value, 'HH:mm');
    }
    return value;
});

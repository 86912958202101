import React, { useEffect, useState } from 'react';
import { Modal, Popover, Button, Icon } from 'antd';

import CommitmentStore from 'stores/CommitmentStore';
import UserStore from 'stores/UserStore';
import LoginStore from 'stores/LoginStore';
import CommitmentActions from 'actions/CommitmentActions';
import UserActions from 'actions/UserActions';
import LocaleFactory from 'locale/LocaleFactory';

import SecurityService from 'services/SecurityService';
import CommitmentHistoryModal from './CommitmentHistoryModal';
import RoleConstants from 'constants/RoleConstants';
import useCurrentHarbour from 'hooks/useCurrentHarbour';
import useHarbourModules, { ADVANCED } from 'hooks/useHarbourModules';

function nl2br(str) {
    return str ? str.split('\n').map((item, key) => (
        <span key={key}>
            {item}
            <br />
        </span>
    )) : null;
}

function showConfirmCommitmentModal({ onOk }) {
    Modal.confirm({
        title: LocaleFactory.trans(`commitment.confirmModal.title`),
        content: nl2br(LocaleFactory.trans(`commitment.confirmModal.content`)),
        onOk,
    });
}

function showConfirmDisCommitmentModal({ onOk }) {
    Modal.confirm({
        title: LocaleFactory.trans(`commitment.disconfirmModal.title`),
        content: nl2br(
            LocaleFactory.trans(`commitment.disconfirmModal.content`),
        ),
        onOk,
    });
}

export default function CurrentCommitmentList() {
    const harbour = useCurrentHarbour();
    const modules = useHarbourModules();

    const [commitments, setCommitments] = useState([]);
    const [users, setUsers] = useState([]);
    const [historyVisible, setHistoryVisible] = useState(false);

    useEffect(() => {
        function refreshCommitments() {
            setCommitments(CommitmentStore.getCurrentByHarbour(harbour));
        }

        refreshCommitments();
        CommitmentActions.reloadCurrentByHarbour(harbour);
        const interval = setInterval(
            () => CommitmentActions.reloadCurrentByHarbour(harbour),
            30000,
        );

        CommitmentStore.addChangeListener(refreshCommitments);
        return () => {
            CommitmentStore.removeChangeListener(refreshCommitments);
            clearInterval(interval);
        };
    }, [harbour]);

    useEffect(() => {
        function refreshUsers() {
            setUsers(UserStore.getByHarbour(harbour));
        }

        refreshUsers();
        UserActions.reloadByHarbour(harbour);

        UserStore.addChangeListener(refreshUsers);
        return () => {
            UserStore.removeChangeListener(refreshUsers);
        };
    }, [harbour]);

    if (
        modules.security < ADVANCED ||
        modules.commitment < ADVANCED ||
        !SecurityService.isGranted([
            RoleConstants.SECURITY_OFFICER,
            RoleConstants.SURVEYOR,
        ])
    ) {
        return null;
    }

    const userTypes = ['agent', 'supervisor'];

    return (
        <div className="current-commitment-list">
            {userTypes.map((userType) => (
                <CommitmentListItem
                    key={userType}
                    harbour={harbour}
                    userType={userType}
                    commitments={commitments}
                    users={users}
                    onHistoryClick={() => setHistoryVisible(true)}
                />
            ))}
            <CommitmentHistoryModal
                visible={historyVisible}
                onCancel={() => setHistoryVisible(false)}
            />
        </div>
    );
}

const StartCommitmentButton = ({
    setPopoverVisible,
    user,
    userType,
    harbour,
}) => {
    function canStartCommitment() {
        const me = LoginStore.getUser();
        if (user && user.id === me.id) {
            return false;
        }

        if (
            userType === 'agent' &&
            SecurityService.isGranted([RoleConstants.SECURITY_OFFICER])
        ) {
            return true;
        }
        if (
            userType === 'supervisor' &&
            SecurityService.isGranted([RoleConstants.SURVEYOR])
        ) {
            return true;
        }
        return false;
    }
    if (!canStartCommitment()) {
        return null;
    }
    return (
        <Button
            type="primary"
            onClick={() => {
                showConfirmCommitmentModal({
                    onOk: () => CommitmentActions.create(harbour, userType),
                });
                setPopoverVisible(false);
            }}
        >
            {LocaleFactory.trans(`commitment.engage`)}
        </Button>
    );
};

function CommitmentListItem({
    userType,
    commitments,
    harbour,
    users,
    onHistoryClick,
}) {
    const [popoverVisible, setPopoverVisible] = useState(false);

    const commitment = commitments.find((c) => c.userType === userType);
    const user = commitment && users.find((u) => u.id === commitment.user.id);

    function canDiscommit() {
        if (!harbour.isDiscommitAllowed) {
            return false;
        }
        if (commitment === undefined) {
            return false;
        }
        return user && user.id === LoginStore.getUser().id;
    }

    return (
        <div className="current-commitment-list__item">
            <Popover
                title={null}
                trigger="click"
                placement="bottomLeft"
                overlayClassName="current-commitment-list__item__menu"
                visible={popoverVisible}
                onVisibleChange={setPopoverVisible}
                content={
                    <React.Fragment>
                        <StartCommitmentButton
                            setPopoverVisible={setPopoverVisible}
                            user={user}
                            userType={userType}
                            harbour={harbour}
                        />
                        <Button
                            onClick={() => {
                                onHistoryClick();
                                setPopoverVisible(false);
                            }}
                        >
                            {LocaleFactory.trans(`commitment.showHistory`)}
                        </Button>
                        {canDiscommit() && (
                            <Button
                                type="primary"
                                onClick={() => {
                                    showConfirmDisCommitmentModal({
                                        onOk: () =>
                                            CommitmentActions.stop(
                                                harbour,
                                                commitment.id,
                                            ),
                                    });
                                    setPopoverVisible(false);
                                }}
                            >
                                {LocaleFactory.trans(`commitment.disengage`)}
                            </Button>
                        )}
                    </React.Fragment>
                }
            >
                <div className="current-commitment-list__item__user-type">
                    {LocaleFactory.trans(`commitment.userTypes.${userType}`)}{' '}
                    <Icon type="down" />
                </div>
            </Popover>
            <div className="current-commitment-list__item__user">
                {
                    user ? `${user.firstName} ${user.lastName}` : null // TODO : Afficher directement le bouton "s'engager"
                }
            </div>
        </div>
    );
}

import useCurrentHarbour from './useCurrentHarbour';

export const STANDARD = 0;
export const ADVANCED = 1;
export const PREMIUM = 2;

const DEFAULT_MODULES = {
    weather: 0,
    webcam: 0,
    info: 0,
    absence: 0,
    security: 0,
    commitment: 0,
    rate: 0,
    communication: 0,
};

export default function useHarbourModules() {
    const harbour = useCurrentHarbour();
    return (harbour && harbour.modules) || DEFAULT_MODULES;
}

// @flow

import React from 'react';

import { Icon } from 'antd';

function LoadingIcon() {
    return <Icon type="loading" spin />;
}

export default LoadingIcon;

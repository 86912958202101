import React from 'react';

import ScrollArea from 'react-scrollbar';
import { Layout } from 'antd';

/**
 * A layout side menu that can be collapsable.
 * Based on the AntD sider.
 */
function Sider({
    children, onCollapse, collapsed, showMask,
}) {
    return (
        <React.Fragment>
            {showMask && !collapsed && (
                <div className="sider-mask" onClick={() => onCollapse(true)} />
            )}
            <Layout.Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <ScrollArea horizontal={false}>{children}</ScrollArea>
            </Layout.Sider>
        </React.Fragment>
    );
}

export default Sider;

import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import PartnerService from 'services/PartnerService';

import CacheManager from 'services/CacheManager';
import HarbourStore from 'stores/HarbourStore';

const CACHE_DURATION = 15 * 1000;

const RELOAD_INTERVAL = 20 * 1000;
const intervalIds = {} as { [harbourId: number]: any };
class PartnerActions extends ActionsBase {
    create = (harbour: IHarbour, partner: Object) => {
        const $this = this;
        return new Promise(
            (
                resolve: (partner: IPartner) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newPartner: IPartner): void {
                    dispatcher.dispatch({
                        type: 'Partner:receivePartner',
                        payload: {
                            harbour,
                            partner: newPartner,
                        },
                    });
                    resolve(newPartner);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                PartnerService.post(harbour.id, partner)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    edit = (harbour: Entity, partnerId: number, partner: Object) => {
        const $this = this;
        return new Promise(
            (
                resolve: (partner: IPartner) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newPartner: IPartner): void {
                    dispatcher.dispatch({
                        type: 'Partner:receivePartner',
                        payload: {
                            harbour,
                            partner: newPartner,
                        },
                    });
                    resolve(newPartner);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                PartnerService.patch(harbour.id, partnerId, partner)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    delete = (harbour: Entity, id: number) => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                function handleSuccess(): void {
                    dispatcher.dispatch({
                        type: 'Partner:deletePartner',
                        payload: {
                            harbour,
                            id,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                PartnerService.remove(harbour.id, id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    reload = (force?: boolean) =>
        this.reloadByHarbour(HarbourStore.getCurrentHarbour(), force);

    reloadByHarbour = (harbour: Entity | null | undefined, force?: boolean) => {
        if (harbour && !intervalIds[harbour.id]) {
            intervalIds[harbour.id] = setInterval(() => {
                this.reloadByHarbour(harbour).catch(function () {
                    clearInterval(intervalIds[harbour.id]);
                    intervalIds[harbour.id] = null;
                });
            }, RELOAD_INTERVAL);
        }

        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                if (
                    !harbour ||
                    (!force &&
                        CacheManager.isCached(
                            'Partner:reloadByHarbour',
                            harbour.id.toString(),
                            CACHE_DURATION,
                        ))
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(partners: Array<IPartner>): void {
                    dispatcher.dispatch({
                        type: 'Partner:receivePartnersOfHarbour',
                        payload: {
                            harbour,
                            partners,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                PartnerService.getByHarbour(harbour.id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };
}

export default new PartnerActions();

export default class FilterService {
    static matchFilter(
        filter: string | number | Array<any>,
        value: string | number | Array<any>,
    ) {
        if (!filter) {
            return true;
        }

        if (Array.isArray(filter)) {
            if (filter.length === 0) {
                return true;
            }

            if (Array.isArray(value)) {
                return filter.some(r => value.indexOf(r) >= 0);
            } else {
                return filter.indexOf(value) !== -1;
            }
        } else {
            return filter.toString() === value.toString();
        }
    }
}

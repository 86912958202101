import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class PlaceService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all places of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/places`,
            method: 'GET',
        });
    }

    /**
     * Get a place by unique identifier.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} placeId The identifier of the place.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    getById(harbourId: number, placeId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/places/${placeId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new place to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The place to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/places`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing place.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} placeId The identifier of the place.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, placeId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/places/${placeId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} placeId The identifier of the place.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, placeId: number, patch: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/places/${placeId}`,
            method: 'PATCH',
            data: patch,
        });
    }
}

export default new PlaceService();

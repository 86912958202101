const roles = {
    ADMINISTRATOR: 'administrator',
    CAPTAIN: 'captain',
    YACHTSMAN: 'yachtsman',
    SECURITY_OFFICER: 'security_officer',
    PROFESSIONAL: 'professional',
    SURVEYOR: 'surveyor',
    ANY: 'any.role.nauticspot',
    SUPER_ADMIN: 'super_admin',
    VISITOR: 'visitor',
};

export default roles;

import EventEmitter from 'events';
import AppDispatcher from 'dispatchers/AppDispatcher';

/**
 * The Base Store from witch all stores must inherit.
 * It contains some utils methods to subscribe to actions and manage change events.
 */
// @ts-ignore
export default class BaseStore extends EventEmitter {
    _dispatchToken: string | null;
    constructor() {
        super();
        this._dispatchToken = null;
    }

    subscribe(
        actionSubscribe: () => (action: {
            type: string;
            payload: Object;
        }) => void,
    ) {
        this._dispatchToken = AppDispatcher.register(actionSubscribe());
    }

    getDispatchToken(): string | null {
        return this._dispatchToken;
    }



    emitChange(): void {
        // @ts-ignore
        this.emit('CHANGE');
    }
    addChangeListener(callback: () => void) {
        // @ts-ignore
        this.on('CHANGE', callback);
    }
    removeChangeListener(callback: () => void) {
        // @ts-ignore
        this.removeListener('CHANGE', callback);
    }

    // Those methods can be used to customize the event
    // but you'll usually use the methods above
    emitEvent(event: string): void {
        // @ts-ignore
        this.emit(event);
    }
    addEventListener(event: string, callback: () => void) {
        // @ts-ignore
        this.on(event, callback);
    }
    removeEventListener(event: string, callback: () => void) {
        // @ts-ignore
        this.removeListener(event, callback);
    }
}

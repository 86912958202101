interface CountryData {
    name: string;
    iso2: string;
    dialCode: string;
    priority: number;
    areaCodes: string[] | null;
}

class Country {
    countryCodes: { [dialCode: string]: string[] };
    countriesData: CountryData[] | null;
    countries: CountryData[] | null;

    constructor() {
        this.countryCodes = {};
        this.countriesData = null;
        this.countries = null;
    }

    setCustomCountriesData(json: CountryData[]) {
        this.countriesData = json;
    }

    addCountryCode(iso2: string, dialCode: string, priority?: number) {
        if (!(dialCode in this.countryCodes)) {
            this.countryCodes[dialCode] = [];
        }

        const index = priority || 0;
        this.countryCodes[dialCode][index] = iso2;
    }

    getAll(): CountryData[] {
        if (!this.countries) {
            this.countries = (
                this.countriesData || require('./countries.json')
            ).sort((c1: CountryData, c2: CountryData) =>
                c1.name > c2.name ? 1 : -1,
            );
        }

        return this.countries as CountryData[];
    }

    getCountryCodes() {
        if (!this.countryCodes.length) {
            this.getAll().forEach((country) => {
                this.addCountryCode(
                    country.iso2,
                    country.dialCode,
                    country.priority,
                );
                if (country.areaCodes) {
                    country.areaCodes.forEach((areaCode) => {
                        this.addCountryCode(
                            country.iso2,
                            country.dialCode + areaCode,
                        );
                    });
                }
            });
        }
        return this.countryCodes;
    }

    getCountryDataByCode(iso2: string) {
        return this.getAll().find((country) => country.iso2 === iso2);
    }
}

export default new Country();



import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import ExternalLinkService from 'services/ExternalLinkService';

import CacheManager from 'services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class ExternalLinkActions extends ActionsBase {
    create = (harbour: IHarbour, externalLink: Object) => {
        const $this = this;
        return new Promise((resolve: (externalLink: IExternalLink) => void, reject: (response: Object) => void): void => {
            function handleSuccess(newExternalLink: IExternalLink): void {
                dispatcher.dispatch({
                    type: 'ExternalLink:receiveOneByHarbour',
                    payload: {
                        harbour,
                        externalLink: newExternalLink,
                    },
                });
                resolve(newExternalLink);
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            ExternalLinkService.post(harbour.id, externalLink)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (harbour: Entity, externalLinkId: number, externalLink: Object) => {
        const $this = this;
        return new Promise((resolve: (externalLink: IExternalLink) => void, reject: (response: Object) => void): void => {
            function handleSuccess(newExternalLink: IExternalLink): void {
                dispatcher.dispatch({
                    type: 'ExternalLink:receiveOneByHarbour',
                    payload: {
                        harbour,
                        externalLink: newExternalLink,
                    },
                });
                resolve(newExternalLink);
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            ExternalLinkService.patch(harbour.id, externalLinkId, externalLink)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    delete = (harbour: Entity, id: number) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            function handleSuccess(): void {
                dispatcher.dispatch({
                    type: 'ExternalLink:deleteOneByHarbour',
                    payload: {
                        harbour,
                        id,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            ExternalLinkService.remove(harbour.id, id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByHarbour = (harbour: Entity, force?: boolean) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            if (
                !force &&
                CacheManager.isCached(
                    'ExternalLink:reloadByHarbour',
                    harbour.id.toString(),
                    CACHE_DURATION,
                )
            ) {
                resolve();
                return;
            }

            function handleSuccess(externalLinks: Array<IExternalLink>): void {
                dispatcher.dispatch({
                    type: 'ExternalLink:receiveByHarbour',
                    payload: {
                        harbour,
                        externalLinks,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            ExternalLinkService.getByHarbour(harbour.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

export default new ExternalLinkActions();

import React from 'react';
import { Modal, Icon, Input, Button } from 'antd';
import ReactPlayer from 'react-player';

import BaseUrlConstants from '../constants/BaseUrlConstants';
import LoginStore from '../stores/LoginStore';

import Locale from '../locale/LocaleFactory';
import DateService from '../services/utils/DateService';

import ZoneStore from '../stores/ZoneStore';
import UserStore from '../stores/UserStore';
import PlaceStore from '../stores/PlaceStore';

import IncidentActions from '../actions/IncidentActions';
import ToastActions from '../actions/ToastActions';

import SecurityService from '../services/SecurityService';
import RoleConstants from '../constants/RoleConstants';
import HarbourStore from '../stores/HarbourStore';
import IncidentConstants from '../constants/IncidentConstants';
import IncidentStore from '../stores/IncidentStore';

import './ShowIncidentModal.scss';

function nl2br(str) {
    return str ? str.split('\n').map((item, key) => (
        <span key={key}>
            {item}
            <br />
        </span>
    )) : null;
}

/**
 * A modal to show attachments.
 */
export default class ShowIncidentModal extends React.Component {
    constructor() {
        super();
        this.state = {
            newHarbourComment: null,
        };
    }

    componentDidMount() {
        IncidentStore.addChangeListener(this.forceUpdate);
    }

    componentWillUnmount() {
        IncidentStore.removeChangeListener(this.forceUpdate);
    }

    acknoledgeIncident = (incident) => {
        IncidentActions.acknowledge(incident.id).then(() => {
            ToastActions.createToastSuccess('Incident pris en compte');
        });
    };

    closeIncident = (incident) => {
        IncidentActions.close(incident.id).then(() => {
            ToastActions.createToastSuccess('Incident clos');
        });
    };

    render() {
        const { visible, onCancel, incidentId } = this.props;

        const incident = IncidentStore.getById(incidentId);
        if (!incident) {
            return null;
        }
        return (
            <Modal
                title={this.renderTitle(incident)}
                visible={visible}
                onCancel={onCancel}
                footer={null}
                className="incident-modal"
            >
                {this.renderInfo(incident)}
                {this.renderAttachments(incident)}
            </Modal>
        );
    }

    renderTitle(incident) {
        const canAcknowledge = SecurityService.isGranted([
            RoleConstants.SECURITY_OFFICER,
            RoleConstants.SURVEYOR,
        ]);
        const canClose = SecurityService.isGranted([RoleConstants.SURVEYOR]);

        return (
            <div>
                <div className="incident-icon">
                    <img
                        src={`images/icon_${incident.incidentType}.svg`}
                        alt={incident.incidentType}
                    />
                </div>
                {Locale.trans(`incident.types.${incident.incidentType}`)}
                {incident.comment && incident.comment.length && (
                    <span
                        style={{
                            display: 'inline-block',
                            marginLeft: 8,
                            fontStyle: 'italic',
                        }}
                    >
                        ({incident.comment})
                    </span>
                )}

                {canAcknowledge &&
                    incident.status === IncidentConstants.PENDING && (
                        <Button
                            className="incident-status-btn"
                            type="primary"
                            onClick={() => {
                                this.acknoledgeIncident(incident);
                            }}
                        >
                            {Locale.trans('incident.action.acknowledge')}
                        </Button>
                    )}
                {canClose &&
                    incident.status === IncidentConstants.ACKNOWLEDGED && (
                        <Button
                            className="incident-status-btn"
                            type="success"
                            onClick={(e) => {
                                this.closeIncident(incident);
                            }}
                        >
                            {Locale.trans('incident.action.close')}
                        </Button>
                    )}
            </div>
        );
    }

    renderInfo(incident) {
        const { newHarbourComment } = this.state;

        const user = incident.user ? UserStore.getById(incident.user.id) : null;

        let zoneName;
        if (incident.place) {
            const place = PlaceStore.getById(incident.place.id);
            zoneName = place ? place.code : '';
        }

        if (incident.zone) {
            const zone = ZoneStore.getById(incident.zone.id);
            zoneName = zone ? zone.name : '';
        }

        const canEditHarbourComment = SecurityService.isGranted([
            RoleConstants.CAPTAIN,
            RoleConstants.SURVEYOR,
            RoleConstants.SECURITY_OFFICER,
        ]);

        return (
            <div className="display-form">
                <div className="panel">
                    <div className="content">
                        <div className="field">
                            <span className="label">
                                {Locale.trans('incident.user')}
                            </span>
                            <span>
                                {user
                                    ? `${user.firstName} ${user.lastName}`
                                    : 'Anonyme'}
                            </span>
                        </div>
                        <div className="field">
                            <span className="label">
                                {Locale.trans('incident.zone')}
                            </span>
                            <span>{zoneName}</span>
                        </div>
                        <div className="field">
                            <span className="label">
                                {Locale.trans('incident.createdAt')}
                            </span>
                            <span>
                                {DateService.formatApiToDisplay(
                                    incident.createdAt,
                                    true,
                                )}
                            </span>
                        </div>
                        <div className="field">
                            <span className="label">
                                {Locale.trans('incident.status')}
                            </span>
                            <span>
                                {Locale.trans(
                                    `incident.statuses.${incident.status}`,
                                )}
                            </span>
                        </div>

                        {incident.confirmedBy &&
                            incident.confirmedBy.length > 0 && (
                                <div className="incident-confirmedBy">
                                    <Icon type="check-circle" />
                                    {incident.confirmedBy.length === 1
                                        ? Locale.trans(
                                              'incident.confirmedByOne',
                                          )
                                        : Locale.trans('incident.confirmedBy', {
                                              COUNT:
                                                  incident.confirmedBy.length,
                                          })}
                                </div>
                            )}

                        {this.renderAcknowledgedInfo(incident)}
                        {this.renderClosedInfo(incident)}

                        {incident.comments && (
                            <div className="field" style={{ width: '100%' }}>
                                <span className="label">
                                    {Locale.trans('incident.comments')}
                                </span>
                                <span>{nl2br(incident.comments)}</span>
                            </div>
                        )}

                        {canEditHarbourComment && (
                            <div className="harbour-comments">
                                <div className="label">
                                    {Locale.trans('incident.harbourComment')}
                                </div>
                                {incident.harbourComments
                                    .sort((a, b) =>
                                        a.createdAt > b.createdAt ? 1 : -1,
                                    )
                                    .map((comment) => {
                                        const author = UserStore.getById(
                                            comment.author.id,
                                        );
                                        return (
                                            <div key={comment.id} className="panel __comment">
                                                {nl2br(comment.comment)}
                                                <div className="__footer">
                                                    {author
                                                        ? `Par ${
                                                              author.firstName
                                                          } ${
                                                              author.lastName
                                                          } le ${DateService.formatApiToDisplay(
                                                              comment.createdAt,
                                                              true,
                                                          )}`
                                                        : DateService.formatApiToDisplay(
                                                              comment.createdAt,
                                                              true,
                                                          )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                <div className="field harbour-comment">
                                    <Input.TextArea
                                        value={newHarbourComment}
                                        onChange={(e) => {
                                            this.setState({
                                                newHarbourComment:
                                                    e.target.value,
                                            });
                                        }}
                                    />
                                    <Button
                                        disabled={!this.state.newHarbourComment || this.state.newHarbourComment.length === 0}
                                        type="primary"
                                        onClick={() => {
                                            IncidentActions.addComment(
                                                HarbourStore.getCurrentHarbour(),
                                                incident.id,
                                                this.state.newHarbourComment,
                                            ).then(() => {
                                                this.setState({
                                                    newHarbourComment: null,
                                                });
                                                ToastActions.createToastSuccess(
                                                    Locale.trans(
                                                        'incident.update.success',
                                                    ),
                                                );
                                            });
                                        }}
                                        style={{ marginTop: 8 }}
                                    >
                                        {Locale.trans(
                                            'incident.saveHarbourComment',
                                        )}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    renderAcknowledgedInfo(incident) {
        if (!incident || !incident.acknowlegedAt) {
            return null;
        }

        let user = null;
        if (incident.acknowlegedBy) {
            user = UserStore.getById(incident.acknowlegedBy.id);
        }

        const date = DateService.formatApiToDisplay(
            incident.acknowlegedAt,
            true,
            Locale.trans('at'),
        );

        return (
            <div className="incident__acknowledge-info">
                <Icon type="check-circle" />
                {user
                    ? Locale.trans('incident.acknowlegedAt_by', {
                          DATE: date,
                          USER: `${user.firstName} ${user.lastName}`,
                      })
                    : Locale.trans('incident.acknowlegedAt', { DATE: date })}
            </div>
        );
    }

    renderClosedInfo(incident) {
        if (!incident || !incident.closedAt) {
            return null;
        }

        let user = null;
        if (incident.closedBy) {
            user = UserStore.getById(incident.closedBy.id);
        }

        const date = DateService.formatApiToDisplay(
            incident.closedAt,
            true,
            Locale.trans('at'),
        );

        return (
            <div className="incident__close-info">
                <Icon type="check-circle" />
                {user
                    ? Locale.trans('incident.closedAt_by', {
                          DATE: date,
                          USER: `${user.firstName} ${user.lastName}`,
                      })
                    : Locale.trans('incident.closedAt', { DATE: date })}
            </div>
        );
    }

    renderAttachments(incident) {
        if (incident.attachments.length === 0) {
            return Locale.trans('incident.no_attachment');
        }

        return (
            <div className="attachments">
                {incident.attachments.map((a) =>
                    this.renderAttachment(a, incident),
                )}
            </div>
        );
    }

    renderAttachment = (attachment, incident) => {
        const { fileName } = attachment;

        const token = LoginStore.getJwt();
        const formattedToken = token ? token.replace(/\+/g, '%2B') : '';
        const url = `${BaseUrlConstants.BASE_URL}harbours/${incident.harbour.id}/incidents/${incident.id}/attachments/${attachment.id}`;

        if (fileName.match(/.(jpg|jpeg|png|gif)$/i)) {
            return (
                <div key={attachment.id} className="attachment-picture">
                    <img
                        alt={fileName}
                        src={`${url}?X-Auth-Token=${formattedToken}`}
                    />
                </div>
            );
        }

        if (ReactPlayer.canPlay(fileName)) {
            return (
                <div key={attachment.id} className="attachment-video">
                    <ReactPlayer
                        url={`${url}?X-Auth-Token=${formattedToken}`}
                        controls
                    />
                </div>
            );
        }

        return null;
    };
}

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import { Moment } from 'moment';

const URL: string = `${BaseUrlConstants.BASE_URL}harbours`;

class HarbourService extends ServiceBase {
    constructor() {
        super(URL);
    }

    getOutingsByHarbour(harbourId: number, filters: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/reporting/outings`,
            method: 'GET',
            data: this._getDataFromFilters(filters),
        });
    }

    getOutingsByBoatTypes(harbourId: number, filters: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/reporting/by_type`,
            method: 'GET',
            data: this._getDataFromFilters(filters),
        });
    }
    getOutingsByBoats(harbourId: number, filters: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/reporting/top10`,
            method: 'GET',
            data: this._getDataFromFilters(filters),
        });
    }

    _getDataFromFilters(filters: any) {
        return {
            start: filters.period.start.format('YYYY-MM-DD'),
            end: filters.period.end.format('YYYY-MM-DD'),
            boatType: filters.boatType,
            yearCompared: filters.yearCompared,
            categorie: filters.categorie,
            user: filters.user,
            place: filters.place,
            zone: filters.zone,
        };
    }

    getOccupancyRatesByHarbour(harbourId: number, filters: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/reporting/occupancy/history`,
            method: 'GET',
            data: this._getDataFromFilters(filters),
        });
    }

    getOptimisationByHarbour(harbourId: number, filters: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/reporting/optimisation`,
            method: 'GET',
            data: this._getDataFromFilters(filters),
        });
    }
    getOutingsByDayByHarbour(harbourId: number, filters: any, day: Moment) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/reporting/outings/${day.format('Y-MM-DD')}`,
            method: 'GET',
            data: this._getDataFromFilters(filters),
        });
    }
}

export default new HarbourService();

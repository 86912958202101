import HarbourMapShapeConstants from '../constants/HarbourMapShapeConstants';
import EntityStoreBase from './EntityStoreBase';
import HarbourStore from './HarbourStore';

class HarbourMapShapeStore extends EntityStoreBase<IHarbourMapShape> {
    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = [];
    }

    _registerToActions = (action: { type: string; payload: any }) => {
        const { type, payload } = action;
        switch (type) {
            case HarbourMapShapeConstants.RECEIVE_MAP_SHAPE:
                this._receiveEntity(payload.shape);
                break;
            case HarbourMapShapeConstants.RECEIVE_MAP_SHAPES:
                this._receiveSomeEntities(payload.shapes);
                break;
            case HarbourMapShapeConstants.DELETE_MAP_SHAPE:
                this._deleteEntity(payload.id);
                break;
            default:
                // Do Nothing
                break;
        }
    };

    clear = () => {
        this._entities = [];
        this.emitChange();
    };

    // /// Public Methods  /////

    getForCurrentHarbour = () =>
        this.getByHarbour(HarbourStore.getCurrentHarbour());
    /**
     * Get the map shapes of a harbour.
     */
    getByHarbour = (harbour: IHarbour | null | undefined) =>
        harbour ? this._entities.filter(e => e.harbour.id === harbour.id) : [];
}

export default new HarbourMapShapeStore();

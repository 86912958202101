import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class RateSeasonConfigurationService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all rates of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/rate-season-configuration`,
            method: 'GET',
        });
    }

    /**
     * Post a new rate to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The rate to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    patch(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/rate-season-configuration`,
            method: 'PATCH',
            data: resource,
        });
    }
}

export default new RateSeasonConfigurationService();

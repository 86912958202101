import React from 'react';
import { Modal } from 'antd/lib/index';

import CameraForm from 'components/forms/CameraForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import CameraActions from 'actions/CameraActions';
import FormService from 'services/utils/FormService';

/**
 * The modal to edit a camera.
 */
export default class EditCameraModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loading: false,
            fields: FormService.getFieldsFromEntity(props.camera),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            this.setState({
                fields: FormService.getFieldsFromEntity(nextProps.camera),
            });
        }
    }

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (camera) => {
        if (!this.props.camera) {
            return;
        }
        const cameraId = this.props.camera.id;

        this.setState({
            loading: true,
        });
        CameraActions.edit(HarbourStore.getCurrentHarbour(), cameraId, camera)
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('camera.update.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    deleteCamera = () => {
        const { camera } = this.props;
        if (!camera) {
            return;
        }
        this.setState({
            loading: true,
        });
        CameraActions.delete(HarbourStore.getCurrentHarbour(), camera.id)
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('camera.delete.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        return (
            <Modal
                title={Locale.trans('camera.update.title')}
                visible={this.props.camera && this.props.visible}
                onCancel={this.props.onCancel}
                footer={null}
            >
                <CameraForm
                    camera={this.props.camera}
                    onChange={this.handleFormChange}
                    fields={this.state.fields}
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                    onDelete={this.deleteCamera}
                />
            </Modal>
        );
    }
}



import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import PlaceActions from 'actions/PlaceActions';
import PlaceZoneService from 'services/PlaceZoneService';

class PlaceZoneActions extends ActionsBase {
    create = (harbour: IHarbour, place: IPlace, zone: Object) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            function handleSuccess(newZone: IPlaceZone): void {
                dispatcher.dispatch({
                    type: 'PlaceZone:receiveZoneOfPlace',
                    payload: {
                        harbour,
                        place,
                        zone: newZone,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            PlaceZoneService.post(harbour.id, place.id, zone)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (harbour: IHarbour, place: IPlace, zone: Object) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            function handleSuccess(newZone: IPlaceZone): void {
                dispatcher.dispatch({
                    type: 'PlaceZone:receiveZoneOfPlace',
                    payload: {
                        harbour,
                        place,
                        zone: newZone,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            PlaceZoneService.post(harbour.id, place.id, zone)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    delete = (harbour: Entity, id: number) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            function handleSuccess(): void {
                dispatcher.dispatch({
                    type: 'PlaceZone:deleteZoneOfPlace',
                    payload: {
                        harbour,
                        id,
                    },
                });
                resolve();
                PlaceActions.reloadByHarbour(harbour, true);
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            PlaceZoneService.remove(harbour.id, id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

export default new PlaceZoneActions();

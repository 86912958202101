import React from 'react';
import { Divider, Row, Col, Popover, Alert } from 'antd';
import Panel from 'components/Panel';
import Locale from 'locale/LocaleFactory';

import ReportingFiltersStore from 'stores/ReportingFiltersStore';
import ReportingStore from 'stores/OutingByBoatTypeReportingStore';
import BoatCategoryStore from 'stores/BoatCategoryStore';

import ReportingFiltersActions from 'actions/ReportingFiltersActions';
import BoatCategoryActions from 'actions/BoatCategoryActions';

import { mainLabel, comparedLabel } from 'services/ReportingLabelService';

export default class OutingByType extends React.Component {
    state = {
        categoriesValues: [],
        types: [],
        filters: null,
        categories: [],
        dataReceived: false,
    };

    componentDidMount() {
        ReportingFiltersStore.addChangeListener(this.changeFilters);
        ReportingStore.addChangeListener(this.receiveData);
        BoatCategoryStore.addChangeListener(this.changeCategories);
        this.changeFilters();
        BoatCategoryActions.reloadForCurrentHarbour();
    }

    componentWillUnmount() {
        ReportingFiltersStore.removeChangeListener(this.changeFilters);
        ReportingStore.removeChangeListener(this.receiveData);
        BoatCategoryStore.removeChangeListener(this.changeCategories);
    }

    changeFilters = () => {
        this.setState({
            filters: ReportingFiltersStore.get(),
        });
    };

    changeCategories = () => {
        this.setState({
            categories: BoatCategoryStore.getForCurrentHarbour(),
        });
    };

    receiveData = () => {
        const outings = ReportingStore.getOutings();
        this.setState({
            categoriesValues: outings.categoryValues,
            types: outings.boatTypesValues,
            dataReceived: true,
        });
    };

    handleClickOnBoatType(boatType) {
        ReportingFiltersActions.changeType(boatType);
    }

    handleClickOnBoatCategory(boatCategory) {
        ReportingFiltersActions.changeCategory(boatCategory);
    }

    render() {
        let classname = "reporting ";
        let warning = '';
        if (this.isEmpty()) {
            classname = classname + " empty";
            if (this.state.dataReceived) {
                warning = (
                    <Alert
                        message={Locale.trans('reporting.data.no_data')}
                        description={Locale.trans('reporting.data.no_data_explanation')}
                        type="error"
                    />
                );
            } else {
                warning = (
                    <Alert
                        message={Locale.trans('reporting.data.waiting')}
                        description={Locale.trans('reporting.data.waiting_explanation')}
                        type="error"
                    />
                );
            }
        }
        return (
            <Panel
                title={Locale.trans('reporting.outing_by_type')}
                className={classname}
            >
                <Row>
                    {this.renderBoatTypes()}
                </Row>
                <Divider className="title">{Locale.trans('boat.categories')}</Divider>
                <Row>
                    {this.renderCategories()}
                </Row>
                {warning}
            </Panel>
        );
    }

    isEmpty() {
        let { types, categoriesValues } = this.state;
        let isEmpty = true;
        for (let type of types) {
            if (type.value !== 0) {
                isEmpty = false;
            }
        }
        return categoriesValues
            .filter(category => category.value !== 0 || category.comparedValue !== 0)
            .length === 0
            && isEmpty;
    }

    renderBoatTypes() {
        const { types, filters } = this.state;
        const totalType = types.length === 0 ? 0 : types.reduce((total, type) => {
            if (Number.isNaN(Number(total))) {
                return type.value;
            }
            return total + type.value;
        });
        return types.map((type) => {
            const onClick = type.value === 0 ? () => {} : () => this.handleClickOnBoatType(type.type);
            const popoverContent = (
                <div>
                    <p className="main-text">
                        {mainLabel(filters)}: {type.value}
                    </p>
                    <p className="compared-text">
                        {comparedLabel(filters)}: {type.comparedValue}
                    </p>
                </div>
            );
            return (
                <Col
                    span={24 / types.length}
                    className={`boat-type ${type.type} ${(type.type === filters.boatType) ? 'selected' : ''}`}
                    onClick={onClick}
                    key={type.type}
                >
                    <Popover
                        content={popoverContent}
                        title={Locale.trans(type.type)}
                        placement="right"
                    >
                        <div
                            className="boat-icon"
                            id={`${type.type}-icon`}
                            style={{ backgroundPositionX: `${(-1 + (type.value / totalType)) * 50}px` }}
                        >
                            <img src={`images/${type.type}-inverted.png`} alt={type.type} />
                        </div>
                        <p className="main-text">
                            {Math.round((type.value / totalType) * 100)}%
                        </p>
                    </Popover>
                </Col>
            );
        });
    }

    renderCategories() {
        let { categoriesValues } = this.state;
        const { categories, filters } = this.state;
        categoriesValues = categoriesValues
            .filter(category => category.value !== 0 || category.comparedValue !== 0)
            .sort((e1, e2) => e2.value - e1.value);
        const totalCategories = categoriesValues.length === 0 ? 0 : categoriesValues.reduce((total, category) => total + category.value, 0);
        let nbColumns = 3;
        if (categoriesValues.length === 0) {
            categoriesValues = this.state.categoriesValues.slice(0, 12);
        }
        return [...Array(nbColumns).keys()].map(columnNumber => (
            <Col
                span={24 / nbColumns}
                className="boat-category"
                key={columnNumber}
            >
                {categoriesValues
                    .slice(
                        Math.ceil(categoriesValues.length / nbColumns) * columnNumber,
                        Math.floor(categoriesValues.length / nbColumns) * (columnNumber + 1) + 1,
                    ).map((category) => {
                        const categoryFound = categories.find(categoryDef => categoryDef.name === category.category);
                        const categoryStatsPopoverContent = (
                            <div>
                                <p className="main-text">
                                    {mainLabel(filters)}: {category.value}
                                </p>
                                <p className="compared-text">
                                    {comparedLabel(filters)}: {category.comparedValue}
                                </p>
                            </div>
                        );
                        const categoryDescPopoverContent = (!categoryFound)
                            ? Locale.trans('boat.category.other.description')
                            : (
                                <div>
                                    <p>
                                        {Locale.trans('boat.length')}: {categoryFound.lengthMin} - {categoryFound.lengthMax}
                                    </p>
                                    <p>
                                        {Locale.trans('boat.width')}: {categoryFound.widthMin} - {categoryFound.widthMax}
                                    </p>
                                </div>
                            );

                        const isSelected= filters.categorie && category.id.toString() === filters.categorie.toString();
                        return (
                            <div
                                className={`category ${isSelected ? 'selected' : ''}`}
                                key={category.category}
                                onClick={() => this.handleClickOnBoatCategory(category.id)}
                                role="radio"
                                aria-checked={isSelected}
                            >
                                <Popover
                                    title={category.category}
                                    content={categoryDescPopoverContent}
                                    placement="top"
                                >
                                    {category.category}
                                </Popover> :
                                <Popover
                                    title={Locale.trans('stats')}
                                    content={categoryStatsPopoverContent}
                                    placement="right"
                                >
                                    <span
                                        className="main-text"
                                    >
                                        {totalCategories === 0 ? 0 : Math.round((category.value / totalCategories) * 100)}%
                                    </span>
                                </Popover>
                            </div>
                        );
                    })}
            </Col>
        ));
    }
}

export default {
    // NauticSpot
    Trans: {
        ok: 'OK',
        beginning: 'Début',
        cost: 'Coût',
        date: 'Date',
        day: 'jour',
        week: 'semaine',
        month: 'mois',
        days: 'jours',
        description: 'Description',
        delete: 'Supprimer',
        'delete.confirm': 'Êtes vous sur de supprimer?',
        download: 'Télécharger',
        end: 'Fin',
        edit: 'Modifier',
        save: 'Enregistrer',
        saved: 'Enregistré',
        send: 'Envoyer',
        cancel: 'Annuler',
        total: 'Total',
        notFound: 'Aucun',
        emptyText: 'Aucune donnée',
        back: 'Retour',
        close: 'Fermer',
        map: 'Plan',
        'map.stroke': 'Plan Contour',
        search: 'Rechercher',
        at: 'à',
        loading: 'Chargement',

        mister: 'Monsieur',
        miss: 'Madame',

        facebook: 'Facebook',
        linkedin: 'Linkedin',
        twitter: 'Twitter',

        matin: 'Matin',
        apresmidi: 'Après-midi',
        refused: 'Refusée',
        approved: 'Acceptée',
        requested: 'En attente',
        accepted: 'Acceptée',

        monday: 'Lundi',
        tuesday: 'Mardi',
        wednesday: 'Mercredi',
        thursday: 'Jeudi',
        friday: 'Vendredi',
        saturday: 'Samedi',
        sunday: 'Dimanche',

        welcome: 'Bonjour __USER__',

        locale: {
            fr: 'Français',
            en: 'English',
            es: 'Español',
            it: 'Italiano',
        },

        stats: 'Statistiques',
        drag_drop_invitation:
            'Cliquez ou déposez un fichier ici pour le téléverser.',

        pages: {
            home: 'Accueil',
            users: 'Plaisanciers',
            calendar: 'Calendrier',
            map: "Plan d'Eau",
            rates: 'Grille Tarifaire',
            reporting: 'Reporting',
            profile: 'Mon profil',
            configuration: 'Configuration',
            security: 'Sécurité',
            communication: 'Communication',
        },

        communication: {
            weather_upload: {
                success: 'Le bulletin a été mis à jour',
                failed:
                    "Une erreur est survenue, le bulletin n'a pas pu être mis à jour.",
            },
            notification: {
                _: 'Notifications - Historique',
                duplicate: 'Dupliquer',
                edit: {
                    title: 'Modifier',
                    success: 'Notification modifiée',
                },
                notify: 'Notifiez les plaisanciers',
                title: 'Renseignez le titre du message...',
                content: 'Détaillez le message...',
                url: 'Renseignez un lien (optionnel)',
                link: 'Lien',
                selectCategory: 'Sélectionnez une catégorie',
                selectHarbour: 'Sélectionnez un port',
                sentAt: 'Envoyée le',
                showDetails: 'Voir le détail',
                details: 'Détails',
                attachments: 'Pièce(s) jointe(s)',
                thumbnail: 'Insérez une image',
                selectThumbnail: 'Sélectionnez une image',
                recipients: {
                    _: 'Destinataires',
                    all: 'Tous',
                    'user-lists': 'Listes de diffusion',
                    pontoon: 'Pontons',
                    users: 'Plaisanciers',
                },
                type: {
                    weather: 'Météo',
                    security: 'Sécurité',
                    event: 'Événement',
                    work: 'Travaux / Maintenance',
                    'concierge-package': 'Conciergerie - Colis',
                    'concierge-mail': 'Conciergerie - Courrier',
                    news: 'Actualités',
                    other: 'Autres',
                },
                success: 'La notification a bien été envoyée',
                failure:
                    "Impossible d'envoyer la notification, une erreur est survenue",
                delete: {
                    success: 'Notification supprimée',
                },
                deleteAttachment: {
                    success: 'Pièce jointe supprimée',
                },
            },
            communication: 'Communication',
        },

        months: {
            jan: 'Janvier',
            feb: 'Février',
            mar: 'Mars',
            apr: 'Avril',
            may: 'Mai',
            jun: 'Juin',
            jul: 'Juillet',
            aug: 'Aout',
            sep: 'Septembre',
            oct: 'Octobre',
            nov: 'Novembre',
            dec: 'Décembre',
        },
        'days.short': {
            monday: 'Lun',
            tuesday: 'Mar',
            wednesday: 'Mer',
            thursday: 'Jeu',
            friday: 'Ven',
            saturday: 'Sam',
            sunday: 'Dim',
        },
        calendar: {
            max: 'Max : ',
            mean: 'Moy : ',
            min: 'Min : ',
            days: {
                long: {
                    monday: 'Lundi',
                    tuesday: 'Mardi',
                    wednesday: 'Mercredi',
                    thursday: 'Jeudi',
                    friday: 'Vendi',
                    saturday: 'Samedi',
                    sunday: 'Dimanche',
                },
                short: {
                    monday: 'Lun',
                    tuesday: 'Mar',
                    wednesday: 'Mer',
                    thursday: 'Jeu',
                    friday: 'Ven',
                    saturday: 'Sam',
                    sunday: 'Dim',
                },
            },
            months: {
                long: {
                    jan: 'Janvier',
                    feb: 'Février',
                    mar: 'Mars',
                    apr: 'Avril',
                    may: 'Mai',
                    jun: 'Juin',
                    jul: 'Juillet',
                    aug: 'Août',
                    sep: 'Septembre',
                    oct: 'Octobre',
                    nov: 'Novembre',
                    dec: 'Décembre',
                },
                short: {
                    jan: 'Jan',
                    feb: 'Fév',
                    mar: 'Mar',
                    apr: 'Avr',
                    may: 'Mai',
                    jun: 'Juin',
                    jul: 'Juil',
                    aug: 'Août',
                    sep: 'Sep',
                    oct: 'Oct',
                    nov: 'Nov',
                    dec: 'Déc',
                },
            },
        },

        license:
            'Nauticspot tout droits réservés © 2018 Créé par IAS Ingenierie',

        address: {
            title: 'Adresse',
            street: 'Rue',
            country: 'Pays',
            city: 'Ville',
            postalCode: 'Code Postal',
        },
        error_message: 'Une erreur est survenue',
        error: {
            required: 'Merci de renseigner ce champ',
            'email.invalid': 'E-Mail invalide',
            'phone.invalid': 'Numéro de téléphone invalide',
        },

        advancedSearch: {
            show: 'Filtres Avancés',
            hide: 'Masquer les Filtres',
        },
        'auth.error.invalidCredentials':
            'Identifiant ou Mot de passe incorrect',
        login: {
            username: "Nom d'Utilisateur",
            password: 'Mot de Passe',
            rememberMe: 'Se souvenir de moi',

            'logIn.button': 'Connexion',
            'logOut.button': 'Déconnexion',
            'username.error.required': 'Veuillez reseigner votre identifiant',
            'password.error.required': 'Veuillez taper votre mot de passe',
            'password.forgotten': "J'ai oublié mon mot de passe",
        },
        password_forgotten: {
            title: 'Mot de passe oublié',
            resetPassword: 'Modifier mon mot de passe',
            confirm:
                'Un email vous a été envoyé pour modifier votre mot de passe.',
        },
        accessRequest: {
            title: 'Demandes de rattachement en attente',
            user: 'Utilisateur',
            category: 'Catégorie',
            place: 'Place',
            boat: 'Bateau',
            refusalComment: 'Raisons du refus',
            refusal: {
                title: 'Refuser la demande',
                success: 'Demande refusée',
            },
            approval: {
                title: 'Accepter la demande',
                existingBoat: 'Bateau existant',
                newBoat: 'Nouveau bateau',
                success: 'Demande acceptée',
            },
            categories: {
                yachtsman: 'Plaisancier',
                professional: 'Professionel',
                visitor: 'Visiteur',
            },
        },
        user: {
            firstName: 'Prénom',
            lastName: 'Nom',
            name: 'Nom',
            role: 'Role',
            roles: 'Roles',
            username: 'Identifiant',
            nationality: 'Nationalité',
            locale: 'Langue',
            email: 'eMail',
            boats: 'Bateaux',
            phoneNumber: 'N° Téléphone',
            'password.edit.title': 'Modifier le mot de passe',
            password: 'Mot de passe',
            passwordConfirm: 'Confirmez votre mot de passe',
            'password.error.required': 'Veuillez taper votre mot de passe',
            error: {
                emailAlreadyUsed: 'Cet email est déjà utilisé',
                usernameAlreadyUsed: 'Cet identifiant est déjà utilisé',
            },

            creation: {
                title: 'Ajouter un utilisateur',
                success: 'Utilisateur "__name__" créé',
            },
            update: {
                title: 'Modifier un utilisateur',
                success: 'Utilisateur "__name__" modifié',
            },
            delete: {
                success: 'Utilisateur supprimé',
                error:
                    "Impossible de supprimer l'utilisateur car il a déjà effectué des sorties / déclaré des absences",
            },
        },
        role: {
            administrator: 'Administrateur',
            yachtsman: 'Plaisancier',
            security_officer: 'Agent de sécurité',
            captain: 'Capitainerie',
            professional: 'Professionnel',
            surveyor: 'Superviseur',
            visitor: 'Visiteur',
            super_admin: 'Super Admin',
            'any.role.nauticspot': 'Autre',
        },
        yachtsman: {
            creation: {
                title: 'Ajouter un plaisancier',
                success: 'Plaisancier "__name__" créé',
            },
            update: {
                title: 'Modifier un Plaisancier',
                success: 'Plaisancier "__name__" modifié',
            },
        },

        weather: {
            forecast: {
                _: 'Bulletin météo',
                upload: 'Mettre à jour le bulletin',
                download: 'Voir le bulletin en ligne',
                updated: 'Dernière mise à jour : __date__',
                close_preview: 'Fermer',
            },
        },
        absence: {
            absence: 'Absence',
            absences: 'Absences',
            departureDate: 'Date de sortie',
            returnDate: 'Date de retour',
            anticipatedReturnDate: 'Date de retour anticipé',
            place: 'Place',
            boat: 'Bateau',
            owners: 'Plaisancier',
            legend: {
                pending: 'En Attente',
                approved: 'Disponible',
                present: 'Occupé',
                'anticipated-return': 'Retour Anticipé',
            },
            creation: {
                title: 'Ajouter une Absence',
                success: 'Absence créée',
            },
            anticipatedReturn: {
                creation: {
                    title: 'Ajouter un Retour Anticipé',
                    success: 'Retour anticipé créé',
                },
            },
        },
        multihull: 'Multi Coque',
        sailboat: 'Voilier',
        motorboat: 'Moteur',
        resident: 'Résident',
        stopover: 'Escale',
        commitment: {
            user: 'Nom',
            startDate: 'Début',
            endDate: 'Fin',
            unknown: 'Inconnu',
            inProgress: 'En cours',

            disengage: 'Se désengager',
            engage: "M'engager",
            showHistory: "Voir l'historique",
            userTypes: {
                agent: 'Agent',
                supervisor: 'Superviseur',
            },
            confirmModal: {
                title: 'Voulez-vous vraiment vous engager ?',
                content:
                    "Si vous vous engagez, vous ne pouvez pas revenir en arrière.\nSeul un autre utilisateur devra s'engager à son tour pour vous libérer.",
            },
            disconfirmModal: {
                title: 'Voulez-vous vraiment vous désengager ?',
                content: ' ',
            },
            historyModal: {
                title: 'Historique des engagements',
            },
        },
        boat: {
            _: 'Bateau',
            name: 'Nom',
            boatType: 'Type',
            place: 'Place',
            draught: "Tirant d'eau",
            length: 'Longueur',
            width: 'Largeur',
            size: 'Taille',
            immatriculation: 'Immatriculation',
            hasTank: 'Réservoir à eaux noires',
            owners: 'Propriétaires',
            position: 'Position',
            countAbsences: "Nombre d'absences",
            countOutings: 'Nombre de sorties',
            synoxDeviceId: 'N° Boitier Synox',
            creation: {
                title: 'Ajouter un Bateau',
                success: 'Bateau "__name__" créé',
            },
            update: {
                title: 'Modifier un Bateau',
                success: 'Bateau "__name__" modifié',
            },
            delete: {
                success: 'Bateau supprimé',
                error:
                    'Impossible de supprimer le bateau car le bateau a déjà effectué des sorties / déclaré des absences',
            },
            categories: 'Catégories de bateaux',
            category: {
                name: 'Nom',
                length: 'Longueur',
                width: 'Largeur',
                minLength: 'Longueur Min',
                maxLength: 'Longueur Max',
                minWidth: 'Largeur Min',
                maxWidth: 'Largeur Max',
                definition: 'De __min__m à __max__m',
                definition_min: 'Plus de __min__m',
                definition_max: "Jusqu'à __max__m",
                definition_undefined: 'Non-définie',
                other: {
                    description: 'Hors catégorie',
                },
                creation: {
                    title: 'Ajouter une categorie.',
                    success: 'Catégorie ajoutée.',
                },
                update: {
                    title: 'Modifier une catégorie.',
                    success: 'Catégorie modifiée.',
                },
            },
        },
        configuration: {
            informations: 'Informations',
            photo_updated: 'Photo mise à jour',
            upload_failed: 'Le téléchargement du fichier a échoué.',
            photo_update: 'Modifier le logo',
            map_updated: 'Plan mis à jour',
            map_update: "Modifier le plan d'eau",
            import_users: 'Importer des utilisateurs',
            import_users_success: 'Import terminé',
            security: 'Module sécurité',
            absence: 'Module absences',
            port: 'Port',
            miscellanous: 'Infos pratiques',
        },
        partners: 'Services et partenaires',
        'external-links': 'Liens externes',
        externalLink: {
            name: 'Libellé',
            color: 'Couleur',
            noBackground: 'Contours',
            url: 'URL',
            updateIcon: "Modifier l'icône",
            create: {
                title: 'Ajouter un lien',
                success: 'Liens mis à jour',
                error: "Impossible d'enregistrer",
            },
        },
        partner: {
            name: 'Nom',
            type: 'Catégorie',
            description: 'Description',
            email: 'Email',
            phone: 'Téléphone',
            websites: 'Sites internet',
            website: {
                label: 'Libellé',
                add: 'Ajouter un site internet',
            },
            types: {
                'harbour-office': 'Capitainerie',
                maintenance: 'Maintenance',
                restaurant: 'Restaurants',
                'nautic-activity': 'Activités nautiques',
                association: 'Associations',
                health: 'Santé',

                'activity-culture': 'Culture',
                'activity-entertainment': 'Divertissement',
                'activity-nature': 'Nature',
                'activity-other': 'Autre',

                'shop-food': 'Alimentation',
                'shop-tools': 'Bricolage - Outillage',
                'shop-nautic-tools': 'Outillage nautique',
            },
            hours: 'Horaires',
            miscellanous: 'Infos pratiques',
            thumbnail: 'Logo',
            pictures: 'Photos',
            picture: {
                add: {
                    title: 'Ajouter une photo',
                    error: 'Impossible de télécharger la photo',
                },
            },
            update: {
                title: 'Modifier un partenaire',
                success: 'Partenaire modifié',
            },
            creation: {
                title: 'Ajouter un partenaire',
                success: 'Partenaire créé',
            },
            delete: {
                error: 'Erreur lors de la suppression',
                success: 'Partenaire supprimé',
            },
        },
        markdown: {
            link: 'Pour créer un lien: [nom du lien](adresse URL)',
            h1: 'Pour créer un titre: # Intitulé du titre',
            italic: 'Pour mettre en italique: _texte_',
            preview: 'Prévisualisation',
            help: 'Aide',
        },
        places: 'Places',
        place: {
            _: 'Place',
            code: 'Code',
            boatTypes: 'Bateaux autorisés',
            placeType: 'Type de place',
            boat: 'Bateau',
            length: 'Longueur',
            width: 'Largeur',
            draught: "Tirant d'eau",
            pontoon: 'Ponton',

            deviceId: 'N° Capteur Ponton',
            ultrasonicMinThreshold: 'Seuil min',
            ultrasonicMaxThreshold: 'Seuil max',
            minInFrameThreshold: 'Nb trames retour',
            minOutFrameThreshold: 'Nb trames sortie',

            deviceType: 'Type',
            deviceTypes: {
                buoy: 'Bouée',
                pontoon: 'Ponton',
            },

            creation: {
                title: 'Ajouter une place',
                success: 'Place créée',
                draw: 'Dessinez la zone à définir',
            },
            update: {
                title: 'Modifier une place',
                success: 'Place modifiée',
            },
            deletion: {
                title: 'Voulez-vous vraiment supprimer la place "__name__"?',
                content:
                    'Cette action supprimera uniquement le dessin de la zone sur la carte et pas la place qui lui est liée.',
                success: 'Place supprimée',
                select: 'Sélectionnez la zone à supprimer',
            },
            state: {
                occupied: 'Place occupée',
                in: 'Place occupée',
                out: 'Place vide',
                absence: 'Place disponible',
                empty: 'Place vide',
                available: 'Place disponible',
                stopover: 'Escale',
                error: 'Erreur capteur',
            },
            error: {
                notFound: "La place demandée n'existe pas",
            },
        },
        pontoons: 'Pontons',
        pontoon: {
            name: 'Code',
            places: 'Places',
            creation: {
                title: 'Ajouter un ponton',
                success: 'Ponton créé',
            },
            update: {
                title: 'Modifier un ponton',
                success: 'Ponton modifié',
            },
        },
        'notification-user-lists': 'Listes de diffusion',
        'notification-user-list': {
            name: 'Nom',
            users: 'Utilisateurs',
            creation: {
                title: 'Ajouter une liste de diffusion',
                success: 'Liste créée',
            },
            update: {
                title: 'Modifier une liste de diffusion',
                success: 'Liste modifiée',
            },
        },
        'weather-api': {
            _: 'Station Météo',
            success: 'Configration mise à jour',
        },
        mapShape: {
            update: {
                success: 'Forme modifiée',
                error: 'Impossible de modifier la forme',
            },
            deletion: {
                title: 'Voulez-vous vraiment supprimer la forme?',
                content:
                    'Cette action supprimera uniquement la forme sélectionnée et pas le reste du plan.',
                success: 'Forme supprimée',
                select: 'Sélectionnez la forme à supprimer',
            },
        },
        mapPosition: {
            name: 'Nom',
            isDefault: 'Position par défaut',
            isPublic: 'Bassin visible pour tous les utilisateurs',
            save: {
                success: 'Position enregistrée',
            },
            delete: {
                success: 'Position supprimée',
            },

            label: 'Bassins',
            saveBtn: {
                tooltip: 'Définir comme position / zoom du bassin',
            },
            defaultBtn: {
                tooltip: 'Définir comme bassin par défaut du port',
            },

            createModal: {
                title: 'Ajouter un bassin',
                success: 'Bassin créé',
                error: 'Impossible de créer le bassin',
            },
            createBtn: {
                title: 'Ajouter un bassin',
            },
        },
        editPanel: {
            zone: 'Zone',
            place: 'Place',
            shape: 'Forme',

            calques: 'Calques',
            actions: 'Actions',
        },
        zone: {
            zone: 'Zone',
            name: 'Nom',
            creation: {
                title: 'Ajouter une Zone',
                success: 'Zone créée',
                choice: 'Souhaitez-vous créer une zone ou une place?',
            },
            update: {
                title: 'Modifier une zone',
                success: 'Zone modifiée.',
                message: 'Sélectionner la zone à modifier',
                bad_zone: 'La zone que vous avez sélectionnée est une place.',
                bad_zone_description:
                    ' Une place ne peut pas être modifiée de cette manière actuellement. Veuillez allez dans "configuration" pour modifier vos places.',
            },
            deletion: {
                title: 'Voulez-vous vraiment supprimer la zone "__name__"?',
            },
            error: {
                notFound: "La zone demandée n'existe pas",
            },
        },
        position: {
            in: 'Au Port',
            out: 'En Mer',
        },
        rate: {
            category: 'Catégorie',
            name: 'Code',
            boatTypes: 'Types de bateau',
            season: {
                high: 'Haute Saison',
                low: 'Basse Saison',
                off: 'Hors Saison',
                definition: 'Du __start__ au __end__',
                from: 'Du',
                to: 'au',
                update: {
                    success: 'Saison modifiée',
                },
            },
            creation: {
                title: 'Ajouter un tarif',
                success: 'Tarif créé',
            },
            update: {
                title: 'Modifier un tarif',
                success: 'Tarif modifié',
            },
        },
        harbour: {
            name: 'Nom',
            latitude: 'Latitude',
            longitude: 'Longitude',
            address: 'Adresse',
            phone: 'Téléphone',
            fax: 'Fax',
            email: 'Email',
            defaultLocale: 'Langue',
            country: 'Pays',
            absenceEmail: "Email utilisé pour notifier une demande d'absence",
            securityEmail: 'Email utilisé pour notifier un nouvel incident',
            commitmentEmail:
                "Email utilisé pour notifier un changement d'engagement",
            minutesBeforeEmail: 'Minutes avant notification',
            intervalMinutesBetweenMail: 'Minutes entre notification',
            select: 'Sélectionnez un port',
            change: 'Changer de port',
            update: {
                success: 'Port "__name__" modifié',
            },
            hourBeginDay: 'Heure de début jour',
            hourEndDay: 'Heure de fin jour',
        },
        cameras: 'Caméras',
        information: 'Informations',
        season: 'Saisons',
        camera: {
            name: 'Nom',
            url: 'URL',
            type: 'Type',
            creation: {
                title: 'Ajouter une caméra',
                success: 'Caméra créée',
            },
            update: {
                title: 'Modifier une caméra',
                success: 'Caméra modifiée',
            },
            delete: {
                success: 'Caméra supprimée',
            },
            types: {
                playlist: 'Fichier vidéo',
                iframe: 'Lecteur Web',
            },
        },
        reporting: {
            main: 'Général',
            repartition: 'Sorties par heure',
            outing: 'Sorties',
            outing_history: 'Sorties',
            calendar: 'Calendrier',
            outing_by_type: 'Sorties par type de bateau',
            fill_history: 'Historique de remplissage',
            top_10: 'Top plaisanciers',
            mean: 'Moyenne',
            potential: 'Potentiel',
            filters: {
                title: 'Filtres',
                labels: {
                    period_title: 'Période',
                    period: 'Période sur laquelle voir les rapports',
                    compare: 'Comparer avec :',
                    users: 'Plaisancier',
                    places_zones: 'Place / Zone',
                    places: 'Places',
                    zones: 'Zones',
                    types: 'Type de bateaux :',
                    category: 'Catégorie de bateau',
                },
                date: 'Please select a valid date',
                year: 'Please select a year',
            },
            top10: {
                number_outing: 'Nombre de sorties sur la période',
                by_day: 'Sorties Jour',
                by_night: 'Sorties Croisières',
                relative_night: '% de sorties de nuits',
                title_less: 'Utilisateurs les moins actifs',
                title_most: 'Utilisateurs les plus actifs',
            },
            occupancy: {
                title: 'Remplissage',
                evolution: '__EVOLUTION__% comparé à __DATE__',
                no_evolution: 'aucune évolution comparé à __DATE__',
                places: '__NB__ places',
                placesUsed: 'Places occupées',
                placesEmpty: 'Places vides',
            },
            data: {
                waiting: 'Waiting for data.',
                no_data: 'No data received.',
                waiting_explanation: 'No data was received as of yet.',
                no_data_explanation:
                    'No data seem to be available on this period for this chart.',
            },
            optimisation: {
                title: 'Optimisation',
                tableTitle: {
                    period: 'Périodes',
                    nights: 'Sorties',
                    profit: 'Gains',
                    absences: 'Absences',
                    losts: 'Pertes',
                },
                period: 'Du __start__ au __end__',
                profit: {
                    potential: 'Potentiel',
                    realised: 'Réalisé',
                    lost: 'Pertes',
                },
                outings: 'Sorties en mer',
                optimized: 'Optimisées en escale',
                declared: 'Déclarées',
                outside: "Hors port d'attache",
            },
        },
        outing: {
            timeIn: "Heure d'arrivée",
            timeOut: 'Heure de sortie',
            listByDay: 'Liste des sorties le __date__',
        },
        urgencePhoneNumbers: "Numéros d'urgence",
        urgencePhoneNumber: {
            name: 'Nom',
            phoneNumber: 'Tel.',
            vhf: 'VHF',
            creation: {
                title: "Ajouter un numéro d'urgence",
                success: 'Numéro créé',
                btn: "Ajouter un numéro d'urgence",
            },
            update: {
                title: "Modifier un numéro d'urgence",
                success: 'Numéro modifié',
            },
            delete: {
                success: 'Numéro supprimé',
            },
        },
        users: 'Utilisateurs',
        categories: 'Catégories',

        incidents: 'Incidents',
        incident: {
            name: 'Incident',
            user: 'Plaisancier',
            types: {
                fire: 'Incendie',
                sink: 'Avarie',
                theft: 'Effraction',
                electric: 'Problème électrique',
                water: "Fuite d'eau",
                sound: 'Nuisances sonores',
                polution: 'Pollution',
                lighting: 'Eclairage',
                sanitary: 'Sanitaires',
                broken_board: 'Planche cassée',
                collision: 'Collision',
                harbour_map: "Plan d'eau",
                other: 'Autre',
                extinguisher: 'Extincteur',
                chain: 'Chaîne / Pendille',
                ladder: 'Echelle',
                towing: 'Remorquage',
                mooring: 'Amarrage',
            },
            visibility: {
                _: 'Visibilité',
                none: 'Personne',
                captain: 'Capitainerie',
                all: 'Tout le monde',
            },
            type: {
                _: "Type d'incident",
                fire: 'Incendie',
                sink: 'Avarie',
                theft: 'Effraction',
                electric: 'Problème électrique',
                water: "Fuite d'eau",
                sound: 'Nuisances sonores',
                polution: 'Pollution',
                broken_board: 'Planche cassée',
                collision: 'Collision',
                harbour_map: "Plan d'eau",
                other: 'Autre',
                extinguisher: 'Extincteur',
                chain: 'Chaîne / Pendille',
                ladder: 'Echelle',
                towing: 'Remorquage',
                mooring: 'Amarrage',
            },
            comment: 'Motif',
            comments: 'Commentaire',
            harbourComment: 'Commentaires du port',
            saveHarbourComment: 'Enregistrer le commentaire',
            status: 'Etat',
            zone: 'Zone',
            createdAt: 'Date',
            confirmedByOne: 'Confirmé par un plaisancier',
            confirmedBy: 'Confirmé par __COUNT__ plaisanciers',
            acknowlegedAt: 'Pris en compte le __DATE__',
            acknowlegedAt_by: 'Pris en compte le __DATE__ par __USER__',
            closedAt: 'Clôturé le __DATE__',
            closedAt_by: 'Clôturé le __DATE__ par __USER__',
            attachments: 'Pièces jointes',
            no_attachment: 'Aucune pièce jointe',
            createdBy: 'Créé par',
            security: 'Sûreté',
            safety: 'Sécurité',
            maintenance: 'Maintenance',
            email: 'Emails de notification',
            notificationEmail: 'Email utilisé pour notifier un nouvel incident',
            isCritical: 'Critique',
            criticalPhoneNumber: {
                day: "Téléphone d'urgence (jour)",
                night: "Téléphone d'urgence (nuit)",
            },
            editZone: 'Définir la zone',
            statuses: {
                pending: 'En attente',
                acknowledged: 'Pris en compte',
                closed: 'Clos',
            },
            action: {
                acknowledge: "Prendre en compte l'incident",
                close: "Clôturer l'incident",
            },
            pendingIncidents: 'Incidents déclarés',

            creation: {
                title: 'Déclarer un incident',
                success: 'Incident déclaré',
            },
            update: {
                success: 'Incident modifié',
            },
        },
    },

    // Ant Design
    Table: {
        filterTitle: 'Rechercher...',
        filterConfirm: 'OK',
        filterReset: 'Réinitialiser',
        emptyText: 'Aucune donnée',
    },
    Modal: {
        okText: 'OK',
        cancelText: 'Annuler',
        justOkText: 'OK',
    },
    Popconfirm: {
        okText: 'OK',
        cancelText: 'Annuler',
    },
    Transfer: {
        notFoundContent: 'Pas de résultat',
        searchPlaceholder: 'Recherche',
        itemUnit: 'élément',
        itemsUnit: 'éléments',
    },
    Select: {
        notFoundContent: 'Pas de résultat',
    },
    Pagination: {
        // Options.jsx
        items_per_page: '/ page',
        jump_to: 'Allez à',
        page: '',

        // Pagination.jsx
        prev_page: 'Page Précédente',
        next_page: 'Page Suivante',
        prev_5: '5 Pages Précédentes',
        next_5: '5 Pages Suivantes',
        prev_3: '3 Pages Précédentes',
        next_3: '3 Pages Suivantes',
    },
    TimePicker: {
        placeholder: '',
    },
    DatePicker: {
        lang: {
            today: "Aujourd'hui",
            now: 'Maintenant',
            backToToday: "Retour à aujourdh'hui",
            ok: 'Ok',
            clear: 'Vider',
            month: 'Mois',
            year: 'Année',
            timeSelect: "Sélectionner l'heure",
            dateSelect: 'Sélectionner une date',
            monthSelect: 'Sélectionner un mois',
            yearSelect: 'Sélectionner une année',
            decadeSelect: 'Sélectionner une décénie',
            yearFormat: 'YYYY',
            dateFormat: 'M/D/YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'M/D/YYYY HH:mm:ss',
            monthFormat: 'MMMM',
            monthBeforeYear: true,
            previousMonth: 'Mois précédent (PageUp)',
            nextMonth: 'Mois suivant (PageDown)',
            previousYear: 'Année précédente (Control + left)',
            nextYear: 'Année suivante (Control + right)',
            previousDecade: 'Décennie précédente',
            nextDecade: 'Décennie suivante',
            previousCentury: 'Siècle précédent',
            nextCentury: 'Siècle suivant',

            placeholder: 'Sélectionnez une date',
            rangePlaceholder: ['Date de début', 'Date de fin'],
        },
    },
};

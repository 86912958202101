import BaseStore from 'stores/BaseStore';
import HarbourStore from 'stores/HarbourStore';

class NotificationUserListStore extends BaseStore {
    _entities: { [harbour_id: number]: INotificationUserList[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string; payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
            case 'NotificationUserList:receiveNotificationUserListsOfHarbour':
                this._receiveByHarbour(payload.harbour, payload.lists);
                break;
            case 'NotificationUserList:receiveNotificationUserList':
                this._receiveOneByHarbour(payload.harbour, payload.list);
                break;
            case 'NotificationUserList:deleteNotificationUserList':
                this._deleteOneByHarbour(payload.harbour, payload.id);
                break;

            default:
                // Do Nothing
                break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, lists: INotificationUserList[]) => {
        this._entities[harbour.id] = lists;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, list: INotificationUserList) => {
        this._entities[harbour.id] = [...this._entities[harbour.id]];
        const entities = this._entities[harbour.id];
        if (!entities) {
            this._entities[harbour.id] = [list];
        } else {
            const i = entities.findIndex((c) => c.id === list.id);
            if (i > -1) {
                entities[i] = list;
            } else {
                entities.push(list);
            }
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, id: number) => {
        this._entities[harbour.id] = [...this._entities[harbour.id]];
        const entities = this._entities[harbour.id];
        if (!entities) {
            return;
        }

        const i = entities.findIndex((c) => c.id === id);
        if (i > -1) {
            entities.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    getForCurrentHarbour = () =>
        this.getByHarbour(HarbourStore.getCurrentHarbour());
    /**
     * Get all the lists of a harbour.
     */
    getByHarbour = (
        harbour: IHarbour | null | undefined,
    ): INotificationUserList[] => (harbour && this._entities[harbour.id]) || [];

    getById = (id: number): INotificationUserList | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const entities = this._entities[harbourId] || [];
            const entity = entities.find(
                (c) => c.id.toString() === id.toString(),
            );
            if (entity) {
                return entity;
            }
        }
        return null;
    };
}

export default new NotificationUserListStore();

import React from 'react';
import HarbourStore from 'stores/HarbourStore';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import LoginStore from 'stores/LoginStore';
import Locale from 'locale/LocaleFactory';

export default function NotificationThumbnail({ notification }) {
    const currentHarbour = HarbourStore.getCurrentHarbour();
    const token = LoginStore.getJwt();
    const formattedToken = token ? token.replace(/\+/g, '%2B') : '';
    const url = `${BaseUrlConstants.BASE_URL}harbours/${currentHarbour.id}/notifications/${notification.id}/thumbnail`;

    return (
        <div className="__thumbnail">
            <img
                alt={Locale.trans('communication.notification.thumbnail')}
                src={`${url}?X-Auth-Token=${formattedToken}&t=${Date.now()}`}
            />
        </div>
    );
}

import React from 'react';
import { Form, Button, Row, Col } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';


class BoatCategoryForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const user = this.getCategoryFromValues(values);
                this.props.onSubmit(user);
            }
        });
    };

    getCategoryFromValues = (values) => {
        const category = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.map((k) => {
            if (k.endsWith('_ids') || k === 'passwordConfirm') {
                return null;
            }
            if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                category[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                category[k] = values[k];
            }
            return null;
        });
        return category;
    };

    render() {
        const { form, loading } = this.props;

        return (
            <Form onSubmit={this.handleSubmit}>
                <FormItem.Input
                    id="name"
                    required
                    label={Locale.trans('boat.category.name')}
                    form={form}
                    validateStatus={this.getValidateStatus('name')}
                    help={this.getHelp('name')}
                    hasFeedback
                />
                
                <Row gutter={16}>
                    <Col span={12}>
                        <FormItem.Input
                            id="lengthMin"
                            label={Locale.trans('boat.category.minLength')}
                            form={this.props.form}
                        />
                    </Col>
                    <Col span={12}>
                        <FormItem.Input
                            id="lengthMax"
                            label={Locale.trans('boat.category.maxLength')}
                            form={this.props.form}
                        />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <FormItem.Input
                            id="widthMin"
                            label={Locale.trans('boat.category.minWidth')}
                            form={this.props.form}
                        />
                    </Col>
                    <Col span={12}>
                        <FormItem.Input
                            id="widthMax"
                            label={Locale.trans('boat.category.maxWidth')}
                            form={this.props.form}
                        />
                    </Col>
                </Row>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }

}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(BoatCategoryForm);

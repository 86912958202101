import React from 'react';

import { Table, Button } from 'antd/lib/index';
import CreateCategoryModal from 'pages/Configuration/CreateCategoryModal.jsx';
import EditCategoryModal from 'pages/Configuration/EditCategoryModal.jsx';
import LoadingIcon from 'components/LoadingIcon.jsx';

import Locale from 'locale/LocaleFactory';

import BoatCategoryActions from 'actions/BoatCategoryActions';
import BoatCategoryStore from 'stores/BoatCategoryStore';
import HarbourStore from 'stores/HarbourStore';

/**
 * The list of the users of a harbour that are not yatchmen. (e.g. Captain, Security officer, etc.)
 */
export default class UsersConfiguration extends React.Component {
    constructor() {
        super();

        const categories = BoatCategoryStore.getForCurrentHarbour();

        this.state = {
            loading: !categories.length,
            categories,

            createCategoryVisible: false,
            editCategoryVisible: false,
            categoryToEdit: null,
        };
    }

    componentDidMount() {
        HarbourStore.addCurrentHarbourChangeListener(this.onChangeCurrentHarbour);
        BoatCategoryStore.addChangeListener(this.receiveUsers);

        this.loadCategories();
    }
    componentWillUnmount() {
        HarbourStore.removeCurrentHarbourChangeListener(this.onChangeCurrentHarbour);
        BoatCategoryStore.removeChangeListener(this.receiveUsers);
    }

    onChangeCurrentHarbour = () => {
        this.loadCategories();
        this.receiveUsers();
    };

    loadCategories = () => {
        this.setState({
            loading: true,
        });
        BoatCategoryActions.reloadForCurrentHarbour().then(() => {
            this.setState({
                loading: false,
            });
        });
    };

    receiveUsers = () => {
        this.setState({
            categories: BoatCategoryStore.getForCurrentHarbour(),
        });
    };

    showCreateCategoryModal = () => {
        this.setState({
            createCategoryVisible: true,
        });
    };
    hideCreateCategoryModal = () => {
        this.setState({
            createCategoryVisible: false,
        });
    };

    editCategory = (category) => {
        this.setState({
            editCategoryVisible: true,
            categoryToEdit: category,
        });
    };
    hideEditCategoryModal = () => {
        this.setState({
            editCategoryVisible: false,
            categoryToEdit: null,
        });
    };

    render() {
        const { loading, categories } = this.state;
        return (
            <div className="category-list">
                {this.renderCategoryTable()}
                {!loading && (
                    <div
                        className="actions-row"
                        style={{ marginTop: categories.length > 0 ? '-50px' : 0 }}
                    >
                        <Button type="primary" icon="plus" onClick={this.showCreateCategoryModal}>
                            {Locale.trans('boat.category.creation.title')}
                        </Button>
                    </div>
                )}

                <CreateCategoryModal
                    onCancel={this.hideCreateCategoryModal}
                    visible={this.state.createCategoryVisible}
                />
                <EditCategoryModal
                    category={this.state.categoryToEdit}
                    onCancel={this.hideEditCategoryModal}
                    visible={this.state.editCategoryVisible}
                />
            </div>
        );
    }

    renderCategoryTable() {
        const { categories } = this.state;
        const columns = [
            {
                title: Locale.trans('boat.category.name'),
                dataIndex: 'name',
                key: 'name',
                width: '100px',
            },
            {
                title: Locale.trans('boat.category.length'),
                key: 'length',
                render: (test, category) => Locale.trans('boat.category.definition', {
                    min: category.lengthMin,
                    max: category.lengthMax,
                }),
                width: '250px',
            },
            {
                title: Locale.trans('boat.category.width'),
                key: 'width',
                render: (test, category) => Locale.trans('boat.category.definition', {
                    min: category.widthMin,
                    max: category.widthMax,
                }),
            },
        ];
        return (
            <Table
                dataSource={categories}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
                loading={this.state.loading && { indicator: <LoadingIcon /> }}
                onRow={a => ({
                    className: 'clickable',
                    onClick: () =>
                    this.editCategory(a),
                })}
            />
        );
    }
}

import React from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';
import DeleteButton from 'components/forms/DeleteButton.jsx';

class CameraForm extends FormBase {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.isFormSubmited(true);
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err && this.props.onSubmit) {
        const camera = this.getEntityFromValues(values);
        this.props.onSubmit(camera);
      }
    });
  };

  getEntityFromValues = values => {
    const boat = {};
    const keys = Object.keys(values);
    const ignoredKeys = [];
    keys.map(k => {
      if (k.endsWith('_ids')) {
        return null;
      }
      if (k.endsWith('_id')) {
        const tK = k.replace('_id', '');
        boat[tK] = values[k];
        ignoredKeys.push(tK);
      } else if (ignoredKeys.indexOf(k) === -1) {
        boat[k] = values[k];
      }
      return null;
    });
    return boat;
  };

  render() {
    const { onDelete } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem.Input
          id="name"
          required
          label={Locale.trans('camera.name')}
          form={this.props.form}
          validateStatus={this.getValidateStatus('name')}
          help={this.getHelp('name')}
          hasFeedback
        />
        <FormItem.Input
          id="url"
          required
          label={Locale.trans('camera.url')}
          form={this.props.form}
          validateStatus={this.getValidateStatus('url')}
          help={this.getHelp('url')}
          hasFeedback
        />
        <FormItem.Select
          id="type"
          required
          label={Locale.trans('camera.type')}
          form={this.props.form}
          validateStatus={this.getValidateStatus('type')}
          help={this.getHelp('type')}
          options={['playlist', 'iframe'].map(t => ({
            value: t,
            label: Locale.trans(`camera.types.${t}`),
          }))}
          hasFeedback
        />
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={this.props.loading}
        >
          {Locale.trans('save')}
        </Button>
        {onDelete && (
          <DeleteButton
            className="login-form-button"
            onDelete={onDelete}
            style={{ marginTop: 8 }}
          />
        )}
      </Form>
    );
  }
}

export default Form.create({
  onFieldsChange(props, changedFields) {
    if (props.onChange) {
      props.onChange(changedFields);
    }
  },
  mapPropsToFields(props) {
    const { fields } = props;
    if (!fields) {
      return {};
    }
    const kFields = Object.keys(fields);
    const map = {};
    for (let i = 0; i < kFields.length; i++) {
      const k = kFields[i];
      map[k] = Form.createFormField({
        ...fields[k],
        value: fields[k].value,
      });
    }
    return map;
  },
})(CameraForm);

import React from 'react';
import { Modal } from 'antd/lib/index';

import BoatCategoryForm from 'components/forms/BoatCategoryForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import BoatCategoryActions from 'actions/BoatCategoryActions';
import FormService from 'services/utils/FormService';

/**
 * The modal to edit a user.
 */
export default class EditCategoryModal extends React.Component {
    totalActions = 0;
    completedActions = 0;

    constructor(props) {
        super();
        this.state = {
            loading: false,
            fields: this.getFieldsFromEntity(props.category),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            this.setState({
                fields: this.getFieldsFromEntity(nextProps.category),
            });
        }
    }

    getFieldsFromEntity = (entity) => {
        if (!entity) {
            return {};
        }
        const fields = {};
        const keys = Object.keys(entity);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            fields[k] = { value: entity[k] };
        }
        return fields;
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (category) => {
        if (!this.props.category) {
            return null;
        }
        const categoryId = this.props.category.id;

        this.setState({
            loading: true,
        });
        BoatCategoryActions.edit(categoryId, category)
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('boat.category.update.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        const { category, visible, onCancel } = this.props;
        const { fields, loading } = this.state;
        if (!category) {
            return null;
        }
        return (
            <Modal
                title={Locale.trans('boat.category.update.title')}
                visible={category && visible}
                onCancel={onCancel}
                footer={null}
            >
                <BoatCategoryForm
                    catgory={category}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                />
            </Modal>
        );
    }
}

import React, { useState } from 'react';
import { Modal } from 'antd';

import IncidentZoneForm from 'components/forms/IncidentZoneForm.jsx';

import Locale from '../locale/LocaleFactory';

import IncidentActions from '../actions/IncidentActions';
import ToastActions from '../actions/ToastActions';

import HarbourStore from '../stores/HarbourStore';

import useZonesOfHarbour from '../hooks/useZonesOfHarbour';
import usePlacesOfHarbour from '../hooks/usePlacesOfHarbour';
import FormService from 'services/utils/FormService';

function ModalTitle({ incident }) {
    return (
        <div>
            <div className="incident-icon">
                <img
                    src={`images/icon_${incident.incidentType}.svg`}
                    alt={incident.incidentType}
                />
            </div>
            {Locale.trans(`incident.types.${incident.incidentType}`)}
            {incident.comment && incident.comment.length && (
                <span
                    style={{
                        display: 'inline-block',
                        marginLeft: 8,
                        fontStyle: 'italic',
                    }}
                >
                    ({incident.comment})
                </span>
            )}
        </div>
    );
}

function EditIncidentZoneModal({ visible, onCancel, incident }) {
    const currentHarbour = HarbourStore.getCurrentHarbour();

    const zones = useZonesOfHarbour(currentHarbour);
    const places = usePlacesOfHarbour(currentHarbour);
    const [fields, setFields] = useState({});
    const [loading, setLoading] = useState(false);

    function handleFormChange(changedFields) {
        setFields({ ...fields, ...changedFields });
    }

    function handleSubmit(newIncident) {
        setLoading(true);
        IncidentActions.edit(currentHarbour, incident.id, newIncident)
            .then(() => {
                setFields({});
                ToastActions.createToastSuccess(
                    Locale.trans('incident.update.success'),
                );
                onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                setLoading(false);
            });
    }

    if (!incident) {
        return null;
    }
    return (
        <Modal
            title={<ModalTitle incident={incident} />}
            visible={visible}
            onCancel={onCancel}
            footer={null}
            className="incident-modal"
        >
            <IncidentZoneForm
                places={places}
                zones={zones}
                onChange={handleFormChange}
                fields={fields}
                onSubmit={handleSubmit}
                loading={loading}
            />
        </Modal>
    );
}

export default EditIncidentZoneModal;

import React, { useState, useEffect } from 'react';
import RateSeasonConfigurationActions from 'actions/RateSeasonConfigurationActions';
import RateSeasonConfigurationStore from 'stores/RateSeasonConfigurationStore';
import useCurrentHarbour from 'hooks/useCurrentHarbour';
import RateSeasonForm from './RateSeasonForm';
import FormService from 'services/utils/FormService';
import Locale from 'locale/LocaleFactory';
import ToastActions from 'actions/ToastActions';

function getFieldsFromEntity(entity) {
    if (!entity) {
        return {};
    }
    const fields = {};
    const keys = Object.keys(entity);
    for (let i = 0; i < keys.length; i++) {
        const k = keys[i];
        fields[k] = { value: entity[k] };
    }
    return fields;
}

export default function RateSeasonConfiguration() {
    const harbour = useCurrentHarbour();
    const rateSeason = useRateSeason(harbour);
    const [fields, setFields] = useState(getFieldsFromEntity(rateSeason));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFields(getFieldsFromEntity(rateSeason));
    }, [rateSeason]);

    function handleSubmit(values) {
        setLoading(true);
        RateSeasonConfigurationActions.edit(harbour, values)
            .then(() => {
                ToastActions.createToastSuccess(
                    Locale.trans('rate.season.update.success'),
                );
            })
            .catch(FormService.handleError)
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div>
            <RateSeasonForm
                onChange={(changedFields) =>
                    setFields((prev) => ({ ...prev, ...changedFields }))
                }
                fields={fields}
                onSubmit={handleSubmit}
                loading={loading}
            />
        </div>
    );
}

function useRateSeason(harbour) {
    const [data, setData] = useState(null);

    useEffect(() => {
        function receiveData() {
            setData(RateSeasonConfigurationStore.getByHarbour(harbour));
        }
        RateSeasonConfigurationActions.reloadByHarbour(harbour);
        RateSeasonConfigurationStore.addChangeListener(receiveData);
        return () => {
            RateSeasonConfigurationStore.removeChangeListener(receiveData);
        };
    }, [harbour]);

    return data;
}

import React from 'react';
import { Row } from 'antd';

import LineChart from 'components/charts/LineChart';

import ReportingConstants from 'constants/ReportingConstants';
import OptimisationStore from 'stores/OptimisationStore';
import SidebarCollapseStore from 'stores/SidebarCollapseStore';
import ReportingFiltersStore from 'stores/ReportingFiltersStore';
import { comparedLabel } from 'services/ReportingLabelService';

import Locale from 'locale/LocaleFactory';

const margin = {
    top: 15,
    right: 20,
    bottom: 53,
    left: 30,
};

function getMax(data, getVal) {
    if (data.main.length === 0 && data.compared.length === 0) {
        return 0;
    }
    const getMaximum = (maximum, value) => Math.max(maximum, getVal(value));
    if (data.main.length === 0) {
        return data.compared.reduce(getMaximum, 0);
    }
    if (data.compared.length === 0) {
        return data.main.reduce(getMaximum, 0);
    }
    const max1 = data.main.reduce(getMaximum, 0);
    const max2 = data.compared.reduce(getMaximum, 0);
    return Math.max(max1, max2);
}
function getMaxProfitPotential(data) {
    return getMax(data, value => value.profit.potential);
}
function getMaxProfitRealized(data) {
    return getMax(data, value => value.profit.realized);
}
export default class OptimisationChart extends LineChart {
    // TODO allow change
    // TODO allow resize
    currentDisplay = ReportingConstants.DISPLAYS.DAYS;
    componentDidMount() {
        super.componentDidMount();
        ReportingFiltersStore.addChangeListener(this.changeFilters);
        OptimisationStore.addChangeListener(this.receiveData);
        SidebarCollapseStore.addChangeListener(() => {
            setTimeout(this.redraw, 250);
        });
        this.filters = ReportingFiltersStore.get();
    }

    getMargin() {
        return margin;
    }
    getXValue(d) {
        return new Date(d.date);
    }

    getYValue(d) {
        return d.profit.realized;
    }

    getYDomain(data) {
        return [0, Math.max(getMaxProfitPotential(data), getMaxProfitRealized(data))];
    }

    getXMainDomain(data) {
        return [ data.period.main.start, data.period.main.end ];
    }

    getXComparedDomain(data) {
        return [ data.period.compared.start, data.period.compared.end ];
    }

    changeFilters() {
        this.filters = ReportingFiltersStore.get();
    }
    
    createLines(data, xMain, xCompared, y) {
        this.createLine(
            'main',
            data.main,
            (val) => xMain(new Date(val.date)),
            (val) => y(val.profit.realized),
        );
        this.createLine(
            'main dotted',
            data.main,
            (val) => xMain(new Date(val.date)),
            (val) => y(val.profit.potential),
        );
        this.createLine(
            'compared',
            data.compared,
            (val) => xCompared(new Date(val.date)),
            (val) => y(val.profit.realized),
        );
        this.createLine(
            'compared dotted',
            data.compared,
            (val) => xCompared(new Date(val.date)),
            (val) => y(val.profit.potential),
        );
    }
    
    getData() {
        const data = OptimisationStore.getHistory();
        return {
            main: data.main.nbAbsences,
            compared: data.compared.nbAbsences,
            period: {
                main: data.main.period,
                compared: data.compared.period,
            },
        };
    }

    getTooltipTitle(point) {
        const date = this.getXValue(point);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('fr-FR', options);
    }

    getTooltipContent(point, pointCompared) {
        const displayCompared = () => {
            if (isNaN(this.getYValueCompared(pointCompared))) {
                return '';
            }
            return `
                <span>
                    <br />
                    <span class="reporting-compared-text">${comparedLabel(this.filters)} : </span>
                    ${this.formatTooltipText(this.getYValueCompared(pointCompared))}
                </span>
            `;
        };
        return `
            <p>
                <span class="reporting-main-text">${Locale.trans('Période')}: </span>
                ${this.formatTooltipText(this.getYValueMain(point))}
                ${displayCompared()}
            </p>`;
    }
    
    formatTooltipText(d) {
        return d;
    }

    render = () => (
        <Row className="optimisation chart">
            {super.render()}
        </Row>
    );
}
import React, { useState, useRef } from 'react';

import List from 'react-virtualized/dist/commonjs/List';
import { Icon } from 'antd';
import useOutClickEvent from 'hooks/useOutClickEvent';
import Locale from "locale/LocaleFactory";

/**
 * This select is used to virtualize the option dropdown when there are
 * a lot of options.
 * This component is not as complete as the Select of AntD but more
 * optimized in this use case.
 *
 * Currently used in the BoatForm component.
 */
function SelectMultipleField({ onChange, options, value, style, placeholder }) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchString, setSearchString] = useState("");
  const searchInputRef = useRef(null);
  const containerRef = useOutClickEvent(() => {
    setDropdownVisible(false);
  });

  function deleteValue(v) {
    let newValue = value.map(v => v.toString());
    const i = newValue.indexOf(v.toString());
    if (i !== -1) {
      newValue.splice(i, 1);
    }
    onChange(newValue);
  }

  function toggleValue(v) {
    let newValue = value.map(v => v.toString());
    const i = newValue.indexOf(v.toString());
    if (i !== -1) {
      newValue.splice(i, 1);
    } else {
      newValue.push(v.toString());
    }
    onChange(newValue);
  }

  return (
    <div
      className="select-multiple-field"
      style={style}
      onClick={() => {
        setDropdownVisible(true);
        searchInputRef.current.focus();
      }}
      ref={containerRef}
    >
      {value
        .map(v => options.find(o => o.value.toString() === v.toString()))
        .map(o => (
          <Choice key={o.value} option={o} onDelete={deleteValue} />
        ))}
      <input
        ref={searchInputRef}
        className="select-multiple-field__search"
        value={searchString}
        onChange={e => setSearchString(e.target.value)}
        style={{width: Math.max(10, searchString.length * 10) }}
      />

      <Dropdown
        options={options}
        value={value}
        searchString={searchString}
        visible={dropdownVisible}
        toggleValue={toggleValue}
      />
    </div>
  );
}

function Choice({ option, onDelete }) {
  if (!option) {
    return null;
  }
  return (
    <div className="select-multiple-field__choice">
      {option.label}
      <Icon
        type="close"
        onClick={e => {
          e.stopPropagation();
          onDelete(option.value);
        }}
      />
    </div>
  );
}

function Dropdown({
  options,
  value,
  searchString,
  visible,
  toggleValue,
}) {
  if (!visible) {
    return null;
  }

  const filteredOptions =
    searchString && searchString.length
      ? options.filter(({ label }) =>
          label.toLowerCase().includes(searchString.toLowerCase())
        )
      : options;
  return (
    <div className="select-multiple-field__dropdown">
        {filteredOptions.length > 0 ?
      <List
        height={Math.min(300, (30*filteredOptions.length))}
        overscanRowCount={4}
        rowCount={filteredOptions.length}
        rowHeight={30}
        rowRenderer={p => (
          <DropdownRow
            {...p}
            option={filteredOptions[p.index]}
            selectedValues={value}
            onClick={() => {
              toggleValue(filteredOptions[p.index].value);
            }}
          />
        )}
        width={300}
      />
      : <div className="select-multiple-field__dropdown__empty-text">{Locale.trans('emptyText')}</div>}
    </div>
  );
}

function DropdownRow({ option, style, selectedValues, onClick }) {
  const { value, label } = option;
  const isSelected = selectedValues.indexOf(value.toString()) !== -1;
  return (
    <div
      className={`select-multiple-field__dropdown__row ${
        isSelected ? 'selected' : ''
      }`}
      style={style}
      onClick={onClick}
    >
      {label}
    </div>
  );
}

export default SelectMultipleField;

import BaseStore from '../stores/BaseStore';
import RateConstants from '../constants/RateConstants';

class RateStore extends BaseStore {
    _entities: { [harbour_id: number]: IRate[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case RateConstants.RECEIVE_RATES_OF_HARBOUR:
            this._receiveByHarbour(payload.harbour, payload.rates);
            break;
        case RateConstants.RECEIVE_RATE_OF_HARBOUR:
            this._receiveOneByHarbour(payload.harbour, payload.rate);
            break;
        case RateConstants.DELETE_RATE_OF_HARBOUR:
            this._deleteOneByHarbour(payload.harbour, payload.id);
            break;

        default:
            // Do Nothing
            break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, rates: IRate[]) => {
        this._entities[harbour.id] = rates;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, rate: IRate) => {
        const rates = this._entities[harbour.id];
        if (!rates) {
            this._entities[harbour.id] = [rate];
        } else {
            const i = rates.findIndex(c => c.id === rate.id);
            if (i > -1) {
                rates[i] = rate;
            } else {
                rates.push(rate);
            }
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, id: number) => {
        const rates = this._entities[harbour.id];
        if (!rates) {
            return;
        }

        const i = rates.findIndex(c => c.id === id);
        if (i > -1) {
            rates.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    /**
     * Get all the rate rates of a harbour.
     */
    getByHarbour = (harbour: IHarbour): IRate[] => this._entities[harbour.id] || [];

    getById = (id: number): IRate | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const rates = this._entities[harbourId] || [];
            const rate = rates.find(c => c.id.toString() === id.toString());
            if (rate) {
                return rate;
            }
        }
        return null;
    };
}

export default new RateStore();

import BaseStore from 'stores/BaseStore';
import ReportingConstants from 'constants/ReportingConstants';

import moment from 'moment';

class ReportingFiltersStore extends BaseStore {
    _currentFilters: any;

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        const start = moment().subtract(1, 'month');
        this._currentFilters = {
            period: {
                start: start.clone(),
                end: moment(),
            },
            yearCompared: start.subtract(1, 'year').year(),
            user: null,
            zone: null,
            place: null,
            boatType: null,
            categorie: null,
        };
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case ReportingConstants.FILTERS_UPDATED:
            this._currentFilters = payload.filters;
            this.emitChange();
            break;
        case ReportingConstants.FILTERS_BOAT_TYPES_UPDATED:
            if (this._currentFilters.boatType === payload.boatType) {
                this._currentFilters.boatType = null;
            } else {
                this._currentFilters.boatType = payload.boatType;
            }
            this.emitChange();
            break;
        case ReportingConstants.FILTERS_BOAT_CATEGORIES_UPDATED:
            if (this._currentFilters.categorie === payload.boatCategory) {
                this._currentFilters.categorie = null;
            } else {
                this._currentFilters.categorie = payload.boatCategory;
            }
            this.emitChange();
            break;
        default:
            // Do Nothing
            break;
        }
    };


    get = () => this._currentFilters;
}

export default new ReportingFiltersStore();

import React from 'react';
import { Button } from 'antd';

import Slider from 'components/Slider.jsx';

const MIN_ZOOM = 1;
const MAX_ZOOM = 10;

export default class SvgMap extends React.Component {
    constructor(props) {
        super(props);

        const position = props.initialPosition || { x: 0, y: 0 };
        const zoom = props.initialZoom || 1;

        this.state = {
            matrix: [zoom, 0, 0, zoom, position.x, position.y],
            dragging: false,
            initialPosition: position,
            initialZoom: zoom,
        };
    }

    areNearby = (p1, p2) => {
        const { matrix } = this.state;
        const zoom = matrix[0];
        const threshold = 10 / zoom;
        const deltaX = p1.x - p2.x;
        const deltaY = p1.y - p2.y;
        return (
            deltaX > -threshold &&
            deltaX < threshold &&
            deltaY > -threshold &&
            deltaY < threshold
        );
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = { ...prevState };
        if (
            nextProps.initialPosition.x !== prevState.initialPosition.x ||
            nextProps.initialPosition.y !== prevState.initialPosition.y
        ) {
            const { matrix } = newState;
            matrix[0] = nextProps.initialZoom;
            matrix[3] = nextProps.initialZoom;
            matrix[4] = nextProps.initialPosition.x;
            matrix[5] = nextProps.initialPosition.y;

            newState.matrix = matrix;
            newState.initialPosition = nextProps.initialPosition;
        }
        if (nextProps.initialZoom !== prevState.initialZoom) {
            const { matrix } = newState;
            matrix[0] = nextProps.initialZoom;
            matrix[3] = nextProps.initialZoom;

            newState.matrix = matrix;
            newState.initialZoom = nextProps.initialZoom;
        }
        return newState;
    }

    onDragStart = (e) => {
        // Find start position of drag based on touch/mouse coordinates.
        const startX =
            typeof e.clientX === 'undefined'
                ? e.changedTouches[0].clientX
                : e.clientX;
        const startY =
            typeof e.clientY === 'undefined'
                ? e.changedTouches[0].clientY
                : e.clientY;

        // Update state with above coordinates, and set dragging to true.
        const state = {
            dragging: true,
            startX,
            startY,
        };

        this.setState(state);
    };

    onDragMove = (e) => {
        // First check if the state is dragging, if not we can just return
        // so we do not move unless the user wants to move
        if (!this.state.dragging) {
            return;
        }

        // Get the new x coordinates
        const x =
            typeof e.clientX === 'undefined'
                ? e.changedTouches[0].clientX
                : e.clientX;
        const y =
            typeof e.clientY === 'undefined'
                ? e.changedTouches[0].clientY
                : e.clientY;

        // Take the delta where we are minus where we came from.
        const dx = x - this.state.startX;
        const dy = y - this.state.startY;

        if (dx === 0 && dy === 0) {
            return;
        }
        this.ignoreNextClick = true;
        // Pan using the deltas
        this.pan(dx, dy);

        // Update the state
        this.setState({
            startX: x,
            startY: y,
        });
    };

    onDragEnd = () => {
        this.setState({ dragging: false });
    };

    onWheel = (e) => {
        if (e.deltaY < 0) {
            this.zoom(1.05);
        } else {
            this.zoom(0.95);
        }
    };

    pan = (dx, dy) => {
        const m = this.state.matrix;
        m[4] += dx;
        m[5] += dy;
        this.setState({ matrix: m });

        const { onPositionChange } = this.props;
        onPositionChange && onPositionChange({ x: m[4], y: m[5] });
    };

    zoom = (scale) => {
        const m = this.state.matrix;
        const newZoom = m[0] * scale;
        if (newZoom < MIN_ZOOM || newZoom > MAX_ZOOM) {
            return;
        }

        const { width, height, onZoomChange } = this.props;
        onZoomChange && onZoomChange(newZoom);

        const len = m.length;
        for (let i = 0; i < len; i++) {
            m[i] *= scale;
        }
        m[4] += ((1 - scale) * width) / 2;
        m[5] += ((1 - scale) * height) / 2;
        this.setState({
            matrix: m,
        });
    };

    zoneClicked = (evt) => {
        const { onClick } = this.props;
        const { matrix } = this.state;
        const scale = matrix[0];

        if (!onClick || this.ignoreNextClick) {
            this.ignoreNextClick = false;
            return;
        }
        const dim = this.node.getBoundingClientRect();
        const x = (evt.clientX - dim.left) / scale;
        const y = (evt.clientY - dim.top) / scale;

        onClick({ x, y });
    };

    handleOnSliderChange = (zoom) => {
        if (zoom < MIN_ZOOM || zoom > MAX_ZOOM) {
            return;
        }

        const m = this.state.matrix;
        m[0] = zoom;
        m[3] = zoom;
        this.setState({
            matrix: m,
        });
    };

    resetZoom = () => {
        const { initialPosition, initialZoom } = this.state;
        const zoom = initialZoom || 1;
        this.setState({
            matrix: [zoom, 0, 0, zoom, initialPosition.x, initialPosition.y],
        });
    };

    render() {
        const { height, width, children } = this.props;
        const { matrix } = this.state;
        return (
            <div className="svg-map-container">
                <svg
                    className="svg-map"
                    height={height}
                    width={width}
                    onMouseDown={this.onDragStart}
                    onTouchStart={this.onDragStart}
                    onMouseMove={this.onDragMove}
                    onTouchMove={this.onDragMove}
                    onMouseUp={this.onDragEnd}
                    onTouchEnd={this.onDragEnd}
                    onWheel={this.onWheel}
                >
                    <g
                        transform={`matrix(${matrix.join(' ')})`}
                        onClick={this.zoneClicked}
                        ref={(n) => {
                            this.node = n;
                        }}
                    >
                        {children}
                    </g>
                </svg>
                <div className="svg-map-zoom">
                    <div className="svg-map-slider">
                        <Slider
                            value={matrix[0]}
                            min={MIN_ZOOM}
                            max={MAX_ZOOM}
                            step={0.05}
                            orientation="vertical"
                            onChange={this.handleOnSliderChange}
                        />
                    </div>
                    <Button
                        className="svg-map-zoom-reset-btn"
                        shape="circle"
                        icon="scan"
                        onClick={this.resetZoom}
                    />
                </div>
            </div>
        );
    }
}

import React from 'react';

import LoginStore from 'stores/LoginStore';
import Locale from 'locale/LocaleFactory';
import { Route, Redirect } from 'react-router-dom';
import UserStore from 'stores/UserStore';

export default class PrivateRoute extends React.Component {
    componentDidMount() {
        LoginStore.addChangeListener(this.receiveLogin);
        UserStore.addChangeListener(this.receiveUser);
    }
    componentWillUnmount() {
        LoginStore.removeChangeListener(this.receiveLogin);
        UserStore.removeChangeListener(this.receiveUser);
    }
    receiveLogin = () => {
        const user = LoginStore.getUser();
        if(user && user.locale) {
            Locale.setLocale(user.locale);
        }
        this.forceUpdate();
    }

    receiveUser = () => {
        const u = LoginStore.getUser();
        if(u) {
            const user = UserStore.getById(u.id);
            if(user && user.locale !== Locale.getLocale()) {
                Locale.setLocale(user.locale);
                this.forceUpdate();
            }
        }
    }

    render() {
        const { component: Component, ...rest } = this.props;

        if(LoginStore.isDirty()) {
            return null;
        }

        return (
            <Route
                {...rest}
                render={props =>
                    (LoginStore.isLoggedIn() ? <Component {...props} /> : <Redirect to="/login" />)
                }
            />
        );
    }
}

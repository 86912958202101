import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const USERS_URL: string = `${BaseUrlConstants.BASE_URL}users`;

class UserService extends ServiceBase {
    constructor() {
        super(USERS_URL);
    }

    getCurrent() {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}users/current`,
            method: 'GET',
        });
    }
    
    /**
     * Get all users of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/users`,
            method: 'GET',
        });
    }

    /**
     * Post a new user to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The boat to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/users`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} userId The identifier of the user.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, userId: number, patch: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/users/${userId}`,
            method: 'PATCH',
            data: patch,
        });
    }

    /**
     * Delete an existing user.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} userId The identifier of the user.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, userId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/users/${userId}`,
            method: 'DELETE',
        });
    }
}

export default new UserService();

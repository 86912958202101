import React from 'react';

import Locale from 'locale/LocaleFactory';

import ReportingFiltersStore from 'stores/ReportingFiltersStore';

import { comparedLabel, mainLabel } from 'services/ReportingLabelService';

export default class LineChartLegend extends React.Component {
    constructor() {
        super();
        this.state = {
            filters: ReportingFiltersStore.get(),
        };
    }
    componentDidMount() {
        ReportingFiltersStore.addChangeListener(this.changeFilters);
    }
    changeFilters = () => {
        this.setState({
            filters: ReportingFiltersStore.get(),
        });
    }
    render() {
        const { filters } = this.state;
        if (filters === undefined) {
            return (
                <ul className="legend" />
            );
        }
        return (
            <ul className="legend line-legend">
                <li className="main">{mainLabel(filters)}</li>
                <li className="main-mean">{Locale.trans('reporting.mean') + ' ' + mainLabel(filters)}</li>
                <li className="compared">{comparedLabel(filters)}</li>
                <li className="compared-mean">{Locale.trans('reporting.mean') + ' ' + comparedLabel(filters)}</li>
            </ul>
        );
    }

}
import React from 'react';
import { Modal } from 'antd/lib/index';

import PontoonForm from 'components/forms/PontoonForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import PontoonActions from 'actions/PontoonActions';
import FormService from 'services/utils/FormService';

/**
 * The modal to edit a pontoon.
 */
export default class EditPontoonModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loading: false,
            fields: this.getFieldsFromEntity(props.pontoon),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            this.setState({
                fields: this.getFieldsFromEntity(nextProps.pontoon),
            });
        }
    }

    getFieldsFromEntity = (entity) => {
        if (!entity) {
            return {};
        }
        const fields = {};
        const keys = Object.keys(entity);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            fields[k] = { value: entity[k] };
        }
        return fields;
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (pontoon) => {
        if (!this.props.pontoon) {
            return null;
        }
        const pontoonId = this.props.pontoon.id;

        this.setState({
            loading: true,
        });
        PontoonActions.edit(
            HarbourStore.getCurrentHarbour(),
            pontoonId,
            pontoon,
        )
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('pontoon.update.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        return (
            <Modal
                title={Locale.trans('pontoon.update.title')}
                visible={this.props.pontoon && this.props.visible}
                onCancel={this.props.onCancel}
                footer={null}
            >
                <PontoonForm
                    pontoon={this.props.pontoon}
                    onChange={this.handleFormChange}
                    fields={this.state.fields}
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                />
            </Modal>
        );
    }
}

import React from 'react';
import ReactPlayer from 'react-player';

import HarbourActions from 'actions/HarbourActions';
import HarbourStore from 'stores/HarbourStore';

import Panel from 'components/Panel.jsx';

export default class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      harbour: HarbourStore.getCurrentHarbour(),
    };
  }

  componentDidMount() {
    HarbourStore.addChangeListener(this.receiveHarbours);
    HarbourActions.reload();
  }

  componentWillUnmount() {
    HarbourStore.removeChangeListener(this.receiveHarbours);
  }

  receiveHarbours = () => {
    this.setState({
      harbour: HarbourStore.getCurrentHarbour(),
    });
  };

  render() {
    const { harbour } = this.state;

    if (!harbour) {
      return null;
    }

    return <div>{this.renderCameras()}</div>;
  }

  renderCameras() {
    const { cameras } = this.state.harbour;

    if (!cameras || !cameras.length) {
      return null;
    }

    return (
      <Panel title="Caméras Live" className="cameras">
        {cameras.map(camera => (
          <Camera key={camera.id} {...camera} />
        ))}
      </Panel>
    );
  }
}

function Camera({ id, name, url, type }) {
  if (type === 'playlist') {
    return (
      <div className="camera">
        <div className="camera-title">{name}</div>
        <ReactPlayer url={url} playing muted />
      </div>
    );
  }
  if (type === 'iframe') {
    return (
      <div className="camera">
        <div className="camera-title">{name}</div>
        <iframe
          src={url}
          title={name}
          width={640}
          height={360}
          allowFullScreen
          style={{ border: 'none' }}
        />
      </div>
    );
  }
  return null;
}

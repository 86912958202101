import React from 'react';

import ReportingFiltersStore from 'stores/ReportingFiltersStore';

import { comparedLabel, mainLabel } from 'services/ReportingLabelService';


export default class OutingsFrequencyLegend extends React.Component {
    constructor() {
        super();
        this.state = {
            filters: ReportingFiltersStore.get(),
        };
    }
    componentDidMount() {
        ReportingFiltersStore.addChangeListener(this.changeFilters);
    }
    changeFilters = () => {
        this.setState({
            filters: ReportingFiltersStore.get(),
        });
    }
    render() {
        const { filters } = this.state;
        if (filters === undefined) {
            return (<ul className="legend" />);
        }
        return (
            <ul className="legend outing-frequency">
                <li><span className="legend-display outing-frequency main"/> {mainLabel(filters)}</li>
                <li><span className="legend-display outing-frequency compared"/> {comparedLabel(filters)}</li>
            </ul>
        );
    }
}

import React from 'react';

import BaseUrlConstants from 'constants/BaseUrlConstants';
import LoginStore from 'stores/LoginStore';

const HarbourPicture = ({ harbour, className, ...props }) => {
    const token = LoginStore.getJwt();
    const formattedToken = token ? token.replace(/\+/g, '%2B') : '';
    const url = `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/picture`;
    return (
        <div {...props} className={`harbour-logo ${className || ''}`}>
            <img alt={harbour.name} src={`${url}?X-Auth-Token=${formattedToken}`} />
        </div>
    );
};

export default HarbourPicture;

import React from 'react';
import { Modal } from 'antd/lib/index';

import PartnerForm from './PartnerForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import PartnerActions from 'actions/PartnerActions';

import UserActions from 'actions/UserActions';
import UserStore from 'stores/UserStore';
import FormService from 'services/utils/FormService';
import PartnerTypeConstants from 'constants/PartnerTypeConstants.js';

/**
 * The modal to edit a partner.
 */
export default class EditPartnerModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loading: false,
            fields: this.getFieldsFromEntity(props.partner),
            users: UserStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            this.setState({
                fields: this.getFieldsFromEntity(nextProps.partner),
            });
        }
    }

    componentDidMount() {
        UserStore.addChangeListener(this.receiveUsers);
        UserActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    }
    componentWillUnmount() {
        UserStore.removeChangeListener(this.receiveUsers);
    }

    receiveUsers = () => {
        this.setState({
            users: UserStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        });
    };

    getFieldsFromEntity = (entity) => {
        if (!entity) {
            return {};
        }
        const fields = {};
        const keys = Object.keys(entity);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            fields[k] = { value: entity[k] };
        }
        return fields;
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (entity) => {
        if (!this.props.partner) {
            return null;
        }
        const id = this.props.partner.id;

        this.setState({
            loading: true,
        });
        PartnerActions.edit(HarbourStore.getCurrentHarbour(), id, entity)
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('partner.update.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        const { partner, visible, onCancel } = this.props;
        const { fields, loading, users } = this.state;
        return (
            <Modal
                title={Locale.trans('partner.update.title')}
                visible={partner && visible}
                onCancel={onCancel}
                footer={null}
            >
                <PartnerForm
                    partner={partner}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                    users={users}
                    onDelete={
                        partner &&
                        partner.type !== PartnerTypeConstants.HARBOUR_OFFICE &&
                        (() => {
                            PartnerActions.delete(
                                HarbourStore.getCurrentHarbour(),
                                partner.id,
                            )
                            .then(() => onCancel())
                            .catch(() => ToastActions.createToastError(Locale.trans('partner.delete.error')));
                        })
                    }
                />
            </Modal>
        );
    }
}

import React, { useMemo } from 'react';
import SvgPath from './SvgPath';
import IncidentConstants from 'constants/IncidentConstants';
import usePlacesOfHarbour from 'hooks/usePlacesOfHarbour';
import HarbourStore from 'stores/HarbourStore';
import MathService from 'services/utils/MathService';
import AbsenceConstants from 'constants/AbsenceConstants';
import DateService from 'services/utils/DateService';
import useIncidentsOfHarbour from 'hooks/useIncidentsOfHarbour';
import useAbsencesOfHarbour from 'hooks/useAbsencesOfHarbour';
import PlaceConstants from 'constants/PlaceConstants';

interface Place {
    id: number;
    code: string;
    placeZone: {
        svgPoints: string;
    };
    isInError: boolean;
    empty: boolean;
    placeType: string;
}

interface Point {
    x: number;
    y: number;
}

interface PlaceZone {
    id: number;
    points: string;
    status?: string;
    place: Place;
    text: string;
    textPosition: { x: number; y: number };
    angle: number;
    center: Point;
}

interface Incident {
    place: { id: number };
    status: string;
}

interface Absence {
    place: { id: number };
    departureDate: string;
    returnDate: string;
    status: string;
}

function getPolygonFromPlace(place: Place): PlaceZone | null {
    if (!place.placeZone) {
        return null;
    }

    // Get the center and angle of the zone
    const coordinates = MathService.convertStringToPoints(
        place.placeZone.svgPoints,
    );
    const center = MathService.getAreaCenter(coordinates);
    let angle = MathService.getAngleOfPolygon(coordinates);
    if (angle < -90) {
        angle += 180;
    } else if (angle > 90) {
        angle -= 180;
    }

    return {
        id: place.id,
        place,
        center,
        text: place.code,
        textPosition: center,
        angle,
        points: place.placeZone.svgPoints,
    };
}

function placeHasIncident(place: Place, incidents: Incident[]) {
    return !!incidents.find(
        (i) =>
            i.place &&
            i.place.id === place.id &&
            i.status === IncidentConstants.PENDING,
    );
}

function getPlaceStatus(place: Place, absences: Absence[]) {
    const today = new Date();
    if (place.isInError) {
        return 'error';
    }
    const absence = absences.find(
        (f) =>
            f.place &&
            f.place.id === place.id &&
            DateService.isBetween(today, f.departureDate, f.returnDate, '[)'),
    );
    const position = place.empty ? 'out' : 'in';

    if (
        place.placeType === PlaceConstants.STOPOVER ||
        (absence &&
            absence.status === AbsenceConstants.APPROVED &&
            absence.returnDate !== DateService.formatApi(today))
    ) {
        return position === 'in' ? 'stopover' : 'absence';
    }
    return position;
}

interface Props {
    filterCallback: (place: Place, status: string) => void;
    showIncident: boolean;
    onPlaceClick: (place: Place) => void;
    zoom: number;

    selectedPlace: Place;
    onSelectedPlaceChange: (values: any) => void;
}
export default function HarbourMapPlaceCalque({
    filterCallback,
    showIncident,
    onPlaceClick,
    zoom,

    selectedPlace,
    onSelectedPlaceChange,
}: Props) {
    const currentHarbour = HarbourStore.getCurrentHarbour();
    const places = usePlacesOfHarbour(currentHarbour) as Place[];
    const incidents = useIncidentsOfHarbour(currentHarbour);
    const absences = useAbsencesOfHarbour(currentHarbour);
    const polygons = useMemo(
        () => (selectedPlace ? [...places.filter(p => selectedPlace.id !== p.id), selectedPlace] : places).map(getPolygonFromPlace).filter((p) => !!p),
        [places, selectedPlace],
    )
        .map(
            (p) =>
                p && {
                    ...p,
                    status: getPlaceStatus(p.place, absences),
                },
        )
        .filter((p) => p && filterCallback(p.place, p.status));
    return (
        <g>
            {polygons
                .filter(
                    (p) =>
                        !selectedPlace ||
                        (p && p.place.id !== selectedPlace.id),
                )
                .map(
                    (p) =>
                        p && (
                            <PlaceSvg
                                place={p}
                                incidents={incidents}
                                showIncident={showIncident}
                                zoom={zoom}
                                onClick={() => onPlaceClick(p.place)}
                            />
                        ),
                )}
            {/* We render the selected zone at the end to make them on top to capture the mouse events */}
            {polygons
                .filter(
                    (p) =>
                        p && selectedPlace && p.place.id === selectedPlace.id,
                )
                .map((p) => {
                    return (
                        p && (
                            <PlaceSvg
                                place={p}
                                incidents={incidents}
                                showIncident={showIncident}
                                zoom={zoom}
                                editable
                                onChange={(path) =>
                                    onSelectedPlaceChange({
                                        svgPoints: path,
                                    })
                                }
                            />
                        )
                    );
                })}
        </g>
    );
}

interface PlaceSvgProps {
    place: PlaceZone;
    showIncident?: boolean;
    incidents: Incident[];
    zoom: number;
    onClick?: () => void;
    editable?: boolean;
    onChange?: (value: string) => void;
}

function PlaceSvg({
    place,
    showIncident,
    incidents,
    zoom,
    onClick,
    editable,
    onChange,
}: PlaceSvgProps) {
    return (
        <g
            className={`place ${place.status} ${
                showIncident && placeHasIncident(place.place, incidents)
                    ? 'incident'
                    : ''
            }`}
            onClick={onClick}
        >
            <SvgPath
                d={place.points}
                zoom={zoom}
                editable={editable}
                onChange={onChange}
            />
            {place.text && (
                <text
                    x={place.textPosition.x}
                    y={place.textPosition.y}
                    dy="1"
                    textAnchor="middle"
                    transform={`rotate(${place.angle} ${place.textPosition.x},${place.textPosition.y})`}
                    className="place-text"
                    fill="#787878"
                >
                    {place.text}
                </text>
            )}
        </g>
    );
}

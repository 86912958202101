import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'antd/lib/index';
import Locale from 'locale/LocaleFactory';
import FormItem from 'components/forms/FormItems';
import HarbourMapPositionActions from 'actions/HarbourMapPositionActions';
import HarbourStore from 'stores/HarbourStore';
import ToastActions from 'actions/ToastActions';
import LoginStore from 'stores/LoginStore';
import SecurityService from 'services/SecurityService';

export default function CreatePositionModal({
    visible,
    onCreate,
    onCancel,
    zoom,
    position,
}) {
    const [loading, setLoading] = useState(false);
    function handleSubmit(values) {
        setLoading(true);
        HarbourMapPositionActions.create(HarbourStore.getCurrentHarbour(), {
            ...values,
            zoom,
            positionX: position && Math.round(position.x),
            positionY: position && Math.round(position.y),
        })
            .then((newPosition) => {
                ToastActions.createToastSuccess(
                    Locale.trans('mapPosition.createModal.success'),
                );
                onCreate(newPosition);
            })
            .catch(() => {
                ToastActions.createToastSuccess(
                    Locale.trans('mapPosition.createModal.error'),
                );
            })
            .finally(() => {
                setLoading(false);
            });
    }
    return (
        <Modal
            title={Locale.trans('mapPosition.createModal.title')}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <PositionForm onSubmit={handleSubmit} loading={loading} />
        </Modal>
    );
}

function PositionFormContent({ form, loading, onSubmit }) {
    const { getFieldValue } = form;

    const canCreatePublic = SecurityService.isGranted(['administrator']);

    useEffect(() => {
        form.validateFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err && onSubmit) {
                if(!canCreatePublic || !values.isPublic) {
                    values.user = LoginStore.getUser().id;
                }
                delete values.isPublic;
                onSubmit(values);
            }
        });
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FormItem.Input
                id="name"
                type="text"
                required
                label={Locale.trans('mapPosition.name')}
                initialValue={
                    getFieldValue('name') ? getFieldValue('name') : null
                }
                form={form}
                hasFeedback
            />
            <FormItem.Checkbox
                id="isDefault"
                label={Locale.trans('mapPosition.isDefault')}
                initialValue={
                    getFieldValue('isDefault')
                        ? getFieldValue('isDefault')
                        : null
                }
                form={form}
                hasFeedback
            />
            {canCreatePublic && (
                <FormItem.Checkbox
                    id="isPublic"
                    label={Locale.trans('mapPosition.isPublic')}
                    initialValue={null}
                    form={form}
                    hasFeedback
                />
            )}
            <Button type="primary" htmlType="submit" loading={loading}>
                {Locale.trans('save')}
            </Button>
        </Form>
    );
}

const PositionForm = Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(PositionFormContent);

import React, { useState } from 'react';
import useCurrentHarbour from 'hooks/useCurrentHarbour';
import useExternalLinksOfHarbour from 'hooks/useExternalLinksOfHarbour';
import ArrayService from 'services/utils/ArrayService';
import { Button, Checkbox, Input, Upload } from 'antd';
import LocaleFactory from 'locale/LocaleFactory';
import ColorPicker from 'components/ColorPicker';

import './ExternalLinkConfiguration.scss';
import ExternalLinkActions from 'actions/ExternalLinkActions';
import ToastActions from 'actions/ToastActions';

export default function ExternalLinkConfiguration() {
    const harbour = useCurrentHarbour();
    const initialLinks = useExternalLinksOfHarbour(harbour);
    const [createdLinks, setCreatedLinks] = useState<IExternalLink[]>([]);
    const [editedLinks, setEditedLinks] = useState<any[]>([]);
    const [deletedLinkIds, setDeletedLinkIds] = useState<number[]>([]);
    const [loading, setLoading] = useState(false);

    const links: IExternalLink[] = [
        ...initialLinks
            .filter((i) => !deletedLinkIds.includes(i.id))
            .map((l) => {
                const editedLink = editedLinks.find((e) => e.id === l.id);
                if (!editedLink) {
                    return l;
                }
                return {
                    ...l,
                    ...editedLink,
                };
            }),
        ...createdLinks,
    ].sort(ArrayService.sortNumberColumn('sortOrder'));

    function updateLink(id: number, values: any) {
        function update(prevLinks: any[]) {
            const newLinks = [...prevLinks];
            const iLink = newLinks.findIndex((p) => p.id === id);
            const newLink = {
                ...newLinks[iLink],
                ...values,
                id,
            };
            if (iLink === -1) {
                newLinks.push(newLink);
            } else {
                newLinks[iLink] = newLink;
            }
            return newLinks;
        }
        if (typeof id === 'string' && (id as string).startsWith('new-')) {
            setCreatedLinks(update);
        } else {
            setEditedLinks(update);
        }
    }

    return (
        <div className="external-link-configuration">
            {links.map((link, i) => {
                return (
                    <LinkForm
                        key={link.id}
                        link={link}
                        onChange={(values) => updateLink(link.id, values)}
                        onDelete={() => {
                            if (
                                typeof link.id === 'string' &&
                                (link.id as string).startsWith('new-')
                            ) {
                                setCreatedLinks((prev) =>
                                    prev.filter((p) => p.id !== link.id),
                                );
                            } else {
                                setDeletedLinkIds((prev) => [
                                    ...prev,
                                    +link.id,
                                ]);
                            }
                        }}
                        onSortUp={() => {
                            if (i > 0) {
                                updateLink(link.id, {
                                    sortOrder: link.sortOrder - 1,
                                });
                                updateLink(links[i - 1].id, {
                                    sortOrder: links[i - 1].sortOrder + 1,
                                });
                            }
                        }}
                        onSortDown={() => {
                            if (i < links.length - 1) {
                                updateLink(link.id, {
                                    sortOrder: link.sortOrder + 1,
                                });
                                updateLink(links[i + 1].id, {
                                    sortOrder: links[i + 1].sortOrder - 1,
                                });
                            }
                        }}
                    />
                );
            })}
            <div className="__actions">
                <Button
                    type="primary"
                    onClick={() => {
                        setCreatedLinks((prev) => [
                            ...prev,
                            {
                                id: `new-${Date.now()}` as any,
                                name: 'Nouveau',
                                url: 'https://',
                                color: '#59c1c6',
                                noBackground: false,
                                sortOrder:
                                    Math.max(
                                        0,
                                        ...links.map((l) => l.sortOrder),
                                    ) + 1,
                                image: null,
                            },
                        ]);
                    }}
                >
                    {LocaleFactory.trans('externalLink.create.title')}
                </Button>
                <Button
                    type="primary"
                    loading={loading}
                    onClick={() => {
                        if (!harbour || loading) {
                            return;
                        }
                        setLoading(true);
                        Promise.all([
                            ...createdLinks.map((link) =>
                                ExternalLinkActions.create(harbour, {
                                    ...link,
                                    harbour: undefined,
                                    id: undefined,
                                }),
                            ),
                            ...editedLinks.map((link) =>
                                ExternalLinkActions.edit(harbour, link.id, {
                                    ...link,
                                    harbour: undefined,
                                    id: undefined,
                                }),
                            ),
                            ...deletedLinkIds.map((linkId) =>
                                ExternalLinkActions.delete(harbour, linkId),
                            ),
                        ])
                            .then(() => {
                                ToastActions.createToastSuccess(
                                    LocaleFactory.trans(
                                        'externalLink.create.success',
                                    ),
                                );
                            })
                            .catch(() => {
                                ToastActions.createToastError(
                                    LocaleFactory.trans(
                                        'externalLink.create.error',
                                    ),
                                );
                            })
                            .finally(() => {
                                setLoading(false);
                                setCreatedLinks([]);
                                setEditedLinks([]);
                                setDeletedLinkIds([]);
                            });
                    }}
                >
                    {LocaleFactory.trans('save')}
                </Button>
            </div>
        </div>
    );
}

interface LinkFormProps {
    link: IExternalLink;
    onChange: (values: any) => void;
    onDelete: () => void;
    onSortUp: () => void;
    onSortDown: () => void;
}
function LinkForm({
    link,
    onChange,
    onDelete,
    onSortUp,
    onSortDown,
}: LinkFormProps) {
    return (
        <div className="__link-form">
            <div className="__preview">
                <div
                    className={`__icon ${
                        link.noBackground ? '--noBackground' : ''
                    }`}
                    style={{
                        backgroundColor: link.color,
                        borderColor: link.color,
                    }}
                >
                    {link.image && <img alt="icon" src={link.image} />}
                </div>
                <div className="__name" style={{ color: link.color }}>
                    {link.name}
                </div>
            </div>
            <div className="__fields">
                <div className="__field">
                    <div className="__label">
                        {LocaleFactory.trans('externalLink.name')}
                    </div>
                    <Input
                        value={link.name}
                        onChange={(e) => onChange({ name: e.target.value })}
                    />
                </div>
                <div className="__row">
                    <div className="__field">
                        <div className="__label">
                            {LocaleFactory.trans('externalLink.color')}
                        </div>
                        <ColorPicker
                            value={link.color}
                            onChange={(value: string) =>
                                onChange({ color: value })
                            }
                        />
                    </div>
                    <div className="__field">
                        <div className="__label">
                            {LocaleFactory.trans('externalLink.noBackground')}
                        </div>
                        <Checkbox
                            checked={link.noBackground}
                            onChange={(e) =>
                                onChange({ noBackground: e.target.checked })
                            }
                        />
                    </div>
                </div>
                <div className="__field">
                    <div className="__label">
                        {LocaleFactory.trans('externalLink.url')}
                    </div>
                    <Input
                        value={link.url}
                        onChange={(e) => onChange({ url: e.target.value })}
                    />
                </div>
                <div className="__actions">
                    <Upload
                        beforeUpload={(f) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(f);
                            reader.onload = () => {
                                onChange({ image: reader.result });
                            };
                            return false;
                        }}
                        fileList={undefined}
                        accept="image/*"
                    >
                        <Button>
                            {LocaleFactory.trans('externalLink.updateIcon')}
                        </Button>
                    </Upload>
                    <Button type="danger" onClick={onDelete}>
                        {LocaleFactory.trans('delete')}
                    </Button>
                </div>
            </div>
            <div className="__sort">
                <Button onClick={onSortUp} icon="up" />
                <Button onClick={onSortDown} icon="down" />
            </div>
        </div>
    );
}

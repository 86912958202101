import React from 'react';
import { arc, pie, select } from 'd3';
import MathService from '../../services/utils/MathService';

export default class LoadingRate extends React.Component {
    shouldComponentUpdate(nextProps) {
        const { percent } = this.props;
        if (nextProps.percent !== percent) {
            this.createChart(nextProps);
        }
        return false;
    }

    componentDidMount() {
        const { width, height } = this.props;
        this.width = width || this.wrapper.clientWidth;
        this.height = height || this.wrapper.clientHeight;
        this.radius = Math.min(this.width, this.height) / 2 - 5;

        this.chart = select(this.node)
            .attr('height', this.height)
            .attr('width', this.width);

        this.createChart(this.props);
    }

    createChart = (props) => {
        this.clean();
        this.appendArcs(props);
        this.appendTextOccupancyRate(props);
    };

    clean = () => {
        this.chart.selectAll('g').remove();
        this.chart.selectAll('text').remove();
    };

    appendArcs(props) {
        const { percent } = props;
        const parts = [
            {
                value: percent,
                className: 'loading-chart_full',
            },
            {
                value: 100 - percent,
                className: 'loading_chart_empty',
            },
        ];
        const arcs = pie()
            .padAngle(0.1)
            .sort(null)
            .value(d => d.value)(parts);

        const arcValue = arc()
            .innerRadius(this.radius * 0.75)
            .outerRadius(this.radius - 1);
        const graph = this.chart
            .append('g')
            .attr('transform', `translate(${this.width / 2},${this.height / 2})`)
            .selectAll('path');
        graph
            .data(arcs)
            .enter()
            .append('path')
            .attr('class', d => d.data.className)
            .attr('d', arcValue);
    }

    appendTextOccupancyRate(props) {
        const { percent, precision } = props;
        this.chart
            .append('text')
            .text(`${MathService.format(percent, precision)}%`)
            .attr('x', this.width / 2)
            .attr('y', this.height / 2 + 2)
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'middle')
            .attr('class', 'loading-chart_text')
            .attr('style', `font-size: ${this.radius / 2}px`);
    }

    render() {
        return (
            <div
                className="chart"
                ref={(wrapper) => {
                    this.wrapper = wrapper;
                }}
            >
                <svg
                    ref={(node) => {
                        this.node = node;
                    }}
                />
            </div>
        );
    }
}

import React from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';
import SelectFormItem from 'components/form-items/SelectFormItem';

function cmpBoatByName(u1, u2) {
    const uN1 = u1.name;
    const uN2 = u2.name;
    return uN1 > uN2 ? 1 : -1;
}

class OutingForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const outing = this.getEntityFromValues(values);
                this.props.onSubmit(outing);
            }
        });
    };

    getEntityFromValues = (values) => {
        const entity = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.forEach((k) => {
            if (k.endsWith('_ids')) {
                return;
            }
            if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                entity[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                entity[k] = values[k];
            }
        });
        return entity;
    };

    render() {
        const { loading } = this.props;
        return (
            <Form onSubmit={this.handleSubmit}>
                <BoatsField
                    outing={this.props.outing}
                    boats={this.props.boats}
                    form={this.props.form}
                    onChange={this.props.onChange}
                />
                <FormItem.DatePicker
                    id="datetimeOut"
                    required
                    label="Départ"
                    form={this.props.form}
                    validateStatus={this.getValidateStatus('datetimeOut')}
                    help={this.getHelp('datetimeOut')}
                    hasFeedback
                />
                <FormItem.DatePicker
                    id="datetimeIn"
                    required
                    label="Retour"
                    form={this.props.form}
                    validateStatus={this.getValidateStatus('datetimeIn')}
                    help={this.getHelp('datetimeIn')}
                    hasFeedback
                />
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }
}

function BoatsField({ outing, boats, form, onChange }) {
    if (!boats || boats.length === 0) {
        if (outing && outing.boat && outing.boat.name) {
            return (
                <div>
                    <label>Bateau : </label>
                    {outing.boat.name}
                </div>
            );
        }
        return null;
    }
    const options = boats.sort(cmpBoatByName).map((c) => ({
        value: c.id,
        label: `${c.name} (${c.immatriculation})`,
    }));
    return (
        <SelectFormItem
            id="boat_id"
            allowClear
            label="Bateau"
            options={options}
            form={form}
        />
    );
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        console.debug('onFieldsChange', changedFields);
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(OutingForm);

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class NotificationUserListService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all lists of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/notification-user-lists`,
            method: 'GET',
        });
    }

    /**
     * Get a list by unique identifier.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} listId The identifier of the pontoon.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    getById(harbourId: number, listId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/notification-user-lists/${listId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new list to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The list to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/notification-user-lists`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing list.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} listId The identifier of the list.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, listId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/notification-user-lists/${listId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} listId The identifier of the pontoon.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, listId: number, patch: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/notification-user-lists/${listId}`,
            method: 'PATCH',
            data: patch,
        });
    }
}

export default new NotificationUserListService();

import React, { useState } from 'react';

const Context = React.createContext({
    initialNotificationFields: {},
    notificationIdToEdit: undefined,
    setInitialNotificationFields: () => {},
});

export default {
    Provider: function ({ children }) {
        const [
            initialNotificationFields,
            setInitialNotificationFields,
        ] = useState({});
        const [notificationIdToEdit, setNotificationIdToEdit] = useState(undefined);
        return (
            <Context.Provider
                value={{
                    initialNotificationFields,
                    setInitialNotificationFields,

                    notificationIdToEdit,
                    setNotificationIdToEdit,
                }}
            >
                {children}
            </Context.Provider>
        );
    },
    Consumer: Context.Consumer,
    Context,
};

import React, { useState, useRef, useMemo } from 'react';
import { Input, Checkbox } from 'antd';
import List from 'react-virtualized/dist/commonjs/List';

import Locale from 'locale/LocaleFactory';

//import "style/components/table/TableColumnFilter.scss";
import StringService from 'services/utils/StringService';
import ArrayService from 'services/utils/ArrayService';

function TableColumnFilter({ name, values, selectedValues, onChange, sortingFunc }) {
    const [searchText, setSearchText] = useState('');
    const listRef = useRef(null);

    function handleChange(newValues) {
        listRef.current.forceUpdateGrid();
        if (onChange) {
            onChange(name, newValues);
        }
    }

    function handleInputChange(e) {
        const newSearchText = e.target.value;
        setSearchText(newSearchText);
        handleChange(getFilteredValues(newSearchText, values));
    }

    function getFilteredValues(filterText, values) {
        let newSelectedValues = [];
        if (filterText.length > 0) {
            const searchTextUpper = filterText.toUpperCase();
            newSelectedValues = values
                .filter(
                    (v) => v.text && v.text.toUpperCase().indexOf(searchTextUpper) !== -1,
                )
                .map((v) => v.value);
        }
        return newSelectedValues;
    }

    function selectValue(value) {
        let newSelectedValues = selectedValues ? [...selectedValues] : [];
        if (newSelectedValues.indexOf(value) === -1) {
            newSelectedValues.push(value);
            handleChange(newSelectedValues);
        }
    }

    function deselectValue(value) {
        let newSelectedValues = selectedValues ? [...selectedValues] : [];
        const i = newSelectedValues.indexOf(value);
        if (i !== -1) {
            newSelectedValues.splice(i, 1);
            handleChange(newSelectedValues);
        }
    }

    function renderRow({ index, key, style, allValues, selectedValues }) {
        const value = allValues[index];
        if (!value) {
            return null;
        }

        return (
            <div key={key} className="list-row" style={style}>
                <Checkbox
                    checked={
                        selectedValues &&
                        selectedValues.indexOf(value.value) !== -1
                    }
                    onChange={(e) => {
                        if (e.target.checked) {
                            selectValue(value.value);
                        } else {
                            deselectValue(value.value);
                        }
                    }}
                >
                    {value.text}
                </Checkbox>
            </div>
        );
    }

    const allValues = useMemo(
        () =>
            ArrayService.uniqueObject(values, (v) => v.value)
                .filter((v) => {
                    if (searchText.length > 0) {
                        const searchTextUpper = searchText.toUpperCase();
                        return (
                            v.text && v.text.toUpperCase().indexOf(searchTextUpper) !== -1
                        );
                    }
                    return true;
                }),
        [values, searchText],
    ).sort((v1, v2) =>
        sortingFunc
            ? sortingFunc(v1, v2)
            : StringService.compareCaseInsensitive(v1.text, v2.text),
    );

    return (
        <div className="table-column-filter-dropdown table-column-list-filter">
            <Input
                placeholder={Locale.trans('search')}
                value={searchText}
                onChange={handleInputChange}
            />
            <List
                ref={listRef}
                height={300}
                overscanRowCount={4}
                rowCount={allValues.length}
                rowHeight={30}
                rowRenderer={(p) =>
                    renderRow({ ...p, allValues, selectedValues })
                }
                width={250}
            />
        </div>
    );
}

export default TableColumnFilter;

// @flow

import React from 'react';
import { Modal } from 'antd/lib/index';

import UrgencePhoneNumberForm from 'components/forms/UrgencePhoneNumberForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import UrgencePhoneNumberActions from 'actions/UrgencePhoneNumberActions';
import FormService from 'services/utils/FormService';

/**
 * The modal to edit an urgence phone number.
 */
export default class EditUrgencePhoneNumberModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loading: false,
            fields: this.getFieldsFromEntity(props.phoneNumber),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            this.setState({
                fields: this.getFieldsFromEntity(nextProps.phoneNumber),
            });
        }
    }

    getFieldsFromEntity = (entity) => {
        if (!entity) {
            return {};
        }
        const fields = {};
        const keys = Object.keys(entity);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            fields[k] = { value: entity[k] };
        }
        return fields;
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (phoneNumber) => {
        if (!this.props.phoneNumber) {
            return;
        }
        const phoneNumberId = this.props.phoneNumber.id;

        this.setState({
            loading: true,
        });
        UrgencePhoneNumberActions.edit(
            HarbourStore.getCurrentHarbour(),
            phoneNumberId,
            phoneNumber,
        )
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('urgencePhoneNumber.update.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    deletePhoneNumber = () => {
        const { phoneNumber } = this.props;
        if (!phoneNumber) {
            return;
        }
        this.setState({
            loading: true,
        });
        UrgencePhoneNumberActions.delete(
            HarbourStore.getCurrentHarbour(),
            phoneNumber.id,
        )
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('urgencePhoneNumber.delete.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        return (
            <Modal
                title={Locale.trans('urgencePhoneNumber.update.title')}
                visible={this.props.phoneNumber && this.props.visible}
                onCancel={this.props.onCancel}
                footer={null}
            >
                <UrgencePhoneNumberForm
                    phoneNumber={this.props.phoneNumber}
                    onChange={this.handleFormChange}
                    fields={this.state.fields}
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                    onDelete={this.deletePhoneNumber}
                />
            </Modal>
        );
    }
}

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';
import HarbourStore from 'stores/HarbourStore';
import BaseStore from './BaseStore';

const CONSTANTS = {
    RECEIVE: 'WeatherStore.receive',
};

class WeatherStore extends BaseStore {
    _entities: { [harbour_id: number]: any };
    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = [];
    }

    _registerToActions = (action: { type: string; payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
            case CONSTANTS.RECEIVE:
                this._receive(payload.local);
                break;
            default:
                // Do Nothing
                break;
        }
    };

    _receive = (local: IPlace[]) => {
        this._entities = local;
        this.emitChange();
    };

    get = () => this._entities;
}
const store = new WeatherStore();
class WeatherService extends ServiceBase {
    /**
     * Post a new user to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The boat to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    postForecast(harbourId: number, resource: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/weather-forecast/local`,
            method: 'POST',
            data: resource,
        });
    }
    getForecast(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/weather-forecast/local`,
            method: 'GET',
        });
    }
}

const service = new WeatherService('notDefined');

class WeatherActions extends ActionsBase {
    constructor() {
        super();
        HarbourStore.addChangeListener(this.load);
    }
    uploadForecast(filesContent: any) {
        const $this = this;
        return new Promise(
            (
                resolve: (harbour: IHarbour) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newHarbour: IHarbour): void {
                    $this.load();
                    resolve(newHarbour);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                const id = HarbourStore.getCurrentHarbourId();
                if (id) {
                    service
                        .postForecast(id, filesContent)
                        .then(handleSuccess)
                        .catch(handleError);
                }
            },
        );
    }
    load = () => {
        const $this = this;
        return new Promise(
            (
                resolve: (harbour: IHarbour) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newHarbour: IHarbour): void {
                    dispatcher.dispatch({
                        type: CONSTANTS.RECEIVE,
                        payload: {
                            local: newHarbour,
                        },
                    });
                    resolve(newHarbour);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                const id = HarbourStore.getCurrentHarbourId();
                if (id) {
                    service
                        .getForecast(id)
                        .then(handleSuccess)
                        .catch(handleError);
                }
            },
        );
    };
}

const action = new WeatherActions();
const uploadForecast = (filesContent: any) => {
    action.uploadForecast(filesContent);
};
const reload = () => {
    action.load();
};
const get = () => {
    return store.get();
};

const register = (callback: () => void) => {
    store.addChangeListener(callback);
    return {
        remove: () => store.removeChangeListener(callback),
    };
};

export { uploadForecast, reload, get, register };

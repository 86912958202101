import BaseStore from 'stores/BaseStore';
import RateSeasonConfigurationConstants from 'constants/RateSeasonConfigurationConstants';

class RateSeasonConfigurationStore extends BaseStore {
    _entities: { [harbour_id: number]: IRateSeasonConfiguration };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case RateSeasonConfigurationConstants.RECEIVE_RATE_SEASON_CONFIGURATION_OF_HARBOUR:
            this._receiveByHarbour(payload.harbour, payload.configuration);
            break;

        default:
            // Do Nothing
            break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, configuration: IRateSeasonConfiguration) => {
        this._entities[harbour.id] = configuration;
        this.emitChange();
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    /**
     * Get the rate season configuration of a harbour.
     */
    getByHarbour = (harbour: IHarbour): IRateSeasonConfiguration =>
        this._entities[harbour.id] || null;
}

export default new RateSeasonConfigurationStore();

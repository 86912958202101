import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class ExternalLinkService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all externalLinks of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/external-links`,
            method: 'GET',
        });
    }

    /**
     * Get a externalLink by unique identifier.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} externalLinkId The identifier of the externalLink.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    getById(harbourId: number, externalLinkId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/external-links/${externalLinkId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new externalLink to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The externalLink to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/external-links`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing externalLink.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} externalLinkId The identifier of the externalLink.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, externalLinkId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/external-links/${externalLinkId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} externalLinkId The identifier of the externalLink.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, externalLinkId: number, patch: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/external-links/${externalLinkId}`,
            method: 'PATCH',
            data: patch,
        });
    }
}

export default new ExternalLinkService();

import React from 'react';
import { Modal } from 'antd';

import RateForm from 'components/forms/RateForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import RateActions from 'actions/RateActions';
import HarbourStore from 'stores/HarbourStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to edit a rate.
 */
export default class EditRateModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loading: false,
            fields: this.getFieldsFromEntity(props.rate),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            this.setState({
                fields: this.getFieldsFromEntity(nextProps.rate),
            });
        }
    }

    getFieldsFromEntity = (entity) => {
        if (!entity) {
            return {};
        }
        const fields = {};
        const keys = Object.keys(entity);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            fields[k] = { value: entity[k] };
        }
        return fields;
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (rate) => {
        if (!this.props.rate) {
            return;
        }
        const rateId = this.props.rate.id;
        this.setState({
            loading: true,
        });
        RateActions.edit(HarbourStore.getCurrentHarbour(), rateId, rate)
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('rate.update.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        const { fields, loading } = this.state;
        const { visible, onCancel, rate } = this.props;
        return (
            <Modal
                title={Locale.trans('rate.update.title')}
                visible={visible}
                onCancel={onCancel}
                footer={null}
            >
                <RateForm
                    rate={rate}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                />
            </Modal>
        );
    }
}

import BaseStore from 'stores/BaseStore';
import ReportingConstants from 'constants/ReportingConstants';
import HarbourStore from 'stores/HarbourStore';

class OutingReportingStore extends BaseStore {
    _entities: { [harbour_id: number]: any };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string; payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
            case ReportingConstants.OUTINGS_RECEIVED:
                this._receiveOutings(payload.harbour, payload.outings);
                break;
            default:
                // Do Nothing
                break;
        }
    };

    _receiveOutings = (harbour: IHarbour, outings: any) => {
        if (typeof this._entities[harbour.id] === 'undefined') {
            this._entities[harbour.id] = {};
        }
        this._entities[harbour.id] = this._formatOutings(outings);
        this.emitChange();
    };

    _formatOutings(outings: any) {
        const outingsToStore = outings;
        outingsToStore.main.repartition = Object.values(
            outings.main.repartition,
        );
        outingsToStore.main.history = Object.values(outings.main.history);
        outingsToStore.main.historyByType.sailboat = Object.values(
            outingsToStore.main.historyByType.sailboat || {},
        );
        outingsToStore.main.historyByType.motorboat = Object.values(
            outingsToStore.main.historyByType.motorboat || {},
        );
        outingsToStore.main.historyByType.multihull = Object.values(
            outingsToStore.main.historyByType.multihull || {},
        );
        outingsToStore.main.repartition = outingsToStore.main.repartition.map(
            (d: any) => ({ hour: parseInt(d.hour, 10), value: d.value }),
        );
        outingsToStore.main.repartition.sort(
            (d1: any, d: any) => parseInt(d1.hour, 10) - parseInt(d.hour, 10),
        );
        outingsToStore.compared.repartition = Object.values(
            outings.compared.repartition,
        );
        outingsToStore.compared.history = Object.values(
            outings.compared.history,
        );
        outingsToStore.compared.repartition = outingsToStore.compared.repartition.map(
            (d: any) => ({ hour: parseInt(d.hour, 10), value: d.value }),
        );
        outingsToStore.compared.repartition.sort(
            (d1: any, d: any) => parseInt(d1.hour, 10) - parseInt(d.hour, 10),
        );
        return outingsToStore;
    }

    getOutings(harbour?: IHarbour) {
        harbour = harbour || HarbourStore.getCurrentHarbour();
        //Done this way to avoid effect on this.entities from clients.
        const getEmpty = () => ({
            repartition: [],
            history: [],
            historyByType: {
                sailboat: [],
                motorboat: [],
                multihull: [],
            },
        });
        let main;
        let compared;
        if (!harbour || !this._entities[harbour.id]) {
            main = getEmpty();
            compared = getEmpty();
        } else {
            main = this._entities[harbour.id].main || getEmpty();
            compared = this._entities[harbour.id].compared || getEmpty();
        }
        
        compared.historyByType.sailboat = compared.historyByType.sailboat
            ? Object.values(compared.historyByType.sailboat)
            : [];
        compared.historyByType.motorboat = compared.historyByType.motorboat
            ? Object.values(compared.historyByType.motorboat)
            : [];
        compared.historyByType.multihull = compared.historyByType.multihull
            ? Object.values(compared.historyByType.multihull)
            : [];

        return {
            main: {
                repartition: [...(main.repartition || [])],
                history: [...(main.history || [])],
                historyByType: {
                    sailboat: [...(main.historyByType.sailboat || [])],
                    motorboat: [...(main.historyByType.motorboat || [])],
                    multihull: [...(main.historyByType.multihull || [])],
                },
            },
            compared: {
                repartition: [...(compared.repartition || [])],
                history: [...(compared.history || [])],
                historyByType: compared.historyByType,
            },
        };
    }
}

export default new OutingReportingStore();

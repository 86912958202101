

import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import RateConstants from 'constants/RateConstants';
import RateService from 'services/RateService';

import CacheManager from 'services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class RateActions extends ActionsBase {
    create = (harbour: IHarbour, rate: Object) => {
        const $this = this;
        return new Promise((resolve: (rate: IRate) => void, reject: (response: Object) => void): void => {
            function handleSuccess(newRate: IRate): void {
                dispatcher.dispatch({
                    type: RateConstants.RECEIVE_RATE_OF_HARBOUR,
                    payload: {
                        harbour,
                        rate: newRate,
                    },
                });
                resolve(newRate);
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            RateService.post(harbour.id, rate)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (harbour: Entity, rateId: number, rate: Object) => {
        const $this = this;
        return new Promise((resolve: (rate: IRate) => void, reject: (response: Object) => void): void => {
            function handleSuccess(newRate: IRate): void {
                dispatcher.dispatch({
                    type: RateConstants.RECEIVE_RATE_OF_HARBOUR,
                    payload: {
                        harbour,
                        rate: newRate,
                    },
                });
                resolve(newRate);
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            RateService.patch(harbour.id, rateId, rate)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    delete = (harbour: Entity, id: number) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            function handleSuccess(): void {
                dispatcher.dispatch({
                    type: RateConstants.DELETE_RATE_OF_HARBOUR,
                    payload: {
                        harbour,
                        id,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            RateService.remove(harbour.id, id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByHarbour = (harbour: Entity) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            if (
                CacheManager.isCached('Rate:reloadByHarbour', harbour.id.toString(), CACHE_DURATION)
            ) {
                resolve();
                return;
            }

            function handleSuccess(rates: Array<IRate>): void {
                dispatcher.dispatch({
                    type: RateConstants.RECEIVE_RATES_OF_HARBOUR,
                    payload: {
                        harbour,
                        rates,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            RateService.getByHarbour(harbour.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

export default new RateActions();

import React, { useState } from 'react';
import { Button, Icon, Upload } from 'antd';
import BaseUrlConstants from '../constants/BaseUrlConstants';
import TokenContainer from '../services/TokenContainer';

const UploadButton = ({
    children,
    onError,
    onSuccess,
    url,
    single,
    type,
    size,
    reloadAfterSuccess,
    accept,
    noIcon
}) => {
    const [loading, setLoading] = useState(false);
    const props = {
        name: 'file',
        action: `${BaseUrlConstants.BASE_URL}${url}`,
        headers: {
            'X-Auth-Token': TokenContainer.get(),
        },
        multiple: !single,
        showUploadList: !single,
        onChange: (info) => {
            const { status } = info.file;
            if (status === 'done') {
                onSuccess && onSuccess();
                if (reloadAfterSuccess) {
                    window.location.reload();
                }
                setLoading(false);
            } else if (status === 'error') {
                onError && onError();
                setLoading(false);
            } else {
                setLoading(true);
            }
        },
        accept,
    };
    return (
        <Upload {...props} className={'upload'}>
            <Button
                type={type}
                size={size}
                className={'upload__button'}
                loading={loading}
            >
                {!loading && !noIcon && <Icon type="upload" />}
                {children}
            </Button>
        </Upload>
    );
};
export default UploadButton;

import React from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';

import RoleConstants from 'constants/RoleConstants';

class ApproveForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const user = this.getUserFromValues(values);
                this.props.onSubmit(user);
            }
        });
    };

    getUserFromValues = (values) => {
        const boat = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.map((k) => {
            if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                boat[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                boat[k] = values[k];
            }
            return null;
        });
        return boat;
    };

    render() {
        const { form, loading } = this.props;

        return (
            <Form onSubmit={this.handleSubmit}>
                {this.renderRoleField()}

                <h2>{Locale.trans('accessRequest.approval.existingBoat')}</h2>
                {this.renderBoatField()}

                <h2>{Locale.trans('accessRequest.approval.newBoat')}</h2>
                <FormItem.Input
                    id="name"
                    label={Locale.trans('boat.name')}
                    form={this.props.form}
                    validateStatus={this.getValidateStatus('name')}
                    help={this.getHelp('name')}
                    hasFeedback
                    onChange={() => {
                        form.setFieldsValue({
                            boat_id: null,
                        });
                    }}
                />
                <FormItem.Input
                    id="immatriculation"
                    label={Locale.trans('boat.immatriculation')}
                    form={this.props.form}
                />
                {this.renderBoatTypeField()}
                {this.renderPlaceField()}
                <FormItem.Input
                    id="length"
                    label={Locale.trans('boat.length')}
                    form={this.props.form}
                />
                <FormItem.Input
                    id="width"
                    label={Locale.trans('boat.width')}
                    form={this.props.form}
                />

                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }

    renderBoatTypeField() {
        const boatTypes = ['motorboat', 'sailboat', 'multihull'];
        return (
            <FormItem.Select
                id="boatType"
                showSearch
                label={Locale.trans('boat.boatType')}
                options={boatTypes.map((c) => ({
                    value: c,
                    label: Locale.trans(c),
                }))}
                form={this.props.form}
                validateStatus={this.getValidateStatus('boatType')}
                help={this.getHelp('boatType')}
                hasFeedback
            />
        );
    }
    renderPlaceField() {
        const { places } = this.props;
        return (
            <FormItem.Select
                id="place_id"
                showSearch
                allowClear
                label={Locale.trans('boat.place')}
                initialValue={null}
                options={places
                    .filter((p) => !!p)
                    .map((c) => ({ value: c.id, label: c.code }))}
                form={this.props.form}
                validateStatus={this.getValidateStatus('place_id')}
                help={this.getHelp('place_id')}
                hasFeedback
            />
        );
    }
    renderBoatField() {
        const { boats, form } = this.props;
        return (
            <FormItem.Select
                id="boat_id"
                showSearch
                allowClear
                label={Locale.trans('boat')}
                initialValue={null}
                options={boats
                    .filter((b) => !!b)
                    .map((b) => ({ value: b.id, label: b.name }))}
                form={form}
                validateStatus={this.getValidateStatus('boat_id')}
                help={this.getHelp('boat_id')}
                hasFeedback
                onChange={() => {
                    form.setFieldsValue({
                        name: null,
                        immatriculation: null,
                        place_id: null,
                        boatType: null,
                        length: null,
                        width: null,
                    });
                }}
            />
        );
    }

    renderRoleField() {
        const options = Object.values(RoleConstants)
            .filter((v) => v !== RoleConstants.ANY)
            .map((o) => ({
                value: o,
                label: Locale.trans(`role.${o}`),
            }));

        const { getFieldValue } = this.props.form;
        return (
            <FormItem.Select
                id="roles"
                required
                label={Locale.trans('user.roles')}
                initialValue={getFieldValue('roles')}
                options={options}
                form={this.props.form}
                multiple
            />
        );
    }
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(ApproveForm);



import ActionsBase from 'actions/ActionsBase';
import dispatcher from 'dispatchers/AppDispatcher';
import SidebarConstants from 'constants/SidebarConstants';


class SidebarActions extends ActionsBase {
    collpase = (collapsed: boolean) => {
        localStorage.setItem('sidebar-collapsed', collapsed.toString());
        dispatcher.dispatch({
            type: SidebarConstants.COLLAPSED,
            payload: {},
        });
    };
}

export default new SidebarActions();
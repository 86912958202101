import BaseStore from 'stores/BaseStore';
import ReportingConstants from 'constants/ReportingConstants';
import HarbourStore from 'stores/HarbourStore';


class OutingByBoatTypeReportingStore extends BaseStore {
    _entities: { [harbour_id: number]: any };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case ReportingConstants.OUTINGS_BY_BOAT_TYPE_RECEIVED:
            this._receiveOutings(payload.harbour, payload.outings);
            break;
        default:
            // Do Nothing
            break;
        }
    };
    _receiveOutings = (harbour: IHarbour, outings: any) => {
        if (typeof this._entities[harbour.id] === 'undefined') {
            this._entities[harbour.id] = {};
        }
        this._entities[harbour.id] = this._formatOutings(outings);
        this.emitChange();
    };

    _formatOutings(outings: any) {
        const outingsToStore = {} as any;
        let comparedValues = Object.values(outings.compared.boatTypesValues);
        outingsToStore.boatTypesValues = Object.values(outings.main.boatTypesValues)
            .map((d: any) => {
                const element = comparedValues.find((el: any) => el.type === d.type) as any;
                d.comparedValue = element.value;
                return d;
            });
        comparedValues = Object.values(outings.compared.categoryValues);
        outingsToStore.categoryValues = Object.values(outings.main.categoryValues)
            .map((d: any) => {
                const element = comparedValues.find((el: any) => el.category === d.category) as any;
                if (element !== undefined) {
                    d.comparedValue = element.value;
                } else {
                    d.comparedValue = 0;
                }
                return d;
            });
        return outingsToStore;
    }


    getOutings(harbour?: IHarbour) {
        const id = harbour ? harbour.id : HarbourStore.getCurrentHarbourId();
        return (id && this._entities[id]) || [];
    }
}

export default new OutingByBoatTypeReportingStore();

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class ZoneService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all zones of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/zones`,
            method: 'GET',
        });
    }

    /**
     * Get a zone by unique identifier.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} zoneId The identifier of the zone.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    getById(harbourId: number, zoneId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/zones/${zoneId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new zone to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The zone to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/zones`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing zone.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} zoneId The identifier of the zone.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, zoneId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/zones/${zoneId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} zoneId The identifier of the zone.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, zoneId: number, patch: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/zones/${zoneId}`,
            method: 'PATCH',
            data: patch,
        });
    }
}

export default new ZoneService();

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class AbsenceService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all absences of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number, absenceYear?: String) {
        const maybeYear = absenceYear ? `year=${absenceYear}` : '';
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/absences?${maybeYear}`,
            method: 'GET',
        });
    }

    /**
     * Get an absence by unique identifier.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} absenceId The identifier of the absence.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    getById(harbourId: number, absenceId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/absences/${absenceId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new absence to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The absence to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/absences`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing absence.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} absenceId The identifier of the absence.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, absenceId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/absences/${absenceId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} absenceId The identifier of the boat.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, absenceId: number, patch: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/absences/${absenceId}`,
            method: 'PATCH',
            data: patch,
        });
    }

}

export default new AbsenceService();

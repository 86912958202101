import React from 'react';
import { Modal } from 'antd';

import BoatForm from 'components/forms/BoatForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import BoatActions from 'actions/BoatActions';
import BoatStore from 'stores/BoatStore';

import PlaceActions from 'actions/PlaceActions';
import PlaceStore from 'stores/PlaceStore';

import UserActions from 'actions/UserActions';
import UserStore from 'stores/UserStore';
import DeleteButton from 'components/forms/DeleteButton';
import LoginStore from 'stores/LoginStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to edit a boat.
 */
export default class EditBoatModal extends React.Component {
    newBoat = null;
    totalActions = 0;
    completedActions = 0;

    constructor(props) {
        super();
        const places = PlaceStore.getAvailableByHarbour(
            HarbourStore.getCurrentHarbour(),
        );
        if (props.boat && props.boat.place) {
            places.unshift(PlaceStore.getById(props.boat.place.id));
        }
        this.state = {
            loading: false,
            loadingDelete: false,
            fields: this.getFieldsFromBoat(props.boat),
            places,
            users: UserStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        };
    }

    componentDidMount() {
        BoatStore.addChangeListener(this.receivePlaces);
        PlaceStore.addChangeListener(this.receivePlaces);
        PlaceActions.reloadByHarbour(HarbourStore.getCurrentHarbour());

        UserStore.addChangeListener(this.receiveUsers);
        UserActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    }
    componentWillUnmount() {
        BoatStore.removeChangeListener(this.receivePlaces);
        PlaceStore.removeChangeListener(this.receivePlaces);
        UserStore.removeChangeListener(this.receiveUsers);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            const { boat } = nextProps;
            const places = PlaceStore.getAvailableByHarbour(
                HarbourStore.getCurrentHarbour(),
            );
            if (boat && boat.place) {
                places.unshift(PlaceStore.getById(boat.place.id));
            }

            this.setState({
                fields: this.getFieldsFromBoat(nextProps.boat),
                places,
            });
        }
    }

    getFieldsFromBoat = (boat) => {
        if (!boat) {
            return {};
        }
        const fields = {};
        const keys = Object.keys(boat);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            fields[k] = { value: boat[k] };
        }
        return fields;
    };

    receivePlaces = () => {
        const { boat } = this.props;
        const places = PlaceStore.getAvailableByHarbour(
            HarbourStore.getCurrentHarbour(),
        );
        if (boat && boat.place) {
            places.unshift(PlaceStore.getById(boat.place.id));
        }
        this.setState({
            places,
        });
    };

    receiveUsers = () => {
        const users = UserStore.getByHarbour(HarbourStore.getCurrentHarbour());
        this.setState({
            users,
        });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (boat, owners) => {
        this.newBoat = null;
        this.totalActions = 0;
        this.completedActions = 0;

        if (!this.props.boat) {
            return null;
        }
        const boatId = this.props.boat.id;

        this.setState({
            loading: true,
        });
        BoatActions.edit(HarbourStore.getCurrentHarbour(), boatId, boat)
            .then((newBoat) => {
                this.newBoat = newBoat;

                owners.created.map((o) => {
                    return this._addOwner(boatId, o);
                });
                owners.deleted.map((o) => {
                    return this._deleteOwner(boatId, o);
                });

                this.tryToComplete();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                    loadingDelete: false,
                });
            });
    };

    // Owner
    _addOwner = (boatId, owner) => {
        this.totalActions++;
        BoatActions.addOwner(HarbourStore.getCurrentHarbour(), boatId, owner.id)
            .then(() => {
                this.completedActions++;
                this.tryToComplete();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                    loadingDelete: false,
                });
            });
    };

    _deleteOwner = (boatId, owner) => {
        this.totalActions++;
        BoatActions.removeOwner(
            HarbourStore.getCurrentHarbour(),
            boatId,
            owner.id,
        )
            .then(() => {
                this.completedActions++;
                this.tryToComplete();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                    loadingDelete: false,
                });
            });
    };

    /**
     * Check if all the things that have to be created are done. (ex: Phones, Mail, etc.)
     * If yes, we complete the process with toast to the user and redirection to Edit page.
     */
    tryToComplete = () => {
        if (!this.newBoat || this.completedActions < this.totalActions) {
            return;
        }

        this.setState({
            fields: {},
            loading: false,
        });
        ToastActions.createToastSuccess(
            Locale.trans('boat.update.success', { name: this.newBoat.name }),
        );
        this.props.onSave(this.newBoat);
        this.props.onCancel(this.newBoat);
    };

    deleteBoat = () => {
        const { boat, onCancel } = this.props;

        const boatId = boat.id;

        this.setState({
            loadingDelete: true,
        });
        BoatActions.delete(HarbourStore.getCurrentHarbour(), boatId)
            .then(() => {
                ToastActions.createToastSuccess(
                    Locale.trans('boat.delete.success'),
                );
                onCancel();
            })
            .catch(() => {
                ToastActions.createToastError(
                    Locale.trans('boat.delete.error'),
                );
            })
            .finally(() => {
                this.setState({
                    loading: false,
                    loadingDelete: false,
                });
            });
    };

    render() {
        const { boat, visible, onCancel, } = this.props;
        const { places, users, fields, loading, loadingDelete } = this.state;
        const loggedUser = LoginStore.getUser();
        return (
            <Modal
                title={Locale.trans('boat.update.title')}
                visible={boat && visible}
                onCancel={onCancel}
                footer={null}
            >
                <BoatForm
                    boat={boat}
                    places={places}
                    users={users}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                />
                {loggedUser.isSuperAdmin && (
                    <DeleteButton
                        onDelete={this.deleteBoat}
                        loading={loadingDelete}
                        style={{ width: '100%' }}
                    />
                )}
            </Modal>
        );
    }
}

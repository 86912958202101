import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import AbsenceConstants from 'constants/AbsenceConstants';
import AbsenceService from 'services/AbsenceService';

import CacheManager from 'services/CacheManager';

const CACHE_DURATION = 30 * 1000;

const RELOAD_INTERVAL = 30 * 1000;
const intervalIds = {} as {[cacheKey: string]: any};
class AbsenceActions extends ActionsBase {
    create = (harbour: IHarbour, absence: Object): Promise<any> => {
        const $this = this;
        return new Promise((resolve: (absence: IAbsence) => void, reject: (response: Object) => void): void => {
            function handleSuccess(newAbsence: IAbsence): void {
                $this.reloadByHarbour(harbour, true);

                dispatcher.dispatch({
                    type: AbsenceConstants.RECEIVE_ABSENCE_OF_HARBOUR,
                    payload: {
                        harbour,
                        absence: newAbsence,
                    },
                });
                resolve(newAbsence);
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            AbsenceService.post(harbour.id, absence)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (harbour: Entity, absenceId: number, absence: Object): Promise<any> => {
        const $this = this;
        return new Promise((resolve: (absence: IAbsence) => void, reject: (response: Object) => void): void => {
            function handleSuccess(newAbsence: IAbsence): void {
                $this.reloadByHarbour(harbour, true);
                
                dispatcher.dispatch({
                    type: AbsenceConstants.RECEIVE_ABSENCE_OF_HARBOUR,
                    payload: {
                        harbour,
                        absence: newAbsence,
                    },
                });
                resolve(newAbsence);
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            AbsenceService.patch(harbour.id, absenceId, absence)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    delete = (harbour: Entity, id: number): Promise<any> => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            function handleSuccess(): void {
                dispatcher.dispatch({
                    type: AbsenceConstants.DELETE_ABSENCE_OF_HARBOUR,
                    payload: {
                        harbour,
                        id,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            AbsenceService.remove(harbour.id, id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByHarbour = (harbour: Entity, force?: boolean, year?: string): Promise<any> => {
        if (harbour && !intervalIds[harbour.id]) {
            intervalIds[harbour.id] = setInterval(() => {
                this.reloadByHarbour(harbour, force, year).catch(function(){
                    clearInterval(intervalIds[harbour.id]);
                    intervalIds[harbour.id] = null;
                });
            }, RELOAD_INTERVAL);
        }

        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            if (
                !harbour ||
                (!force && !year && 
                CacheManager.isCached(
                    `Absence:reloadByHarbour${year ? ':' + year : ''}`,
                    harbour.id.toString(),
                    CACHE_DURATION,
                ))
            ) {
                resolve();
                return;
            }

            function handleSuccess(absences: Array<IAbsence>): void {
                dispatcher.dispatch({
                    type: AbsenceConstants.RECEIVE_ABSENCES_OF_HARBOUR,
                    payload: {
                        harbour,
                        absences,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }
            AbsenceService.getByHarbour(harbour.id, year)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

export default new AbsenceActions();

import HarbourMapPositionConstants from 'constants/HarbourMapPositionConstants';
import EntityStoreBase from './EntityStoreBase';
import HarbourStore from './HarbourStore';

class HarbourMapPositionStore extends EntityStoreBase<IHarbourMapPosition> {
    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = [];
    }

    _registerToActions = (action: { type: string; payload: any }) => {
        const { type, payload } = action;
        switch (type) {
            case HarbourMapPositionConstants.RECEIVE_MAP_POSITION:
                this._receiveEntity(payload.position);
                break;
            case HarbourMapPositionConstants.RECEIVE_MAP_POSITIONS:
                this._receiveSomeEntities(payload.positions);
                break;
            case HarbourMapPositionConstants.DELETE_MAP_POSITION:
                this._deleteEntity(payload.id);
                break;
            default:
                // Do Nothing
                break;
        }
    };

    clear = () => {
        this._entities = [];
        this.emitChange();
    };

    // /// Public Methods  /////

    getForCurrentHarbour = () =>
        this.getByHarbour(HarbourStore.getCurrentHarbour());
    /**
     * Get the map positions of a harbour.
     */
    getByHarbour = (harbour: IHarbour | null | undefined) => {
        return harbour ? this._entities.filter(e => e.harbour.id === harbour.id) : [];
    }
}

export default new HarbourMapPositionStore();

import React from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';

import DateService from 'services/utils/DateService';

class AnticipatedReturnForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const absence = this.getEntityFromValues(values);
                this.props.onSubmit(absence);
            }
        });
    };

    getEntityFromValues = (values) => {
        const boat = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.map((k) => {
            if (k.endsWith('_ids')) {
                return null;
            }
            if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                boat[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                boat[k] = values[k];
            }
            return null;
        });
        return boat;
    };

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                {this.renderAbsenceField()}
                <FormItem.DatePicker
                    id="anticipatedReturnDate"
                    required
                    label={Locale.trans('absence.anticipatedReturnDate')}
                    form={this.props.form}
                    validateStatus={this.getValidateStatus('anticipatedReturnDate')}
                    help={this.getHelp('anticipatedReturnDate')}
                    hasFeedback
                />
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={this.props.loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }
    renderAbsenceField() {
        const { absences, boats } = this.props;
        return (
            <FormItem.Select
                id="absence_id"
                showSearch
                label={Locale.trans('absence.absence')}
                options={absences
                    .filter(a => a.returnDate > DateService.formatApi(new Date()))
                    .map((a) => {
                        const boat = boats.find(b => b.id === a.boat.id);
                        if (!boat) {
                            return null;
                        }
                        return {
                            value: a.id,
                            label: `${boat.name} ${
                                boat.owners.length
                                    ? `(${boat.owners[0].firstName} ${boat.owners[0].lastName})`
                                    : ''
                            } : Du ${DateService.formatApiToDisplay(a.departureDate)} au ${DateService.formatApiToDisplay(a.returnDate)}`,
                        };
                    })
                    .filter(o => !!o)}
                form={this.props.form}
                validateStatus={this.getValidateStatus('boat_id')}
                help={this.getHelp('boat_id')}
                hasFeedback
            />
        );
    }
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(AnticipatedReturnForm);

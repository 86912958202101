import React from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';

class PlaceZoneForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        const { form, places } = this.props;

        e.preventDefault();
        this.isFormSubmited(true);
        form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const place = places.find(p => p.id.toString() === values.place_id);
                this.props.onSubmit({}, place);
            }
        });
    };

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                {this.renderPlaceField()}
                <Button key="back" onClick={() => this.props.onBack()}>
                    {Locale.trans('back')}
                </Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.props.loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }
    renderPlaceField() {
        const { places } = this.props;
        const { getFieldValue } = this.props.form;
        return (
            <FormItem.Select
                id="place_id"
                required
                showSearch
                label={Locale.trans('boat.place')}
                initialValue={getFieldValue('place') ? getFieldValue('place').id.toString() : null}
                options={places.map(c => ({ value: c.id, label: c.code }))}
                form={this.props.form}
                validateStatus={this.getValidateStatus('place_id')}
                help={this.getHelp('place_id')}
                hasFeedback
            />
        );
    }
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(PlaceZoneForm);

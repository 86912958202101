import React from 'react';

import { Table, Button } from 'antd/lib/index';
import TableColumnFilter from 'components/table/TableColumnFilter.jsx';
import FilterIcon from 'components/table/FilterIcon.jsx';
import CreateNotificationUserListModal from 'pages/Configuration/CreateNotificationUserListModal.jsx';
import EditNotificationUserListModal from 'pages/Configuration/EditNotificationUserListModal.jsx';
import LoadingIcon from 'components/LoadingIcon.jsx';

import Locale from 'locale/LocaleFactory';
import ArrayService from 'services/utils/ArrayService';
import StringService from 'services/utils/StringService';
import FilterService from 'services/utils/FilterService';

import NotificationUserListActions from 'actions/NotificationUserListActions';
import NotificationUserListStore from 'stores/NotificationUserListStore';
import HarbourStore from 'stores/HarbourStore';

// Sorting Methods
function sortNameColumn(p1, p2) {
    return StringService.compareCaseInsensitive(p1.name, p2.name);
}

/**
 * The list of the notification user lists of a harbour
 */
export default class NotificationUserListConfiguration extends React.Component {
    constructor() {
        super();

        const lists = NotificationUserListStore.getByHarbour(
            HarbourStore.getCurrentHarbour(),
        );

        this.state = {
            loading: !lists.length,
            lists,

            filteredNotificationUserLists: [],

            filterGlobal: '',
            filters: {
                name: [],
            },

            createNotificationUserListVisible: false,
            editNotificationUserListVisible: false,
            listToEdit: null,
        };
    }

    componentDidMount() {
        HarbourStore.addCurrentHarbourChangeListener(
            this.onChangeCurrentHarbour,
        );
        NotificationUserListStore.addChangeListener(
            this.receiveNotificationUserLists,
        );

        this.reloadData();

        this.updateFilters();
        // Here we set the default sorted column
        // Temporary solution waiting for AntD to propose a native way to do it.
        const column = this.table.findColumn('reference');
        this.table.toggleSortOrder('descend', column);
    }
    componentWillUnmount() {
        HarbourStore.removeCurrentHarbourChangeListener(
            this.onChangeCurrentHarbour,
        );
        NotificationUserListStore.removeChangeListener(
            this.receiveNotificationUserLists,
        );
    }

    reloadData = () => {
        this.loadNotificationUserLists();
    };

    onChangeCurrentHarbour = () => {
        this.reloadData();
        this.receiveNotificationUserLists();
    };

    loadNotificationUserLists = () => {
        this.setState({
            loading: true,
        });
        NotificationUserListActions.reloadByHarbour(
            HarbourStore.getCurrentHarbour(),
        ).then(() => {
            this.setState({
                loading: false,
            });
        });
    };

    receiveNotificationUserLists = () => {
        this.setState({
            lists: NotificationUserListStore.getByHarbour(
                HarbourStore.getCurrentHarbour(),
            ),
        });
        this.updateFilters();
    };

    // Filters
    updateFilters = () => {
        const { lists } = this.state;
        const filteredNotificationUserLists = lists.filter(
            this.listMatchFilters,
        );
        this.setState({ filteredNotificationUserLists });
    };

    listMatchFilters = (p) => {
        const { filters } = this.state;
        return FilterService.matchFilter(filters.name, p.name);
    };

    handleFilterChange = (name, values) => {
        const newFilters = { ...this.state.filters };
        newFilters[name] = values;
        this.setState(
            {
                filters: newFilters,
            },
            this.updateFilters,
        );
    };

    getNotificationUserListNames = () =>
        ArrayService.unique(this.state.lists.map((p) => p.name));

    showCreateNotificationUserListModal = () => {
        this.setState({
            createNotificationUserListVisible: true,
        });
    };
    hideCreateNotificationUserListModal = () => {
        this.setState({
            createNotificationUserListVisible: false,
        });
    };

    editNotificationUserList = (list) => {
        this.setState({
            editNotificationUserListVisible: true,
            listToEdit: list,
        });
    };
    hideEditNotificationUserListModal = () => {
        this.setState({
            editNotificationUserListVisible: false,
            listToEdit: null,
        });
    };

    render() {
        const { loading, filteredNotificationUserLists } = this.state;
        return (
            <div className="notification-user-list-list">
                {this.renderNotificationUserListTable()}
                {!loading && (
                    <div
                        className="actions-row"
                        style={{
                            marginTop:
                                filteredNotificationUserLists.length > 0
                                    ? '-50px'
                                    : 0,
                        }}
                    >
                        <Button
                            type="primary"
                            icon="plus"
                            onClick={this.showCreateNotificationUserListModal}
                        >
                            {Locale.trans(
                                'notification-user-list.creation.title',
                            )}
                        </Button>
                    </div>
                )}

                <CreateNotificationUserListModal
                    onCancel={this.hideCreateNotificationUserListModal}
                    visible={this.state.createNotificationUserListVisible}
                />
                <EditNotificationUserListModal
                    list={this.state.listToEdit}
                    onCancel={this.hideEditNotificationUserListModal}
                    visible={this.state.editNotificationUserListVisible}
                />
            </div>
        );
    }

    renderNotificationUserListTable() {
        const { filters, filteredNotificationUserLists } = this.state;

        const columns = [
            {
                title: Locale.trans('notification-user-list.name'),
                dataIndex: 'name',
                key: 'name',
                sorter: sortNameColumn,
                filterIcon: <FilterIcon active={filters.name.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="name"
                        selectedValues={filters.name}
                        values={this.getNotificationUserListNames().map(
                            (r) => ({
                                text: r,
                                value: r,
                            }),
                        )}
                        onChange={this.handleFilterChange}
                    />
                ),
            },
            {
                title: Locale.trans('notification-user-list.users'),
                key: 'users',
                render: ({ users }) => users && users.length,

                width: '100px',
            },
        ];
        return (
            <Table
                dataSource={filteredNotificationUserLists}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
                ref={(r) => {
                    this.table = r;
                }}
                loading={this.state.loading && { indicator: <LoadingIcon /> }}
                onRow={(a) => ({
                    className: 'clickable',
                    onClick: () => this.editNotificationUserList(a),
                })}
            />
        );
    }
}

import BaseStore from '../stores/BaseStore';

class CameraStore extends BaseStore {
    _entities: { [harbour_id: number]: ICamera[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case 'Camera:receiveByHarbour':
            this._receiveByHarbour(payload.harbour, payload.cameras);
            break;
        case 'Camera:receiveOneByHarbour':
            this._receiveOneByHarbour(payload.harbour, payload.camera);
            break;
        case 'Camera:deleteOneByHarbour':
            this._deleteOneByHarbour(payload.harbour, payload.id);
            break;

        default:
            // Do Nothing
            break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, cameras: ICamera[]) => {
        this._entities[harbour.id] = cameras;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, camera: ICamera) => {
        const cameras = this._entities[harbour.id];
        if (!cameras) {
            this._entities[harbour.id] = [camera];
        } else {
            const i = cameras.findIndex(c => c.id === camera.id);
            if (i > -1) {
                cameras[i] = camera;
            } else {
                cameras.push(camera);
            }
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, cameraId: number) => {
        const cameras = this._entities[harbour.id];
        if (!cameras) {
            return;
        }

        const i = cameras.findIndex(c => c.id === cameraId);
        if (i > -1) {
            cameras.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    /**
     * Get all the places of a harbour.
     */
    getByHarbour = (harbour: IHarbour): ICamera[] =>
        (harbour ? this._entities[harbour.id] || [] : []);

    getById = (id: number): ICamera | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const boats = this._entities[harbourId] || [];
            const boat = boats.find(c => c.id.toString() === id.toString());
            if (boat) {
                return boat;
            }
        }
        return null;
    };
}

export default new CameraStore();

import BaseStore from 'stores/BaseStore';
import ReportingConstants from 'constants/ReportingConstants';
import HarbourStore from 'stores/HarbourStore';


class OutingByDayReportingStore extends BaseStore {
    _entities: { [harbour_id: number]: any };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case ReportingConstants.OUTINGS_BY_DAY_RECEIVED:
            this._receiveOutings(payload.harbour, payload.outings);
            break;
        default:
            // Do Nothing
            break;
        }
    };

    _receiveOutings = (harbour: IHarbour, outings: any) => {
        if (typeof this._entities[harbour.id] === 'undefined') {
            this._entities[harbour.id] = {};
        }
        this._entities[harbour.id] = outings;
        this.emitChange();
    };


    getOutings(harbour?: IHarbour) {
        harbour = harbour || HarbourStore.getCurrentHarbour();
        if(!harbour || !this._entities[harbour.id]) {
            return [];
        }
        return [...this._entities[harbour.id]];
    }
}

export default new OutingByDayReportingStore();

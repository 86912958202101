import dispatcher from '../dispatchers/AppDispatcher';
import ActionsBase from '../actions/ActionsBase';

import CommitmentService from '../services/CommitmentService';

import CacheManager from '../services/CacheManager';

const CACHE_DURATION = 30 * 1000;

const RELOAD_INTERVAL = 5 * 1000;
const intervalIds = {} as {[harbourId: number]: any};

class CommitmentActions extends ActionsBase {
  create = (harbour: IHarbour, userType: string) => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(newCommitment: ICommitment) {
        dispatcher.dispatch({
          type: 'Commitment:receiveOneByHarbour',
          payload: {
            harbour,
            commitment: newCommitment,
          },
        });
        resolve(newCommitment);
      }

      function handleError(err: any) {
        $this.handleError(err, reject);
      }

      CommitmentService.post(harbour.id, userType)
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  stop = (harbour: IHarbour, commitmentId: number) => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(newCommitment: ICommitment) {
        dispatcher.dispatch({
          type: 'Commitment:receiveOneByHarbour',
          payload: {
            harbour,
            commitment: newCommitment,
          },
        });
        resolve(newCommitment);
      }

      function handleError(err: any) {
        $this.handleError(err, reject);
      }

      CommitmentService.stop(harbour.id, commitmentId)
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  reloadByHarbour = (harbour: IHarbour) => {
    const $this = this;
    return new Promise((resolve, reject) => {
      if (
        CacheManager.isCached(
          'Commitment:reloadByHarbour',
          harbour.id.toString(),
          CACHE_DURATION
        )
      ) {
        resolve();
        return;
      }

      function handleSuccess(commitments: ICommitment[]) {
        dispatcher.dispatch({
          type: 'Commitment:receiveAllByHarbour',
          payload: {
            harbour,
            commitments,
          },
        });
        resolve();
      }

      function handleError(err: any) {
        $this.handleError(err, reject);
      }

      CommitmentService.getByHarbour(harbour.id)
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  reloadCurrentByHarbour = (harbour: IHarbour) => {
    if (harbour && !intervalIds[harbour.id]) {
      intervalIds[harbour.id] = setInterval(() => {
        this.reloadCurrentByHarbour(harbour).catch(function() {
          clearInterval(intervalIds[harbour.id]);
          intervalIds[harbour.id] = null;
        });
      }, RELOAD_INTERVAL);
    }

    const $this = this;
    return new Promise((resolve, reject) => {
      if (
        CacheManager.isCached(
          'Commitment:reloadCurrentByHarbour',
          harbour.id.toString(),
          RELOAD_INTERVAL
        )
      ) {
        resolve();
        return;
      }

      function handleSuccess(commitments: ICommitment[]) {
        dispatcher.dispatch({
          type: 'Commitment:receiveCurrentByHarbour',
          payload: {
            harbour,
            commitments,
          },
        });
        resolve();
      }

      function handleError(err: any) {
        $this.handleError(err, reject);
      }

      CommitmentService.getCurrentByHarbour(harbour.id)
        .then(handleSuccess)
        .catch(handleError);
    });
  };
}

export default new CommitmentActions();

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class PartnerService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all partners of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/partners`,
            method: 'GET',
        });
    }

    /**
     * Get a partner by unique identifier.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} partnerId The identifier of the partner.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    getById(harbourId: number, partnerId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/partners/${partnerId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new partner to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The partner to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/partners`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing partner.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} partnerId The identifier of the partner.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, partnerId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/partners/${partnerId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} partnerId The identifier of the partner.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, partnerId: number, patch: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/partners/${partnerId}`,
            method: 'PATCH',
            data: patch,
        });
    }

    getPictures(harbourId: number, partnerId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/partners/${partnerId}/pictures`,
            method: 'GET',
        });
    }

    deletePicture(harbourId: number, partnerId: number, pictureId: string) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/partners/${partnerId}/pictures/${pictureId}`,
            method: 'DELETE',
        });
    }
}

export default new PartnerService();

import React, { useContext, useState } from 'react';
import moment from 'moment';
import Panel from 'components/Panel';
import Locale from 'locale/LocaleFactory';
import { Dropdown, Button, Icon } from 'antd';
import useStore from 'hooks/useStore';
import NotificationStore from 'stores/NotificationStore';
import ToastActions from 'actions/ToastActions';

import CommunicationContext from './CommunicationContext';
import NotificationIcon from './NotificationIcon';
import NotificationThumbnail from './NotificationThumbnail';
import NotificationDetailsModal from './NotificationDetailsModal';
import StringService from 'services/utils/StringService';
import DeleteButton from 'components/forms/DeleteButton';

export default function Notifications() {
    const [notificationToShow, setNotificationToShow] = useState(null);
    const { setInitialNotificationFields, setNotificationIdToEdit } = useContext(
        CommunicationContext.Context,
    );
    const [history] = useStore({
        ...NotificationStore,
    });

    const duplicate = ({ description, title, type, url }) => {
        setInitialNotificationFields({ description, title, type, url });
        setNotificationIdToEdit(undefined);
    };

    const edit = (notification) => {
        setInitialNotificationFields({ });
        setNotificationIdToEdit(notification.id);
    };
    return (
        <Panel
            title={Locale.trans('communication.notification')}
            className="notifications"
        >
            <ul className="notifications__history">
                {history.reverse().map((item) => {
                    const menu = (
                        <div className="notification__menu">
                            <Button onClick={() => duplicate(item)}>
                                {Locale.trans(
                                    'communication.notification.duplicate',
                                )}
                            </Button>
                            <Button onClick={() => edit(item)}>
                                {Locale.trans(
                                    'communication.notification.edit.title',
                                )}
                            </Button>
                            <DeleteButton
                                onDelete={() =>
                                    NotificationStore.deleteNotification(
                                        item.id,
                                    ).then(() =>
                                        ToastActions.createToastSuccess(
                                            Locale.trans(
                                                'communication.notification.delete.success',
                                            ),
                                        ),
                                    )
                                }
                            />
                        </div>
                    );
                    return (
                        <li key={item.id} className="notification">
                            {item.type === 'news' ? (
                                <NotificationThumbnail notification={item} />
                            ) : (
                                <div className="__icon">
                                    <NotificationIcon
                                        notification={item}
                                        size={3}
                                    />
                                </div>
                            )}

                            <article className="notification__content">
                                <header className="notification__title">
                                    {item.title}
                                </header>
                                <p className="notification__text">
                                    {StringService.nl2br(item.description)}
                                </p>
                                <Button
                                    className="__details-btn"
                                    onClick={() => setNotificationToShow(item)}
                                >
                                    {Locale.trans(
                                        'communication.notification.showDetails',
                                    )}
                                    <Icon type="right" />
                                </Button>
                            </article>
                            <span className="notification__date">
                                {`${Locale.trans(
                                    'communication.notification.sentAt',
                                )} ${moment(item.sentAt).calendar()}`}
                            </span>
                            <Dropdown overlay={menu}>
                                <span className="notification__options">
                                    <Icon type="ellipsis" />
                                </span>
                            </Dropdown>
                        </li>
                    );
                })}
            </ul>
            <NotificationDetailsModal
                visible={!!notificationToShow}
                onClose={() => setNotificationToShow(null)}
                notification={notificationToShow}
            />
        </Panel>
    );
}

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL = `${BaseUrlConstants.BASE_URL}`;

class CommitmentService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all commitments of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/commitments`,
            method: 'GET',
        });
    }

     /**
     * Get current commitments of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getCurrentByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/commitments/current`,
            method: 'GET',
        });
    }

    /**
     * Post a new commitment to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} userType The type of user (e.g. agent or supervisor)
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    //@ts-ignore
    post(harbourId: number, userType: string) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/commitments`,
            method: 'POST',
            data: {
                userType
            },
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} commitmentId The identifier of the camera.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    stop(harbourId: number, commitmentId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/commitments/${commitmentId}/stop`,
            method: 'PATCH',
        });
    }
}

export default new CommitmentService();

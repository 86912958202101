import React from 'react';
import { Modal } from 'antd/lib/index';

import PlaceForm from 'components/forms/PlaceForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import PlaceActions from 'actions/PlaceActions';

import PontoonActions from 'actions/PontoonActions';
import PontoonStore from 'stores/PontoonStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to create a new place.
 */
export default class CreatePlaceModal extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {
                boatTypes: { value: [] },
            },
            pontoons: PontoonStore.getByHarbour(
                HarbourStore.getCurrentHarbour(),
            ),
        };
    }

    componentDidMount() {
        PontoonStore.addChangeListener(this.receivePontoons);
        PontoonActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    }
    componentWillUnmount() {
        PontoonStore.removeChangeListener(this.receivePontoons);
    }

    receivePontoons = () => {
        this.setState({
            pontoons: PontoonStore.getByHarbour(
                HarbourStore.getCurrentHarbour(),
            ),
        });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (place) => {
        this.setState({
            loading: true,
        });
        PlaceActions.create(HarbourStore.getCurrentHarbour(), place)
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('place.creation.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        return (
            <Modal
                title={Locale.trans('place.creation.title')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                footer={null}
            >
                <PlaceForm
                    onChange={this.handleFormChange}
                    fields={this.state.fields}
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                    pontoons={this.state.pontoons}
                />
            </Modal>
        );
    }
}

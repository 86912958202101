import { useEffect, useState } from 'react';
import * as WeatherStore from '../stores/WeatherStore';

export default function useIncidentsOfHarbour() {
    const [localWeather, setLocalWeather] = useState([]);

    useEffect(() => {
        function refresh() {
            setLocalWeather(WeatherStore.get());
        }

        refresh();
        WeatherStore.reload();

        const temp = WeatherStore.register(refresh);
        return () => {
            temp.remove();
        };
    }, []);

    return localWeather;
}

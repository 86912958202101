import BaseStore from '../stores/BaseStore';
import OutingConstants from '../constants/OutingConstants';
import /* moment, */ { Moment } from 'moment';
import DateService from '../services/utils/DateService';

class OutingStore extends BaseStore {
    _entities: { [harbour_id: number]: IOuting[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string; payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
            case OutingConstants.RECEIVE_OUTINGS_OF_HARBOUR:
                this._receiveByHarbour(payload.harbour, payload.outings);
                break;
            case OutingConstants.RECEIVE_OUTING_OF_HARBOUR:
                this._receiveOneByHarbour(payload.harbour, payload.outing);
                break;
            case OutingConstants.DELETE_OUTING_OF_HARBOUR:
                this._deleteOneByHarbour(payload.harbour, payload.id);
                break;

            default:
                // Do Nothing
                break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, outings: IOuting[]) => {
        this._entities[harbour.id] = outings;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, outing: IOuting) => {
        const outings = this._entities[harbour.id];
        if (!outings) {
            this._entities[harbour.id] = [outing];
        } else {
            const i = outings.findIndex((c) => c.id === outing.id);
            if (i > -1) {
                outings[i] = outing;
            } else {
                outings.push(outing);
            }
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, outingId: number) => {
        const outings = this._entities[harbour.id];
        if (!outings) {
            return;
        }

        const i = outings.findIndex((c) => c.id === outingId);
        if (i > -1) {
            outings.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    /**
     * Get all the places of a harbour.
     */
    getByHarbour = (
        harbour: IHarbour,
        startDate: Moment,
        endDate: Moment,
    ): IOuting[] => {
        let outings = harbour ? this._entities[harbour.id] || [] : [];

        if (startDate && endDate) {
            outings = outings.filter((o) => {
                const isOut = DateService.isBetween(
                    o.datetimeOut,
                    startDate.toDate(),
                    endDate.toDate(),
                );
                const isIn =
                    o.datetimeIn &&
                    DateService.isBetween(
                        o.datetimeIn,
                        startDate.toDate(),
                        endDate.toDate(),
                    );
                return isOut || isIn;
            });
        }
        return outings;
    };

    getOneByPlace = (place: IPlace): IOuting | null | undefined =>
        place
            ? (this._entities[place.harbour.id] || []).find(
                  (e) => e.place && e.place.id === place.id,
              )
            : null;

    getById = (id: number): IOuting | null | undefined => {
        const harbourIds = (Object.keys(this._entities) as unknown) as number[];
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = harbourIds[i];
            const outings = this._entities[harbourId] || [];
            const outing = outings.find(
                (c) => c.id.toString() === id.toString(),
            );
            if (outing) {
                return outing;
            }
        }
        return null;
    };
}

export default new OutingStore();

import BaseStore from '../stores/BaseStore';

class UrgencePhoneNumberStore extends BaseStore {
    _entities: { [harbour_id: number]: IBoat[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case 'UrgencePhoneNumber:receiveByHarbour':
            this._receiveByHarbour(payload.harbour, payload.phoneNumbers);
            break;
        case 'UrgencePhoneNumber:receiveOneByHarbour':
            this._receiveOneByHarbour(payload.harbour, payload.phoneNumber);
            break;
        case 'UrgencePhoneNumber:deleteOneByHarbour':
            this._deleteOneByHarbour(payload.harbour, payload.id);
            break;

        default:
            // Do Nothing
            break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, phoneNumbers: any[]) => {
        this._entities[harbour.id] = phoneNumbers;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, phoneNumber: any) => {
        const phoneNumbers = this._entities[harbour.id];
        if (!phoneNumbers) {
            this._entities[harbour.id] = [phoneNumber];
        } else {
            const i = phoneNumbers.findIndex(c => c.id === phoneNumber.id);
            if (i > -1) {
                phoneNumbers[i] = phoneNumber;
            } else {
                phoneNumbers.push(phoneNumber);
            }
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, phoneNumberId: number) => {
        const phoneNumbers = this._entities[harbour.id];
        if (!phoneNumbers) {
            return;
        }

        const i = phoneNumbers.findIndex(c => c.id === phoneNumberId);
        if (i > -1) {
            phoneNumbers.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    /**
     * Get all the places of a harbour.
     */
    getByHarbour = (harbour: IHarbour): IBoat[] =>
        (harbour ? this._entities[harbour.id] || [] : []);

    getById = (id: number): any | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const boats = this._entities[harbourId] || [];
            const boat = boats.find(c => c.id.toString() === id.toString());
            if (boat) {
                return boat;
            }
        }
        return null;
    };
}

export default new UrgencePhoneNumberStore();

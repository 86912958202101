import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class PontoonService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all pontoons of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/pontoons`,
            method: 'GET',
        });
    }

    /**
     * Get a pontoon by unique identifier.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} pontoonId The identifier of the pontoon.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    getById(harbourId: number, pontoonId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/pontoons/${pontoonId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new pontoon to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The pontoon to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/pontoons`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing pontoon.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} pontoonId The identifier of the pontoon.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, pontoonId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/pontoons/${pontoonId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} pontoonId The identifier of the pontoon.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, pontoonId: number, patch: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/pontoons/${pontoonId}`,
            method: 'PATCH',
            data: patch,
        });
    }
}

export default new PontoonService();

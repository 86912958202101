import React from 'react';

import { Table, Button } from 'antd/lib/index';
import TableColumnFilter from 'components/table/TableColumnFilter.jsx';
import FilterIcon from 'components/table/FilterIcon.jsx';
import CreatePontoonModal from 'pages/Configuration/CreatePontoonModal.jsx';
import EditPontoonModal from 'pages/Configuration/EditPontoonModal.jsx';
import LoadingIcon from 'components/LoadingIcon.jsx';

import Locale from 'locale/LocaleFactory';
import ArrayService from 'services/utils/ArrayService';
import StringService from 'services/utils/StringService';
import FilterService from 'services/utils/FilterService';

import PontoonActions from 'actions/PontoonActions';
import PontoonStore from 'stores/PontoonStore';
import HarbourStore from 'stores/HarbourStore';

// Sorting Methods
function sortNameColumn(p1, p2) {
    return StringService.compareCaseInsensitive(p1.name, p2.name);
}

/**
 * The list of the pontoons of a harbour
 */
export default class PontoonList extends React.Component {
    constructor() {
        super();

        const pontoons = PontoonStore.getByHarbour(
            HarbourStore.getCurrentHarbour(),
        );

        this.state = {
            loading: !pontoons.length,
            pontoons,

            filteredPontoons: [],

            filterGlobal: '',
            filters: {
                name: [],
            },

            createPontoonVisible: false,
            editPontoonVisible: false,
            pontoonToEdit: null,
        };
    }

    componentDidMount() {
        HarbourStore.addCurrentHarbourChangeListener(
            this.onChangeCurrentHarbour,
        );
        PontoonStore.addChangeListener(this.receivePontoons);

        this.reloadData();

        this.updateFilters();
        // Here we set the default sorted column
        // Temporary solution waiting for AntD to propose a native way to do it.
        const column = this.table.findColumn('reference');
        this.table.toggleSortOrder('descend', column);
    }
    componentWillUnmount() {
        HarbourStore.removeCurrentHarbourChangeListener(
            this.onChangeCurrentHarbour,
        );
        PontoonStore.removeChangeListener(this.receivePontoons);
    }

    reloadData = () => {
        this.loadPontoons();
    };

    onChangeCurrentHarbour = () => {
        this.reloadData();
        this.receivePontoons();
    };

    loadPontoons = () => {
        this.setState({
            loading: true,
        });
        PontoonActions.reloadByHarbour(HarbourStore.getCurrentHarbour()).then(
            () => {
                this.setState({
                    loading: false,
                });
            },
        );
    };

    receivePontoons = () => {
        const pontoons = PontoonStore.getByHarbour(
            HarbourStore.getCurrentHarbour(),
        );
        this.setState({
            pontoons,
        });
        this.updateFilters();
    };

    // Filters
    updateFilters = () => {
        const { pontoons } = this.state;
        const filteredPontoons = pontoons.filter(this.pontoonMatchFilters);
        this.setState({ filteredPontoons });
    };

    pontoonMatchFilters = (p) => {
        const { filters } = this.state;
        return FilterService.matchFilter(filters.name, p.name);
    };

    handleFilterChange = (name, values) => {
        const newFilters = { ...this.state.filters };
        newFilters[name] = values;
        this.setState(
            {
                filters: newFilters,
            },
            this.updateFilters,
        );
    };

    getPontoonNames = () =>
        ArrayService.unique(this.state.pontoons.map((p) => p.name));

    showCreatePontoonModal = () => {
        this.setState({
            createPontoonVisible: true,
        });
    };
    hideCreatePontoonModal = () => {
        this.setState({
            createPontoonVisible: false,
        });
    };

    editPontoon = (pontoon) => {
        this.setState({
            editPontoonVisible: true,
            pontoonToEdit: pontoon,
        });
    };
    hideEditPontoonModal = () => {
        this.setState({
            editPontoonVisible: false,
            pontoonToEdit: null,
        });
    };

    render() {
        const { loading, filteredPontoons } = this.state;
        return (
            <div className="pontoons-list">
                {this.renderPontoonTable()}
                {!loading && (
                    <div
                        className="actions-row"
                        style={{
                            marginTop:
                                filteredPontoons.length > 0 ? '-50px' : 0,
                        }}
                    >
                        <Button
                            type="primary"
                            icon="plus"
                            onClick={this.showCreatePontoonModal}
                        >
                            {Locale.trans('pontoon.creation.title')}
                        </Button>
                    </div>
                )}

                <CreatePontoonModal
                    onCancel={this.hideCreatePontoonModal}
                    visible={this.state.createPontoonVisible}
                />
                <EditPontoonModal
                    pontoon={this.state.pontoonToEdit}
                    onCancel={this.hideEditPontoonModal}
                    visible={this.state.editPontoonVisible}
                />
            </div>
        );
    }

    renderPontoonTable() {
        const { filters, filteredPontoons } = this.state;

        const columns = [
            {
                title: Locale.trans('pontoon.name'),
                dataIndex: 'name',
                key: 'name',
                sorter: sortNameColumn,
                filterIcon: <FilterIcon active={filters.name.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="name"
                        selectedValues={filters.name}
                        values={this.getPontoonNames().map((r) => ({
                            text: r,
                            value: r,
                        }))}
                        onChange={this.handleFilterChange}
                    />
                ),
            },
            {
                title: Locale.trans('pontoon.places'),
                key: 'places',
                render: ({ places }) => places && places.length,

                width: '100px',
            },
        ];
        return (
            <Table
                dataSource={filteredPontoons}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
                ref={(r) => {
                    this.table = r;
                }}
                loading={this.state.loading && { indicator: <LoadingIcon /> }}
                onRow={(a) => ({
                    className: 'clickable',
                    onClick: () => this.editPontoon(a),
                })}
            />
        );
    }
}

import BaseStore from 'stores/BaseStore';
import IncidentConstants from 'constants/IncidentConstants';
import HarbourStore from 'stores/HarbourStore';
import IncidentActions from 'actions/IncidentActions';

class IncidentConfigurationStore extends BaseStore {
    _entities: { [harbour_id: number]: IIncidentConfiguration[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
        HarbourStore.addChangeListener(() =>
            IncidentActions.reloadConfigurationByHarbour(
                HarbourStore.getCurrentHarbour(),
            ),
        );
    }

    _registerToActions = (action: { type: string; payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
            case IncidentConstants.RECEIVE_CONFIGURATION_OF_HARBOUR:
                this._receiveByHarbour(payload.harbour, payload.incidents);
                break;
            default:
                // Do Nothing
                break;
        }
    };

    _receiveByHarbour = (
        harbour: IHarbour,
        incidents: IIncidentConfiguration[],
    ) => {
        this._entities[harbour.id] = incidents;
        this.emitChange();
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    /**
     * Get all the incidents of a harbour.
     */
    getByHarbour = (harbour: IHarbour): IIncidentConfiguration[] =>
        harbour ? this._entities[harbour.id] || [] : [];

    getById = (id: number): IIncidentConfiguration | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const incidents = this._entities[harbourId] || [];
            const incident = incidents.find(
                c => c.id.toString() === id.toString(),
            );
            if (incident) {
                return incident;
            }
        }
        return null;
    };

    getCurrent = (): IIncidentConfiguration[] => {
        const id = HarbourStore.getCurrentHarbourId();
        return (id && this._entities[id]) || [];
    };
}

export default new IncidentConfigurationStore();

import React from 'react';
import { Modal } from 'antd/lib/index';

import NotificationUserListForm from 'components/forms/NotificationUserListForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import NotificationUserListActions from 'actions/NotificationUserListActions';

import UserActions from 'actions/UserActions';
import UserStore from 'stores/UserStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to edit a notification user list.
 */
export default class EditNotificationUserListModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loading: false,
            fields: this.getFieldsFromEntity(props.list),
            users: UserStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            this.setState({
                fields: this.getFieldsFromEntity(nextProps.list),
            });
        }
    }

    componentDidMount() {
        UserStore.addChangeListener(this.receiveUsers);
        UserActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    }
    componentWillUnmount() {
        UserStore.removeChangeListener(this.receiveUsers);
    }

    receiveUsers = () => {
        this.setState({
            users: UserStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        });
    };

    getFieldsFromEntity = (entity) => {
        if (!entity) {
            return {};
        }
        const fields = {};
        const keys = Object.keys(entity);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            fields[k] = { value: entity[k] };
        }
        return fields;
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (entity) => {
        if (!this.props.list) {
            return null;
        }
        const id = this.props.list.id;

        this.setState({
            loading: true,
        });
        NotificationUserListActions.edit(
            HarbourStore.getCurrentHarbour(),
            id,
            entity,
        )
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('notification-user-list.update.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        const { list, visible, onCancel } = this.props;
        const { fields, loading, users } = this.state;
        return (
            <Modal
                title={Locale.trans('notification-user-list.update.title')}
                visible={list && visible}
                onCancel={onCancel}
                footer={null}
            >
                <NotificationUserListForm
                    list={list}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                    users={users}
                />
            </Modal>
        );
    }
}

export default {
    // NauticSpot
    Trans: {
        ok: 'OK',
        beginning: 'beginning',
        cost: 'Cost',
        date: 'Date',
        day: 'day',
        week: 'week',
        month: 'month',
        days: 'days',
        description: 'Description',
        delete: 'delete',
        'delete.confirm': 'Are you sure to delete?',
        download: 'Download',
        end: 'End',
        edit: 'Edite',
        save: 'Save',
        saved: 'Saved',
        send: 'Send',
        cancel: 'Cancel',
        total: 'Total',
        notFound: 'Not found',
        emptyText: 'No data',
        back: 'Back',
        close: 'Close',
        map: 'Map',
        'map.stroke': 'Map stroke',
        search: 'Search',
        at: 'at',
        loading: 'Loading',

        mister: 'Mister',
        miss: 'Miss',

        facebook: 'Facebook',
        linkedin: 'Linkedin',
        twitter: 'Twitter',

        matin: 'Mrning',
        apresmidi: 'Afternoon',
        refused: 'Refused',
        approved: 'Approved',
        requested: 'Requested',
        accepted: 'Accepted',

        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',

        welcome: 'Welcome __USER__',

        locale: {
            fr: 'Français',
            en: 'English',
            es: 'Español',
            it: 'Italiano',
        },

        stats: 'Stats',
        drag_drop_invitation: 'Click or drag file here to upload.',

        pages: {
            home: 'Home',
            users: 'Yachtmen',
            calendar: 'Calendar',
            map: 'Map',
            rates: 'Rates',
            reporting: 'Reporting',
            profile: 'My profile',
            configuration: 'Configuration',
            security: 'Security',
            communication: 'Communication',
        },

        communication: {
            weather_upload: {
                success: 'The weather form is uploaded',
                failed: 'An error occured.',
            },
            notification: {
                _: 'Notifications - History',
                duplicate: 'Duplicate',
                edit: {
                    title: 'Edit',
                    success: 'Notification updated',
                },
                notify: 'Notify yachtmen',
                title: 'Input the notification title...',
                content: 'Input the notification content...',
                url: 'Add a link (option)',
                link: 'Link',
                selectCategory: 'Select a category',
                selectHarbour: 'Select a harbour',
                sentAt: 'Sent at',
                showDetails: 'Show details',
                details: 'Details',
                attachments: 'Attachment(s)',
                thumbnail: 'Insert an image',
                selectThumbnail: 'Select an image',
                recipients: {
                    _: 'Recipients',
                    all: 'All',
                    'user-lists': 'User lists',
                    pontoon: 'Pontoons',
                    users: 'Yachtsmen',
                },
                type: {
                    weather: 'Weather',
                    security: 'Security',
                    event: 'Event',
                    work: 'Work',
                    'concierge-package': 'Concierge service - Package',
                    'concierge-mail': 'Concierge service - Mail',
                    news: 'News',
                    other: 'Miscellaneous',
                },
                success: 'Notification sent',
                failure: 'An error occured',
                delete: {
                    success: 'Notification deleted',
                },
                deleteAttachment: {
                    success: 'Attachment deleted',
                },
            },
            communication: 'Communication',
        },

        months: {
            jan: 'January',
            feb: 'February',
            mar: 'March',
            apr: 'April',
            may: 'May',
            jun: 'June',
            jul: 'July',
            aug: 'August',
            sep: 'September',
            oct: 'October',
            nov: 'November',
            dec: 'Décember',
        },
        'days.short': {
            monday: 'Mon',
            tuesday: 'Tue',
            wednesday: 'Wed',
            thursday: 'Thu',
            friday: 'Fri',
            saturday: 'Sat',
            sunday: 'Sun',
        },
        calendar: {
            max: 'Max : ',
            mean: 'Moy : ',
            min: 'Min : ',
            days: {
                long: {
                    monday: 'Monday',
                    tuesday: 'Tuesday',
                    wednesday: 'Wednesday',
                    thursday: 'Thursday',
                    friday: 'Friday',
                    saturday: 'Saturday',
                    sunday: 'Sunday',
                },
                short: {
                    monday: 'Mon',
                    tuesday: 'Tue',
                    wednesday: 'Wed',
                    thursday: 'Thu',
                    friday: 'Fri',
                    saturday: 'Sat',
                    sunday: 'Sun',
                },
            },
            months: {
                long: {
                    jan: 'January',
                    feb: 'February',
                    mar: 'March',
                    apr: 'April',
                    may: 'May',
                    jun: 'June',
                    jul: 'July',
                    aug: 'August',
                    sep: 'September',
                    oct: 'October',
                    nov: 'November',
                    dec: 'Décember',
                },
                short: {
                    jan: 'Jan',
                    feb: 'Feb',
                    mar: 'Mar',
                    apr: 'Apr',
                    may: 'May',
                    jun: 'June',
                    jul: 'July',
                    aug: 'Aug',
                    sep: 'Sep',
                    oct: 'Oct',
                    nov: 'Nov',
                    dec: 'Déc',
                },
            },
        },

        license: 'Nauticspot copyright © 2018 Made by IAS Ingenierie',

        address: {
            title: 'Address',
            street: 'Street',
            country: 'Country',
            city: 'City',
            postalCode: 'Postal code',
        },
        error_message: 'An error occured',
        error: {
            required: 'This field is required',
            'email.invalid': 'Invalid E-Mail',
            'phone.invalid': 'Invalid phone number',
        },

        advancedSearch: {
            show: 'Advanced filters',
            hide: 'Hide filters',
        },
        'auth.error.invalidCredentials': 'Username or password incorrect',
        login: {
            username: 'Username',
            password: 'Password',
            rememberMe: 'Remember me',

            'logIn.button': 'Connection',
            'logOut.button': 'Deconnection',
            'username.error.required': 'Please input your username',
            'password.error.required': 'Please input your password',
            'password.forgotten': 'Password forgotten',
        },
        password_forgotten: {
            title: 'Password forgotten',
            resetPassword: 'Reset my password',
            confirm: 'An email is sent to change your password.',
        },
        accessRequest: {
            title: 'Pending access requests',
            user: 'User',
            category: 'Category',
            place: 'Place',
            boat: 'Boat',
            refusalComment: 'Refusal justification',
            refusal: {
                title: 'Refuse the request',
                success: 'Request rejected',
            },
            approval: {
                title: 'Approve the request',
                existingBoat: 'Existing boat',
                newBoat: 'New boat',
                success: 'Request approved',
            },
            categories: {
                yachtsman: 'Yachtsman',
                professional: 'Professional',
                visitor: 'Visitor',
            },
        },
        user: {
            firstName: 'Firstname',
            lastName: 'Lastname',
            name: 'Name',
            role: 'Role',
            roles: 'Roles',
            username: 'Username',
            nationality: 'Lationality',
            locale: 'Locale',
            email: 'eMail',
            boats: 'Boats',
            phoneNumber: 'Phone Number',
            'password.edit.title': 'Edit password',
            password: 'Password',
            passwordConfirm: 'Confirm',
            'password.error.required': 'Please input your password',
            error: {
                emailAlreadyUsed: 'Email already used',
                usernameAlreadyUsed: 'Username already used',
            },

            creation: {
                title: 'Add a user',
                success: 'User "__name__" created',
            },
            update: {
                title: 'Edit a user',
                success: 'User "__name__" edited',
            },
            delete: {
                success: 'User deleted',
                error: 'User delete error',
            },
        },
        role: {
            administrator: 'Administrator',
            yachtsman: 'Yachtsman',
            security_officer: 'Security officer',
            captain: 'Captain office',
            professional: 'Professional',
            surveyor: 'Surveyor',
            visitor: 'Visitor',
            super_admin: 'Super Admin',
            'any.role.nauticspot': 'Other',
        },
        yachtsman: {
            creation: {
                title: 'Add a yachtsman',
                success: 'Yachtsman "__name__" created',
            },
            update: {
                title: 'Edit a yachtsman',
                success: 'Yachtsman "__name__" edited',
            },
        },

        weather: {
            forecast: {
                _: 'Weather form',
                upload: 'MUpdate the form',
                download: 'Show the form online',
                updated: 'Last update : __date__',
                close_preview: 'Close',
            },
        },
        absence: {
            absence: 'Absence',
            absences: 'Absences',
            departureDate: 'Departure date',
            returnDate: 'Return date',
            anticipatedReturnDate: 'Anticipated return date',
            place: 'Place',
            boat: 'Boat',
            owners: 'Owners',
            legend: {
                pending: 'Pending',
                approved: 'Approved',
                present: 'Occupied',
                'anticipated-return': 'Anticipated return',
            },
            creation: {
                title: 'Add an absence',
                success: 'Absence created',
            },
            anticipatedReturn: {
                creation: {
                    title: 'Add an anticipated return',
                    success: 'Anticipated return created',
                },
            },
        },
        multihull: 'Multi Hull',
        sailboat: 'Sailboat',
        motorboat: 'Motorboat',
        resident: 'Resident',
        stopover: 'Stopover',
        commitment: {
            user: 'Name',
            startDate: 'Start',
            endDate: 'End',
            unknown: 'Unknown',
            inProgress: 'In progress',

            disengage: 'Disengage',
            engage: 'Engage',
            showHistory: 'Show history',
            userTypes: {
                agent: 'Agent',
                supervisor: 'Supervisor',
            },
            confirmModal: {
                title: 'Are you sure to engage ?',
                content:
                    "If you engage, you can't go back.\nOnly another user will have to engage himself to disengage you.",
            },
            disconfirmModal: {
                title: 'Are you sure to disengage ?',
                content: ' ',
            },
            historyModal: {
                title: 'Engagement history',
            },
        },
        boat: {
            _: 'Boat',
            name: 'Name',
            boatType: 'Type',
            place: 'Place',
            draught: 'Draught',
            length: 'Length',
            width: 'Width',
            size: 'Size',
            immatriculation: 'Immatriculation',
            hasTank: 'Has tank',
            owners: 'Owners',
            position: 'Position',
            countAbsences: 'Count absences',
            countOutings: 'Count outings',
            synoxDeviceId: 'Synox device id',
            creation: {
                title: 'Add a boat',
                success: 'Boat "__name__" created',
            },
            update: {
                title: 'Edit a boat',
                success: 'Boat "__name__" edited',
            },
            delete: {
                success: 'Boat deleted',
                error: 'Boat delete error',
            },
            categories: 'Boat categories',
            category: {
                name: 'Name',
                length: 'Length',
                width: 'Width',
                minLength: 'Min length',
                maxLength: 'Max length',
                minWidth: 'Min width',
                maxWidth: 'Max width',
                definition: 'From __min__m to __max__m',
                definition_min: 'More than __min__m',
                definition_max: 'To __max__m',
                definition_undefined: 'Undefined',
                other: {
                    description: 'Other category',
                },
                creation: {
                    title: 'Ajouter une categorie.',
                    success: 'Catégorie ajoutée.',
                },
                update: {
                    title: 'Edit a category.',
                    success: 'Category edited.',
                },
            },
        },
        configuration: {
            informations: 'Informations',
            photo_updated: 'Picture updated',
            upload_failed: 'an error occured.',
            photo_update: 'Change the logo',
            map_updated: 'Map updated',
            map_update: 'Change the map',
            import_users: 'Import users',
            import_users_success: 'Import complete',
            security: 'Security module',
            absence: 'Absence module',
            port: 'Harbour',
            miscellanous: 'Information',
        },
        partners: 'Services and partners',
        'external-links': 'External links',
        externalLink: {
            name: 'Label',
            color: 'Color',
            noBackground: 'Stroke',
            url: 'URL',
            updateIcon: 'Update icon',
            create: {
                title: 'Add new link',
                success: 'External links updated',
                error: "Impossible to save",
            }
        },
        partner: {
            name: 'Name',
            type: 'Category',
            description: 'Description',
            email: 'Email',
            phone: 'Phone',
            websites: 'Websites',
            website: {
                label: 'Label',
                add: 'Add a website',
            },
            types: {
                'harbour-office': 'Harbour office',
                maintenance: 'Maintenance',
                restaurant: 'Restaurants',
                'nautic-activity': 'Nautical activities',
                association: 'Associations',
                health: 'Health',

                'activity-culture': 'Culture',
                'activity-entertainment': 'Entertainment',
                'activity-nature': 'Nature',
                'activity-other': 'Other',

                'shop-food': 'Food',
                'shop-tools': 'Tools',
                'shop-nautic-tools': 'Nautical tools',
            },
            hours: 'Hours',
            miscellanous: 'Information',
            thumbnail: 'Logo',
            pictures: 'Pictures',
            picture: {
                add: {
                    title: 'Add a picture',
                    error: 'Error uploading picture',
                },
            },
            update: {
                title: 'Edit a partner',
                success: 'Partner updated',
            },
            creation: {
                title: 'Add a partner',
                success: 'Partner created',
            },
            delete: {
                error: 'Error deleting partner',
                success: 'Partner deleted',
            },
        },
        markdown: {
            link: 'To add a link: [nom du lien](adresse URL)',
            h1: 'To add a title: # Intitulé du titre',
            italic: 'To put in italic: _texte_',
            preview: 'Preview',
            help: 'Help',
        },
        places: 'Places',
        place: {
            _: 'Place',
            code: 'Code',
            boatTypes: 'Boats allowed',
            placeType: 'Place type',
            boat: 'Boat',
            length: 'Length',
            width: 'Width',
            draught: 'Draught',
            pontoon: 'Pontoon',

            deviceId: 'Device ID',
            ultrasonicMinThreshold: 'Min threshold',
            ultrasonicMaxThreshold: 'Max threshold',
            minInFrameThreshold: 'Count in frames',
            minOutFrameThreshold: 'Count out frames',

            deviceType: 'Type',
            deviceTypes: {
                buoy: 'Buoy',
                pontoon: 'Pontoon',
            },

            creation: {
                title: 'Add a place',
                success: 'Place created',
                draw: 'Draw the area',
            },
            update: {
                title: 'Edit a place',
                success: 'Place edited',
                error: 'Place edite error',
            },
            deletion: {
                title: 'Are you sure to delete place "__name__"?',
                content:
                    'This will only delete the area drawn on the map, not the place linked.',
                success: 'Place deleted',
                select: 'Select an area to delete',
            },
            state: {
                occupied: 'Place occupied',
                in: 'Place occupied',
                out: 'Place empty',
                absence: 'Place available',
                empty: 'Place empty',
                available: 'Place available',
                stopover: 'Stopover',
                error: 'Sensor error',
            },
            error: {
                notFound: 'Place not found',
            },
        },
        pontoons: 'Pontoons',
        pontoon: {
            name: 'Code',
            places: 'Places',
            creation: {
                title: 'Add a pontoon',
                success: 'Pontoon created',
            },
            update: {
                title: 'Edit a pontoon',
                success: 'Pontoon edited',
            },
        },
        'notification-user-lists': 'Notification user list',
        'notification-user-list': {
            name: 'Name',
            users: 'Users',
            creation: {
                title: 'Add a list',
                success: 'List created',
            },
            update: {
                title: 'Edit a list',
                success: 'List edited',
            },
        },
        'weather-api': 'Weather API',
        mapShape: {
            update: {
                success: 'Map shape updated',
                error: 'Map shape update error',
            },
            deletion: {
                title: 'Are you sure to delete ?',
                content:
                    'This will only delete the selected area, not the rest of the map.',
                success: 'Area deleted',
                select: 'Select the area to delete',
            },
        },
        mapPosition: {
            name: 'Nom',
            isDefault: 'Default position',
            isPublic: 'Pond visible fo all users',
            save: {
                success: 'Position saved',
            },
            delete: {
                success: 'Position deleted',
            },

            label: 'Ponds',
            saveBtn: {
                tooltip: 'Defined as position / zoom of the pond',
            },
            defaultBtn: {
                tooltip: 'Define as default pond for the harbour',
            },

            createModal: {
                title: 'Add a pond',
                success: 'Pond created',
                error: 'An error occured',
            },
            createBtn: {
                title: 'Add a pond',
            },
        },
        editPanel: {
            zone: 'Zone',
            place: 'Place',
            shape: 'Shape',

            calques: 'layers',
            actions: 'Actions',
        },
        zone: {
            zone: 'Area',
            name: 'Name',
            creation: {
                title: 'Add an area',
                success: 'Area created',
                choice: 'Do you want to create an area or a place?',
            },
            update: {
                title: 'Edit an area',
                success: 'Area edited',
                message: 'Select an area to edit',
                bad_zone: 'The area selected is a place.',
                bad_zone_description:
                    "A place can't be edited this way currently.",
            },
            deletion: {
                title: 'Are you sure to delete the area "__name__"?',
            },
            error: {
                notFound: 'Area not found',
            },
        },
        position: {
            in: 'In harbour',
            out: 'In sea',
        },
        rate: {
            category: 'Category',
            name: 'Code',
            boatTypes: 'Boat type',
            season: {
                high: 'High season',
                low: 'Low season',
                off: 'Off season',
                definition: 'From __start__ to __end__',
                from: 'From',
                to: 'to',
                update: {
                    success: 'Season edited',
                },
            },
            creation: {
                title: 'Add a rate',
                success: 'Rate created',
            },
            update: {
                title: 'Edit a rate',
                success: 'Rate edited',
            },
        },
        harbour: {
            name: 'Name',
            latitude: 'Latitude',
            longitude: 'Longitude',
            address: 'Address',
            phone: 'Phone',
            fax: 'Fax',
            email: 'Email',
            defaultLocale: 'Default locale',
            country: 'Country',
            absenceEmail: 'Email used to notify a new absence',
            securityEmail: 'Email used to notify a new incident',
            commitmentEmail: 'Email use to notify a new engagment',
            minutesBeforeEmail: 'Minutes before notification',
            intervalMinutesBetweenMail: 'Minutes between notifications',
            select: 'Select a harbour',
            change: 'Change harbour',
            update: {
                success: 'Harbour "__name__" edited',
            },
            hourBeginDay: 'Hour begin day',
            hourEndDay: 'Hour end day',
        },
        cameras: 'Cameras',
        information: 'Information',
        season: 'Seasons',
        camera: {
            name: 'Name',
            url: 'URL',
            type: 'Type',
            creation: {
                title: 'Add a camera',
                success: 'Camera created',
            },
            update: {
                title: 'Edit a camera',
                success: 'Camera edited',
            },
            delete: {
                success: 'Camera deleted',
            },
            types: {
                playlist: 'Video file',
                iframe: 'Website',
            },
        },
        reporting: {
            main: 'Main',
            repartition: 'Repartition',
            outing: 'Outings',
            outing_history: 'Outings history',
            calendar: 'Calendar',
            outing_by_type: 'Outnigs by boat type',
            fill_history: 'Fill history',
            top_10: 'Top yachtsmen',
            mean: 'Mean',
            potential: 'Potential',
            filters: {
                title: 'Filters',
                labels: {
                    period_title: 'Period',
                    period: 'Period of the report',
                    compare: 'Compared to :',
                    users: 'Yachtsmen',
                    places_zones: 'Place / Area',
                    places: 'Places',
                    zones: 'Areas',
                    types: 'Boat type :',
                    category: 'Boat category',
                },
                date: 'Please select a valid date',
                year: 'Please select a year',
            },
            top10: {
                number_outing: 'Number of outings of the period',
                by_day: 'Day outings',
                by_night: 'Night outings',
                relative_night: '% of night outings',
                title_less: 'Yachtsmen less active',
                title_most: 'Yachtsmen most active',
            },
            occupancy: {
                title: 'Occupancy',
                evolution: '__EVOLUTION__% compared to __DATE__',
                no_evolution: 'No evolution compared to __DATE__',
                places: '__NB__ places',
                placesUsed: 'Places occupied',
                placesEmpty: 'Places empty',
            },
            data: {
                waiting: 'Waiting for data.',
                no_data: 'No data received.',
                waiting_explanation: 'No data was received as of yet.',
                no_data_explanation:
                    'No data seem to be available on this period for this chart.',
            },
            optimisation: {
                title: 'Optimisation',
                tableTitle: {
                    period: 'Periods',
                    nights: 'Outings',
                    profit: 'Profits',
                    absences: 'Absences',
                    losts: 'Losts',
                },
                period: 'From __start__ to __end__',
                profit: {
                    potential: 'Potential',
                    realised: 'Realised',
                    lost: 'Lost',
                },
                outings: 'Outings',
                optimized: 'Optimized',
                declared: 'Declared',
                outside: 'Outside harbour',
            },
        },
        outing: {
            timeIn: 'Hour in',
            timeOut: 'Hour out',
            listByDay: 'List of outings for __date__',
        },
        urgencePhoneNumbers: 'Emergency number',
        urgencePhoneNumber: {
            name: 'Nom',
            phoneNumber: 'Phone',
            vhf: 'VHF',
            creation: {
                title: 'Add an emergency phone',
                success: 'Phone created',
                btn: 'Add an emergency phone',
            },
            update: {
                title: 'Edit an emergency phone',
                success: 'Phone edited',
            },
            delete: {
                success: 'Phone deleted',
            },
        },
        users: 'Users',
        categories: 'Categories',

        incidents: 'Incidents',
        incident: {
            name: 'Incident',
            user: 'Yachtsmen',
            types: {
                fire: 'Fire',
                sink: 'Sink',
                theft: 'Theft',
                electric: 'Electric',
                water: 'Water leak',
                sound: 'Noise pollution',
                polution: 'Pollution',
                lighting: 'Lighting',
                sanitary: 'Sanitary',
                broken_board: 'Broken board',
                collision: 'Collision',
                harbour_map: 'Harbour map',
                other: 'Other',
                extinguisher: 'Extinguisher',
                chain: 'Chain / Mooring line',
                ladder: 'Ladder',
                towing: 'Towing',
                mooring: 'Mooring',                
            },
            visibility: {
                _: 'Visibility',
                none: 'No one',
                captain: 'Harbour office',
                all: 'Everyone',
            },
            type: {
                _: 'Incident type',
                fire: 'Fire',
                sink: 'Sink',
                theft: 'Theft',
                electric: 'Electric',
                water: 'Water leak',
                sound: 'Noise pollution',
                polution: 'Pollution',
                lighting: 'Lighting',
                sanitary: 'Sanitary',
                broken_board: 'Broken board',
                collision: 'Collision',
                harbour_map: 'Harbour map',
                other: 'Other',
                extinguisher: 'Extinguisher',
                chain: 'Chain / Mooring line',
                ladder: 'Ladder',
                towing: 'Towing',
                mooring: 'Mooring',                
            },
            comment: 'Reason',
            comments: 'Comment',
            harbourComment: 'Harbour comments',
            saveHarbourComment: 'Save comment',
            status: 'Status',
            zone: 'Area',
            createdAt: 'Created at',
            confirmedByOne: 'Confirmed by a yachtsman',
            confirmedBy: 'Confirmed by  __COUNT__ yachtsmen',
            acknowlegedAt: 'Achnowleged at __DATE__',
            acknowlegedAt_by: 'Achnowleged at __DATE__ by __USER__',
            closedAt: 'Closed at __DATE__',
            closedAt_by: 'Closed at __DATE__ by __USER__',
            attachments: 'Attachments',
            no_attachment: 'No attachment',
            createdBy: 'Created by',
            security: 'Security',
            safety: 'Safety',
            maintenance: 'Maintenance',
            email: 'Notification emails',
            notificationEmail: 'Email used to notify an incident',
            isCritical: 'Critical',
            criticalPhoneNumber: {
                day: 'Emergency phone (day)',
                night: 'Emergency phone (night)',
            },
            editZone: 'Define the area',
            statuses: {
                pending: 'Pending',
                acknowledged: 'Acknowledged',
                closed: 'Closed',
            },
            action: {
                acknowledge: 'Acknowlege the incident',
                close: 'Close the incident',
            },

            pendingIncidents: 'Pending incidents',

            creation: {
                title: 'Declare an incident',
                success: 'Incident declared',
            },
            update: {
                success: 'Incident edited',
            },
        },
    },

    // Ant Design
    Table: {
        filterTitle: 'Search...',
        filterConfirm: 'OK',
        filterReset: 'Reset',
        emptyText: 'No data',
    },
    Modal: {
        okText: 'OK',
        cancelText: 'Cancel',
        justOkText: 'OK',
    },
    Popconfirm: {
        okText: 'OK',
        cancelText: 'Cancel',
    },
    Transfer: {
        notFoundContent: 'No result',
        searchPlaceholder: 'Search',
        itemUnit: 'item',
        itemsUnit: 'items',
    },
    Select: {
        notFoundContent: 'No result',
    },
    Pagination: {
        // Options.jsx
        items_per_page: '/ page',
        jump_to: 'Go to',
        page: '',

        // Pagination.jsx
        prev_page: 'Prev Page',
        next_page: 'Next Page',
        prev_5: '5 Prev Pages',
        next_5: '5 Next Pages',
        prev_3: '3 Prev Pages',
        next_3: '3 Next Pages',
    },
    TimePicker: {
        placeholder: '',
    },
    DatePicker: {
        lang: {
            today: 'Today',
            now: 'Now',
            backToToday: 'Back to today',
            ok: 'Ok',
            clear: 'Clear',
            month: 'Month',
            year: 'Year',
            timeSelect: 'Select time',
            dateSelect: 'Select date',
            monthSelect: 'Select month',
            yearSelect: 'Select year',
            decadeSelect: 'Select decade',
            yearFormat: 'YYYY',
            dateFormat: 'M/D/YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'M/D/YYYY HH:mm:ss',
            monthFormat: 'MMMM',
            monthBeforeYear: true,
            previousMonth: 'Prev month (PageUp)',
            nextMonth: 'Next month (PageDown)',
            previousYear: 'Prev year (Control + left)',
            nextYear: 'Next year (Control + right)',
            previousDecade: 'Prev decade',
            nextDecade: 'Next decade',
            previousCentury: 'Prev century',
            nextCentury: 'Next century',

            placeholder: 'Select a date',
            rangePlaceholder: ['Date start', 'Date end'],
        },
    },
};

/* eslint no-debugger: "warn" */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Capitalize first letter of string
 * @private
 * @param  {string} - String
 * @return {string} - String with first letter capitalized
 */
function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.substr(1);
}

/**
 * Clamp position between a range
 * @param  {number} - Value to be clamped
 * @param  {number} - Minimum value in range
 * @param  {number} - Maximum value in range
 * @return {number} - Clamped value
 */
function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

/**
 * Predefined constants
 * @type {Object}
 */
const constants = {
    orientation: {
        horizontal: {
            dimension: 'width',
            direction: 'left',
            reverseDirection: 'right',
            coordinate: 'x',
        },
        vertical: {
            dimension: 'height',
            direction: 'top',
            reverseDirection: 'bottom',
            coordinate: 'y',
        },
    },
};

class Slider extends Component {
    static propTypes = {
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        value: PropTypes.number,
        orientation: PropTypes.string,
        reverse: PropTypes.bool,
        onChangeStart: PropTypes.func,
        onChange: PropTypes.func,
        onChangeComplete: PropTypes.func,
    };

    static defaultProps = {
        min: 0,
        max: 100,
        step: 1,
        value: 0,
        orientation: 'horizontal',
        reverse: false,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            limit: 0,
            grab: 0,
        };
    }

    componentDidMount() {
        this.handleUpdate();
    }

    /**
     * Update slider state on change
     * @return {void}
     */
    handleUpdate = () => {
        if (!this.slider) {
            // for shallow rendering
            return;
        }
        const { orientation } = this.props;
        const dimension = capitalize(constants.orientation[orientation].dimension);
        const sliderPos = this.slider[`offset${dimension}`];
        const handlePos = this.handle[`offset${dimension}`];

        this.setState({
            limit: sliderPos - handlePos,
            grab: handlePos / 2,
        });
    };

    /**
     * Attach event listeners to mousemove/mouseup events
     * @return {void}
     */
    handleStart = (e) => {
        const { onChangeStart } = this.props;
        document.addEventListener('mousemove', this.handleDrag);
        document.addEventListener('mouseup', this.handleEnd);
        onChangeStart && onChangeStart(e);
    };

    /**
     * Handle drag/mousemove event
     * @param  {Object} e - Event object
     * @return {void}
     */
    handleDrag = (e) => {
        e.stopPropagation();
        const { onChange } = this.props;
        const {
            target: { className, classList, dataset },
        } = e;
        if (!onChange || className === 'rangeslider__labels') return;

        let value = this.position(e);

        if (classList && classList.contains('rangeslider__label-item') && dataset.value) {
            value = parseFloat(dataset.value);
        }

        onChange && onChange(value, e);
    };

    /**
     * Detach event listeners to mousemove/mouseup events
     * @return {void}
     */
    handleEnd = (e) => {
        const { onChangeComplete } = this.props;
        onChangeComplete && onChangeComplete(e);
        document.removeEventListener('mousemove', this.handleDrag);
        document.removeEventListener('mouseup', this.handleEnd);
    };

    /**
     * Support for key events on the slider handle
     * @param  {Object} e - Event object
     * @return {void}
     */
    handleKeyDown = (e) => {
        e.preventDefault();
        const { keyCode } = e;
        const {
            value, min, max, step, onChange,
        } = this.props;
        let sliderValue;

        switch (keyCode) {
        case 38:
        case 39:
            sliderValue = value + step > max ? max : value + step;
            onChange && onChange(sliderValue, e);
            break;
        case 37:
        case 40:
            sliderValue = value - step < min ? min : value - step;
            onChange && onChange(sliderValue, e);
            break;
        default:
            // Nothing
        }
    };

    /**
     * Calculate position of slider based on its value
     * @param  {number} value - Current value of slider
     * @return {position} pos - Calculated position of slider based on value
     */
    getPositionFromValue = (value) => {
        const { limit } = this.state;
        const { min, max } = this.props;
        const diffMaxMin = max - min;
        const diffValMin = value - min;
        const percentage = diffValMin / diffMaxMin;
        const pos = Math.round(percentage * limit);

        return pos;
    };

    /**
     * Translate position of slider to slider value
     * @param  {number} pos - Current position/coordinates of slider
     * @return {number} value - Slider value
     */
    getValueFromPosition = (pos) => {
        const { limit } = this.state;
        const {
            orientation, min, max, step,
        } = this.props;
        const percentage = clamp(pos, 0, limit) / (limit || 1);
        const baseVal = step * Math.round(percentage * (max - min) / step);
        const value = orientation === 'horizontal' ? baseVal + min : max - baseVal;

        return clamp(value, min, max);
    };

    /**
     * Calculate position of slider based on value
     * @param  {Object} e - Event object
     * @return {number} value - Slider value
     */
    position = (e) => {
        const { grab } = this.state;
        const { orientation, reverse } = this.props;

        const node = this.slider;
        const coordinateStyle = constants.orientation[orientation].coordinate;
        const directionStyle = reverse
            ? constants.orientation[orientation].reverseDirection
            : constants.orientation[orientation].direction;
        const clientCoordinateStyle = `client${capitalize(coordinateStyle)}`;
        const coordinate = !e.touches
            ? e[clientCoordinateStyle]
            : e.touches[0][clientCoordinateStyle];
        const direction = node.getBoundingClientRect()[directionStyle];
        const pos = reverse ? direction - coordinate - grab : coordinate - direction - grab;
        const value = this.getValueFromPosition(pos);

        return value;
    };

    /**
     * Grab coordinates of slider
     * @param  {Object} pos - Position object
     * @return {Object} - Slider fill/handle coordinates
     */
    coordinates = (pos) => {
        const { limit, grab } = this.state;
        const { orientation } = this.props;
        const value = this.getValueFromPosition(pos);
        const position = this.getPositionFromValue(value);
        const handlePos = orientation === 'horizontal' ? position + grab : position;
        const fillPos = orientation === 'horizontal' ? handlePos : limit - handlePos;

        return {
            fill: fillPos,
            handle: handlePos,
            label: handlePos,
        };
    };

    render() {
        const {
            value, orientation, className, reverse, min, max,
        } = this.props;
        const dimension = constants.orientation[orientation].dimension;
        const direction = reverse
            ? constants.orientation[orientation].reverseDirection
            : constants.orientation[orientation].direction;
        const position = this.getPositionFromValue(value);
        const coords = this.coordinates(position);
        const fillStyle = { [dimension]: `${coords.fill}px` };
        const handleStyle = { [direction]: `${coords.handle}px` };

        return (
            <div
                ref={(s) => {
                    this.slider = s;
                }}
                className={`rangeslider rangeslider-${orientation} ${
                    reverse ? 'rangeslider-reverse' : ''
                } ${className || ''}`}
                onMouseDown={this.handleDrag}
                onMouseUp={this.handleEnd}
                onTouchStart={this.handleStart}
                onTouchEnd={this.handleEnd}
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                aria-orientation={orientation}
            >
                <div className="rangeslider__fill" style={fillStyle} />
                <div
                    ref={(sh) => {
                        this.handle = sh;
                    }}
                    className="rangeslider__handle"
                    onMouseDown={this.handleStart}
                    onTouchMove={this.handleDrag}
                    onTouchEnd={this.handleEnd}
                    onKeyDown={this.handleKeyDown}
                    style={handleStyle}
                    tabIndex={0}
                />
            </div>
        );
    }
}

export default Slider;

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class CameraService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all cameras of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/cameras`,
            method: 'GET',
        });
    }

    /**
     * Get a camera by unique identifier.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} cameraId The identifier of the camera.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    getById(harbourId: number, cameraId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/cameras/${cameraId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new camera to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The camera to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/cameras`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing camera.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} cameraId The identifier of the camera.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, cameraId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/cameras/${cameraId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} cameraId The identifier of the camera.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, cameraId: number, patch: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/cameras/${cameraId}`,
            method: 'PATCH',
            data: patch,
        });
    }
}

export default new CameraService();

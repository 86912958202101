import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL = `${BaseUrlConstants.BASE_URL}`;

class HarbourMapShapeService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get the map of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/map/shapes`,
            method: 'GET',
        });
    }

    /**
     * Get a map shape by unique identifier.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} shapeId The identifier of the shape.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    getById(harbourId: number, shapeId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/map/shapes/${shapeId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new shape to a harbour map.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The shape to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/map/shapes`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing map shape.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} shapeId The identifier of the shape.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, shapeId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/map/shapes/${shapeId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} shapeId The identifier of the shape.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, shapeId: number, patch: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/map/shapes/${shapeId}`,
            method: 'PATCH',
            data: patch,
        });
    }
}

export default new HarbourMapShapeService();

import React from 'react';
import CountrySelect from './CountrySelect';
import PhoneUtil from './PhoneUtil';
import { Input } from 'antd';

import './PhoneInput.scss';

interface Props {
    value: string | undefined;
    onChange: (newvalue: string | undefined) => void;
}

export default function PhoneInput({ value, onChange }: Props) {
    const countryCode = value ? PhoneUtil.getCountryCodeOfNumber(value) : 'fr';
    const country = PhoneUtil.getCountryDataByCode(countryCode);

    return (
        <Input
            className="phone-input"
            addonBefore={
                <CountrySelect
                    type="dial"
                    value={countryCode}
                    onChange={(newCountryCode: any) => {
                        const newCountry = PhoneUtil.getCountryDataByCode(
                            newCountryCode,
                        );

                        let newValue =
                            country && value
                                ? value.replace(`+${country.dialCode}`, '')
                                : value;

                        if (newValue && newValue.charAt(0) === '0') {
                            newValue = newValue.substr(1);
                        }

                        newValue = newCountry
                            ? `+${newCountry.dialCode}${newValue}`
                            : newValue;

                        onChange(
                            newValue ? PhoneUtil.format(newValue) : undefined,
                        );
                    }}
                />
            }
            style={{
                width: '100%',
            }}
            value={
                country && value
                    ? value.replace(`+${country.dialCode}`, '')
                    : value
            }
            onChange={(e) => {
                const newValue = e.target.value;
                onChange(
                    PhoneUtil.format(
                        country ? `+${country.dialCode}${newValue}` : newValue,
                    ),
                );
            }}
        />
    );
}

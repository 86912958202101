import BaseStore from './BaseStore';
import ActionsBase from '../actions/ActionsBase';
import ServiceBase from '../services/ServiceBase';
import HarbourStore from './HarbourStore';
import CacheManager from '../services/CacheManager';
import dispatcher from '../dispatchers/AppDispatcher';
import BaseUrlConstants from '../constants/BaseUrlConstants';

const CONSTANTS = {
    NOTIFICATION_RECEIVED: 'notification.NOTIFICATION_RECEIVED',
    NOTIFICATION_ADDED: 'notification.NOTIFICATION_ADDED',
    NOTIFICATION_DELETED: 'notification.NOTIFICATION_DELETED',
    ATTACHMENT_DELETED: 'notification.ATTACHMENT_DELETED',
};
const CACHE_DURATION = 30 * 1000;

class NotificationService extends ServiceBase {
    getByHarbour(harbour: Entity) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/notifications`,
            method: 'GET',
        });
    }
    postByHarbour(harbour: Entity, resource: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/notifications`,
            method: 'POST',
            data: resource,
        });
    }
    patchByHarbour(harbour: Entity, id: number | string, resource: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/notifications/${id}`,
            method: 'PATCH',
            data: resource,
        });
    }
    deleteByHarbour(harbour: Entity, notificationId: number | string) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/notifications/${notificationId}`,
            method: 'DELETE',
        });
    }
    deleteAttachment(
        harbour: Entity,
        notificationId: number | string,
        attachmentId: number | string,
    ) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/notifications/${notificationId}/attachments/${attachmentId}`,
            method: 'DELETE',
        });
    }
    postAttachment(harbour: Entity, notification: Entity, files: any) {
        return this.uploadFiles({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/notifications/${notification.id}/attachments`,
            method: 'POST',
            files,
        });
    }

    postThumbnail(harbour: Entity, notification: Entity, thumbnailFile: any) {
        return this.uploadFiles({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/notifications/${notification.id}/thumbnail`,
            method: 'POST',
            files: [thumbnailFile],
        });
    }
}

class NotificationAction extends ActionsBase {
    service: NotificationService;
    constructor(service: NotificationService) {
        super();
        this.service = service;
    }
    load(force = false) {
        const harbour = HarbourStore.getCurrentHarbour();
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                if (
                    !harbour ||
                    (!force &&
                        CacheManager.isCached(
                            'NotificationAction:load',
                            harbour.id.toString(),
                            CACHE_DURATION,
                        ))
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(notifications: Array<any>): void {
                    dispatcher.dispatch({
                        type: CONSTANTS.NOTIFICATION_RECEIVED,
                        payload: {
                            notifications,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                this.service
                    .getByHarbour(harbour)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    }
    add(harbour: IHarbour, notification: any, attachments: any[], thumbnailFile: any | null) {
        const $this = this;
        return new Promise(
            (resolve, reject: (response: Object) => void): void => {
                function handleSuccess(newNotification: any): void {
                    dispatcher.dispatch({
                        type: CONSTANTS.NOTIFICATION_ADDED,
                        payload: {
                            notification: newNotification,
                        },
                    });
                    resolve(newNotification);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                if (harbour) {
                    this.service
                        .postByHarbour(harbour, notification)
                        .then((newNotification: any) => {
                            const promises = [];
                            if (attachments && attachments.length > 0) {
                                promises.push(
                                    this.service.postAttachment(
                                        harbour,
                                        newNotification,
                                        attachments,
                                    ),
                                );
                            }
                            if (thumbnailFile) {
                                promises.push(
                                    this.service.postThumbnail(
                                        harbour,
                                        newNotification,
                                        thumbnailFile,
                                    ),
                                );
                            }
                            Promise.all(promises)
                                .then(() => {
                                    this.load(true);
                                    handleSuccess(newNotification);
                                })
                                .catch(handleError);
                        })
                        .catch(handleError);
                }
            },
        );
    }

    edit(
        id: number | string,
        notification: any,
        attachments: any[],
        thumbnailFile: any | null,
    ) {
        const $this = this;
        const harbour = HarbourStore.getCurrentHarbour();
        return new Promise(
            (resolve, reject: (response: Object) => void): void => {
                function handleSuccess(notification: any): void {
                    dispatcher.dispatch({
                        type: CONSTANTS.NOTIFICATION_ADDED,
                        payload: {
                            notification,
                        },
                    });
                    resolve(notification);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                if (harbour) {
                    this.service
                        .patchByHarbour(harbour, id, notification)
                        .then((newNotification: any) => {
                            const promises = [];
                            if (attachments && attachments.length > 0) {
                                promises.push(
                                    this.service.postAttachment(
                                        harbour,
                                        newNotification,
                                        attachments,
                                    ),
                                );
                            }
                            if (thumbnailFile) {
                                promises.push(
                                    this.service.postThumbnail(
                                        harbour,
                                        newNotification,
                                        thumbnailFile,
                                    ),
                                );
                            }
                            Promise.all(promises)
                                .then(() => {
                                    this.load(true);
                                    handleSuccess(newNotification);
                                })
                                .catch(handleError);
                        })
                        .catch(handleError);
                }
            },
        );
    }

    delete(notificationId: number | string) {
        const $this = this;
        const harbour = HarbourStore.getCurrentHarbour();
        return new Promise((resolve, reject): void => {
            function handleSuccess(): void {
                dispatcher.dispatch({
                    type: CONSTANTS.NOTIFICATION_DELETED,
                    payload: {
                        notificationId,
                    },
                });
                resolve();
            }
            if (harbour) {
                this.service
                    .deleteByHarbour(harbour, notificationId)
                    .then(handleSuccess)
                    .catch((e: any) => $this.handleError(e, reject));
            } else {
                reject();
            }
        });
    }

    deleteAttachment(
        notificationId: number | string,
        attachmentId: number | string,
    ) {
        const $this = this;
        const harbour = HarbourStore.getCurrentHarbour();
        return new Promise((resolve, reject): void => {
            function handleSuccess(): void {
                dispatcher.dispatch({
                    type: CONSTANTS.ATTACHMENT_DELETED,
                    payload: {
                        notificationId,
                        attachmentId,
                    },
                });
                resolve();
            }
            if (harbour) {
                this.service
                    .deleteAttachment(harbour, notificationId, attachmentId)
                    .then(handleSuccess)
                    .catch((e: any) => $this.handleError(e, reject));
            } else {
                reject();
            }
        });
    }
}

class NotificationStore extends BaseStore {
    action: NotificationAction;
    _entities: Array<any>;
    constructor(action: NotificationAction) {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = [];
        this.action = action;
        HarbourStore.addChangeListener(() => this.action.load());
    }
    _registerToActions = (action: { type: string; payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
            case CONSTANTS.NOTIFICATION_RECEIVED:
                return this._receive(payload.notifications);
            case CONSTANTS.NOTIFICATION_ADDED:
                return this._add(payload.notification);
            case CONSTANTS.NOTIFICATION_DELETED:
                return this._delete(payload.notificationId);
            case CONSTANTS.ATTACHMENT_DELETED:
                return this._deleteAttachment(
                    payload.notificationId,
                    payload.attachmentId,
                );
            default:
                // Do Nothing
                break;
        }
    };
    _receive(notifications: any) {
        this._entities = notifications;
        this.emitChange();
    }
    _add(notification: any) {
        this._entities = [...this._entities];
        const i = this._entities.findIndex((e) => e.id === notification.id);
        if (i === -1) {
            this._entities.push(notification);
        } else {
            this._entities[i] = notification;
        }

        this.emitChange();
    }
    _delete(notificationId: any) {
        const i = this._entities.findIndex(
            (n) => parseInt(n.id, 10) === parseInt(notificationId, 10),
        );
        if (i > -1) {
            this._entities = [...this._entities];
            this._entities.splice(i, 1);
            this.emitChange();
        }
    }
    _deleteAttachment(notificationId: any, attachmentId: any) {
        const i = this._entities.findIndex(
            (n) => parseInt(n.id, 10) === parseInt(notificationId, 10),
        );
        if (i > -1) {
            const newNotification = {
                ...this._entities[i],
                attachments: this._entities[i].attachments.filter(
                    (a: { id: any }) => a.id !== attachmentId,
                ),
            };
            this._entities = [...this._entities];
            this._entities[i] = newNotification;
            this.emitChange();
        }
    }

    get = () => {
        return this._entities;
    };
}

const service = new NotificationService('aaa');
const action = new NotificationAction(service);
const store = new NotificationStore(action);

const get = () => {
    return store.get();
};
const register = (func: () => void) => {
    store.addChangeListener(func);
    return {
        remove: () => store.removeChangeListener(func),
    };
};
const load = () => {
    return action.load();
};
const add = (harbour: IHarbour, item: any, attachments: any[], thumbnailFile: any | null) => {
    return action.add(harbour, item, attachments, thumbnailFile);
};
const edit = (
    id: number | string,
    item: any,
    attachments: any[],
    thumbnailFile: any | null,
) => {
    return action.edit(id, item, attachments, thumbnailFile);
};
const deleteNotification = (itemId: any) => {
    return action.delete(itemId);
};
const deleteAttachment = (itemId: any, attachmentId: any) => {
    return action.deleteAttachment(itemId, attachmentId);
};

export default {
    get,
    register,
    load,
    add,
    edit,
    deleteNotification,
    deleteAttachment
};

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class PlaceZoneService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Post a new place to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} placeId The identifier of the place.
     * @param {Object} resource The place to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, placeId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/places/${placeId}/zone`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing place.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} placeId The identifier of the place.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, placeId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/places/${placeId}/zone`,
            method: 'DELETE',
        });
    }
}

export default new PlaceZoneService();

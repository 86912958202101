import React from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';
import SelectMultiple from 'components/form-items/SelectMultipleFormItem';

function cmpUsersByName(u1, u2) {
    const uN1 = u1.firstName + u1.lastName;
    const uN2 = u2.firstName + u2.lastName;
    return uN1 > uN2 ? 1 : -1;
}

class BoatForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const boat = this.getBoatFromValues(values);
                delete boat.owners;

                // Owners
                const allUsers = this.props.users;
                const initialOwnersId =
                    this.props.boat && this.props.boat.owners
                        ? this.props.boat.owners.map(o => o.id.toString())
                        : [];
                const ownersIds = values.owners_ids;

                const owners = {
                    created: ownersIds
                        .filter(id => initialOwnersId.indexOf(id) < 0)
                        .map(id => allUsers.find(c => c.id.toString() === id.toString())),
                    deleted: initialOwnersId
                        .filter(id => ownersIds.indexOf(id) < 0)
                        .map(id => allUsers.find(c => c.id.toString() === id.toString())),
                };

                this.props.onSubmit(boat, owners);
            }
        });
    };

    getBoatFromValues = (values) => {
        const boat = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.map((k) => {
            if (k.endsWith('_ids')) {
                return null;
            }
            if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                boat[tK] = typeof values[k] === 'undefined' ? null : values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                boat[k] = values[k];
            }
            return null;
        });
        return boat;
    };

    render() {
        const { getFieldValue } = this.props.form;
        const boat = getFieldValue('boat');
        return (
            <Form onSubmit={this.handleSubmit}>
                <FormItem.Input
                    id="name"
                    required
                    initialValue={boat ? boat.name : null}
                    label={Locale.trans('boat.name')}
                    form={this.props.form}
                    validateStatus={this.getValidateStatus('name')}
                    help={this.getHelp('name')}
                    hasFeedback
                />
                <FormItem.Input
                    id="immatriculation"
                    initialValue={boat ? boat.immatriculation : null}
                    label={Locale.trans('boat.immatriculation')}
                    form={this.props.form}
                />
                {this.renderOwnersField()}
                {this.renderBoatTypeField()}
                {this.renderPlaceField()}
                <FormItem.Input
                    id="length"
                    initialValue={boat ? boat.length : null}
                    label={Locale.trans('boat.length')}
                    form={this.props.form}
                />
                <FormItem.Input
                    id="width"
                    initialValue={boat ? boat.width : null}
                    label={Locale.trans('boat.width')}
                    form={this.props.form}
                />
                <FormItem.Input
                    id="draught"
                    initialValue={boat ? boat.draught : null}
                    label={Locale.trans('boat.draught')}
                    form={this.props.form}
                />
                <FormItem.Input
                    id="synoxDeviceId"
                    initialValue={boat ? boat.synoxDeviceId : null}
                    label={Locale.trans('boat.synoxDeviceId')}
                    form={this.props.form}
                />
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={this.props.loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }
    renderBoatTypeField() {
        const boatTypes = ['motorboat', 'sailboat', 'multihull'];
        const { getFieldValue } = this.props.form;
        return (
            <FormItem.Select
                id="boatType"
                required
                showSearch
                initialValue={getFieldValue('boat') ? getFieldValue('boat').boatType.toString() : null}
                label={Locale.trans('boat.boatType')}
                options={boatTypes.map(c => ({ value: c, label: Locale.trans(c) }))}
                form={this.props.form}
                validateStatus={this.getValidateStatus('boatType')}
                help={this.getHelp('boatType')}
                hasFeedback
            />
        );
    }
    renderPlaceField() {
        const { places } = this.props;
        const { getFieldValue } = this.props.form;
        return (
            <FormItem.Select
                id="place_id"
                showSearch
                allowClear
                label={Locale.trans('boat.place')}
                initialValue={getFieldValue('place') ? getFieldValue('place').id.toString() : null}
                options={places.filter(p => !!p).map(c => ({ value: c.id, label: c.code }))}
                form={this.props.form}
                validateStatus={this.getValidateStatus('place_id')}
                help={this.getHelp('place_id')}
                hasFeedback
            />
        );
    }

    renderOwnersField() {
        const { users } = this.props;

        if (!users || users.length === 0) {
            return null;
        }

        const { getFieldValue } = this.props.form;
        return (
            <SelectMultiple
                id="owners_ids"
                multiple
                label={Locale.trans('boat.owners')}
                initialValue={((getFieldValue('boat') && getFieldValue('boat').owners) || []).map(c => c.id.toString())}
                options={users.sort(cmpUsersByName).map(c => ({
                    value: c.id,
                    label: `${c.lastName} ${c.firstName}`,
                }))}
                form={this.props.form}
            />
        );
    }
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(BoatForm);

import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Input, Button, Checkbox } from 'antd';
import List from 'react-virtualized/dist/commonjs/List';
import Locale from 'locale/LocaleFactory';

import './RecipientSelect.scss';
import StringService from 'services/utils/StringService';
import UserStore from 'stores/UserStore';
import PontoonStore from 'stores/PontoonStore';
import UserActions from 'actions/UserActions';
import PontoonActions from 'actions/PontoonActions';
import NotificationUserListActions from 'actions/NotificationUserListActions';
import NotificationUserListStore from 'stores/NotificationUserListStore';
import BoatActions from 'actions/BoatActions';
import BoatStore from 'stores/BoatStore';
import ArrayService from 'services/utils/ArrayService';

interface UserListRecipients {
    type: string;
    key: number;
    name: string;
    users: User[];
    disabledUserIds?: number[];
}

function useDataByHarbour(
    actions: any,
    store: any,
    harbour: IHarbour | undefined,
) {
    const [data, setData] = useState(store.getByHarbour(harbour));

    useEffect(() => {
        function receiveData() {
            const data = store.getByHarbour(harbour);
            setData(data);
        }

        store.addChangeListener(receiveData);
        actions.reloadByHarbour(harbour);

        return () => {
            store.removeChangeListener(receiveData);
        };
    }, [actions, store, harbour]);

    return data;
}

function getUsersFromRecipientList(selectedRecipients: UserListRecipients[]) {
    const users = ArrayService.flatten(
        selectedRecipients.map((r) =>
            r.users.filter(
                (u) =>
                    !r.disabledUserIds ||
                    r.disabledUserIds.indexOf(u.id) === -1,
            ),
        ),
    );

    return ArrayService.uniqueEntity(users) as User[];
}

interface RecipientSelectProps {
    onChange: (users: User[]) => void;
    harbour: IHarbour;
    value: User[];
}
export default function RecipientSelect({
    value,
    harbour,
    onChange,
}: RecipientSelectProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRecipients, setSelectedRecipients] = useState(
        [] as UserListRecipients[],
    );
    const [users, setUsers] = useState([] as User[]);

    function handleChange(newRecipients: UserListRecipients[]) {
        // TODO
        setSelectedRecipients(newRecipients);
        const users = getUsersFromRecipientList(newRecipients);
        setUsers(users);
        onChange(users);
    }

    useEffect(() => {
        if (!value || value.length === 0) {
            setSelectedRecipients([]);
        }
    }, [value]);

    return (
        <>
            <div className="recipient-select">
                <div className="__label">
                    {Locale.trans('communication.notification.recipients')}
                </div>

                <Button className="__trigger" onClick={() => setIsOpen(true)}>
                    {selectedRecipients.length > 0 ? (
                        <>
                            {selectedRecipients
                                .map((r) => {
                                    return r.name;
                                })
                                .slice(0, 4)
                                .join(', ')}{' '}
                            <span className="__extra">
                                {`${users.length} ${Locale.trans(
                                    'communication.notification.recipients',
                                ).toLowerCase()}`}
                            </span>
                        </>
                    ) : (
                        Locale.trans(
                            'communication.notification.recipients.all',
                        )
                    )}
                </Button>
            </div>
            {isOpen && (
                <RecipientSelectModal
                    harbour={harbour}
                    visible={isOpen}
                    onClose={() => setIsOpen(false)}
                    selectedRecipients={selectedRecipients}
                    onChange={handleChange}
                />
            )}
        </>
    );
}

function cmpUsersByName(u1: User, u2: User) {
    const uN1 = u1.firstName + u1.lastName;
    const uN2 = u2.firstName + u2.lastName;
    return StringService.compareCaseInsensitive(uN1, uN2);
}

function cmpByName(u1: any, u2: any) {
    return StringService.compareCaseInsensitive(u1.name, u2.name);
}

function getUserNameAndBoat(user: User) {
    let boat = null;

    const u = UserStore.getById(user.id);
    if (!u) {
        return '';
    }
    if (u.boats.length > 0) {
        boat = BoatStore.getById(u.boats[0].id);
    }
    return `${u.firstName} ${u.lastName} ${boat ? `(${boat.name})` : ''}`;
}

interface RecipientSelectModalProps {
    harbour: IHarbour;
    visible: boolean;
    onClose: () => void;
    selectedRecipients: UserListRecipients[];
    onChange: (values: UserListRecipients[]) => void;
}
function RecipientSelectModal({
    harbour,
    visible,
    onClose,
    selectedRecipients,
    onChange,
}: RecipientSelectModalProps) {
    const harbourUsers = useDataByHarbour(
        UserActions,
        UserStore,
        harbour,
    );
    const users = useMemo(
        () =>
            harbourUsers
                .filter(
                    (u: User) =>
                        u.rolesInHarbours &&
                        !!u.rolesInHarbours.find((r) => r.role === 'yachtsman'),
                )
                .sort(cmpUsersByName),
        [harbourUsers],
    ) as User[];
    const pontoons = useDataByHarbour(
        PontoonActions,
        PontoonStore,
        harbour,
    ) as IPontoon[];
    const userLists = useDataByHarbour(
        NotificationUserListActions,
        NotificationUserListStore,
        harbour,
    ) as INotificationUserList[];
    const boats = useDataByHarbour(
        BoatActions,
        BoatStore,
        harbour,
    ) as IBoat[];

    function addItem(item: UserListRecipients) {
        if (
            !selectedRecipients.find(
                (r) => r.type === item.type && r.key === item.key,
            )
        ) {
            let newSelected = [...selectedRecipients];
            newSelected.push(item);
            onChange(newSelected);
        }
    }

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={Locale.trans('communication.notification.recipients')}
            footer={
                <Button type="primary" onClick={onClose}>
                    {Locale.trans('ok')}
                </Button>
            }
            className="recipient-select-modal"
        >
            <div className="__section __available-section">
                <AvailableItemList
                    onClick={addItem}
                    title={Locale.trans(
                        'communication.notification.recipients.user-lists',
                    )}
                    items={userLists
                        .filter(
                            (u) =>
                                !selectedRecipients.find(
                                    (s) =>
                                        s.type === 'user-list' &&
                                        s.key === u.id,
                                ),
                        )
                        .sort(cmpByName)
                        .map((u) => {
                            return {
                                type: 'user-list',
                                key: u.id,
                                name: u.name,
                                users: u.users,
                            };
                        })}
                />
                <AvailableItemList
                    onClick={addItem}
                    title={Locale.trans(
                        'communication.notification.recipients.pontoon',
                    )}
                    items={pontoons
                        .filter(
                            (u) =>
                                !selectedRecipients.find(
                                    (s) =>
                                        s.type === 'pontoon' && s.key === u.id,
                                ),
                        )
                        .sort(cmpByName)
                        .map((p) => {
                            const pontoonBoats = p.places.map((p) =>
                                boats.find((b) => b.place && b.place.id === p.id),
                            );
                            const pontoonUsers = pontoonBoats.reduce(
                                (u: any, b: IBoat | undefined) => {
                                    if (b && b.owners.length > 0) {
                                        return [...u, ...b.owners];
                                    }
                                    return u;
                                },
                                [],
                            ) as User[];
                            return {
                                type: 'pontoon',
                                key: p.id,
                                name: p.name,
                                users: pontoonUsers,
                            };
                        })}
                />
                <AvailableItemList
                    onClick={addItem}
                    title={Locale.trans(
                        'communication.notification.recipients.users',
                    )}
                    items={users
                        .filter(
                            (u) =>
                                !selectedRecipients.find(
                                    (s) => s.type === 'user' && s.key === u.id,
                                ),
                        )
                        .map((u) => {
                            return {
                                type: 'user',
                                key: u.id,
                                name: getUserNameAndBoat(u),
                                users: [u],
                            };
                        })}
                />
            </div>
            <div className="__section __selected-section">
                <SelectedItemList
                    onChange={onChange}
                    title={Locale.trans(
                        'communication.notification.recipients',
                    )}
                    items={selectedRecipients}
                />
            </div>
        </Modal>
    );
}

interface AvailableItemListProps {
    items: UserListRecipients[];
    title: string;
    onClick: (item: UserListRecipients) => void;
}

function AvailableItemList({ title, items, onClick }: AvailableItemListProps) {
    const [searchText, setSearchText] = useState('');

    function filter(item: UserListRecipients) {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
    }

    const filteredItems = items
        .filter(filter)
        .sort((i1, i2) =>
            StringService.compareCaseInsensitive(i1.name, i2.name),
        );

    return (
        <div className="__item-list">
            <header className="__header">
                <h3 className="__title">{title}</h3>
                <Input.Search
                    className="__search"
                    placeholder={Locale.trans('search')}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </header>
            <section className="__content">
                {filteredItems.length > 0 ? (
                    // @ts-ignore
                    <List
                        height={100}
                        overscanRowCount={4}
                        rowCount={filteredItems.length}
                        rowHeight={32}
                        rowRenderer={({ index, key, style }: any) => {
                            const item = filteredItems[index];
                            return (
                                <AvailableItem
                                    key={key}
                                    style={style}
                                    item={item}
                                    onClick={() => onClick(item)}
                                />
                            );
                        }}
                        width={400}
                    />
                ) : (
                    <div className="__no-data">{Locale.trans('emptyText')}</div>
                )}
            </section>
        </div>
    );
}

interface AvailableItemProps {
    item: UserListRecipients;
    onClick: () => void;
    style?: any;
}
function AvailableItem({ item, style, onClick }: AvailableItemProps) {
    return (
        <div className="__item" style={style}>
            <div className="__header" onDoubleClick={onClick}>
                <div>{item.name}</div>
                <Button icon="plus" type="primary" onClick={onClick} />
            </div>
        </div>
    );
}

interface SelectedItemListProps {
    items: UserListRecipients[];
    title: string;
    onChange: (newSelected: UserListRecipients[]) => void;
}

function SelectedItemList({ title, items, onChange }: SelectedItemListProps) {
    const [searchText, setSearchText] = useState('');

    function filter(item: UserListRecipients) {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
    }

    const filteredItems = items
        .filter(filter)
        .sort((i1, i2) =>
            StringService.compareCaseInsensitive(i1.name, i2.name),
        );

    function onRemoveItem(item: UserListRecipients) {
        const index = items.findIndex(
            (i) => i.type === item.type && i.key === item.key,
        );
        if (index > -1) {
            const newItems = [...items];
            newItems.splice(index, 1);
            onChange(newItems);
        }
    }

    function onChangeItem(newItem: UserListRecipients) {
        const index = items.findIndex(
            (i) => i.type === newItem.type && i.key === newItem.key,
        );
        if (index > -1) {
            const newItems = [...items];
            newItems[index] = newItem;
            onChange(newItems);
        }
    }

    return (
        <div className="__item-list">
            <header className="__header">
                <h3 className="__title">{title}</h3>
                <Input.Search
                    className="__search"
                    placeholder={Locale.trans('search')}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </header>
            <section className="__content">
                {filteredItems.length > 0 ? (
                    filteredItems.map((item) => (
                        <SelectedItem
                            key={item.type + item.key}
                            onRemove={() => onRemoveItem(item)}
                            onChange={onChangeItem}
                            item={item}
                        />
                    ))
                ) : (
                    <div className="__no-data">{Locale.trans('emptyText')}</div>
                )}
            </section>
        </div>
    );
}

interface SelectedItemProps {
    item: UserListRecipients;
    onRemove: () => void;
    onChange: (newItem: UserListRecipients) => void;
    style?: any;
}
function SelectedItem({ item, onRemove, onChange, style }: SelectedItemProps) {
    return (
        <div className="__item" style={style}>
            <div className="__header" onDoubleClick={onRemove}>
                <div>{item.name}</div>
                <Button icon="minus" onClick={onRemove} />
            </div>
            {item.type !== 'user' && (
                <div className="__content">
                    {item.users.map((user) => {
                        const checked =
                            !item.disabledUserIds ||
                            item.disabledUserIds.indexOf(user.id) === -1;
                        return (
                            <div
                                key={user.id}
                                className="__user"
                                onClick={() => {
                                    let newDisabled = item.disabledUserIds
                                        ? [...item.disabledUserIds]
                                        : [];

                                    const index = newDisabled.indexOf(user.id);
                                    if (index > -1) {
                                        newDisabled.splice(index, 1);
                                    } else {
                                        newDisabled.push(user.id);
                                    }

                                    const newItem = {
                                        ...item,
                                        disabledUserIds: newDisabled,
                                    };
                                    onChange(newItem);
                                }}
                            >
                                <Checkbox checked={checked} />{' '}
                                {getUserNameAndBoat(user)}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

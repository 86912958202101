import React from 'react';

import { Table, Button } from 'antd/lib/index';
import CreateUrgencePhoneNumberModal from 'pages/Configuration/CreateUrgencePhoneNumberModal.jsx';
import EditUrgencePhoneNumberModal from 'pages/Configuration/EditUrgencePhoneNumberModal.jsx';
import LoadingIcon from 'components/LoadingIcon.jsx';

import Locale from 'locale/LocaleFactory';

import UrgencePhoneNumberActions from 'actions/UrgencePhoneNumberActions';
import UrgencePhoneNumberStore from 'stores/UrgencePhoneNumberStore';
import HarbourStore from 'stores/HarbourStore';

/**
 * The list of the urgence phone numbers of a harbour
 */
export default class UrgencePhoneNumberConfiguration extends React.Component {
    constructor() {
        super();

        const phoneNumbers = UrgencePhoneNumberStore.getByHarbour(HarbourStore.getCurrentHarbour());

        this.state = {
            loading: !phoneNumbers.length,
            phoneNumbers,

            createPhoneNumberVisible: false,
            editPhoneNumberVisible: false,
            phoneNumberToEdit: null,
        };
    }

    componentDidMount() {
        HarbourStore.addCurrentHarbourChangeListener(this.onChangeCurrentHarbour);
        UrgencePhoneNumberStore.addChangeListener(this.receivePhoneNumbers);

        this.reloadData();
    }
    componentWillUnmount() {
        HarbourStore.removeCurrentHarbourChangeListener(this.onChangeCurrentHarbour);
        UrgencePhoneNumberStore.removeChangeListener(this.receivePhoneNumbers);
    }

    reloadData = () => {
        this.loadPhoneNumbers();
    };

    onChangeCurrentHarbour = () => {
        this.reloadData();
        this.receivePhoneNumbers();
    };

    loadPhoneNumbers = () => {
        this.setState({
            loading: true,
        });
        UrgencePhoneNumberActions.reloadByHarbour(HarbourStore.getCurrentHarbour()).then(() => {
            this.setState({
                loading: false,
            });
        });
    };

    receivePhoneNumbers = () => {
        const phoneNumbers = UrgencePhoneNumberStore.getByHarbour(HarbourStore.getCurrentHarbour());
        this.setState({
            phoneNumbers,
        });
    };

    showCreatePhoneNumberModal = () => {
        this.setState({
            createPhoneNumberVisible: true,
        });
    };
    hideCreatePhoneNumberModal = () => {
        this.setState({
            createPhoneNumberVisible: false,
        });
    };

    editPhoneNumber = (phoneNumber) => {
        this.setState({
            editPhoneNumberVisible: true,
            phoneNumberToEdit: phoneNumber,
        });
    };
    hideEditPhoneNumberModal = () => {
        this.setState({
            editPhoneNumberVisible: false,
            phoneNumberToEdit: null,
        });
    };

    render() {
        const { loading, phoneNumbers } = this.state;
        return (
            <div className="phone-numbers-list">
                {this.renderPhoneNumberTable()}
                {!loading && (
                    <div
                        className="actions-row"
                        style={{ marginTop: phoneNumbers.length > 0 ? '-50px' : 0 }}
                    >
                        <Button type="primary" icon="plus" onClick={this.showCreatePhoneNumberModal}>
                            {Locale.trans('urgencePhoneNumber.creation.btn')}
                        </Button>
                    </div>
                )}

                <CreateUrgencePhoneNumberModal
                    onCancel={this.hideCreatePhoneNumberModal}
                    visible={this.state.createPhoneNumberVisible}
                />
                <EditUrgencePhoneNumberModal
                    phoneNumber={this.state.phoneNumberToEdit}
                    onCancel={this.hideEditPhoneNumberModal}
                    visible={this.state.editPhoneNumberVisible}
                />
            </div>
        );
    }

    renderPhoneNumberTable() {
        const { phoneNumbers } = this.state;

        const columns = [
            {
                title: Locale.trans('urgencePhoneNumber.name'),
                dataIndex: 'name',
                key: 'name',
                width: '350px',
            },
            {
                title: Locale.trans('urgencePhoneNumber.phoneNumber'),
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
                width: '200px',
            },
            {
                title: Locale.trans('urgencePhoneNumber.vhf'),
                dataIndex: 'vhf',
                key: 'vhf',
            },
        ];
        return (
            <Table
                dataSource={phoneNumbers}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
                loading={this.state.loading && { indicator: <LoadingIcon /> }}
                onRow={a => ({
                    className: 'clickable',
                    onClick: () =>
                        this.editPhoneNumber(a),
                })}
            />
        );
    }
}

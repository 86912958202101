import React from 'react';
import { Button, Icon, Tooltip } from 'antd';

import Locale from 'locale/LocaleFactory';

import DateService from 'services/utils/DateService';
import SecurityService from 'services/SecurityService';

import IncidentConstants from 'constants/IncidentConstants';
import RoleConstants from 'constants/RoleConstants';
import IncidentActions from 'actions/IncidentActions';
import ToastActions from 'actions/ToastActions';


function IncidentCard({
    incident,
    zone,
    author,
    onClick,
    onChangeZone,
}) {
    const canAcknowledge = SecurityService.isGranted([
        RoleConstants.SECURITY_OFFICER,
        RoleConstants.SURVEYOR,
    ]);
    const canClose = SecurityService.isGranted([RoleConstants.SURVEYOR]);

    const onClose = () => {
        IncidentActions.close(incident.id).then(() => {
            ToastActions.createToastSuccess('Incident clos');
        });
    };
    const acknowledgeIncident = () => {
        IncidentActions.acknowledge(incident.id).then(() => {
            ToastActions.createToastSuccess('Incident pris en compte');
        });
    };

  return (
    <div
      className={`incident-card ${incident.isCritical ? 'critical' : ''}`}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
    >
      <header className="incident-card-header">
        <h4 className="incident-card-title">
          <div className="incident-icon">
            <img
              src={`images/icon_${incident.incidentType}.svg`}
              alt={incident.incidentType}
            />
          </div>
          {Locale.trans(`incident.types.${incident.incidentType}`)}
          {incident.comment && incident.comment.length && (
            <span
              style={{
                display: 'inline-block',
                marginLeft: 8,
                fontStyle: 'italic',
              }}
            >
              ({incident.comment})
            </span>
          )}
        </h4>
        {incident.attachments.length > 0 && (
          <Tooltip title={`${incident.attachments.length} pièces jointes`}>
            <Icon type="paper-clip" />
          </Tooltip>
        )}
      </header>
      <section className="incident-card-content">
        <div className="incident-card-zone">
          {zone && (
            <React.Fragment>
              {`${Locale.trans('incident.zone')} : ${zone.name}`}
              <span className="incident-card-zone-color" />
            </React.Fragment>
          )}
          {!zone && onChangeZone && (
            <Button
              type="info"
              ghost
              onClick={(e) => {
                e.stopPropagation();
                onChangeZone();
              }}
              style={{ marginLeft: 8, height: 24 }}
            >
              {Locale.trans('incident.editZone')}
            </Button>
          )}
        </div>

                <div className="incident-card-status">
                    {`${Locale.trans('incident.status')} : ${
                        Locale.trans(`incident.statuses.${incident.status}`)
                    }`}
                </div>

        {author && (
          <div className="incident-author">
            {`${Locale.trans('incident.createdBy')} : ${author.firstName} ${
              author.lastName
            }`}
            {author.phoneNumber && (
              <span className="incident-author-phone">
                {`(Tel. ${author.phoneNumber} )`}
              </span>
            )}
          </div>
        )}
        <div className="incident-date">
          {`${Locale.trans('incident.createdAt')} : ${
              DateService.formatApiToDisplay(incident.createdAt, true)
          }`}
        </div>
        {incident.confirmedBy && incident.confirmedBy.length > 0 && (
          <div className="incident-confirmedBy">
            <Icon type="check-circle" />
            {incident.confirmedBy.length === 1
              ? Locale.trans('incident.confirmedByOne')
              : Locale.trans('incident.confirmedBy', {
                  COUNT: incident.confirmedBy.length,
                })}
          </div>
        )}
      </section>
      <footer className="incident-card-footer">
        <div className="incident-card-actions">
          {canAcknowledge && incident.status === IncidentConstants.PENDING && (
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                  acknowledgeIncident();
              }}
            >
              {Locale.trans('incident.action.acknowledge')}
            </Button>
          )}
          {canClose && incident.status === IncidentConstants.ACKNOWLEDGED && (
            <Button
              type="success"
              onClick={e => {
                e.stopPropagation();
                onClose();
              }}
            >
              {Locale.trans('incident.action.close')}
            </Button>
          )}
        </div>
      </footer>
    </div>
  );
}

export default IncidentCard;

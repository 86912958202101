import React from 'react';

export default class Loading extends React.PureComponent {
    render() {
        const { message } = this.props;
        const msg = typeof message === 'undefined' ? 'Chargement...' : message;
        return (
            <div className="loading-component">
                <div>
                    <div className="spinner">
                        <div className="dot1" />
                        <div className="dot2" />
                    </div>
                    {msg && <div className="loading-message">{msg}</div>}
                </div>
            </div>
        );
    }
}

import BaseStore from '../stores/BaseStore';

class CommitmentStore extends BaseStore {
    _entities: {[harbour_id: number]: {history: ICommitment[], current: ICommitment[]}};
    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: {type: string, payload: any}) => {
        const { type, payload } = action;
        switch (type) {
        case 'Commitment:receiveAllByHarbour':
            this._receiveAllByHarbour(payload.harbour, payload.commitments);
            break;
        case 'Commitment:receiveCurrentByHarbour':
            this._receiveCurrentByHarbour(payload.harbour, payload.commitments);
            break;
        case 'Commitment:receiveOneByHarbour':
            this._receiveOneByHarbour(payload.harbour, payload.commitment);
            break;

        default:
            // Do Nothing
            break;
        }
    };

    _receiveAllByHarbour = (harbour: IHarbour, commitments: ICommitment[]) => {
        if(!this._entities[harbour.id]) {
            this._entities[harbour.id] = {
                history: [],
                current: [],
            };
        }
        this._entities[harbour.id].history = commitments;
        this.emitChange();
    };

    _receiveCurrentByHarbour = (harbour: IHarbour, commitments: ICommitment[]) => {
        if(!this._entities[harbour.id]) {
            this._entities[harbour.id] = {
                history: [],
                current: [],
            };
        }
        this._entities[harbour.id].current = commitments;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, commitment: ICommitment) => {
        const commitments = this._entities[harbour.id];
        if (!commitments) {
            this._entities[harbour.id] = {
                history: [],
                current: []
            };
        }

        const iH = commitments.history.findIndex(c => c.id === commitment.id);
        if (iH > -1) {
            commitments.history[iH] = commitment;
        } else {
            commitments.history.push(commitment);
        }

        // If we receive a commitment and if has been stoped,
        // we remove it from the current commitments.
        const iC = commitments.current.findIndex(c => c.id === commitment.id);
        if (iC > -1 && commitment.endDate) {
            commitments.current.splice(iC, 1);
        } else if(iC === -1 && !commitment.endDate) {
            commitments.current = commitments.current.filter(c => c.userType !== commitment.userType);
            commitments.current.push(commitment);
        }

        this.emitChange();
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    /**
     * Get all the places of a harbour.
     */
    getByHarbour = (harbour: IHarbour | null | undefined) =>
        (harbour && this._entities[harbour.id] ? this._entities[harbour.id].history : []);

    getCurrentByHarbour = (harbour: IHarbour | null | undefined) =>
        (harbour && this._entities[harbour.id] ? this._entities[harbour.id].current : []);
}

export default new CommitmentStore();

import BaseStore from 'stores/BaseStore';
import AbsenceConstants from 'constants/AbsenceConstants';

import { Moment } from 'moment';

import DateService from 'services/utils/DateService';

class AbsenceStore extends BaseStore {
    _entities: { [harbour_id: number]: IAbsence[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string; payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
            case AbsenceConstants.RECEIVE_ABSENCES_OF_HARBOUR:
                this._receiveByHarbour(payload.harbour, payload.absences);
                break;
            case AbsenceConstants.RECEIVE_ABSENCE_OF_HARBOUR:
                this._receiveOneByHarbour(payload.harbour, payload.absence);
                break;
            case AbsenceConstants.DELETE_ABSENCE_OF_HARBOUR:
                this._deleteOneByHarbour(payload.harbour, payload.id);
                break;

            default:
                // Do Nothing
                break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, absences: IAbsence[]) => {
        this._entities[harbour.id] = absences;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, absence: IAbsence) => {
        if (!absence || !absence.id) {
            return;
        }

        const absences = this._entities[harbour.id];
        if (!absences) {
            this._entities[harbour.id] = [absence];
        } else {
            const i = absences.findIndex(c => c.id === absence.id);
            if (i > -1) {
                absences[i] = absence;
            } else {
                absences.push(absence);
            }
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, absenceId: number) => {
        const absences = this._entities[harbour.id];
        if (!absences) {
            return;
        }

        const i = absences.findIndex(c => c.id === absenceId);
        if (i > -1) {
            absences.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    /**
     * Get all the absences of a harbour.
     */
    getByHarbour = (harbour: Entity): IAbsence[] =>
        harbour ? this._entities[harbour.id] || [] : [];

    countPendingAbsenceByHarbour = (harbour: IHarbour): number =>
        this.getByHarbour(harbour).filter(
            a => a.status === AbsenceConstants.PENDING,
        ).length;

    countPendingAnticipatedReturnByHarbour = (harbour: IHarbour): number =>
        this.getByHarbour(harbour).filter(
            a => a.anticipatedReturnStatus === AbsenceConstants.PENDING,
        ).length;

    getOneByPlaceAndDate(
        place: IPlace,
        date: Date,
    ): IAbsence | null | undefined {
        return (
            place &&
            this.getByHarbour(place.harbour).find(
                f =>
                    f.place &&
                    f.place.id.toString() === place.id.toString() &&
                    AbsenceStore.absenceAtDate(date, f),
            )
        );
    }
    getByPlaceBetweenDates(
        place: IPlace,
        start: Date | Moment,
        end: Date | Moment,
    ): IAbsence[] {
        return place
            ? this.getByHarbour(place.harbour).filter(f => {
                  if (
                      !f.place ||
                      f.place.id.toString() !== place.id.toString()
                  ) {
                      return false;
                  }
                  return (
                      DateService.parseApi(f.returnDate) >= start &&
                      DateService.parseApi(f.departureDate) <= end
                  );
              })
            : [];
    }

    getByPlaceAndDate(place: IPlace, date: Date): IAbsence[] {
        return place
            ? this.getByHarbour(place.harbour).filter(
                  f =>
                      f.place &&
                      f.place.id.toString() === place.id.toString() &&
                      AbsenceStore.absenceAtDate(date, f),
              )
            : [];
    }

    getOnePendingAnticipatedReturnByPlaceAndDate(
        place: IPlace,
        date: Date,
    ): IAbsence | null | undefined {
        return (
            place &&
            this.getByHarbour(place.harbour).find(
                a =>
                    a.place &&
                    a.place.id.toString() === place.id.toString() &&
                    a.anticipatedReturnStatus === AbsenceConstants.PENDING &&
                    DateService.isBetween(
                        date,
                        a.anticipatedReturnDate,
                        a.returnDate,
                        '[)',
                    ),
            )
        );
    }

    getById = (id: number): IAbsence | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const absences = this._entities[harbourId] || [];
            const absence = absences.find(
                c => c.id.toString() === id.toString(),
            );
            if (absence) {
                return absence;
            }
        }
        return null;
    };

    getByBoat = (boat: IBoat): IAbsence[] =>
        boat
            ? this.getByHarbour(boat.harbour).filter(
                  a => a.boat && a.boat.id.toString() === boat.id.toString(),
              )
            : [];

    getOneByBoatAndDate(boat: IBoat, date: Date): IAbsence | null | undefined {
        return (
            boat &&
            this.getByHarbour(boat.harbour).find(
                f =>
                    f.boat.id.toString() === boat.id.toString() &&
                    AbsenceStore.absenceAtDate(date, f),
            )
        );
    }

    /**
     * Check if a date is between two dates.
     * Note that the two dates are included.
     * @param {Date | string} date It can be a Date object or a string in format YYYY-MM-DD.
     * @param {IAbsence} absence
     * @return {boolean}  TRUE if the date is between, else FALSE.
     */
    static absenceAtDate(date: Date | string, absence: IAbsence): boolean {
        return DateService.isBetween(
            date,
            absence.departureDate,
            absence.returnDate,
            '[)',
        );
    }
}

export default new AbsenceStore();

import BaseStore from '../stores/BaseStore';

class ExternalLinkStore extends BaseStore {
    _entities: { [harbour_id: number]: IExternalLink[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case 'ExternalLink:receiveByHarbour':
            this._receiveByHarbour(payload.harbour, payload.externalLinks);
            break;
        case 'ExternalLink:receiveOneByHarbour':
            this._receiveOneByHarbour(payload.harbour, payload.externalLink);
            break;
        case 'ExternalLink:deleteOneByHarbour':
            this._deleteOneByHarbour(payload.harbour, payload.id);
            break;

        default:
            // Do Nothing
            break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, externalLinks: IExternalLink[]) => {
        this._entities[harbour.id] = externalLinks;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, externalLink: IExternalLink) => {
        const externalLinks = this._entities[harbour.id];
        if (!externalLinks) {
            this._entities[harbour.id] = [externalLink];
        } else {
            const newLinks = [...externalLinks];
            const i = newLinks.findIndex(c => c.id === externalLink.id);
            if (i > -1) {
                newLinks[i] = externalLink;
            } else {
                newLinks.push(externalLink);
            }
            this._entities[harbour.id] = newLinks;
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, externalLinkId: number) => {
        const externalLinks = this._entities[harbour.id];
        if (!externalLinks) {
            return;
        }

        const i = externalLinks.findIndex(c => c.id === externalLinkId);
        if (i > -1) {
            externalLinks.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    /**
     * Get all the places of a harbour.
     */
    getByHarbour = (harbour: IHarbour): IExternalLink[] =>
        (harbour ? this._entities[harbour.id] || [] : []);

    getById = (id: number): IExternalLink | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const boats = this._entities[harbourId] || [];
            const boat = boats.find(c => c.id.toString() === id.toString());
            if (boat) {
                return boat;
            }
        }
        return null;
    };
}

export default new ExternalLinkStore();

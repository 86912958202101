import { useEffect, useState } from 'react';
import ExternalLinkStore from '../stores/ExternalLinkStore';
import ExternalLinkActions from '../actions/ExternalLinkActions';
import HarbourStore from 'stores/HarbourStore';

export default function useExternalLinksOfHarbour(harbour: Entity | undefined) {
    const [entities, setEntities] = useState<IExternalLink[]>([]);

    useEffect(() => {
        const h = harbour ? HarbourStore.getById(harbour.id) : null;
        function refresh() {
            setEntities(h ? ExternalLinkStore.getByHarbour(h) : []);
        }

        refresh();
        h && ExternalLinkActions.reloadByHarbour(h);

        ExternalLinkStore.addChangeListener(refresh);
        return () => {
            ExternalLinkStore.removeChangeListener(refresh);
        };
    }, [harbour]);

    return entities;
}

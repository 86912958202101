import { useEffect, useState } from 'react';
import IncidentStore from '../stores/IncidentStore';
import IncidentActions from '../actions/IncidentActions';
import HarbourStore from 'stores/HarbourStore';

export default function useIncidentsOfHarbour(harbour) {
    const [incidents, setIncidents] = useState([]);

    useEffect(() => {
        const currentHarbour = HarbourStore.getById(harbour.id);
        function refreshIncidentOfCurrentHarbour() {
            setIncidents(IncidentStore.getByHarbour(currentHarbour));
        }

        refreshIncidentOfCurrentHarbour();
        IncidentActions.reloadByHarbour(currentHarbour);

        IncidentStore.addChangeListener(refreshIncidentOfCurrentHarbour);
        return () => {
            IncidentStore.removeChangeListener(refreshIncidentOfCurrentHarbour);
        };
    }, [harbour.id]);

    return incidents;
}

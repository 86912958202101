import React from 'react';
import { Modal } from 'antd';

import AbsenceForm from 'components/forms/AbsenceForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import AbsenceActions from 'actions/AbsenceActions';

import BoatActions from 'actions/BoatActions';
import BoatStore from 'stores/BoatStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to create a new boat.
 */
export default class CreateAbsenceModal extends React.Component {
    newBoat = null;
    totalActions = 0;
    completedActions = 0;

    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {},
            boats: BoatStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        };
    }

    componentDidMount() {
        BoatStore.addChangeListener(this.receiveBoats);
        BoatActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    }
    componentWillUnmount() {
        BoatStore.removeChangeListener(this.receiveBoats);
    }

    receiveBoats = () => {
        const boats = BoatStore.getByHarbour(HarbourStore.getCurrentHarbour());
        this.setState({
            boats,
        });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (a) => {
        this.setState({
            loading: true,
        });
        const absence = {
            boat: a.boat,
            departureDate: {
                year: a.departureDate.year(),
                month: a.departureDate.month() + 1,
                day: a.departureDate.date(),
            },
            returnDate: {
                year: a.returnDate.year(),
                month: a.returnDate.month() + 1,
                day: a.returnDate.date(),
            },
        };
        AbsenceActions.create(HarbourStore.getCurrentHarbour(), absence)
            .then(() => {
                this.setState({
                    fields: {},
                    loading: false,
                });
                ToastActions.createToastSuccess(
                    Locale.trans('absence.creation.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        return (
            <Modal
                title={Locale.trans('absence.creation.title')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                footer={null}
            >
                <AbsenceForm
                    boats={this.state.boats}
                    onChange={this.handleFormChange}
                    fields={this.state.fields}
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                />
            </Modal>
        );
    }
}

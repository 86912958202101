import React from 'react';

import Panel from '../components/Panel.jsx';
import HarbourSvgMap from '../pages/HarbourMap/HarbourSvgMap.jsx';

import PlaceActions from '../actions/PlaceActions';
import PlaceStore from '../stores/PlaceStore';

import IncidentConstants from '../constants/IncidentConstants';
import IncidentActions from '../actions/IncidentActions';
import IncidentStore from '../stores/IncidentStore';

import ZoneActions from '../actions/ZoneActions';
import ZoneStore from '../stores/ZoneStore';

import HarbourStore from '../stores/HarbourStore';

/**
 * An interractive map showing all the places of the harbour and there status.
 */
export default class InterractiveMap extends React.Component {
    constructor() {
        super();
        this.state = {
            places: PlaceStore.getByHarbour(HarbourStore.getCurrentHarbour()),
            zones: ZoneStore.getByHarbour(HarbourStore.getCurrentHarbour()),
            incidents: IncidentStore.getByHarbour(HarbourStore.getCurrentHarbour()).filter(i => i.status !== IncidentConstants.CLOSED),
        };
    }

    componentDidMount() {
        HarbourStore.addCurrentHarbourChangeListener(this.onChangeCurrentHarbour);
        PlaceStore.addChangeListener(this.receivePlaces);
        ZoneStore.addChangeListener(this.receiveZones);
        IncidentStore.addChangeListener(this.receiveIncidents);
        this.reloadDataWithCurrentHarbour();
    }

    componentWillUnmount() {
        HarbourStore.removeCurrentHarbourChangeListener(this.onChangeCurrentHarbour);
        PlaceStore.removeChangeListener(this.receivePlaces);
        ZoneStore.removeChangeListener(this.receiveZones);
    }

    onChangeCurrentHarbour = () => {
        this.reloadDataWithCurrentHarbour();
        // Because the data is cached, it might not change if we change back to a port fastly,
        // so we force the change.
        this.receivePlaces();
        this.receiveZones();
        this.receiveIncidents();
    };

    reloadDataWithCurrentHarbour = () => {
        PlaceActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
        ZoneActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
        IncidentActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    };

    receivePlaces = () => {
        this.setState({
            places: PlaceStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        });
    };

    receiveZones = () => {
        this.setState({
            zones: ZoneStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        });
    };

    receiveIncidents = () => {
        this.setState({
            incidents: IncidentStore.getByHarbour(HarbourStore.getCurrentHarbour()).filter(i => i.status !== IncidentConstants.CLOSED),
        });
    };

    render() {
        const { places, zones, incidents } = this.state;
        return (
            <div>
                <Panel>
                    <HarbourSvgMap places={places} zones={zones} incidents={incidents} />
                </Panel>
            </div>
        );
    }
}

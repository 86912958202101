import React from 'react';
import { Modal } from 'antd';

import IncidentForm from 'components/forms/IncidentForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import IncidentActions from 'actions/IncidentActions';

import PlaceActions from 'actions/PlaceActions';
import PlaceStore from 'stores/PlaceStore';

import ZoneActions from 'actions/ZoneActions';
import ZoneStore from 'stores/ZoneStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to create a new incident.
 */
export default class CreateIncidentModal extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {},
            places: PlaceStore.getByHarbour(HarbourStore.getCurrentHarbour()),
            zones: ZoneStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        };
    }

    componentDidMount() {
        PlaceStore.addChangeListener(this.receivePlaces);
        PlaceActions.reloadByHarbour(HarbourStore.getCurrentHarbour());

        ZoneStore.addChangeListener(this.receiveZones);
        ZoneActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    }
    componentWillUnmount() {
        PlaceStore.removeChangeListener(this.receivePlaces);
        ZoneStore.removeChangeListener(this.receiveZones);
    }

    receivePlaces = () => {
        this.setState({
            places: PlaceStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        });
    };

    receiveZones = () => {
        this.setState({
            zones: ZoneStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (incident) => {
        this.setState({
            loading: true,
        });
        IncidentActions.create(HarbourStore.getCurrentHarbour(), incident)
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('incident.creation.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        const { places, zones, fields, loading } = this.state;
        const { visible, onCancel } = this.props;
        return (
            <Modal
                title={Locale.trans('incident.creation.title')}
                visible={visible}
                onCancel={onCancel}
                footer={null}
            >
                <IncidentForm
                    places={places}
                    zones={zones}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                />
            </Modal>
        );
    }
}

import React, { useEffect, useState } from 'react';
import SvgPath from './SvgPath';
import HarbourMapShapeStore from '../../stores/HarbourMapShapeStore';
import HarbourMapShapeActions from '../../actions/HarbourMapShapeActions';
import LoginStore from '../../stores/LoginStore';
import BaseUrlConstants from '../../constants/BaseUrlConstants';
import useCurrentHarbour from 'hooks/useCurrentHarbour';

function getHarbourPlanPictureUrl(harbour: Entity) {
    const token = LoginStore.getJwt();
    const formattedToken = token ? token.replace(/\+/g, '%2B') : '';
    return `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/plan?X-Auth-Token=${formattedToken}`;
}

function sortMapShapes(s1: IHarbourMapShape, s2: IHarbourMapShape) {
    if (s1.sort === s2.sort) {
        return 0;
    }
    return s1.sort > s2.sort ? 1 : -1;
}

interface Props {
    selectedShape: IHarbourMapShape;
    onShapeClick: (shape: IHarbourMapShape) => void;
    zoom: number;
    onSelectedShapeChange: (values: any) => void;
}
export default function HarbourMapBaseCalque({
    selectedShape,
    onShapeClick,
    zoom,
    onSelectedShapeChange,
}: Props) {
    const currentHarbour = useCurrentHarbour();
    const [harbourMapShapes, setHarbourMapShapes] = useState<
        IHarbourMapShape[]
    >([]);

    useEffect(() => {
        if (!currentHarbour) {
            return;
        }
        function refreshShapesOfCurrentHarbour() {
            setHarbourMapShapes(
                HarbourMapShapeStore.getByHarbour(currentHarbour),
            );
        }

        refreshShapesOfCurrentHarbour();
        HarbourMapShapeActions.reloadByHarbour(currentHarbour);

        HarbourMapShapeStore.addChangeListener(refreshShapesOfCurrentHarbour);
        return () => {
            HarbourMapShapeStore.removeChangeListener(
                refreshShapesOfCurrentHarbour,
            );
        };
    }, [currentHarbour]);

    // TODO : Remove this backware compatibility hack.
    // It's just here until all the harbours have the new map system.
    if (harbourMapShapes.length === 0 && currentHarbour) {
        return (
            <image
                xlinkHref={getHarbourPlanPictureUrl(currentHarbour)}
                height="1000"
                width="1000"
            />
        );
    }

    if (harbourMapShapes.length === 0) {
        return null;
    }

    return (
        <g
            className="harbour-map-base-calque"
            stroke="#4a4a4a"
            strokeWidth=".25"
        >
            {harbourMapShapes
                .filter(
                    (s) => !selectedShape || (s && s.id !== selectedShape.id),
                )
                .sort(sortMapShapes)
                .map((shape) => (
                    <SvgPath
                        key={shape.id}
                        d={shape.path}
                        fill={shape.path.endsWith('z') ? '#dddddd' : 'none'}
                        onClick={() => onShapeClick(shape)}
                        zoom={zoom}
                    />
                ))}
            {harbourMapShapes
                .filter((s) => selectedShape && s && s.id === selectedShape.id)
                .map((shape) => (
                    <SvgPath
                        key={shape.id}
                        d={shape.path}
                        fill={shape.path.endsWith('z') ? '#dddddd' : 'none'}
                        zoom={zoom}
                        editable
                        onChange={(path) =>
                            onSelectedShapeChange({
                                path,
                            })
                        }
                    />
                ))}
        </g>
    );
}

import React from 'react';
import Panel from '../Panel';
import Locale from '../../locale/LocaleFactory';
import OutingByBoatReportingStore from 'stores/OutingByBoatReportingStore';
import ReportingConstants from 'constants/ReportingConstants';
import { Popover } from 'antd';

const NUMBER_MAX_USERS = 20;

export default class Top10 extends React.Component {

    constructor() {
        super();
        this.state = {
            active: [],
            filterBy: ReportingConstants.TOP_10_FILTER.ALL
        };
    }

    componentDidMount() {
        OutingByBoatReportingStore.addChangeListener(() => this.changeData(this.state.filterBy));
    }

    changeData = (filterBy) => {
        const outingsByBoat = OutingByBoatReportingStore.getOutings().sort((e1, e2) => {
            if (filterBy === ReportingConstants.TOP_10_FILTER.NIGHT) {
                return e2.night - e1.night;
            }
            if (filterBy === ReportingConstants.TOP_10_FILTER.DAY) {
                return e2.day - e1.day;
            }
            return e2.all - e1.all;
        });
        this.setState({
            active: outingsByBoat.slice(0, NUMBER_MAX_USERS),
        });
    };

    toggleDisplay(option) {
        const { filterBy } = this.state;
        if (filterBy === option) {
            option = ReportingConstants.TOP_10_FILTER.ALL;
        }
        this.changeData(option);
        this.setState({
            filterBy: option,
        });
    }

    render() {
        const { active, filterBy } = this.state;
        const max = active.reduce((maximum, current) => {
            if (filterBy === ReportingConstants.TOP_10_FILTER.NIGHT) {
                return Math.max(current.night, maximum);
            }
            if (filterBy === ReportingConstants.TOP_10_FILTER.DAY) {
                return Math.max(current.day, maximum);
            }
            return Math.max(current.all, maximum);
        }, 0);
        return (
            <Panel
                title={Locale.trans('reporting.top_10')}
                className="reporting top10"
            >
                <ol>
                    {active.map(e => this.renderBoat(e, max))}
                </ol>
                {this.renderLegend()}
            </Panel>
        );
    }

    renderLegend = () => {
        const { filterBy } = this.state;
        const dayFaded = filterBy === ReportingConstants.TOP_10_FILTER.NIGHT;
        const nightFaded = filterBy === ReportingConstants.TOP_10_FILTER.DAY;
        return (
            <ul className="legend">
                <li>
                    <span 
                        className={ "legend-display top10 night clickable " + (nightFaded ? 'faded' : '') }
                        onClick={() => this.toggleDisplay(ReportingConstants.TOP_10_FILTER.NIGHT)}
                    /> 
                    {Locale.trans('reporting.top10.by_night')}
                </li>
                <li>
                    <span 
                        className={"legend-display top10 day clickable " + (dayFaded ? 'faded' : '')}
                        onClick={() => this.toggleDisplay(ReportingConstants.TOP_10_FILTER.DAY)}
                    /> 
                    {Locale.trans('reporting.top10.by_day')}
                </li>
            </ul>
        );
    };
    

    renderBoat = (e, max) => {
        const { filterBy } = this.state;
        let nbPlaces = e.all;
        if (filterBy === ReportingConstants.TOP_10_FILTER.NIGHT) {
            nbPlaces = e.night;
        }
        if (filterBy === ReportingConstants.TOP_10_FILTER.DAY) {
            nbPlaces = e.day;
        }
        return (
            <li key={e.boat.id}>
                <div>
                    <Popover
                        title={`${e.boat.name} (${e.users.map(user => `${user.lastName} ${user.firstName}`).join(' & ')})`}
                        placement="right"
                        content={(
                            <div>
                                <h5>
                                    {Locale.trans('reporting.top10.number_outing')}
                                </h5>
                                <ul>
                                    <li>{Locale.trans('reporting.top10.by_night')}: {e.night}</li>
                                    <li>{Locale.trans('reporting.top10.by_day')}: {e.day}</li>
                                </ul>
                                <h5>{Locale.trans('reporting.top10.relative_night')}</h5>
                                <ul><li>{Math.round((e.night / e.all) * 10000) / 100} %</li></ul>
                            </div>
                        )}
                    >
                        <span className="boat">
                            {e.boat.name}: {nbPlaces}
                        </span>
                    </Popover>
                    {this.renderBars(e, max)}
                </div>
            </li>
        );
    };

    renderBars = (e, max) => {
        const { filterBy } = this.state;
        const width = (e) => {
            if (filterBy === ReportingConstants.TOP_10_FILTER.NIGHT) {
                return (e.night / max) * 100;
            }
            if (filterBy === ReportingConstants.TOP_10_FILTER.DAY) {
                return (e.day / max) * 100;
            }
            return (e.all / max) * 100;
        };
        let content = null;
        if (filterBy === ReportingConstants.TOP_10_FILTER.NIGHT) {
            content = (  
                <span className="night" style={{ width: (e.night/ max) * width(e) + '%' }}/>
            );
        } else if (filterBy === ReportingConstants.TOP_10_FILTER.DAY) {
            content = (  
                <span className="day" style={{ width: (e.day/ max) * width(e) + '%' }}/>
            );
        } else {
            content = (
                <React.Fragment>
                    <span className="night" style={{ width: `${(e.night / max) * width(e)}%` }} />
                    <span
                        className="day"
                        style={{
                            width: `${(e.day / max) * width(e)}%`,
                            left: `${(e.night / max) * width(e)}%`,
                        }}
                    />
                </React.Fragment>
            );
        }
        return (
            <span className="bar" style={{ width: width(e) + '%' }}>
                {content}
            </span>
        )
    }
};

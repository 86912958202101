import React, { useState } from 'react';
import { Form, Button, Input } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';
import PartnerTypeConstants from 'constants/PartnerTypeConstants';
import DeleteButton from 'components/forms/DeleteButton';

import './PartnerForm.scss';
import PartnerPictures from './PartnerPictures';
import useCurrentHarbour from 'hooks/useCurrentHarbour';
import LoginStore from 'stores/LoginStore';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import ToastActions from 'actions/ToastActions';
import UploadButton from 'components/UploadButton';
import useHarbourModules, { ADVANCED, PREMIUM } from 'hooks/useHarbourModules';
import SecurityService from 'services/SecurityService';
import RoleConstants from 'constants/RoleConstants';

class PartnerForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                let entity = this.getEntityFromValues(values);
                entity.websites &&
                    entity.websites.forEach((w) => (w.id = undefined));
                this.props.onSubmit(entity);
            }
        });
    };

    getEntityFromValues = (values) => {
        const entity = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.forEach((k) => {
            if (k.endsWith('_ids')) {
                const tK = k.replace('_ids', '');
                entity[tK] = values[k].map((v) => parseInt(v));
                ignoredKeys.push(tK);
            } else if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                entity[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                entity[k] = values[k];
            }
        });
        return entity;
    };

    render() {
        const { form, partner, loading, onDelete } = this.props;
        return (
            <Form className="partner-form" onSubmit={this.handleSubmit}>
                <FormItem.Input
                    id="name"
                    required
                    label={Locale.trans('partner.name')}
                    form={form}
                    validateStatus={this.getValidateStatus('name')}
                    help={this.getHelp('name')}
                    hasFeedback
                />
                {this.renderTypeField()}
                <FormItem.TextArea
                    id="description"
                    label={Locale.trans('partner.description')}
                    form={form}
                    validateStatus={this.getValidateStatus('description')}
                    help={this.getHelp('description')}
                    hasFeedback
                />
                <FormItem.Input
                    id="email"
                    label={Locale.trans('partner.email')}
                    form={form}
                    validateStatus={this.getValidateStatus('email')}
                    help={this.getHelp('email')}
                    hasFeedback
                />
                <FormItem.PhoneInput
                    id="phone"
                    label={Locale.trans('partner.phone')}
                    form={form}
                    validateStatus={this.getValidateStatus('phone')}
                    help={this.getHelp('phone')}
                    hasFeedback
                />
                <WebSitesInput form={form} />
                <AddressInput form={form} />
                <HoursInput form={form} />
                <FormItem.TextArea
                    id="miscellanous"
                    label={Locale.trans('partner.miscellanous')}
                    form={form}
                    validateStatus={this.getValidateStatus('miscellanous')}
                    help={this.getHelp('miscellanous')}
                    hasFeedback
                />

                {partner && partner.id && <PicturesField partner={partner} />}

                <Button
                    type="primary"
                    htmlType="submit"
                    className="__save-btn"
                    loading={loading}
                >
                    {Locale.trans('save')}
                </Button>

                {onDelete && (
                    <DeleteButton className="__save-btn" onDelete={onDelete} />
                )}
            </Form>
        );
    }
    renderTypeField() {
        const { form } = this.props;
        const { getFieldValue } = form;

        const options = Object.values(PartnerTypeConstants)
            .filter((v) => v !== PartnerTypeConstants.HARBOUR_OFFICE)
            .map((t) => ({
                value: t,
                label: Locale.trans(`partner.types.${t}`),
            }));

        if (getFieldValue('type') === PartnerTypeConstants.HARBOUR_OFFICE) {
            return null;
        }

        return (
            <FormItem.Select
                id="type"
                required
                showSearch
                label={Locale.trans('partner.type')}
                initialValue={getFieldValue('type')}
                options={options}
                form={form}
                validateStatus={this.getValidateStatus('type')}
                help={this.getHelp('type')}
                hasFeedback
            />
        );
    }
}

function PicturesField({ partner }) {
    const modules = useHarbourModules();

    if (partner && partner.id && (partner.type === PartnerTypeConstants.HARBOUR_OFFICE || SecurityService.isGranted(RoleConstants.ADMINISTRATOR))) {

        return (
            <>
                {modules.info >= PREMIUM &&
                    partner.type !== PartnerTypeConstants.HARBOUR_OFFICE && (
                        <div className="__thumbnail">
                            <div>{Locale.trans('partner.thumbnail')}</div>
                            <Thumbnail partnerId={partner.id} />
                        </div>
                    )}
                {modules.info >= ADVANCED && (
                    <fieldset className="__pictures">
                        <legend>{Locale.trans('partner.pictures')}</legend>
                        <PartnerPictures partnerId={partner.id} />
                    </fieldset>
                )}
            </>
        );
    }
    return null;
}

function Thumbnail({ partnerId }) {
    const [timestamp, setTimestamp] = useState(Date.now());
    const harbour = useCurrentHarbour();
    const token = LoginStore.getJwt();
    const formattedToken = token ? token.replace(/\+/g, '%2B') : '';
    const url = `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/partners/${partnerId}/thumbnail`;
    return (
        <UploadButton
            single
            noIcon
            url={`harbours/${harbour.id}/partners/${partnerId}/thumbnail`}
            onError={() =>
                ToastActions.createToastError(
                    Locale.trans('partner.picture.add.error'),
                )
            }
            onSuccess={() => setTimestamp(Date.now())}
            accept="image/png, image/jpeg"
        >
            <img
                alt={Locale.trans('partner.thumbnail')}
                src={`${url}?X-Auth-Token=${formattedToken}&t=${timestamp}`}
            />
        </UploadButton>
    );
}

function AddressInput({ form }) {
    form.getFieldDecorator('address', { initialValue: {} });
    const address = form.getFieldValue('address');
    function handleFieldChange(day, newValue) {
        form.setFieldsValue({
            address: { ...form.getFieldValue('address'), [day]: newValue },
        });
    }
    return (
        <fieldset className="__address">
            <legend>{Locale.trans('address.title')}</legend>
            <div>
                <div>{Locale.trans('address.street')}</div>
                <Input.TextArea
                    value={address.street}
                    onChange={(e) =>
                        handleFieldChange('street', e.target.value)
                    }
                />
            </div>
            <div className="__city">
                <div>
                    <div>{Locale.trans('address.postalCode')}</div>
                    <Input
                        value={address.postalCode}
                        onChange={(e) =>
                            handleFieldChange('postalCode', e.target.value)
                        }
                    />
                </div>
                <div>
                    <div>{Locale.trans('address.city')}</div>
                    <Input
                        value={address.city}
                        onChange={(e) =>
                            handleFieldChange('city', e.target.value)
                        }
                    />
                </div>
            </div>
            <div>
                <div>{Locale.trans('address.country')}</div>
                <Input
                    value={address.country}
                    onChange={(e) =>
                        handleFieldChange('country', e.target.value)
                    }
                />
            </div>
        </fieldset>
    );
}

function HoursInput({ form }) {
    form.getFieldDecorator('hours', { initialValue: {} });
    const hours = form.getFieldValue('hours');
    function handleFieldChange(day, newValue) {
        form.setFieldsValue({
            hours: { ...form.getFieldValue('hours'), [day]: newValue },
        });
    }
    return (
        <fieldset>
            <legend>{Locale.trans('partner.hours')}</legend>
            {[
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday',
            ].map((day) => (
                <div key={day}>
                    <div>{Locale.trans(day)}</div>
                    <Input
                        value={hours[day]}
                        onChange={(e) => handleFieldChange(day, e.target.value)}
                    />
                </div>
            ))}
        </fieldset>
    );
}

function WebSitesInput({ form }) {
    form.getFieldDecorator('websites', { initialValue: [] });

    function handleFieldChange(index, field, newValue) {
        const websites = form.getFieldValue('websites');
        if (index < websites.length) {
            let newWebsites = [...websites];
            newWebsites[index] = { ...newWebsites[index], [field]: newValue };
            form.setFieldsValue({
                websites: newWebsites,
            });
        }
    }

    function addWebsite() {
        form.setFieldsValue({
            websites: [
                ...form.getFieldValue('websites'),
                { label: '', url: '' },
            ],
        });
    }

    function deleteWebsite(index) {
        const websites = form.getFieldValue('websites');
        if (index < websites.length) {
            let newWebsites = [...websites];
            newWebsites.splice(index, 1);
            form.setFieldsValue({
                websites: newWebsites,
            });
        }
    }

    return (
        <fieldset>
            <legend>{Locale.trans('partner.websites')}</legend>
            {form.getFieldValue('websites').map((website, i) => (
                <div key={i} className="__website">
                    <div className="__fields">
                        <Input
                            placeholder={Locale.trans('partner.website.label')}
                            value={website.label}
                            onChange={(e) =>
                                handleFieldChange(i, 'label', e.target.value)
                            }
                        />
                        <Input
                            value={website.url}
                            placeholder="https://"
                            onChange={(e) =>
                                handleFieldChange(i, 'url', e.target.value)
                            }
                        />
                    </div>
                    <div className="__actions">
                        <DeleteButton
                            shape="circle"
                            onDelete={() => deleteWebsite(i)}
                        />
                    </div>
                </div>
            ))}
            <Button onClick={addWebsite}>
                {Locale.trans('partner.website.add')}
            </Button>
        </fieldset>
    );
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(PartnerForm);

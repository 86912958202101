import React from 'react';
import { Modal } from 'antd/lib/index';

import UserForm from 'components/forms/UserForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import UserActions from 'actions/UserActions';
import HarbourStore from 'stores/HarbourStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to create a new user.
 */
export default class CreateUserModal extends React.Component {
    newUser = null;
    totalActions = 0;
    completedActions = 0;

    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {},
        };
    }

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (user) => {
        this.newUser = null;
        this.totalActions = 0;
        this.completedActions = 0;

        this.setState({
            loading: true,
        });
        UserActions.create(HarbourStore.getCurrentHarbour(), user)
            .then((newUser) => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(Locale.trans(
                    'user.creation.success',
                    { name: newUser.firstName },
                ));
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        return (
            <Modal
                title={Locale.trans('user.creation.title')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                footer={null}
            >
                <UserForm
                    onChange={this.handleFormChange}
                    fields={this.state.fields}
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                />
            </Modal>
        );
    }
}

import React from 'react';
import { Modal, Row, Col, Button } from 'antd/lib/index';

import PlaceZoneForm from 'components/forms/PlaceZoneForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import PlaceZoneActions from 'actions/PlaceZoneActions';

import PlaceActions from 'actions/PlaceActions';
import PlaceStore from 'stores/PlaceStore';
import ZoneForm from 'components/forms/ZoneForm';
import ZoneActions from 'actions/ZoneActions';
import HarbourMapShapeActions from 'actions/HarbourMapShapeActions';
import HarbourMapShapeStore from 'stores/HarbourMapShapeStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to create a new place zone.
 */
export default class CreateZoneModal extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {},
            places: PlaceStore.getByHarbour(
                HarbourStore.getCurrentHarbour(),
            ).filter((p) => !p.placeZone),

            zoneType: null,
            zoneFields: {},
        };
    }

    componentDidMount() {
        PlaceStore.addChangeListener(this.receivePlaces);
        PlaceActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    }

    componentWillUnmount() {
        PlaceStore.removeChangeListener(this.receivePlaces);
    }

    receivePlaces = () => {
        this.setState({
            places: PlaceStore.getByHarbour(
                HarbourStore.getCurrentHarbour(),
            ).filter((p) => !p.placeZone),
        });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };
    handleZoneFormChange = (changedFields) => {
        this.setState(({ zoneFields }) => ({
            zoneFields: { ...zoneFields, ...changedFields },
        }));
    };

    handleSubmit = (z, place) => {
        const { svgPoints } = this.props;

        const zone = {
            svgPoints,
        };

        this.setState({
            loading: true,
        });
        PlaceZoneActions.create(HarbourStore.getCurrentHarbour(), place, zone)
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('place.creation.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleZoneFormSubmit = (values) => {
        const { svgPoints } = this.props;

        const zone = {
            svgPoints,
            name: values.name,
        };

        this.setState({
            loading: true,
        });
        ZoneActions.create(HarbourStore.getCurrentHarbour(), zone)
            .then(() => {
                this.setState({
                    zoneFields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('zone.creation.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    createMapShape = (strokeOnly) => {
        const { svgPoints, onCancel } = this.props;

        const shapes = HarbourMapShapeStore.getByHarbour(
            HarbourStore.getCurrentHarbour(),
        );

        const shape = {
            path: strokeOnly
                ? svgPoints.substr(0, svgPoints.length - 2)
                : svgPoints,
            sort:
                shapes.reduce(
                    (maxSort, s) => (s.sort > maxSort ? s.sort : maxSort),
                    0,
                ) + 1,
        };

        this.setState({
            loading: true,
        });
        HarbourMapShapeActions.create(HarbourStore.getCurrentHarbour(), shape)
            .then(() => {
                ToastActions.createToastSuccess(
                    Locale.trans('zone.creation.success'),
                );
                onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    setZoneType(type) {
        this.setState({
            zoneType: type,
        });
    }

    render() {
        const { places, fields, zoneFields, loading, zoneType } = this.state;
        const { visible, onCancel } = this.props;

        let content = null;
        let title = '';
        if (zoneType === 'place') {
            title = Locale.trans('place.creation.title');
            content = (
                <PlaceZoneForm
                    places={places}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                    onBack={() => this.setZoneType(null)}
                />
            );
        } else if (zoneType === 'zone') {
            title = Locale.trans('zone.creation.title');
            content = (
                <ZoneForm
                    onChange={this.handleZoneFormChange}
                    onSubmit={this.handleZoneFormSubmit}
                    loading={loading}
                    fields={zoneFields}
                    onBack={() => this.setZoneType(null)}
                />
            );
        } else {
            title = Locale.trans('zone.creation.choice');
            content = this.renderChoiceContent();
        }
        return (
            <Modal
                title={title}
                visible={visible}
                onCancel={onCancel}
                footer={null}
            >
                {content}
            </Modal>
        );
    }

    renderChoiceContent() {
        return (
            <Row type="flex" justify="space-around" align="middle">
                <Col span={6}>
                    <Button
                        size="large"
                        type="primary"
                        onClick={() => this.setZoneType('zone')}
                    >
                        {Locale.trans('zone.zone')}
                    </Button>
                </Col>
                <Col span={6}>
                    <Button
                        size="large"
                        type="default"
                        onClick={() => this.setZoneType('place')}
                    >
                        {Locale.trans('boat.place')}
                    </Button>
                </Col>
                <Col span={6}>
                    <Button
                        size="large"
                        type="default"
                        onClick={() => this.createMapShape(false)}
                    >
                        {Locale.trans('map')}
                    </Button>
                </Col>
                <Col span={6}>
                    <Button
                        size="large"
                        type="default"
                        onClick={() => this.createMapShape(true)}
                    >
                        {Locale.trans('map.stroke')}
                    </Button>
                </Col>
            </Row>
        );
    }
}

import React from 'react';
import LoginStore from '../../stores/LoginStore';
import BaseUrlConstants from '../../constants/BaseUrlConstants';
import HarbourStore from '../../stores/HarbourStore';

function getHarbourPlanPictureUrl(harbour) {
    const token = LoginStore.getJwt();
    const formattedToken = token ? token.replace(/\+/g, '%2B') : '';
    return `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/plan?X-Auth-Token=${formattedToken}`;
}

/**
 * The harbour map blueprint is used as a model
 * to draw map, zones and places.
 * It's used only in Edit mode and is not visible
 * to the final user.
 */
function HarbourMapBlueprintCalque() {
    const currentHarbour = HarbourStore.getCurrentHarbour();
    return (
        <g>
            <image
                xlinkHref={getHarbourPlanPictureUrl(currentHarbour)}
                height="1000"
                width="1000"
            />
            <rect height="1000" width="1000" fillOpacity="0" />
        </g>
    );
}

export default HarbourMapBlueprintCalque;

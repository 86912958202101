// @flow

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class ActivityLogService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get summaries of activity for each boats of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getSummariesByHarbour(harbourId: number, summaryYear?: string, searchAxis?: string) {
        const maybeYear = summaryYear ? `year=${summaryYear}&` : '';
        const maybeAxis = searchAxis ? `by=${searchAxis}&` : '';
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/activity-log-summaries?${maybeYear}${maybeAxis}`,
            method: 'GET',
        });
    }

    /**
     * Get summaries of activity for each boat of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getCavalaireChallengeOutingsOfBoatAction(harbourId: number, boatId: number, year?: string) {
        const maybeYear = year ? `year=${year}` : '';
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/boats/${boatId}/cavalaire-challenge-logs?${maybeYear}`,
            method: 'GET',
        });
    }

    /**
     * Get summaries of activity for each place of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getCavalaireChallengeOutingsOfPlaceAction(harbourId: number, placeId: number, year?: string) {
        const maybeYear = year ? `year=${year}` : '';
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/places/${placeId}/cavalaire-challenge-logs?${maybeYear}`,
            method: 'GET',
        });
    }

}

export default new ActivityLogService();

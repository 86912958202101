import dispatcher from 'dispatchers/AppDispatcher';
import Promise from 'promise';
import ActionsBase from 'actions/ActionsBase';

import UserConstants from 'constants/UserConstants';
import UserService from 'services/UserService';

import CacheManager from 'services/CacheManager';
import HarbourStore from 'stores/HarbourStore';

const CACHE_DURATION = 30 * 1000;

class UserActions extends ActionsBase {
    create = (harbour: IHarbour, user: User) => {
        const $this = this;
        return new Promise((resolve: (user: User) => void, reject: (response: Object) => void): void => {
            function handleSuccess(newUser: User): void {
                dispatcher.dispatch({
                    type: UserConstants.RECEIVE_USER_OF_HARBOUR,
                    payload: {
                        harbour,
                        user: newUser,
                    },
                });

                resolve(newUser);
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            UserService.post(harbour.id, user)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
    edit = (harbour: Entity, userId: number, user: User) => {
        const $this = this;
        return new Promise((resolve: (user: User) => void, reject: (response: Object) => void): void => {
            function handleSuccess(newUser: User): void {
                dispatcher.dispatch({
                    type: UserConstants.RECEIVE_USER_OF_HARBOUR,
                    payload: {
                        harbour,
                        user: newUser,
                    },
                });
                resolve(newUser);
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            UserService.patch(harbour.id, userId, user)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
    delete = (harbour: Entity, id: number) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            function handleSuccess(): void {
                dispatcher.dispatch({
                    type: UserConstants.DELETE_USER_OF_HARBOUR,
                    payload: {
                        harbour,
                        id,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            UserService.remove(harbour.id, id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
    reload = () => {
        return this.reloadByHarbour(HarbourStore.getCurrentHarbour());
    };

    reloadByHarbour = (harbour: Entity | null | undefined) => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            if (
                !harbour ||
                CacheManager.isCached('User:reloadByHarbour', harbour.id.toString(), CACHE_DURATION)
            ) {
                resolve();
                return;
            }

            function handleSuccess(users: Array<User>): void {
                dispatcher.dispatch({
                    type: UserConstants.RECEIVE_USERS_OF_HARBOUR,
                    payload: {
                        harbour,
                        users,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            UserService.getByHarbour(harbour.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadCurrentUser = () => {
        const $this = this;
        return new Promise((resolve: () => void, reject: (response: Object) => void): void => {
            function handleSuccess(user: User): void {
                dispatcher.dispatch({
                    type: UserConstants.RECEIVE_CURRENT_USER,
                    payload: {
                        user,
                    },
                });
                resolve();
            }

            function handleError(err: Object): void {
                $this.handleError(err, reject);
            }

            UserService.getCurrent()
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

export default new UserActions();

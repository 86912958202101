import CountryUtil from './CountryUtil';

import libPhoneNumber from 'google-libphonenumber';

const { PhoneNumberUtil, AsYouTypeFormatter } = libPhoneNumber;

class PhoneNumber {
    phoneUtil: libphonenumber.PhoneNumberUtil;
    constructor() {
        this.phoneUtil = PhoneNumberUtil.getInstance();
    }

    getAllCountries() {
        return CountryUtil.getAll();
    }

    getDialCode(number: string) {
        let dialCode = '';
        // only interested in international numbers (starting with a plus)
        if (number && number.charAt(0) === '+') {
            let numericChars = '';
            // iterate over chars
            for (let i = 0; i < number.length; i++) {
                const c = number.charAt(i);
                // if char is number
                if (this.isNumeric(c)) {
                    numericChars += c;
                    // if current numericChars make a valid dial code
                    // if (this.countryCodes[numericChars]) {
                    if (CountryUtil.getCountryCodes()[numericChars]) {
                        // store the actual raw string (useful for matching later)
                        dialCode = number.substr(0, i + 1);
                    }
                    // longest dial code is 4 chars
                    if (numericChars.length === 4) {
                        break;
                    }
                }
            }
        }
        return dialCode;
    }

    getNumeric(str: string) {
        return str.replace(/\D/g, '');
    }

    isNumeric(n: string) {
        return !isNaN(parseFloat(n)) && isFinite(parseFloat(n));
    }

    getCountryCodeOfNumber(number: string): string {
        if (!number) {
            return '';
        }
        const dialCode = this.getDialCode(number);
        const numeric = this.getNumeric(dialCode);
        const countryCode = CountryUtil.getCountryCodes()[numeric];

        // countryCode[0] can be null -> get first element that is not null
        if (countryCode) {
            return countryCode.find((iso2) => !!iso2) || '';
        }

        return '';
    }

    parse(number: string, iso2?: string) {
        try {
            return this.phoneUtil.parse(number, iso2);
        } catch (err) {
            return null;
        }
    }

    isValidNumber(number: string, iso2?: string) {
        const phoneInfo = this.parse(number, iso2);

        if (phoneInfo) {
            return this.phoneUtil.isValidNumber(phoneInfo);
        }

        return false;
    }

    isValid(number: string) {
        const code = this.getCountryCodeOfNumber(number);
        if (!code || code.length === 0) {
            return false;
        }
        return this.isValidNumber(number, code);
    }

    format(number: string, iso2?: string) {
        const formatter = new AsYouTypeFormatter(
            iso2 ? iso2 : this.getCountryCodeOfNumber(number),
        );
        let formatted;

        number
            .replace(/[^\d+]/g, '')
            .split('')
            .forEach((n) => (formatted = formatter.inputDigit(n)));

        return formatted;
    }

    getCountryDataByCode(iso2: string) {
        return CountryUtil.getCountryDataByCode(iso2);
    }
}

export default new PhoneNumber();

import React from 'react';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import useCurrentHarbour from 'hooks/useCurrentHarbour';
import useQuery from 'hooks/useQuery';
import { Badge } from 'antd';

export default function PendingAccessRequestsCounter() {
    const harbour = useCurrentHarbour();
    const { data } = useQuery({
        url: `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/access-requests`,
        polling: 10,
    });

    const filteredData = data ? data.filter((d) => d.status === 'pending') : [];
    return <Badge count={filteredData.length} />;
}

import React from 'react';
import { Button, Popover } from 'antd';
import SecurityService from 'services/SecurityService';
import RoleConstants from 'constants/RoleConstants';
import IncidentConstants from 'constants/IncidentConstants';
import Locale from 'locale/LocaleFactory';
import IncidentActions from 'actions/IncidentActions';
import ToastActions from 'actions/ToastActions';

function HarbourMapIncident({
    incident,
    position,
    onClick,
    onShowIncidentClick,
}) {
    const acknowledgeIncident = () => {
        IncidentActions.acknowledge(incident.id).then(() => {
            ToastActions.createToastSuccess('Incident pris en compte');// TODO trad
        });
    };

    const closeIncident = () => {
        IncidentActions.close(incident.id).then(() => {
            ToastActions.createToastSuccess('Incident clos');// TODO trad
        });
    };
    const canAcknowledge = SecurityService.isGranted([
        RoleConstants.SECURITY_OFFICER,
        RoleConstants.SURVEYOR,
    ]);
    const canClose = SecurityService.isGranted([RoleConstants.SURVEYOR]);

    const content = (
        <div>
            {canAcknowledge && incident.status === IncidentConstants.PENDING && (
                <Button
                    type="primary"
                    style={{
                        display: 'block',
                        width: 220,
                        marginBottom: 8,
                    }}
                    onClick={(e) => {
                        acknowledgeIncident();
                        e.stopPropagation();
                    }}
                >
                    {Locale.trans('incident.action.acknowledge')}
                </Button>
            )}
            {canClose && incident.status === IncidentConstants.ACKNOWLEDGED && (
                <Button
                    type="success"
                    style={{
                        display: 'block',
                        width: 220,
                        marginBottom: 8,
                    }}
                    onClick={(e) => {
                        closeIncident();
                        e.stopPropagation();
                    }}
                >
                    {Locale.trans('incident.action.close')}
                </Button>
            )}
            {onShowIncidentClick && incident.attachments.length > 0 && (
                <Button
                    type="info"
                    icon="paper-clip"
                    ghost
                    style={{
                        display: 'block',
                        width: 220,
                        marginBottom: 8,
                    }}
                    onClick={(e) => {
                        onShowIncidentClick();
                        e.stopPropagation();
                    }}
                >
                    {`${incident.attachments.length} pièces jointes`}
                </Button>
            )}
        </div>
    );

  return (
    <Popover
      key={incident.id}
      content={content}
      title={
        <div>
          {Locale.trans(`incident.types.${incident.incidentType}`)}
          {incident.comment && incident.comment.length && (
            <span
              style={{
                display: 'inline-block',
                marginLeft: 8,
                fontStyle: 'italic',
              }}
            >
              ({incident.comment})
            </span>
          )}
        </div>
      }
    >
      <g
        className={`incident-indicator ${
          incident.status === IncidentConstants.PENDING ? 'pending' : ''
        } ${incident.isCritical ? 'critical' : ''}`}
        transform={`translate(${position.x}, ${position.y})`}
        onClick={onClick}
      >
        <path d="M 0,0 C 0,8.1642207 -9.3973212,21.056891 -14.682521,26.342081 -20.076341,20.948261 -29.465251,8.1642307 -29.465251,0 -29.465251,-8.1643193 -22.846801,-14.782769 -14.682521,-14.782769 c 8.1642698,0 14.78271984,6.6184497 14.78271984,14.782719710505 z" />
        <circle
          r="15"
          cx="-15"
          cy="0"
          stroke="none"
          fill={`url(#icon-${incident.incidentType})`}
        />
        {incident.attachments.length > 0 && (
          <g className="incident-incidator-attachment">
            <circle r="7" cx="0" cy="-5" />
            <circle
              r="7"
              cx="0"
              cy="-5"
              stroke="none"
              fill="url(#icon-attachment)"
            />
          </g>
        )}
      </g>
    </Popover>
  );
}

export default HarbourMapIncident;

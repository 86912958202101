import React, { useState } from 'react';
import { Modal, Button, Icon, Slider, Tooltip, Checkbox } from 'antd';

import SelectField from 'components/form-fields/SelectField';

import SvgMap from 'components/SvgMap.jsx';
import CreatePositionModal from 'pages/HarbourMap/CreatePositionModal.jsx';
import CreateZoneModal from 'pages/HarbourMap/CreateZoneModal.jsx';
import ShowIncidentModal from 'pages/ShowIncidentModal';

import LoadingRate from 'components/charts/LoadingChart';

import SecurityService from 'services/SecurityService';
import MathService from 'services/utils/MathService';
import ToastActions from 'actions/ToastActions';
import Locale from 'locale/LocaleFactory';

import HarbourStore from 'stores/HarbourStore';

import PlaceZoneActions from 'actions/PlaceZoneActions';
import ZoneActions from 'actions/ZoneActions';

import BoatConstants from 'constants/BoatConstants';
import OccupancyRateReportingStore from 'stores/OccupancyRateReportingStore';
import ReportingActions from 'actions/ReportingActions';
import HarbourMapIncidentCalque from 'pages/HarbourMap/HarbourMapIncidentCalque';
import HarbourMapBaseCalque from 'pages/HarbourMap/HarbourMapBaseCalque';
import HarbourMapPlaceCalque from 'pages/HarbourMap/HarbourMapPlaceCalque';
import HarbourMapZoneCalque from 'pages/HarbourMap/HarbourMapZoneCalque';
import HarbourMapBlueprintCalque from 'pages/HarbourMap/HarbourMapBlueprintCalque';
import HarbourMapEditPanel from 'pages/HarbourMap/HarbourMapEditPanel';
import PlaceStore from 'stores/PlaceStore';
import HarbourMapShapeActions from 'actions/HarbourMapShapeActions';
import HarbourMapPositionStore from 'stores/HarbourMapPositionStore';
import HarbourMapPositionActions from 'actions/HarbourMapPositionActions';
import DeleteButton from 'components/forms/DeleteButton';
import LoginStore from 'stores/LoginStore';
import StringService from 'services/utils/StringService';
import FormService from 'services/utils/FormService';

function roundPrecision(number, precision) {
    const pow = 10 ** precision;
    return Math.round(number * pow) / pow;
}

function findDefaultPosition(positions) {
    return positions
        .sort((p1, p2) => {
            if (p1.user && !p2.user) {
                return -1;
            }
            if (!p1.user && p2.user) {
                return 1;
            }
            return 0;
        })
        .find((p) => p.isDefault);
}

/**
 * An interactive map showing all the places of the harbour and there status.
 */
export default class HarbourSvgMap extends React.Component {
    constructor() {
        super();

        const positions = HarbourMapPositionStore.getForCurrentHarbour();
        const defaultPosition = findDefaultPosition(positions);
        const initialPosition = defaultPosition
            ? this.getRelativePosition({
                  x: defaultPosition.positionX,
                  y: defaultPosition.positionY,
              })
            : { x: 0, y: 0 };
        const initialZoom = defaultPosition ? defaultPosition.zoom : 1;

        this.state = {
            viewMode: 'web', // Can be "web" or "mobile"

            width: 0,
            height: 0,
            zoom: initialZoom,
            position: initialPosition,
            drawingPolygonPoints: null,
            initialPosition,
            initialZoom,
            isCreatingZone: false,
            creatingZoneShape: null,
            isCreateZoneModalVisible: false,
            newZoneSvgPoints: null,

            filters: {
                boatTypes: [],
                startLength: null,
                endLength: null,
                startWidth: null,
                endWidth: null,

                placeStates: [],
            },
            previousOccupency: {
                mean: 0,
                total: 1,
            },

            canEdit: SecurityService.isGranted(['super-admin']),
            isEditing: false,

            calques: [
                {
                    id: 'blueprint',
                    name: 'Modèle',
                    visible: false,
                },
                {
                    id: 'map',
                    name: 'Plan',
                    visible: true,
                },
                {
                    id: 'zones',
                    name: 'Zones',
                    visible: true,
                },
                {
                    id: 'places',
                    name: 'Places',
                    visible: true,
                },
                {
                    id: 'incidents',
                    name: 'Incidents',
                    visible: true,
                },
            ],

            selectedItem: null,

            positions,
        };
    }

    componentDidMount() {
        const { positions } = this.state;
        OccupancyRateReportingStore.addChangeListener(
            this.receiveOccupancyRates,
        );
        ReportingActions.reloadPreviousMonthOccupancyRate();

        HarbourStore.addCurrentHarbourChangeListener(this.handleHarbourChange);

        HarbourMapPositionStore.addChangeListener(this.receiveMapPositions);
        HarbourMapPositionActions.reloadByHarbour(
            HarbourStore.getCurrentHarbour(),
        );

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        const defaultPosition = findDefaultPosition(positions);

        let initialPosition;
        if (defaultPosition) {
            initialPosition = this.getRelativePosition({
                x: defaultPosition.positionX,
                y: defaultPosition.positionY,
            });
        } else {
            initialPosition = this.getRelativePosition({
                x: -500,
                y: -500,
            });
        }

        this.setState({
            initialPosition,
            position: initialPosition,
        });
    }

    componentWillUnmount() {
        OccupancyRateReportingStore.removeChangeListener(
            this.receiveOccupancyRates,
        );
        HarbourStore.removeCurrentHarbourChangeListener(
            this.receiveMapPositions,
        );
        HarbourMapPositionStore.removeChangeListener(this.receiveMapPositions);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    handleHarbourChange = () => {
        this.receiveMapPositions();
        HarbourMapPositionActions.reloadByHarbour(
            HarbourStore.getCurrentHarbour(),
        );
    };

    getRelativePosition(position) {
        const windowDimension = this.getWindowDimension();
        return {
            x: windowDimension.width / 2 + position.x,
            y: windowDimension.height / 2 + position.y,
        };
    }

    getAbsolutePosition(position) {
        const windowDimension = this.getWindowDimension();
        return {
            x: position.x - windowDimension.width / 2,
            y: position.y - windowDimension.height / 2,
        };
    }

    receiveMapPositions = () => {
        const positions = HarbourMapPositionStore.getForCurrentHarbour();
        const defaultPosition = findDefaultPosition(positions);

        const initialPosition = defaultPosition
            ? this.getRelativePosition({
                  x: defaultPosition.positionX,
                  y: defaultPosition.positionY,
              })
            : this.state.initialPosition;
        const initialZoom = defaultPosition
            ? defaultPosition.zoom
            : this.state.initialZoom;

        this.setState({
            positions,
            initialPosition,
            initialZoom,
            zoom: initialZoom,
            position: initialPosition,
        });
    };

    receiveActivityLogs = () => {
        this.forceUpdate();
    };

    receiveOccupancyRates = () => {
        const data = OccupancyRateReportingStore.getMean();
        this.setState({
            previousOccupency: {
                mean: data.meanEmpty,
                total: data.nbPlaces,
            },
        });
    };

    placeMatchFilters = (place, status) => {
        const { filters } = this.state;
        const {
            boatTypes,
            startLength,
            endLength,
            startWidth,
            endWidth,
            placeStates,
        } = filters;

        if (placeStates.length > 0 && placeStates.indexOf(status) === -1) {
            return false;
        }

        if (boatTypes.length > 0) {
            if (
                boatTypes.findIndex(
                    (t) => place.boatTypes.indexOf(t) !== -1,
                ) === -1
            ) {
                return false;
            }
        }
        if (startLength && endLength) {
            if (place.length < startLength || place.length > endLength) {
                return false;
            }
        }
        if (startWidth && endWidth) {
            if (place.width < startWidth || place.width > endWidth) {
                return false;
            }
        }
        return true;
    };
    static polygonMatchFilters(polygon, filters) {
        const { placeStates } = filters;
        return (
            placeStates.length === 0 ||
            placeStates.indexOf(polygon.status) !== -1
        );
    }

    getWindowDimension = () => {
        if (!this.node) {
            return {
                width: 0,
                height: 0,
            };
        }
        const offset = this.node.getBoundingClientRect();
        return {
            width: window.innerWidth - offset.left - 16,
            height: window.innerHeight - offset.top - 24,
        };
    };

    updateWindowDimensions = () => {
        this.setState({
            ...this.getWindowDimension(),
        });
    };

    toggleCreateZone = (shape) => {
        this.setState((prev) => ({
            isCreatingZone: !prev.isCreatingZone,
            creatingZoneShape: shape,
            drawingPolygonPoints: null,
        }));
    };

    handleClick = (p) => {
        // TODO : Remake this to create path

        const { drawingPolygonPoints, creatingZoneShape } = this.state;

        const point = { ...p };

        // Here we need to fix the position of the point
        // to match the map.
        point.x -= 500.417;
        point.y -= 500.417;

        if (
            creatingZoneShape === 'rect' &&
            drawingPolygonPoints &&
            drawingPolygonPoints.length === 1
        ) {
            const p0 = drawingPolygonPoints[0];
            const svgPoints = `M${MathService.round(
                p0.x,
                3,
            )} ${MathService.round(p0.y, 3)} L${MathService.round(
                point.x,
                3,
            )} ${MathService.round(p0.y, 3)} L${MathService.round(
                point.x,
                3,
            )} ${MathService.round(point.y, 3)} L${MathService.round(
                p0.x,
                3,
            )} ${MathService.round(point.y, 3)} z`;
            this.setState({
                drawingPolygonPoints: null,
            });
            this.showCreateZoneModal(svgPoints);
            // TODO
        } else if (
            creatingZoneShape === 'circle' &&
            drawingPolygonPoints &&
            drawingPolygonPoints.length === 1
        ) {
            const p0 = drawingPolygonPoints[0];
            const r = MathService.getPointsDistance(p0, point);
            const delta = r/1.81;
            const svgPoints = 
            `M${p0.x-r} ${p0.y
            } C ${p0.x-r} ${p0.y - delta} ${p0.x-delta} ${p0.y-r} ${p0.x} ${p0.y-r
            } C ${p0.x+delta} ${p0.y-r} ${p0.x+r} ${p0.y-delta} ${p0.x+r} ${p0.y
            } C ${p0.x+r} ${p0.y+delta} ${p0.x+delta} ${p0.y+r} ${p0.x} ${p0.y+r
            } C ${p0.x-delta} ${p0.y+r} ${p0.x-r} ${p0.y+delta} ${p0.x-r} ${p0.y
            } z`;
            this.setState({
                drawingPolygonPoints: null,
            });
            this.showCreateZoneModal(svgPoints);
            // TODO
        } else if (
            creatingZoneShape === 'polygon' &&
            drawingPolygonPoints &&
            drawingPolygonPoints.length > 2 &&
            this.svgMap.areNearby(drawingPolygonPoints[0], point)
        ) {
            const svgPoints = `M${drawingPolygonPoints
                .map(
                    (s) =>
                        `${roundPrecision(s.x, 3)} ${roundPrecision(s.y, 3)}`,
                )
                .join(' L')} z`;
            this.setState({
                drawingPolygonPoints: null,
            });
            this.showCreateZoneModal(svgPoints);
        } else {
            const newDrawingPolygonPoints = this.state.drawingPolygonPoints
                ? [...this.state.drawingPolygonPoints]
                : [];
            newDrawingPolygonPoints.push(this.getClosestPoint(point));
            this.setState({
                drawingPolygonPoints: newDrawingPolygonPoints,
            });
        }
    };

    getClosestPoint = (point) => {
        const places = PlaceStore.getForCurrentHarbour();

        for (let i = 0; i < places.length; i++) {
            const place = places[i];
            if (place.placeZone) {
                const currentPath = place.placeZone.svgPoints;
                const points = MathService.convertStringToPoints(currentPath);
                const nearPoint = points.find((p) =>
                    this.svgMap.areNearby(p, point),
                );
                if (nearPoint) {
                    return nearPoint;
                }
            }
        }
        return point;
    };

    deleteZone = (zone) => {
        Modal.confirm({
            title: Locale.trans('zone.deletion.title', { name: zone.name }),
            content: Locale.trans('place.deletion.content'),
            okText: Locale.trans('delete'),
            okType: 'danger',
            cancelText: Locale.trans('cancel'),
            onOk: () => {
                ZoneActions.delete(HarbourStore.getCurrentHarbour(), zone.id)
                    .then(() => {
                        ToastActions.createToastSuccess(
                            Locale.trans('place.deletion.success'),
                        );
                        this.setState({
                            selectedItem: null
                        });
                    })
                    .catch(FormService.handleError);
            },
        });
    };

    deletePlace = (place) => {
        Modal.confirm({
            title: Locale.trans('place.deletion.title', { name: place.code }),
            content: Locale.trans('place.deletion.content'),
            okText: Locale.trans('delete'),
            okType: 'danger',
            cancelText: Locale.trans('cancel'),
            onOk: () => {
                PlaceZoneActions.delete(
                    HarbourStore.getCurrentHarbour(),
                    place.id,
                )
                    .then(() => {
                        ToastActions.createToastSuccess(
                            Locale.trans('place.deletion.success'),
                        );
                        this.setState({
                            selectedItem: null
                        });
                    })
                    .catch(FormService.handleError);
            },
        });
    };

    deleteShape = (shape) => {
        Modal.confirm({
            title: Locale.trans('mapShape.deletion.title'),
            content: Locale.trans('mapShape.deletion.content'),
            okText: Locale.trans('delete'),
            okType: 'danger',
            cancelText: Locale.trans('cancel'),
            onOk: () => {
                HarbourMapShapeActions.delete(
                    HarbourStore.getCurrentHarbour(),
                    shape.id,
                )
                    .then(() => {
                        ToastActions.createToastSuccess(
                            Locale.trans('mapShape.deletion.success'),
                        );
                        this.setState({
                            selectedItem: null
                        });
                    })
                    .catch(FormService.handleError);
            },
        });
    };

    showCreateZoneModal = (svgPoints) => {
        this.setState({
            isCreateZoneModalVisible: true,
            newZoneSvgPoints: svgPoints,
            isCreatingZone: false,
            creatingZoneShape: null,
        });
    };

    hideCreateZoneModal = () => {
        this.setState({
            isCreateZoneModalVisible: false,
            newZoneSvgPoints: null,
            isCreatingZone: false,
            creatingZoneShape: null,
        });
    };

    showCreatePositionModal = () => {
        this.setState({
            isCreatePositionModalVisible: true,
        });
    };

    hideCreatePositionModal = () => {
        this.setState({
            isCreatePositionModalVisible: false,
        });
    };

    showIncidentModal = (incident) => {
        this.setState({
            incidentToShow: incident,
        });
    };

    hideIncidentModal = () => {
        this.setState({
            incidentToShow: null,
        });
    };

    handleCalqueChange = (calqueId, visible) => {
        this.setState(({ calques }) => {
            const calque = calques.find((c) => c.id === calqueId);
            calque.visible = visible;
            return {
                calques,
            };
        });
    };

    isCalqueVisible = (calqueId) => {
        const { calques, viewMode } = this.state;

        const mobileCalques = ['map', 'zones', 'incidents'];
        if (viewMode === 'mobile' && mobileCalques.indexOf(calqueId) === -1) {
            return false;
        }

        const calque = calques.find((c) => c.id === calqueId);
        return !!(calque && calque.visible);
    };

    handleEditPanelClose = () => {
        this.setState({
            isEditing: false,
            selectedItem: null,

            isCreatingZone: false,
            creatingZoneShape: null,
            drawingPolygonPoints: null,
        });
    };

    handleEditPanelCancel = () => {
        this.setState({
            selectedItem: null,
        });
    };

    setSelectedItem = (selectedItem) => {
        this.setState({
            selectedItem,
        });
    };

    saveSelectedItem = () => {
        const { selectedItem } = this.state;
        if (!selectedItem) {
            return;
        }

        if (selectedItem.type === 'zone') {
            // TODO : Create the Zone if it doesn't have an ID
            const zone = { ...selectedItem.zone };
            const zoneId = zone.id;
            delete zone.id;
            delete zone.harbour;
            ZoneActions.edit(HarbourStore.getCurrentHarbour(), zoneId, zone)
                .then(() => {
                    ToastActions.createToastSuccess(
                        Locale.trans('zone.update.success'),
                    );
                })
                .catch(() => {
                    ToastActions.createToastError(
                        Locale.trans('zone.update.error'),
                    );
                });
        } else if (selectedItem.type === 'shape') {
            const shape = { ...selectedItem.shape };
            const shapeId = shape.id;
            delete shape.id;
            delete shape.harbour;
            HarbourMapShapeActions.edit(
                HarbourStore.getCurrentHarbour(),
                shapeId,
                shape,
            )
                .then(() => {
                    ToastActions.createToastSuccess(
                        Locale.trans('mapShape.update.success'),
                    );
                })
                .catch(() => {
                    ToastActions.createToastError(
                        Locale.trans('mapShape.update.error'),
                    );
                });
        } else if (selectedItem.type === 'place') {
            // TODO
            const zone = { ...selectedItem.place.placeZone };
            delete zone.id;
            PlaceZoneActions.edit(
                HarbourStore.getCurrentHarbour(),
                selectedItem.place,
                zone,
            )
                .then(() => {
                    ToastActions.createToastSuccess(
                        Locale.trans('place.update.success'),
                    );
                })
                .catch(() => {
                    ToastActions.createToastError(
                        Locale.trans('place.update.error'),
                    );
                });
        }
    };

    duplicateSelectedItem = () => {
        const { selectedItem } = this.state;
        if (!selectedItem) {
            return;
        }

        let svgPoints = null;
        switch(selectedItem.type) {
            case "zone":
                svgPoints = selectedItem.zone.svgPoints;
                break;
            case "place":
                svgPoints = selectedItem.place.placeZone.svgPoints;
                break;
            case "shape":
                svgPoints = selectedItem.shape.path;
                break;
            default: 
                //Nothing
        }

        if(!svgPoints) {
            return;
        }

        this.showCreateZoneModal(svgPoints);
    }

    setViewMode = (viewMode) => {
        this.setState({
            viewMode,
        });
    };

    render() {
        const {
            incidentToShow,
            isEditing,
            calques,
            selectedItem,
            viewMode,
        } = this.state;
        return (
            <div
                className={`harbour-map view-${viewMode}`}
                ref={(n) => {
                    this.node = n;
                }}
            >
                {this.renderSvgMap()}
                {this.renderActionButtons()}
                {this.renderSideBar()}

                {isEditing && (
                    <HarbourMapEditPanel
                        viewMode={viewMode}
                        calques={calques}
                        selectedItem={selectedItem}
                        onViewModeChange={this.setViewMode}
                        onSelectedItemChange={this.setSelectedItem}
                        onCalqueChange={this.handleCalqueChange}
                        onCreateZone={this.toggleCreateZone}
                        onSaveZone={this.saveSelectedItem}
                        onDeleteZone={this.deleteZone}
                        onSavePlace={this.saveSelectedItem}
                        onDeletePlace={this.deletePlace}
                        onSaveShape={this.saveSelectedItem}
                        onDeleteShape={this.deleteShape}
                        onClose={this.handleEditPanelClose}
                        onCancel={this.handleEditPanelCancel}
                        onDuplicate={this.duplicateSelectedItem}
                    />
                )}

                {incidentToShow && (
                    <ShowIncidentModal
                        visible
                        incidentId={incidentToShow.id}
                        onCancel={this.hideIncidentModal}
                    />
                )}
            </div>
        );
    }

    renderSideBar() {
        return (
            <aside className="harbour-map-sidebar">
                {this.renderReporting()}
                {this.renderFilters()}
                {this.renderLegend()}
                {this.renderPositions()}
            </aside>
        );
    }

    changeInitialPosition = (position) => {
        const initialPosition = this.getRelativePosition({
            x: position.positionX + Math.random() / 100000,
            y: position.positionY + Math.random() / 100000,
        });
        const initialZoom = position.zoom + Math.random() / 100000;
        this.setState({
            initialPosition,
            initialZoom,
            position: initialPosition,
            zoom: initialZoom,
        });
    };

    renderPositions() {
        const canEditPublic = SecurityService.isGranted(['administrator']);

        const { positions, zoom, position } = this.state;

        const currentPosition = this.getAbsolutePosition(position);
        const currentZoom = zoom;
        const me = LoginStore.getUser();

        return (
            <div className="harbour-map-positions" style={{ marginTop: 16 }}>
                <label style={{ display: 'block' }}>
                    {Locale.trans('mapPosition.label')}
                </label>

                {positions
                    .sort((p1, p2) => {
                        if (p1.user && !p2.user) {
                            return 1;
                        }
                        if (!p1.user && p2.user) {
                            return -1;
                        }
                        return StringService.compareCaseInsensitive(
                            p1.name,
                            p2.name,
                        );
                    })
                    .map((position) => {
                        const isPrivate =
                            position.user && position.user.id === me.id;
                        return (
                            <PositionItem
                                key={position.id}
                                position={position}
                                currentPosition={currentPosition}
                                currentZoom={currentZoom}
                                onClick={() => {
                                    this.changeInitialPosition(position);
                                }}
                                isPrivate={isPrivate}
                                editable={canEditPublic || isPrivate}
                                onPositionEdited={(newPosition) => {
                                    this.changeInitialPosition(newPosition);
                                }}
                            />
                        );
                    })}
                <Button
                    style={{ width: '100%', marginTop: 16 }}
                    onClick={this.showCreatePositionModal}
                >
                    {Locale.trans('mapPosition.createBtn.title')}
                </Button>
                {this.renderCreatePositionModal()}
            </div>
        );
    }

    getOccupency = () => {
        const { places } = this.props;
        return places.reduce((occupency, place) => {
            if (!place.empty) {
                return occupency + 1;
            }
            return occupency;
        }, 0);
    };
    getTotalPlaces = () => this.props.places.length;

    renderReporting() {
        const { previousOccupency } = this.state;
        const occupency = this.getOccupency();
        const totalPlaces = this.getTotalPlaces();
        const percent = totalPlaces !== 0 ? (occupency / totalPlaces) * 100 : 0;

        const previousPercent =
            ((previousOccupency.total - previousOccupency.mean) /
                previousOccupency.total) *
            100;

        const delta = percent - previousPercent;

        return (
            <div className="harbour-map-reporting">
                <LoadingRate
                    percent={percent}
                    precision={1}
                    height={100}
                    width={100}
                />
                <div className="harbour-map-reporting_key-numbers">
                    <div>
                        <strong>{occupency}</strong> / {totalPlaces} places
                    </div>
                    <div>
                        {delta > 0 ? (
                            <Icon type="caret-up" />
                        ) : (
                            <Icon type="caret-down" />
                        )}{' '}
                        {MathService.format(delta)}% ce mois-ci
                    </div>
                </div>
            </div>
        );
    }

    handleBoatTypeFilterChange = (value) => {
        this.setState(({ filters }) => ({
            filters: {
                ...filters,
                boatTypes: value,
            },
        }));
    };

    handleLengthFilterChange = (value) => {
        this.setState(({ filters }) => ({
            filters: {
                ...filters,
                startLength: value[0],
                endLength: value[1],
            },
        }));
    };
    handleWidthFilterChange = (value) => {
        this.setState(({ filters }) => ({
            filters: {
                ...filters,
                startWidth: value[0],
                endWidth: value[1],
            },
        }));
    };
    renderFilters() {
        const { places } = this.props;
        const {
            boatTypes,
            startLength,
            endLength,
            startWidth,
            endWidth,
        } = this.state.filters;

        const allBoatTypes = [
            BoatConstants.MOTORBOAT,
            BoatConstants.SAILBOAT,
            BoatConstants.MULTIHULL,
        ];

        const minLength =
            places.reduce(
                (length, p) =>
                    length === null || p.length < length ? p.length : length,
                null,
            ) || 0;
        const maxLength = places.reduce(
            (length, p) => (p.length > length ? p.length : length),
            0,
        );

        const minWidth =
            places.reduce(
                (width, p) =>
                    width === null || p.width < width ? p.width : width,
                null,
            ) || 0;
        const maxWidth = places.reduce(
            (width, p) => (p.width > width ? p.width : width),
            0,
        );

        return (
            <div className="harbour-map-filters">
                <div style={{ marginBottom: 8 }}>
                    <label>Type de Bateau</label>
                    <SelectField
                        value={boatTypes}
                        options={allBoatTypes.map((t) => ({
                            label: Locale.trans(t),
                            value: t,
                        }))}
                        onChange={this.handleBoatTypeFilterChange}
                        mode="multiple"
                    />
                </div>

                <div>
                    <label>{Locale.trans('boat.length')}</label>
                    <Slider
                        range
                        min={Math.floor(minLength)}
                        max={Math.ceil(maxLength)}
                        value={[
                            startLength || minLength,
                            endLength || maxLength,
                        ]}
                        onChange={this.handleLengthFilterChange}
                        tipFormatter={(value) => `${value}m`}
                    />
                </div>

                <div>
                    <label>{Locale.trans('boat.width')}</label>
                    <Slider
                        range
                        min={Math.floor(minWidth)}
                        max={Math.ceil(maxWidth)}
                        value={[startWidth || minWidth, endWidth || maxWidth]}
                        onChange={this.handleWidthFilterChange}
                        tipFormatter={(value) => `${value}m`}
                    />
                </div>

                <div style={{ marginTop: 16 }}>
                    <Checkbox
                        checked={this.isCalqueVisible('incidents')}
                        onChange={(e) => {
                            this.handleCalqueChange(
                                'incidents',
                                e.target.checked,
                            );
                        }}
                    >
                        {Locale.trans('incidents')}
                    </Checkbox>
                </div>
            </div>
        );
    }

    tooglePlaceStateFilter = (state) => {
        this.setState(({ filters }) => {
            const placeStates = filters.placeStates.slice(0);
            const i = placeStates.indexOf(state);
            if (i === -1) {
                placeStates.push(state);
            } else {
                placeStates.splice(i, 1);
            }

            return {
                filters: {
                    ...filters,
                    placeStates,
                },
            };
        });
    };

    renderLegend() {
        const { placeStates } = this.state.filters;

        const allPlaceStates = ['in', 'out', 'absence', 'stopover', 'error'];

        return (
            <div className="harbour-map-legends">
                {allPlaceStates.map((state) => (
                    <div
                        key={state}
                        className={`harbour-map-legend-item ${state} ${
                            placeStates.indexOf(state) !== -1 ? 'selected' : ''
                        }`}
                        onClick={() => this.tooglePlaceStateFilter(state)}
                        onKeyPress={() => this.tooglePlaceStateFilter(state)}
                        role="button"
                        tabIndex={0}
                    >
                        <div className="legend-color" />
                        <div className="legend-label">
                            {Locale.trans(`place.state.${state}`)}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    renderActionButtons() {
        const { canEdit, isCreatingZone } = this.state;
        return (
            canEdit && (
                <React.Fragment>
                    {isCreatingZone && (
                        <div className="harbour-map-msg">
                            {Locale.trans('place.creation.draw')}
                        </div>
                    )}
                    <div className="harbour-map-actions">
                        <Button
                            type="primary"
                            shape="circle"
                            icon="edit"
                            onClick={() => this.setState({ isEditing: true })}
                        />
                    </div>
                    {this.renderCreateZoneModal()}
                </React.Fragment>
            )
        );
    }

    handleZoneClick = (zone) => {
        const { isEditing, isCreatingZone, selectedItem } = this.state;
        if (
            isEditing &&
            !isCreatingZone &&
            (!selectedItem ||
                !selectedItem.zone ||
                selectedItem.zone.id !== zone.id)
        ) {
            this.setState({
                selectedItem: {
                    type: 'zone',
                    zone,
                },
            });
        }
    };

    handleShapeClick = (shape) => {
        const { isEditing, isCreatingZone, selectedItem } = this.state;
        if (
            isEditing &&
            !isCreatingZone &&
            (!selectedItem ||
                !selectedItem.shape ||
                selectedItem.shape.id !== shape.id)
        ) {
            this.setState({
                selectedItem: {
                    type: 'shape',
                    shape,
                },
            });
        }
    };

    handleSelectedZoneChange = (newZone) => {
        const { selectedItem } = this.state;
        if (selectedItem && selectedItem.zone) {
            let newSelectedItem = { ...selectedItem };
            newSelectedItem.zone = Object.assign(
                {},
                selectedItem.zone,
                newZone,
            );
            this.setState({
                selectedItem: newSelectedItem,
            });
        }
    };

    handleSelectedShapeChange = (newShape) => {
        const { selectedItem } = this.state;
        if (selectedItem && selectedItem.shape) {
            let newSelectedItem = { ...selectedItem };
            newSelectedItem.shape = {
                ...selectedItem.shape,
                ...newShape,
            };
            this.setState({
                selectedItem: newSelectedItem,
            });
        }
    };

    handleSelectedPlaceChange = (newPlace) => {
        const { selectedItem } = this.state;
        if (selectedItem && selectedItem.place) {
            let newSelectedItem = { ...selectedItem };
            newSelectedItem.place = {
                ...selectedItem.place,
                placeZone: {
                    ...selectedItem.place.placeZone,
                    ...newPlace,
                },
            };
            this.setState({
                selectedItem: newSelectedItem,
            });
        }
    };

    handlePlaceClick = (place) => {
        const { isEditing, isCreatingZone, selectedItem } = this.state;
        if (
            isEditing &&
            !isCreatingZone &&
            (!selectedItem ||
                !selectedItem.place ||
                selectedItem.place.id !== place.id)
        ) {
            this.setState({
                selectedItem: {
                    type: 'place',
                    place,
                },
            });
        }
    };

    handleZoomChange = (zoom) => {
        this.setState({
            zoom,
        });
    };

    handlePositionChange = (position) => {
        this.setState({
            position,
        });
    };

    renderSvgMap() {
        const {
            width,
            height,
            initialPosition,
            initialZoom,
            canEdit,
            isCreatingZone,
            selectedItem,
            viewMode,
            zoom,
        } = this.state;

        const showIncident = this.isCalqueVisible('incidents');

        return (
            <SvgMap
                width={width}
                height={height}
                onClick={canEdit && isCreatingZone ? this.handleClick : null}
                onZoomChange={this.handleZoomChange}
                onPositionChange={this.handlePositionChange}
                initialPosition={initialPosition}
                initialZoom={initialZoom}
                ref={(n) => {
                    this.svgMap = n;
                }}
            >
                {isCreatingZone && (
                    <rect
                        x="-500"
                        y="-500"
                        width="2000"
                        height="2000"
                        fill="transparent"
                        stroke="#aaaaaa"
                        strokeDasharray="5,5"
                    />
                )}

                {this.isCalqueVisible('blueprint') && (
                    <HarbourMapBlueprintCalque />
                )}
                {this.isCalqueVisible('map') && (
                    <HarbourMapBaseCalque
                        zoom={zoom}
                        selectedShape={
                            selectedItem &&
                            selectedItem.type === 'shape' &&
                            selectedItem.shape
                        }
                        onShapeClick={this.handleShapeClick}
                        onSelectedShapeChange={this.handleSelectedShapeChange}
                    />
                )}
                {this.isCalqueVisible('zones') && (
                    <HarbourMapZoneCalque
                        viewMode={viewMode}
                        zoom={zoom}
                        onZoneClick={this.handleZoneClick}
                        showIncident={showIncident}
                        selectedZone={
                            selectedItem &&
                            selectedItem.type === 'zone' &&
                            selectedItem.zone
                        }
                        onSelectedZoneChange={this.handleSelectedZoneChange}
                    />
                )}
                {this.isCalqueVisible('places') && (
                    <HarbourMapPlaceCalque
                        zoom={zoom}
                        onPlaceClick={this.handlePlaceClick}
                        filterCallback={this.placeMatchFilters}
                        showIncident={showIncident}
                        selectedPlace={
                            selectedItem &&
                            selectedItem.type === 'place' &&
                            selectedItem.place
                        }
                        onSelectedPlaceChange={this.handleSelectedPlaceChange}
                    />
                )}
                {showIncident && (
                    <HarbourMapIncidentCalque
                        onClick={this.showIncidentModal}
                        onShowIncidentClick={this.showIncidentModal}
                    />
                )}
                {this.renderDrawingPolygon()}
            </SvgMap>
        );
    }

    renderCreateZoneModal() {
        const { isCreateZoneModalVisible, newZoneSvgPoints } = this.state;
        if (!isCreateZoneModalVisible || !newZoneSvgPoints) {
            return null;
        }
        return (
            <CreateZoneModal
                visible
                onCancel={this.hideCreateZoneModal}
                svgPoints={newZoneSvgPoints}
            />
        );
    }

    renderCreatePositionModal() {
        const { isCreatePositionModalVisible, zoom, position } = this.state;
        return (
            <CreatePositionModal
                visible={isCreatePositionModalVisible}
                onCancel={this.hideCreatePositionModal}
                onCreate={(newPosition) => {
                    this.changeInitialPosition(newPosition);
                    this.hideCreatePositionModal();
                }}
                zoom={zoom}
                position={this.getAbsolutePosition(position)}
            />
        );
    }

    renderDrawingPolygon() {
        const { drawingPolygonPoints } = this.state;
        if (!drawingPolygonPoints || drawingPolygonPoints.length === 0) {
            return null;
        }

        const lastPoint = drawingPolygonPoints[drawingPolygonPoints.length - 1];

        let d = `M${drawingPolygonPoints[0].x} ${drawingPolygonPoints[0].y}`;
        for (let i = 1; i < drawingPolygonPoints.length; i++) {
            d += ` L${drawingPolygonPoints[i].x} ${drawingPolygonPoints[i].y}`;
        }

        return (
            <React.Fragment>
                <path d={d} className="drawing-polygon" />
                <circle
                    cx={lastPoint.x}
                    cy={lastPoint.y}
                    r="0.5"
                    className="drawing-polygon-point"
                />
            </React.Fragment>
        );
    }
}

function PositionItem({
    position,
    editable,
    isPrivate,
    onClick,
    currentZoom,
    currentPosition,
    onPositionEdited,
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(position.name);

    function saveNewName() {
        HarbourMapPositionActions.edit(
            HarbourStore.getCurrentHarbour(),
            position.id,
            {
                name: newName,
            },
        )
            .then(() => {
                ToastActions.createToastSuccess(
                    Locale.trans('mapPosition.save.success'),
                );
                setIsEditing(false);
            })
            .catch(FormService.handleError);
    }

    return (
        <div
            key={position.id}
            className={`harbour-map-positions__item ${
                isPrivate ? 'harbour-map-positions__item--private' : ''
            }`}
        >
            <div
                className="harbour-map-positions__item__label"
                onClick={!isEditing && onClick}
            >
                {isEditing ? (
                    <input
                        autoFocus
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                saveNewName();
                            }
                        }}
                        onBlur={() => setIsEditing(false)}
                    />
                ) : (
                    position.name
                )}
            </div>
            {editable && (
                <div className="harbour-map-positions__item__actions">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setNewName(position.name);
                            setIsEditing(true);
                            return false;
                        }}
                        shape="circle"
                        icon="edit"
                        size="small"
                    />
                    <Tooltip
                        title={Locale.trans('mapPosition.saveBtn.tooltip')}
                    >
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                HarbourMapPositionActions.edit(
                                    HarbourStore.getCurrentHarbour(),
                                    position.id,
                                    {
                                        zoom: currentZoom,
                                        positionX: Math.round(
                                            currentPosition.x,
                                        ),
                                        positionY: Math.round(
                                            currentPosition.y,
                                        ),
                                    },
                                )
                                    .then((newPosition) => {
                                        onPositionEdited(newPosition);
                                        ToastActions.createToastSuccess(
                                            Locale.trans(
                                                'mapPosition.save.success',
                                            ),
                                        );
                                    })
                                    .catch(FormService.handleError);
                                return false;
                            }}
                            shape="circle"
                            icon="fullscreen-exit"
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip
                        title={Locale.trans('mapPosition.defaultBtn.tooltip')}
                    >
                        <Button
                            type={position.isDefault ? 'primary' : null}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                HarbourMapPositionActions.edit(
                                    HarbourStore.getCurrentHarbour(),
                                    position.id,
                                    {
                                        isDefault: !position.isDefault,
                                    },
                                )
                                    .then(() => {
                                        HarbourMapPositionActions.reloadByHarbour(
                                            HarbourStore.getCurrentHarbour(),
                                            true,
                                        );
                                        ToastActions.createToastSuccess(
                                            Locale.trans(
                                                'mapPosition.save.success',
                                            ),
                                        );
                                    })
                                    .catch(FormService.handleError);
                                return false;
                            }}
                            shape="circle"
                            icon="check"
                            size="small"
                        />
                    </Tooltip>
                    <DeleteButton
                        shape="circle"
                        size="small"
                        onDelete={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            HarbourMapPositionActions.delete(
                                HarbourStore.getCurrentHarbour(),
                                position.id,
                            )
                                .then(() =>
                                    ToastActions.createToastSuccess(
                                        Locale.trans(
                                            'mapPosition.delete.success',
                                        ),
                                    ),
                                )
                                .catch(FormService.handleError);
                            return false;
                        }}
                    />
                </div>
            )}
        </div>
    );
}

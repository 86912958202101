import React from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';
import moment from 'moment';

import './RateSeasonForm.scss';
import StringService from 'services/utils/StringService';

class RateSeasonForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                let entity = this.getEntityFromValues(values);
                entity.websites &&
                    entity.websites.forEach((w) => (w.id = undefined));
                this.props.onSubmit(entity);
            }
        });
    };

    getEntityFromValues = (values) => {
        const entity = {};
        const keys = Object.keys(values);
        keys.forEach((k) => {
            entity[k] = values[k];
        });
        return entity;
    };

    render() {
        const { form, loading } = this.props;
        return (
            <Form className="rate-season-form" onSubmit={this.handleSubmit}>
                <fieldset>
                    <legend>{Locale.trans('rate.season.high')}</legend>
                    <div className="__row">
                        <div className="__label">
                            {Locale.trans('rate.season.from')}
                        </div>
                        <FormItem.InputNumber
                            id="hightSeasonStartDay"
                            required
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'hightSeasonStartDay',
                            )}
                            help={this.getHelp('hightSeasonStartDay')}
                            hasFeedback
                            min={1}
                            max={31}
                        />
                        <SelectMonthItem
                            id="hightSeasonStartMonth"
                            required
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'hightSeasonStartMonth',
                            )}
                            help={this.getHelp('hightSeasonStartMonth')}
                            hasFeedback
                        />
                        <div className="__label">
                            {Locale.trans('rate.season.to')}
                        </div>
                        <FormItem.InputNumber
                            id="hightSeasonEndDay"
                            required
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'hightSeasonEndDay',
                            )}
                            help={this.getHelp('hightSeasonEndDay')}
                            hasFeedback
                            min={1}
                            max={31}
                        />
                        <SelectMonthItem
                            id="hightSeasonEndMonth"
                            required
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'hightSeasonEndMonth',
                            )}
                            help={this.getHelp('hightSeasonEndMonth')}
                            hasFeedback
                        />
                    </div>
                </fieldset>
                <fieldset>
                    <legend>{Locale.trans('rate.season.low')}</legend>
                    <div className="__row">
                        <div className="__label">
                            {Locale.trans('rate.season.from')}
                        </div>
                        <FormItem.InputNumber
                            id="lowSeasonStartDay"
                            required
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'lowSeasonStartDay',
                            )}
                            help={this.getHelp('lowSeasonStartDay')}
                            hasFeedback
                            min={1}
                            max={31}
                        />
                        <SelectMonthItem
                            id="lowSeasonStartMonth"
                            required
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'lowSeasonStartMonth',
                            )}
                            help={this.getHelp('lowSeasonStartMonth')}
                            hasFeedback
                        />
                        <div className="__label">
                            {Locale.trans('rate.season.to')}
                        </div>
                        <FormItem.InputNumber
                            id="lowSeasonEndDay"
                            required
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'lowSeasonEndDay',
                            )}
                            help={this.getHelp('lowSeasonEndDay')}
                            hasFeedback
                            min={1}
                            max={31}
                        />
                        <SelectMonthItem
                            id="lowSeasonEndMonth"
                            required
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'lowSeasonEndMonth',
                            )}
                            help={this.getHelp('lowSeasonEndMonth')}
                            hasFeedback
                        />
                    </div>
                </fieldset>
                <fieldset>
                    <legend>{Locale.trans('rate.season.off')}</legend>
                    <div className="__row">
                        <div className="__label">
                            {Locale.trans('rate.season.from')}
                        </div>
                        <FormItem.InputNumber
                            id="offSeasonStartDay"
                            required
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'offSeasonStartDay',
                            )}
                            help={this.getHelp('offSeasonStartDay')}
                            hasFeedback
                            min={1}
                            max={31}
                        />
                        <SelectMonthItem
                            id="offSeasonStartMonth"
                            required
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'offSeasonStartMonth',
                            )}
                            help={this.getHelp('offSeasonStartMonth')}
                            hasFeedback
                        />
                        <div className="__label">
                            {Locale.trans('rate.season.to')}
                        </div>
                        <FormItem.InputNumber
                            id="offSeasonEndDay"
                            required
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'offSeasonEndDay',
                            )}
                            help={this.getHelp('offSeasonEndDay')}
                            hasFeedback
                            min={1}
                            max={31}
                        />
                        <SelectMonthItem
                            id="offSeasonEndMonth"
                            required
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'offSeasonEndMonth',
                            )}
                            help={this.getHelp('offSeasonEndMonth')}
                            hasFeedback
                        />
                    </div>
                </fieldset>

                <Button
                    type="primary"
                    htmlType="submit"
                    className="__save-btn"
                    loading={loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }
}

function SelectMonthItem({
    id,
    required,
    hasFeedback,
    label,
    validateStatus,
    help,
    initialValue,
    form,
}) {
    const months = moment.months();
    return (
        <FormItem.Select
            id={id}
            required={required}
            showSearch
            label={label}
            initialValue={initialValue}
            options={months.map((m, i) => ({
                value: i + 1,
                label: StringService.capitalize(m),
            }))}
            form={form}
            validateStatus={validateStatus}
            help={help}
            hasFeedback={hasFeedback}
        />
    );
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(RateSeasonForm);

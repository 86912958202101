import React, { useState, useRef, useMemo } from 'react';
import { Input, Checkbox } from 'antd';
import { List } from 'react-virtualized/dist/commonjs/List';

import Locale from 'locale/LocaleFactory';

//import "style/components/table/TableColumnFilter.scss";
import StringService from 'services/utils/StringService';
import ArrayService from 'services/utils/ArrayService';

type Props = {
    name: string;
    values: Array<Value>;
    selectedValues?: Array<string | number>;
    onChange: (filterName: string, values: Array<string | number>) => void;
    sort?: (v1: Value, v2: Value) => number;
};

type Value = { text: string | any; value: string | number };

function TableColumnFilter({
    name,
    values,
    selectedValues,
    onChange,
    sort,
}: Props) {
    const [searchText, setSearchText] = useState('');
    const listRef = useRef<any>(null);

    function handleChange(newValues: Array<string | number>) {
        listRef.current.forceUpdateGrid();
        if (onChange) {
            onChange(name, newValues);
        }
    }

    function handleInputChange(e: any) {
        const newSearchText = e.target.value;
        setSearchText(newSearchText);
        handleChange(getFilteredValues(newSearchText, values));
    }

    function getFilteredValues(filterText: string, values: Value[]) {
        let newSelectedValues: Array<string | number> = [];
        if (filterText.length > 0) {
            const searchTextUpper = filterText.toUpperCase();
            newSelectedValues = values
                .filter(
                    (v) => v.text.toUpperCase().indexOf(searchTextUpper) !== -1,
                )
                .map((v) => v.value);
        }
        return newSelectedValues;
    }

    function selectValue(value: string | number) {
        let newSelectedValues = selectedValues ? [...selectedValues] : [];
        if (newSelectedValues.indexOf(value) === -1) {
            newSelectedValues.push(value);
            handleChange(newSelectedValues);
        }
    }

    function deselectValue(value: string | number) {
        let newSelectedValues = selectedValues ? [...selectedValues] : [];
        const i = newSelectedValues.indexOf(value);
        if (i !== -1) {
            newSelectedValues.splice(i, 1);
            handleChange(newSelectedValues);
        }
    }

    function renderRow({ index, key, style, allValues, selectedValues }: any) {
        const value = allValues[index];
        if (!value) {
            return null;
        }

        return (
            <div key={key} className="list-row" style={style}>
                <Checkbox
                    checked={
                        selectedValues &&
                        selectedValues.indexOf(value.value) !== -1
                    }
                    onChange={(e) => {
                        if (e.target.checked) {
                            selectValue(value.value);
                        } else {
                            deselectValue(value.value);
                        }
                    }}
                >
                    {value.text}
                </Checkbox>
            </div>
        );
    }

    const allValues = useMemo(
        () =>
            ArrayService.uniqueObject(values, (v: Value) => v.value)
                .filter((v) => {
                    if (searchText.length > 0) {
                        const searchTextUpper = searchText.toUpperCase();
                        return (
                            v.text.toUpperCase().indexOf(searchTextUpper) !== -1
                        );
                    }
                    return true;
                })
                .sort(
                    sort
                        ? sort
                        : (v1, v2) =>
                              StringService.compareCaseInsensitive(
                                  v1.text,
                                  v2.text,
                              ),
                ),
        [values, searchText, sort],
    );

    return (
        <div className="table-column-filter-dropdown table-column-list-filter">
            <Input
                placeholder={Locale.trans('search', null)}
                value={searchText}
                onChange={handleInputChange}
            />
            <List
                ref={listRef}
                height={300}
                overscanRowCount={4}
                rowCount={allValues.length}
                rowHeight={30}
                rowRenderer={(p: any) =>
                    renderRow({ ...p, allValues, selectedValues })
                }
                width={250}
            />
        </div>
    );
}

export default TableColumnFilter;

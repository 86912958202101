import React, { useState, useContext, useEffect } from 'react';
import Panel from 'components/Panel';
import Locale from 'locale/LocaleFactory';
import { Button, Input, Upload, Icon } from 'antd';
import SelectField from 'components/form-fields/SelectField';
import NotificationConstants from 'constants/NotificationConstants';
import RoleConstants from 'constants/RoleConstants';
import NotificationStore from 'stores/NotificationStore';
import ToastActions from 'actions/ToastActions';

import CommunicationContext from './CommunicationContext';
import NotificationThumbnailUpload from './NotificationThumbnailUpload';

import useHarbourModules, { PREMIUM, ADVANCED } from 'hooks/useHarbourModules';

import './CreateCommunication.scss';
import RecipientSelect from './RecipientSelect';
import useCurrentHarbour from 'hooks/useCurrentHarbour';
import HarbourPicture from 'components/HarbourPicture';
import NotificationThumbnail from './NotificationThumbnail';
import DeleteButton from 'components/forms/DeleteButton';
import LoginStore from 'stores/LoginStore';
import HarbourStore from 'stores/HarbourStore';

export default function CreateCommunication() {
    const [, forceUpdate] = useState({});
    const modules = useHarbourModules();
    const harbour = useCurrentHarbour();
    const [selectedHarbours, setSelectedHarbours] = useState([harbour]);
    const {
        initialNotificationFields,
        notificationIdToEdit,
        setNotificationIdToEdit,
    } = useContext(CommunicationContext.Context);
    const [fields, setFields] = useState({});
    const [attachmentFiles, setAttachmentFiles] = useState([]);
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const availableHarbours = useAvailableHarbours();

    const notificationToEdit = NotificationStore.get().find(
        (n) => n.id === notificationIdToEdit,
    );

    useEffect(() => {
        setFields(initialNotificationFields);
    }, [initialNotificationFields]);

    useEffect(() => {
        if (notificationToEdit) {
            setFields({
                ...notificationToEdit,
                attachments: undefined,
                id: undefined,
                sentAt: undefined,
                harbour: undefined,
            });
            setAttachmentFiles([]);
            setThumbnailFile(null);
        }
    }, [notificationToEdit]);

    useEffect(() => {
        if (modules.communication < ADVANCED) {
            setFields((prev) => ({ ...prev, type: 'news' }));
        }
    }, [modules]);

    function handleFieldChange(field, value) {
        setFields((fields) => ({
            ...fields,
            [field]: value,
        }));
    }

    const submit = () => {
        //Send
        if (loading) {
            return;
        }
        setLoading(true);

        if (notificationToEdit) {
            NotificationStore.edit(
                notificationToEdit.id,
                fields,
                attachmentFiles,
                thumbnailFile,
            )
                .then(() => {
                    ToastActions.createToastSuccess(
                        Locale.trans('communication.notification.edit.success'),
                    );
                    setNotificationIdToEdit(undefined);
                    setFields({});
                    setAttachmentFiles([]);
                    setThumbnailFile(null);
                })
                .catch(() => {
                    ToastActions.createToastError(
                        Locale.trans('communication.notification.failure'),
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            selectedHarbours.map((h) =>
                NotificationStore.add(h, fields, attachmentFiles, thumbnailFile)
                    .then(() => {
                        ToastActions.createToastSuccess(
                            Locale.trans('communication.notification.success'),
                        );
                        setFields({});
                        setAttachmentFiles([]);
                        setThumbnailFile(null);
                    })
                    .catch(() => {
                        ToastActions.createToastError(
                            Locale.trans('communication.notification.failure'),
                        );
                    })
                    .finally(() => {
                        setLoading(false);
                    }),
            );
        }
    };

    return (
        <Panel
            title={Locale.trans('communication.communication')}
            className="create-communication"
        >
            <div className="new-notification">
                {
                    <SelectField
                        className="__field"
                        options={NotificationConstants.NOTIFICATION_TYPES.map(
                            (type) => ({
                                label: Locale.trans(
                                    `communication.notification.type.${type}`,
                                ),
                                value: type,
                            }),
                        )}
                        placeholder={Locale.trans(
                            'communication.notification.selectCategory',
                        )}
                        value={fields.type}
                        onChange={(value) => handleFieldChange('type', value)}
                        readOnly={modules.communication < ADVANCED}
                    />
                }
                {fields.type === 'news' && (
                    <NotificationThumbnailUpload
                        file={thumbnailFile}
                        onChange={setThumbnailFile}
                        placeholder={
                            notificationToEdit ? (
                                <NotificationThumbnail
                                    notification={notificationToEdit}
                                />
                            ) : (
                                <HarbourPicture harbour={harbour} />
                            )
                        }
                    />
                )}
                {!notificationToEdit && availableHarbours.length > 1 && (
                    <SelectField
                        className="__field"
                        options={availableHarbours.map((h) => ({
                            label: h.name,
                            value: `${h.id}`,
                        }))}
                        placeholder={Locale.trans(
                            'communication.notification.selectHarbour',
                        )}
                        value={selectedHarbours.map((h) => `${h.id}`)}
                        onChange={(value) => {
                            handleFieldChange('users', null);
                            setSelectedHarbours(
                                availableHarbours.filter((h) =>
                                    value.includes(`${h.id}`),
                                ),
                            );
                        }}
                        mode="multiple"
                    />
                )}
                {fields.type !== 'news' &&
                    !notificationToEdit &&
                    modules.communication >= PREMIUM &&
                    selectedHarbours.length === 1 && (
                        <RecipientSelect
                            harbour={selectedHarbours[0]}
                            value={(fields.users || []).map((u) => ({ id: u }))}
                            onChange={(v) =>
                                handleFieldChange(
                                    'users',
                                    v.map((u) => u.id),
                                )
                            }
                        />
                    )}
                <Input
                    className="__field"
                    placeholder={Locale.trans(
                        'communication.notification.title',
                    )}
                    value={fields.title}
                    onChange={(e) => handleFieldChange('title', e.target.value)}
                    maxLength={255}
                />
                <div className="__row">
                    <div className="__col" style={{ flex: 3 }}>
                        <Input.TextArea
                            className="__content-field"
                            autosize
                            placeholder={Locale.trans(
                                'communication.notification.content',
                            )}
                            value={fields.description}
                            onChange={(e) =>
                                handleFieldChange('description', e.target.value)
                            }
                        />
                    </div>
                    <div className="__col" style={{ flex: 1 }}>
                        <AttachmentUpload
                            fileList={attachmentFiles}
                            onChange={setAttachmentFiles}
                        />
                    </div>
                </div>
                {notificationToEdit && (
                    <div className="__prev-attachments">
                        {notificationToEdit.attachments.map((a) => (
                            <div key={a.id} className="__item">
                                <div className="__label">{a.name}</div>
                                <DeleteButton
                                    shape="circle"
                                    size="small"
                                    onDelete={() => {
                                        NotificationStore.deleteAttachment(
                                            notificationToEdit.id,
                                            a.id,
                                        ).then(() => {
                                            ToastActions.createToastSuccess(
                                                Locale.trans(
                                                    'communication.notification.deleteAttachment.success',
                                                ),
                                            );
                                            forceUpdate({});
                                        });
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                )}
                <div className="__url-field">
                    <div className="__label">
                        {Locale.trans('communication.notification.url')}
                    </div>
                    <Input
                        placeholder="http://"
                        value={fields.url}
                        onChange={(e) =>
                            handleFieldChange('url', e.target.value)
                        }
                        maxLength={255}
                    />
                </div>

                <div className="__actions">
                    <Button
                        className="__submit-btn"
                        type="primary"
                        onClick={submit}
                        loading={loading}
                        disabled={
                            !fields.title || !fields.type || !fields.description || selectedHarbours.length === 0
                        }
                    >
                        {Locale.trans(notificationToEdit ? 'edit' : 'send')}
                    </Button>
                    {notificationToEdit && (
                        <Button
                            className="__submit-btn"
                            style={{ marginLeft: 8 }}
                            onClick={() => {
                                setNotificationIdToEdit(undefined);
                                setFields({});
                            }}
                        >
                            {Locale.trans('cancel')}
                        </Button>
                    )}
                </div>
            </div>
        </Panel>
    );
}

function useAvailableHarbours() {
    const [allHarbours, setHarbours] = useState(HarbourStore.getAll());
    const user = LoginStore.getUser();
    useEffect(() => {
        function receiveHarbours() {
            setHarbours(HarbourStore.getAll());
        }
        HarbourStore.addChangeListener(receiveHarbours);
        return () => {
            HarbourStore.removeChangeListener(receiveHarbours);
        };
    }, []);
    return allHarbours.filter(
        (h) =>
            user.isSuperAdmin ||
            user.rolesInHarbours.findIndex(
                (r) => r.harbourId === h.id && r.role === RoleConstants.CAPTAIN,
            ) > -1,
    );
}

function AttachmentUpload({ fileList, onChange }) {
    const props = {
        accept: 'image/png,image/jpeg,image/svg+xml,application/pdf',
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);

            onChange(newFileList);
        },
        beforeUpload: (file) => {
            onChange([...fileList, file]);
            return false;
        },
        fileList,
        className: '__attachment-upload',
    };
    return (
        <Upload {...props}>
            <div className="__trigger">
                <Icon type="plus" />
                {Locale.trans('communication.notification.attachments')}
            </div>
        </Upload>
    );
}

import BaseUrlConstants from 'constants/BaseUrlConstants';
import ServiceBase from './ServiceBase';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class BoatCategoryService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     *
     * @returns {Promise} A promise to handle the request asynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/categories`,
            method: 'GET',
        });
    }

    // @ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/categories`,
            method: 'POST',
            data: resource,
        });
    }

    // @ts-ignore
    patch(harbourId: number, categoryId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/categories/${categoryId}`,
            method: 'PATCH',
            data: resource,
        });
    }

    // @ts-ignore
    remove(harbourId: number, categoryId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/users/${categoryId}`,
            method: 'DELETE',
        });
    }
}

export default new BoatCategoryService();

import React from 'react';
import { Button, Checkbox, Input, Radio } from 'antd';
import Locale from '../../locale/LocaleFactory';
import ColorPicker from '../../components/ColorPicker';

function HarbourMapEditPanelActions({
    item,
    onCreateZone,
    onSaveZone,
    onDeleteZone,
    onSavePlace,
    onDeletePlace,
    onSaveShape,
    onDeleteShape,
    onDuplicate,
}) {
    if (!item) {
        return (
            <React.Fragment>
                <Button
                    type="primary"
                    className="edit-panel-action-btn"
                    style={{ width: '100%' }}
                    icon="plus"
                    onClick={() => onCreateZone('polygon')}
                >
                    Ajouter une zone
                </Button>
                <Button
                    type="primary"
                    className="edit-panel-action-btn"
                    style={{ width: '100%' }}
                    icon="plus"
                    onClick={() => onCreateZone('rect')}
                >
                    Ajouter une zone rectangle
                </Button>
                <Button
                    type="primary"
                    className="edit-panel-action-btn"
                    style={{ width: '100%' }}
                    icon="plus"
                    onClick={() => onCreateZone('circle')}
                >
                    Ajouter une zone cercle
                </Button>
            </React.Fragment>
        );
    }

    if (item.type === 'zone') {
        return (
            <React.Fragment>
                <Button
                    type="primary"
                    className="edit-panel-action-btn"
                    style={{ width: '100%' }}
                    onClick={onSaveZone}
                >
                    Enregistrer la zone
                </Button>
                <Button
                    className="edit-panel-action-btn"
                    style={{ width: '100%' }}
                    onClick={onDuplicate}
                >
                    Dupliquer la zone
                </Button>
                <Button
                    type="danger"
                    className="edit-panel-action-btn"
                    style={{ width: '100%' }}
                    icon="delete"
                    onClick={() => onDeleteZone(item.zone)}
                >
                    Supprimer la zone
                </Button>
            </React.Fragment>
        );
    }

    if (item.type === 'place') {
        return (
            <React.Fragment>
                <Button
                    type="primary"
                    className="edit-panel-action-btn"
                    style={{ width: '100%' }}
                    onClick={onSavePlace}
                >
                    Enregistrer la place
                </Button>
                <Button
                    className="edit-panel-action-btn"
                    style={{ width: '100%' }}
                    onClick={onDuplicate}
                >
                    Dupliquer la place
                </Button>
                <Button
                    type="danger"
                    className="edit-panel-action-btn"
                    style={{ width: '100%' }}
                    icon="delete"
                    onClick={() => onDeletePlace(item.place)}
                >
                    Supprimer la place
                </Button>
            </React.Fragment>
        );
    }

    if (item.type === 'shape') {
        return (
            <React.Fragment>
                <Button
                    type="primary"
                    className="edit-panel-action-btn"
                    style={{ width: '100%' }}
                    onClick={onSaveShape}
                >
                    Enregistrer la forme
                </Button>
                <Button
                    className="edit-panel-action-btn"
                    style={{ width: '100%' }}
                    onClick={onDuplicate}
                >
                    Dupliquer la forme
                </Button>
                <Button
                    type="danger"
                    className="edit-panel-action-btn"
                    style={{ width: '100%' }}
                    icon="delete"
                    onClick={() => onDeleteShape(item.shape)}
                >
                    Supprimer la forme
                </Button>
            </React.Fragment>
        );
    }

    // TODO
    return <div>TODO</div>;
}

function ZoneProperties({ zone, onChange }) {
    return (
        <div>
            <Input
                placeholder={Locale.trans('zone.name')}
                value={zone.name}
                onChange={(e) => {
                    const { value } = e.target;
                    const newZone = { ...zone, name: value };
                    onChange(newZone);
                }}
                style={{ marginBottom: '4px' }}
            />
            <Checkbox
                checked={zone.isSecurityZone}
                onChange={(e) => {
                    const value = e.target.checked;
                    const newZone = { ...zone, isSecurityZone: value };
                    onChange(newZone);
                }}
                style={{ marginBottom: '4px' }}
            >
                Module Sécurité
            </Checkbox>

            {zone.isSecurityZone && (
                <ColorPicker
                    value={zone.color}
                    label="Couleur"
                    onChange={(value) => {
                        const newZone = { ...zone, color: value };
                        onChange(newZone);
                    }}
                />
            )}
        </div>
    );
}

function PlaceProperties({ place, onChange }) {
    // TODO
    return null;
}

function ShapeProperties({ shape, onChange }) {
    // TODO
    return null;
}

function HarbourMapEditPanelProperties({ item, onChange }) {
    if (!item) {
        return null;
    }

    if (item.type === 'zone') {
        return (
            <ZoneProperties
                zone={item.zone}
                onChange={(newZone) => {
                    onChange({ ...item, zone: newZone });
                }}
            />
        );
    }

    if (item.type === 'place') {
        return (
            <PlaceProperties
                place={item.place}
                onChange={(newPlace) => {
                    onChange({ ...item, place: newPlace });
                }}
            />
        );
    }

    if (item.type === 'shape') {
        return (
            <ShapeProperties
                shape={item.shape}
                onChange={(newShape) => {
                    onChange({ ...item, shape: newShape });
                }}
            />
        );
    }

    return <div>TODO</div>;
}

export default function HarbourMapEditPanel({
    viewMode,
    calques,
    onCalqueChange,
    selectedItem,
    onViewModeChange,
    onSelectedItemChange,
    onCreateZone,
    onSaveZone,
    onDeleteZone,
    onSavePlace,
    onDeletePlace,
    onSaveShape,
    onDeleteShape,
    onDuplicate,
    onClose,
    onCancel,
}) {
    return (
        <div className="harbour-map-edit-panel">
            <div className="edit-panel-section">
                <h5 className="edit-panel-section-title">
                    {Locale.trans('editPanel.calques')}
                </h5>
                <div className="edit-panel-section-content">
                    <Radio.Group
                        className="edit-panel-view-mode"
                        value={viewMode}
                        onChange={(e) => onViewModeChange(e.target.value)}
                    >
                        <Radio.Button value="web">Web</Radio.Button>
                        <Radio.Button value="mobile">Mobile</Radio.Button>
                    </Radio.Group>
                    {calques.map((calque) => (
                        <div key={calque.id} className="edit-panel-calque">
                            <Checkbox
                                checked={calque.visible}
                                onChange={(e) =>
                                    onCalqueChange(calque.id, e.target.checked)
                                }
                            >
                                {calque.name}
                            </Checkbox>
                        </div>
                    ))}
                </div>
            </div>
            {selectedItem && (
                <div className="edit-panel-section">
                    <h5 className="edit-panel-section-title">
                        {Locale.trans(`editPanel.${selectedItem.type}`)}
                    </h5>
                    <div className="edit-panel-section-content">
                        <HarbourMapEditPanelProperties
                            item={selectedItem}
                            onChange={onSelectedItemChange}
                        />
                    </div>
                </div>
            )}
            <div className="edit-panel-section actions-section">
                <h5 className="edit-panel-section-title">
                    {Locale.trans('editPanel.actions')}
                </h5>
                <div className="edit-panel-section-content">
                    <HarbourMapEditPanelActions
                        item={selectedItem}
                        onCreateZone={onCreateZone}
                        onSaveZone={onSaveZone}
                        onDeleteZone={onDeleteZone}
                        onSavePlace={onSavePlace}
                        onDeletePlace={onDeletePlace}
                        onSaveShape={onSaveShape}
                        onDeleteShape={onDeleteShape}
                        onDuplicate={onDuplicate}
                    />
                </div>
            </div>
            {selectedItem && (
                <Button className="close-btn" onClick={onCancel}>
                    {Locale.trans('cancel')}
                </Button>
            )}
            <Button className="close-btn" onClick={onClose}>
                {Locale.trans('close')}
            </Button>
        </div>
    );
}

import BaseStore from 'stores/BaseStore';
import BoatStore from 'stores/BoatStore';
import PlaceConstants from 'constants/PlaceConstants';
import HarbourStore from 'stores/HarbourStore';

class PlaceStore extends BaseStore {
    _entities: { [harbour_id: number]: IPlace[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case PlaceConstants.RECEIVE_PLACES_OF_HARBOUR:
            this._receiveByHarbour(payload.harbour, payload.places);
            break;
        case PlaceConstants.RECEIVE_PLACE_OF_HARBOUR:
            this._receiveOneByHarbour(payload.harbour, payload.place);
            break;
        case PlaceConstants.DELETE_PLACE_OF_HARBOUR:
            this._deleteOneByHarbour(payload.harbour, payload.id);
            break;
        case 'PlaceZone:receiveZoneOfPlace':
            this._receiveZoneOfPlace(payload.harbour, payload.place, payload.zone);
            break;
        case 'PlaceZone:deleteZoneOfPlace':
            this._deleteZoneOfPlace(payload.harbour, payload.id);
            break;

        default:
            // Do Nothing
            break;
        }
    };

    _receiveZoneOfPlace(harbour: IHarbour, place: IPlace, zone: IPlaceZone) {
        this._entities[harbour.id] = [...this._entities[harbour.id]];
        const places = this._entities[harbour.id];
        if (places) {
            const p = places.find(c => c.id === place.id);
            if (p) {
                p.placeZone = zone;
            }
            this.emitChange();
        }
    }

    _deleteZoneOfPlace(harbour: IHarbour, place: IPlace) {
        this._entities[harbour.id] = [...this._entities[harbour.id]];
        const places = this._entities[harbour.id];
        if (places) {
            const p = places.find(c => c.id === place.id);
            if (p) {
                p.placeZone = null;
            }
            this.emitChange();
        }
    }

    _receiveByHarbour = (harbour: IHarbour, places: IPlace[]) => {
        this._entities[harbour.id] = places;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, place: IPlace) => {
        this._entities[harbour.id] = [...this._entities[harbour.id]];
        const places = this._entities[harbour.id];
        if (!places) {
            this._entities[harbour.id] = [place];
        } else {
            const i = places.findIndex(c => c.id === place.id);
            if (i > -1) {
                places[i] = place;
            } else {
                places.push(place);
            }
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, placeId: number) => {
        this._entities[harbour.id] = [...this._entities[harbour.id]];
        const places = this._entities[harbour.id];
        if (!places) {
            return;
        }

        const i = places.findIndex(c => c.id === placeId);
        if (i > -1) {
            places.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    getForCurrentHarbour = () => this.getByHarbour(HarbourStore.getCurrentHarbour());
    /**
     * Get all the places of a harbour.
     */
    getByHarbour = (harbour: IHarbour | null | undefined): IPlace[] => (harbour && this._entities[harbour.id]) || [];

    /**
     * Get all the places of a harbour that have no boat.
     */
    getAvailableByHarbour = (harbour: IHarbour): IPlace[] => {
        const boats = BoatStore.getByHarbour(harbour);
        return this.getByHarbour(harbour).filter(p => boats.findIndex(b => b.place && b.place.id === p.id) === -1);
    };

    getById = (id: number): IPlace | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const places = this._entities[harbourId] || [];
            const place = places.find(c => c.id.toString() === id.toString());
            if (place) {
                return place;
            }
        }
        return null;
    };
}

export default new PlaceStore();

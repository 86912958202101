import React from 'react';
import { Row, Col } from 'antd';

import ResetPasswordRequestForm from 'components/forms/ResetPasswordRequestForm.jsx';
import Locale from 'locale/LocaleFactory';
import PasswordService from 'services/PasswordService';
import FormService from 'services/utils/FormService';

export default class ResetPasswordRequest extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {},
            successMsgVisible: false,
        };
    }

    handleSubmit = ({ email }) => {
        this.setState({
            loading: true,
            successMsgVisible: false,
        });
        PasswordService.postPasswordRequest({
            email,
        })
            .then(() => {
                this.setState({
                    successMsgVisible: true,
                });
                setTimeout(() => {
                    this.props.history.push('/login');
                }, 4000);
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    render() {
        return (
            <Row
                type="flex"
                align="middle"
                justify="center"
                className="login-page"
                style={{
                    backgroundImage: 'url("images/login-background.jpg")',
                }}
            >
                <Col xs={14} sm={8} md={6} lg={4} xl={4}>
                    <img
                        alt="Logo"
                        src="images/logo.png"
                        style={{ width: '100%', paddingBottom: '1rem' }}
                    />
                    <h2>{Locale.trans('password_forgotten.title')}</h2>
                    {this.renderSuccessMesage()}
                    <ResetPasswordRequestForm
                        onChange={this.handleFormChange}
                        fields={this.state.fields}
                        onSubmit={this.handleSubmit}
                        loading={this.state.loading}
                    />
                </Col>
            </Row>
        );
    }

    renderSuccessMesage() {
        if (!this.state.successMsgVisible) {
            return null;
        }
        return (
            <div className="success-msg">
                {Locale.trans('password_forgotten.confirm')}
            </div>
        );
    }
}

import React from 'react';
import { Modal } from 'antd/lib/index';

import PontoonForm from 'components/forms/PontoonForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import PontoonActions from 'actions/PontoonActions';
import FormService from 'services/utils/FormService';

/**
 * The modal to create a new pontoon.
 */
export default class CreatePontoonModal extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {
                boatTypes: { value: [] },
            },
        };
    }

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (pontoon) => {
        this.setState({
            loading: true,
        });
        PontoonActions.create(HarbourStore.getCurrentHarbour(), pontoon)
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('pontoon.creation.success'),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        return (
            <Modal
                title={Locale.trans('pontoon.creation.title')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                footer={null}
            >
                <PontoonForm
                    onChange={this.handleFormChange}
                    fields={this.state.fields}
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                />
            </Modal>
        );
    }
}

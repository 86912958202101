import { useEffect, useState } from 'react';
import PlaceStore from '../stores/PlaceStore';
import PlaceActions from '../actions/PlaceActions';
import HarbourStore from 'stores/HarbourStore';

export default function usePlacesOfHarbour(harbour) {
    const [places, setPlaces] = useState([]);

    useEffect(() => {
        const currentHarbour = HarbourStore.getById(harbour.id);
        function refreshPlacesOfCurrentHarbour() {
            setPlaces(PlaceStore.getByHarbour(currentHarbour));
        }

        refreshPlacesOfCurrentHarbour();
        PlaceActions.reloadByHarbour(currentHarbour);

        PlaceStore.addChangeListener(refreshPlacesOfCurrentHarbour);
        return () => {
            PlaceStore.removeChangeListener(refreshPlacesOfCurrentHarbour);
        };
    }, [harbour.id]);

    return places;
}

import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import HarbourMapPositionConstants from 'constants/HarbourMapPositionConstants';
import HarbourMapPositionService from 'services/HarbourMapPositionService';

import CacheManager from 'services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class HarbourMapPositionActions extends ActionsBase {
    reloadByHarbour = (harbour: IHarbour, force?: boolean) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            if (
                !force &&
                CacheManager.isCached(
                    'HarbourMapPosition:reloadByHarbour',
                    harbour.id.toString(),
                    CACHE_DURATION,
                )
            ) {
                resolve();
                return;
            }

            function handleSuccess(positions: IHarbourMapPosition[]) {
                dispatcher.dispatch({
                    type: HarbourMapPositionConstants.RECEIVE_MAP_POSITIONS,
                    payload: {
                        positions,
                    },
                });
                resolve();
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }

            HarbourMapPositionService.getByHarbour(harbour.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    create = (harbour: IHarbour, position: any) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newPosition: IHarbourMapPosition) {
                dispatcher.dispatch({
                    type: HarbourMapPositionConstants.RECEIVE_MAP_POSITION,
                    payload: {
                        position: newPosition,
                    },
                });
                resolve(newPosition);
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }

            HarbourMapPositionService.post(harbour.id, position)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (harbour: IHarbour, positionId: number, position: any) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newPosition: IHarbourMapPosition) {
                dispatcher.dispatch({
                    type: HarbourMapPositionConstants.RECEIVE_MAP_POSITION,
                    payload: {
                        harbour,
                        position: newPosition,
                    },
                });
                resolve(newPosition);
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }

            HarbourMapPositionService.patch(harbour.id, positionId, position)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    delete = (harbour: IHarbour, id: number) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess() {
                dispatcher.dispatch({
                    type: HarbourMapPositionConstants.DELETE_MAP_POSITION,
                    payload: {
                        id,
                    },
                });
                resolve();
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }

            HarbourMapPositionService.remove(harbour.id, id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

export default new HarbourMapPositionActions();

import React from 'react';
import { Modal } from 'antd/lib/index';

import UserForm from 'components/forms/UserForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import UserActions from 'actions/UserActions';
import HarbourStore from 'stores/HarbourStore';
import DeleteButton from 'components/forms/DeleteButton';
import LoginStore from 'stores/LoginStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to edit a user.
 */
export default class EditUserModal extends React.Component {
    newUser = null;
    totalActions = 0;
    completedActions = 0;

    constructor(props) {
        super();
        this.state = {
            loading: false,
            fields: this.getFieldsFromEntity(props.user),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            this.setState({
                fields: this.getFieldsFromEntity(nextProps.user),
            });
        }
    }

    getFieldsFromEntity = (entity) => {
        if (!entity) {
            return {};
        }
        const fields = {};
        const keys = Object.keys(entity);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            fields[k] = { value: entity[k] };
        }

        if (entity.rolesInHarbours) {
            const harbour = HarbourStore.getCurrentHarbour();
            fields.roles = {
                value: entity.rolesInHarbours
                    .filter((r) => r.harbourId === harbour.id)
                    .map((r) => r.role),
            };
        }
        return fields;
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (user) => {
        if (!this.props.user) {
            return null;
        }
        const userId = this.props.user.id;

        this.setState({
            loading: true,
        });
        UserActions.edit(HarbourStore.getCurrentHarbour(), userId, user)
            .then((newUser) => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(
                    Locale.trans('user.update.success', {
                        name: newUser.firstName,
                    }),
                );
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                    loadingDelete: false,
                });
            });
    };

    deleteUser = () => {
        const { user, onCancel } = this.props;

        const userId = user.id;

        this.setState({
            loadingDelete: true,
        });
        UserActions.delete(HarbourStore.getCurrentHarbour(), userId)
            .then(() => {
                this.setState({
                    loadingDelete: false,
                });
                ToastActions.createToastSuccess(
                    Locale.trans('user.delete.success'),
                );
                onCancel();
            })
            .catch(() => {
                ToastActions.createToastError(
                    Locale.trans('user.delete.error'),
                );
            })
            .finally(() => {
                this.setState({
                    loading: false,
                    loadingDelete: false,
                });
            });
    };

    render() {
        const { user, visible, onCancel } = this.props;
        const { fields, loading, loadingDelete } = this.state;
        if (!user) {
            return null;
        }

        const loggedUser = LoginStore.getUser();
        return (
            <Modal
                title={Locale.trans('user.update.title')}
                visible={visible}
                onCancel={onCancel}
                footer={null}
            >
                <UserForm
                    user={user}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                />
                {loggedUser.isSuperAdmin && (
                    <DeleteButton
                        onDelete={this.deleteUser}
                        loading={loadingDelete}
                        style={{ width: '100%' }}
                    />
                )}
            </Modal>
        );
    }
}

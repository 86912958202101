import dispatcher from '../dispatchers/AppDispatcher';
import ActionsBase from '../actions/ActionsBase';

import OutingConstants from '../constants/OutingConstants';
import OutingService from '../services/OutingService';

import CacheManager from '../services/CacheManager';
import { Moment } from 'moment';

const CACHE_DURATION = 30 * 1000;

class OutingActions extends ActionsBase {
    create = (harbour: IHarbour, outing: Object): Promise<IOuting> => {
        const $this = this;
        return new Promise(
            (
                resolve: (outing: IOuting) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newOuting: IOuting): void {
                    dispatcher.dispatch({
                        type: OutingConstants.RECEIVE_OUTING_OF_HARBOUR,
                        payload: {
                            harbour,
                            outing: newOuting,
                        },
                    });
                    resolve(newOuting);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                OutingService.post(harbour.id, outing)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    edit = (
        harbour: Entity,
        outingId: number,
        outing: Object,
    ): Promise<IOuting> => {
        const $this = this;
        return new Promise(
            (
                resolve: (outing: IOuting) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(newOuting: IOuting): void {
                    dispatcher.dispatch({
                        type: OutingConstants.RECEIVE_OUTING_OF_HARBOUR,
                        payload: {
                            harbour,
                            outing: newOuting,
                        },
                    });
                    resolve(newOuting);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                OutingService.patch(harbour.id, outingId, outing)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    delete = (harbour: Entity, id: number): Promise<void> => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                function handleSuccess(): void {
                    dispatcher.dispatch({
                        type: OutingConstants.DELETE_OUTING_OF_HARBOUR,
                        payload: {
                            harbour,
                            id,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                OutingService.remove(harbour.id, id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    reloadByHarbour = (
        harbour: Entity,
        startDate: Moment,
        endDate: Moment,
    ): Promise<void> => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                if (
                    CacheManager.isCached(
                        'Outing:reloadByHarbour',
                        harbour.id.toString(),
                        CACHE_DURATION,
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(outings: Array<IOuting>): void {
                    dispatcher.dispatch({
                        type: OutingConstants.RECEIVE_OUTINGS_OF_HARBOUR,
                        payload: {
                            harbour,
                            outings,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                OutingService.getByHarbour(harbour.id, startDate, endDate)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };
}

export default new OutingActions();

import React, { useState } from 'react';
import Locale from 'locale/LocaleFactory';
import { Table, Button, Modal, Tag } from 'antd';
import LoadingIcon from 'components/LoadingIcon.jsx';
import ServiceBase from 'services/ServiceBase';

import './PendingAccessRequestsTable.scss';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import useCurrentHarbour from 'hooks/useCurrentHarbour';
import StringService from 'services/utils/StringService';
import ApproveForm from './ApproveForm';
import RoleConstants from 'constants/RoleConstants';
import useBoatsOfHarbour from 'hooks/useBoatsOfHarbour';
import usePlacesOfHarbour from 'hooks/usePlacesOfHarbour';
import FormService from 'services/utils/FormService';
import ToastActions from 'actions/ToastActions';
import BoatActions from 'actions/BoatActions';
import RefuseForm from './RefuseForm';
import useQuery from 'hooks/useQuery';

function sortEntityByField({ field, order, type, translate }) {
    function getValue(r) {
        let value;
        if (Array.isArray(field)) {
            value = field.reduce((v, curr) => v[curr], r);
        } else {
            value = r[field];
        }
        return value;
    }

    let cmpFunc;
    if (type === 'string') {
        cmpFunc = StringService.compareCaseInsensitive;
    } else if (type === 'trans') {
        cmpFunc = (r1, r2) =>
            StringService.compareCaseInsensitive(translate(r1), translate(r2));
    } else {
        cmpFunc = (r1, r2) => (r1[field] > r2[field] ? 1 : -1);
    }

    return (r1, r2) =>
        order === 'desc'
            ? cmpFunc(getValue(r2), getValue(r1))
            : cmpFunc(getValue(r1), getValue(r2));
}

export default function PendingAccessRequestsTable() {
    const harbour = useCurrentHarbour();
    const boats = useBoatsOfHarbour(harbour);
    const places = usePlacesOfHarbour(harbour);
    const { data, loading, refetch } = useQuery({
        url: `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/access-requests`,
        polling: 30,
    });

    const columns = [
        {
            title: Locale.trans('accessRequest.user'),
            key: 'user',
            render: (record) =>
                record.user &&
                `${record.user.firstName} ${record.user.lastName}`,
            sorter: sortEntityByField({
                field: ['user', 'firstName'],
                type: 'string',
            }),
        },
        {
            title: Locale.trans('user.email'),
            key: 'user.email',
            render: (record) => record.user && record.user.email,
            sorter: sortEntityByField({
                field: ['user', 'email'],
                type: 'string',
            }),
        },
        {
            title: Locale.trans('user.phoneNumber'),
            key: 'user.phoneNumber',
            render: (record) => record.user && record.user.phoneNumber,
            sorter: sortEntityByField({
                field: ['user', 'phoneNumber'],
                type: 'string',
            }),
        },
        {
            title: Locale.trans('accessRequest.category'),
            key: 'category',
            render: (record) =>
                record.user &&
                Locale.trans(
                    `accessRequest.categories.${record.user.category}`,
                ),
            sorter: sortEntityByField({
                field: ['user', 'category'],
                type: 'trans',
                translate: (v) => Locale.trans(`accessRequest.categories.${v}`),
            }),
        },
        {
            title: Locale.trans('accessRequest.place'),
            key: 'placeCode',
            dataIndex: 'placeCode',
            sorter: sortEntityByField({ field: 'placeCode', type: 'string' }),
        },
        {
            title: Locale.trans('accessRequest.boat'),
            key: 'boatName',
            dataIndex: 'boatName',
            sorter: sortEntityByField({ field: 'boatName', type: 'string' }),
        },
        {
            title: null,
            key: 'actions',
            width: 200,
            className: '__actions',
            render: (record) => (
                <>
                    {record.status === 'refused' && (
                        <Tag color="red">
                            {Locale.trans('accessRequest.refusal.success')}
                        </Tag>
                    )}
                    <ApproveButton
                        accessRequest={record}
                        boats={boats}
                        places={places}
                        onApprove={refetch}
                    />
                    {record.status === 'pending' && (
                        <RefuseButton
                            accessRequest={record}
                            onRefuse={refetch}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <div>
            <h1>{Locale.trans('accessRequest.title')}</h1>
            <Table
                dataSource={data}
                rowKey={(r) => `${r.harbour.id}${r.user.id}${r.role}`}
                columns={columns}
                locale={Locale.Table}
                loading={loading && { indicator: <LoadingIcon /> }}
                className="pending-access-requests-table"
            />
        </div>
    );
}

function ApproveButton({ accessRequest, boats, places, onApprove }) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState({
        roles: {
            value: [RoleConstants.YACHTSMAN],
        },
    });

    function handleSubmit(values) {
        setLoading(true);

        ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}access-requests/approve`,
            method: 'POST',
            data: {
                roles: values.roles,
                user: accessRequest.user.id,
                harbour: accessRequest.harbour.id,
            },
        })
            .then(() => {
                if (values.boat) {
                    BoatActions.addOwner(
                        accessRequest.harbour,
                        values.boat,
                        accessRequest.user.id,
                    ).catch(FormService.handleError);
                } else if (values.name && values.name.length > 0) {
                    BoatActions.create(accessRequest.harbour, {
                        ...values,
                        boat: undefined,
                        roles: undefined,
                    })
                        .then((newBoat) =>
                            BoatActions.addOwner(
                                accessRequest.harbour,
                                newBoat.id,
                                accessRequest.user.id,
                            ).catch(FormService.handleError),
                        )
                        .catch(FormService.handleError);
                }
                ToastActions.createToastSuccess(
                    Locale.trans('accessRequest.approval.success'),
                );
                onApprove && onApprove();
                setVisible(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    return (
        <>
            <Button
                type="success"
                shape="circle"
                icon="check"
                onClick={(e) => {
                    setVisible(true);
                }}
                style={{ marginRight: 4 }}
            />
            <Modal
                title={Locale.trans('accessRequest.approval.title')}
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <div className="access-request-info">
                    <div className="__row">
                        <div className="__label">
                            {Locale.trans('accessRequest.user')}
                        </div>
                        <div className="__value">{`${accessRequest.user.firstName} ${accessRequest.user.lastName}`}</div>
                    </div>
                    <div className="__row">
                        <div className="__label">
                            {Locale.trans('accessRequest.category')}
                        </div>
                        <div className="__value">
                            {Locale.trans(
                                `accessRequest.categories.${accessRequest.user.category}`,
                            )}
                        </div>
                    </div>
                    <div className="__row">
                        <div className="__label">
                            {Locale.trans('accessRequest.place')}
                        </div>
                        <div className="__value">{accessRequest.placeCode}</div>
                    </div>
                    <div className="__row">
                        <div className="__label">
                            {Locale.trans('accessRequest.boat')}
                        </div>
                        <div className="__value">{accessRequest.boatName}</div>
                    </div>
                </div>
                <ApproveForm
                    boats={boats}
                    places={places}
                    onChange={(changedFields) =>
                        setFields((prev) => ({ ...prev, ...changedFields }))
                    }
                    fields={fields}
                    onSubmit={handleSubmit}
                    loading={loading}
                />
            </Modal>
        </>
    );
}

function RefuseButton({ accessRequest, onRefuse }) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState({});

    function handleSubmit(values) {
        setLoading(true);

        ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}access-requests/refuse`,
            method: 'POST',
            data: {
                refusalComment: values.refusalComment,
                user: accessRequest.user.id,
                harbour: accessRequest.harbour.id,
            },
        })
            .then(() => {
                ToastActions.createToastSuccess(
                    Locale.trans('accessRequest.refusal.success'),
                );
                onRefuse && onRefuse();
                setVisible(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    return (
        <>
            <Button
                type="danger"
                shape="circle"
                icon="close"
                onClick={() => {
                    setVisible(true);
                }}
            />
            <Modal
                title={Locale.trans('accessRequest.refusal.title')}
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <div className="access-request-info">
                    <div className="__row">
                        <div className="__label">
                            {Locale.trans('accessRequest.user')}
                        </div>
                        <div className="__value">{`${accessRequest.user.firstName} ${accessRequest.user.lastName}`}</div>
                    </div>
                    <div className="__row">
                        <div className="__label">
                            {Locale.trans('accessRequest.category')}
                        </div>
                        <div className="__value">
                            {Locale.trans(
                                `accessRequest.categories.${accessRequest.user.category}`,
                            )}
                        </div>
                    </div>
                    <div className="__row">
                        <div className="__label">
                            {Locale.trans('accessRequest.place')}
                        </div>
                        <div className="__value">{accessRequest.placeCode}</div>
                    </div>
                    <div className="__row">
                        <div className="__label">
                            {Locale.trans('accessRequest.boat')}
                        </div>
                        <div className="__value">{accessRequest.boatName}</div>
                    </div>
                </div>
                <RefuseForm
                    onChange={(changedFields) =>
                        setFields((prev) => ({ ...prev, ...changedFields }))
                    }
                    fields={fields}
                    onSubmit={handleSubmit}
                    loading={loading}
                />
            </Modal>
        </>
    );
}

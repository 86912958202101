import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ToastActions from 'actions/ToastActions';
import Locale from 'locale/LocaleFactory';

interface iProps extends FormComponentProps {
    fields: any;
    onChange: (fields: any) => void;
}

export default class FormService {
    static getFieldsFromEntity(entity: any) {
        if (!entity) {
            return {};
        }
        const fields: { [key: string]: any } = {};
        const keys = Object.keys(entity);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            fields[k] = { value: entity[k] };
        }
        return fields;
    }

    static create(
        formClass: any,
        customMap = (key: string, value: any) => value,
    ) {
        return Form.create({
            onFieldsChange(props: iProps, changedFields: any) {
                if (props.onChange) {
                    props.onChange(changedFields);
                }
            },
            mapPropsToFields(props) {
                const { fields } = props;
                if (!fields) {
                    return {};
                }
                const kFields = Object.keys(fields);
                const map: { [key: string]: any } = {};
                for (let i = 0; i < kFields.length; i++) {
                    const k = kFields[i];
                    map[k] = Form.createFormField({
                        ...fields[k],
                        value: customMap(k, fields[k].value),
                    });
                }
                return map;
            },
        })(formClass);
    }

    static handleError(error: {
        errors?: { errors?: string[] };
        response?: any;
        message?: string;
    }) {
        let message = Locale.trans('error_message', null);
        if (error) {
            if (error.errors && error.errors.errors) {
                message = error.errors.errors
                    .map((e: string) => Locale.trans(e, null))
                    .join('\n');
            } else if (error.response) {
                try {
                    const resp = JSON.parse(error.response);
                    message = Locale.trans(resp.message, null);
                } catch (e) {
                    // Nothing to do
                }
            } else {
                message = Locale.trans(error.message, null);
            }
        }

        ToastActions.createToastError(message);
    }
}

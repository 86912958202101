// @flow

import BaseStore from '../stores/BaseStore';
import SidebarConstants from '../constants/SidebarConstants';


class SidebarCollapseStore extends BaseStore {
    _collapsed: boolean;

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._collapsed = window.innerWidth <= 900 || localStorage.getItem('sidebar-collapsed') === 'true';
    }

    _registerToActions = (action: { type: string }): void => {
        const { type } = action;
        if (type === SidebarConstants.COLLAPSED) {
            this._collapsed = localStorage.getItem('sidebar-collapsed') === 'true';
            this.emitChange();
        }
    };

    get = () => this._collapsed;
}
export default new SidebarCollapseStore();

import React, { useState } from 'react';
import { Modal } from 'antd/lib/index';

import PartnerForm from './PartnerForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import HarbourStore from 'stores/HarbourStore';

import PartnerActions from 'actions/PartnerActions';
import FormService from 'services/utils/FormService';

/**
 * The modal to create a new partner.
 */
export default function CreatePartnerModal({ visible, onCancel, onCreate }) {
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState({});

    function handleFormChange(changedFields) {
        setFields((prev) => ({ ...prev, ...changedFields }));
    }

    function handleSubmit(entity) {
        setLoading(true);
        PartnerActions.create(HarbourStore.getCurrentHarbour(), entity)
            .then((newPartner) => {
                onCreate(newPartner);
                setFields({});
                ToastActions.createToastSuccess(
                    Locale.trans('partner.creation.success'),
                );
            })
            .catch(FormService.handleError)
            .finally(() => {
                setLoading(false);
            });
    }
    return (
        <Modal
            title={Locale.trans('partner.creation.title')}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <PartnerForm
                onChange={handleFormChange}
                fields={fields}
                onSubmit={handleSubmit}
                loading={loading}
            />
        </Modal>
    );
}

import BaseStore from 'stores/BaseStore';
import IncidentConstants from 'constants/IncidentConstants';

import DateService from 'services/utils/DateService';

class IncidentStore extends BaseStore {
    _entities: { [harbour_id: number]: IIncident[] };

    constructor() {
        super();
        this.subscribe(() => this._registerToActions);
        this._entities = {};
    }

    _registerToActions = (action: { type: string, payload: any }): void => {
        const { type, payload } = action;
        switch (type) {
        case IncidentConstants.RECEIVE_INCIDENTS_OF_HARBOUR:
            this._receiveByHarbour(payload.harbour, payload.incidents);
            break;
        case IncidentConstants.RECEIVE_INCIDENT_OF_HARBOUR:
            this._receiveOneByHarbour(payload.harbour, payload.incident);
            break;
        case IncidentConstants.DELETE_INCIDENT_OF_HARBOUR:
            this._deleteOneByHarbour(payload.harbour, payload.id);
            break;

        default:
            // Do Nothing
            break;
        }
    };

    _receiveByHarbour = (harbour: IHarbour, incidents: IIncident[]) => {
        this._entities[harbour.id] = incidents;
        this.emitChange();
    };

    _receiveOneByHarbour = (harbour: IHarbour, incident: IIncident) => {
        if (!incident || !incident.id) {
            return;
        }

        const incidents = this._entities[harbour.id];
        if (!incidents) {
            this._entities[harbour.id] = [incident];
        } else {
            const i = incidents.findIndex(c => c.id === incident.id);
            if (i > -1) {
                incidents[i] = incident;
            } else {
                incidents.push(incident);
            }
        }
        this.emitChange();
    };

    _deleteOneByHarbour = (harbour: IHarbour, incidentId: number) => {
        const incidents = this._entities[harbour.id];
        if (!incidents) {
            return;
        }

        const i = incidents.findIndex(c => c.id === incidentId);
        if (i > -1) {
            incidents.splice(i, 1);
            this.emitChange();
        }
    };

    clear = () => {
        this._entities = {};
        this.emitChange();
    };

    // /// Public Methods  /////

    /**
     * Get all the incidents of a harbour.
     */
    getByHarbour = (harbour: IHarbour): IIncident[] =>
        (harbour ? this._entities[harbour.id] || [] : []);

    getById = (id: number): IIncident | null => {
        const harbourIds = Object.keys(this._entities);
        for (let i = 0; i < harbourIds.length; i++) {
            const harbourId = parseInt(harbourIds[i], 10);
            const incidents = this._entities[harbourId] || [];
            const incident = incidents.find(c => c.id.toString() === id.toString());
            if (incident) {
                return incident;
            }
        }
        return null;
    };

    countPendingIncidentByHarbour = (harbour: IHarbour): number =>
        this.getByHarbour(harbour).filter(a => a.status === IncidentConstants.PENDING).length;

    getByDate = (harbour: IHarbour, date: Date): IIncident[] =>
        this.getByHarbour(harbour).filter(i => i.createdAt.substring(0, 10) === DateService.formatApi(date));

    countByTypeAndDate = (harbour: IHarbour, incidentType: string, date: Date) =>
        this.getByHarbour(harbour).filter(i =>
            i.incidentType === incidentType &&
                i.createdAt.substring(0, 10) === DateService.formatApi(date)).length;
}

export default new IncidentStore();

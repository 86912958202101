import React from 'react';
import { axisBottom, axisLeft } from 'd3-axis';
import { line, curveMonotoneX, area } from 'd3-shape';
import { select, mouse } from 'd3-selection';
import { bisector } from 'd3-array';

import Locale from 'locale/LocaleFactory';

import { scaleLinear, scaleTime } from 'd3-scale';
import * as d3 from 'd3';


export default class LineChart extends React.Component {
    constructor() {
        super();
        /*
        if (new.target === LineChart) {
            throw new TypeError('Cannot construct LineChart instances directly');
        }
        */
        if (this.getXValue === undefined) {
            throw new TypeError('method "getXValue" must be defined.');
        }
        if (this.getYDomain === undefined) {
            throw new TypeError('method "getYDomain" must be defined.');
        }
        if (this.getData === undefined) {
            throw new TypeError('method "getData" must be defined.');
        }
        if (this.getXMainDomain === undefined) {
            throw new TypeError('method "getXMainDomain" must be defined.');
        }
        if (this.getXComparedDomain === undefined) {
            throw new TypeError('method "getXComparedDomain" must be defined.');
        }
        if (this.createLines === undefined) {
            throw new TypeError('method "createLines" must be defined.');
        }
        if (this.getMargin === undefined) {
            throw new TypeError('method "getMargin" must be defined.');
        }
        if (this.formatTooltipText === undefined) {
            throw new TypeError('method "formatTooltipText" must be defined.');
        }
        if (this.getTooltipTitle === undefined) {
            throw new TypeError('method "getTooltipTitle" must be defined.');
        }
        if (this.getTooltipContent === undefined) {
            throw new TypeError('method "getTooltipContent" must be defined.');
        }
    }


    componentDidMount() {
        this._draw();
    }

    _draw = () => {
        const margin = this.getMargin();

        this.width = this.wrapper.clientWidth;
        this.realHeight = this.wrapper.clientHeight;
        this.height = this.realHeight - margin.top - margin.bottom;

        this.chart = select(this.node)
            .attr('height', this.realHeight)
            .attr('width', this.width)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);
    }
    _clear = () => {
        this.chart.remove();
    }

    redraw = () => {
        this._clear();
        this._draw();
        this.createChart();
    };

    receiveData = () => {
        this.createChart();
    };

    getYValueMain = (d) => {
        if (this.getYValue !== undefined) {
            return this.getYValue(d);
        }
        return d.value;
    };
    getYValueCompared = (d) => {
        if (this.getYValue !== undefined) {
            return this.getYValue(d);
        }
        return d.value;
    };

    createChart = () => {
        const height = this.height;
        const margin = this.getMargin();
        // set the dimensions and margins of the graph
        const width = this.width - margin.left - margin.right;

        const data = this.getData();

        // set the ranges
        const xMain = scaleTime()
            .range([0, width])
            .domain(this.getXMainDomain(data));
        const xCompared = scaleTime()
            .range([0, width])
            .domain(this.getXComparedDomain(data));

        const y = scaleLinear()
            .range([height, 0])
            .domain(this.getYDomain(data));

        this.createLines(data, xMain, xCompared, y);
        this.createAxis(height, xMain, y);
        this.createTooltips(width, xMain, xCompared, y, data);
    };

    createAxis(height, xMain, y) {
        const localeFormatter = d3.timeFormatLocale({
            dateTime: "%a %b %e %X %Y",
            date: "%d-%m-%Y",
            time: "%H:%M:%S",
            periods: ["AM", "PM"],
            days: [
                Locale.trans('calendar.days.long.monday'),
                Locale.trans('calendar.days.long.tuesday'),
                Locale.trans('calendar.days.long.wednesday'),
                Locale.trans('calendar.days.long.thursday'),
                Locale.trans('calendar.days.long.friday'),
                Locale.trans('calendar.days.long.saturday'),
                Locale.trans('calendar.days.long.sunday'),
            ],
            shortDays: [
                Locale.trans('calendar.days.short.monday'),
                Locale.trans('calendar.days.short.tuesday'),
                Locale.trans('calendar.days.short.wednesday'),
                Locale.trans('calendar.days.short.thursday'),
                Locale.trans('calendar.days.short.friday'),
                Locale.trans('calendar.days.short.saturday'),
                Locale.trans('calendar.days.short.sunday'),
            ],
            months: [
                Locale.trans('calendar.months.long.jan'),
                Locale.trans('calendar.months.long.feb'),
                Locale.trans('calendar.months.long.mar'),
                Locale.trans('calendar.months.long.apr'),
                Locale.trans('calendar.months.long.may'),
                Locale.trans('calendar.months.long.jun'),
                Locale.trans('calendar.months.long.jul'),
                Locale.trans('calendar.months.long.aug'),
                Locale.trans('calendar.months.long.sep'),
                Locale.trans('calendar.months.long.oct'),
                Locale.trans('calendar.months.long.nov'),
                Locale.trans('calendar.months.long.dec'),
            ],
            shortMonths: [
                Locale.trans('calendar.months.short.jan'),
                Locale.trans('calendar.months.short.feb'),
                Locale.trans('calendar.months.short.mar'),
                Locale.trans('calendar.months.short.apr'),
                Locale.trans('calendar.months.short.may'),
                Locale.trans('calendar.months.short.jun'),
                Locale.trans('calendar.months.short.jul'),
                Locale.trans('calendar.months.short.aug'),
                Locale.trans('calendar.months.short.sep'),
                Locale.trans('calendar.months.short.oct'),
                Locale.trans('calendar.months.short.nov'),
                Locale.trans('calendar.months.short.dec'),
            ],
        });
        const formatMillisecond = localeFormatter.format(".%L");
        const formatSecond = localeFormatter.format(":%S");
        const formatMinute = localeFormatter.format("%I:%M");
        const formatHour = localeFormatter.format("%I %p");
        const formatDay = localeFormatter.format("%a %d");
        const formatWeek = localeFormatter.format("%b %d");
        const formatMonth = localeFormatter.format("%B");
        const formatYear = localeFormatter.format("%Y");
        const multiFormat = date => (d3.timeSecond(date) < date ? formatMillisecond
                : d3.timeMinute(date) < date ? formatSecond
                : d3.timeHour(date) < date ? formatMinute
                : d3.timeDay(date) < date ? formatHour
                : d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
                : d3.timeYear(date) < date ? formatMonth
                : formatYear)(date);
        
        this.chart.select('.xAxis').remove();
        this.chart.append('g')
            .attr('class', 'xAxis')
            .attr('transform', `translate(0,${height})`)
            .call(axisBottom(xMain).ticks(4).tickFormat(multiFormat))
            .selectAll('text');
        this.chart.select('.yAxis').remove();
        this.chart.append('g')
            .attr('class', 'yAxis')
            .call(axisLeft(y).ticks(4));
    }

    createLine(className, data, computeXValue, computeYValue) {
        const valueLine = line()
            .x(computeXValue)
            .y(computeYValue)
            .curve(curveMonotoneX);
        const classNameForSelect = className.replace(' ', '.');

        this.chart.selectAll(`path.${classNameForSelect}.line`)
            .remove();
        this.chart
            .append('g')
            .append('path')
            .attr('class', `${className} line`)
            .attr('d', valueLine([]));

        this.chart
            .select(`path.${classNameForSelect}.line`)
            .transition()
            .duration(300)
            .attr('d', valueLine(data));
    }

    createArea(className, data, computeXValue, computeYValue) {
        const height = this.height;
        const areaLine = area()
            .x(computeXValue)
            .y0(height)
            .y1(computeYValue)
            .curve(curveMonotoneX);

        this.chart.selectAll(`path.${className}.area`)
            .remove();
        this.chart
            .append('g')
            .append('path')
            .attr('class', `${className} area`)
            .attr('d', areaLine([]));

        this.chart
            .select(`path.${className}.area`)
            .transition()
            .duration(300)
            .attr('d', areaLine(data));
    }

    createTooltips(width, xMain, xCompared, y, data) {
        this.chart.select('.focus').remove();
        const focus = this.chart.append('g')
            .attr('class', 'focus')
            .style('display', 'none');

        //TODO multiple lines
        focus.append('line')
            .attr('class', 'x-hover-line hover-line')
            .attr('y1', 0)
            .attr('y2', this.height);

        focus.append('circle')
            .attr('class', 'compared')
            .attr('r', 5.5);
        focus.append('circle')
            .attr('class', 'main')
            .attr('r', 5.5);

        const findClosestPoint = (date, dataArray) => {
            const bisectDate = bisector(this.getXValue).left;
            const i = bisectDate(dataArray, date);
            if(dataArray[i - 1] === undefined) {
                return dataArray[i];
            }
            if (dataArray[i] === undefined || date - this.getXValue(dataArray[i - 1]) < this.getXValue(dataArray[i]) - date) {
                return dataArray[i - 1];
            }
            return dataArray[i];
        };

        const $this = this;
        function mouseMove() {
            focus.style('display', null);
            const margin = $this.getMargin();
            const x0 = new Date(xMain.invert(mouse(this)[0] - margin.left));
            const x1 = new Date(xCompared.invert(mouse(this)[0] - margin.left));
            const d = findClosestPoint(x0, data.main);
            const dCompared = findClosestPoint(x1, data.compared);
            $this.createTooltip(focus, d, dCompared, xMain, xCompared, y);
        }

        select(this.wrapper)
            .on('mouseover', () => { focus.style('display', null); })
            .on('mouseout', () => { this.mouseOut(); focus.style('display', 'none'); })
            .on('mousemove', mouseMove)
            .on('mouseclick', mouseMove);
    }

    createTooltip(focus, point, pointComparison, xMain, xCompared, y) {
        const mainYVal = y(this.getYValueMain(point));
        const comparedYVal = y(this.getYValueCompared(pointComparison));
        let maxPoint = 0;
        if (isNaN(comparedYVal)) {
            maxPoint = mainYVal;
        } else {
            maxPoint = Math.min(mainYVal, comparedYVal);//The max is the min on the svg cadran
        }
        focus
            .attr('transform', `translate(${xMain(this.getXValue(point))},${maxPoint})`);
        if (maxPoint !== mainYVal) {
            focus.selectAll('.main')
                .attr('transform', `translate(0,${mainYVal - maxPoint})`);
        } else {
            focus.selectAll('.main')
                .attr('transform', `translate(0,0)`);
        }
        focus.select('.x-hover-line')
            .attr('y2', this.height - maxPoint);
        if (maxPoint !== comparedYVal) {
            focus.selectAll('.compared')
                .attr('transform', `translate(0,${comparedYVal - maxPoint})`);
        } else {
            focus.selectAll('.compared')
                .attr('transform', `translate(0,0)`);
        }

        const tooltipWindow = select(this.wrapper).select('.tooltip');
        const margin = this.getMargin();
        tooltipWindow
            .attr('class', 'tooltip show')
            .style('bottom', `${(this.height - maxPoint)}px`)
            .style('left', `${xMain(this.getXValue(point)) + margin.left}px`);
        tooltipWindow
            .select('.tooltip-title')
            .text(this.getTooltipTitle(point));
        tooltipWindow
            .select('.tooltip-content')
            .html(this.getTooltipContent(point, pointComparison));
    }

    mouseOut = () => {
        const tooltipWindow = select(this.wrapper).select('.tooltip');
        tooltipWindow
            .attr('class', 'tooltip');
    }

    render() {
        return (
            <div
                className="chart line-chart"
                ref={(wrapper) => {
                    this.wrapper = wrapper;
                }}
            >
                <svg ref={(node) => {
                    this.node = node;
                }}
                />
                <div className="tooltip">
                    <div className="tooltip-title" />
                    <div className="tooltip-content" />
                </div>
            </div>
        );
    }
};

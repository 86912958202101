import React from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';
import SelectMultiple from 'components/form-items/SelectMultipleFormItem';

function cmpUsersByName(u1, u2) {
    const uN1 = u1.firstName + u1.lastName;
    const uN2 = u2.firstName + u2.lastName;
    return uN1 > uN2 ? 1 : -1;
}

class NotificationUserListForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const entity = this.getEntityFromValues(values);
                this.props.onSubmit(entity);
            }
        });
    };

    getEntityFromValues = (values) => {
        const entity = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.forEach((k) => {
            if (k.endsWith('_ids')) {
                const tK = k.replace('_ids', '');
                entity[tK] = values[k].map(v => parseInt(v));
                ignoredKeys.push(tK);
            }else if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                entity[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                entity[k] = values[k];
            }
        });
        return entity;
    };

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <FormItem.Input
                    id="name"
                    required
                    label={Locale.trans('notification-user-list.name')}
                    form={this.props.form}
                    validateStatus={this.getValidateStatus('name')}
                    help={this.getHelp('name')}
                    hasFeedback
                />
                {this.renderUsersField()}
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={this.props.loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }

    renderUsersField() {
        const { users, form } = this.props;

        if (!users || users.length === 0) {
            return null;
        }

        const { getFieldValue } = form;
        return (
            <SelectMultiple
                id="users_ids"
                multiple
                label={Locale.trans('notification-user-list.users')}
                initialValue={(getFieldValue('users') || []).map((c) =>
                    c.id.toString(),
                )}
                options={users.sort(cmpUsersByName).map((c) => ({
                    value: c.id,
                    label: `${c.lastName} ${c.firstName}`,
                }))}
                form={form}
            />
        );
    }
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(NotificationUserListForm);

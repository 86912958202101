import React from 'react';
import { Modal } from 'antd';

import YachtsmanUserForm from 'components/forms/YachtsmanUserForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import UserActions from 'actions/UserActions';
import HarbourStore from 'stores/HarbourStore';

import BoatActions from 'actions/BoatActions';
import BoatStore from 'stores/BoatStore';

import RoleConstants from '../constants/RoleConstants';
import FormService from 'services/utils/FormService';

/**
 * The modal to create a new yachtsman.
 */
export default class CreateYachtsmanModal extends React.Component {
    newUser = null;
    totalActions = 0;
    completedActions = 0;

    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {
                locale: {
                    value: HarbourStore.getCurrentHarbour().defaultLocale,
                },
            },
            boats: BoatStore.getByHarbour(HarbourStore.getCurrentHarbour()),
        };
    }

    componentDidMount() {
        BoatStore.addChangeListener(this.receiveBoats);
        BoatActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    }
    componentWillUnmount() {
        BoatStore.removeChangeListener(this.receiveBoats);
    }

    receiveBoats = () => {
        const boats = BoatStore.getByHarbour(HarbourStore.getCurrentHarbour());
        this.setState({
            boats,
        });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (user, boats) => {
        this.newUser = null;
        this.totalActions = 0;
        this.completedActions = 0;

        this.setState({
            loading: true,
        });
        UserActions.create(
            HarbourStore.getCurrentHarbour(),
            Object.assign({}, user, { role: RoleConstants.YACHTSMAN }),
        )
            .then((newUser) => {
                this.newUser = newUser;

                boats.created.map((b) => this._addBoat(this.newUser.id, b));

                this.tryToComplete();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    // Boat
    _addBoat = (userId, boat) => {
        this.totalActions++;
        BoatActions.addOwner(HarbourStore.getCurrentHarbour(), boat.id, userId)
            .then(() => {
                this.completedActions++;
                this.tryToComplete();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    /**
     * Check if all the things that have to be created are done. (ex: Phones, Mail, etc.)
     * If yes, we complete the process with toast to the user and redirection to Edit page.
     */
    tryToComplete = () => {
        if (!this.newUser || this.completedActions < this.totalActions) {
            return;
        }

        this.setState({
            fields: {},
            loading: false,
        });
        ToastActions.createToastSuccess(
            Locale.trans('yachtsman.creation.success', {
                name: this.newUser.firstName,
            }),
        );
        this.props.onCancel();
    };

    render() {
        return (
            <Modal
                title={Locale.trans('yachtsman.creation.title')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                footer={null}
            >
                <YachtsmanUserForm
                    boats={this.state.boats}
                    onChange={this.handleFormChange}
                    fields={this.state.fields}
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                />
            </Modal>
        );
    }
}

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class UrgencePhoneNumberService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all urgence phone numbers of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/urgence-phone-numbers`,
            method: 'GET',
        });
    }

    /**
     * Get a urgence phone number by unique identifier.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} phoneNumberId The identifier of the urgence phone number.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    getById(harbourId: number, phoneNumberId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/urgence-phone-numbers/${phoneNumberId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new urgence phone number to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The urgence phone number to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/urgence-phone-numbers`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing urgence phone number.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} phoneNumberId The identifier of the urgence phone number.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, phoneNumberId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/urgence-phone-numbers/${phoneNumberId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} phoneNumberId The identifier of the urgence phone number.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, phoneNumberId: number, patch: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/urgence-phone-numbers/${phoneNumberId}`,
            method: 'PATCH',
            data: patch,
        });
    }
}

export default new UrgencePhoneNumberService();

export default {
    NOTIFICATION_TYPES: [
        'weather',
        'security',
        'event',
        'work',
        'concierge-package',
        'concierge-mail',
        'news',
        'other',
    ],
};

import React, { useEffect } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import frFR from "antd/es/locale/fr_FR";

import "styles/style.scss";

import LoginActions from "actions/LoginActions";

import Layout from "pages/Layout.jsx";
import Login from "pages/Login.jsx";
import ResetPassword from "pages/ResetPassword.jsx";
import ResetPasswordRequest from "pages/ResetPasswordRequest.jsx";
import Privacy from 'pages/Privacy';

import ProtectedRoute from "components/ProtectedRoute.jsx";

export default function App() {
  useEffect(() => {
    LoginActions.loginUserIfRemembered();
  }, []);
  return (
    <ConfigProvider locale={frFR}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route
            exact
            path="/reset-password-request"
            component={ResetPasswordRequest}
          />
          <Route exact path="/privacy" component={Privacy} />
          <ProtectedRoute path="/" component={Layout} />
        </Switch>
      </Router>
    </ConfigProvider>
  );
}

import LoginStore from 'stores/LoginStore';
import HarbourStore from 'stores/HarbourStore';

/**
 * Service to use the security context of the user.
 */
class SecurityService {
    /**
     * Check if the user is allowed to access a resource with an access level.
     * @param {number} roles The roles required.
     * @returns {boolean} TRUE if the user is allowed, else FALSE.
     */
    isGranted(roles: string[]) {
        const harbour = HarbourStore.getCurrentHarbour();
        let user = LoginStore.getUser();

        if (!user || !harbour) {
            return false;
        }

        if (user.isSuperAdmin) {
            return true;
        }

        const userRoles = user.rolesInHarbours;
        if (userRoles) {
            for (let i = 0; i < userRoles.length; i++) {
                const context = userRoles[i];
                if (
                    context.harbourId && 
                    context.harbourId.toString() === harbour.id.toString() &&
                    (!roles ||
                        roles.length === 0 ||
                        roles.indexOf(context.role) !== -1)
                ) {
                    return true;
                }
            }
        }

        return false;
    }
    isSuperAdmin() {
        const user = LoginStore.getUser();
        return user && user.isSuperAdmin === true;
    }
}

export default new SecurityService();

import React from 'react';
import { Button, Modal, Collapse, Table, Icon } from 'antd';
import TableColumnFilter from 'components/table/TableColumnFilter.jsx';
import FilterIcon from 'components/table/FilterIcon.jsx';
import LoadingIcon from 'components/LoadingIcon.jsx';
import CavalaireChallengeTable from 'pages/CavalaireChallengeTable.jsx';

import Locale from 'locale/LocaleFactory';
import DateService from 'services/utils/DateService';
import ArrayService from 'services/utils/ArrayService';
import StringService from 'services/utils/StringService';
import FilterService from 'services/utils/FilterService';

import HarbourStore from 'stores/HarbourStore';

import UserActions from 'actions/UserActions';
import UserStore from 'stores/UserStore';

// Sorting Methods
function sortDepartureDateColumn(a1, a2) {
    return StringService.compareCaseInsensitive(a1.departureDate, a2.departureDate);
}
function sortReturnDateColumn(a1, a2) {
    return StringService.compareCaseInsensitive(a1.returnDate, a2.returnDate);
}

/**
 * The modal to show a activity.
 */
export default class ShowActivityModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            absences: [],
            filteredAbsences: [],
            filters: {
                departureDate: [],
                returnDate: [],
            },
        };
    }

    componentDidMount() {
        this.setState({
            absences: this.props.absences
        })
        UserStore.addChangeListener(this.receiveUsers);
        UserActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    }
    componentWillUnmount() {
        UserStore.removeChangeListener(this.receiveUsers);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            if (nextProps.absences !== this.props.absences) {
                this.setState({
                    absences: nextProps.absences
                }, this.updateFilters);
            }
        }
}
    receiveUsers = () => {
        this.forceUpdate();
    };

    getAbsenceDepartureDates = () =>
        ArrayService.unique(this.state.absences.map(b => b.departureDate));
    getAbsenceReturnDates = () => ArrayService.unique(this.state.absences.map(b => b.returnDate));

    // Filters
    updateFilters = () => {
        const { absences } = this.state;
        const filteredAbsences = absences.filter(this.absenceMatchFilters);
        this.setState({ filteredAbsences });
    };

    absenceMatchFilters = (a) => {
        const { filters } = this.state;
        return (
            FilterService.matchFilter(filters.departureDate, a.departureDate) &&
            FilterService.matchFilter(filters.returnDate, a.returnDate)
        );
    };

    handleFilterChange = (name, values) => {
        let newFilters = {...this.state.filters};
        newFilters[name] = values;
        this.setState({ 
            filters: newFilters
        }, this.updateFilters);
    };

    render() {
        const { activity, visible, onCancel } = this.props;
        if (!activity) {
            return null;
        }
        return (
            <Modal title={(activity.boat && activity.boat.name) || (activity.place && activity.place.code)} visible={visible} onCancel={onCancel} footer={null}>
                <div className="display-form">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className="field">
                            <span className="label">{Locale.trans('boat.name')}</span>
                            <span>{activity.boat && activity.boat.name}</span>
                        </div>
                        <div className="field">
                            <span className="label">{Locale.trans('boat.immatriculation')}</span>
                            <span>{activity.boat && activity.boat.immatriculation}</span>
                        </div>
                        <div className="field">
                            <span className="label">{Locale.trans('boat.place')}</span>
                            <span>{activity.place && activity.place.code}</span>
                        </div>
                    </div>
                    <div className="panel">
                        <h5 className="title">Caractéristiques</h5>
                        <div className="content">
                            <div className="field">
                                <span className="label">{Locale.trans('boat.boatType')}</span>
                                <span>{activity.boat && Locale.trans(activity.boat.boatType)}</span>
                            </div>
                            <div className="field" />
                            <div className="field" />
                            <div className="field">
                                <span className="label">{Locale.trans('boat.length')}</span>
                                <span>{activity.boat && activity.boat.length}</span>
                            </div>
                            <div className="field">
                                <span className="label">{Locale.trans('boat.width')}</span>
                                <span>{activity.boat && activity.boat.width}</span>
                            </div>
                            <div className="field">
                                <span className="label">{Locale.trans('boat.draught')}</span>
                                <span>{activity.boat && activity.boat.draught}</span>
                            </div>
                        </div>
                    </div>
                    <div className="panel">
                        <h5 className="title">Propriétaires</h5>
                        <div className="content">{this.renderOwners()}</div>
                    </div>
                    {this.renderCavalaireChallengeDetailTable()}
                    {this.props.displayBy === "boat" && (
                        <>
                            <div className="panel">
                                <h5 className="title">{`${this.state.filteredAbsences && this.state.filteredAbsences.length > 1 ? Locale.trans('absence.absences') : Locale.trans('absence.absence')} ${this.props.year} (${this.state.filteredAbsences ? this.state.filteredAbsences.length : 0})`}</h5>
                                <div className="content">{this.renderAbsences()}</div>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        );
    }

    renderCavalaireChallengeDetailTable() {
        const { activity } = this.props;
        const harbour = HarbourStore.getCurrentHarbour();

        if (!activity || (!activity.boat && this.props.displayBy === "boat") || (!activity.place && this.props.displayBy === "place") || !harbour.showCavalaireChallenge) {
            return null;
        }
        const item = this.props.displayBy === "boat" ? activity.boat : activity.place;
        item.harbour = harbour;

        return <CavalaireChallengeTable item={item} year={this.props.year} itemType={this.props.displayBy} />;
    }

    renderOwners = () => {
        if (!this.props.activity || !this.props.activity.boat || !this.props.activity.boat.owners || this.props.activity.boat.owners.length === 0) {
            return (
                <div style={{ textAlign: 'center', width: '100%', color: 'rgba(0, 0, 0, 0.45)' }}>
                    Aucune donnée
                </div>
            );
        }
        return (
            <Collapse bordered={false}>
                {this.props.activity.boat.owners.map(owner => (
                    <Collapse.Panel header={`${owner.firstName} ${owner.lastName}`} key={owner.id}>
                        {this.renderOwnerDetail(owner)}
                    </Collapse.Panel>
                ))}
            </Collapse>
        );
    };
    renderOwnerDetail = (owner) => {
        const user = UserStore.getById(owner.id);
        if (!user) {
            return <LoadingIcon />;
        }
        return (
            <div className="owner-detail">
                <div className="main-info">
                    <div className="name">{`${user.firstName} ${user.lastName}`}</div>
                    <div className="nationality">{user.nationality}</div>
                </div>
                <div className="contact-info">
                    <div className="field">
                        <span className="label">{Locale.trans('user.email')}</span>
                        <span>{user.email || 'Inconnue'}</span>
                    </div>
                    <div className="field">
                        <span className="label">{Locale.trans('user.phoneNumber')}</span>
                        <span>{user.phoneNumber || 'Inconnu'}</span>
                    </div>
                </div>
                {this.props.onEditUser && (
                    <Button
                        type="primary"
                        icon="edit"
                        onClick={() => {
                            this.props.onEditUser(user);
                        }}
                    >
                        Modifier
                    </Button>
                )}
            </div>
        );
    };
    renderAbsences = () => {
        const { filters, filteredAbsences } = this.state;
        const columns = [
            {
                title: Locale.trans('absence.departureDate'),
                key: 'departureDate',
                render: this.renderDepartureDateCell,
                sorter: sortDepartureDateColumn,
                filterIcon: <FilterIcon active={filters.departureDate.length > 0} />,
                defaultSortOrder: 'descend',
                filterDropdown: (
                    <TableColumnFilter
                        name="departureDate"
                        selectedValues={filters.departureDate}
                        values={this.getAbsenceDepartureDates().map(r => ({
                            text: DateService.formatApiToDisplay(r),
                            value: r,
                        }))}
                        onChange={this.handleFilterChange}
                        sortingFunc={(v1, v2) => StringService.compareCaseInsensitive(v2.value, v1.value)}
                    />
                ),
            },
            {
                title: Locale.trans('absence.returnDate'),
                key: 'returnDate',
                render: this.renderReturnDateCell,
                sorter: sortReturnDateColumn,
                filterIcon: <FilterIcon active={filters.returnDate.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="returnDate"
                        selectedValues={filters.returnDate}
                        values={this.getAbsenceReturnDates().map(r => ({
                            text: DateService.formatApiToDisplay(r),
                            value: r,
                        }))}
                        onChange={this.handleFilterChange}
                        sortingFunc={(v1, v2) => StringService.compareCaseInsensitive(v2.value, v1.value)}
                    />
                ),
            },
        ];
        return (
            <Table
                dataSource={filteredAbsences}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
                loading={this.state.loading && { indicator: <Icon type="loading" spin /> }}
            />
        );
    };

    renderDepartureDateCell = (absence) =>
        DateService.formatApiToDisplay(absence.departureDate);
    renderReturnDateCell = (absence) =>
        DateService.formatApiToDisplay(absence.returnDate);
}

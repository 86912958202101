import React from 'react';
import { Form, Button, Row, Col } from 'antd';
import Locale from 'locale/LocaleFactory';
import StringService from 'services/utils/StringService';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';
import BoatCategoryStore from 'stores/BoatCategoryStore';
import BoatCategoryActions from 'actions/BoatCategoryActions';
import SelectFormItem from '../form-items/SelectFormItem';

class RateForm extends FormBase {
    constructor() {
        super();
        this.state = {
            categories: [],
        };
    }
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
        BoatCategoryStore.addChangeListener(this.changeCategories);
        BoatCategoryActions.reloadForCurrentHarbour();
    }

    changeCategories = () => {
        this.setState({
            categories: BoatCategoryStore.getForCurrentHarbour(),
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const rate = this.getEntityFromValues(values);
                this.props.onSubmit(rate);
            }
        });
    };

    getEntityFromValues = (values) => {
        const entity = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        for (let iK = 0; iK < keys.length; iK++) {
            const k = keys[iK];
            if (k.endsWith('_ids')) {
                return;
            }
            if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                entity[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                entity[k] = values[k];
            }
        }
        return entity;
    };

    render() {
        const { categories } = this.state;
        const { getFieldValue } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit}>
                {this.renderBoatTypesField()}

                <SelectFormItem
                    id="category_id"
                    allowClear
                    label={Locale.trans('rate.category')}
                    initialValue={(getFieldValue('category') || { id: 0 }).id.toString()}
                    options={categories.map(type => ({
                        value: type.id,
                        label: type.name,
                    }))}
                    form={this.props.form}
                    required
                />

                <div className="panel">
                    <h5 className="title">{Locale.trans('rate.season.high')}</h5>
                    <div className="content">
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormItem.Input
                                    id="hightSeasonDay"
                                    label={StringService.capitalize(Locale.trans('day'))}
                                    form={this.props.form}
                                />
                            </Col>
                            <Col span={8}>
                                <FormItem.Input
                                    id="hightSeasonWeek"
                                    label={StringService.capitalize(Locale.trans('week'))}
                                    form={this.props.form}
                                />
                            </Col>
                            <Col span={8}>
                                <FormItem.Input
                                    id="hightSeasonMonth"
                                    label={StringService.capitalize(Locale.trans('month'))}
                                    form={this.props.form}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="panel">
                    <h5 className="title">{Locale.trans('rate.season.low')}</h5>
                    <div className="content">
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormItem.Input
                                    id="lowSeasonDay"
                                    label={StringService.capitalize(Locale.trans('day'))}
                                    form={this.props.form}
                                />
                            </Col>
                            <Col span={8}>
                                <FormItem.Input
                                    id="lowSeasonWeek"
                                    label={StringService.capitalize(Locale.trans('week'))}
                                    form={this.props.form}
                                />
                            </Col>
                            <Col span={8}>
                                <FormItem.Input
                                    id="lowSeasonMonth"
                                    label={StringService.capitalize(Locale.trans('month'))}
                                    form={this.props.form}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="panel">
                    <h5 className="title">{Locale.trans('rate.season.off')}</h5>
                    <div className="content">
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormItem.Input
                                    id="offSeasonDay"
                                    label={StringService.capitalize(Locale.trans('day'))}
                                    form={this.props.form}
                                />
                            </Col>
                            <Col span={8}>
                                <FormItem.Input
                                    id="offSeasonWeek"
                                    label={StringService.capitalize(Locale.trans('week'))}
                                    form={this.props.form}
                                />
                            </Col>
                            <Col span={8}>
                                <FormItem.Input
                                    id="offSeasonMonth"
                                    label={StringService.capitalize(Locale.trans('month'))}
                                    form={this.props.form}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>

                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={this.props.loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }
    renderBoatTypesField() {
        const boatTypes = ['motorboat', 'sailboat', 'multihull'];
        return (
            <FormItem.Select
                id="boatTypes"
                multiple
                required
                showSearch
                label={Locale.trans('rate.boatTypes')}
                options={boatTypes.map(c => ({ value: c, label: Locale.trans(c) }))}
                form={this.props.form}
                validateStatus={this.getValidateStatus('boatTypes')}
                help={this.getHelp('boatTypes')}
                hasFeedback
            />
        );
    }
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(RateForm);

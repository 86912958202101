import React, { useState, useEffect } from 'react';
import { Route, Switch, Link } from 'react-router-dom';

import {
    Button,
    Menu,
    Icon,
    Layout,
    Popover,
    Avatar,
    Badge,
    Input,
} from 'antd';
import Sider from 'components/Sider';

import useHarbourModules, { ADVANCED, PREMIUM } from 'hooks/useHarbourModules';

import EditUserModal from 'pages/Configuration/EditUserModal.jsx';

import Locale from 'locale/LocaleFactory';
import SecurityService from 'services/SecurityService';

import LoginActions from 'actions/LoginActions';
import LoginStore from 'stores/LoginStore';

import HarbourActions from 'actions/HarbourActions';
import HarbourStore from 'stores/HarbourStore';

import AbsenceActions from 'actions/AbsenceActions';
import AbsenceStore from 'stores/AbsenceStore';

import IncidentActions from 'actions/IncidentActions';

import Home from 'pages/Home.jsx';
import BoatList from 'pages/BoatList.jsx';
import OutingList from 'pages/OutingList';
import Calendar from 'pages/Calendar.jsx';
import InterractiveMap from 'pages/InterractiveMap.jsx';
import HarbourConfiguration from 'pages/Configuration/HarbourConfiguration.jsx';
import RateList from 'pages/RateList.jsx';
import Security from 'pages/Security.jsx';
import Reporting from 'pages/Reporting';

import Loading from 'components/Loading.jsx';
import HarbourPicture from 'components/HarbourPicture.jsx';

import SidebarCollapseStore from 'stores/SidebarCollapseStore';

import RoleConstants from 'constants/RoleConstants';
import SidebarActions from 'actions/SidebarActions';
import Communication from 'pages/Communication';
import StringService from 'services/utils/StringService';
import useIncidentsOfHarbour from 'hooks/useIncidentsOfHarbour';
import IncidentConstants from 'constants/IncidentConstants';
import CurrentCommitmentList from 'pages/Commitment/CurrentCommitmentList';
import useCurrentHarbour from 'hooks/useCurrentHarbour';
import PendingAccessRequestsCounter from './Boats/PendingAccessRequestsCounter';

const { Header, Content } = Layout;

function PendingIncidentBadge({ harbour }) {
    const incidents = useIncidentsOfHarbour(harbour);
    const pendingIncidents = incidents.filter(
        (i) => i.status === IncidentConstants.PENDING && (i.place || i.zone),
    );
    return <Badge count={pendingIncidents.length} />;
}

function IncompleteIncidentBadge({ harbour }) {
    const incidents = useIncidentsOfHarbour(harbour);
    const incompleteIncidents = incidents.filter(
        (i) => i.status === IncidentConstants.PENDING && !i.place && !i.zone,
    );
    return (
        <Badge className="danger-badge" count={incompleteIncidents.length} />
    );
}

function countPendingAbsencesOfHarbour(harbour) {
    return (
        AbsenceStore.countPendingAbsenceByHarbour(harbour) +
        AbsenceStore.countPendingAnticipatedReturnByHarbour(harbour)
    );
}

const smallLogoUrl = 'images/logo-icon.png';
const logoUrl = 'images/logo-text.png';

export default function AppLayout({ history, location }) {
    const modules = useHarbourModules();
    const harbour = useCurrentHarbour();
    const [countPendingAbsences, setCountPendingAbsences] = useState(
        countPendingAbsencesOfHarbour(harbour),
    );
    const [menuCollapsed, setMenuCollapsed] = useState(
        SidebarCollapseStore.get(),
    );

    useEffect(() => {
        HarbourActions.reload();
    }, []);

    useEffect(() => {
        function receiveAbsences() {
            setCountPendingAbsences(countPendingAbsencesOfHarbour(harbour));
        }
        AbsenceStore.addChangeListener(receiveAbsences);
        AbsenceActions.reloadByHarbour(harbour, true);

        return () => {
            AbsenceStore.removeChangeListener(receiveAbsences);
        };
    }, [harbour]);
    useEffect(() => {
        IncidentActions.reloadByHarbour(harbour);
    }, [harbour]);

    function shouldShowSecurityModule() {
        if (modules.security < ADVANCED) {
            return false;
        }
        if (SecurityService.isSuperAdmin()) {
            return true;
        }
        const currentUser = LoginStore.getUser();
        return (
            currentUser &&
            currentUser.showSecurityModule &&
            SecurityService.isGranted([
                RoleConstants.CAPTAIN,
                RoleConstants.SECURITY_OFFICER,
                RoleConstants.SURVEYOR,
            ])
        );
    }

    function shouldShowBoats() {
        if (SecurityService.isSuperAdmin()) {
            return true;
        }
        return (
            harbour.isPartner &&
            (modules.absence >= ADVANCED || modules.security >= ADVANCED) &&
            SecurityService.isGranted([
                RoleConstants.CAPTAIN,
                RoleConstants.YACHTSMAN,
            ])
        );
    }

    function shouldShowReportingModule() {
        if (modules.absence < PREMIUM) {
            return false;
        }
        if (SecurityService.isSuperAdmin()) {
            return true;
        }
        const currentUser = LoginStore.getUser();
        return currentUser && currentUser.showReportingModule;
    }

    function shouldShowCommunicationModule() {
        if (SecurityService.isSuperAdmin()) {
            return true;
        }
        const currentUser = LoginStore.getUser();
        return currentUser && currentUser.showCommunicationModule;
    }

    function shouldShowAbsenceModule() {
        return (
            modules.absence >= ADVANCED &&
            SecurityService.isGranted([
                RoleConstants.CAPTAIN,
                RoleConstants.YACHTSMAN,
            ])
        );
    }

    function shouldShowRatesModule() {
        return (
            modules.rate >= ADVANCED &&
            SecurityService.isGranted([
                RoleConstants.CAPTAIN,
                RoleConstants.YACHTSMAN,
            ])
        );
    }

    function handleMenuClick(e) {
        if (e.key !== location.pathname) {
            history.push(e.key);
        }
    }

    function onCollapse(collapsed) {
        setMenuCollapsed(collapsed);
        SidebarActions.collpase(collapsed);
    }

    if (!harbour) {
        return <Loading />;
    }

    const selectedMenu = location.pathname;

    const user = LoginStore.getUser();
    const firstname = user.firstName.replace(
        /\w\S*/g,
        (tStr) => tStr.charAt(0).toUpperCase() + tStr.substr(1).toLowerCase(),
    );
    const fullName = `${firstname} ${user.lastName.toUpperCase()}`;

    return (
        <Layout
            className={menuCollapsed ? 'sidebar-collapsed' : ''}
            style={{ minHeight: '100vh' }}
        >
            <Sider
                collapsible
                collapsed={menuCollapsed}
                onCollapse={onCollapse}
                showMask={window.innerWidth <= 900}
            >
                <Link className="sider-home-link" to="/">
                    <div className="logo">
                        <img
                            className="logo-icon"
                            alt={Locale.trans('pages.home')}
                            src={smallLogoUrl}
                        />
                        <img
                            className="logo-text"
                            alt={Locale.trans('pages.home')}
                            src={logoUrl}
                        />
                    </div>
                </Link>
                <HarbourLogo harbour={harbour} />
                <Menu
                    theme="dark"
                    onClick={handleMenuClick}
                    selectedKeys={[selectedMenu]}
                    mode="inline"
                >
                    <Menu.Item key="/">
                        <Icon type="home" />
                        <span>{Locale.trans('pages.home')}</span>
                    </Menu.Item>
                    {shouldShowBoats() && (
                        <Menu.Item key="/boats">
                            <Icon type="team" />
                            <span>{Locale.trans('pages.users')}</span>
                            {SecurityService.isGranted(['captain']) && (
                                <PendingAccessRequestsCounter />
                            )}
                        </Menu.Item>
                    )}
                    {shouldShowBoats() && (
                        <Menu.Item key="/outings">
                            <Icon type="logout" />
                            <span>Sorties</span>
                        </Menu.Item>
                    )}
                    {shouldShowAbsenceModule() && (
                        <Menu.Item key="/calendar">
                            <Icon type="calendar" />
                            <span>{Locale.trans('pages.calendar')}</span>
                            <Badge count={countPendingAbsences} />
                        </Menu.Item>
                    )}
                    {shouldShowBoats() && (
                        <Menu.Item key="/map">
                            <Icon type="environment-o" />
                            <span>{Locale.trans('pages.map')}</span>
                            <PendingIncidentBadge harbour={harbour} />
                        </Menu.Item>
                    )}
                    {shouldShowRatesModule() && (
                        <Menu.Item key="/rates">
                            <Icon type="credit-card" />
                            <span>{Locale.trans('pages.rates')}</span>
                        </Menu.Item>
                    )}
                    {shouldShowSecurityModule() && (
                        <Menu.Item key="/security">
                            <Icon type="warning" />
                            <span>{Locale.trans('pages.security')}</span>
                            <IncompleteIncidentBadge harbour={harbour} />
                        </Menu.Item>
                    )}
                    {shouldShowReportingModule() && (
                        <Menu.Item key="/reporting">
                            <Icon type="line-chart" />
                            <span>{Locale.trans('pages.reporting')}</span>
                        </Menu.Item>
                    )}
                    {shouldShowCommunicationModule() && (
                        <Menu.Item key="/communication">
                            <Icon type="message" />
                            <span>{Locale.trans('pages.communication')}</span>
                        </Menu.Item>
                    )}
                </Menu>
            </Sider>
            <Layout>
                <Header>
                    <div
                        className="sider-toggle-menu-btn"
                        role="button"
                        tabIndex="0"
                        onClick={() => onCollapse(!menuCollapsed)}
                        onKeyPress={() => onCollapse(!menuCollapsed)}
                    >
                        <Icon
                            type={menuCollapsed ? 'menu-unfold' : 'menu-fold'}
                        />
                    </div>

                    <CurrentCommitmentList />

                    <div className="welcome-message">
                        {Locale.trans('welcome', { USER: fullName })}
                    </div>
                    <HeaderUserMenu history={history} />
                </Header>
                <Content>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        {shouldShowBoats() && (
                            <Route exact path="/boats" component={BoatList} />
                        )}
                        {shouldShowBoats() && (
                            <Route
                                exact
                                path="/outings"
                                component={OutingList}
                            />
                        )}
                        {shouldShowAbsenceModule() && (
                            <Route
                                exact
                                path="/calendar"
                                component={Calendar}
                            />
                        )}
                        {shouldShowBoats() && (
                            <Route
                                exact
                                path="/map"
                                component={InterractiveMap}
                            />
                        )}
                        {shouldShowSecurityModule() && (
                            <Route
                                exact
                                path="/security"
                                component={Security}
                            />
                        )}
                        {shouldShowRatesModule() && (
                            <Route exact path="/rates" component={RateList} />
                        )}
                        {SecurityService.isGranted([RoleConstants.CAPTAIN]) && (
                            <Route
                                exact
                                path="/configuration"
                                component={HarbourConfiguration}
                            />
                        )}
                        {shouldShowReportingModule() && (
                            <Route
                                exact
                                path="/reporting"
                                component={Reporting}
                            />
                        )}
                        {shouldShowCommunicationModule() && (
                            <Route
                                exact
                                path="/communication"
                                component={Communication}
                            />
                        )}
                    </Switch>
                </Content>
            </Layout>
        </Layout>
    );
}

function HeaderUserMenu({ history }) {
    const [allHarbours, setHarbours] = useState(HarbourStore.getAll());
    useEffect(() => {
        function receiveHarbours() {
            setHarbours(HarbourStore.getAll());
        }
        HarbourStore.addChangeListener(receiveHarbours);
        return () => {
            HarbourStore.removeChangeListener(receiveHarbours);
        };
    }, []);

    function logout() {
        LoginActions.logoutUser();
        history.push('/login');
    }

    const user = LoginStore.getUser();
    const harbours = allHarbours.filter(
        (h) =>
            user.isSuperAdmin ||
            user.rolesInHarbours.findIndex((r) => r.harbourId === h.id) > -1,
    );

    return (
        <Popover
            overlayClassName="layout-user-menu"
            content={
                <React.Fragment>
                    <ProfileModalButton />
                    {harbours.length > 1 && (
                        <Popover
                            content={<HarbourList harbours={harbours} />}
                            title={Locale.trans('harbour.select')}
                            placement="leftTop"
                            overlayClassName="layout-select-harbour"
                        >
                            <Button icon="fork">
                                {Locale.trans('harbour.change')}
                            </Button>
                        </Popover>
                    )}
                    {SecurityService.isGranted([RoleConstants.CAPTAIN]) && (
                        <Button
                            icon="setting"
                            onClick={() => {
                                history.push('/configuration');
                            }}
                        >
                            {Locale.trans('pages.configuration')}
                        </Button>
                    )}
                    <Button icon="logout" onClick={logout}>
                        {Locale.trans('login.logOut.button')}
                    </Button>
                </React.Fragment>
            }
            title={null}
            placement="bottomRight"
            trigger="click"
        >
            <Avatar size="large" className="profile-btn">
                {user.firstName.charAt(0).toUpperCase()}
                {user.lastName.charAt(0).toUpperCase()}
            </Avatar>
        </Popover>
    );
}

function ProfileModalButton() {
    const [myProfileVisible, setMyProfileVisible] = useState(false);

    function showMyProfileModal() {
        setMyProfileVisible(true);
    }

    function hideMyProfileModal() {
        setMyProfileVisible(false);
    }

    const user = LoginStore.getUser();

    return (
        <>
            <Button icon="user" onClick={showMyProfileModal}>
                {Locale.trans('pages.profile')}
            </Button>
            <EditUserModal
                user={user}
                visible={myProfileVisible}
                onCancel={hideMyProfileModal}
            />
        </>
    );
}

function HarbourList({ harbours }) {
    const [harbourFilter, setharbourFilter] = useState(null);

    function harbourMatchFilter(harbour) {
        if (!harbourFilter || harbourFilter.length === 0) {
            return true;
        }
        return harbour.name.toLowerCase().includes(harbourFilter.toLowerCase());
    }

    return (
        <React.Fragment>
            {harbours.length > 5 && (
                <Input
                    placeholder={Locale.trans('search')}
                    onChange={(e) => setharbourFilter(e.target.value)}
                    value={harbourFilter}
                />
            )}
            {harbours
                .filter(harbourMatchFilter)
                .sort((h1, h2) =>
                    StringService.compareCaseInsensitive(h1.name, h2.name),
                )
                .map((h) => (
                    <Button
                        key={h.id}
                        onClick={() => {
                            HarbourStore.setCurrentHarbourId(h.id);
                        }}
                    >
                        {h.name}
                    </Button>
                ))}
        </React.Fragment>
    );
}

function HarbourLogo({ harbour }) {
    const harbourName = harbour ? harbour.name : null;
    return (
        <React.Fragment>
            <HarbourPicture harbour={harbour} />
            <div className="harbour-name">{harbourName}</div>
        </React.Fragment>
    );
}

// @flow

import React, { useState, useEffect, useCallback } from 'react';

import useHarbourModules, { PREMIUM } from 'hooks/useHarbourModules';

import PlacesConfiguration from 'pages/Configuration/PlacesConfiguration.jsx';
import CamerasConfiguration from 'pages/Configuration/CamerasConfiguration.jsx';
import UrgencePhoneNumberConfiguration from 'pages/Configuration/UrgencePhoneNumberConfiguration.jsx';
import UsersConfiguration from 'pages/Configuration/UsersConfiguration';
import CategoriesConfiguration from 'pages/Configuration/CategoriesConfiguration';
import NotificationUserListConfiguration from './NotificationUserListConfiguration';
import WeatherApiConfiguration from './WeatherApiConfiguration';
import PartnerConfiguration from './Partners/PartnerConfiguration';
import ExternalLinkConfiguration from './ExternalLinks/ExternalLinkConfiguration';
import RateSeasonConfiguration from './RateSeason/RateSeasonConfiguration';
import HarbourForm from 'components/forms/HarbourForm';

import Panel from 'components/Panel';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';

import HarbourActions from 'actions/HarbourActions';
import HarbourStore from 'stores/HarbourStore';

import IncidentConfiguration from 'pages/Configuration/IncidentConfiguration';
import FormService from 'services/utils/FormService';
import { Col, Row, Tabs } from 'antd';

import SecurityService from 'services/SecurityService';

const { TabPane } = Tabs;

const useBasicFormActions = (handleSuccess, getCurrent) => {
    const [fields, setFields] = useState(
        FormService.getFieldsFromEntity(getCurrent()),
    );
    const [loading, setLoading] = useState(false);

    const action = (object) => {
        handleSuccess(object)
            .then(() => {
                setFields(FormService.getFieldsFromEntity(object));
            })
            .catch(FormService.handleError)
            .finally(() => {
                setLoading(false);
            });
    };
    const handleFormChange = (changedFields) => {
        setFields({ ...fields, ...changedFields });
    };
    const refresh = useCallback(() => {
        const current = getCurrent();
        setFields(FormService.getFieldsFromEntity(current));
    }, [getCurrent]);
    return [fields, loading, action, handleFormChange, refresh];
};
const gutter = 24;
const HarbourConfigurationTab = () => {
    const [harbour, setHarbour] = useState(HarbourStore.getCurrentHarbour());
    const handleSubmit = (newHarbour) => {
        if (!harbour) {
            return () => {};
        }
        return HarbourActions.edit(harbour.id, newHarbour).then(
            (newHarbourReceived) => {
                ToastActions.createToastSuccess(
                    Locale.trans('harbour.update.success', {
                        name: newHarbourReceived.name,
                    }),
                );
            },
        );
    };
    const [
        fields,
        loading,
        action,
        handleFormChange,
        refresh,
    ] = useBasicFormActions(handleSubmit, HarbourStore.getCurrentHarbour);

    useEffect(() => {
        const onChangeCurrentHarbour = () => {
            setHarbour(HarbourStore.getCurrentHarbour());
            refresh();
        };
        HarbourStore.addCurrentHarbourChangeListener(onChangeCurrentHarbour);
        return () => {
            HarbourStore.removeCurrentHarbourChangeListener(
                onChangeCurrentHarbour,
            );
        };
    }, [refresh]);

    return (
        <React.Fragment>
            <Row gutter={gutter}>
                <Col span={24}>
                    <HarbourForm
                        onChange={handleFormChange}
                        fields={fields}
                        onSubmit={action}
                        loading={loading}
                        harbour={harbour}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default function HarbourConfiguration() {
    const modules = useHarbourModules();
    const isSuperAdmin = SecurityService.isSuperAdmin();
    return (
        <div className="configuration">
            <Panel>
                <Tabs>
                    {[
                        isSuperAdmin &&
                        {
                            key: 'information',
                            render: () => <HarbourConfigurationTab />,
                        },
                        isSuperAdmin &&
                        {
                            key: 'season',
                            render: () => <RateSeasonConfiguration />,
                        },
                        isSuperAdmin &&
                        {
                            key: 'cameras',
                            render: () => <CamerasConfiguration />,
                        },
                        {
                            key: 'urgencePhoneNumbers',
                            render: () => <UrgencePhoneNumberConfiguration />,
                        },
                        isSuperAdmin &&
                        {
                            key: 'places',
                            render: () => <PlacesConfiguration />,
                        },
                        {
                            key: 'users',
                            render: () => <UsersConfiguration />,
                        },
                        isSuperAdmin &&
                        {
                            key: 'categories',
                            render: () => <CategoriesConfiguration />,
                        },
                        {
                            key: 'incidents',
                            render: () => <IncidentConfiguration />,
                        },
                        modules.communication >= PREMIUM && {
                            key: 'notification-user-lists',
                            render: () => <NotificationUserListConfiguration />,
                        },
                        isSuperAdmin && modules.weather >= PREMIUM && {
                            key: 'weather-api',
                            render: () => <WeatherApiConfiguration />,
                        },
                        {
                            key: 'partners',
                            render: () => <PartnerConfiguration />,
                        },
                        isSuperAdmin && {
                            key: 'external-links',
                            render: () => <ExternalLinkConfiguration />,
                        }
                    ]
                        .filter((t) => !!t)
                        .map((object) => (
                            <TabPane
                                tab={Locale.trans(object.key)}
                                key={object.key}
                            >
                                {object.render()}
                            </TabPane>
                        ))}
                </Tabs>
            </Panel>
        </div>
    );
};

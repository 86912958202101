import React from 'react';
import { Modal, Button, Icon } from 'antd';
import Locale from 'locale/LocaleFactory';
import NotificationIcon from './NotificationIcon';
import NotificationThumbnail from './NotificationThumbnail';
import StringService from 'services/utils/StringService';

import './NotificationDetails.scss';
import LoginStore from 'stores/LoginStore';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import HarbourStore from 'stores/HarbourStore';

export default function NotificationDetailsModal({
    visible,
    onClose,
    notification,
}) {
    return (
        <Modal
            title={Locale.trans('communication.notification.details')}
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            {notification && (
                <NotificationDetails notification={notification} />
            )}
        </Modal>
    );
}

function NotificationDetails({ notification }) {
    return (
        <div className="notification-details">
            <div className="__header">
                {notification.type === 'news' ? (
                    <NotificationThumbnail notification={notification} />
                ) : (
                    <div className="__icon">
                        <NotificationIcon
                            notification={notification}
                            size={2}
                        />
                    </div>
                )}
                <div className="__title">{notification.title}</div>
            </div>
            <div className="__description">
                {StringService.nl2br(notification.description)}
            </div>
            {notification.url && (
                <div className="__url">
                    {Locale.trans('communication.notification.link')} :{' '}
                    <a href={addHttp(notification.url)}>{notification.url}</a>
                </div>
            )}
            {notification.attachments.length > 0 && (
                <div className="__attachments">
                    {notification.attachments.map((attachment) => (
                        <Attachment
                            notification={notification}
                            attachment={attachment}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

function addHttp(str) {
    if (
        str.toLowerCase().startsWith('http://') ||
        str.toLowerCase().startsWith('https://')
    ) {
        return str;
    }
    return 'http://' + str;
}

function Attachment({ notification, attachment }) {
    const currentHarbour = HarbourStore.getCurrentHarbour();
    const token = LoginStore.getJwt();
    const formattedToken = token ? token.replace(/\+/g, '%2B') : '';
    const url = `${BaseUrlConstants.BASE_URL}harbours/${currentHarbour.id}/notifications/${notification.id}/attachments/${attachment.id}`;

    return (
        <div className="__item">
            {attachment.mimeType.startsWith('image/') ? (
                <img
                    alt={attachment.name}
                    src={`${url}?X-Auth-Token=${formattedToken}`}
                />
            ) : (
                <Button
                    onClick={() => {
                        window.open(
                            `${url}?X-Auth-Token=${formattedToken}`,
                            '_blank',
                        );
                    }}
                >
                    <Icon type="file-pdf" /> {attachment.name}
                </Button>
            )}
        </div>
    );
}

import React from 'react';
import { Table, Row } from 'antd';

import Locale from 'locale/LocaleFactory';
import moment from 'moment';
import OptimisationStore from 'stores/OptimisationStore';
import MathService from 'services/utils/MathService';

export default class OptimisationTable extends React.Component {
    constructor() {
        super();
        this.state = {
            main: {
                period: {
                    start: moment(),
                    end: moment(),
                },
                total: 0,
                declared: 0,
                optimized: 0,
            },
            compared: {
                period: {
                    start: moment(),
                    end: moment(),
                },
                total: 0,
                declared: 0,
                optimized: 0,
            },
        };
    }
    componentDidMount = () => {
        OptimisationStore.addChangeListener(this.changeData);
    };

    changeData = () => {
        const data = OptimisationStore.getHistory();
        const reducer = (total, current) => ({
            total: total.total + current.total,
            declared: total.declared + current.declared,
            optimized: total.optimized + current.optimized,
            outings: total.outings + current.outings,
            profit: {
                potential: total.profit.potential + current.profit.potential,
                lost: total.profit.lost + current.profit.lost,
                realized: total.profit.realized + current.profit.realized,
            },
        });
        const getDefault = () => ({
            total: 0,
            declared: 0,
            optimized: 0,
            outings: 0,
            profit: {
                potential: 0,
                lost: 0,
                realized: 0,
            },
        });
        const main = data.main.nbAbsences.reduce(reducer, getDefault());
        main.period = data.main.period;
        const compared = data.compared.nbAbsences.reduce(reducer, getDefault());
        compared.period = data.compared.period;
        this.setState({ main, compared });
    }

    render = () => {
        const { main, compared } = this.state;
        const data = [
            {
                period: main.period,
                nights: {
                    declared: main.declared,
                    optimized: main.optimized,
                    outside: main.total,
                    outings: main.outings,
                },
                optimisation: {
                    potentialProfit: (main.profit && main.profit.potential) || 0,
                    profit: (main.profit && main.profit.realized) || 0,
                },
            },
            {
                period: compared.period,
                nights: {
                    declared: compared.declared,
                    optimized: compared.optimized,
                    outside: compared.total,
                    outings: compared.outings,
                },
                optimisation: {
                    potentialProfit: (compared.profit && compared.profit.potential) || 0,
                    profit: (compared.profit && compared.profit.realized) || 0,
                },
            },
        ];
        const columns = [{
            title: Locale.trans('reporting.optimisation.tableTitle.period'),
            dataIndex: 'period',
            key: 'period',
            render: period => Locale.trans('reporting.optimisation.period', {
                start: period.start.format('DD/MM/YY'),
                end: period.end.format('DD/MM/YY'),
            }),
        }, {
            title: Locale.trans('reporting.optimisation.tableTitle.nights'),
            dataIndex: 'nights',
            key: 'nights',
            render: nights => (
                <ul>
                    <li>
                        <span className="definition">{Locale.trans('reporting.optimisation.outings')} : </span>
                        <span className="value">{nights.outings}</span>
                    </li>
                    <li>
                        <span className="definition">{Locale.trans('reporting.optimisation.outside')} : </span>
                        <span className="value">{nights.outside}</span>
                    </li>
                </ul>
            ),
        }, {
            title: Locale.trans('reporting.optimisation.tableTitle.absences'),
            dataIndex: 'nights',
            key: 'absences',
            render: (absences) => {
                const optimisationRate = Math.round((absences.optimized / absences.outside) * 1000) / 10;
                const declarationRate = Math.round((absences.declared / absences.outside) * 1000) / 10;
                return (
                    <ul>
                        <li>
                            <span className="definition">{Locale.trans('reporting.optimisation.declared')} : </span>
                            <span className="value">{absences.declared} ({absences.outside === 0 ? '--' : declarationRate}%)</span>
                        </li>
                        <li>
                            <span className="definition">{Locale.trans('reporting.optimisation.optimized')} :</span>
                            <span className="value">{absences.optimized} ({absences.outside === 0 ? '--' : optimisationRate}%)</span>
                        </li>
                    </ul>
                );
            },
        }, {
            title: Locale.trans('reporting.optimisation.tableTitle.profit'),
            dataIndex: 'optimisation',
            key: 'optimisation',
            render: optimisation => (
                <ul>
                    <li>
                        <span className="definition">{Locale.trans('reporting.optimisation.profit.potential')} :</span>
                        <span className="value">{MathService.format(optimisation.potentialProfit, 2)}</span>
                    </li>
                    <li>
                        <span className="definition">{Locale.trans('reporting.optimisation.profit.realised')} :</span>
                        <span className="value">{MathService.format(optimisation.profit, 2)}</span>
                    </li>
                </ul>
            ),
        }, {
            title: Locale.trans('reporting.optimisation.tableTitle.losts'),
            dataIndex: 'optimisation',
            key: 'losts',
            render: optimisation => (
                <ul>
                    <li>
                        <span className="definition">{Locale.trans('reporting.optimisation.profit.lost')}: </span>
                        <span className="value">{MathService.format(optimisation.potentialProfit - optimisation.profit, 2)}</span>
                    </li>
                </ul>
            ),
        }];
        return (
            <Row className="optimisation table">
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                />
            </Row>
        );
    }
}


import React from 'react';
import { Modal, Table } from 'antd';
import moment from 'moment';

import Locale from 'locale/LocaleFactory';

export default function ViewOutingsModal({ day, loading, outings, visible, onCancel }) {
    const columns = [
        {
            title: Locale.trans('outing.timeOut'),
            dataIndex: 'datetimeOut',
            key: 'datetimeOut',
            render: (e) => {
                const date = moment(e);
                return date.format('HH:mm:ss');
            },
        },
        {
            title: Locale.trans('outing.timeIn'),
            dataIndex: 'datetimeIn',
            key: 'datetimeIn',
            render: (e) => {
                const date = moment(e);
                return date.format('HH:mm:ss');
            },
        },
        {
            title: Locale.trans('place'),
            dataIndex: 'place',
            key: 'place',
            render: e => e.code,
        },
        {
            title: Locale.trans('boat'),
            dataIndex: 'boat',
            key: 'boat',
            render: e => e.name,
        },
    ]
    return (
        <Modal
            title={Locale.trans('outing.listByDay', { date: day.format('DD/MM/YYYY') })}
            confirmLoading={loading}
            visible={visible}
            onCancel={onCancel}
            onOk={onCancel}
        >
            <Table
                columns={columns}
                dataSource={outings}
            />
        </Modal>
    )
}
import React from 'react';

import { Table, Icon } from 'antd';

import Locale from 'locale/LocaleFactory';
import moment from 'moment';
import DateService from 'services/utils/DateService';

import HarbourStore from 'stores/HarbourStore';

import ActivityLogService from '../services/ActivityLogService';

export default class CavalaireChallengeTable extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            rows: [],
        };
    }

    componentDidMount() {
        this.reloadChallengeLogs();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item.id !== this.props.item.id) {
            this.reloadChallengeLogs();
        }
    }

    reloadChallengeLogs() {
        const { item } = this.props;
        this.setState({
            loading: true,
        });
        if (this.props.itemType === "boat") {
            ActivityLogService.getCavalaireChallengeOutingsOfBoatAction(item.harbour.id, item.id, this.props.year).then(this.updateLogs.bind(this));
        } else {
            ActivityLogService.getCavalaireChallengeOutingsOfPlaceAction(item.harbour.id, item.id, this.props.year).then(this.updateLogs.bind(this));
        }
    }

    updateLogs(logs) {
        this.setState({
            loading: false,
            rows: CavalaireChallengeTable.getTableRowsFromLogs(logs),
        });
    }

    static getTableRowsFromLogs(logs) {
        let lastIn = null;
        let lastOut = null;

        const detail = [];

        for (let i = 0; i < logs.length; i++) {
            const log = logs[i];
            if (log.way === 'in') {
                lastIn = log;
            } else {
                lastOut = log;
            }

            if (lastIn && lastOut) {
                const dateIn = moment(lastIn.datetime, 'YYYY-MM-DD HH:mm');
                const dateOut = moment(lastOut.datetime, 'YYYY-MM-DD HH:mm');
                const isSameDay = dateIn.format('YYMMDD') === dateOut.format('YYMMDD');
                detail.push({
                    key: lastOut.id,
                    dateOut,
                    dateIn,
                    isSameDay,
                });

                lastIn = null;
                lastOut = null;
            }
        }

        return detail;
    }

    render() {
        const harbour = HarbourStore.getCurrentHarbour();
        if (!harbour.showCavalaireChallenge) {
            return null;
        }

        const { rows, loading } = this.state;
        const columns = [
            {
                title: Locale.trans('absence.departureDate'),
                key: 'departureDate',
                render: this.renderDepartureDateCell,
            },
            {
                title: Locale.trans('absence.returnDate'),
                key: 'returnDate',
                render: this.renderReturnDateCell,
            },
        ];

        return (
            <div className="panel">
                <h5 className="title">Challenge {this.props.year} ({rows && rows.length ? rows.length : 0})</h5>
                <div className="content">
                    <Table
                        dataSource={rows}
                        rowKey="outing.id"
                        columns={columns}
                        locale={Locale.Table}
                        loading={loading && { indicator: <Icon type="loading" spin /> }}
                    />
                </div>
            </div>
        );
    }

    renderDepartureDateCell = (row) =>
        moment(DateService.parseApi(row.dateOut)).format('DD/MM/YYYY HH:mm');
    renderReturnDateCell = (row) =>
        moment(DateService.parseApi(row.dateIn)).format('DD/MM/YYYY HH:mm');
}

import React from 'react';
import { Form, Row, Col, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';

import SecurityService from 'services/SecurityService';

class PlaceForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const place = this.getEntityFromValues(values);
                this.props.onSubmit(place);
            }
        });
    };

    getEntityFromValues = (values) => {
        const boat = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.map((k) => {
            if (k.endsWith('_ids')) {
                return null;
            }
            if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                boat[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                boat[k] = values[k];
            }
            return null;
        });
        return boat;
    };

    render() {
        const { form } = this.props;
        return (
            <Form onSubmit={this.handleSubmit}>
                <FormItem.Input
                    id="code"
                    required
                    label={Locale.trans('place.code')}
                    form={this.props.form}
                    validateStatus={this.getValidateStatus('code')}
                    help={this.getHelp('code')}
                    hasFeedback
                />
                <FormItem.Input
                    id="length"
                    required
                    label={Locale.trans('place.length')}
                    form={this.props.form}
                    validateStatus={this.getValidateStatus('length')}
                    help={this.getHelp('length')}
                    hasFeedback
                />
                <FormItem.Input
                    id="width"
                    required
                    label={Locale.trans('place.width')}
                    form={this.props.form}
                    validateStatus={this.getValidateStatus('width')}
                    help={this.getHelp('width')}
                    hasFeedback
                />
                <FormItem.Input
                    id="draught"
                    required
                    label={Locale.trans('place.draught')}
                    form={this.props.form}
                    validateStatus={this.getValidateStatus('draught')}
                    help={this.getHelp('draught')}
                    hasFeedback
                />
                {this.renderBoatTypesField()}
                {this.renderPlaceTypeField()}
                {this.renderPontoonField()}

                {SecurityService.isSuperAdmin() && (
                    <fieldset>
                        <legend>Senplify</legend>
                        <Row gutter={16}>
                            <Col span={12}>{this.renderDeviceTypeField()}</Col>
                            <Col span={12}>
                                <FormItem.Input
                                    id="deviceId"
                                    label={Locale.trans('place.deviceId')}
                                    form={this.props.form}
                                    validateStatus={this.getValidateStatus(
                                        'deviceId',
                                    )}
                                    help={this.getHelp('deviceId')}
                                    hasFeedback
                                />
                            </Col>
                        </Row>
                        {form.getFieldValue('deviceType') === 'pontoon' && (
                            <Row gutter={16}>
                                <Col span={12}>
                                    <FormItem.Input
                                        id="ultrasonicMinThreshold"
                                        label={Locale.trans(
                                            'place.ultrasonicMinThreshold',
                                        )}
                                        form={this.props.form}
                                        validateStatus={this.getValidateStatus(
                                            'ultrasonicMinThreshold',
                                        )}
                                        help={this.getHelp(
                                            'ultrasonicMinThreshold',
                                        )}
                                        hasFeedback
                                        addonAfter="mm"
                                    />
                                </Col>
                                <Col span={12}>
                                    <FormItem.Input
                                        id="ultrasonicMaxThreshold"
                                        label={Locale.trans(
                                            'place.ultrasonicMaxThreshold',
                                        )}
                                        form={this.props.form}
                                        validateStatus={this.getValidateStatus(
                                            'ultrasonicMaxThreshold',
                                        )}
                                        help={this.getHelp(
                                            'ultrasonicMaxThreshold',
                                        )}
                                        hasFeedback
                                        addonAfter="mm"
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row gutter={16}>
                            <Col span={12}>
                                <FormItem.Input
                                    id="minOutFrameThreshold"
                                    label={Locale.trans(
                                        'place.minOutFrameThreshold',
                                    )}
                                    form={this.props.form}
                                    validateStatus={this.getValidateStatus(
                                        'minOutFrameThreshold',
                                    )}
                                    help={this.getHelp('minOutFrameThreshold')}
                                    hasFeedback
                                />
                            </Col>
                            <Col span={12}>
                                <FormItem.Input
                                    id="minInFrameThreshold"
                                    label={Locale.trans(
                                        'place.minInFrameThreshold',
                                    )}
                                    form={this.props.form}
                                    validateStatus={this.getValidateStatus(
                                        'minInFrameThreshold',
                                    )}
                                    help={this.getHelp('minInFrameThreshold')}
                                    hasFeedback
                                />
                            </Col>
                        </Row>
                    </fieldset>
                )}
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={this.props.loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }
    renderBoatTypesField() {
        const { getFieldValue } = this.props.form;
        const boatTypes = ['motorboat', 'sailboat', 'multihull'];
        return (
            <FormItem.Select
                id="boatTypes"
                required
                showSearch
                multiple
                label={Locale.trans('place.boatTypes')}
                initialValue={(getFieldValue('boatTypes') || [])
                    .filter((b) => !!b)
                    .map((b) => b.toString())}
                options={boatTypes.map((c) => ({
                    value: c,
                    label: Locale.trans(c),
                }))}
                form={this.props.form}
                validateStatus={this.getValidateStatus('boatTypes')}
                help={this.getHelp('boatTypes')}
                hasFeedback
            />
        );
    }
    renderPlaceTypeField() {
        const placeTypes = ['resident', 'stopover'];
        return (
            <FormItem.Select
                id="placeType"
                required
                showSearch
                label={Locale.trans('place.placeType')}
                options={placeTypes.map((c) => ({
                    value: c,
                    label: Locale.trans(c),
                }))}
                form={this.props.form}
                validateStatus={this.getValidateStatus('placeType')}
                help={this.getHelp('placeType')}
                hasFeedback
            />
        );
    }
    renderDeviceTypeField() {
        const deviceTypes = ['pontoon', 'buoy'];
        return (
            <FormItem.Select
                id="deviceType"
                showSearch
                label={Locale.trans('place.deviceType')}
                options={deviceTypes.map((c) => ({
                    value: c,
                    label: Locale.trans(`place.deviceTypes.${c}`),
                }))}
                form={this.props.form}
                validateStatus={this.getValidateStatus('deviceType')}
                help={this.getHelp('deviceType')}
                hasFeedback
            />
        );
    }
    renderPontoonField() {
        const { pontoons } = this.props;
        const { getFieldValue } = this.props.form;
        return (
            <FormItem.Select
                id="pontoon_id"
                showSearch
                allowClear
                label={Locale.trans('place.pontoon')}
                initialValue={
                    getFieldValue('pontoon')
                        ? getFieldValue('pontoon').id.toString()
                        : null
                }
                options={pontoons
                    .filter((p) => !!p)
                    .map((c) => ({ value: c.id, label: c.name }))}
                form={this.props.form}
                validateStatus={this.getValidateStatus('pontoon_id')}
                help={this.getHelp('pontoon_id')}
                hasFeedback
            />
        );
    }
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(PlaceForm);

import { useEffect, useState } from 'react';
import AbsenceStore from 'stores/AbsenceStore';
import AbsenceActions from 'actions/AbsenceActions';
import HarbourStore from 'stores/HarbourStore';

export default function useAbsencesOfHarbour(harbour) {
    const [absences, setAbsences] = useState([]);

    const currentHarbourId = harbour.id;
    useEffect(() => {
        const currentHarbour = HarbourStore.getById(currentHarbourId);
        function refreshAbsencesOfCurrentHarbour() {
            setAbsences(AbsenceStore.getByHarbour(currentHarbour));
        }

        refreshAbsencesOfCurrentHarbour();
        AbsenceActions.reloadByHarbour(currentHarbour, true);

        AbsenceStore.addChangeListener(refreshAbsencesOfCurrentHarbour);
        return () => {
            AbsenceStore.removeChangeListener(refreshAbsencesOfCurrentHarbour);
        };
    }, [currentHarbourId]);

    return absences;
}

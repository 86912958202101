import dispatcher from '../dispatchers/AppDispatcher';
import ActionsBase from '../actions/ActionsBase';

import IncidentConstants from '../constants/IncidentConstants';
import IncidentService from '../services/IncidentService';

import CacheManager from '../services/CacheManager';
import HarbourStore from 'stores/HarbourStore';

const CACHE_DURATION = 15 * 1000;

const RELOAD_INTERVAL = 20 * 1000;
const intervalIds = {} as { [harbourId: number]: any };
class IncidentActions extends ActionsBase {
    create = (harbour: IHarbour, incident: IIncident) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newIncident: IIncident) {
                $this.reloadByHarbour(harbour, true);

                dispatcher.dispatch({
                    type: IncidentConstants.RECEIVE_INCIDENT_OF_HARBOUR,
                    payload: {
                        harbour,
                        incident: newIncident,
                    },
                });
                resolve(newIncident);
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }

            IncidentService.post(harbour.id, incident)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (harbour: IHarbour, incidentId: number, incident: any) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newIncident: IIncident) {
                $this.reloadByHarbour(harbour, true);

                dispatcher.dispatch({
                    type: IncidentConstants.RECEIVE_INCIDENT_OF_HARBOUR,
                    payload: {
                        harbour,
                        incident: newIncident,
                    },
                });
                resolve(newIncident);
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }
            IncidentService.patch(harbour.id, incidentId, incident)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    close = (incidentId: number) => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                function handleSuccess(): void {
                    $this.reloadByHarbour(
                        HarbourStore.getCurrentHarbour(),
                        true,
                    );
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }
                IncidentService.close(incidentId)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    acknowledge = (incidentId: number) => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                function handleSuccess(): void {
                    $this.reloadByHarbour(
                        HarbourStore.getCurrentHarbour(),
                        true,
                    );
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }
                IncidentService.acknowledge(incidentId)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    delete = (harbour: IHarbour, id: number) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess() {
                dispatcher.dispatch({
                    type: IncidentConstants.DELETE_INCIDENT_OF_HARBOUR,
                    payload: {
                        harbour,
                        id,
                    },
                });
                resolve();
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }

            IncidentService.remove(harbour.id, id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByHarbour = (
        harbour: IHarbour | null | undefined,
        force?: boolean,
    ) => {
        if (harbour && !intervalIds[harbour.id]) {
            intervalIds[harbour.id] = setInterval(() => {
                this.reloadByHarbour(harbour).catch(function () {
                    clearInterval(intervalIds[harbour.id]);
                    intervalIds[harbour.id] = null;
                });
            }, RELOAD_INTERVAL);
        }

        const $this = this;
        return new Promise((resolve, reject) => {
            if (
                !harbour ||
                (!force &&
                    CacheManager.isCached(
                        'Incident:reloadByHarbour',
                        harbour.id.toString(),
                        CACHE_DURATION,
                    ))
            ) {
                resolve();
                return;
            }

            function handleSuccess(incidents: IIncident) {
                dispatcher.dispatch({
                    type: IncidentConstants.RECEIVE_INCIDENTS_OF_HARBOUR,
                    payload: {
                        harbour,
                        incidents,
                    },
                });
                resolve();
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }

            IncidentService.getByHarbour(harbour.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    editConfigurations(configurations: any) {
        const $this = this;
        return new Promise((resolve, reject) => {
            const harbour = HarbourStore.getCurrentHarbour();
            function handleSuccess(newIncident: any) {
                $this.reloadConfigurationByHarbour(harbour, true);

                dispatcher.dispatch({
                    type: IncidentConstants.RECEIVE_INCIDENT_OF_HARBOUR,
                    payload: {
                        harbour,
                        incident: newIncident,
                    },
                });
                resolve(newIncident);
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }
            if (harbour) {
                IncidentService.patchConfiguration(harbour.id, configurations)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        });
    }

    reloadConfigurationByHarbour = (
        harbour: IHarbour | null | undefined,
        force?: boolean,
    ) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            if (
                !harbour ||
                (!force &&
                    CacheManager.isCached(
                        'Incident:reloadConfigurationByHarbour',
                        harbour.id.toString(),
                        CACHE_DURATION,
                    ))
            ) {
                resolve();
                return;
            }

            function handleSuccess(incidents: IIncident[]) {
                dispatcher.dispatch({
                    type: IncidentConstants.RECEIVE_CONFIGURATION_OF_HARBOUR,
                    payload: {
                        harbour,
                        incidents,
                    },
                });
                resolve();
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }

            IncidentService.getConfigurationsByHarbour(harbour.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    addComment = (harbour: IHarbour, incidentId: number, comment: string) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess() {
                $this.reloadByHarbour(harbour, true);
                resolve();
            }

            function handleError(err: any) {
                $this.handleError(err, reject);
            }
            IncidentService.addComment(harbour.id, incidentId, comment)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

export default new IncidentActions();

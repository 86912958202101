import React from 'react';

import { Table, Button, Popover, Radio } from 'antd';
import TableColumnFilter from 'components/table/TableColumnFilter.jsx';
import FilterIcon from 'components/table/FilterIcon.jsx';
import CreateBoatModal from 'pages/CreateBoatModal.jsx';
import EditBoatModal from 'pages/EditBoatModal.jsx';
import ShowActivityModal from 'pages/ShowActivityModal.jsx';
import CreateYachtsmanModal from 'pages/CreateYachtsmanModal.jsx';
import EditYachtsmanModal from 'pages/EditYachtsmanModal.jsx';
import LoadingIcon from 'components/LoadingIcon.jsx';

import Locale from 'locale/LocaleFactory';
import SecurityService from 'services/SecurityService';
import ArrayService from 'services/utils/ArrayService';
import StringService from 'services/utils/StringService';
import FilterService from 'services/utils/FilterService';
import DateService from 'services/utils/DateService';

import HarbourStore from 'stores/HarbourStore';

import UserActions from 'actions/UserActions';
import UserStore from 'stores/UserStore';

import PendingAccessRequestsTable from './Boats/PendingAccessRequestsTable';
import AbsenceService from 'services/AbsenceService';
import ActivityLogService from 'services/ActivityLogService';

// Sorting Methods
function sortBoatColumn(i1, i2) {
    return StringService.compareCaseInsensitive(i1.boat ? i1.boat.name : "", i2.boat ? i2.boat.name : "");
}
function sortBoatTypeColumn(i1, i2) {
    return StringService.compareCaseInsensitive(i1.boatType, i2.boatType);
}
function sortPlaceColumn(i1, i2) {
    const c1 = i1.place && i1.place.code;
    const c2 = i2.place && i2.place.code;
    const regex = /^\d+$/;
    if (!regex.test(c1) || !regex.test(c2)) {
        return StringService.compareCaseInsensitive(c1, c2);
    }
    return Number.parseInt(c1, 10) > Number.parseInt(c2, 10) ? 1 : -1;
}
function sortOwnersColumn(b1, b2) {
    const o1 =
        b1 &&
        b1.owners &&
        b1.owners.reduce((acc, o) => acc + o.firstName + o.lastName, '');
    const o2 =
        b2 &&
        b2.owners &&
        b2.owners.reduce((acc, o) => acc + o.firstName + o.lastName, '');

    return StringService.compareCaseInsensitive(o1, o2);
}

function sortBoatPositionColumn(b1, b2) {
    const p1 =
        b1.place && Locale.trans(`position.${b1.place.empty ? 'out' : 'in'}`);
    const p2 =
        b2.place && Locale.trans(`position.${b2.place.empty ? 'out' : 'in'}`);
    return StringService.compareCaseInsensitive(p1, p2);
}

/**
 * The list of the boats of the harbour.
 */
export default class BoatList extends React.Component {

    _currentYear;
    _synthesisYear;
    _displayBy;

    constructor() {
        super();
        this._currentYear = new Date().getFullYear();
        this._synthesisYear = this._currentYear;
        this._displayBy = "boat";

        this.state = {
            loadingOutings: false,
            loadingAbsences: false,
            activities: [],
            filteredActivities: [],
            filterGlobal: '',
            filters: {
                name: [],
                boatType: [],
                place: [],
                owners: [],
                position: [],
            },

            createBoatVisible: false,
            editBoatVisible: false,
            itemToEdit: null,
            showActivityVisible: false,
            activityToShow: null,
            boatAbsences: [],
            createUserVisible: false,
            editUserVisible: false,
            userToEdit: null,
            absences: [],
        };
    }

    componentDidMount() {
        HarbourStore.addCurrentHarbourChangeListener(
            this.onChangeCurrentHarbour,
        );
        UserStore.addChangeListener(this.receiveUsers);

        this.reloadData();

        this.updateFilters();
        // Here we set the default sorted column
        // Temporary solution waiting for AntD to propose a native way to do it.
        const column = this.table.findColumn('reference');
        this.table.toggleSortOrder('descend', column);
    }

    componentWillUnmount() {
        HarbourStore.removeCurrentHarbourChangeListener(
            this.onChangeCurrentHarbour,
        );
        UserStore.removeChangeListener(this.receiveUsers);
    }

    sortCountAbsencesColumn = (b1, b2) => {
        return this.getAbsencesByBoat(b1).length < this.getAbsencesByBoat(b2).length
            ? -1
            : 1;
    }
    
    sortCountOutingsColumn = (b1, b2) => {
        const s1 = this.getActivity(b1);
        const s2 = this.getActivity(b2);
        const count1 = s1 ? s1.countOutings : 0;
        const count2 = s2 ? s2.countOutings : 0;
        return count1 < count2 ? -1 : 1;
    }
    
    sortCavalaireChallengeColumn = (b1, b2) => {
        const s1 = this.getActivity(b1);
        const s2 = this.getActivity(b2);
        const count1 = s1 ? s1.countCavalaireChallenge : 0;
        const count2 = s2 ? s2.countCavalaireChallenge : 0;
        return count1 < count2 ? -1 : 1;
    }
    reloadData = () => {
        this.setState({
            loadingAbsences: true,
            loadingOutings: true,
        });
        AbsenceService.getByHarbour(HarbourStore.getCurrentHarbour().id, this._synthesisYear)
            .then(response => {
                this.setState({
                    loadingAbsences: false,
                    absences: response,
                });
                this.updateFilters();
            });
        ActivityLogService.getSummariesByHarbour(HarbourStore.getCurrentHarbour().id, this._synthesisYear, this._displayBy)
            .then(response => {
                this.setState({
                    loadingOutings: false,
                    activities: response.map(item => {
                        item.id = this._displayBy === "boat" ? item.boat.id : item.place.id;
                        return item;
                    }),
                });
                this.updateFilters();
            });
        UserActions.reloadByHarbour(HarbourStore.getCurrentHarbour());
    };

    onChangeCurrentHarbour = () => {
        this.reloadData();
        this.receiveUsers();
    };


    receiveUsers = () => {
        this.forceUpdate();
    };

    // Filters
    updateFilters = () => {
        const { activities } = this.state;
        const filteredActivities = activities.filter(this.activityMatchFilters);
        this.setState({ filteredActivities });
    };

    activityMatchFilters = (activity) => {
        const { filters } = this.state;
        return (
            FilterService.matchFilter(filters.name, activity.boat && activity.boat.name) &&
            FilterService.matchFilter(filters.boatType, activity.boat && activity.boat.boatType) &&
            FilterService.matchFilter(filters.place, activity.place && activity.place.id) &&
            FilterService.matchFilter(
                filters.owners,
                activity.boat && activity.boat.owners && activity.boat.owners.map((owner) => owner.id),
            ) &&
            FilterService.matchFilter(
                filters.position,
                activity.place && activity.place.empty ? 'out' : 'in',
            ) &&
            this.matchGlobalSearch(activity)
        );
    };

    matchGlobalSearch = (activity) => {
        const { filterGlobal } = this.state;
        return (
            (activity && activity.boat && activity.boat.name && activity.boat.name.toLowerCase().indexOf(filterGlobal) > -1) ||
            (activity.place && activity.place.code && 
                activity.place.code.toLowerCase().indexOf(filterGlobal) > -1)
        );
    };

    handleFilterChange = (name, values) => {
        const newFilters = { ...this.state.filters };
        newFilters[name] = values;
        this.setState(
            {
                filters: newFilters,
            },
            this.updateFilters,
        );
    };

    getBoatNames = () =>
        ArrayService.unique(this.state.activities.map((activity) => activity.boat && activity.boat.name));
    getBoatTypes = () =>
        ArrayService.unique(this.state.activities.map((activity) => activity.boat && activity.boat.boatType));
    getBoatPositions = () => ['in', 'out'];
    getBoatPlaces = () =>
        ArrayService.uniqueEntity(
            this.state.activities.map((activity) => activity.place).filter((place) => !!place),
        );
    getBoatOwners = () =>
        ArrayService.uniqueEntity(
            this.state.activities
                .reduce((acc, activity) => acc.concat(activity.boat && activity.boat.owners), [])
                .filter((p) => !!p),
        );

    showCreateBoatModal = () => {
        this.setState({
            createBoatVisible: true,
        });
    };
    hideCreateBoatModal = () => {
        this.setState({
            createBoatVisible: false,
        });
    };

    editBoat = (item) => {
        this.setState({
            editBoatVisible: true,
            itemToEdit: item,
        });
    };
    hideEditBoatModal = (newBoat) => {
        this.setState({
            editBoatVisible: false,
            itemToEdit: null,
        });
    };

    editBoatModalSucceed = (newBoat) => {
        const { itemToEdit } = this.state;
        itemToEdit.boat = newBoat;
    }

    showActivity = (activity) => {
        const boatAbsences = this.getAbsencesByBoat(activity);
        this.setState({
            showActivityVisible: true,
            activityToShow: activity,
            boatAbsences: boatAbsences,
        });
    };
    hideShowActivityModal = () => {
        this.setState({
            showActivityVisible: false,
            activityToShow: null,
            boatAbsences: [],
        });
    };

    showCreateYachtsmanModal = () => {
        this.setState({
            createUserVisible: true,
        });
    };
    hideCreateYachtsmanModal = () => {
        this.setState({
            createUserVisible: false,
        });
    };

    editUser = (user) => {
        this.setState({
            editUserVisible: true,
            userToEdit: user,
        });
    };
    hideEditYachtsmanModal = () => {
        this.setState({
            editUserVisible: false,
            userToEdit: null,
        });
    };

    _onChangeSeason = (e) => {
        this._synthesisYear = e.target.value;
        this.reloadData();
      }

    _onChangeDisplayBy = (e) => {
        this._displayBy = e.target.value;
        const { filters } = this.state;
        filters.name = [];
        filters.place = [];
        this.setState({
            filters: filters
        });
        this.reloadData();
    }  

    render() {
        const { activityToShow, userToEdit, itemToEdit, boatAbsences } = this.state;
        const isAllowedToEdit = SecurityService.isGranted(['captain']);
        return (
            <div className="boat-list">
                <div className='boat-list-by'>
                    <span>Affichage par&nbsp;</span>
                    <Radio.Group onChange={this._onChangeDisplayBy} defaultValue="boat" style={{ marginTop: 16 }} buttonStyle="solid">
                        <Radio.Button value="boat">{Locale.trans('boat')}</Radio.Button>
                        <Radio.Button value="place">{Locale.trans('boat.place')}</Radio.Button>
                    </Radio.Group>
                </div>
                <div className='boat-list-year'>
                    <Radio.Group onChange={this._onChangeSeason} defaultValue={this._currentYear} style={{ marginTop: 16 }} buttonStyle="solid">
                        <Radio.Button value={this._currentYear}>{this._currentYear}</Radio.Button>
                        <Radio.Button value={this._currentYear-1}>{this._currentYear-1}</Radio.Button>
                        <Radio.Button value={this._currentYear-2}>{this._currentYear-2}</Radio.Button>
                    </Radio.Group>
                </div>
                {this.renderBoatTable(isAllowedToEdit)}

                {isAllowedToEdit && (
                    <>
                        <div
                            className="actions-row"
                            style={{ marginTop: '-50px' }}
                        >
                            <Button
                                type="primary"
                                icon="plus"
                                onClick={this.showCreateBoatModal}
                            >
                                {Locale.trans('boat.creation.title')}
                            </Button>
                            <Button
                                type="primary"
                                icon="plus"
                                onClick={this.showCreateYachtsmanModal}
                            >
                                {Locale.trans('yachtsman.creation.title')}
                            </Button>
                        </div>

                        <CreateBoatModal
                            onCancel={this.hideCreateBoatModal}
                            visible={this.state.createBoatVisible}
                        />
                        <EditBoatModal
                            boat={itemToEdit}
                            onCancel={this.hideEditBoatModal}
                            onSave={this.editBoatModalSucceed}
                            visible={this.state.editBoatVisible}
                        />

                        <CreateYachtsmanModal
                            onCancel={this.hideCreateYachtsmanModal}
                            visible={this.state.createUserVisible}
                        />

                        <PendingAccessRequestsTable />
                    </>
                )}
                <EditYachtsmanModal
                    user={userToEdit}
                    onCancel={this.hideEditYachtsmanModal}
                    visible={this.state.editUserVisible}
                />
                <ShowActivityModal
                    activity={activityToShow}
                    year={this._synthesisYear}
                    absences={boatAbsences}
                    onCancel={this.hideShowActivityModal}
                    onEditUser={this.editUser}
                    visible={this.state.showActivityVisible}
                    displayBy={this._displayBy}
                />
            </div>
        );
    }

    renderBoatTable(showActions) {
        const { filters, filteredActivities, loadingOutings, loadingAbsences } = this.state;
        const boatColumn = {
            title: Locale.trans('boat'),
            key: 'boat.id',
            render: this.renderBoatCell,
            sorter: sortBoatColumn,
            filterIcon: <FilterIcon active={filters.name.length > 0} />,
            filterDropdown: (
                <TableColumnFilter
                    name="name"
                    selectedValues={filters.name}
                    values={this.getBoatNames().map((r) => ({
                        text: r,
                        value: r,
                    }))}
                    onChange={this.handleFilterChange}
                />
            ),
        };
        const placeColumn = {
            title: Locale.trans('boat.place'),
            key: 'place',
            render: this.renderPlaceCell,
            sorter: sortPlaceColumn,
            filterIcon: <FilterIcon active={filters.place.length > 0} />,
            filterDropdown: (
                <TableColumnFilter
                    name="place"
                    selectedValues={filters.place}
                    values={this.getBoatPlaces().map((r) => ({
                        text: r.code,
                        value: r.id,
                    }))}
                    onChange={this.handleFilterChange}
                />
            ),
        };
        const columns = [ 
            this._displayBy === "boat" ? boatColumn : placeColumn,
            this._displayBy === "boat" ? placeColumn : boatColumn,
            {
                title: 'Plaisancier',
                key: 'owners',
                render: this.renderOwnersCell,
                sorter: sortOwnersColumn,
                filterIcon: <FilterIcon active={filters.owners.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="owners"
                        selectedValues={filters.owners}
                        values={this.getBoatOwners().map((r) => ({
                            text: `${r.firstName} ${r.lastName}`,
                            value: r.id,
                        }))}
                        onChange={this.handleFilterChange}
                    />
                ),
            },
            {
                title: Locale.trans('boat.boatType'),
                key: 'boatType',
                render: this.renderBoatTypeCell,
                sorter: sortBoatTypeColumn,
                filterIcon: <FilterIcon active={filters.boatType.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="boatType"
                        selectedValues={filters.boatType}
                        values={this.getBoatTypes().map((r) => ({
                            text: Locale.trans(r),
                            value: r,
                        }))}
                        onChange={this.handleFilterChange}
                    />
                ),
            },
            {
                title: Locale.trans('boat.position'),
                key: 'position',
                render: this.renderBoatPositionCell,
                sorter: sortBoatPositionColumn,
                filterIcon: <FilterIcon active={filters.position.length > 0} />,
                filterDropdown: (
                    <TableColumnFilter
                        name="position"
                        selectedValues={filters.position}
                        values={this.getBoatPositions().map((r) => ({
                            text: Locale.trans(`position.${r}`),
                            value: r,
                        }))}
                        onChange={this.handleFilterChange}
                    />
                ),
            }
        ];

        if (this._displayBy === "boat") {
            columns.push({
                title: Locale.trans('boat.countAbsences'),
                key: 'countAbsences',
                render: this.renderCountAbsenceCell,
                sorter: this.sortCountAbsencesColumn,
            });
        }

        columns.push({
            title: Locale.trans('boat.countOutings'),
            key: 'countOutings',
            render: this.renderCountOutingsCell,
            sorter: this.sortCountOutingsColumn,
        });
        const harbour = HarbourStore.getCurrentHarbour();
        if (harbour.showCavalaireChallenge) {
            columns.push({
                title: 'Challenge',
                key: 'cavalaireChallenge',
                render: this.renderCavalaireChallengeCell,
                sorter: this.sortCavalaireChallengeColumn,
            });
        }

        if (showActions) {
            columns.push({
                title: null,
                key: 'actions',
                width: '50px',
                render: this.rendActionsCell,
            });
        }

        return (
            
                <Table
                dataSource={filteredActivities}
                rowKey="id"
                columns={columns}
                className="row-clickable"
                onRow={(activity) => ({
                    onClick: () => {
                        this.showActivity(activity);
                    },
                })}
                locale={Locale.Table}
                ref={(r) => {
                    this.table = r;
                }}
                loading={(loadingOutings || loadingAbsences) && { indicator: <LoadingIcon /> }}
            />
            );
    }

    getAbsencesByBoat = (boat) => {
        return boat && boat.id ? this.state.absences.filter(absence => absence.boat && absence.boat.id.toString() === boat.id.toString()) : [];
    }

    getActivity = (item) => {
        if (item && item.harbour) {
            return this.state.activities.find(activity => {
                return this._displayBy === "boat" ? 
                activity.boat && activity.boat.id === item.id && activity.harbour.id === item.harbour.id : 
                activity.place && activity.place.id === item.id && activity.harbour.id === item.harbour.id;
            });
        }
        return undefined;
    }

    renderCountAbsenceCell = (boat) => {
        const absences = this.getAbsencesByBoat(boat);
        const trigger = (
            <div className="count-absences-trigger">{absences.length}</div>
        );
        if (absences.length === 0) {
            return trigger;
        }

        const firstAbsences = absences.slice(0, 5);
        const content = (
            <div className="count-absences">
                {firstAbsences.map((a) => (
                    <div key={a.id}>
                        Du {DateService.formatApiToDisplay(a.departureDate)} au{' '}
                        {DateService.formatApiToDisplay(a.returnDate)}
                    </div>
                ))}
            </div>
        );
        return (
            <Popover
                content={content}
                title="Dernières absences"
                placement="left"
            >
                {trigger}
            </Popover>
        );
    };
    renderCountOutingsCell = (item) => {
        const activity = this.getActivity(item);

        const countOutings = activity ? activity.countOutings : 0;
        const trigger = (
            <div className="count-absences-trigger">{countOutings}</div>
        );
        if (countOutings === 0) {
            return trigger;
        }

        const content = (
            <div className="count-outings">
                <div>
                    <label>Haute Saison</label> {activity.countOutingsHighSeason}
                </div>
                <div>
                    <label>Basse Saison</label> {activity.countOutingsLowSeason}
                </div>
                <div>
                    <label>Hors Saison</label> {activity.countOutingsOffSeason}
                </div>
            </div>
        );
        return (
            <Popover
                content={content}
                title="Sorties par saison"
                placement="left"
            >
                {trigger}
            </Popover>
        );
    };

    renderCavalaireChallengeCell = (item) => {
        const activity = this.getActivity(item);
        const countCavalaireChallenge = activity
            ? activity.countCavalaireChallenge
            : 0;
        return countCavalaireChallenge;
    };

    renderBoatPositionCell = (boat) =>
        boat.place &&
        Locale.trans(`position.${boat.place.empty ? 'out' : 'in'}`);
    rendActionsCell = (item) => (
        item && item.boat && <React.Fragment>
            <Button
                type="primary"
                shape="circle"
                icon="edit"
                onClick={(e) => {
                    this.editBoat(item);
                    e.stopPropagation();
                    e.preventDefault();
                    return false;
                }}
            />
        </React.Fragment>
    );
    renderOwnersCell = (item) =>
        item.boat && item.boat.owners
            ? item.boat.owners.map((o) => {
                  const user = UserStore.getById(o.id);
                  if (user) {
                      return (
                          <button
                              key={user.id}
                              style={{ display: 'block' }}
                              className="link"
                              onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  this.editUser(user);
                                  return false;
                              }}
                          >
                              {user.firstName} {user.lastName}
                          </button>
                      );
                  }
                  return (
                      <div key={o.id}>
                          {o.firstName} {o.lastName}
                      </div>
                  );
              })
            : null;
    renderBoatCell = (item) => item.boat && item.boat.name;
    renderPlaceCell = (item) => item.place && item.place.code;
    renderBoatTypeCell = (item) =>
        item.boat && item.boat.boatType && (
            <img
                className="boat-type-icon"
                alt={Locale.trans(item.boat.boatType)}
                title={Locale.trans(item.boat.boatType)}
                src={`images/${item.boat.boatType}.png`}
            />
        );
}

import React, { useEffect, useCallback, useState } from 'react';
import Locale from 'locale/LocaleFactory';
import ToastActions from 'actions/ToastActions';
import UploadButton from 'components/UploadButton';
import useCurrentHarbour from 'hooks/useCurrentHarbour';
import PartnerService from 'services/PartnerService';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import LoginStore from 'stores/LoginStore';
import DeleteButton from 'components/forms/DeleteButton';

export default function PartnerPictures({ partnerId }) {
    const [pictures, setPictures] = useState([]);
    const harbour = useCurrentHarbour();

    const reloadPictures = useCallback(() => {
        PartnerService.getPictures(harbour.id, partnerId).then((res) =>
            setPictures(res),
        );
    }, [harbour.id, partnerId]);

    function deletePicture(pictureId) {
        PartnerService.deletePicture(harbour.id, partnerId, pictureId).then(
            reloadPictures,
        );
    }

    useEffect(() => {
        reloadPictures();
    }, [reloadPictures]);

    return (
        <div>
            {pictures.map((p) => (
                <Picture
                    key={p}
                    harbour={harbour}
                    partnerId={partnerId}
                    pictureId={p}
                    onDelete={() => deletePicture(p)}
                />
            ))}
            <UploadButton
                single
                url={`harbours/${harbour.id}/partners/${partnerId}/pictures`}
                onError={() =>
                    ToastActions.createToastError(
                        Locale.trans('partner.picture.add.error'),
                    )
                }
                onSuccess={() => {
                    reloadPictures();
                }}
                accept="image/png, image/jpeg"
            >
                {Locale.trans('partner.picture.add.title')}
            </UploadButton>
        </div>
    );
}

function Picture({ harbour, partnerId, pictureId, onDelete }) {
    const token = LoginStore.getJwt();
    const formattedToken = token ? token.replace(/\+/g, '%2B') : '';
    const url = `${BaseUrlConstants.BASE_URL}harbours/${harbour.id}/partners/${partnerId}/pictures/${pictureId}`;
    return (
        <div className="__picture">
            <img alt={Locale.trans('partner.thumbnail')} src={`${url}?X-Auth-Token=${formattedToken}`} />
            <DeleteButton
                className="__delete-picture-btn"
                shape="circle"
                onDelete={onDelete}
            />
        </div>
    );
}

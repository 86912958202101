import React from 'react';
import Panel from 'components/Panel';
import Locale from 'locale/LocaleFactory';
import OptimisationChart from './OptimisationChart';
import OptimisationTable from './OptimisationTable';
import OptimisationLegend from './OptimisationLegend';

export default class Optimisation extends React.Component {
    render() {
        return (
            <Panel
                title={Locale.trans('reporting.optimisation.title')}
                className="reporting optimisation"
            >
                <OptimisationChart />
                <OptimisationTable />
                <OptimisationLegend />
            </Panel>
        );
    }
}

import React, { useState } from 'react';
import { Modal, DatePicker, Button } from 'antd';

import moment from 'moment';
import TokenContainer from 'services/TokenContainer';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import HarbourStore from 'stores/HarbourStore';

import './ExportIncidentModal.scss';

interface Props {
    visible: boolean;
    onCancel: () => void;
}

export default function ExportIncidentModal({ visible, onCancel }: Props) {
    // TODO

    const [range, setRange] = useState<
        | undefined
        | undefined[]
        | [undefined, moment.Moment]
        | [moment.Moment]
        | [moment.Moment, moment.Moment]
    >(undefined);

    function exportIncidents() {
        if (!range || range.length < 2) {
            return;
        }
        const from = range[0] ? range[0].format('YYYY-MM-DD') : undefined;
        const to = range[1] ? range[1].format('YYYY-MM-DD') : undefined;

        const url = `${
            BaseUrlConstants.BASE_URL
        }harbours/${HarbourStore.getCurrentHarbourId()}/incidents.csv`;
        const token = TokenContainer.get();
        const formattedToken = token ? token.replace(/\+/g, '%2B') : '';
        const fromParam = from ? `&from=${from}` : '';
        const toParam = to ? `&to=${to}` : '';
        window.open(
            `${url}?X-Auth-Token=${formattedToken}${fromParam}${toParam}`,
        );
    }

    return (
        <Modal
            title="Exporter les incidents"
            visible={visible}
            onCancel={onCancel}
            footer={null}
            className="export-incident-modal"
        >
            <DatePicker.RangePicker
                value={range}
                onChange={setRange}
                format="DD/MM/YYYY"
            />
            <Button type="primary" onClick={exportIncidents}>
                Exporter
            </Button>
        </Modal>
    );
}

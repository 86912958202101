import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class IncidentService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all incidents of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/incidents`,
            method: 'GET',
        });
    }

    /**
     * Get an incident by unique identifier.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} incidentId The identifier of the incident.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    getById(harbourId: number, incidentId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/incidents/${incidentId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new incident to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The incident to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/incidents`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing incident.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} incidentId The identifier of the incident.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, incidentId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/incidents/${incidentId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} incidentId The identifier of the incident.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, incidentId: number, patch: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/incidents/${incidentId}`,
            method: 'PATCH',
            data: patch,
        });
    }

    acknowledge(incidentId: number) {
        return ServiceBase.execute({
            url: `${
                BaseUrlConstants.BASE_URL
            }incidents/${incidentId}/acknowledge`,
            method: "PATCH",
        });
    }
    close(incidentId: number) {
        return ServiceBase.execute({
            url: `${
                BaseUrlConstants.BASE_URL
            }incidents/${incidentId}/close`,
            method: "PATCH",
        });
    }

    getConfigurationsByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/incidents/configuration`,
            method: 'GET',
        });
    }

    patchConfiguration(harbourId: number, configurations: any) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/incidents/configurations`,
            method: 'PATCH',
            data: {
                configurations,
            },
        });
    }

    addComment(harbourId: number, incidentId: number, comment: string) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/incidents/${incidentId}/comments`,
            method: 'POST',
            data: { comment},
        });
    }
}

export default new IncidentService();

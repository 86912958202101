export default {
    HARBOUR_OFFICE: 'harbour-office',
    MAINTENANCE: 'maintenance',
    RESTAURANT: 'restaurant',
    NAUTIC_ACTIVITY: 'nautic-activity',
    ASSOCIATION: 'association',
    HEALTH: 'health',

    ACTIVITY_CULTURE: 'activity-culture',
    ACTIVITY_ENTERTAINMENT: 'activity-entertainment',
    ACTIVITY_NATURE: 'activity-nature',
    ACTIVITY_AUTRE: 'activity-other',

    SHOP_FOOD: 'shop-food',
    SHOP_TOOLS: 'shop-tools',
    SHOP_NAUTIC_TOOLS: 'shop-nautic-tools',
};

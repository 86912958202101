export const mainLabel = (filters: any) => {
    if (filters.period.start.year() !== filters.period.end.year()) {
        return `${filters.period.start.year()} - ${filters.period.end.year()}`;
    }
    return filters.period.start.year();
};

export const comparedLabel = (filters: any) => {
    if (filters.period.start.year() !== filters.period.end.year()) {
        return `${filters.yearCompared} - ${filters.yearCompared + 1}`;
    }
    return filters.yearCompared;
};

import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

interface Props {
    value: string;
    label?: string;
    onChange: (value: string) => void;
}
export default function ColorPicker({ value, label, onChange }: Props) {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    return (
        <div className="color-picker" style={{ display: 'flex' }}>
            {label && <span style={{ display: 'inline-block', marginRight: '8px' }}>{label}</span>}
            <div
                style={{
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                }}
                onClick={() => setDisplayColorPicker(!displayColorPicker)}
                onKeyPress={() => setDisplayColorPicker(!displayColorPicker)}
                role="button"
                tabIndex={0}
            >
                <div
                    style={{
                        width: '36px',
                        height: '14px',
                        borderRadius: '2px',
                        background: value,
                    }}
                />
            </div>
            {displayColorPicker ? (
                <div style={{ position: 'absolute', zIndex: 2 }}>
                    <div
                        style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }}
                        onClick={() => setDisplayColorPicker(false)}
                        onKeyPress={() => setDisplayColorPicker(false)}
                        role="button"
                        tabIndex={0}
                    />
                    <ChromePicker color={value || '#ffffff'} onChange={e => onChange(e.hex)} />
                </div>
            ) : null}
        </div>
    );
}

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class BoatService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all places of a harbour.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(harbourId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/boats`,
            method: 'GET',
        });
    }

    /**
     * Get a place by unique identifier.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} boatId The identifier of the boat.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    //@ts-ignore
    getById(harbourId: number, boatId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/boats/${boatId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new boat to a harbour.
     * @param {number} harbourId The identifier of the harbour.
     * @param {Object} resource The boat to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    //@ts-ignore
    post(harbourId: number, resource: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/boats`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing boat.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} boatId The identifier of the boat.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    //@ts-ignore
    remove(harbourId: number, boatId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/boats/${boatId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} harbourId The identifier of the harbour.
     * @param {number} boatId The identifier of the boat.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    //@ts-ignore
    patch(harbourId: number, boatId: number, patch: Object) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/boats/${boatId}`,
            method: 'PATCH',
            data: patch,
        });
    }

    // Owners
    addOwner(harbourId: number, boatId: number, ownerId: number) {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/boats/${boatId}/owners`,
            method: 'POST',
            data: { id: ownerId },
        });
    }

    removeOwner(harbourId: number, boatId: number, ownerId: number) {
        return ServiceBase.execute({
            url: `${
                BaseUrlConstants.BASE_URL
            }harbours/${harbourId}/boats/${boatId}/owners/${ownerId}`,
            method: 'DELETE',
        });
    }
}

export default new BoatService();

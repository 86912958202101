import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from 'react';
import MathService from 'services/utils/MathService';
import './SvgPath.scss';

interface Props {
    className?: string;
    d: string;
    onClick?: () => void;
    fill?: string;
    stroke?: string;
    editable?: boolean;
    onChange?: (value: string) => void;
    zoom: number;
}

function SvgPath({
    className,
    d,
    onClick,
    fill,
    stroke,
    editable,
    onChange,
    zoom,
}: Props) {
    if (editable) {
        return (
            <EditablePath
                className={className}
                initialPath={d}
                fill={fill}
                stroke={stroke}
                onChange={onChange}
                zoom={zoom}
            />
        );
    }

    return (
        <path
            className={className || undefined}
            d={d}
            onClick={onClick}
            fill={fill}
            stroke={stroke}
        />
    );
}

interface EditablePathProps {
    className?: string;
    initialPath: string;
    fill?: string;
    stroke?: string;
    onChange?: (value: string) => void;
    zoom: number;
}

function getPathBoudaryBox(points: number[][]) {
    const boundaries = points
        .filter((_, i) => i !== 0)
        .map((p, i) => {
            const iPrevPoint = i === 0 ? points.length - 1 : i;
            const prevP = points[iPrevPoint];

            const p0 = { x: prevP[4], y: prevP[5] };
            const p0hr = { x: p[0], y: p[1] };
            const p1 = { x: p[4], y: p[5] };
            const p1hl = { x: p[2], y: p[3] };

            return MathService.getCurveBounds(p0, p0hr, p1, p1hl);
        });

    const minX = Math.min(...boundaries.map((b) => b.minX));
    const minY = Math.min(...boundaries.map((b) => b.minY));
    const maxX = Math.max(...boundaries.map((b) => b.maxX));
    const maxY = Math.max(...boundaries.map((b) => b.maxY));

    return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
}

function EditablePath({
    className,
    initialPath,
    fill,
    stroke,
    onChange,
    zoom,
}: EditablePathProps) {
    const [path, setPath] = useState(initialPath);

    const [isRotate, setIsRotate] = useState(false);
    const [isResize, setIsResize] = useState(false);
    const [isMoving, setIsMoving] = useState(false);
    const [moveStartPosition, setMoveStartPosition] = useState<Point | null>(
        null,
    );
    const [boxRotation, setBoxRotation] = useState(0);
    const [boxTranslation, setBoxTranslation] = useState({ x: 0, y: 0 });
    const node = useRef<SVGPathElement | null>(null);

    const points = getPointsFromPath(path);

    const handleSize = 12 / zoom;
    const editRect = useMemo(() => getPathBoudaryBox(points), [points]);

    const handleChange = useCallback(
        (newPoints: number[][], commit?: boolean) => {
            const newPath = getSvgFromPoints(newPoints, initialPath);
            setPath(newPath);
            if (commit) {
                onChange && onChange(newPath);
            }
        },
        [onChange, initialPath],
    );

    useEffect(() => {
        function onMouseMove(e: MouseEvent) {
            e.stopPropagation();

            // The "mouseup" event is not triggered consistantly,
            // we use this solution to check if the mouse is up
            const isUp = e.buttons === 0;
            if (isUp) {
                onMouseUp();
                return;
            }

            if (isRotate && node.current) {
                const {
                    left: x,
                    top: y,
                    width,
                    height,
                } = node.current.getClientRects()[0];

                const textCenter = {
                    x: x + width / 2,
                    y: y + height / 2,
                };
                const mousePosition = {
                    x: e.clientX,
                    y: e.clientY,
                };

                let newRotation = Math.round(
                    (Math.atan(
                        (textCenter.y - mousePosition.y) /
                            (textCenter.x - mousePosition.x),
                    ) *
                        180) /
                        Math.PI,
                );
                if (mousePosition.x < textCenter.x) {
                    newRotation += 180;
                }

                setBoxRotation(newRotation);
            }

            if (isMoving && moveStartPosition) {
                const mousePosition = {
                    x: e.clientX,
                    y: e.clientY,
                };

                // Take the delta where we are minus where we came from.
                const dx = (mousePosition.x - moveStartPosition.x) / zoom;
                const dy = (mousePosition.y - moveStartPosition.y) / zoom;

                setMoveStartPosition(mousePosition);
                setBoxTranslation((prev) => ({
                    x: Math.round(prev.x + dx),
                    y: Math.round(prev.y + dy),
                }));
            }

            if (isResize && moveStartPosition) {
                const mousePosition = {
                    x: e.clientX,
                    y: e.clientY,
                };

                // Take the delta where we are minus where we came from.
                const dx = (mousePosition.x - moveStartPosition.x) / zoom;
                const newScale = (editRect.width + dx) / editRect.width;

                setMoveStartPosition(mousePosition);

                const newPoints = points.map((p) => {
                    return p.map((v, i) => {
                        // X Axis
                        if (i % 2 === 0) {
                            return v * newScale - editRect.x * (newScale - 1);
                        }
                        // Y Axis
                        return (
                            v * newScale -
                            editRect.y * (newScale - 1) -
                            (editRect.height * (newScale - 1)) / 2
                        );
                    });
                });
                handleChange(newPoints, true);
            }
        }
        function onMouseUp() {
            // To optimise, we generate the new coordinates of the points
            // when the user release the mouse.
            // We were able to do it with rotation and translation only.
            if (isRotate || isMoving) {
                const center = {
                    x: editRect.x + editRect.width / 2,
                    y: editRect.y + editRect.height / 2,
                };
                const newPoints = points.map((p) => {
                    const tempPoints = [];
                    for (let i = 0; i < p.length; i += 2) {
                        tempPoints.push({
                            x: p[i],
                            y: p[i + 1],
                        });
                    }
                    const transformedPoints = tempPoints.map((tempPoint) => {
                        let transformedPoint = MathService.rotatePoint(
                            center,
                            tempPoint,
                            -boxRotation,
                        );
                        transformedPoint.x += boxTranslation.x;
                        transformedPoint.y += boxTranslation.y;
                        return [transformedPoint.x, transformedPoint.y];
                    });

                    return ([] as number[]).concat(...transformedPoints);
                });
                handleChange(newPoints, true);
            }

            setBoxRotation(0);
            setBoxTranslation({ x: 0, y: 0 });

            setIsRotate(false);
            setIsResize(false);
            setIsMoving(false);
        }

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, [
        isRotate,
        isMoving,
        isResize,
        editRect,
        boxRotation,
        boxTranslation,
        points,
        moveStartPosition,
        zoom,
        handleChange,
    ]);

    useEffect(() => {
        setPath(initialPath);
    }, [initialPath]);

    function commitChange() {
        onChange && onChange(path);
    }

    return (
        <g
            className="editable-svg-path"
            transform={
                editRect
                    ? `rotate(${boxRotation} ${
                          editRect.x + editRect.width / 2
                      },${editRect.y + editRect.height / 2})  
                      translate(${boxTranslation.x}, ${boxTranslation.y})`
                    : undefined
            }
        >
            <path
                ref={node}
                className={`${className || ''} svg-path`}
                d={path}
                fill={fill}
                stroke={stroke}
            />
            {editRect && (
                <g className="__boudary-box">
                    <rect className="__box" {...editRect} />
                    <g
                        className="__handles"
                        transform={`translate(${
                            editRect.x + editRect.width + 20 / zoom
                        }, ${editRect.y + editRect.height / 2})`}
                    >
                        <g
                            transform={`translate(0, -${20 / zoom})`}
                            onMouseDown={(e) => {
                                e.stopPropagation();
                                setIsRotate(true);
                                setIsResize(false);
                                setIsMoving(false);
                                return false;
                            }}
                        >
                            <circle r={10 / zoom} />
                            <svg
                                viewBox="64 64 896 896"
                                width={12 / zoom}
                                height={12 / zoom}
                                x={-6 / zoom}
                                y={-6 / zoom}
                            >
                                <path d="M758.2 839.1C851.8 765.9 912 651.9 912 523.9 912 303 733.5 124.3 512.6 124 291.4 123.7 112 302.8 112 523.9c0 125.2 57.5 236.9 147.6 310.2 3.5 2.8 8.6 2.2 11.4-1.3l39.4-50.5c2.7-3.4 2.1-8.3-1.2-11.1-8.1-6.6-15.9-13.7-23.4-21.2a318.64 318.64 0 01-68.6-101.7C200.4 609 192 567.1 192 523.9s8.4-85.1 25.1-124.5c16.1-38.1 39.2-72.3 68.6-101.7 29.4-29.4 63.6-52.5 101.7-68.6C426.9 212.4 468.8 204 512 204s85.1 8.4 124.5 25.1c38.1 16.1 72.3 39.2 101.7 68.6 29.4 29.4 52.5 63.6 68.6 101.7 16.7 39.4 25.1 81.3 25.1 124.5s-8.4 85.1-25.1 124.5a318.64 318.64 0 01-68.6 101.7c-9.3 9.3-19.1 18-29.3 26L668.2 724a8 8 0 00-14.1 3l-39.6 162.2c-1.2 5 2.6 9.9 7.7 9.9l167 .8c6.7 0 10.5-7.7 6.3-12.9l-37.3-47.9z" />
                            </svg>
                        </g>
                        <g
                            onMouseDown={(e) => {
                                e.stopPropagation();
                                setMoveStartPosition({
                                    x: e.clientX,
                                    y: e.clientY,
                                });
                                setIsResize(true);
                                setIsRotate(false);
                                setIsMoving(false);
                                return false;
                            }}
                        >
                            <circle r={10 / zoom} />
                            <svg
                                viewBox="64 64 896 896"
                                width={12 / zoom}
                                height={12 / zoom}
                                x={-6 / zoom}
                                y={-6 / zoom}
                            >
                                <path d="M855 160.1l-189.2 23.5c-6.6.8-9.3 8.8-4.7 13.5l54.7 54.7-153.5 153.5a8.03 8.03 0 000 11.3l45.1 45.1c3.1 3.1 8.2 3.1 11.3 0l153.6-153.6 54.7 54.7a7.94 7.94 0 0013.5-4.7L863.9 169a7.9 7.9 0 00-8.9-8.9zM416.6 562.3a8.03 8.03 0 00-11.3 0L251.8 715.9l-54.7-54.7a7.94 7.94 0 00-13.5 4.7L160.1 855c-.6 5.2 3.7 9.5 8.9 8.9l189.2-23.5c6.6-.8 9.3-8.8 4.7-13.5l-54.7-54.7 153.6-153.6c3.1-3.1 3.1-8.2 0-11.3l-45.2-45z" />
                            </svg>
                        </g>
                        <g
                            transform={`translate(0, ${20 / zoom})`}
                            onMouseDown={(e) => {
                                e.stopPropagation();
                                setMoveStartPosition({
                                    x: e.clientX,
                                    y: e.clientY,
                                });
                                setIsMoving(true);
                                setIsRotate(false);
                                setIsResize(false);
                                return false;
                            }}
                        >
                            <circle r={10 / zoom} />
                            <svg
                                viewBox="64 64 896 896"
                                width={12 / zoom}
                                height={12 / zoom}
                                x={-6 / zoom}
                                y={-6 / zoom}
                            >
                                <path d="M290 236.4l43.9-43.9a8.01 8.01 0 00-4.7-13.6L169 160c-5.1-.6-9.5 3.7-8.9 8.9L179 329.1c.8 6.6 8.9 9.4 13.6 4.7l43.7-43.7L370 423.7c3.1 3.1 8.2 3.1 11.3 0l42.4-42.3c3.1-3.1 3.1-8.2 0-11.3L290 236.4zm352.7 187.3c3.1 3.1 8.2 3.1 11.3 0l133.7-133.6 43.7 43.7a8.01 8.01 0 0013.6-4.7L863.9 169c.6-5.1-3.7-9.5-8.9-8.9L694.8 179c-6.6.8-9.4 8.9-4.7 13.6l43.9 43.9L600.3 370a8.03 8.03 0 000 11.3l42.4 42.4zM845 694.9c-.8-6.6-8.9-9.4-13.6-4.7l-43.7 43.7L654 600.3a8.03 8.03 0 00-11.3 0l-42.4 42.3a8.03 8.03 0 000 11.3L734 787.6l-43.9 43.9a8.01 8.01 0 004.7 13.6L855 864c5.1.6 9.5-3.7 8.9-8.9L845 694.9zm-463.7-94.6a8.03 8.03 0 00-11.3 0L236.3 733.9l-43.7-43.7a8.01 8.01 0 00-13.6 4.7L160.1 855c-.6 5.1 3.7 9.5 8.9 8.9L329.2 845c6.6-.8 9.4-8.9 4.7-13.6L290 787.6 423.7 654c3.1-3.1 3.1-8.2 0-11.3l-42.4-42.4z" />
                            </svg>
                        </g>
                    </g>
                </g>
            )}
            <g className="__path-handles">
                {points.map((p, i) => {
                    if (i === 0) {
                        return null;
                    }
                    const iPrevPoint = i === 1 ? points.length - 1 : i - 1;
                    const prevP = points[iPrevPoint];

                    return (
                        <EditablePathSection
                            key={i}
                            prevPoint={prevP}
                            currentPoint={p}
                            onClick={() => {
                                const t = 0.5;
                                const p0 = { x: prevP[4], y: prevP[5] };
                                const p0hr = { x: p[0], y: p[1] };
                                const p1 = { x: p[4], y: p[5] };
                                const p1hl = { x: p[2], y: p[3] };

                                const t1 = {
                                    x: p0.x + (p0hr.x - p0.x) * t,
                                    y: p0.y + (p0hr.y - p0.y) * t,
                                };
                                const t2 = {
                                    x: p0hr.x + (p1hl.x - p0hr.x) * t,
                                    y: p0hr.y + (p1hl.y - p0hr.y) * t,
                                };

                                const t3 = {
                                    x: p1hl.x + (p1.x - p1hl.x) * t,
                                    y: p1hl.y + (p1.y - p1hl.y) * t,
                                };

                                const p2hl = {
                                    x: t1.x + (t2.x - t1.x) * t,
                                    y: t1.y + (t2.y - t1.y) * t,
                                };
                                const p2hr = {
                                    x: t2.x + (t3.x - t2.x) * t,
                                    y: t2.y + (t3.y - t2.y) * t,
                                };
                                const p2 = {
                                    x: p2hl.x + (p2hr.x - p2hl.x) * t,
                                    y: p2hl.y + (p2hr.y - p2hl.y) * t,
                                };

                                const newPoint = [
                                    t1.x,
                                    t1.y,
                                    p2hl.x,
                                    p2hl.y,
                                    p2.x,
                                    p2.y,
                                ];
                                const newCurrentPoint = [
                                    p2hr.x,
                                    p2hr.y,
                                    t3.x,
                                    t3.y,
                                    p1.x,
                                    p1.y,
                                ];

                                const newPoints = [...points];
                                newPoints[i] = newCurrentPoint;
                                newPoints.splice(i, 0, newPoint);
                                handleChange(newPoints, true);
                            }}
                            zoom={zoom}
                        />
                    );
                })}
                {points.map((p, i) => {
                    // If the last point is identical to the first, then we hide the first point.
                    // This is a hack in case we want bezier curve on first point.
                    if (i === 0) {
                        return null;
                    }

                    const iNextPoint = i === points.length - 1 ? 1 : i + 1;
                    const nextP = points[iNextPoint];
                    const iPrevPoint = i === 1 ? points.length - 1 : i - 1;
                    const prevP = points[iPrevPoint];

                    const pointPosition = { x: p[4], y: p[5] };
                    const prevPointPosition = {
                        x: prevP[4],
                        y: prevP[5],
                    };
                    const nextPointPosition = {
                        x: nextP[4],
                        y: nextP[5],
                    };

                    const inControlPointPosition = {
                        x: p[2],
                        y: p[3],
                    };
                    const prevAngle = MathService.getAngleOfLine(
                        prevPointPosition,
                        pointPosition,
                    );
                    let inControlPointOffset = MathService.getPointFromAngleAndDistance(
                        pointPosition,
                        prevAngle,
                        handleSize,
                    );
                    inControlPointOffset.x -= pointPosition.x;
                    inControlPointOffset.y -= pointPosition.y;

                    const outControlPointPosition = {
                        x: nextP[0],
                        y: nextP[1],
                    };

                    const nextAngle = MathService.getAngleOfLine(
                        nextPointPosition,
                        pointPosition,
                    );
                    let outControlPointOffset = MathService.getPointFromAngleAndDistance(
                        pointPosition,
                        nextAngle,
                        handleSize,
                    );
                    outControlPointOffset.x -= pointPosition.x;
                    outControlPointOffset.y -= pointPosition.y;

                    return (
                        <g key={i}>
                            <line
                                x1={pointPosition.x}
                                y1={pointPosition.y}
                                x2={
                                    inControlPointPosition.x +
                                    inControlPointOffset.x
                                }
                                y2={
                                    inControlPointPosition.y +
                                    inControlPointOffset.y
                                }
                                strokeDasharray={`${4 / zoom} ${2 / zoom}`}
                            />
                            <line
                                x1={pointPosition.x}
                                y1={pointPosition.y}
                                x2={
                                    outControlPointPosition.x +
                                    outControlPointOffset.x
                                }
                                y2={
                                    outControlPointPosition.y +
                                    outControlPointOffset.y
                                }
                                strokeDasharray={`${4 / zoom} ${2 / zoom}`}
                            />

                            <PointHandle
                                size={handleSize}
                                position={inControlPointPosition}
                                offset={inControlPointOffset}
                                zoom={zoom}
                                onPositionChange={(newPosition) => {
                                    const newPoints = [...points];

                                    let currentPoint = [...newPoints[i]];

                                    currentPoint[2] = newPosition.x;
                                    currentPoint[3] = newPosition.y;

                                    newPoints[i] = currentPoint;
                                    handleChange(newPoints);
                                }}
                                onComplete={commitChange}
                                color="#223c80"
                            />
                            <PointHandle
                                position={outControlPointPosition}
                                size={handleSize}
                                offset={outControlPointOffset}
                                zoom={zoom}
                                onPositionChange={(newPosition) => {
                                    const newPoints = [...points];
                                    let nextPoint = [...newPoints[iNextPoint]];

                                    nextPoint[0] = newPosition.x;
                                    nextPoint[1] = newPosition.y;

                                    newPoints[iNextPoint] = nextPoint;

                                    handleChange(newPoints);
                                }}
                                onComplete={commitChange}
                                color="#223c80"
                            />
                            <PointHandle
                                position={pointPosition}
                                size={handleSize}
                                zoom={zoom}
                                onPositionChange={(newPosition) => {
                                    const newPoints = [...points];

                                    const currentPoint = [...newPoints[i]];
                                    const firstPoint = [...newPoints[0]];
                                    const nextPoint = [
                                        ...newPoints[iNextPoint],
                                    ];

                                    const prevPosition = {
                                        x: currentPoint[4],
                                        y: currentPoint[5],
                                    };

                                    const dx = newPosition.x - prevPosition.x;
                                    const dy = newPosition.y - prevPosition.y;

                                    currentPoint[2] = currentPoint[2] + dx;
                                    currentPoint[3] = currentPoint[3] + dy;

                                    currentPoint[4] = newPosition.x;
                                    currentPoint[5] = newPosition.y;

                                    if (
                                        i === newPoints.length - 1 &&
                                        firstPoint[0] === prevPosition.x &&
                                        firstPoint[1] === prevPosition.y
                                    ) {
                                        firstPoint[0] = newPosition.x;
                                        firstPoint[1] = newPosition.y;
                                        newPoints[0] = firstPoint;
                                    }

                                    nextPoint[0] = nextPoint[0] + dx;
                                    nextPoint[1] = nextPoint[1] + dy;
                                    newPoints[iNextPoint] = nextPoint;

                                    newPoints[i] = currentPoint;

                                    handleChange(newPoints);
                                }}
                                onComplete={commitChange}
                                onClick={() => {
                                    const newPoints = [...points];
                                    newPoints.splice(i, 1);
                                    handleChange(newPoints, true);
                                }}
                                color="#f59e00"
                            />
                        </g>
                    );
                })}
            </g>
        </g>
    );
}

/**
 * Create the points from a SVG path.
 * In order to make the manipulation easier, we convert all "Line" oprations to "Curve" operations
 * so we have only one case to handle.
 * @param path
 */
function getPointsFromPath(path: string) {
    const commands = path.split(/(?=[LMC])/);
    let points = commands.map(function (c, i) {
        return c
            .slice(1, c.length)
            .split(' ')
            .filter((p: string) => p !== '' && !isNaN(parseFloat(p)))
            .map((p: string) => +p);
    });

    const isLastPointCombined =
        points[points.length - 1].length === 6 &&
        points[points.length - 1][4] === points[0][0] &&
        points[points.length - 1][5] === points[0][1];
    if (!isLastPointCombined) {
        const lastPoint = points[points.length - 1];
        points.push([
            lastPoint.length === 6 ? lastPoint[4] : lastPoint[0],
            lastPoint.length === 6 ? lastPoint[5] : lastPoint[1],
            points[0][0],
            points[0][1],
            points[0][0],
            points[0][1],
        ]);
    }
    points = points.map((p, i) => {
        if (p.length === 6 || i === 0) {
            return p;
        }
        const prevPoint = points[i - 1];
        return [
            prevPoint.length === 6 ? prevPoint[4] : prevPoint[0],
            prevPoint.length === 6 ? prevPoint[5] : prevPoint[1],
            p[0],
            p[1],
            p[0],
            p[1],
        ];
    });
    return points;
}

function getSvgFromPoints(points: number[][], initialPath: string) {
    return `${points
        .map((p, i) => {
            if (i === 0) {
                return `M${MathService.round(p[0], 3)} ${MathService.round(
                    p[1],
                    3,
                )}`;
            }
            return `C${p.map((v) => MathService.round(v, 3)).join(' ')}`;
        })
        .join(' ')}${initialPath.endsWith('z') ? ' z' : ''}`;
}

interface Point {
    x: number;
    y: number;
}

interface PointHandleProps {
    position: Point;
    size: number;
    color?: string;
    onPositionChange: (newPosition: Point) => void;
    onComplete: () => void;
    onClick?: () => void;
    zoom: number;
    offset?: Point;
}

function PointHandle({
    position,
    size,
    color,
    onPositionChange,
    onComplete,
    onClick,
    zoom,
    offset,
}: PointHandleProps) {
    const [isMoving, setIsMoving] = useState(false);
    const [moveStartPosition, setMoveStartPosition] = useState<Point | null>(
        null,
    );

    useEffect(() => {
        function onMouseMove(e: MouseEvent) {
            // The "mouseup" event is not triggered consistantly,
            // we use this solution to check if the mouse is up
            const isUp = e.buttons === 0;
            if (isUp) {
                onMouseUp();
                return;
            }

            if (isMoving) {
                e.stopPropagation();
                const currentPosition = {
                    x:
                        typeof e.clientX === 'undefined'
                            ? (e as any).changedTouches[0].clientX
                            : e.clientX,
                    y:
                        typeof e.clientY === 'undefined'
                            ? (e as any).changedTouches[0].clientY
                            : e.clientY,
                };

                // Take the delta where we are minus where we came from.
                const dx =
                    (currentPosition.x -
                        (moveStartPosition ? moveStartPosition.x : 0)) /
                    zoom;
                const dy =
                    (currentPosition.y -
                        (moveStartPosition ? moveStartPosition.y : 0)) /
                    zoom;

                setMoveStartPosition(currentPosition);
                onPositionChange({
                    x: Math.round(position.x + dx),
                    y: Math.round(position.y + dy),
                });
            }
        }
        function onMouseUp() {
            if (isMoving) {
                onComplete();
            }
            setIsMoving(false);
        }

        if (isMoving) {
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        }
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, [
        isMoving,
        moveStartPosition,
        position,
        zoom,
        onPositionChange,
        onComplete,
    ]);

    return (
        <circle
            className="__point-handle"
            cx={position.x + (offset ? offset.x : 0)}
            cy={position.y + (offset ? offset.y : 0)}
            r={size / 2}
            fill={color || 'green'}
            onMouseDown={(e) => {
                e.stopPropagation();
                setMoveStartPosition({
                    x:
                        typeof e.clientX === 'undefined'
                            ? (e as any).changedTouches[0].clientX
                            : e.clientX,
                    y:
                        typeof e.clientY === 'undefined'
                            ? (e as any).changedTouches[0].clientY
                            : e.clientY,
                });
                setIsMoving(true);
                return false;
            }}
            onDoubleClick={(e) => {
                e.stopPropagation();
                onClick && onClick();
                return false;
            }}
        />
    );
}

interface EditablePathSectionProps {
    prevPoint: number[];
    currentPoint: number[];
    onClick: () => void;
    zoom: number;
}
function EditablePathSection({
    prevPoint,
    currentPoint,
    onClick,
    zoom,
}: EditablePathSectionProps) {
    return (
        <path
            className="__editable-path-section"
            strokeWidth={10 / zoom}
            d={`M${prevPoint[4]} ${prevPoint[5]} C${currentPoint.join(' ')}`}
            onDoubleClick={(e) => {
                e.stopPropagation();
                onClick();
                return false;
            }}
        />
    );
}

export default SvgPath;

import React from 'react';
import { Modal } from 'antd';

import RateForm from 'components/forms/RateForm.jsx';

import Locale from 'locale/LocaleFactory';

import ToastActions from 'actions/ToastActions';
import RateActions from 'actions/RateActions';
import HarbourStore from 'stores/HarbourStore';
import FormService from 'services/utils/FormService';

/**
 * The modal to create a rate.
 */
export default class CreateRateModal extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            fields: { boatTypes: { value: [] } },
        };
    }

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (rate) => {
        this.setState({
            loading: true,
        });
        RateActions.create(HarbourStore.getCurrentHarbour(), rate)
            .then(() => {
                this.setState({
                    fields: {},
                });
                ToastActions.createToastSuccess(Locale.trans('rate.creation.success'));
                this.props.onCancel();
            })
            .catch(FormService.handleError)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        return (
            <Modal
                title={Locale.trans('rate.creation.title')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                footer={null}
            >
                <RateForm
                    onChange={this.handleFormChange}
                    fields={this.state.fields}
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                />
            </Modal>
        );
    }
}

import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';

import DateService from 'services/utils/DateService';
import { Moment } from 'moment';

const URL: string = `${BaseUrlConstants.BASE_URL}`;

class OutingService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get all outings.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByHarbour(
        harbourId: number,
        startDate: Moment,
        endDate: Moment,
    ): Promise<any> {
        const startDateParam = startDate
            ? `&start-date=${DateService.formatApi(startDate)}`
            : '';
        const endDateParam = endDate
            ? `&end-date=${DateService.formatApi(endDate)}`
            : '';
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/outings?t=0${startDateParam}${endDateParam}`,
            method: 'GET',
        });
    }

    /**
     * Get a place by unique identifier.
     * @param {number} outingId The identifier of the outing.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    getById(harbourId: number, outingId: number): Promise<any> {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/outings/${outingId}`,
            method: 'GET',
        });
    }

    /**
     * Post a new outing.
     * @param {Object} resource The outing to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    post(harbourId: number, resource: Object): Promise<any> {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/outings`,
            method: 'POST',
            data: resource,
        });
    }

    /**
     * Delete an existing outing.
     * @param {number} outingId The identifier of the outing.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    remove(harbourId: number, outingId: number): Promise<any> {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/outings/${outingId}`,
            method: 'DELETE',
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} outingId The identifier of the outing.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    // @ts-ignore
    patch(harbourId: number, outingId: number, patch: Object): Promise<any> {
        return ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}harbours/${harbourId}/outings/${outingId}`,
            method: 'PATCH',
            data: patch,
        });
    }
}

export default new OutingService();

import React from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormBase from 'components/forms/FormBase.jsx';
import FormItem from 'components/forms/FormItems';

import LoginStore from 'stores/LoginStore';

import RoleConstants from 'constants/RoleConstants';
import SecuritySercive from 'services/SecurityService';

class UserForm extends FormBase {
    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isFormSubmited(true);
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.props.onSubmit) {
                const user = this.getUserFromValues(values);
                this.props.onSubmit(user);
            }
        });
    };

    getUserFromValues = (values) => {
        const boat = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];
        keys.map((k) => {
            if (k.endsWith('_ids') || k === 'passwordConfirm') {
                return null;
            }
            if (k.endsWith('_id')) {
                const tK = k.replace('_id', '');
                boat[tK] = values[k];
                ignoredKeys.push(tK);
            } else if (ignoredKeys.indexOf(k) === -1) {
                boat[k] = values[k];
            }
            return null;
        });
        return boat;
    };

    checkPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('plainPassword')) {
            callback('Les mots de passe ne sont pas identiques!');
        } else {
            callback();
        }
    };

    render() {
        const { form, user, loading } = this.props;
        const isEditing = !!user;

        const loggedUser = LoginStore.getUser();
        const canEditPassword =
            user &&
            (loggedUser.id === user.id ||
                SecuritySercive.isGranted([RoleConstants.ADMINISTRATOR]));

        return (
            <Form onSubmit={this.handleSubmit}>
                {this.renderRoleField()}

                <FormItem.Input
                    id="firstName"
                    required
                    label={Locale.trans('user.firstName')}
                    form={form}
                    validateStatus={this.getValidateStatus('firstName')}
                    help={this.getHelp('firstName')}
                    hasFeedback
                />
                <FormItem.Input
                    id="lastName"
                    required
                    label={Locale.trans('user.lastName')}
                    form={form}
                    validateStatus={this.getValidateStatus('lastName')}
                    help={this.getHelp('lastName')}
                    hasFeedback
                />
                <FormItem.Input
                    id="email"
                    required
                    label={Locale.trans('user.email')}
                    form={form}
                    validateStatus={this.getValidateStatus('email')}
                    help={this.getHelp('email')}
                    hasFeedback
                />
                <FormItem.PhoneInput
                    id="phoneNumber"
                    label={Locale.trans('user.phoneNumber')}
                    form={form}
                    validateStatus={this.getValidateStatus('phoneNumber')}
                    help={this.getHelp('phoneNumber')}
                    hasFeedback
                />
                <FormItem.Select
                    id="locale"
                    required
                    label={Locale.trans('user.locale')}
                    form={form}
                    options={Locale.getAvailableLocales().map((l) => ({
                        value: l,
                        label: Locale.trans(`locale.${l}`),
                    }))}
                />

                <FormItem.Input
                    id="username"
                    required
                    label={Locale.trans('user.username')}
                    form={form}
                    validateStatus={this.getValidateStatus('username')}
                    help={this.getHelp('username')}
                    hasFeedback
                />

                {loggedUser.isSuperAdmin && (
                    <React.Fragment>
                        <FormItem.Checkbox
                            id="showSecurityModule"
                            label="Module sécurité"
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'showSecurityModule',
                            )}
                            help={this.getHelp('showSecurityModule')}
                            hasFeedback
                        />
                        <FormItem.Checkbox
                            id="showReportingModule"
                            label="Module reporting"
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'showReportingModule',
                            )}
                            help={this.getHelp('showReportingModule')}
                            hasFeedback
                        />
                        <FormItem.Checkbox
                            id="showCommunicationModule"
                            label="Module communication"
                            form={form}
                            validateStatus={this.getValidateStatus(
                                'showCommunicationModule',
                            )}
                            help={this.getHelp('showCommunicationModule')}
                            hasFeedback
                        />
                    </React.Fragment>
                )}

                {!isEditing && this.renderPasswordFields(true)}
                {isEditing && canEditPassword && (
                    <React.Fragment>
                        <h2>{Locale.trans('user.password.edit.title')}</h2>
                        {this.renderPasswordFields(false)}
                    </React.Fragment>
                )}

                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                >
                    {Locale.trans('save')}
                </Button>
            </Form>
        );
    }

    renderPasswordFields(isRequired) {
        const { form } = this.props;
        return (
            <React.Fragment>
                <FormItem.Input
                    id="plainPassword"
                    type="password"
                    required={isRequired}
                    label={Locale.trans('user.password')}
                    form={form}
                    validateStatus={this.getValidateStatus('plainPassword')}
                    help={this.getHelp('plainPassword')}
                    hasFeedback
                />
                <FormItem.Input
                    id="passwordConfirm"
                    type="password"
                    required={isRequired}
                    label={Locale.trans('user.passwordConfirm')}
                    rules={[
                        {
                            validator: this.checkPassword,
                        },
                    ]}
                    form={form}
                    validateStatus={this.getValidateStatus('passwordConfirm')}
                    help={this.getHelp('passwordConfirm')}
                    hasFeedback
                />
            </React.Fragment>
        );
    }

    renderRoleField() {
        if (!SecuritySercive.isGranted([RoleConstants.ADMINISTRATOR])) {
            return null;
        }

        const options = Object.values(RoleConstants)
            .filter((v) => v !== RoleConstants.ANY)
            .map((o) => ({
                value: o,
                label: Locale.trans(`role.${o}`),
            }));

        const { getFieldValue } = this.props.form;
        return (
            <FormItem.Select
                id="roles"
                required
                label={Locale.trans('user.roles')}
                initialValue={getFieldValue('roles')}
                options={options}
                form={this.props.form}
                multiple
            />
        );
    }
}

export default Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange) {
            props.onChange(changedFields);
        }
    },
    mapPropsToFields(props) {
        const { fields } = props;
        if (!fields) {
            return {};
        }
        const kFields = Object.keys(fields);
        const map = {};
        for (let i = 0; i < kFields.length; i++) {
            const k = kFields[i];
            map[k] = Form.createFormField({
                ...fields[k],
                value: fields[k].value,
            });
        }
        return map;
    },
})(UserForm);

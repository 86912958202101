import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import NotificationUserListService from 'services/NotificationUserListService';

import CacheManager from 'services/CacheManager';
import HarbourStore from 'stores/HarbourStore';

const CACHE_DURATION = 15 * 1000;

const RELOAD_INTERVAL = 20 * 1000;
const intervalIds = {} as { [harbourId: number]: any };
class NotificationUserListActions extends ActionsBase {
    create = (harbour: IHarbour, entity: Object) => {
        const $this = this;
        return new Promise(
            (
                resolve: (entity: INotificationUserList) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(
                    newNotificationUserList: INotificationUserList,
                ): void {
                    dispatcher.dispatch({
                        type:
                            'NotificationUserList:receiveNotificationUserList',
                        payload: {
                            harbour,
                            list: newNotificationUserList,
                        },
                    });
                    resolve(newNotificationUserList);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                NotificationUserListService.post(harbour.id, entity)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    edit = (harbour: Entity, id: number, entity: Object) => {
        const $this = this;
        return new Promise(
            (
                resolve: (entity: INotificationUserList) => void,
                reject: (response: Object) => void,
            ): void => {
                function handleSuccess(
                    newNotificationUserList: INotificationUserList,
                ): void {
                    dispatcher.dispatch({
                        type:
                            'NotificationUserList:receiveNotificationUserList',
                        payload: {
                            harbour,
                            list: newNotificationUserList,
                        },
                    });
                    resolve(newNotificationUserList);
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                NotificationUserListService.patch(harbour.id, id, entity)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    delete = (harbour: Entity, id: number) => {
        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                function handleSuccess(): void {
                    dispatcher.dispatch({
                        type: 'NotificationUserList:deleteNotificationUserList',
                        payload: {
                            harbour,
                            id,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                NotificationUserListService.remove(harbour.id, id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };

    reload = (force?: boolean) =>
        this.reloadByHarbour(HarbourStore.getCurrentHarbour(), force);

    reloadByHarbour = (harbour: Entity | null | undefined, force?: boolean) => {
        if (harbour && !intervalIds[harbour.id]) {
            intervalIds[harbour.id] = setInterval(() => {
                this.reloadByHarbour(harbour).catch(function () {
                    clearInterval(intervalIds[harbour.id]);
                    intervalIds[harbour.id] = null;
                });
            }, RELOAD_INTERVAL);
        }

        const $this = this;
        return new Promise(
            (resolve: () => void, reject: (response: Object) => void): void => {
                if (
                    !harbour ||
                    (!force &&
                        CacheManager.isCached(
                            'NotificationUserList:reloadByHarbour',
                            harbour.id.toString(),
                            CACHE_DURATION,
                        ))
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(
                    entities: Array<INotificationUserList>,
                ): void {
                    dispatcher.dispatch({
                        type:
                            'NotificationUserList:receiveNotificationUserListsOfHarbour',
                        payload: {
                            harbour,
                            lists: entities,
                        },
                    });
                    resolve();
                }

                function handleError(err: Object): void {
                    $this.handleError(err, reject);
                }

                NotificationUserListService.getByHarbour(harbour.id)
                    .then(handleSuccess)
                    .catch(handleError);
            },
        );
    };
}

export default new NotificationUserListActions();

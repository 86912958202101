import React, { useState, useCallback, useEffect } from 'react';
import ServiceBase from 'services/ServiceBase';
import BaseUrlConstants from 'constants/BaseUrlConstants';
import Loading from 'components/Loading.jsx';
import MDReactComponent from 'markdown-react-js';

export default function Privacy() {
    const { data, loading } = useEndUserLicenseAgreement();
    if (loading) {
        return <Loading />;
    }
    return (
        <div style={{ maxWidth: 800, margin: 'auto', textAlign: 'center' }}>
            <img
                alt="Logo"
                src="images/logo.png"
                style={{ width: 300, paddingBottom: '1rem' }}
            />
            <MDReactComponent
                text={data ? data.content : ''}
                onIterate={(Tag: any, props: any, children: any) => {
                    if (!children) {
                        return <Tag {...props} />;
                    }
                    let newChildren = [...children];
                    const newProps = { ...props };
                    if (Tag === 'p') {
                        newChildren = newChildren.map((item) => {
                            if (item === '\n') {
                                return <br />;
                            }
                            return item;
                        });
                    }
                    if (!newProps.style) {
                        newProps.style = {};
                    }
                    if (Tag === 'h1') {
                        newProps.style.color = '#f59e00';
                    }
                    newProps.style.textAlign = 'center';
                    return <Tag {...newProps}>{newChildren}</Tag>;
                }}
            />
        </div>
    );
}

function useEndUserLicenseAgreement() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<{ content: string } | undefined>(
        undefined,
    );

    const refetch = useCallback(() => {
        setLoading(true);
        ServiceBase.execute({
            url: `${BaseUrlConstants.BASE_URL}end-user-license-agreement`,
        })
            .then((res: any) => {
                setData(res);
            })
            .catch((e: any) => console.error(e))
            .finally(() => {
                setLoading(false);
            });
    }, []);
    useEffect(() => {
        refetch();
    }, [refetch]);
    return { data, loading, refetch };
}
